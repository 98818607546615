import { gql } from "@apollo/client";
import { productItemProductInstanceFields } from "../RiskDetails/RiskDetails.query";
import { authorizationWorkflowAuditFields } from "graphql/fragments/authorizationWorkflowAudit.fragment";
import { notificationRecipientFields } from "graphql/fragments/notificationRecipient.fragment";

export const attachmentFields = gql`
  fragment AttachmentFields on Attachment {
    id
    fileName
    fileUrl
    mimeType
    description
    locked
    fileSize
    status
    dateCreated
    dateModified
    creatorId
    creator {
      id
      firstname
      surname
      company {
        registeredName
      }
    }
  }
`;

export const ewSubTypesFields = gql`
  fragment StatusOptionFields on ItemStatusOption {
    id
    description
    equatesToRemoved
    statusCollectionId
    isInternal
    status
  }

  fragment LightUserFields on User {
    id
    firstname
    surname
  }
`;

export const earlyWarningItemQuery = gql`
  ${ewSubTypesFields}
  ${productItemProductInstanceFields}
  ${attachmentFields}
  ${authorizationWorkflowAuditFields}
  ${notificationRecipientFields}

  query earlyWarningItem($id: ID!) {
    earlyWarningItem(id: $id) {
      id
      title
      severity
      data {
        sections {
          name
          entries {
            name
            value
          }
        }
      }
      number
      productInstanceId
      ownerId
      statusOptionId
      dateCreated
      creatorId
      creator {
        ...LightUserFields
      }
      statusOption {
        ...StatusOptionFields
      }
      owner {
        ...LightUserFields
      }
      productInstance {
        ...ProductInstanceFields
      }
      dateModified
      attachments {
        ...AttachmentFields
      }
      #changeLog: ChangeLogItemList!
      #comments(limit: Int!, nextToken: String): CommentList!
      offline
      givenById
      dateSent
      authorizationWorkflowAudit {
        ...AuthorizationWorkflowAuditFields
      }
      notificationRecipients {
        ...NotificationRecipientFields
      }
    }
  }
`;
