import { FileItemProps, FileMIMEType } from "../../Attachments.decl";
import { isAttachment, stringToFileMIMEType } from "../../Attachments.utils";
import { useCallback, useEffect, useState } from "react";
import { useAttachmentDownloadPresignedUrl } from "../../hooks/useAttachmentDownloadPresignedUrl";
import { useFileItem } from "../../hooks/useFileItem";

export const useFileGridItem = (props: FileItemProps) => {
  const { file, editable = true } = props;

  const fileLocked = isAttachment(file) && file.locked;
  const fileLoading = !isAttachment(file) && file.loading;

  const [previewUrl, setPreviewUrl] = useState<string>();

  const mimeTypeEnum = stringToFileMIMEType(file.mimeType);

  const { getAttachmentDownloadPresignedUrl } =
    useAttachmentDownloadPresignedUrl();

  const { handleDelete, handleDescriptionChange, handleListItemClick } =
    useFileItem(props);

  const loadPresignedUrl = useCallback(
    async (fileUrl: string) => {
      const dwdUrl = await getAttachmentDownloadPresignedUrl(fileUrl);
      setPreviewUrl(dwdUrl ?? "");
    },
    [getAttachmentDownloadPresignedUrl]
  );

  useEffect(() => {
    if (mimeTypeEnum === FileMIMEType.Img && file.fileUrl) {
      loadPresignedUrl(file.fileUrl);
    }
  }, [file, mimeTypeEnum, loadPresignedUrl]);

  return {
    editable,
    fileLocked,
    fileLoading,
    previewUrl,
    mimeTypeEnum,
    setPreviewUrl,
    handleDelete,
    handleDescriptionChange,
    handleListItemClick,
    loadPresignedUrl,
  };
};
