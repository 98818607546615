import { gql } from "@apollo/client";
import { statusOptionLightFields } from "./changeEarlyWarningStatus";

export const editRiskItemMutation = gql`
  ${statusOptionLightFields}

  mutation editRiskItem($input: EditRiskItemInput!) {
    editRiskItem(input: $input) {
      id
      title
      severity
      data {
        sections {
          name
          entries {
            name
            value
          }
        }
      }
      number
      productInstanceId
      ownerId
      statusOptionId
      dateCreated
      creatorId
      creator {
        id
        firstname
        surname
      }
      statusOption {
        ...StatusOptionLightFields
        creatorId
        creator {
          id
          firstname
          surname
        }
        dateCreated
        optionReasons {
          items {
            id
            displayOrder
            description
            statusOptionId
            isInternal
            status
            creatorId
            creator {
              id
              firstname
              surname
            }
            dateCreated
          }
        }
      }
      owner {
        id
        firstname
        surname
      }
      productInstance {
        id
        description
        contract {
          id
          name
          friendlyName
          timeZone
          project {
            id
            name
            friendlyName
          }
        }
        product {
          id
          name
        }
        productSchema {
          id
          name
          isDefault
          productId
          isInternal
          status
          creatorId
          creator {
            id
            firstname
            surname
          }
          dateCreated
          schemaSections {
            items {
              id
              name
              displayText
              displayOrder
              columnPlacement
              productSchemaId
              isInternal
              status
              dateCreated
              creatorId
              creator {
                id
                firstname
                surname
              }
              schemaFields {
                items {
                  id
                  name
                  schemaSectionId
                  fieldTypeId
                  displayText
                  displayOrder
                  isRequired
                  isInternal
                  complexTypeItemId
                  status
                  creatorId
                  creator {
                    id
                    firstname
                    surname
                  }
                  dateCreated
                  fieldType {
                    id
                    description
                    shortCode
                    status
                  }
                }
              }
            }
          }
        }
        statusCollection {
          id
          name
          isDefault
          productId
          isInternal
          status
          creatorId
          statusOptions {
            items {
              id
              displayOrder
              description
              equatesToRemoved
              style
              statusCollectionId
              isInternal
              status
              creatorId
              creator {
                id
                firstname
                surname
              }
              dateCreated
              optionReasons {
                items {
                  id
                  displayOrder
                  description
                  statusOptionId
                  isInternal
                  status
                  creatorId
                  creator {
                    id
                    firstname
                    surname
                  }
                  dateCreated
                }
              }
            }
          }
        }
      }
      dateModified
      # attachments: [Attachment!]
    }
  }
`;
