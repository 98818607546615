import { gql } from "@apollo/client";
import { contractLiteFields } from "graphql/fragments/contractLite.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { notificationRecipientFields } from "graphql/fragments/notificationRecipient.fragment";
import { schemaFieldFields } from "graphql/fragments/schemaField.fragment";

export const productItemProductInstanceFields = gql`
  ${creatorFields}
  ${contractLiteFields}
  ${schemaFieldFields}

  fragment ProductInstanceFields on ProductInstance {
    id
    description
    contract {
      ...ContractLiteFields
    }
    product {
      id
      name
    }
    productSchema {
      id
      name
      isDefault
      productId
      isInternal
      status
      creatorId
      creator {
        ...CreatorFields
      }
      dateCreated
      schemaSections {
        items {
          id
          name
          displayText
          displayOrder
          columnPlacement
          productSchemaId
          isInternal
          status
          dateCreated
          creatorId
          creator {
            ...CreatorFields
          }
          schemaFields {
            items {
              ...SchemaFieldFields
            }
          }
        }
      }
    }
    statusCollection {
      id
      name
      isDefault
      productId
      isInternal
      status
      creatorId
      statusOptions {
        items {
          id
          displayOrder
          description
          equatesToRemoved
          style
          statusCollectionId
          isInternal
          status
          creatorId
          creator {
            ...CreatorFields
          }
          dateCreated
          optionReasons {
            items {
              id
              displayOrder
              description
              statusOptionId
              isInternal
              status
              creatorId
              creator {
                ...CreatorFields
              }
              dateCreated
            }
          }
        }
      }
    }
  }
`;

export const riskItemQuery = gql`
  ${productItemProductInstanceFields}
  ${creatorFields}
  ${notificationRecipientFields}

  query riskItem($id: ID!) {
    riskItem(id: $id) {
      id
      title
      data {
        sections {
          name
          entries {
            name
            value
          }
        }
      }
      severity
      number
      productInstanceId
      productInstance {
        ...ProductInstanceFields
      }
      ownerId
      statusOptionId
      dateCreated
      creatorId
      creator {
        ...CreatorFields
      }
      statusOption {
        id
        description
        equatesToRemoved
        statusCollectionId
        isInternal
        status
      }
      owner {
        ...CreatorFields
      }
      dateModified
      attachments {
        id
        fileName
        fileUrl
        mimeType
        description
        locked
        fileSize
        status
        dateCreated
        dateModified
        creatorId
        creator {
          ...CreatorFields
          company {
            registeredName
          }
        }
      }
      notificationRecipients {
        ...NotificationRecipientFields
      }
    }
  }
`;
