import {
  EditEventItemMutation,
  EditEventItemMutationVariables,
} from "generated/graphql";
import { editEventItemMutation } from "graphql/mutations/editEventItem";
import { useEventItem } from "hooks/useEventItem";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const useEditEvent = () => {
  const { eventItemId } = useParams();
  const { t } = useTranslation();

  const {
    eventItemData,
    refetchEventData,
    loading: eventItemLoading,
    error: eventItemError,
  } = useEventItem(eventItemId!);

  const [doEditEvent, { loading: editEventLoading }] = useGraphMutation<
    EditEventItemMutation,
    EditEventItemMutationVariables
  >(
    editEventItemMutation,
    {
      update: () => {
        refetchEventData();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Events.event"),
    })
  );

  return {
    eventItem: eventItemData,
    eventItemLoading,
    eventItemError,
    doEditEvent,
    editEventLoading,
  };
};
