import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { itemStatusOptionFields } from "graphql/fragments/itemStatusOption.fragment";
import { itemDataFields } from "graphql/fragments/itemData.fragment";
import { attachmentLiteFields } from "./attachmentLite.fragment";
import { productSchemaFields } from "./productSchema.fragment";

export const instructionItemBaseFields = gql`
  ${itemStatusOptionFields}
  ${creatorFields}
  ${itemDataFields}

  fragment InstructionItemBaseFields on InstructionItem {
    id
    title
    severity
    number
    productInstanceId
    ownerId
    statusOptionId
    data {
      ...ItemDataFields
    }
    dateCreated
    dateModified
    notifyDueDate
    creatorId
    creator {
      ...CreatorFields
    }
    statusOption {
      ...ItemStatusOptionFields
    }
    owner {
      ...CreatorFields
    }
    offline
    givenById
    dateSent
  }
`;

export const instructionItemFields = gql`
  ${instructionItemBaseFields}
  ${attachmentLiteFields}
  ${productSchemaFields}

  fragment InstructionItemFields on InstructionItem {
    ...InstructionItemBaseFields
    attachments {
      ...AttachmentLiteFields
    }
    productInstance {
      id
      description
      numberingFormat
      productSchema {
        ...ProductSchemaFields
      }
      contract {
        id
        contractTypeId
        valueCurrency
        timeZone
      }
      product {
        id
        numberingFormat
      }
    }
  }
`;
