import axios from "axios";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  CreateUploadAttachmentPreSignedUrlMutation,
  CreateUploadAttachmentPreSignedUrlMutationVariables,
} from "generated/graphql";
import { createUploadAttachmentPreSignedUrlMutation } from "graphql/mutations/createUploadAttachmentPresignedUrl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EnhancedAttachment, FileType } from "../Attachments.decl";
import {
  attachmentsAndFilesSortFn,
  attachmentsToAttachmentInputs,
  downloadURI,
  isAttachment,
} from "../Attachments.utils";
import { useRemoveAttachmentFile } from "./useRemoveAttachmentFile";
import { useAttachmentDownloadPresignedUrl } from "./useAttachmentDownloadPresignedUrl";
import { snackbarAutoHideDuration } from "constants/constants";
import uniqBy from "lodash.uniqby";

type useAttachmentsResponse = {
  allAttachments: (EnhancedAttachment | FileType)[];
  attachmentsLoading: boolean;
  addAttachments: (newAttachments: FileType[]) => void;
  removeAttachment: (attachment: FileType | Attachment) => void;
  updateAttachment: (updatedAttachment: FileType | Attachment) => void;
  unloadLocalAttachments: () => void;
  downloadAttachment: (attachment: FileType | Attachment) => void;
};

export const useAttachments = (
  attachments: EnhancedAttachment[],
  editProductItemAttachments?: (attachments: AttachmentInput[]) => Promise<void> // NOTE: see if this can return void
): useAttachmentsResponse => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const activeAttachments = useMemo(
    () =>
      attachments.filter((attach) => attach.status === AttachmentStatus.Active),
    [attachments]
  );

  const [localAttachments, setLocalAttachments] = useState<FileType[]>([]);
  const [attachmentsToCommit, setAttachmentsToCommit] = useState<FileType[]>(
    []
  );

  const allAttachments: (EnhancedAttachment | FileType)[] = useMemo(() => {
    const activeAttachmentsUrls = activeAttachments.map(
      (activeAttach) => activeAttach.fileUrl
    );
    // filter out local attachments that have recently been added to a product item, thus they're now coming from "attachments" argument
    const filteredLocalAttachments = localAttachments.filter(
      (localAttach) =>
        !activeAttachmentsUrls.find((url) => url.indexOf(localAttach.id) >= 0)
    );
    if (filteredLocalAttachments.length !== localAttachments.length) {
      setLocalAttachments((crtLocalAttach) =>
        crtLocalAttach.filter(
          (localAttach) =>
            !activeAttachmentsUrls.find(
              (url) => url.indexOf(localAttach.id) >= 0
            )
        )
      );
    }
    const allFilesRaw = [...activeAttachments, ...filteredLocalAttachments];
    allFilesRaw.sort(attachmentsAndFilesSortFn);

    return allFilesRaw;
  }, [localAttachments, activeAttachments]);

  const [createUploadAttachmentPresignedUrl] = useGraphMutation<
    CreateUploadAttachmentPreSignedUrlMutation,
    CreateUploadAttachmentPreSignedUrlMutationVariables
  >(
    createUploadAttachmentPreSignedUrlMutation,
    {
      update: (cache) => {
        cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItem" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "draftEarlyWarningItem" });
        cache.gc();
      },
    },
    null
  );

  const { getAttachmentDownloadPresignedUrl } =
    useAttachmentDownloadPresignedUrl();

  const removeAttachmentFile = useRemoveAttachmentFile();

  const uploadFile = async (file: FileType) => {
    const { data } = await createUploadAttachmentPresignedUrl({
      variables: {
        input: { fileName: file.fileName, mimeType: file.mimeType },
      },
    });

    if (data) {
      await axios
        .put(data.createUploadAttachmentPreSignedUrl.fileUrl, file.rawFile, {
          headers: {
            "Content-Type": file.mimeType,
          },
        })
        .catch((error) =>
          console.error(error.response.data, { request: error.request })
        );

      enqueueSnackbar(t("Attachments.attachmentUploadedMsg"), {
        autoHideDuration: snackbarAutoHideDuration,
        variant: "success",
      });

      return {
        fileName: data.createUploadAttachmentPreSignedUrl.fileName,
        fileUrl: data.createUploadAttachmentPreSignedUrl.fileUrl,
      };
    }
  };

  const tryNotifyConsumerOnAddedAttachments = useCallback(
    async (newAttachments: FileType[], newLocalAttachments: FileType[]) => {
      // Note: on ProductInstanceItems, newLocalAttachments are populated. On Diary sections, newLocalAttachments = [] and localAttachments are populated.
      // TODO: to be revised
      const computedNewAttachments = uniqBy(
        [...newLocalAttachments, ...localAttachments],
        "id"
      );

      if (computedNewAttachments.length) {
        // if there are local attachments when adding a new attachment, it means there's already an upload in progress.
        // Try again and again each 1s until the previous attachment ended
        console.log(
          "upload in progress... saving current attachments for a future commit",
          computedNewAttachments.map((attach) => attach.displayName).join(", ")
        );
        setAttachmentsToCommit(newAttachments);
      } else {
        await editProductItemAttachments?.(
          attachmentsToAttachmentInputs(
            [
              ...activeAttachments,
              ...computedNewAttachments,
              ...newAttachments,
            ].sort(attachmentsAndFilesSortFn)
          )
        );
      }
    },
    [editProductItemAttachments, activeAttachments, localAttachments]
  );

  const handleAddAttachments = async (newAttachments: FileType[]) => {
    setLocalAttachments((crtLocalAttachments) => {
      const allAttachments = [
        ...crtLocalAttachments,
        ...newAttachments.map((newAttach) => ({
          ...newAttach,
          loading: true,
        })),
      ];
      allAttachments.sort(attachmentsAndFilesSortFn);

      return allAttachments;
    });

    // upload to S3
    let newLocalAttachments: FileType[] = [];
    /* eslint-disable no-loop-func */
    for (let index = 0; index < newAttachments.length; index++) {
      const newAttachment = newAttachments[index];

      const result = await uploadFile(newAttachment);
      newAttachment.fileUrl = result?.fileUrl;

      setLocalAttachments((crtAttachments) => {
        newLocalAttachments = crtAttachments.map((attach) =>
          attach.id === newAttachment.id
            ? {
                ...attach,
                fileUrl: result?.fileUrl,
                id: result?.fileName || attach.id,
                loading: false,
              }
            : attach
        );
        return newLocalAttachments;
      });
    }
    /* eslint-enable no-loop-func */

    await tryNotifyConsumerOnAddedAttachments(
      newAttachments,
      newLocalAttachments
    );
  };

  const handleRemoveAttachment = (attachment: FileType | Attachment) => {
    setLocalAttachments((crtLocalAttachments) =>
      crtLocalAttachments.filter(
        (crtLocalAttachment) => crtLocalAttachment.id !== attachment.id
      )
    );

    editProductItemAttachments?.(
      attachmentsToAttachmentInputs(
        allAttachments
          .filter((crtAttachment) => crtAttachment.id !== attachment.id)
          .sort(attachmentsAndFilesSortFn)
      )
    );

    if (!isAttachment(attachment)) {
      // this means the file is not saved against a product item, thus FE needs to manually call remove file from S3 function
      removeAttachmentFile({ variables: { fileName: attachment.id } });
    }
  };

  const handleUpdateAttachment = (updatedAttachment: FileType | Attachment) => {
    editProductItemAttachments?.(
      attachmentsToAttachmentInputs(
        allAttachments
          .map((crtAttachment) =>
            crtAttachment.id === updatedAttachment.id
              ? updatedAttachment
              : crtAttachment
          )
          .sort(attachmentsAndFilesSortFn)
      )
    );

    if (!isAttachment(updatedAttachment)) {
      // AKA if updatedAttachment is a local file not yet uploaded against the product item
      setLocalAttachments((crtLocalAttachments) =>
        crtLocalAttachments.map((crtAttachment) =>
          crtAttachment.id === updatedAttachment.id
            ? updatedAttachment
            : crtAttachment
        )
      );
    }
  };

  const unloadLocalAttachments = () => {
    for (let index = 0; index < localAttachments.length; index++) {
      const crtAttach = localAttachments[index];
      removeAttachmentFile({ variables: { fileName: crtAttach.id } });
    }
  };

  const downloadAttachment = async (attachment: FileType | Attachment) => {
    const dwdUrl = await getAttachmentDownloadPresignedUrl(
      attachment.fileUrl!,
      attachment.fileName
    );

    if (dwdUrl) {
      downloadURI(dwdUrl, attachment.fileName);
    }
  };

  const attachmentsLoading = useMemo(
    () => !!allAttachments.find((attachment) => attachment.loading),
    [allAttachments]
  );

  useEffect(() => {
    if (attachmentsToCommit.length) {
      // new attachments failed to commit because another upload was in progress. When first upload finishes, newlyAttached files are removed from
      // localAttachments, thus we can be certain we can commit the new attachments when `localAttachments` change
      editProductItemAttachments?.(
        attachmentsToAttachmentInputs(
          [
            ...activeAttachments,
            ...localAttachments.filter(
              (attach) =>
                !attachmentsToCommit.find(
                  (attachToCommit) => attachToCommit.fileUrl === attach.fileUrl
                )
            ),
            ...attachmentsToCommit,
          ].sort(attachmentsAndFilesSortFn)
        )
      );

      setAttachmentsToCommit([]);
    }
  }, [
    localAttachments,
    activeAttachments,
    editProductItemAttachments,
    attachmentsToCommit,
  ]);

  return {
    allAttachments,
    attachmentsLoading,
    addAttachments: handleAddAttachments,
    removeAttachment: handleRemoveAttachment,
    updateAttachment: handleUpdateAttachment,
    unloadLocalAttachments,
    downloadAttachment,
  };
};
