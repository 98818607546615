import { gql } from "@apollo/client";

export const statusOptionLightFields = gql`
  fragment StatusOptionLightFields on ItemStatusOption {
    id
    displayOrder
    description
    equatesToRemoved
    style
    statusCollectionId
    isInternal
    status
  }
`;

export const changeEarlyWarningItemStatusMutation = gql`
  ${statusOptionLightFields}

  mutation changeEarlyWarningItemStatus(
    $input: ChangeEarlyWarningItemStatusInput!
  ) {
    changeEarlyWarningItemStatus(input: $input) {
      id
      title
      statusOption {
        ...StatusOptionLightFields
      }
      dateModified
    }
  }
`;
