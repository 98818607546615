import { Box } from "@mui/material";
import { CollapsibleSectionContainer } from "containers/Projects/components/SchemaInterpretor/Section/Section";
import { ReviewersList } from "../ReviewersList/ReviewersList";
import {
  AuthorizationWorkflow,
  AuthorizationWorkflowAudit,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";

export const ReviewersPanel: React.FC<{
  authAudit?: AuthorizationWorkflowAudit;
  authWorkflow?: AuthorizationWorkflow;
  loading?: boolean;
}> = ({ authAudit, authWorkflow, loading }) => {
  const { t } = useTranslation();

  const authLevels = authAudit?.workflow.levels ?? authWorkflow?.levels;

  return (
    <Box width="100%" key="reviewers-section" position="relative">
      {loading ? (
        <CenteredLoadingIndicator />
      ) : (
        <CollapsibleSectionContainer
          collapsible
          title={t("common.labels.reviewers")}
          maxHeight="600px"
          overflow="auto"
        >
          {authLevels && (
            <ReviewersList
              authLevels={authLevels}
              auditTrails={authAudit?.auditTrails ?? []}
            />
          )}
        </CollapsibleSectionContainer>
      )}
    </Box>
  );
};
