import { gql } from "@apollo/client";
import { statusOptionLightFields } from "./changeEarlyWarningStatus";

export const changeRiskItemStatusMutation = gql`
  ${statusOptionLightFields}

  mutation changeRiskItemStatus($input: ChangeRiskItemStatusInput!) {
    changeRiskItemStatus(input: $input) {
      id
      title
      statusOption {
        ...StatusOptionLightFields
      }
      dateModified
    }
  }
`;
