import { gql } from "@apollo/client";

export const changeInstructionItemStatusMutation = gql`
  mutation changeInstructionItemStatus(
    $input: ChangeInstructionItemStatusInput!
  ) {
    changeInstructionItemStatus(input: $input) {
      id
      statusOption {
        id
        description
      }
    }
  }
`;
