import { Box } from "@mui/material";
import { CollapsibleSectionContainer } from "containers/Projects/components/SchemaInterpretor/Section/Section";
import { DailyDiaryItem, DailyDiaryItemRevision } from "generated/graphql";
import { RevisionsList } from "./RevisionsList/RevisionsList";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

type RevisionsPanelProps = {
  revisions: DailyDiaryItemRevision[];
  crtRevisionId: string;
  dailyDiary: DailyDiaryItem;
};

export const RevisionsPanel: React.FC<RevisionsPanelProps> = ({
  dailyDiary,
  crtRevisionId,
  revisions,
}) => {
  const { t } = useTranslation();

  const revisionsOrdered = useMemo(
    () =>
      revisions
        .slice()
        .sort((rev1, rev2) => Number(rev2.number) - Number(rev1.number)),
    [revisions]
  );

  return (
    <Box width="100%" key="revisions-section" position="relative">
      <CollapsibleSectionContainer
        collapsible
        title={t("Projects.DailyDiaries.revisionsMany")}
        maxHeight="600px"
        overflow="auto"
      >
        <RevisionsList
          revisions={revisionsOrdered}
          dailyDiary={dailyDiary}
          crtRevisionId={crtRevisionId}
        />
      </CollapsibleSectionContainer>
    </Box>
  );
};
