import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { ProductItemHeaderContainer } from "containers/Projects/components/HeaderContainer";
import { ProductType } from "generated/graphql";
import { useTranslation } from "react-i18next";
import {
  // EditRiskEventContextMenu,
  EditRiskEventContextMenuProps,
} from "./EditRiskEventContextMenu";

export type EditRiskEventHeaderProps = {
  onCancel: () => void;
  onSave: () => void;
  productType?: ProductType;
  disabled?: boolean;
  loading?: boolean;
} & EditRiskEventContextMenuProps;

export const EditRiskEventHeader: React.FC<EditRiskEventHeaderProps> = ({
  onCancel,
  onSave,
  disabled,
  productType = ProductType.RisksRegister,
  loading,
  // onDuplicate,
  // onMoveToProject,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ProductItemHeaderContainer>
      {(isHeaderStuck?: boolean) => (
        <>
          <Typography variant="h3" color={theme.palette.grey[900]}>
            {productType === ProductType.RisksRegister
              ? t("Projects.Risks.editRisk")
              : t("Projects.Events.editEvent")}
          </Typography>
          {/* TODO: uncomment when implementing the functionality */}
          {/* <EditRiskEventContextMenu
            onDuplicate={onDuplicate}
            onMoveToProject={onMoveToProject}
          /> */}
          <Box
            display="flex"
            alignItems="center"
            ml="auto"
            mr={isHeaderStuck ? 3 : 0}
          >
            <Button variant="text" onClick={onCancel} data-testid="cancel-btn">
              {t("common.buttons.cancel")}
            </Button>
            <Box ml={1}>
              <LoadingButton
                variant="contained"
                size="large"
                onClick={onSave}
                data-testid="save-btn"
                disabled={disabled || loading}
              >
                <Typography variant="p2" fontWeight={700}>
                  {t("common.labels.saveChanges")}
                </Typography>
              </LoadingButton>
            </Box>
          </Box>
        </>
      )}
    </ProductItemHeaderContainer>
  );
};
