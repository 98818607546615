import { gql } from "@apollo/client";
import { eventItemFields } from "graphql/fragments/eventItem.fragment";

export const editEventItemMutation = gql`
  ${eventItemFields}

  mutation editEventItem($input: EditEventItemInput!) {
    editEventItem(input: $input) {
      ...EventItemFields
    }
  }
`;
