import { mainNavigationHeight } from "components/MainNavigation/MainNavigation.styled";

export const adminConsoleNavigationHeight = 70;
export const adminConsolePageHeaderHeight = 50;
export const adminConsolePageBottomPadding = 32;

/** Because we decided to have scroll at page level and headers stay sticky, we cannot
 * enforce overflow: hidden on top parents and rely on height: 100% / flex: 1 on children,
 * thus, on some pages that contain only one grid, we're forcing the grid to be calc(100vh - adminConsolePageContentDifference) */
export const adminConsolePageContentDifference =
  mainNavigationHeight +
  adminConsoleNavigationHeight +
  adminConsolePageHeaderHeight +
  adminConsolePageBottomPadding;
