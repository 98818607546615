import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { DeleteConfirmationModal } from "components/DeleteConfirmationModal";
import {
  AddDailyDiaryWeatherRecordInput,
  AttachmentInput,
  DailyDiaryPresetSection,
  DailyDiaryWeatherRecord,
  EditDailyDiaryWeatherRecordInput,
} from "generated/graphql";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { BaseHeaderSection } from "../BaseSectionHeader";
import { useWeatherSection } from "./useWeatherSection";
import { WeatherRecordModal } from "./WeatherRecordModal/WeatherRecordModal";
import { WeatherTable } from "./WeatherTable/WeatherTable";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { ImportWeatherRecordsModal } from "./ImportWeatherRecordsModal";
import { useDiaryMetadata } from "../../hooks/useDiaryMetadata";

export type WeatherSectionProps = {
  section: DailyDiaryPresetSection;
};

export const WeatherSection: React.FC<WeatherSectionProps> = ({ section }) => {
  const { t } = useTranslation();
  const updatedAttachmentsRef = useRef<AttachmentInput[]>();

  const { dailyDiaryId: dailyDiaryIdSearchParam } = useDiaryMetadata();

  const [recordToDeleteEdit, setRecordToDeleteEdit] =
    useState<DailyDiaryWeatherRecord>();
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const {
    modalVisibility: importModalVisibility,
    toggleModalVisibility: toggleImportModalVisibility,
  } = useBasicModal();

  const {
    dailyDiary,
    emptyDailyDiary,
    contractLookupCollections,
    activeContractLookupCollections,
    loading: contextLoading,
    crtRevisionId,
    refetchDDByDate,
  } = useContext(DailyDiaryContext);
  const { modalVisibility, toggleModalVisibility } = useBasicModal();
  const {
    weatherRecords,
    loading: weatherRecordsLoading,
    refetchDDWeatherRecords,
    addWeatherRecord,
    editWeatherRecord,
    removeWeatherRecord,
  } = useWeatherSection(dailyDiary, crtRevisionId);

  const toggleConfirmationModalVisibility = () => {
    setConfirmationModalVisibility((crtState) => !crtState);
  };

  const handleAddWeatherRecord = () => {
    toggleModalVisibility();
  };

  const triggerEditRecordFlow = (recordId: GridRowId) => {
    const record = weatherRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    toggleModalVisibility();
  };

  const triggerRemoveFlow = (recordId: GridRowId) => {
    const record = weatherRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    setConfirmationModalVisibility(true);
  };

  const handleAttachmentsUpdated = async (
    attachmentInputs: AttachmentInput[]
  ) => {
    updatedAttachmentsRef.current = attachmentInputs;
    if (recordToDeleteEdit) {
      await editWeatherRecord({
        variables: {
          input: {
            id: recordToDeleteEdit.id,
            conditionsOptionId: recordToDeleteEdit.conditionsOptionId,
            humidity: recordToDeleteEdit.humidity,
            rainFall: recordToDeleteEdit.rainFall,
            temperature: recordToDeleteEdit.temperature,
            timeOfMeasurement: recordToDeleteEdit.timeOfMeasurement,
            windSpeed: recordToDeleteEdit.windSpeed,
            attachments: attachmentInputs,
          },
        },
      });
      refetchDDWeatherRecords();
    }
  };

  const handleModalPrimaryClick = async (
    weatherRecord:
      | AddDailyDiaryWeatherRecordInput
      | EditDailyDiaryWeatherRecordInput,
    closeModal?: boolean
  ) => {
    if (recordToDeleteEdit) {
      await editWeatherRecord({
        variables: {
          input: {
            ...(weatherRecord as EditDailyDiaryWeatherRecordInput),
            id: recordToDeleteEdit.id,
            attachments: attachmentsToAttachmentInputs(
              // editing attachments on existing record are updated immediately. Here just commiting the same data
              recordToDeleteEdit.attachments ?? []
            ),
          },
        },
      });
    } else {
      await addWeatherRecord({
        variables: {
          input: {
            ...(weatherRecord as AddDailyDiaryWeatherRecordInput),
            ...(dailyDiary?.id
              ? { dailyDiaryId: dailyDiary.id }
              : {
                  date: emptyDailyDiary?.date,
                  productInstanceId: emptyDailyDiary?.productInstanceId,
                }),
            attachments: updatedAttachmentsRef.current ?? [],
          },
        },
      });
    }

    updatedAttachmentsRef.current = undefined;

    if (closeModal) {
      handleWeatherRecordModalClose();
    }
  };

  const handleSuccessfulImport = () => {
    toggleImportModalVisibility();
    if (dailyDiaryIdSearchParam) {
      refetchDDWeatherRecords();
    } else {
      refetchDDByDate?.();
    }
  };

  const handleWeatherRecordModalClose = () => {
    setRecordToDeleteEdit(undefined);
    toggleModalVisibility();
  };

  const handleRemoveRecord = () => {
    toggleConfirmationModalVisibility();
    removeWeatherRecord({ variables: { id: recordToDeleteEdit?.id! } });
    setRecordToDeleteEdit(undefined);
  };

  useEffect(() => {
    if (recordToDeleteEdit && !weatherRecords.includes(recordToDeleteEdit)) {
      const updatedRecord = weatherRecords.find(
        (rec) => rec.id === recordToDeleteEdit.id
      );
      if (updatedRecord) {
        setRecordToDeleteEdit(updatedRecord);
      }
    }
  }, [weatherRecords, recordToDeleteEdit]);

  return (
    <>
      <DeleteConfirmationModal
        title={t("Projects.DailyDiaries.RemoveConfirmationModal.title")}
        subtitle={t("Projects.DailyDiaries.RemoveConfirmationModal.subtitle")}
        open={confirmationModalVisibility}
        onPrimaryClick={handleRemoveRecord}
        onSecondaryClick={toggleConfirmationModalVisibility}
      />
      {importModalVisibility && (
        <ImportWeatherRecordsModal
          open={importModalVisibility}
          section={section}
          lookups={contractLookupCollections}
          loading={contextLoading}
          onClose={toggleImportModalVisibility}
          onImportSuccess={handleSuccessfulImport}
        />
      )}
      <WeatherRecordModal
        open={modalVisibility}
        onAttachmentsUpdated={handleAttachmentsUpdated}
        onPrimaryClick={handleModalPrimaryClick}
        onSecondaryClick={handleWeatherRecordModalClose}
        onClose={handleWeatherRecordModalClose}
        section={section}
        primaryBtnLoading={weatherRecordsLoading}
        weatherRecord={recordToDeleteEdit}
        weatherLookupCollections={activeContractLookupCollections}
      />
      <Box width="100%">
        <BaseHeaderSection
          title={t("Projects.DailyDiaries.sections.weather")}
          onAdd={handleAddWeatherRecord}
          onImport={toggleImportModalVisibility}
          mb={3}
        />
        <WeatherTable
          section={section}
          records={weatherRecords}
          weatherLookupCollections={contractLookupCollections}
          loading={weatherRecordsLoading || contextLoading}
          onEdit={triggerEditRecordFlow}
          onRemove={triggerRemoveFlow}
        />
      </Box>
    </>
  );
};
