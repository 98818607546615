import { ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { extendedTheme } from "theme/extendedTheme";
import { Authorizations } from "./components/Authorizations/Authorizations";
import { NextSteps } from "./components/NextSteps/NextSteps";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useAuthStore } from "state-management/store/authStore";

export const Home: React.FC = () => {
  const setAuthModalState = useAuthStore((state) => state.setAuthModalState);
  const [searchParams, setSearchParams] = useSearchParams();
  const authAuditId = searchParams.get("authAuditId");
  const authorizationsGridsContainerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const state = location.state as
    | { scrollToAuthorizations?: boolean }
    | undefined;

  useEffect(() => {
    if (state?.scrollToAuthorizations) {
      authorizationsGridsContainerRef.current?.scrollIntoView();
    }
  }, [state]);

  useEffect(() => {
    if (authAuditId) {
      setAuthModalState({
        authAuditId,
        type: "Any",
        bypassUserAuthorizer: true,
      });
      searchParams.delete("authAuditId");
      setSearchParams(searchParams, { replace: true });
    }
  }, [setAuthModalState, authAuditId, searchParams, setSearchParams]);

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <NewPageContentContainer
        sx={{ flexDirection: "column", justifyContent: "flex-start", gap: 7.5 }}
      >
        <NextSteps />
        <Authorizations ref={authorizationsGridsContainerRef} />
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
