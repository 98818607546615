import { gql } from "@apollo/client";
import { statusOptionLightFields } from "./changeEarlyWarningStatus";

export const changeEventItemStatusMutation = gql`
  ${statusOptionLightFields}

  mutation changeEventItemStatus($input: ChangeEventItemStatusInput!) {
    changeEventItemStatus(input: $input) {
      id
      title
      statusOption {
        ...StatusOptionLightFields
      }
      dateModified
    }
  }
`;
