import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ProductInstance, UserRoleAssignmentInput } from "generated/graphql";
import { Key } from "phosphor-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const Header: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="h6"
        fontWeight={600}
        color={theme.palette.grey[800]}
        mb={1}
      >
        {t("AdminConsole.Users.BulkAssignUsersRolesModal.title")}
      </Typography>
      <Typography variant="p1" color={theme.palette.grey[700]}>
        {t("AdminConsole.Users.BulkAssignUsersRolesModal.subtitle")}
      </Typography>
    </Box>
  );
};

type BulkAssignUsersRolesModalProps = {
  productInstances?: ProductInstance[];
  loading?: boolean;
  onPrimaryClick: (roleAssignments: UserRoleAssignmentInput[]) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const BulkAssignUsersRolesModal: React.FC<
  BulkAssignUsersRolesModalProps
> = ({
  productInstances,
  loading,
  onPrimaryClick,
  primaryBtnDisabled,
  ...restDialogProps
}) => {
  const { t } = useTranslation();

  const productInstanceRolesMap = useMemo(() => {
    return productInstances?.reduce((acc, cur) => {
      const roles = cur.product?.roles?.items?.map((role) => ({
        id: role.id,
        name: role.name,
      }));
      roles.sort((r1, r2) => r1.name.localeCompare(r2.name));
      acc[cur.id] = roles;
      return acc;
    }, {} as Record<string, { id: string; name: string }[]>);
  }, [productInstances]);

  const [formData, setFormData] = useState<UserRoleAssignmentInput[]>([]);

  const handlePrimaryBtnClick = () => {
    if (formData.length) {
      onPrimaryClick(formData);
    }
  };

  const handleRoleChange = (
    event: SelectChangeEvent<string>,
    productInstanceId: string
  ) => {
    const productRoleId = event.target.value;

    setFormData((curData) => {
      if (
        curData.find(
          (mapping) => mapping.productInstanceId === productInstanceId
        )
      ) {
        // edit
        return curData.map((mapping) => {
          if (mapping.productInstanceId === productInstanceId) {
            return {
              ...mapping,
              productRoleId,
            };
          }
          return mapping;
        });
      } else {
        // add
        return [
          ...curData,
          {
            productInstanceId,
            productRoleId,
          },
        ];
      }
    });
  };

  const sortedProductInstances = useMemo(() => {
    const copy = productInstances?.slice();

    copy?.sort((pi1, pi2) => pi1.description.localeCompare(pi2.description));

    return copy ?? [];
  }, [productInstances]);

  return (
    <ActionsDialog
      iconsHeader={<Key size={32} />}
      title={<Header />}
      content={
        <Grid container spacing={2}>
          {productInstanceRolesMap &&
          sortedProductInstances.length > 0 &&
          !loading ? (
            sortedProductInstances.map((productInstance) => (
              <Grid
                item
                xs={12}
                justifyContent="space-between"
                alignItems="center"
                key={productInstance.id}
              >
                <Typography variant="body1" fontWeight={600} color="grey.800">
                  {productInstance.description}
                </Typography>
                <Select
                  data-testid={`role-select-${productInstance.product.name}`}
                  value={
                    formData.find(
                      (mapping) =>
                        mapping.productInstanceId === productInstance.id
                    )?.productRoleId ?? ""
                  }
                  onChange={(event) =>
                    handleRoleChange(event, productInstance.id)
                  }
                  size="small"
                  fullWidth
                  displayEmpty
                >
                  {productInstanceRolesMap[productInstance.id]?.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ))
          ) : (
            <CenteredLoadingIndicator />
          )}
        </Grid>
      }
      contentSx={{ minWidth: "420px", maxWidth: "520px" }}
      onPrimaryClick={handlePrimaryBtnClick}
      primaryBtnCaption={t("common.buttons.save")}
      primaryBtnDisabled={primaryBtnDisabled || !formData.length}
      {...restDialogProps}
    />
  );
};
