import {
  ChangeRiskItemStatusMutation,
  ChangeRiskItemStatusMutationVariables,
  EditRiskItemMutation,
  EditRiskItemMutationVariables,
  RiskItem,
  RiskItemQuery,
  RiskItemQueryVariables,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useTranslation } from "react-i18next";
import { riskItemQuery } from "../RiskDetails.query";
import { useParams } from "react-router-dom";
import { ErrorHandlingType, useGraphMutation } from "hooks/useGraphMutation";
import { changeRiskItemStatusMutation } from "graphql/mutations/changeRiskStatus";
import { editRiskItemMutation } from "graphql/mutations/editRisk";

export const useRiskDetails = (onReloadChangelog: () => void) => {
  const { t } = useTranslation();
  const { riskItemId } = useParams();

  const {
    data: riskItemData,
    refetch: refetchRiskData,
    loading: riskItemDataLoading,
    error: riskItemError,
  } = useGraphQuery<RiskItemQuery, RiskItemQueryVariables>(
    riskItemQuery,
    {
      variables: { id: riskItemId! },
      errorPolicy: "none",
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  const [changeRiskItemStatus, { loading: changeRiskItemStatusLoading }] =
    useGraphMutation<
      ChangeRiskItemStatusMutation,
      ChangeRiskItemStatusMutationVariables
    >(
      changeRiskItemStatusMutation,
      {
        update: (_cache) => {
          refetchRiskData();

          // cache.evict({ id: "ROOT_QUERY", fieldName: "riskItems" });
          // cache.gc();
          onReloadChangelog();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Risks.riskItem"),
      })
    );

  const [doEditRisk] = useGraphMutation<
    EditRiskItemMutation,
    EditRiskItemMutationVariables
  >(
    editRiskItemMutation,
    {
      update: () => {
        refetchRiskData();
        onReloadChangelog();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Risks.riskItem"),
    })
  );

  return {
    riskItem: riskItemData?.riskItem as RiskItem | undefined,
    riskItemDataLoading,
    riskItemError,
    changeRiskItemStatus,
    changeRiskItemStatusLoading,
    doEditRisk,
  };
};
