import { LoadingButton } from "@mui/lab";
import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { EditIcon } from "components/Icons/EditIcon";
import { ItemTag } from "components/ItemTag";
import { OverflowTooltip } from "components/OverflowTooltip";
import {
  StatusOptionNew,
  StatusTagNew,
} from "components/StatusTag/StatusTagNew";
import { ChangeProductItemStatusModal } from "containers/Projects/components/ChangeProductItemStatusModal/ChangeProductItemStatusModal";
import { HeaderBackButton } from "containers/Projects/components/HeaderBackButton";
import { ProductItemHeaderContainer } from "containers/Projects/components/HeaderContainer";
import { ItemStatusOption, ProductType } from "generated/graphql";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { PermissionsGate } from "helpers/Permissions/PermissionsGate";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export type RiskEventDetailsHeaderProps = {
  title: string;
  dataLoading?: boolean;
  saveLoading?: boolean;
  statusOptionId: string;
  productType: ProductType;
  statusOptions: ItemStatusOption[];
  isCurrentUserProductItemOwner?: boolean;
  titleSuffix?: string;
  onStatusChange?: (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => void;
  onBack: () => void;
  onEdit: () => void;
};

export const RiskEventDetailsHeader: React.FC<RiskEventDetailsHeaderProps> = ({
  title,
  titleSuffix,
  dataLoading,
  saveLoading,
  statusOptionId,
  productType,
  statusOptions,
  isCurrentUserProductItemOwner,
  onStatusChange,
  onEdit,
  onBack,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { productInstanceId } = useParams();

  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState<ItemStatusOption>();

  const sortedStatusOptions = useMemo(() => {
    const sortedOptions = statusOptions.slice();
    sortedOptions.sort((op1, op2) => op1.displayOrder - op2.displayOrder);
    return sortedOptions;
  }, [statusOptions]);

  const triggerStatusChangeFlow = (
    newSelectedOption: StatusOptionNew<string>
  ) => {
    setNewStatus(
      sortedStatusOptions.find((option) => option.id === newSelectedOption.id)!
    );

    setChangeStatusModalOpen(true);
  };

  const handleStatusChange = (reasonId: string, remarks?: string) => {
    onStatusChange?.(newStatus!, reasonId, remarks);
    setChangeStatusModalOpen(false);
    setNewStatus(undefined);
  };

  return (
    <>
      <ChangeProductItemStatusModal
        open={changeStatusModalOpen}
        oldStatusName={
          sortedStatusOptions.find((option) => option.id === statusOptionId)
            ?.description || ""
        }
        newStatusName={newStatus?.description || ""}
        reasons={newStatus?.optionReasons.items || []}
        onClose={() => setChangeStatusModalOpen(false)}
        onSecondaryClick={() => setChangeStatusModalOpen(false)}
        onPrimaryClick={handleStatusChange}
      />
      <ProductItemHeaderContainer>
        {(isHeaderStuck?: boolean) => (
          <>
            <HeaderBackButton onBack={onBack} disabled={dataLoading} />
            {dataLoading ? (
              <Box ml={2}>
                <Skeleton variant="rectangular" animation="pulse" width={500} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" ml={2}>
                <ItemTag type={productType} />
                <Box display="flex" alignItems="center" mx={2}>
                  <OverflowTooltip
                    title={title}
                    typographyProps={{
                      variant: "h3",
                      color: theme.palette.grey[800],
                      maxWidth: "600px",
                    }}
                  />
                  {titleSuffix && (
                    <Typography
                      variant="h3"
                      fontWeight={400}
                      ml={0.75}
                      color={theme.palette.grey[800]}
                    >
                      {titleSuffix}
                    </Typography>
                  )}
                </Box>
                <StatusTagNew
                  size="small"
                  hideArrow
                  placement="bottom-start"
                  selectedOptionId={statusOptionId}
                  options={sortedStatusOptions.map((statusOption) => ({
                    id: statusOption.id,
                    label: statusOption.description,
                    style: statusOption.style,
                  }))}
                  disabled={!onStatusChange}
                  onChange={triggerStatusChangeFlow}
                />
              </Box>
            )}
            <PermissionsGate
              productInstanceId={productInstanceId}
              requiredPermissions={[PermissionEnum.Edit]}
              fallbackCheck={isCurrentUserProductItemOwner}
            >
              <Box ml="auto" mr={isHeaderStuck ? 3 : 0}>
                <LoadingButton
                  variant="outlined"
                  onClick={onEdit}
                  loading={saveLoading}
                  size="large"
                  data-testid="edit-btn"
                >
                  <EditIcon />
                  <Typography
                    variant="p2"
                    fontWeight={700}
                    color={theme.palette.grey[800]}
                    ml={1}
                  >
                    {t("common.buttons.edit")}
                  </Typography>
                </LoadingButton>
              </Box>
            </PermissionsGate>
          </>
        )}
      </ProductItemHeaderContainer>
    </>
  );
};
