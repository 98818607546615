import {
  Box,
  BoxProps,
  Collapse,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { CaretDownIcon } from "components/Icons/CaretDownIcon";
import { CaretUpIcon } from "components/Icons/CaretUpIcon";
import {
  ItemDataEntryInput,
  ItemDataSectionInput,
  SchemaFieldStatus,
  SchemaSection,
} from "generated/graphql";
import { useState, useRef, useEffect, useMemo, useContext } from "react";
import { Field } from "../Field/Field";
import { MetadataFooter } from "../MetadataFooter/MetadataFooter";
import { SchemaInterpretorContext } from "../SchemaInterpretor.context";
import { SectionHeader } from "./Section.styled";
import { SectionContainer } from "components/miscellaneous/SectionContainer";

export type SectionProps = {
  section: SchemaSection;
  sectionValues?: ItemDataSectionInput;
  editMode: boolean;
  isMainSection?: boolean;
  collapsible?: boolean;
  onSectionValuesChange: (newSectionData: ItemDataSectionInput) => void;
  onSectionValidityChange: (validity: boolean, sectionName: string) => void;
};

export const CollapsibleSectionContainer: React.FC<
  {
    collapsible?: boolean;
    title?: React.ReactNode;
    children: React.ReactNode;
    initialCollapseState?: boolean;
  } & Omit<BoxProps, "title">
> = ({
  collapsible,
  title,
  children,
  initialCollapseState = false,
  ...restContainerProps
}) => {
  const { liteVariant } = useContext(SchemaInterpretorContext);
  const [collapsed, setCollapsed] = useState(initialCollapseState);

  const toggleCollapsed = () => {
    setCollapsed((crtCollapsed) => !crtCollapsed);
  };

  return collapsible ? (
    <SectionContainer
      sx={{ paddingTop: collapsible ? 0 : "auto" }}
      liteVariant={liteVariant}
      {...restContainerProps}
    >
      {collapsible && (
        <SectionHeader collapsed={collapsed}>
          {typeof title === "string" ? (
            <Typography variant="h3" color="grey.900">
              {title}
            </Typography>
          ) : (
            title
          )}
          {collapsed ? (
            <CaretDownIcon
              onClick={toggleCollapsed}
              dataTestId="caret-down-btn"
              height="7.5px"
              width="15px"
            />
          ) : (
            <CaretUpIcon
              onClick={toggleCollapsed}
              dataTestId="caret-up-btn"
              height="7.5px"
              width="15px"
            />
          )}
        </SectionHeader>
      )}
      <Collapse in={!collapsed} sx={{ width: "100%" }}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {children}
        </Box>
      </Collapse>
    </SectionContainer>
  ) : (
    <SectionContainer liteVariant={liteVariant} {...restContainerProps}>
      {children}
    </SectionContainer>
  );
};

export const Section: React.FC<SectionProps> = ({
  section,
  sectionValues,
  editMode,
  isMainSection,
  collapsible = false,
  onSectionValuesChange,
  onSectionValidityChange,
}) => {
  const theme = useTheme();
  const { metadata, liteVariant } = useContext(SchemaInterpretorContext);
  const [sectionValidity, setSectionValidity] = useState(true);
  const fieldsValidity = useRef<{ [fieldId: string]: boolean }>({});

  const handleFieldValueChange = (newFieldValue: ItemDataEntryInput) => {
    onSectionValuesChange({
      name: section.name,
      entries: [
        ...(sectionValues?.entries?.filter(
          (fieldValue) => fieldValue.name !== newFieldValue.name
        ) || []),
        newFieldValue,
      ],
    });
  };

  const handleFieldValidationChange = (validity: boolean, fieldId: string) => {
    fieldsValidity.current[fieldId] = validity;

    setSectionValidity(
      !Object.values(fieldsValidity.current).some((value) => !value)
    );
  };

  const fields = useMemo(() => {
    return [...section.schemaFields.items]
      .filter((field) => field.status === SchemaFieldStatus.Active)
      .sort((field1, field2) => field1.displayOrder - field2.displayOrder);
  }, [section]);

  useEffect(() => {
    onSectionValidityChange(sectionValidity, section.id);
  }, [sectionValidity, section, onSectionValidityChange]);

  return (
    <CollapsibleSectionContainer collapsible={collapsible} title={section.name}>
      {!collapsible && (
        <Typography
          variant="h3"
          mb={liteVariant ? 1 : 3}
          color={theme.palette.grey[900]}
        >
          {section.displayText ?? section.name}
        </Typography>
      )}
      <Grid container spacing={3} overflow="hidden">
        {fields.map((field) => {
          // if a section contains more than 1 field (except "Title"), display label for fields
          const showFieldLabel =
            fields.filter((crtField) => crtField.name.toLowerCase() !== "title")
              .length > 1;
          const fieldValue = sectionValues?.entries?.find(
            (fieldValue) => fieldValue.name === field.name
          );

          // "Title" field should not be displayed in the editMode = false situation because it's part of the Header component.
          // However, it should be present when editMode = true.
          // This is a temporarry hack. Long term solution would be to add an <isVisible: 'readOnly' | 'editMode' | 'both'> flag on the SchemaField. TODO
          if (
            isMainSection &&
            !editMode &&
            fieldValue?.name.toLowerCase() === "title"
          ) {
            return null;
          }

          return (
            <Grid item xs={12} key={field.id}>
              <Field
                field={field}
                editMode={editMode}
                fieldValue={fieldValue}
                showLabel={showFieldLabel}
                onFieldValueChange={handleFieldValueChange}
                onFieldValidationChange={handleFieldValidationChange}
              />
            </Grid>
          );
        })}
        {isMainSection && !editMode && metadata && (
          <Grid item xs={12} key="metadata">
            <MetadataFooter />
          </Grid>
        )}
      </Grid>
    </CollapsibleSectionContainer>
  );
};
