import { Typography, TypographyProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AttachmentsDencity } from "../Attachments.decl";

export type AttachmentsTitleProps = {
  attachmentsCount?: number;
  dencity?: AttachmentsDencity;
} & TypographyProps;

export const AttachmentsTitle: React.FC<AttachmentsTitleProps> = ({
  attachmentsCount,
  dencity,
  ...restTypographyProps
}) => {
  const { t } = useTranslation();

  return (
    <Typography
      variant={dencity === AttachmentsDencity.Compact ? "h4" : "h3"}
      fontWeight={600}
      mb={dencity === AttachmentsDencity.Compact ? 2 : 4}
      {...restTypographyProps}
    >
      {t("Attachments.attachments")}{" "}
      {attachmentsCount ? `(${attachmentsCount})` : ""}
    </Typography>
  );
};
