import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import {
  Changelog,
  ChangelogPublicAPI,
} from "containers/Projects/components/Changelog/Changelog";
import { Comments } from "containers/Projects/components/Comments/Comments";
import {
  ExplorerContext,
  ExplorerState,
} from "containers/Projects/components/Explorer/Explorer.context";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import { CollapsibleSectionContainer } from "containers/Projects/components/SchemaInterpretor/Section/Section";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  AuthorizationWorkflowAudit,
  ItemStatusOption,
  ProductSchema,
  ProductType,
  User,
} from "generated/graphql";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { useHasAccess } from "hooks/useHasAccess";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { extendedTheme } from "theme/extendedTheme";
import { EWInstructionDetailsHeader } from "../../components/EWInstructionDetailsHeader";
import { NewAppPaths } from "helpers/paths/paths";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { useNavigateToRegisterPage } from "containers/Projects/hooks/useNavigateToRegisterPage";
import { NotifiedUsers } from "containers/Projects/components/NotifiedUsers/NotifiedUsers";
import { NotifiedUsersWidgetHeader } from "containers/Projects/components/NotifiedUsers/NotifiedUsersWidgetHeader";
import { IntercomEvents } from "constants/intercom";
import { useEWDetailsTrackingEvents } from "./hooks/useEWDetailsTrackingEvents";
import { useEarlyWarningDetails } from "./hooks/useEarlyWarningDetails";

export const EarlyWarningDetails = () => {
  const { productInstanceId, ewItemId } = useParams();
  const visitedRef = useRef<boolean>(false);
  const navigate = useNavigate();
  const handleNavigateToEWRegister = useNavigateToRegisterPage();
  const location = useLocation();
  const { authenticatedUser } = useContext(GlobalContext);
  const {
    changeExplorerEntities,
    clear: clearExplorerData,
    setLoading: setExplorerDataLoading,
  } = useContext(ExplorerContext);

  const { trackEWDetailsEvent } = useEWDetailsTrackingEvents();

  const [changelogApiRef, setChangelogApiRef] = useState<ChangelogPublicAPI>();

  const reloadChangelog = useCallback(() => {
    changelogApiRef?.reload();
  }, [changelogApiRef]);

  const {
    changeEWStatus,
    changeEWStatusLoading,
    doEditEarlyWarning,
    earlyWarning,
    earlyWarningError,
    earlyWarningLoading,
  } = useEarlyWarningDetails(reloadChangelog);

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    await doEditEarlyWarning({
      variables: {
        input: {
          id: ewItemId!,
          attachments: attachmentsUpdated ?? earlyWarning?.attachments,
        },
      },
    });
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
  } = useAttachments(
    ((earlyWarning?.attachments as Attachment[]) ?? []).filter(
      (attach) => attach.status === AttachmentStatus.Active
    ) || [],
    handleAttachmentsUpdated
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const handleChangeEWStataus = async (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => {
    await changeEWStatus({
      variables: {
        input: {
          id: earlyWarning!.id,
          newStatusId: newStatus.id,
          reasonId,
          remarks,
        },
      },
    });
  };

  const handleCommentAdded = (noOfMentions: number) => {
    reloadChangelog();
    earlyWarning &&
      trackEWDetailsEvent(IntercomEvents.AddedComment, earlyWarning, {
        Product: earlyWarning.productInstance.product.name,
        Mentions: noOfMentions,
      });
  };

  useEffect(() => {
    if (changelogApiRef) {
      reloadChangelog();
    }
  }, [changelogApiRef, reloadChangelog]);

  useEffect(() => {
    if (earlyWarning) {
      changeExplorerEntities({
        projectId: earlyWarning.productInstance.contract.project.id,
        contractId: earlyWarning.productInstance.contract.id,
        productId: earlyWarning.productInstance.product.id,
        productInstanceId: earlyWarning.productInstanceId,
      });
    }
  }, [changeExplorerEntities, earlyWarning]);

  useEffect(() => {
    if (location.state) {
      changeExplorerEntities(location.state as ExplorerState);
    } else {
      // state not preset, needs fetching
      setExplorerDataLoading(true);
    }
  }, [setExplorerDataLoading, location, changeExplorerEntities]);

  useEffect(() => {
    if (earlyWarning) {
      setExplorerDataLoading(false);
    }
  }, [setExplorerDataLoading, earlyWarning]);

  useEffect(() => {
    if (earlyWarningError && !earlyWarning) {
      clearExplorerData();
      navigate(NewAppPaths.authorized.NotFound);
    }
  }, [earlyWarningError, earlyWarning, navigate, clearExplorerData]);

  useEffect(() => {
    if (!visitedRef.current && earlyWarning) {
      trackEWDetailsEvent(IntercomEvents.ViewedEarlyWarning, earlyWarning);
      visitedRef.current = true;
    }
  }, [earlyWarning, trackEWDetailsEvent]);

  const isCurrentUserEWOwner = earlyWarning?.ownerId === authenticatedUser?.id;
  const canChangeStatus =
    useHasAccess(
      undefined,
      [PermissionEnum.ChangeStatus],
      productInstanceId ?? undefined
    ) || isCurrentUserEWOwner;
  const canManageAttachments =
    useHasAccess(
      undefined,
      [PermissionEnum.ManageAttachments],
      productInstanceId!
    ) || isCurrentUserEWOwner;

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        contractTimezone={earlyWarning?.productInstance.contract.timeZone}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <EWInstructionDetailsHeader
            title={earlyWarning?.title ?? ""}
            titleSuffix={`(${earlyWarning?.number})`}
            statusOptionId={earlyWarning?.statusOptionId || ""}
            dataLoading={earlyWarningLoading || changeEWStatusLoading}
            productType={ProductType.EarlyWarnings}
            statusOptions={
              (earlyWarning?.productInstance.statusCollection.statusOptions
                .items as ItemStatusOption[]) ?? []
            }
            isRecorded={!!earlyWarning?.offline}
            onStatusChange={canChangeStatus ? handleChangeEWStataus : undefined}
            onBack={handleNavigateToEWRegister}
          />
          <Box mt={3}>
            {earlyWarningLoading || !earlyWarning ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode={false}
                productItemType={ProductType.EarlyWarnings}
                schema={
                  earlyWarning.productInstance.productSchema as ProductSchema
                }
                authAudit={
                  (earlyWarning.authorizationWorkflowAudit as AuthorizationWorkflowAudit) ??
                  undefined
                }
                metadata={{
                  owner: earlyWarning.owner as User,
                  creator: earlyWarning.creator as User,
                  dateModified: earlyWarning.dateModified,
                  dateCreated: earlyWarning.dateCreated,
                  productItemId: earlyWarning.id!,
                  dateSent: earlyWarning.dateSent,
                  offline: earlyWarning.offline,
                }}
                schemaValues={earlyWarning.data}
                contractCurrency={
                  earlyWarning.productInstance.contract.valueCurrency ?? ""
                }
                contractTimezone={
                  earlyWarning.productInstance.contract.timeZone ?? ""
                }
                productInstanceId={earlyWarning.productInstanceId}
                onOwnerChange={reloadChangelog}
                mainColumnExtraWidgets={
                  <>
                    <Grid item xs={12} key="attachments" position="relative">
                      <SectionContainer>
                        <Attachments
                          editMode={canManageAttachments === true}
                          attachments={allAttachments}
                          timezone={
                            earlyWarning?.productInstance.contract.timeZone
                          }
                          onAttachmentsAdd={addAttachments}
                          onAttachmentRemove={removeAttachment}
                          onAttachmentUpdate={updateAttachment}
                          onAttachmentClick={handleAttachmentClick}
                        />
                      </SectionContainer>
                    </Grid>
                    <Grid item xs={12} key="comments" position="relative">
                      <SectionContainer>
                        <Comments
                          productType={ProductType.EarlyWarnings}
                          productItemId={ewItemId!}
                          productInstanceId={productInstanceId!}
                          timezone={
                            earlyWarning?.productInstance.contract.timeZone
                          }
                          onCommentAdded={handleCommentAdded}
                        />
                      </SectionContainer>
                    </Grid>
                  </>
                }
                secondaryColumnExtraWidgets={
                  <>
                    <Box width="100%" key="changelog" position="relative">
                      <CollapsibleSectionContainer
                        collapsible
                        title="Changelog"
                        maxHeight="600px"
                        overflow="auto"
                      >
                        <Changelog
                          productItemId={earlyWarning.id}
                          productType={ProductType.EarlyWarnings}
                          contractTimezone={
                            earlyWarning.productInstance.contract.timeZone ?? ""
                          }
                          productInstanceId={productInstanceId!}
                          ref={(apiRef) => {
                            setChangelogApiRef(apiRef!);
                          }}
                        />
                      </CollapsibleSectionContainer>
                    </Box>
                    {earlyWarning.notificationRecipients.length > 0 && (
                      <Box
                        width="100%"
                        key="notified-users"
                        position="relative"
                      >
                        <CollapsibleSectionContainer
                          collapsible
                          initialCollapseState={true}
                          title={
                            <NotifiedUsersWidgetHeader
                              recipientsCount={
                                earlyWarning.notificationRecipients.length
                              }
                            />
                          }
                        >
                          <NotifiedUsers
                            recipients={earlyWarning.notificationRecipients}
                          />
                        </CollapsibleSectionContainer>
                      </Box>
                    )}
                  </>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
