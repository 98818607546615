import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";
import { attachmentLiteFields } from "./attachmentLite.fragment";
import { productSchemaFields } from "./productSchema.fragment";
import { itemDataFields } from "./itemData.fragment";

export const earlyWarningItemFields = gql`
  ${creatorFields}
  ${attachmentLiteFields}
  ${productSchemaFields}
  ${itemDataFields}

  fragment EarlyWarningItemFields on EarlyWarningItem {
    id
    title
    severity
    data {
      ...ItemDataFields
    }
    number
    productInstanceId
    ownerId
    statusOptionId
    dateCreated
    creatorId
    creator {
      ...CreatorFields
    }
    #statusOption: ItemStatusOption!
    owner {
      ...CreatorFields
    }
    productInstance {
      id
      description
      numberingFormat
      productSchema {
        ...ProductSchemaFields
      }
      contract {
        id
        valueCurrency
        timeZone
      }
      product {
        id
        numberingFormat
      }
    }
    dateModified
    attachments {
      ...AttachmentLiteFields
    }
    #changeLog: ChangeLogItemList!
    #comments(limit: Int!, nextToken: String): CommentList!
    offline
    givenById
    dateSent
  }
`;
