import { gql } from "@apollo/client";
import { instructionItemBaseFields } from "graphql/fragments/instructionItem.fragment";
import { attachmentFields } from "../EarlyWarningDetails/EarlyWarningDetails.query";
import { productItemProductInstanceFields } from "../RiskDetails/RiskDetails.query";
import { authorizationWorkflowAuditFields } from "graphql/fragments/authorizationWorkflowAudit.fragment";
import { notificationRecipientFields } from "graphql/fragments/notificationRecipient.fragment";

export const instructionItemExtendedQuery = gql`
  ${instructionItemBaseFields}
  ${productItemProductInstanceFields}
  ${attachmentFields}
  ${authorizationWorkflowAuditFields}
  ${notificationRecipientFields}

  query instructionItemExtended($id: ID!) {
    instructionItem(id: $id) {
      ...InstructionItemBaseFields
      productInstance {
        ...ProductInstanceFields
      }
      attachments {
        ...AttachmentFields
      }
      compEvent {
        id
      }
      claim {
        id
      }
      variation {
        id
      }
      authorizationWorkflowAudit {
        ...AuthorizationWorkflowAuditFields # TODO: take only ID here and fetch authAudit only where necessary. Same in EW
      }
      notificationRecipients {
        ...NotificationRecipientFields
      }
    }
  }
`;
