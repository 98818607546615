import { Color, IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import { AuthorizationWorkflowAudit, ProductType } from "generated/graphql";
import { MetadataHeading } from "../../../../../components/SchemaInterpretor/MetadataHeading";
import { useTranslation } from "react-i18next";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { AuditTrailModal } from "components/Authorization/AuditTrailModal/AuditTrailModal";
import { IconContainer } from "components/IconContainer";
import { Path } from "phosphor-react";

type MetadataSenderHeadingProps = {
  authAudit?: AuthorizationWorkflowAudit;
  productItemType: ProductType;
};

export const MetadataSenderHeading: React.FC<MetadataSenderHeadingProps> = ({
  authAudit,
  productItemType,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { modalVisibility, toggleModalVisibility } = useBasicModal();

  return (
    <>
      {modalVisibility && authAudit && (
        <AuditTrailModal
          open={modalVisibility}
          onClose={toggleModalVisibility}
          authAuditId={authAudit.id}
        />
      )}
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <MetadataHeading>
          {t(
            `common.labels.${
              [ProductType.RisksRegister, ProductType.Events].includes(
                productItemType
              )
                ? "creator"
                : "sender"
            }`
          )}
        </MetadataHeading>
        {authAudit && (
          <Tooltip title={t("Authorizations.AuditTrailModal.title")}>
            <IconButton
              onClick={toggleModalVisibility}
              sx={{ padding: 0, pl: 1 }}
            >
              <IconContainer
                height="20px"
                width="20px"
                sx={{
                  backgroundColor: (
                    theme.palette.secondary as Partial<Color>
                  )[100],
                }}
              >
                <Path size={15} />
              </IconContainer>
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </>
  );
};
