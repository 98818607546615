export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
  BigInt: any;
  Double: any;
};

export type ActionAuthorizedDetails = EventDetails & {
  __typename?: "ActionAuthorizedDetails";
  actionName: Scalars["String"];
  authorizationAuditId?: Maybe<Scalars["ID"]>;
  productDetails?: Maybe<CompEventAuthorizationNotificationData>;
  title: Scalars["String"];
};

export type ActionPreventedDetails = EventDetails & {
  __typename?: "ActionPreventedDetails";
  actionName: Scalars["String"];
  authorizationAuditId?: Maybe<Scalars["ID"]>;
  productDetails?: Maybe<CompEventAuthorizationNotificationData>;
  title: Scalars["String"];
};

export type AddAuthorizationActionInput = {
  contractBindingTypeId?: InputMaybe<Scalars["ID"]>;
  productInstanceId: Scalars["ID"];
  productOutputActionId: Scalars["ID"];
  workflowId: Scalars["ID"];
};

export type AddAuthorizationWorkflowInput = {
  levels: Array<AuthorizationWorkflowLevelInput>;
  name: Scalars["String"];
  projectId: Scalars["ID"];
};

export type AddCommentInput = {
  content: Scalars["String"];
  itemId: Scalars["ID"];
  productInstanceId: Scalars["ID"];
  versionId?: InputMaybe<Scalars["ID"]>;
};

export type AddCompanyInput = {
  logo?: InputMaybe<Scalars["String"]>;
  physicalAddress?: InputMaybe<AddressInput>;
  postalAddress?: InputMaybe<AddressInput>;
  registeredName: Scalars["String"];
  registrationNumber?: InputMaybe<Scalars["String"]>;
  tradingName: Scalars["String"];
  type: CompanyType;
  vatRegistrationNumber?: InputMaybe<Scalars["String"]>;
};

export type AddCompanyLookupCollectionInput = {
  companyId: Scalars["ID"];
  name: Scalars["String"];
  optionsCharLengthLimit?: InputMaybe<Scalars["Int"]>;
  optionsLockedForUsers: Scalars["Boolean"];
  usage: CompanyLookupCollectionUsage;
};

export type AddContractBindingInput = {
  companyId: Scalars["ID"];
  contractBindingTypeId: Scalars["ID"];
  contractId: Scalars["ID"];
  defaultCompany: Scalars["Boolean"];
  isCompanyBilled: Scalars["Boolean"];
  representativeId?: InputMaybe<Scalars["ID"]>;
};

export type AddContractInput = {
  contractTypeId: Scalars["ID"];
  coordinatesLatitude?: InputMaybe<Scalars["Float"]>;
  coordinatesLongitude?: InputMaybe<Scalars["Float"]>;
  country: Scalars["String"];
  endDate?: InputMaybe<Scalars["AWSDate"]>;
  friendlyName: Scalars["String"];
  keyDates?: InputMaybe<Array<AddContractKeyDateInput>>;
  name: Scalars["String"];
  number: Scalars["String"];
  projectId: Scalars["ID"];
  province?: InputMaybe<Scalars["String"]>;
  sections?: InputMaybe<Array<AddContractSectionInput>>;
  startDate?: InputMaybe<Scalars["AWSDate"]>;
  timeZone: Scalars["String"];
  value: Scalars["Float"];
  valueCurrency: Scalars["String"];
};

export type AddContractKeyDateInput = {
  conditionToBeMet: Scalars["String"];
  keyDate: Scalars["AWSDate"];
  number?: InputMaybe<Scalars["Int"]>;
};

export type AddContractSectionInput = {
  completionDate: Scalars["AWSDate"];
  description: Scalars["String"];
  number?: InputMaybe<Scalars["Int"]>;
};

export type AddDailyDiaryDelayRecordInput = {
  areaOptionId?: InputMaybe<Scalars["ID"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dailyDiaryId?: InputMaybe<Scalars["ID"]>;
  date?: InputMaybe<Scalars["AWSDate"]>;
  delayTypeOptionId: Scalars["ID"];
  description: Scalars["String"];
  disciplineOptionId?: InputMaybe<Scalars["ID"]>;
  duration?: InputMaybe<Scalars["AWSTime"]>;
  productInstanceId?: InputMaybe<Scalars["ID"]>;
  shiftOptionId?: InputMaybe<Scalars["ID"]>;
  teamOptionId?: InputMaybe<Scalars["ID"]>;
};

export type AddDailyDiaryGeneralRecordInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dailyDiaryId?: InputMaybe<Scalars["ID"]>;
  date?: InputMaybe<Scalars["AWSDate"]>;
  description: Scalars["String"];
  productInstanceId?: InputMaybe<Scalars["ID"]>;
};

export type AddDailyDiaryHseRecordInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dailyDiaryId?: InputMaybe<Scalars["ID"]>;
  date?: InputMaybe<Scalars["AWSDate"]>;
  description: Scalars["String"];
  hseTypeOptionId: Scalars["ID"];
  productInstanceId?: InputMaybe<Scalars["ID"]>;
};

export type AddDailyDiaryPresetInput = {
  name: Scalars["String"];
  sections: Array<DailyDiaryPresetSectionInput>;
};

export type AddDailyDiaryResourceRecordInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  companyOptionId?: InputMaybe<Scalars["ID"]>;
  dailyDiaryId?: InputMaybe<Scalars["ID"]>;
  date?: InputMaybe<Scalars["AWSDate"]>;
  hours: Scalars["String"];
  productInstanceId?: InputMaybe<Scalars["ID"]>;
  quantity: Scalars["Int"];
  remarks?: InputMaybe<Scalars["String"]>;
  resourceOptionId: Scalars["ID"];
  shiftOptionId?: InputMaybe<Scalars["ID"]>;
  teamOptionId?: InputMaybe<Scalars["ID"]>;
};

export type AddDailyDiaryWeatherRecordInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  conditionsOptionId: Scalars["ID"];
  dailyDiaryId?: InputMaybe<Scalars["ID"]>;
  date?: InputMaybe<Scalars["AWSDate"]>;
  humidity?: InputMaybe<Scalars["Float"]>;
  productInstanceId?: InputMaybe<Scalars["ID"]>;
  rainFall?: InputMaybe<Scalars["Float"]>;
  temperature?: InputMaybe<Scalars["Float"]>;
  timeOfMeasurement: Scalars["AWSTime"];
  windSpeed?: InputMaybe<Scalars["Float"]>;
};

export type AddDailyDiaryWorkRecordInput = {
  activityOptionId?: InputMaybe<Scalars["ID"]>;
  amount?: InputMaybe<Scalars["Float"]>;
  areaOptionId?: InputMaybe<Scalars["ID"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dailyDiaryId?: InputMaybe<Scalars["ID"]>;
  date?: InputMaybe<Scalars["AWSDate"]>;
  description?: InputMaybe<Scalars["String"]>;
  disciplineOptionId?: InputMaybe<Scalars["ID"]>;
  productInstanceId?: InputMaybe<Scalars["ID"]>;
  shiftOptionId?: InputMaybe<Scalars["ID"]>;
  teamOptionId?: InputMaybe<Scalars["ID"]>;
  unitOfMeasurementOptionId?: InputMaybe<Scalars["ID"]>;
  workTypeOptionId?: InputMaybe<Scalars["ID"]>;
};

export type AddDocumentTemplateInput = {
  content: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
};

export type AddDocumentTemplateRouteInput = {
  clause?: InputMaybe<Scalars["String"]>;
  contractTypeId: Scalars["ID"];
  defaultNumbering?: InputMaybe<Scalars["String"]>;
  documentTemplateId: Scalars["ID"];
  originatingPartyId: Scalars["ID"];
  productOutputActionId: Scalars["ID"];
  targetPartyId: Scalars["ID"];
};

export type AddDocumentTemplateRouteOverrideInput = {
  clause?: InputMaybe<Scalars["String"]>;
  contractId: Scalars["ID"];
  defaultNumbering?: InputMaybe<Scalars["String"]>;
  documentTemplateId: Scalars["ID"];
  originatingPartyId: Scalars["ID"];
  productOutputActionId: Scalars["ID"];
  targetPartyId: Scalars["ID"];
};

export type AddDraftAssessmentInput = {
  attachments: Array<AttachmentInput>;
  compEventId: Scalars["ID"];
  details: Scalars["String"];
  keyDatesChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
};

export type AddDraftClaimAgreementInput = {
  attachments: Array<AttachmentInput>;
  claimId: Scalars["ID"];
  details: Scalars["String"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
};

export type AddDraftClaimDeterminationInput = {
  attachments: Array<AttachmentInput>;
  claimId: Scalars["ID"];
  details: Scalars["String"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
};

export type AddDraftDetailedClaimInput = {
  attachments: Array<AttachmentInput>;
  claimId: Scalars["ID"];
  details: Scalars["String"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
};

export type AddDraftQuotationInput = {
  attachments: Array<AttachmentInput>;
  compEventId: Scalars["ID"];
  details: Scalars["String"];
  keyDatesChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
};

export type AddDraftVariationAgreementInput = {
  attachments: Array<AttachmentInput>;
  details: Scalars["String"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type AddDraftVariationDeterminationInput = {
  attachments: Array<AttachmentInput>;
  details: Scalars["String"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type AddDraftVariationProposalInput = {
  attachments: Array<AttachmentInput>;
  details: Scalars["String"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type AddEffectOptionInput = {
  effectPresetId: Scalars["ID"];
  estimationType: EffectEstimationType;
  label: Scalars["String"];
  unit: Scalars["String"];
};

export type AddEffectPresetInput = {
  contractTypeId: Scalars["ID"];
  isDefault: Scalars["Boolean"];
  name: Scalars["String"];
};

export type AddEmailTemplateInput = {
  body: Scalars["String"];
  type: Scalars["String"];
};

export type AddEventItemInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  data: ItemDataInput;
  productInstanceId: Scalars["ID"];
};

export type AddFieldTypeInput = {
  description: Scalars["String"];
  shortCode: FieldTypeEnum;
};

export type AddItemStatusCollectionInput = {
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  productId: Scalars["ID"];
};

export type AddItemStatusOptionInput = {
  description: Scalars["String"];
  displayOrder: Scalars["Int"];
  equatesToRemoved: Scalars["Boolean"];
  statusCollectionId: Scalars["ID"];
  style: ItemStatusOptionStyles;
};

export type AddItemStatusOptionReasonInput = {
  description: Scalars["String"];
  displayOrder: Scalars["Int"];
  statusOptionId: Scalars["ID"];
};

export type AddLookupCollectionInput = {
  name: Scalars["String"];
  optionsCharLengthLimit?: InputMaybe<Scalars["Int"]>;
  optionsLockedForUsers: Scalars["Boolean"];
};

export type AddLookupOptionInput = {
  collectionId: Scalars["ID"];
  displayOrder: Scalars["Int"];
  value: Scalars["String"];
};

export type AddNewClaimTypeOverrideInput = {
  clause: Scalars["String"];
  contractId: Scalars["ID"];
  description: Scalars["String"];
};

export type AddProductInstanceInput = {
  contractId: Scalars["ID"];
  description: Scalars["String"];
  extraConfig?: InputMaybe<ExtraConfigInput>;
  numberingFormat: Scalars["String"];
  productId: Scalars["ID"];
  productSchemaId: Scalars["ID"];
  soloModeSupported: Scalars["Boolean"];
  statusCollectionId: Scalars["ID"];
};

export type AddProductRoleActionInput = {
  name: Scalars["String"];
};

export type AddProductRoleInput = {
  actionIds: Array<Scalars["ID"]>;
  name: Scalars["String"];
  productId: Scalars["ID"];
};

export type AddProductSchemaInput = {
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  productId: Scalars["ID"];
};

export type AddProductUserRoleInput = {
  productInstanceId: Scalars["ID"];
  productRoleId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type AddProjectInput = {
  billingCompanyId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
  friendlyName: Scalars["String"];
  name: Scalars["String"];
  number?: InputMaybe<Scalars["String"]>;
  sector: ProjectSector;
};

export type AddRiskItemInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  data: ItemDataInput;
  productInstanceId: Scalars["ID"];
};

export type AddSchemaFieldInput = {
  complexTypeItemId?: InputMaybe<Scalars["ID"]>;
  displayOrder: Scalars["Int"];
  displayText: Scalars["String"];
  fieldTypeId: Scalars["ID"];
  isRequired: Scalars["Boolean"];
  name: Scalars["String"];
  schemaSectionId: Scalars["ID"];
};

export type AddSchemaSectionInput = {
  columnPlacement: ColumnPlacementType;
  displayOrder: Scalars["Int"];
  displayText: Scalars["String"];
  name: Scalars["String"];
  productSchemaId: Scalars["ID"];
};

export type AddSeverityPresetInput = {
  consequenceLabel: Scalars["String"];
  consequenceSets: Array<Scalars["String"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  likelihoodLabel: Scalars["String"];
  likelihoodSets: Array<Scalars["String"]>;
  name: Scalars["String"];
};

export type AddSeverityRatingInput = {
  colour: Scalars["String"];
  label: Scalars["String"];
  lowerRange: Scalars["Int"];
  name: Scalars["String"];
  severityPresetId: Scalars["ID"];
  upperRange: Scalars["Int"];
};

export type AddTimePeriodOverrideInput = {
  contractId: Scalars["ID"];
  duration: Scalars["Int"];
  timePeriodId: Scalars["ID"];
};

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  provinceState?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  country: Scalars["String"];
  line1?: InputMaybe<Scalars["String"]>;
  line2?: InputMaybe<Scalars["String"]>;
  provinceState?: InputMaybe<Scalars["String"]>;
};

export type AdminResetPasswordInput = {
  password: Scalars["String"];
  username: Scalars["String"];
};

export type AdminResetPasswordResponse = {
  __typename?: "AdminResetPasswordResponse";
  success: Scalars["Boolean"];
};

export type Attachment = {
  __typename?: "Attachment";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  description?: Maybe<Scalars["String"]>;
  fileName: Scalars["String"];
  fileSize: Scalars["Int"];
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  locked: Scalars["Boolean"];
  mimeType: Scalars["String"];
  status: AttachmentStatus;
};

export type AttachmentAddedDetails = EventDetails & {
  __typename?: "AttachmentAddedDetails";
  attachments: Array<ItemAttachmentDataDetails>;
  title: Scalars["String"];
};

export type AttachmentDescriptionAddedDetails = EventDetails & {
  __typename?: "AttachmentDescriptionAddedDetails";
  attachmentChanges: Array<ItemAttachmentChangeDetails>;
  title: Scalars["String"];
};

export type AttachmentDescriptionEditedDetails = EventDetails & {
  __typename?: "AttachmentDescriptionEditedDetails";
  attachmentChanges: Array<ItemAttachmentChangeDetails>;
  title: Scalars["String"];
};

export type AttachmentInput = {
  description?: InputMaybe<Scalars["String"]>;
  fileName: Scalars["String"];
  fileUrl: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  mimeType: Scalars["String"];
};

export type AttachmentPreSignedUrl = {
  __typename?: "AttachmentPreSignedUrl";
  fileName: Scalars["String"];
  fileUrl: Scalars["String"];
};

export type AttachmentRemovedDetails = EventDetails & {
  __typename?: "AttachmentRemovedDetails";
  attachments: Array<ItemAttachmentDataDetails>;
  title: Scalars["String"];
};

export enum AttachmentStatus {
  Active = "Active",
  Removed = "Removed",
}

export type AuthenticationData = {
  __typename?: "AuthenticationData";
  intercomIdentityHash: Scalars["String"];
  isAdmin: Scalars["Boolean"];
  user: User;
};

export enum AuthorizationActionStatus {
  Approved = "Approved",
  Denied = "Denied",
  Pending = "Pending",
}

export type AuthorizationRequiredDetails = EventDetails & {
  __typename?: "AuthorizationRequiredDetails";
  actionName: Scalars["String"];
  authorizationAuditId?: Maybe<Scalars["ID"]>;
  initiatorName: Scalars["String"];
  productDetails?: Maybe<CompEventAuthorizationNotificationData>;
  title: Scalars["String"];
};

export type AuthorizationWorkflow = {
  __typename?: "AuthorizationWorkflow";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  levels: Array<AuthorizationWorkflowLevel>;
  modifiedBy: User;
  modifiedById: Scalars["ID"];
  name: Scalars["String"];
  project: Project;
  projectId: Scalars["ID"];
  status: AuthorizationWorkflowStatus;
};

export type AuthorizationWorkflowActionMapping = {
  __typename?: "AuthorizationWorkflowActionMapping";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  modifiedBy: User;
  modifiedById: Scalars["ID"];
  party?: Maybe<ContractBindingType>;
  partyId?: Maybe<Scalars["ID"]>;
  productInstance: ProductInstance;
  productInstanceId: Scalars["ID"];
  productOutputAction: ProductOutputAction;
  productOutputActionId: Scalars["ID"];
  status: AuthorizationWorkflowActionMappingStatus;
  workflow: AuthorizationWorkflow;
  workflowId: Scalars["ID"];
};

export enum AuthorizationWorkflowActionMappingStatus {
  Active = "Active",
  Removed = "Removed",
}

export type AuthorizationWorkflowActionMappingsList = {
  __typename?: "AuthorizationWorkflowActionMappingsList";
  items: Array<AuthorizationWorkflowActionMapping>;
};

export type AuthorizationWorkflowAudit = {
  __typename?: "AuthorizationWorkflowAudit";
  action: AuthorizationWorkflowActionMapping;
  actionId: Scalars["ID"];
  auditTrails?: Maybe<Array<AuthorizationWorkflowAuditTrail>>;
  dateCreated: Scalars["AWSDateTime"];
  dateInitiated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  initiatedBy: User;
  initiatedById: Scalars["ID"];
  regardingItemHistoryId?: Maybe<Scalars["ID"]>;
  regardingProductItem: ProductItem;
  regardingProductItemId: Scalars["ID"];
  regardingProductType: ProductType;
  status: AuthorizationWorkflowAuditStatus;
  workflow: AuthorizationWorkflow;
  workflowId: Scalars["ID"];
};

export type AuthorizationWorkflowAuditList = {
  __typename?: "AuthorizationWorkflowAuditList";
  items: Array<AuthorizationWorkflowAudit>;
  nextToken?: Maybe<Scalars["String"]>;
};

export enum AuthorizationWorkflowAuditStatus {
  Authorized = "Authorized",
  Denied = "Denied",
  InProgress = "InProgress",
  Removed = "Removed",
}

export type AuthorizationWorkflowAuditTrail = {
  __typename?: "AuthorizationWorkflowAuditTrail";
  action: AuthorizationWorkflowActionMapping;
  actionId: Scalars["ID"];
  audit: AuthorizationWorkflowAudit;
  auditId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  denialReason?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  productInstance?: Maybe<ProductInstance>;
  productInstanceId: Scalars["ID"];
  regardingItemHistoryId?: Maybe<Scalars["ID"]>;
  regardingProductItemId: Scalars["ID"];
  regardingProductType: ProductType;
  status: AuthorizationActionStatus;
};

export type AuthorizationWorkflowAuditTrails = {
  __typename?: "AuthorizationWorkflowAuditTrails";
  items?: Maybe<Array<Maybe<AuthorizationWorkflowAuditTrail>>>;
};

export type AuthorizationWorkflowLevel = {
  __typename?: "AuthorizationWorkflowLevel";
  mode: AuthorizationWorkflowLevelMode;
  sequence: Scalars["Int"];
  userIds: Array<Scalars["ID"]>;
  users: UserList;
};

export type AuthorizationWorkflowLevelInput = {
  mode: AuthorizationWorkflowLevelMode;
  sequence: Scalars["Int"];
  userIds: Array<Scalars["ID"]>;
};

export enum AuthorizationWorkflowLevelMode {
  AnyoneCanAuthorize = "AnyoneCanAuthorize",
  EveryoneMustAuthorize = "EveryoneMustAuthorize",
}

export type AuthorizationWorkflowList = {
  __typename?: "AuthorizationWorkflowList";
  items?: Maybe<Array<AuthorizationWorkflow>>;
};

export enum AuthorizationWorkflowStatus {
  Active = "Active",
  Removed = "Removed",
}

export enum AutoRole {
  Contributor = "Contributor",
  None = "None",
  Viewer = "Viewer",
}

export type BulkUserRoleAssignmentInput = {
  roleAssignment: Array<UserRoleAssignmentInput>;
  userIds: Array<Scalars["ID"]>;
};

export type ChangeEarlyWarningItemStatusInput = {
  id: Scalars["ID"];
  newStatusId: Scalars["ID"];
  reasonId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type ChangeEventItemStatusInput = {
  id: Scalars["ID"];
  newStatusId: Scalars["ID"];
  reasonId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type ChangeInstructionItemStatusInput = {
  id: Scalars["ID"];
  newStatusId: Scalars["ID"];
  reasonId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type ChangeLogItem = {
  __typename?: "ChangeLogItem";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  details: EventDetails;
  id: Scalars["ID"];
  itemId: Scalars["ID"];
};

export type ChangeLogItemList = {
  __typename?: "ChangeLogItemList";
  items: Array<ChangeLogItem>;
};

export type ChangeRiskItemStatusInput = {
  id: Scalars["ID"];
  newStatusId: Scalars["ID"];
  reasonId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type ClaimAction = {
  __typename?: "ClaimAction";
  actionMapping?: Maybe<AuthorizationWorkflowActionMapping>;
  type: ClaimActionType;
};

export type ClaimActionInput = {
  claimId?: InputMaybe<Scalars["ID"]>;
  productInstanceId?: InputMaybe<Scalars["ID"]>;
  regardingId?: InputMaybe<Scalars["ID"]>;
  regardingType?: InputMaybe<ClaimRegardingType>;
};

export type ClaimActionList = {
  __typename?: "ClaimActionList";
  items: Array<ClaimAction>;
};

export enum ClaimActionType {
  DenyRequestForMoreTime = "DenyRequestForMoreTime",
  DisagreeWithNoticeErrorAgreement = "DisagreeWithNoticeErrorAgreement",
  DisagreeWithNoticeErrorDetermination = "DisagreeWithNoticeErrorDetermination",
  GrantRequestForMoreTime = "GrantRequestForMoreTime",
  NotifyAgreementClaim = "NotifyAgreementClaim",
  NotifyAgreementClaimNotReached = "NotifyAgreementClaimNotReached",
  NotifyClaim = "NotifyClaim",
  NotifyClaimNoticeInvalid = "NotifyClaimNoticeInvalid",
  NotifyClaimNoticeValidityHasLapsed = "NotifyClaimNoticeValidityHasLapsed",
  NotifyDeterminationClaim = "NotifyDeterminationClaim",
  NotifyDisagreementWithClaimNoticesDeemedContinuingValidity = "NotifyDisagreementWithClaimNoticesDeemedContinuingValidity",
  NotifyDisagreementWithClaimNoticesDeemedValidity = "NotifyDisagreementWithClaimNoticesDeemedValidity",
  NotifyErrorFoundAgreement = "NotifyErrorFoundAgreement",
  NotifyErrorFoundDetermination = "NotifyErrorFoundDetermination",
  ProvideFurtherParticulars = "ProvideFurtherParticulars",
  RequestFurtherParticulars = "RequestFurtherParticulars",
  RequestMoreTime = "RequestMoreTime",
  RespondRegardingDetailedClaim = "RespondRegardingDetailedClaim",
  SubmitFinalDetailedClaim = "SubmitFinalDetailedClaim",
  SubmitInterimDetailedClaim = "SubmitInterimDetailedClaim",
}

export type ClaimAgreement = {
  __typename?: "ClaimAgreement";
  attachments: Array<Attachment>;
  claimId: Scalars["ID"];
  creatorId: Scalars["ID"];
  currency: Scalars["String"];
  dateCreated: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<DetailedClaimTimeChange>;
  time: Scalars["Int"];
};

export type ClaimAgreementNoticePrompt = {
  __typename?: "ClaimAgreementNoticePrompt";
  draftClaimAgreements: Array<DraftClaimAgreement>;
};

export type ClaimAgreementNoticePromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimAgreementNoticeRecordedDetails = EventDetails & {
  __typename?: "ClaimAgreementNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimAgreementNoticeSentDetails = EventDetails & {
  __typename?: "ClaimAgreementNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimDetailsExplorerItem =
  | ClaimAgreement
  | ClaimDetermination
  | DetailedClaim;

export type ClaimDetailsExplorerItemList = {
  __typename?: "ClaimDetailsExplorerItemList";
  items: Array<ClaimDetailsExplorerItem>;
};

export type ClaimDetailsResponsePrompt = {
  __typename?: "ClaimDetailsResponsePrompt";
  details: Scalars["String"];
  pendingDetailedClaims: Array<DetailedClaim>;
};

export type ClaimDetailsResponsePromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimDetailsResponseRecordedDetails = EventDetails & {
  __typename?: "ClaimDetailsResponseRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimDetailsResponseSentDetails = EventDetails & {
  __typename?: "ClaimDetailsResponseSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimDetermination = {
  __typename?: "ClaimDetermination";
  attachments: Array<Attachment>;
  claimId: Scalars["ID"];
  creatorId: Scalars["ID"];
  currency: Scalars["String"];
  dateCreated: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<DetailedClaimTimeChange>;
  time: Scalars["Int"];
};

export type ClaimDeterminationNoticePrompt = {
  __typename?: "ClaimDeterminationNoticePrompt";
  draftClaimDeterminations: Array<DraftClaimDetermination>;
};

export type ClaimDeterminationNoticePromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimDeterminationNoticeRecordedDetails = EventDetails & {
  __typename?: "ClaimDeterminationNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimDeterminationNoticeSentDetails = EventDetails & {
  __typename?: "ClaimDeterminationNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimErrorAgreementDisagreementPrompt = {
  __typename?: "ClaimErrorAgreementDisagreementPrompt";
  remarks: Scalars["String"];
};

export type ClaimErrorAgreementDisagreementPromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimErrorAgreementDisagreementRecordedDetails = EventDetails & {
  __typename?: "ClaimErrorAgreementDisagreementRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimErrorAgreementDisagreementSentDetails = EventDetails & {
  __typename?: "ClaimErrorAgreementDisagreementSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimErrorAgreementNoticePrompt = {
  __typename?: "ClaimErrorAgreementNoticePrompt";
  details: Scalars["String"];
};

export type ClaimErrorAgreementNoticePromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimErrorAgreementNoticeRecordedDetails = EventDetails & {
  __typename?: "ClaimErrorAgreementNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimErrorAgreementNoticeSentDetails = EventDetails & {
  __typename?: "ClaimErrorAgreementNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimErrorDeterminationDisagreementPrompt = {
  __typename?: "ClaimErrorDeterminationDisagreementPrompt";
  remarks: Scalars["String"];
};

export type ClaimErrorDeterminationDisagreementPromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimErrorDeterminationDisagreementRecordedDetails =
  EventDetails & {
    __typename?: "ClaimErrorDeterminationDisagreementRecordedDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    regardingType: ClaimRegardingType;
    title: Scalars["String"];
  };

export type ClaimErrorDeterminationDisagreementSentDetails = EventDetails & {
  __typename?: "ClaimErrorDeterminationDisagreementSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimErrorDeterminationNoticePrompt = {
  __typename?: "ClaimErrorDeterminationNoticePrompt";
  details: Scalars["String"];
};

export type ClaimErrorDeterminationNoticePromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimErrorDeterminationNoticeRecordedDetails = EventDetails & {
  __typename?: "ClaimErrorDeterminationNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimErrorDeterminationNoticeSentDetails = EventDetails & {
  __typename?: "ClaimErrorDeterminationNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimFinalClaimDetailsPrompt = {
  __typename?: "ClaimFinalClaimDetailsPrompt";
  draftDetailedClaims: Array<DraftDetailedClaim>;
};

export type ClaimFinalClaimDetailsPromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimFinalClaimDetailsRecordedDetails = EventDetails & {
  __typename?: "ClaimFinalClaimDetailsRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimFinalClaimDetailsSentDetails = EventDetails & {
  __typename?: "ClaimFinalClaimDetailsSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimFurtherParticularsRequestPrompt = {
  __typename?: "ClaimFurtherParticularsRequestPrompt";
  reasons: Scalars["String"];
};

export type ClaimFurtherParticularsRequestPromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimFurtherParticularsRequestRecordedDetails = EventDetails & {
  __typename?: "ClaimFurtherParticularsRequestRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimFurtherParticularsRequestSentDetails = EventDetails & {
  __typename?: "ClaimFurtherParticularsRequestSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimFurtherParticularsSubmissionPrompt = {
  __typename?: "ClaimFurtherParticularsSubmissionPrompt";
  details: Scalars["String"];
};

export type ClaimFurtherParticularsSubmissionPromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimFurtherParticularsSubmissionRecordedDetails = EventDetails & {
  __typename?: "ClaimFurtherParticularsSubmissionRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimFurtherParticularsSubmissionSentDetails = EventDetails & {
  __typename?: "ClaimFurtherParticularsSubmissionSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimHistoryAgreementAnswers = {
  __typename?: "ClaimHistoryAgreementAnswers";
  agreement?: Maybe<ClaimAgreement>;
  agreementId?: Maybe<Scalars["ID"]>;
  draftAgreement?: Maybe<DraftClaimAgreement>;
  draftAgreementId?: Maybe<Scalars["ID"]>;
};

export type ClaimHistoryAnswers =
  | ClaimHistoryAgreementAnswers
  | ClaimHistoryClaimDetailsResponseAnswers
  | ClaimHistoryDeterminationAnswers
  | ClaimHistoryErrorAgreementDisagreementAnswers
  | ClaimHistoryErrorAgreementNoticeAnswers
  | ClaimHistoryErrorDeterminationDisagreementAnswers
  | ClaimHistoryErrorDeterminationNoticeAnswers
  | ClaimHistoryFinalDetailsAnswers
  | ClaimHistoryFurtherParticularsRequestAnswers
  | ClaimHistoryFurtherParticularsSubmissionAnswers
  | ClaimHistoryInterimDetailsAnswers
  | ClaimHistoryNoAgreementReachedAnswers
  | ClaimHistoryNoticeAnswers
  | ClaimHistoryNoticeInvalidNoticeAnswers
  | ClaimHistoryNoticeValidityLapsedAnswers;

export type ClaimHistoryClaimDetailsResponseAnswers = {
  __typename?: "ClaimHistoryClaimDetailsResponseAnswers";
  detailedClaim: DetailedClaim;
  detailedClaimId: Scalars["ID"];
  details: Scalars["String"];
};

export type ClaimHistoryDeterminationAnswers = {
  __typename?: "ClaimHistoryDeterminationAnswers";
  determination?: Maybe<ClaimDetermination>;
  determinationId?: Maybe<Scalars["ID"]>;
  draftDetermination?: Maybe<DraftClaimDetermination>;
  draftDeterminationId?: Maybe<Scalars["ID"]>;
};

export type ClaimHistoryErrorAgreementDisagreementAnswers = {
  __typename?: "ClaimHistoryErrorAgreementDisagreementAnswers";
  remarks: Scalars["String"];
};

export type ClaimHistoryErrorAgreementNoticeAnswers = {
  __typename?: "ClaimHistoryErrorAgreementNoticeAnswers";
  details: Scalars["String"];
};

export type ClaimHistoryErrorDeterminationDisagreementAnswers = {
  __typename?: "ClaimHistoryErrorDeterminationDisagreementAnswers";
  remarks: Scalars["String"];
};

export type ClaimHistoryErrorDeterminationNoticeAnswers = {
  __typename?: "ClaimHistoryErrorDeterminationNoticeAnswers";
  details: Scalars["String"];
};

export type ClaimHistoryFinalDetailsAnswers = {
  __typename?: "ClaimHistoryFinalDetailsAnswers";
  detailedClaim: DetailedClaim;
  detailedClaimId: Scalars["ID"];
};

export type ClaimHistoryFurtherParticularsRequestAnswers = {
  __typename?: "ClaimHistoryFurtherParticularsRequestAnswers";
  reasons: Scalars["String"];
};

export type ClaimHistoryFurtherParticularsSubmissionAnswers = {
  __typename?: "ClaimHistoryFurtherParticularsSubmissionAnswers";
  details: Scalars["String"];
};

export type ClaimHistoryInterimDetailsAnswers = {
  __typename?: "ClaimHistoryInterimDetailsAnswers";
  detailedClaim: DetailedClaim;
  detailedClaimId: Scalars["ID"];
};

export type ClaimHistoryItem = {
  __typename?: "ClaimHistoryItem";
  actionNumber: Scalars["String"];
  actionType: ClaimActionType;
  answers?: Maybe<ClaimHistoryAnswers>;
  attachments: Array<Attachment>;
  authorizationWorkflowAudit?: Maybe<AuthorizationWorkflowAudit>;
  claimId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateSent: Scalars["AWSDateTime"];
  daysLate: Scalars["Int"];
  dueDate?: Maybe<Scalars["AWSDate"]>;
  id: Scalars["ID"];
  isIncomplete?: Maybe<Scalars["Boolean"]>;
  offline: Scalars["Boolean"];
  recipients: Array<ClaimRecipient>;
  sentByParty?: Maybe<ContractBindingType>;
  sentByPartyId?: Maybe<Scalars["ID"]>;
  sentByUser: User;
  sentByUserId: Scalars["ID"];
};

export type ClaimHistoryItemList = {
  __typename?: "ClaimHistoryItemList";
  items: Array<ClaimHistoryItem>;
};

export type ClaimHistoryNoAgreementReachedAnswers = {
  __typename?: "ClaimHistoryNoAgreementReachedAnswers";
  remarks: Scalars["String"];
};

export type ClaimHistoryNoticeAnswers = {
  __typename?: "ClaimHistoryNoticeAnswers";
  advanceWarning?: Maybe<EarlyWarningItem>;
  advanceWarningId?: Maybe<Scalars["ID"]>;
  claimType: ClaimTypeInfo;
  claimTypeId: Scalars["ID"];
};

export type ClaimHistoryNoticeInvalidNoticeAnswers = {
  __typename?: "ClaimHistoryNoticeInvalidNoticeAnswers";
  reasons: Scalars["String"];
};

export type ClaimHistoryNoticeValidityLapsedAnswers = {
  __typename?: "ClaimHistoryNoticeValidityLapsedAnswers";
  remarks: Scalars["String"];
};

export type ClaimInterimClaimDetailsPrompt = {
  __typename?: "ClaimInterimClaimDetailsPrompt";
  draftDetailedClaims: Array<DraftDetailedClaim>;
};

export type ClaimInterimClaimDetailsPromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimInterimClaimDetailsRecordedDetails = EventDetails & {
  __typename?: "ClaimInterimClaimDetailsRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimInterimClaimDetailsSentDetails = EventDetails & {
  __typename?: "ClaimInterimClaimDetailsSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimItem = {
  __typename?: "ClaimItem";
  advanceWarning?: Maybe<EarlyWarningItem>;
  advanceWarningId?: Maybe<Scalars["ID"]>;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  lastAction: ClaimItemLastAction;
  nextStep?: Maybe<ClaimItemNextStep>;
  notifiedByParty: ContractBindingType;
  notifiedByPartyId: Scalars["ID"];
  number: Scalars["String"];
  ongoingAuthorizationAudit?: Maybe<AuthorizationWorkflowAudit>;
  priceGranted?: Maybe<Scalars["Float"]>;
  priceSought?: Maybe<Scalars["Float"]>;
  productInstance: ProductInstance;
  productInstanceId: Scalars["ID"];
  regardingEvent?: Maybe<EventItem>;
  regardingId: Scalars["ID"];
  regardingInstruction?: Maybe<InstructionItem>;
  regardingType: ClaimRegardingType;
  status: ClaimStatus;
  timeGranted?: Maybe<Scalars["Int"]>;
  timeSought?: Maybe<Scalars["Int"]>;
};

export type ClaimItemLastAction = {
  __typename?: "ClaimItemLastAction";
  actionType: ClaimActionType;
  date: Scalars["AWSDateTime"];
  daysLate?: Maybe<Scalars["Int"]>;
  party: ContractBindingType;
  partyId: Scalars["ID"];
};

export type ClaimItemList = {
  __typename?: "ClaimItemList";
  items: Array<ClaimItem>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type ClaimItemNextStep = {
  __typename?: "ClaimItemNextStep";
  actionType: ClaimActionType;
  dueDate: Scalars["AWSDate"];
  party: ContractBindingType;
  partyId: Scalars["ID"];
};

export type ClaimNoAgreementNoticePrompt = {
  __typename?: "ClaimNoAgreementNoticePrompt";
  remarks: Scalars["String"];
};

export type ClaimNoAgreementNoticePromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimNoAgreementNoticeRecordedDetails = EventDetails & {
  __typename?: "ClaimNoAgreementNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimNoAgreementNoticeSentDetails = EventDetails & {
  __typename?: "ClaimNoAgreementNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimNoticeInvalidNoticePrompt = {
  __typename?: "ClaimNoticeInvalidNoticePrompt";
  reasons: Scalars["String"];
};

export type ClaimNoticeInvalidNoticePromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimNoticeInvalidNoticeRecordedDetails = EventDetails & {
  __typename?: "ClaimNoticeInvalidNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimNoticeInvalidNoticeSentDetails = EventDetails & {
  __typename?: "ClaimNoticeInvalidNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimNoticeLapsedNoticePrompt = {
  __typename?: "ClaimNoticeLapsedNoticePrompt";
  remarks: Scalars["String"];
};

export type ClaimNoticeLapsedNoticePromptInput = {
  claimId: Scalars["ID"];
};

export type ClaimNoticeLapsedNoticeRecordedDetails = EventDetails & {
  __typename?: "ClaimNoticeLapsedNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimNoticeLapsedNoticeSentDetails = EventDetails & {
  __typename?: "ClaimNoticeLapsedNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimNoticePrompt = {
  __typename?: "ClaimNoticePrompt";
  advanceWarnings: Array<ListItem>;
  claimTypes: Array<ListItem>;
};

export type ClaimNoticePromptInput = {
  productInstanceId: Scalars["ID"];
};

export type ClaimNoticeRecordedDetails = EventDetails & {
  __typename?: "ClaimNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimNoticeSentDetails = EventDetails & {
  __typename?: "ClaimNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: ClaimRegardingType;
  title: Scalars["String"];
};

export type ClaimRecipient = {
  __typename?: "ClaimRecipient";
  name: Scalars["String"];
  preference: UserPreferencesValue;
  userId: Scalars["ID"];
};

export enum ClaimRegardingType {
  Event = "Event",
  Instruction = "Instruction",
}

export enum ClaimStatus {
  Accepted = "Accepted",
  Agreed = "Agreed",
  Determined = "Determined",
  Final = "Final",
  Interim = "Interim",
  NotNotified = "NotNotified",
  Notified = "Notified",
  Rejected = "Rejected",
}

export type ClaimType = {
  __typename?: "ClaimType";
  clause: Scalars["String"];
  contractType: ContractType;
  contractTypeId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  status: ClaimTypeStatus;
};

export type ClaimTypeInfo = {
  __typename?: "ClaimTypeInfo";
  clause: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type ClaimTypeList = {
  __typename?: "ClaimTypeList";
  items: Array<ClaimType>;
};

export enum ClaimTypeStatus {
  Active = "Active",
  Removed = "Removed",
}

export type Claims = Product & {
  __typename?: "Claims";
  allowMultipleInstances: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  numberingFormat: Scalars["String"];
  outputActions: ProductOutputActionList;
  productSchemas: ProductSchemaList;
  roles: ProductRoleList;
  soloModeSupported: Scalars["Boolean"];
  status: ProductStatus;
  statusCollections: ItemStatusCollectionList;
};

export enum ColumnPlacementType {
  Primary = "Primary",
  Secondary = "Secondary",
}

export type Comment = {
  __typename?: "Comment";
  content: Scalars["String"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  itemId: Scalars["ID"];
  replies: CommentReplyList;
};

export type CommentList = {
  __typename?: "CommentList";
  items: Array<Comment>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type CommentRepliedDetails = EventDetails & {
  __typename?: "CommentRepliedDetails";
  content: Scalars["String"];
  title: Scalars["String"];
  versionId?: Maybe<Scalars["ID"]>;
};

export type CommentReply = {
  __typename?: "CommentReply";
  commentId: Scalars["ID"];
  content: Scalars["String"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
};

export type CommentReplyList = {
  __typename?: "CommentReplyList";
  items: Array<CommentReply>;
};

export type CompEventAbandonInstructionPrompt = {
  __typename?: "CompEventAbandonInstructionPrompt";
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventAbandonInstructionPromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventAbandonInstructionRecordedDetails = EventDetails & {
  __typename?: "CompEventAbandonInstructionRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventAbandonInstructionSentDetails = EventDetails & {
  __typename?: "CompEventAbandonInstructionSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventAction = {
  __typename?: "CompEventAction";
  actionMapping?: Maybe<AuthorizationWorkflowActionMapping>;
  type: CompEventActionType;
};

export type CompEventActionInput = {
  compEventId?: InputMaybe<Scalars["ID"]>;
  productInstanceId?: InputMaybe<Scalars["ID"]>;
  regardingId?: InputMaybe<Scalars["ID"]>;
  regardingType?: InputMaybe<CompEventRegardingType>;
};

export type CompEventActionList = {
  __typename?: "CompEventActionList";
  items: Array<CompEventAction>;
  ongoingAuthorizationAudit?: Maybe<AuthorizationWorkflowAudit>;
};

export enum CompEventActionType {
  AbandonInstruction = "AbandonInstruction",
  AcceptQuotation = "AcceptQuotation",
  CompEventDeemedConfirmed = "CompEventDeemedConfirmed",
  ConfirmCompEvent = "ConfirmCompEvent",
  DenyRequestMoreTime = "DenyRequestMoreTime",
  GrantRequestMoreTime = "GrantRequestMoreTime",
  NotifyCompEvent = "NotifyCompEvent",
  NotifyCompEventRequestQuotation = "NotifyCompEventRequestQuotation",
  NotifyOwnAssessment = "NotifyOwnAssessment",
  NotifySilenceCompEventNotice = "NotifySilenceCompEventNotice",
  NotifySilenceOwnAssessment = "NotifySilenceOwnAssessment",
  NotifySilenceQuotation = "NotifySilenceQuotation",
  ProposedInstructionChangedDecision = "ProposedInstructionChangedDecision",
  QuotationDeemedAccepted = "QuotationDeemedAccepted",
  RejectCompEvent = "RejectCompEvent",
  RequestMoreTime = "RequestMoreTime",
  RequestRevisedQuotation = "RequestRevisedQuotation",
  SubmitOwnAssessment = "SubmitOwnAssessment",
  SubmitQuotation = "SubmitQuotation",
  SubmitRevisedQuotation = "SubmitRevisedQuotation",
}

export type CompEventAssessment = {
  __typename?: "CompEventAssessment";
  attachments: Array<Attachment>;
  compEventId: Scalars["ID"];
  creatorId: Scalars["ID"];
  currency: Scalars["String"];
  dateCreated: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  keyDatesChanges: Array<CompEventQuotationTimeChange>;
  price: Scalars["Float"];
  reference: Scalars["String"];
  revision: Scalars["Int"];
  sectionalChanges: Array<CompEventQuotationTimeChange>;
  time: Scalars["Int"];
};

export type CompEventAuthorizationNotificationData = {
  __typename?: "CompEventAuthorizationNotificationData";
  regardingItemId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
};

export type CompEventConfirmationPrompt = {
  __typename?: "CompEventConfirmationPrompt";
  assumptions?: Maybe<Scalars["String"]>;
  ewCouldveBeenGiven: Scalars["Boolean"];
};

export type CompEventConfirmationPromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventConfirmationRecordedDetails = EventDetails & {
  __typename?: "CompEventConfirmationRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventConfirmationSentDetails = EventDetails & {
  __typename?: "CompEventConfirmationSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventDeemingConfirmedDetails = EventDetails & {
  __typename?: "CompEventDeemingConfirmedDetails";
  actionNumber: Scalars["String"];
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventDeemingQuotationAcceptedDetails = EventDetails & {
  __typename?: "CompEventDeemingQuotationAcceptedDetails";
  actionNumber: Scalars["String"];
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventDenyingOfMoreTimePrompt = {
  __typename?: "CompEventDenyingOfMoreTimePrompt";
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventDenyingOfMoreTimePromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventDenyingOfMoreTimeRecordedDetails = EventDetails & {
  __typename?: "CompEventDenyingOfMoreTimeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventDenyingOfMoreTimeSentDetails = EventDetails & {
  __typename?: "CompEventDenyingOfMoreTimeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventExtraConfig = {
  __typename?: "CompEventExtraConfig";
  test: Scalars["String"];
};

export type CompEventExtraConfigInput = {
  test: Scalars["String"];
};

export type CompEventGrantingOfMoreTimePrompt = {
  __typename?: "CompEventGrantingOfMoreTimePrompt";
  actionDueDate: Scalars["AWSDate"];
  grantedDays: Scalars["Int"];
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventGrantingOfMoreTimePromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventGrantingOfMoreTimeRecordedDetails = EventDetails & {
  __typename?: "CompEventGrantingOfMoreTimeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventGrantingOfMoreTimeSentDetails = EventDetails & {
  __typename?: "CompEventGrantingOfMoreTimeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventHistoryAbandonInstructionAnswers = {
  __typename?: "CompEventHistoryAbandonInstructionAnswers";
  remarks: Scalars["String"];
};

export type CompEventHistoryConfirmationAnswers = {
  __typename?: "CompEventHistoryConfirmationAnswers";
  assumptions?: Maybe<Scalars["String"]>;
  ewCouldveBeenGiven: Scalars["Boolean"];
};

export type CompEventHistoryDenyingOfMoreTimeAnswers = {
  __typename?: "CompEventHistoryDenyingOfMoreTimeAnswers";
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventHistoryGrantingOfMoreTimeAnswers = {
  __typename?: "CompEventHistoryGrantingOfMoreTimeAnswers";
  grantedDays: Scalars["Int"];
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventHistoryItem = {
  __typename?: "CompEventHistoryItem";
  abandonInstructionAnswers?: Maybe<CompEventHistoryAbandonInstructionAnswers>;
  actionNumber: Scalars["String"];
  actionType: CompEventActionType;
  attachments: Array<Attachment>;
  authorizationWorkflowAudit?: Maybe<AuthorizationWorkflowAudit>;
  compEventId: Scalars["ID"];
  confirmationAnswers?: Maybe<CompEventHistoryConfirmationAnswers>;
  dateCreated: Scalars["AWSDateTime"];
  dateSent: Scalars["AWSDateTime"];
  daysLate: Scalars["Int"];
  denyingOfMoreTimeAnswers?: Maybe<CompEventHistoryDenyingOfMoreTimeAnswers>;
  dueDate?: Maybe<Scalars["AWSDate"]>;
  grantingOfMoreTimeAnswers?: Maybe<CompEventHistoryGrantingOfMoreTimeAnswers>;
  id: Scalars["ID"];
  isIncomplete?: Maybe<Scalars["Boolean"]>;
  noticeAnswers?: Maybe<CompEventHistoryNoticeAnswers>;
  noticeOfSilenceCompEventNoticeAnswers?: Maybe<CompEventHistoryNoticeOfSilenceCompEventNoticeAnswers>;
  noticeOfSilenceOwnAssessmentAnswers?: Maybe<CompEventHistoryNoticeOfSilenceOwnAssessmentAnswers>;
  noticeOfSilenceQuotationAnswers?: Maybe<CompEventHistoryNoticeOfSilenceQuotationAnswers>;
  noticeProposedInstructionAnswers?: Maybe<CompEventHistoryNoticeProposedInstructionAnswers>;
  noticeRequestQuotationAnswers?: Maybe<CompEventHistoryNoticeRequestQuotationAnswers>;
  offline: Scalars["Boolean"];
  ownAssessmentAnswers?: Maybe<CompEventHistoryOwnAssessmentAnswers>;
  ownAssessmentNoticeAnswers?: Maybe<CompEventHistoryOwnAssessmentNoticeAnswers>;
  quotationAcceptanceAnswers?: Maybe<CompEventHistoryQuotationAcceptanceAnswers>;
  quotationAnswers?: Maybe<CompEventHistoryQuotationAnswers>;
  quotationRevisionRequestAnswers?: Maybe<CompEventHistoryQuotationRevisionRequestAnswers>;
  recipients: Array<CompEventRecipient>;
  rejectionAnswers?: Maybe<CompEventHistoryRejectionAnswers>;
  requestForMoreTimeAnswers?: Maybe<CompEventHistoryRequestForMoreTimeAnswers>;
  sentByParty?: Maybe<ContractBindingType>;
  sentByPartyId?: Maybe<Scalars["ID"]>;
  sentByUser: User;
  sentByUserId: Scalars["ID"];
};

export type CompEventHistoryItemList = {
  __typename?: "CompEventHistoryItemList";
  items: Array<CompEventHistoryItem>;
};

export type CompEventHistoryNoticeAnswers = {
  __typename?: "CompEventHistoryNoticeAnswers";
  claimType: ClaimTypeInfo;
  claimTypeId: Scalars["ID"];
  earlyWarning?: Maybe<EarlyWarningItem>;
  earlyWarningId?: Maybe<Scalars["ID"]>;
};

export type CompEventHistoryNoticeOfSilenceCompEventNoticeAnswers = {
  __typename?: "CompEventHistoryNoticeOfSilenceCompEventNoticeAnswers";
  deemedAcceptedDate: Scalars["AWSDate"];
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventHistoryNoticeOfSilenceOwnAssessmentAnswers = {
  __typename?: "CompEventHistoryNoticeOfSilenceOwnAssessmentAnswers";
  acceptedQuotation: CompEventQuotation;
  acceptedQuotationId: Scalars["ID"];
  deemedAcceptedDate: Scalars["AWSDate"];
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventHistoryNoticeOfSilenceQuotationAnswers = {
  __typename?: "CompEventHistoryNoticeOfSilenceQuotationAnswers";
  acceptedQuotation: CompEventQuotation;
  acceptedQuotationId: Scalars["ID"];
  deemedAcceptedDate: Scalars["AWSDate"];
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventHistoryNoticeProposedInstructionAnswers = {
  __typename?: "CompEventHistoryNoticeProposedInstructionAnswers";
  assumptions?: Maybe<Scalars["String"]>;
  claimType: ClaimTypeInfo;
  claimTypeId: Scalars["ID"];
};

export type CompEventHistoryNoticeRequestQuotationAnswers = {
  __typename?: "CompEventHistoryNoticeRequestQuotationAnswers";
  assumptions?: Maybe<Scalars["String"]>;
  claimType: ClaimTypeInfo;
  claimTypeId: Scalars["ID"];
};

export type CompEventHistoryOwnAssessmentAnswers = {
  __typename?: "CompEventHistoryOwnAssessmentAnswers";
  assessment?: Maybe<CompEventAssessment>;
  assessmentId?: Maybe<Scalars["ID"]>;
  draftAssessment?: Maybe<DraftAssessment>;
  draftAssessmentId?: Maybe<Scalars["ID"]>;
};

export type CompEventHistoryOwnAssessmentNoticeAnswers = {
  __typename?: "CompEventHistoryOwnAssessmentNoticeAnswers";
  reason: Scalars["String"];
};

export type CompEventHistoryQuotationAcceptanceAnswers = {
  __typename?: "CompEventHistoryQuotationAcceptanceAnswers";
  acceptedQuotation: CompEventQuotation;
  acceptedQuotationId: Scalars["ID"];
};

export type CompEventHistoryQuotationAnswers = {
  __typename?: "CompEventHistoryQuotationAnswers";
  preferredQuotation: CompEventQuotation;
  preferredQuotationId: Scalars["ID"];
};

export type CompEventHistoryQuotationRevisionRequestAnswers = {
  __typename?: "CompEventHistoryQuotationRevisionRequestAnswers";
  reason: Scalars["String"];
};

export type CompEventHistoryRejectionAnswers = {
  __typename?: "CompEventHistoryRejectionAnswers";
  reason: Scalars["String"];
};

export type CompEventHistoryRequestForMoreTimeAnswers = {
  __typename?: "CompEventHistoryRequestForMoreTimeAnswers";
  remarks?: Maybe<Scalars["String"]>;
  requestedDays: Scalars["Int"];
};

export type CompEventItem = {
  __typename?: "CompEventItem";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  deemingDate?: Maybe<Scalars["AWSDate"]>;
  earlyWarning?: Maybe<EarlyWarningItem>;
  earlyWarningId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  lastAction: CompEventItemLastAction;
  nextStep?: Maybe<CompEventItemNextStep>;
  notifiedByParty: ContractBindingType;
  notifiedByPartyId: Scalars["ID"];
  number: Scalars["String"];
  ongoingAuthorizationAudit?: Maybe<AuthorizationWorkflowAudit>;
  price?: Maybe<Scalars["Float"]>;
  productInstance: ProductInstance;
  productInstanceId: Scalars["ID"];
  regardingEvent?: Maybe<EventItem>;
  regardingId: Scalars["ID"];
  regardingInstruction?: Maybe<InstructionItem>;
  regardingType: CompEventRegardingType;
  status: CompEventStatus;
  time?: Maybe<Scalars["Int"]>;
};

export type CompEventItemLastAction = {
  __typename?: "CompEventItemLastAction";
  actionType: CompEventActionType;
  date: Scalars["AWSDateTime"];
  daysLate?: Maybe<Scalars["Int"]>;
};

export type CompEventItemList = {
  __typename?: "CompEventItemList";
  items: Array<CompEventItem>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type CompEventItemNextStep = {
  __typename?: "CompEventItemNextStep";
  actionType: CompEventActionType;
  dueDate: Scalars["AWSDate"];
  party: ContractBindingType;
  partyId: Scalars["ID"];
};

export type CompEventNoticeOfSilenceCompEventNoticePrompt = {
  __typename?: "CompEventNoticeOfSilenceCompEventNoticePrompt";
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventNoticeOfSilenceCompEventNoticePromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventNoticeOfSilenceCompEventNoticeRecordedDetails =
  EventDetails & {
    __typename?: "CompEventNoticeOfSilenceCompEventNoticeRecordedDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    compEventNumber: Scalars["String"];
    notifiedByParty: Scalars["String"];
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    regardingType: CompEventRegardingType;
    title: Scalars["String"];
  };

export type CompEventNoticeOfSilenceCompEventNoticeSentDetails =
  EventDetails & {
    __typename?: "CompEventNoticeOfSilenceCompEventNoticeSentDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    compEventNumber: Scalars["String"];
    notifiedByParty: Scalars["String"];
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    regardingType: CompEventRegardingType;
    title: Scalars["String"];
  };

export type CompEventNoticeOfSilenceOwnAssessmentPrompt = {
  __typename?: "CompEventNoticeOfSilenceOwnAssessmentPrompt";
  quotations: Array<CompEventQuotation>;
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventNoticeOfSilenceOwnAssessmentPromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventNoticeOfSilenceOwnAssessmentRecordedDetails =
  EventDetails & {
    __typename?: "CompEventNoticeOfSilenceOwnAssessmentRecordedDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    compEventNumber: Scalars["String"];
    notifiedByParty: Scalars["String"];
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    regardingType: CompEventRegardingType;
    title: Scalars["String"];
  };

export type CompEventNoticeOfSilenceOwnAssessmentSentDetails = EventDetails & {
  __typename?: "CompEventNoticeOfSilenceOwnAssessmentSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventNoticeOfSilenceQuotationPrompt = {
  __typename?: "CompEventNoticeOfSilenceQuotationPrompt";
  quotations: Array<CompEventQuotation>;
  remarks?: Maybe<Scalars["String"]>;
};

export type CompEventNoticeOfSilenceQuotationPromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventNoticeOfSilenceQuotationRecordedDetails = EventDetails & {
  __typename?: "CompEventNoticeOfSilenceQuotationRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventNoticeOfSilenceQuotationSentDetails = EventDetails & {
  __typename?: "CompEventNoticeOfSilenceQuotationSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventNoticePrompt = {
  __typename?: "CompEventNoticePrompt";
  compEventTypes: Array<ListItem>;
  earlyWarnings: Array<ListItem>;
};

export type CompEventNoticePromptInput = {
  productInstanceId: Scalars["ID"];
};

export type CompEventNoticeProposedInstructionRecordedDetails = EventDetails & {
  __typename?: "CompEventNoticeProposedInstructionRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventNoticeProposedInstructionSentDetails = EventDetails & {
  __typename?: "CompEventNoticeProposedInstructionSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventNoticeRecordedDetails = EventDetails & {
  __typename?: "CompEventNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventNoticeRequestQuotationAuditPrompt = {
  __typename?: "CompEventNoticeRequestQuotationAuditPrompt";
  assumptions?: Maybe<Scalars["String"]>;
  claimType: ClaimTypeInfo;
  claimTypeId: Scalars["ID"];
};

export type CompEventNoticeRequestQuotationPrompt = {
  __typename?: "CompEventNoticeRequestQuotationPrompt";
  assumptions?: Maybe<Scalars["String"]>;
  compEventTypes: Array<ListItem>;
};

export type CompEventNoticeRequestQuotationPromptInput = {
  productInstanceId: Scalars["ID"];
};

export type CompEventNoticeRequestQuotationRecordedDetails = EventDetails & {
  __typename?: "CompEventNoticeRequestQuotationRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventNoticeRequestQuotationSentDetails = EventDetails & {
  __typename?: "CompEventNoticeRequestQuotationSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventNoticeSentDetails = EventDetails & {
  __typename?: "CompEventNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  notifiedByParty: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventOwnAssessmentNoticePrompt = {
  __typename?: "CompEventOwnAssessmentNoticePrompt";
  reason: Scalars["String"];
};

export type CompEventOwnAssessmentNoticePromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventOwnAssessmentNoticeRecordedDetails = EventDetails & {
  __typename?: "CompEventOwnAssessmentNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventOwnAssessmentNoticeSentDetails = EventDetails & {
  __typename?: "CompEventOwnAssessmentNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventOwnAssessmentPrompt = {
  __typename?: "CompEventOwnAssessmentPrompt";
  draftAssessments: Array<DraftAssessment>;
};

export type CompEventOwnAssessmentPromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventOwnAssessmentRecordedDetails = EventDetails & {
  __typename?: "CompEventOwnAssessmentRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventOwnAssessmentSentDetails = EventDetails & {
  __typename?: "CompEventOwnAssessmentSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventQuotation = {
  __typename?: "CompEventQuotation";
  accepted?: Maybe<Scalars["Boolean"]>;
  attachments: Array<Attachment>;
  compEventId: Scalars["ID"];
  creatorId: Scalars["ID"];
  currency: Scalars["String"];
  dateCreated: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  keyDatesChanges: Array<CompEventQuotationTimeChange>;
  price: Scalars["Float"];
  reference: Scalars["String"];
  revision: Scalars["Int"];
  sectionalChanges: Array<CompEventQuotationTimeChange>;
  time: Scalars["Int"];
};

export type CompEventQuotationAcceptancePrompt = {
  __typename?: "CompEventQuotationAcceptancePrompt";
  quotations: Array<CompEventQuotation>;
};

export type CompEventQuotationAcceptancePromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventQuotationAcceptanceRecordedDetails = EventDetails & {
  __typename?: "CompEventQuotationAcceptanceRecordedDetails";
  acceptedQuotationReference: Scalars["String"];
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventQuotationAcceptanceSentDetails = EventDetails & {
  __typename?: "CompEventQuotationAcceptanceSentDetails";
  acceptedQuotationReference: Scalars["String"];
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventQuotationAssessment =
  | CompEventAssessment
  | CompEventQuotation;

export type CompEventQuotationAssessmentList = {
  __typename?: "CompEventQuotationAssessmentList";
  items: Array<CompEventQuotationAssessment>;
};

export type CompEventQuotationPrompt = {
  __typename?: "CompEventQuotationPrompt";
  draftQuotations: Array<DraftQuotation>;
};

export type CompEventQuotationPromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventQuotationRecordedDetails = EventDetails & {
  __typename?: "CompEventQuotationRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventQuotationRevisionRequestPrompt = {
  __typename?: "CompEventQuotationRevisionRequestPrompt";
  reason: Scalars["String"];
};

export type CompEventQuotationRevisionRequestPromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventQuotationRevisionRequestRecordedDetails = EventDetails & {
  __typename?: "CompEventQuotationRevisionRequestRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventQuotationRevisionRequestSentDetails = EventDetails & {
  __typename?: "CompEventQuotationRevisionRequestSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventQuotationSentDetails = EventDetails & {
  __typename?: "CompEventQuotationSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventQuotationTimeChange = {
  __typename?: "CompEventQuotationTimeChange";
  days: Scalars["Int"];
  description: Scalars["String"];
  number?: Maybe<Scalars["Int"]>;
};

export type CompEventRecipient = {
  __typename?: "CompEventRecipient";
  name: Scalars["String"];
  preference: UserPreferencesValue;
  userId: Scalars["ID"];
};

export enum CompEventRegardingType {
  Event = "Event",
  Instruction = "Instruction",
}

export type CompEventRejectionPrompt = {
  __typename?: "CompEventRejectionPrompt";
  reason: Scalars["String"];
};

export type CompEventRejectionPromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventRejectionRecordedDetails = EventDetails & {
  __typename?: "CompEventRejectionRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventRejectionSentDetails = EventDetails & {
  __typename?: "CompEventRejectionSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventRequestForMoreTimePrompt = {
  __typename?: "CompEventRequestForMoreTimePrompt";
  remarks?: Maybe<Scalars["String"]>;
  requestedDays: Scalars["Int"];
};

export type CompEventRequestForMoreTimePromptInput = {
  compEventId: Scalars["ID"];
};

export type CompEventRequestForMoreTimeRecordedDetails = EventDetails & {
  __typename?: "CompEventRequestForMoreTimeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export type CompEventRequestForMoreTimeSentDetails = EventDetails & {
  __typename?: "CompEventRequestForMoreTimeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  compEventNumber: Scalars["String"];
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  regardingType: CompEventRegardingType;
  title: Scalars["String"];
};

export enum CompEventStatus {
  Abandoned = "Abandoned",
  Assessing = "Assessing",
  Confirmed = "Confirmed",
  Implemented = "Implemented",
  Notified = "Notified",
  Proposed = "Proposed",
  Quoted = "Quoted",
  Rejected = "Rejected",
}

export type CompEvents = Product & {
  __typename?: "CompEvents";
  allowMultipleInstances: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  numberingFormat: Scalars["String"];
  outputActions: ProductOutputActionList;
  productSchemas: ProductSchemaList;
  roles: ProductRoleList;
  soloModeSupported: Scalars["Boolean"];
  status: ProductStatus;
  statusCollections: ItemStatusCollectionList;
};

export type Company = {
  __typename?: "Company";
  contractBindings: ContractBindingList;
  contracts: ContractList;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  logo?: Maybe<Scalars["String"]>;
  lookupCollections: CompanyLookupCollectionList;
  physicalAddress?: Maybe<Address>;
  postalAddress?: Maybe<Address>;
  projects: ProjectList;
  registeredName: Scalars["String"];
  registrationNumber?: Maybe<Scalars["String"]>;
  status: CompanyStatus;
  tradingName: Scalars["String"];
  type?: Maybe<CompanyType>;
  users: UserList;
  vatRegistrationNumber?: Maybe<Scalars["String"]>;
};

export type CompanyList = {
  __typename?: "CompanyList";
  items: Array<Company>;
};

export type CompanyLookupCollection = {
  __typename?: "CompanyLookupCollection";
  companyId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  options: LookupOptionList;
  optionsCharLengthLimit?: Maybe<Scalars["Int"]>;
  optionsLockedForUsers: Scalars["Boolean"];
  status: CompanyLookupCollectionStatus;
  usage: CompanyLookupCollectionUsage;
};

export type CompanyLookupCollectionList = {
  __typename?: "CompanyLookupCollectionList";
  items: Array<CompanyLookupCollection>;
  nextToken?: Maybe<Scalars["String"]>;
};

export enum CompanyLookupCollectionStatus {
  Active = "Active",
  Removed = "Removed",
}

export enum CompanyLookupCollectionUsage {
  ContractBindings = "ContractBindings",
  ProjectsOwned = "ProjectsOwned",
}

export enum CompanyStatus {
  Active = "Active",
  Removed = "Removed",
  Suspended = "Suspended",
}

export enum CompanyType {
  JointVenture = "JointVenture",
  Other = "Other",
  Partnership = "Partnership",
  Private = "Private",
  Public = "Public",
  SoleProprietor = "SoleProprietor",
}

export type Contract = {
  __typename?: "Contract";
  activeBindings: ContractBindingList;
  contractBindings: ContractBindingList;
  contractType: ContractType;
  contractTypeId: Scalars["ID"];
  coordinatesLatitude?: Maybe<Scalars["Float"]>;
  coordinatesLongitude?: Maybe<Scalars["Float"]>;
  country: Scalars["String"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  endDate?: Maybe<Scalars["AWSDate"]>;
  friendlyName: Scalars["String"];
  id: Scalars["ID"];
  keyDates: Array<ContractKeyDate>;
  name: Scalars["String"];
  number: Scalars["String"];
  productInstances: ProductInstanceList;
  project: Project;
  projectId: Scalars["ID"];
  province?: Maybe<Scalars["String"]>;
  sections: Array<ContractSection>;
  startDate?: Maybe<Scalars["AWSDate"]>;
  status: ContractStatus;
  templateRoutesOverride: DocumentTemplateRouteList;
  timeZone: Scalars["String"];
  value: Scalars["Float"];
  valueCurrency: Scalars["String"];
};

export type ContractBinding = {
  __typename?: "ContractBinding";
  company: Company;
  companyId: Scalars["ID"];
  contractBindingType: ContractBindingType;
  contractBindingTypeId: Scalars["ID"];
  contractId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  defaultCompany: Scalars["Boolean"];
  id: Scalars["ID"];
  isCompanyBilled: Scalars["Boolean"];
  representative?: Maybe<User>;
  representativeId?: Maybe<Scalars["ID"]>;
  status: ContractBindingStatus;
};

export type ContractBindingList = {
  __typename?: "ContractBindingList";
  items: Array<ContractBinding>;
};

export enum ContractBindingStatus {
  Active = "Active",
  Removed = "Removed",
}

export type ContractBindingType = {
  __typename?: "ContractBindingType";
  contractTypeId: Scalars["ID"];
  description: Scalars["String"];
  id: Scalars["ID"];
  status: ContractBindingTypeStatus;
};

export type ContractBindingTypeList = {
  __typename?: "ContractBindingTypeList";
  items: Array<ContractBindingType>;
};

export enum ContractBindingTypeStatus {
  Active = "Active",
  Removed = "Removed",
}

export type ContractKeyDate = {
  __typename?: "ContractKeyDate";
  conditionToBeMet: Scalars["String"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  keyDate: Scalars["AWSDate"];
  number?: Maybe<Scalars["Int"]>;
  status: ContractKeyDateStatus;
};

export enum ContractKeyDateStatus {
  Active = "Active",
  Removed = "Removed",
}

export type ContractList = {
  __typename?: "ContractList";
  items: Array<Contract>;
};

export type ContractSection = {
  __typename?: "ContractSection";
  completionDate: Scalars["AWSDate"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  number?: Maybe<Scalars["Int"]>;
  status: ContractSectionStatus;
};

export enum ContractSectionStatus {
  Active = "Active",
  Removed = "Removed",
}

export enum ContractStatus {
  Active = "Active",
  Closed = "Closed",
  Offline = "Offline",
  Removed = "Removed",
}

export type ContractTimeImpact = {
  __typename?: "ContractTimeImpact";
  days: Scalars["Int"];
  id: Scalars["ID"];
};

export type ContractTimeImpactInput = {
  days: Scalars["Int"];
  id: Scalars["ID"];
};

export type ContractType = {
  __typename?: "ContractType";
  contractBindingTypes: ContractBindingTypeList;
  description: Scalars["String"];
  id: Scalars["ID"];
  status: ContractTypeStatus;
  subType?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

export type ContractTypeList = {
  __typename?: "ContractTypeList";
  items: Array<ContractType>;
};

export enum ContractTypeStatus {
  Active = "Active",
  Offline = "Offline",
  Removed = "Removed",
}

export type CreateDraftClaimAgreementPrompt = {
  __typename?: "CreateDraftClaimAgreementPrompt";
  lastDetailedClaim: DetailedClaim;
  lastDetailedClaimId: Scalars["ID"];
};

export type CreateDraftClaimDeterminationPrompt = {
  __typename?: "CreateDraftClaimDeterminationPrompt";
  lastDetailedClaim: DetailedClaim;
  lastDetailedClaimId: Scalars["ID"];
};

export type CreateDraftVariationAgreementPrompt = {
  __typename?: "CreateDraftVariationAgreementPrompt";
  lastProposal: VariationProposal;
  lastProposalId: Scalars["ID"];
};

export type CreateDraftVariationDeterminationPrompt = {
  __typename?: "CreateDraftVariationDeterminationPrompt";
  lastProposal: VariationProposal;
  lastProposalId: Scalars["ID"];
};

export type CreateUploadAttachmentPreSignedUrlInput = {
  fileName: Scalars["String"];
  mimeType: Scalars["String"];
};

export type DailyDiary = Product & {
  __typename?: "DailyDiary";
  allowMultipleInstances: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  numberingFormat: Scalars["String"];
  outputActions: ProductOutputActionList;
  productSchemas: ProductSchemaList;
  roles: ProductRoleList;
  soloModeSupported: Scalars["Boolean"];
  status: ProductStatus;
  statusCollections: ItemStatusCollectionList;
};

export type DailyDiaryDelayRecord = {
  __typename?: "DailyDiaryDelayRecord";
  areaLookupId?: Maybe<Scalars["ID"]>;
  areaOptionId?: Maybe<Scalars["ID"]>;
  attachments?: Maybe<Array<Attachment>>;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  delayTypeLookupId: Scalars["ID"];
  delayTypeOptionId: Scalars["ID"];
  description: Scalars["String"];
  disciplineLookupId?: Maybe<Scalars["ID"]>;
  disciplineOptionId?: Maybe<Scalars["ID"]>;
  duration?: Maybe<Scalars["AWSTime"]>;
  id: Scalars["ID"];
  shiftLookupId?: Maybe<Scalars["ID"]>;
  shiftOptionId?: Maybe<Scalars["ID"]>;
  teamLookupId?: Maybe<Scalars["ID"]>;
  teamOptionId?: Maybe<Scalars["ID"]>;
};

export type DailyDiaryExtraConfig = {
  __typename?: "DailyDiaryExtraConfig";
  areaLookupId?: Maybe<Scalars["ID"]>;
  companyLookupId?: Maybe<Scalars["ID"]>;
  equipmentLookupId?: Maybe<Scalars["ID"]>;
  hseTypeLookupId?: Maybe<Scalars["ID"]>;
  manPowerLookupId?: Maybe<Scalars["ID"]>;
  presetId: Scalars["ID"];
  shiftLookupId?: Maybe<Scalars["ID"]>;
  startDate: Scalars["AWSDate"];
  teamLookupId?: Maybe<Scalars["ID"]>;
};

export type DailyDiaryExtraConfigInput = {
  areaLookupId?: InputMaybe<Scalars["ID"]>;
  companyLookupId?: InputMaybe<Scalars["ID"]>;
  equipmentLookupId?: InputMaybe<Scalars["ID"]>;
  hseTypeLookupId?: InputMaybe<Scalars["ID"]>;
  manPowerLookupId?: InputMaybe<Scalars["ID"]>;
  presetId: Scalars["ID"];
  shiftLookupId?: InputMaybe<Scalars["ID"]>;
  startDate: Scalars["AWSDate"];
  teamLookupId?: InputMaybe<Scalars["ID"]>;
};

export type DailyDiaryGeneralRecord = {
  __typename?: "DailyDiaryGeneralRecord";
  attachments?: Maybe<Array<Attachment>>;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type DailyDiaryHseRecord = {
  __typename?: "DailyDiaryHSERecord";
  attachments?: Maybe<Array<Attachment>>;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  description: Scalars["String"];
  hseTypeLookupId: Scalars["ID"];
  hseTypeOptionId: Scalars["ID"];
  id: Scalars["ID"];
};

export type DailyDiaryItem = {
  __typename?: "DailyDiaryItem";
  creator: User;
  creatorId: Scalars["ID"];
  date: Scalars["AWSDate"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  dateSent?: Maybe<Scalars["AWSDateTime"]>;
  id: Scalars["ID"];
  latestRevision: DailyDiaryItemRevision;
  number: Scalars["String"];
  productInstance: ProductInstance;
  productInstanceId: Scalars["ID"];
  revisions: Array<DailyDiaryItemRevision>;
  sentBy?: Maybe<User>;
  sentById?: Maybe<Scalars["ID"]>;
  status: DailyDiaryItemStatus;
  submissions: Array<DailyDiaryItemSubmission>;
};

export type DailyDiaryItemList = {
  __typename?: "DailyDiaryItemList";
  items: Array<DailyDiaryItem>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type DailyDiaryItemMixed = DailyDiaryItem | EmptyDailyDiaryItem;

export type DailyDiaryItemMixedList = {
  __typename?: "DailyDiaryItemMixedList";
  items: Array<DailyDiaryItemMixed>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type DailyDiaryItemRevision = {
  __typename?: "DailyDiaryItemRevision";
  authorizationWorkflowAudit?: Maybe<AuthorizationWorkflowAudit>;
  dateSent?: Maybe<Scalars["AWSDateTime"]>;
  diaryId: Scalars["ID"];
  id: Scalars["ID"];
  number: Scalars["String"];
  sentBy?: Maybe<User>;
  sentById?: Maybe<Scalars["ID"]>;
  status: DailyDiaryItemStatus;
};

export enum DailyDiaryItemStatus {
  Approved = "Approved",
  Draft = "Draft",
  LockedDraft = "LockedDraft",
  Rejected = "Rejected",
  Sent = "Sent",
  Waived = "Waived",
}

export type DailyDiaryItemSubmission = {
  __typename?: "DailyDiaryItemSubmission";
  date?: Maybe<Scalars["AWSDateTime"]>;
  user: User;
  userId: Scalars["ID"];
};

export type DailyDiaryPreset = {
  __typename?: "DailyDiaryPreset";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  sections: Array<DailyDiaryPresetSection>;
  status: DailyDiaryPresetStatus;
};

export type DailyDiaryPresetList = {
  __typename?: "DailyDiaryPresetList";
  items: Array<DailyDiaryPreset>;
};

export type DailyDiaryPresetSchema = {
  __typename?: "DailyDiaryPresetSchema";
  lookups: Array<ListItem>;
  sections: Array<DailyDiaryPresetSchemaSection>;
};

export type DailyDiaryPresetSchemaSection = {
  __typename?: "DailyDiaryPresetSchemaSection";
  fields: Array<DailyDiaryPresetSchemaSectionField>;
  name: Scalars["String"];
};

export type DailyDiaryPresetSchemaSectionField = {
  __typename?: "DailyDiaryPresetSchemaSectionField";
  canBeHidden: Scalars["Boolean"];
  displayName?: Maybe<Scalars["String"]>;
  fieldType: FieldTypeEnum;
  isRequired: Scalars["Boolean"];
  name: Scalars["String"];
  units: Array<Scalars["String"]>;
};

export type DailyDiaryPresetSection = {
  __typename?: "DailyDiaryPresetSection";
  fields: Array<DailyDiaryPresetSectionField>;
  name: Scalars["String"];
};

export type DailyDiaryPresetSectionField = {
  __typename?: "DailyDiaryPresetSectionField";
  displayName?: Maybe<Scalars["String"]>;
  fieldType: FieldTypeEnum;
  isHidden: Scalars["Boolean"];
  isRequired: Scalars["Boolean"];
  lookupId?: Maybe<Scalars["ID"]>;
  name: Scalars["String"];
  unit?: Maybe<Scalars["String"]>;
};

export type DailyDiaryPresetSectionFieldInput = {
  isHidden: Scalars["Boolean"];
  lookupId?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  unit?: InputMaybe<Scalars["String"]>;
};

export type DailyDiaryPresetSectionInput = {
  fields: Array<DailyDiaryPresetSectionFieldInput>;
  name: Scalars["String"];
};

export enum DailyDiaryPresetStatus {
  Active = "Active",
  Removed = "Removed",
}

export type DailyDiaryResourceRecord = {
  __typename?: "DailyDiaryResourceRecord";
  attachments?: Maybe<Array<Attachment>>;
  companyLookupId?: Maybe<Scalars["ID"]>;
  companyOptionId?: Maybe<Scalars["ID"]>;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  hours: Scalars["AWSTime"];
  id: Scalars["ID"];
  quantity: Scalars["Int"];
  remarks?: Maybe<Scalars["String"]>;
  resourceLookupId: Scalars["ID"];
  resourceOptionId: Scalars["ID"];
  shiftLookupId?: Maybe<Scalars["ID"]>;
  shiftOptionId?: Maybe<Scalars["ID"]>;
  teamLookupId?: Maybe<Scalars["ID"]>;
  teamOptionId?: Maybe<Scalars["ID"]>;
  totalHours: Scalars["String"];
};

export type DailyDiaryWeatherRecord = {
  __typename?: "DailyDiaryWeatherRecord";
  attachments?: Maybe<Array<Attachment>>;
  conditionsLookupId: Scalars["ID"];
  conditionsOptionId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  humidity?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  rainFall?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  timeOfMeasurement: Scalars["AWSTime"];
  windSpeed?: Maybe<Scalars["Float"]>;
};

export type DailyDiaryWorkRecord = {
  __typename?: "DailyDiaryWorkRecord";
  activityLookupId?: Maybe<Scalars["ID"]>;
  activityOptionId?: Maybe<Scalars["ID"]>;
  amount?: Maybe<Scalars["Float"]>;
  areaLookupId?: Maybe<Scalars["ID"]>;
  areaOptionId?: Maybe<Scalars["ID"]>;
  attachments?: Maybe<Array<Attachment>>;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  description?: Maybe<Scalars["String"]>;
  disciplineLookupId?: Maybe<Scalars["ID"]>;
  disciplineOptionId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  shiftLookupId?: Maybe<Scalars["ID"]>;
  shiftOptionId?: Maybe<Scalars["ID"]>;
  teamLookupId?: Maybe<Scalars["ID"]>;
  teamOptionId?: Maybe<Scalars["ID"]>;
  unitOfMeasurementLookupId?: Maybe<Scalars["ID"]>;
  unitOfMeasurementOptionId?: Maybe<Scalars["ID"]>;
  workTypeLookupId?: Maybe<Scalars["ID"]>;
  workTypeOptionId?: Maybe<Scalars["ID"]>;
};

export type DetailedClaim = {
  __typename?: "DetailedClaim";
  attachments: Array<Attachment>;
  claimId: Scalars["ID"];
  creatorId: Scalars["ID"];
  currency: Scalars["String"];
  dateCreated: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<DetailedClaimTimeChange>;
  time: Scalars["Int"];
};

export type DetailedClaimTimeChange = {
  __typename?: "DetailedClaimTimeChange";
  days: Scalars["Int"];
  description: Scalars["String"];
  number?: Maybe<Scalars["Int"]>;
};

export type DisabledClaimTypeOverride = {
  __typename?: "DisabledClaimTypeOverride";
  claimType: ClaimType;
  claimTypeId: Scalars["ID"];
  contract: Contract;
  contractId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
};

export type DisabledClaimTypeOverrideList = {
  __typename?: "DisabledClaimTypeOverrideList";
  items: Array<DisabledClaimTypeOverride>;
};

export type DocumentTemplate = {
  __typename?: "DocumentTemplate";
  content: Scalars["String"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  name: Scalars["String"];
  status: DocumentTemplateStatus;
};

export type DocumentTemplateList = {
  __typename?: "DocumentTemplateList";
  items: Array<DocumentTemplate>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type DocumentTemplateRoute = {
  __typename?: "DocumentTemplateRoute";
  clause?: Maybe<Scalars["String"]>;
  contract?: Maybe<Contract>;
  contractId?: Maybe<Scalars["ID"]>;
  contractType: ContractType;
  contractTypeId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  defaultNumbering?: Maybe<Scalars["String"]>;
  documentTemplate: DocumentTemplate;
  documentTemplateId: Scalars["ID"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  originatingParty: ContractBindingType;
  originatingPartyId: Scalars["ID"];
  outputAction: ProductOutputAction;
  productOutputActionId: Scalars["ID"];
  targetParty: ContractBindingType;
  targetPartyId: Scalars["ID"];
};

export type DocumentTemplateRouteList = {
  __typename?: "DocumentTemplateRouteList";
  items: Array<DocumentTemplateRoute>;
};

export enum DocumentTemplateStatus {
  Active = "Active",
  Removed = "Removed",
}

export type DraftAssessment = {
  __typename?: "DraftAssessment";
  attachments: Array<Attachment>;
  compEventId: Scalars["ID"];
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  keyDatesChanges: Array<Maybe<ContractTimeImpact>>;
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<Maybe<ContractTimeImpact>>;
  time: Scalars["Int"];
};

export type DraftAssessmentList = {
  __typename?: "DraftAssessmentList";
  items: Array<DraftAssessment>;
};

export type DraftClaimAgreement = {
  __typename?: "DraftClaimAgreement";
  attachments: Array<Attachment>;
  claimId: Scalars["ID"];
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<Maybe<ContractTimeImpact>>;
  time: Scalars["Int"];
};

export type DraftClaimAgreementList = {
  __typename?: "DraftClaimAgreementList";
  items: Array<DraftClaimAgreement>;
};

export type DraftClaimDetermination = {
  __typename?: "DraftClaimDetermination";
  attachments: Array<Attachment>;
  claimId: Scalars["ID"];
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<Maybe<ContractTimeImpact>>;
  time: Scalars["Int"];
};

export type DraftClaimDeterminationList = {
  __typename?: "DraftClaimDeterminationList";
  items: Array<DraftClaimDetermination>;
};

export type DraftDetailedClaim = {
  __typename?: "DraftDetailedClaim";
  attachments: Array<Attachment>;
  claimId: Scalars["ID"];
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<Maybe<ContractTimeImpact>>;
  time: Scalars["Int"];
};

export type DraftDetailedClaimList = {
  __typename?: "DraftDetailedClaimList";
  items: Array<DraftDetailedClaim>;
};

export type DraftQuotation = {
  __typename?: "DraftQuotation";
  attachments: Array<Attachment>;
  compEventId: Scalars["ID"];
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  keyDatesChanges: Array<Maybe<ContractTimeImpact>>;
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<Maybe<ContractTimeImpact>>;
  time: Scalars["Int"];
};

export type DraftQuotationList = {
  __typename?: "DraftQuotationList";
  items: Array<DraftQuotation>;
};

export type DraftVariationAgreement = {
  __typename?: "DraftVariationAgreement";
  attachments: Array<Attachment>;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<Maybe<ContractTimeImpact>>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type DraftVariationAgreementList = {
  __typename?: "DraftVariationAgreementList";
  items: Array<DraftVariationAgreement>;
};

export type DraftVariationDetermination = {
  __typename?: "DraftVariationDetermination";
  attachments: Array<Attachment>;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<Maybe<ContractTimeImpact>>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type DraftVariationDeterminationList = {
  __typename?: "DraftVariationDeterminationList";
  items: Array<DraftVariationDetermination>;
};

export type DraftVariationProposal = {
  __typename?: "DraftVariationProposal";
  attachments: Array<Attachment>;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<Maybe<ContractTimeImpact>>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type DraftVariationProposalList = {
  __typename?: "DraftVariationProposalList";
  items: Array<DraftVariationProposal>;
};

export type EarlyWarningItem = {
  __typename?: "EarlyWarningItem";
  attachments?: Maybe<Array<Attachment>>;
  authorizationWorkflowAudit?: Maybe<AuthorizationWorkflowAudit>;
  changeLog: ChangeLogItemList;
  comments: CommentList;
  creator: User;
  creatorId: Scalars["ID"];
  data: ItemData;
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  dateSent: Scalars["AWSDateTime"];
  givenById?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  notificationRecipients: Array<NotificationRecipient>;
  number: Scalars["String"];
  offline: Scalars["Boolean"];
  owner: User;
  ownerId: Scalars["ID"];
  productInstance: ProductInstance;
  productInstanceId: Scalars["ID"];
  severity: Scalars["String"];
  statusOption: ItemStatusOption;
  statusOptionId: Scalars["ID"];
  title: Scalars["String"];
};

export type EarlyWarningItemCommentsArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type EarlyWarningItemList = {
  __typename?: "EarlyWarningItemList";
  items: Array<EarlyWarningItem>;
};

export type EarlyWarningItemPreview = {
  __typename?: "EarlyWarningItemPreview";
  actionMapping?: Maybe<AuthorizationWorkflowActionMapping>;
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<EarlyWarningRecipient>;
};

export type EarlyWarningRecipient = {
  __typename?: "EarlyWarningRecipient";
  name: Scalars["String"];
  preference: UserPreferencesValue;
  userId: Scalars["ID"];
};

export type EarlyWarnings = Product & {
  __typename?: "EarlyWarnings";
  allowMultipleInstances: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  numberingFormat: Scalars["String"];
  outputActions: ProductOutputActionList;
  productSchemas: ProductSchemaList;
  roles: ProductRoleList;
  soloModeSupported: Scalars["Boolean"];
  status: ProductStatus;
  statusCollections: ItemStatusCollectionList;
};

export type EditAuthorizationActionInput = {
  id: Scalars["ID"];
  workflowId: Scalars["ID"];
};

export type EditAuthorizationWorkflowInput = {
  id: Scalars["ID"];
  levels: Array<InputMaybe<AuthorizationWorkflowLevelInput>>;
  name: Scalars["String"];
};

export type EditCompanyInput = {
  id: Scalars["ID"];
  logo?: InputMaybe<Scalars["String"]>;
  physicalAddress?: InputMaybe<AddressInput>;
  postalAddress?: InputMaybe<AddressInput>;
  registeredName: Scalars["String"];
  registrationNumber?: InputMaybe<Scalars["String"]>;
  tradingName: Scalars["String"];
  type?: InputMaybe<CompanyType>;
  vatRegistrationNumber?: InputMaybe<Scalars["String"]>;
};

export type EditCompanyLookupCollectionInput = {
  companyId: Scalars["ID"];
  id: Scalars["ID"];
  name: Scalars["String"];
  optionsCharLengthLimit?: InputMaybe<Scalars["Int"]>;
  optionsLockedForUsers: Scalars["Boolean"];
  usage: CompanyLookupCollectionUsage;
};

export type EditContractBindingInput = {
  companyId: Scalars["ID"];
  contractBindingTypeId: Scalars["ID"];
  contractId: Scalars["ID"];
  defaultCompany: Scalars["Boolean"];
  id: Scalars["ID"];
  isCompanyBilled: Scalars["Boolean"];
  representativeId?: InputMaybe<Scalars["ID"]>;
};

export type EditContractInput = {
  contractTypeId: Scalars["ID"];
  coordinatesLatitude?: InputMaybe<Scalars["Float"]>;
  coordinatesLongitude?: InputMaybe<Scalars["Float"]>;
  country: Scalars["String"];
  endDate?: InputMaybe<Scalars["AWSDate"]>;
  friendlyName: Scalars["String"];
  id: Scalars["ID"];
  keyDates?: InputMaybe<Array<EditContractKeyDateInput>>;
  name: Scalars["String"];
  number: Scalars["String"];
  projectId: Scalars["ID"];
  province?: InputMaybe<Scalars["String"]>;
  sections?: InputMaybe<Array<EditContractSectionInput>>;
  startDate?: InputMaybe<Scalars["AWSDate"]>;
  timeZone: Scalars["String"];
  value: Scalars["Float"];
  valueCurrency: Scalars["String"];
};

export type EditContractKeyDateInput = {
  conditionToBeMet: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  keyDate: Scalars["AWSDate"];
  number?: InputMaybe<Scalars["Int"]>;
  status: ContractKeyDateStatus;
};

export type EditContractSectionInput = {
  completionDate: Scalars["AWSDate"];
  description: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  number?: InputMaybe<Scalars["Int"]>;
  status: ContractSectionStatus;
};

export type EditDailyDiaryDelayRecordInput = {
  areaOptionId?: InputMaybe<Scalars["ID"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  delayTypeOptionId: Scalars["ID"];
  description: Scalars["String"];
  disciplineOptionId?: InputMaybe<Scalars["ID"]>;
  duration?: InputMaybe<Scalars["AWSTime"]>;
  id: Scalars["ID"];
  shiftOptionId?: InputMaybe<Scalars["ID"]>;
  teamOptionId?: InputMaybe<Scalars["ID"]>;
};

export type EditDailyDiaryGeneralRecordInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type EditDailyDiaryHseRecordInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  description: Scalars["String"];
  hseTypeOptionId: Scalars["ID"];
  id: Scalars["ID"];
};

export type EditDailyDiaryPresetInput = {
  id: Scalars["ID"];
  name: Scalars["String"];
  sections: Array<DailyDiaryPresetSectionInput>;
};

export type EditDailyDiaryResourceRecordInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  companyOptionId?: InputMaybe<Scalars["ID"]>;
  hours: Scalars["AWSTime"];
  id: Scalars["ID"];
  quantity: Scalars["Int"];
  remarks?: InputMaybe<Scalars["String"]>;
  resourceOptionId: Scalars["ID"];
  shiftOptionId?: InputMaybe<Scalars["ID"]>;
  teamOptionId?: InputMaybe<Scalars["ID"]>;
};

export type EditDailyDiaryWeatherRecordInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  conditionsOptionId: Scalars["ID"];
  humidity?: InputMaybe<Scalars["Float"]>;
  id: Scalars["ID"];
  rainFall?: InputMaybe<Scalars["Float"]>;
  temperature?: InputMaybe<Scalars["Float"]>;
  timeOfMeasurement: Scalars["AWSTime"];
  windSpeed?: InputMaybe<Scalars["Float"]>;
};

export type EditDailyDiaryWorkRecordInput = {
  activityOptionId?: InputMaybe<Scalars["ID"]>;
  amount?: InputMaybe<Scalars["Float"]>;
  areaOptionId?: InputMaybe<Scalars["ID"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  description?: InputMaybe<Scalars["String"]>;
  disciplineOptionId?: InputMaybe<Scalars["ID"]>;
  id: Scalars["ID"];
  shiftOptionId?: InputMaybe<Scalars["ID"]>;
  teamOptionId?: InputMaybe<Scalars["ID"]>;
  unitOfMeasurementOptionId?: InputMaybe<Scalars["ID"]>;
  workTypeOptionId?: InputMaybe<Scalars["ID"]>;
};

export type EditDocumentTemplateInput = {
  content: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type EditDocumentTemplateRouteInput = {
  clause?: InputMaybe<Scalars["String"]>;
  contractTypeId: Scalars["ID"];
  defaultNumbering?: InputMaybe<Scalars["String"]>;
  documentTemplateId: Scalars["ID"];
  id: Scalars["ID"];
  originatingPartyId: Scalars["ID"];
  productOutputActionId: Scalars["ID"];
  targetPartyId: Scalars["ID"];
};

export type EditDocumentTemplateRouteOverrideInput = {
  clause?: InputMaybe<Scalars["String"]>;
  contractId: Scalars["ID"];
  defaultNumbering?: InputMaybe<Scalars["String"]>;
  documentTemplateId: Scalars["ID"];
  id: Scalars["ID"];
  originatingPartyId: Scalars["ID"];
  productOutputActionId: Scalars["ID"];
  targetPartyId: Scalars["ID"];
};

export type EditDraftAssessmentInput = {
  attachments: Array<AttachmentInput>;
  compEventId: Scalars["ID"];
  details: Scalars["String"];
  id: Scalars["ID"];
  keyDatesChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
};

export type EditDraftClaimAgreementInput = {
  attachments: Array<AttachmentInput>;
  claimId: Scalars["ID"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
};

export type EditDraftClaimDeterminationInput = {
  attachments: Array<AttachmentInput>;
  claimId: Scalars["ID"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
};

export type EditDraftDetailedClaimInput = {
  attachments: Array<AttachmentInput>;
  claimId: Scalars["ID"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
};

export type EditDraftEarlyWarningItemInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  data: ItemDataInput;
  id: Scalars["ID"];
};

export type EditDraftInstructionItemInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  data: ItemDataInput;
  id: Scalars["ID"];
};

export type EditDraftQuotationInput = {
  attachments: Array<AttachmentInput>;
  compEventId: Scalars["ID"];
  details: Scalars["String"];
  id: Scalars["ID"];
  keyDatesChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
};

export type EditDraftVariationAgreementInput = {
  attachments: Array<AttachmentInput>;
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type EditDraftVariationDeterminationInput = {
  attachments: Array<AttachmentInput>;
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type EditDraftVariationProposalInput = {
  attachments: Array<AttachmentInput>;
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  sectionalChanges: Array<InputMaybe<ContractTimeImpactInput>>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type EditEarlyWarningItemInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  id: Scalars["ID"];
};

export type EditEffectPresetInput = {
  contractTypeId: Scalars["ID"];
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
  name: Scalars["String"];
};

export type EditEmailTemplateInput = {
  body: Scalars["String"];
  id: Scalars["ID"];
  type: Scalars["String"];
};

export type EditEventItemInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  data: ItemDataInput;
  id: Scalars["ID"];
};

export type EditFieldTypeInput = {
  description: Scalars["String"];
  id: Scalars["ID"];
  shortCode: FieldTypeEnum;
};

export type EditInstructionItemInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  id: Scalars["ID"];
};

export type EditItemStatusCollectionInput = {
  id: Scalars["ID"];
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  productId: Scalars["ID"];
};

export type EditItemStatusOptionInput = {
  displayOrder: Scalars["Int"];
  id: Scalars["ID"];
  style: ItemStatusOptionStyles;
};

export type EditItemStatusOptionReasonInput = {
  displayOrder: Scalars["Int"];
  id: Scalars["ID"];
};

export type EditLookupCollectionInput = {
  id: Scalars["ID"];
  name: Scalars["String"];
  optionsCharLengthLimit?: InputMaybe<Scalars["Int"]>;
  optionsLockedForUsers: Scalars["Boolean"];
};

export type EditLookupOptionInput = {
  collectionId: Scalars["ID"];
  displayOrder: Scalars["Int"];
  id: Scalars["ID"];
};

export type EditProductInstanceInput = {
  contractId: Scalars["ID"];
  description: Scalars["String"];
  extraConfig?: InputMaybe<ExtraConfigInput>;
  id: Scalars["ID"];
  numberingFormat: Scalars["String"];
  productId: Scalars["ID"];
  productSchemaId: Scalars["ID"];
  soloModeSupported: Scalars["Boolean"];
  statusCollectionId: Scalars["ID"];
};

export type EditProductRoleActionInput = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type EditProductRoleInput = {
  actionIds: Array<Scalars["ID"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  productId: Scalars["ID"];
};

export type EditProductSchemaInput = {
  id: Scalars["ID"];
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  productId: Scalars["ID"];
};

export type EditProductUserRoleInput = {
  id: Scalars["ID"];
  productInstanceId: Scalars["ID"];
  productRoleId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type EditProjectInput = {
  billingCompanyId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
  friendlyName: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  number?: InputMaybe<Scalars["String"]>;
  sector: ProjectSector;
};

export type EditRiskItemInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  data: ItemDataInput;
  id: Scalars["ID"];
};

export type EditSchemaFieldInput = {
  complexTypeItemId?: InputMaybe<Scalars["ID"]>;
  displayOrder: Scalars["Int"];
  displayText: Scalars["String"];
  fieldTypeId: Scalars["ID"];
  id: Scalars["ID"];
  isRequired: Scalars["Boolean"];
  name: Scalars["String"];
  schemaSectionId: Scalars["ID"];
};

export type EditSchemaSectionInput = {
  columnPlacement?: InputMaybe<ColumnPlacementType>;
  displayOrder: Scalars["Int"];
  displayText: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  productSchemaId: Scalars["ID"];
};

export type EditSeverityPresetInput = {
  consequenceLabel: Scalars["String"];
  consequenceSets: Array<Scalars["String"]>;
  id: Scalars["ID"];
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  likelihoodLabel: Scalars["String"];
  likelihoodSets: Array<Scalars["String"]>;
  name: Scalars["String"];
};

export type EditSeverityRatingInput = {
  colour: Scalars["String"];
  id: Scalars["ID"];
  label: Scalars["String"];
  lowerRange: Scalars["Int"];
  name: Scalars["String"];
  severityPresetId: Scalars["ID"];
  upperRange: Scalars["Int"];
};

export type EditUserInput = {
  alternateNumber?: InputMaybe<Scalars["AWSPhone"]>;
  companyId: Scalars["ID"];
  country: Scalars["String"];
  dateOfBirth: Scalars["AWSDate"];
  firstname: Scalars["String"];
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  mobileNumber: Scalars["AWSPhone"];
  profilePicture?: InputMaybe<Scalars["String"]>;
  surname: Scalars["String"];
};

export enum EffectEstimationType {
  Decimal = "Decimal",
  Monetary = "Monetary",
  None = "None",
}

export type EffectOption = {
  __typename?: "EffectOption";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  effectPresetId: Scalars["ID"];
  estimationType: EffectEstimationType;
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  label: Scalars["String"];
  status: EffectOptionStatus;
  unit: Scalars["String"];
};

export type EffectOptionList = {
  __typename?: "EffectOptionList";
  items: Array<EffectOption>;
};

export enum EffectOptionStatus {
  Active = "Active",
  Removed = "Removed",
}

export type EffectPreset = {
  __typename?: "EffectPreset";
  contractTypeId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
  isInternal: Scalars["Boolean"];
  name: Scalars["String"];
  options: EffectOptionList;
  status: EffectPresetStatus;
};

export type EffectPresetList = {
  __typename?: "EffectPresetList";
  items: Array<EffectPreset>;
};

export enum EffectPresetStatus {
  Active = "Active",
  Removed = "Removed",
}

export type EmailTemplate = {
  __typename?: "EmailTemplate";
  body: Scalars["String"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  status: EmailTemplateStatus;
  type: Scalars["String"];
};

export type EmailTemplateList = {
  __typename?: "EmailTemplateList";
  items: Array<EmailTemplate>;
};

export enum EmailTemplateStatus {
  Active = "Active",
  Removed = "Removed",
}

export type EmptyDailyDiaryItem = {
  __typename?: "EmptyDailyDiaryItem";
  date: Scalars["AWSDate"];
  productInstanceId: Scalars["ID"];
};

export type EventAddedDetails = EventDetails & {
  __typename?: "EventAddedDetails";
  attachments: Array<ItemAttachmentDataDetails>;
  title: Scalars["String"];
};

export type EventDetails = {
  title: Scalars["String"];
};

export type EventEditedDetails = EventDetails & {
  __typename?: "EventEditedDetails";
  fieldChanges: Array<ItemFieldChangeDetails>;
  title: Scalars["String"];
};

export type EventItem = {
  __typename?: "EventItem";
  attachments?: Maybe<Array<Attachment>>;
  changeLog: ChangeLogItemList;
  claim?: Maybe<ClaimItem>;
  comments: CommentList;
  compEvent?: Maybe<CompEventItem>;
  creator: User;
  creatorId: Scalars["ID"];
  data: ItemData;
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  notificationRecipients: Array<NotificationRecipient>;
  notifyDueDate: Scalars["AWSDate"];
  number: Scalars["String"];
  owner: User;
  ownerId: Scalars["ID"];
  productInstance: ProductInstance;
  productInstanceId: Scalars["ID"];
  severity: Scalars["String"];
  statusOption: ItemStatusOption;
  statusOptionId: Scalars["ID"];
  title: Scalars["String"];
};

export type EventItemCommentsArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type EventItemList = {
  __typename?: "EventItemList";
  items: Array<EventItem>;
};

export type EventOwnerChangedDetails = EventDetails & {
  __typename?: "EventOwnerChangedDetails";
  newOwner: Scalars["String"];
  oldOwner: Scalars["String"];
  title: Scalars["String"];
};

export type EventStatusChangedDetails = EventDetails & {
  __typename?: "EventStatusChangedDetails";
  details?: Maybe<Scalars["String"]>;
  newStatus: Scalars["String"];
  oldStatus: Scalars["String"];
  reason: Scalars["String"];
  title: Scalars["String"];
};

export type Events = Product & {
  __typename?: "Events";
  allowMultipleInstances: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  numberingFormat: Scalars["String"];
  outputActions: ProductOutputActionList;
  productSchemas: ProductSchemaList;
  roles: ProductRoleList;
  soloModeSupported: Scalars["Boolean"];
  status: ProductStatus;
  statusCollections: ItemStatusCollectionList;
};

export type EwEditedDetails = EventDetails & {
  __typename?: "EwEditedDetails";
  fieldChanges: Array<ItemFieldChangeDetails>;
  title: Scalars["String"];
};

export type EwOwnerChangedDetails = EventDetails & {
  __typename?: "EwOwnerChangedDetails";
  newOwner: Scalars["String"];
  oldOwner: Scalars["String"];
  title: Scalars["String"];
};

export type EwRecordedDetails = EventDetails & {
  __typename?: "EwRecordedDetails";
  attachments: Array<ItemAttachmentDataDetails>;
  title: Scalars["String"];
};

export type EwSentDetails = EventDetails & {
  __typename?: "EwSentDetails";
  attachments: Array<ItemAttachmentDataDetails>;
  title: Scalars["String"];
};

export type EwStatusChangedDetails = EventDetails & {
  __typename?: "EwStatusChangedDetails";
  details?: Maybe<Scalars["String"]>;
  newStatus: Scalars["String"];
  oldStatus: Scalars["String"];
  reason: Scalars["String"];
  title: Scalars["String"];
};

export type ExtraConfigInput = {
  compEvent?: InputMaybe<CompEventExtraConfigInput>;
  dailyDiary?: InputMaybe<DailyDiaryExtraConfigInput>;
};

export type ExtraConfigType = CompEventExtraConfig | DailyDiaryExtraConfig;

export type FieldType = {
  __typename?: "FieldType";
  description: Scalars["String"];
  id: Scalars["ID"];
  shortCode: FieldTypeEnum;
  status: FieldTypeStatus;
};

export enum FieldTypeEnum {
  D = "D",
  Dec = "DEC",
  Dt = "DT",
  Eff = "EFF",
  Enu = "ENU",
  InstType = "INST_TYPE",
  Int = "INT",
  Lkp = "LKP",
  Mlt = "MLT",
  Rt = "RT",
  Rta = "RTA",
  Slt = "SLT",
  Srs = "SRS",
  T = "T",
}

export type FieldTypeList = {
  __typename?: "FieldTypeList";
  items: Array<FieldType>;
};

export enum FieldTypeStatus {
  Active = "Active",
  Removed = "Removed",
}

export type GlobalConfiguration = {
  __typename?: "GlobalConfiguration";
  maxUploadFileSize: Scalars["Int"];
};

export type ImportDailyDiaryRecordsInput = {
  destinationDailyDiaryId?: InputMaybe<Scalars["ID"]>;
  destinationDate?: InputMaybe<Scalars["AWSDate"]>;
  destinationProductInstanceId?: InputMaybe<Scalars["ID"]>;
  sourceDailyDiaryId: Scalars["ID"];
  sourceRecordIds: Array<Scalars["ID"]>;
};

export type InstructionEditedDetails = EventDetails & {
  __typename?: "InstructionEditedDetails";
  fieldChanges: Array<ItemFieldChangeDetails>;
  title: Scalars["String"];
};

export type InstructionItem = {
  __typename?: "InstructionItem";
  attachments?: Maybe<Array<Attachment>>;
  authorizationWorkflowAudit?: Maybe<AuthorizationWorkflowAudit>;
  changeLog: ChangeLogItemList;
  claim?: Maybe<ClaimItem>;
  comments: CommentList;
  compEvent?: Maybe<CompEventItem>;
  creator: User;
  creatorId: Scalars["ID"];
  data: ItemData;
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  dateSent: Scalars["AWSDateTime"];
  givenById?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  notificationRecipients: Array<NotificationRecipient>;
  notifyDueDate: Scalars["AWSDate"];
  number: Scalars["String"];
  offline: Scalars["Boolean"];
  owner: User;
  ownerId: Scalars["ID"];
  productInstance: ProductInstance;
  productInstanceId: Scalars["ID"];
  severity: Scalars["String"];
  statusOption: ItemStatusOption;
  statusOptionId: Scalars["ID"];
  title: Scalars["String"];
  variation?: Maybe<VariationItem>;
};

export type InstructionItemCommentsArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type InstructionItemList = {
  __typename?: "InstructionItemList";
  items: Array<InstructionItem>;
};

export type InstructionItemPreview = {
  __typename?: "InstructionItemPreview";
  actionMapping?: Maybe<AuthorizationWorkflowActionMapping>;
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<InstructionRecipient>;
};

export type InstructionOwnerChangedDetails = EventDetails & {
  __typename?: "InstructionOwnerChangedDetails";
  newOwner: Scalars["String"];
  oldOwner: Scalars["String"];
  title: Scalars["String"];
};

export type InstructionRecipient = {
  __typename?: "InstructionRecipient";
  name: Scalars["String"];
  preference: UserPreferencesValue;
  userId: Scalars["ID"];
};

export type InstructionRecordedDetails = EventDetails & {
  __typename?: "InstructionRecordedDetails";
  attachments: Array<ItemAttachmentDataDetails>;
  title: Scalars["String"];
};

export type InstructionSentDetails = EventDetails & {
  __typename?: "InstructionSentDetails";
  attachments: Array<ItemAttachmentDataDetails>;
  title: Scalars["String"];
};

export type InstructionStatusChangedDetails = EventDetails & {
  __typename?: "InstructionStatusChangedDetails";
  details?: Maybe<Scalars["String"]>;
  newStatus: Scalars["String"];
  oldStatus: Scalars["String"];
  reason: Scalars["String"];
  title: Scalars["String"];
};

export type Instructions = Product & {
  __typename?: "Instructions";
  allowMultipleInstances: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  numberingFormat: Scalars["String"];
  outputActions: ProductOutputActionList;
  productSchemas: ProductSchemaList;
  roles: ProductRoleList;
  soloModeSupported: Scalars["Boolean"];
  status: ProductStatus;
  statusCollections: ItemStatusCollectionList;
};

export type InviteUserInput = {
  autoRole?: InputMaybe<AutoRole>;
  companyId: Scalars["ID"];
  email: Scalars["AWSEmail"];
  roles?: InputMaybe<Array<InviteUserRoleInput>>;
};

export type InviteUserResponse = {
  __typename?: "InviteUserResponse";
  success: Scalars["Boolean"];
};

export type InviteUserRoleInput = {
  productInstanceId: Scalars["ID"];
  productRoleId: Scalars["ID"];
};

export type ItemAttachmentChangeDetails = {
  __typename?: "ItemAttachmentChangeDetails";
  fileName: Scalars["String"];
  newDescription: Scalars["String"];
  oldDescription: Scalars["String"];
};

export type ItemAttachmentDataDetails = {
  __typename?: "ItemAttachmentDataDetails";
  description: Scalars["String"];
  fileName: Scalars["String"];
};

export type ItemData = {
  __typename?: "ItemData";
  sections: Array<ItemDataSection>;
};

export type ItemDataEntry = {
  __typename?: "ItemDataEntry";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type ItemDataEntryInput = {
  name: Scalars["String"];
  value: Scalars["String"];
};

export type ItemDataInput = {
  sections: Array<ItemDataSectionInput>;
};

export type ItemDataSection = {
  __typename?: "ItemDataSection";
  entries: Array<ItemDataEntry>;
  name: Scalars["String"];
};

export type ItemDataSectionInput = {
  entries: Array<ItemDataEntryInput>;
  name: Scalars["String"];
};

export type ItemFieldChangeDetails = {
  __typename?: "ItemFieldChangeDetails";
  fieldName: Scalars["String"];
  newValue: Scalars["String"];
  oldValue: Scalars["String"];
};

export type ItemStatusCollection = {
  __typename?: "ItemStatusCollection";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
  isInternal: Scalars["Boolean"];
  name: Scalars["String"];
  productId: Scalars["ID"];
  status: ItemStatusCollectionStatus;
  statusOptions: ItemStatusOptionList;
};

export type ItemStatusCollectionList = {
  __typename?: "ItemStatusCollectionList";
  items: Array<ItemStatusCollection>;
};

export enum ItemStatusCollectionStatus {
  Active = "Active",
  Removed = "Removed",
}

export type ItemStatusOption = {
  __typename?: "ItemStatusOption";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  description: Scalars["String"];
  displayOrder: Scalars["Int"];
  equatesToRemoved: Scalars["Boolean"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  optionReasons: ItemStatusOptionReasonList;
  status: ItemStatusOptionStatus;
  statusCollectionId: Scalars["ID"];
  style: ItemStatusOptionStyles;
};

export type ItemStatusOptionList = {
  __typename?: "ItemStatusOptionList";
  items: Array<ItemStatusOption>;
};

export type ItemStatusOptionReason = {
  __typename?: "ItemStatusOptionReason";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  description: Scalars["String"];
  displayOrder: Scalars["Int"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  status: ItemStatusOptionReasonStatus;
  statusOptionId: Scalars["ID"];
};

export type ItemStatusOptionReasonList = {
  __typename?: "ItemStatusOptionReasonList";
  items: Array<ItemStatusOptionReason>;
};

export enum ItemStatusOptionReasonStatus {
  Active = "Active",
  Removed = "Removed",
}

export enum ItemStatusOptionStatus {
  Active = "Active",
  Removed = "Removed",
}

export enum ItemStatusOptionStyles {
  Amber = "Amber",
  Black = "Black",
  Green = "Green",
  Grey = "Grey",
}

export type ListItem = {
  __typename?: "ListItem";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type LookupCollection = {
  __typename?: "LookupCollection";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  name: Scalars["String"];
  options: LookupOptionList;
  optionsCharLengthLimit?: Maybe<Scalars["Int"]>;
  optionsLockedForUsers: Scalars["Boolean"];
  status: LookupCollectionStatus;
};

export type LookupCollectionList = {
  __typename?: "LookupCollectionList";
  items: Array<LookupCollection>;
};

export enum LookupCollectionStatus {
  Active = "Active",
  Removed = "Removed",
}

export type LookupOption = {
  __typename?: "LookupOption";
  collectionId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  displayOrder: Scalars["Int"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  status: LookupOptionStatus;
  value: Scalars["String"];
};

export type LookupOptionList = {
  __typename?: "LookupOptionList";
  items: Array<LookupOption>;
};

export enum LookupOptionStatus {
  Active = "Active",
  Removed = "Removed",
}

export type Mutation = {
  __typename?: "Mutation";
  addAuthorizationWorkflow: AuthorizationWorkflow;
  addAuthorizationWorkflowActionMapping: AuthorizationWorkflowActionMapping;
  addComment: Comment;
  addCompany: Company;
  addCompanyLookupCollection: CompanyLookupCollection;
  addContract: Contract;
  addContractBinding: ContractBinding;
  addDailyDiaryDelayRecord: DailyDiaryDelayRecord;
  addDailyDiaryEquipmentRecord: DailyDiaryResourceRecord;
  addDailyDiaryGeneralRecord: DailyDiaryGeneralRecord;
  addDailyDiaryHSERecord: DailyDiaryHseRecord;
  addDailyDiaryManpowerRecord: DailyDiaryResourceRecord;
  addDailyDiaryPreset: DailyDiaryPreset;
  addDailyDiaryWeatherRecord: DailyDiaryWeatherRecord;
  addDailyDiaryWorkRecord: DailyDiaryWorkRecord;
  addDocumentTemplate: DocumentTemplate;
  addDocumentTemplateRoute: DocumentTemplateRoute;
  addDocumentTemplateRouteOverride: DocumentTemplateRoute;
  addDraftAssessment: DraftAssessment;
  addDraftClaimAgreement: DraftClaimAgreement;
  addDraftClaimDetermination: DraftClaimDetermination;
  addDraftDetailedClaim: DraftDetailedClaim;
  addDraftEarlyWarningItem: EarlyWarningItem;
  addDraftEventItem: EventItem;
  addDraftInstructionItem: InstructionItem;
  addDraftQuotation: DraftQuotation;
  addDraftRiskItem: RiskItem;
  addDraftVariationAgreement: DraftVariationAgreement;
  addDraftVariationDetermination: DraftVariationDetermination;
  addDraftVariationProposal: DraftVariationProposal;
  addEffectOption: EffectOption;
  addEffectPreset: EffectPreset;
  addEmailTemplate: EmailTemplate;
  addEventItem: EventItem;
  addItemStatusCollection: ItemStatusCollection;
  addItemStatusOption: ItemStatusOption;
  addItemStatusOptionReason: ItemStatusOptionReason;
  addLookupCollection: LookupCollection;
  addLookupOption: LookupOption;
  addNewClaimTypeOverride: NewClaimTypeOverride;
  addProductInstance: ProductInstance;
  addProductRole: ProductRole;
  addProductRoleAction: ProductRoleAction;
  addProductSchema: ProductSchema;
  addProductUserRole: ProductUserRole;
  addProject: Project;
  addRiskItem: RiskItem;
  addSchemaField: SchemaField;
  addSchemaSection: SchemaSection;
  addSeverityPreset: SeverityPreset;
  addSeverityRating: SeverityRating;
  addTimePeriodOverride: TimePeriodOverride;
  adminResetPassword: AdminResetPasswordResponse;
  bulkAssignUserRoles: Scalars["Boolean"];
  changeAuthorizationWorkflowStatus: AuthorizationWorkflow;
  changeCompanyLookupCollectionStatus: CompanyLookupCollection;
  changeCompanyStatus: Company;
  changeContractBindingStatus: ContractBinding;
  changeContractStatus: Contract;
  changeDailyDiaryPresetStatus: DailyDiaryPreset;
  changeDocumentTemplateStatus: DocumentTemplate;
  changeEarlyWarningItemOwner: EarlyWarningItem;
  changeEarlyWarningItemStatus: EarlyWarningItem;
  changeEffectOptionStatus: EffectOption;
  changeEffectPresetStatus: EffectPreset;
  changeEmailTemplateStatus: EmailTemplate;
  changeEventItemOwner: EventItem;
  changeEventItemStatus: EventItem;
  changeInstructionItemOwner: InstructionItem;
  changeInstructionItemStatus: InstructionItem;
  changeItemStatusCollectionStatus: ItemStatusCollection;
  changeItemStatusOptionReasonStatus: ItemStatusOptionReason;
  changeItemStatusOptionStatus: ItemStatusOption;
  changeLookupCollectionStatus: LookupCollection;
  changeLookupOptionStatus: LookupOption;
  changeNewClaimTypeOverrideStatus: NewClaimTypeOverride;
  changeProductInstanceStatus: ProductInstance;
  changeProductRoleActionStatus: ProductRoleAction;
  changeProductRoleStatus: ProductRole;
  changeProductSchemaStatus: ProductSchema;
  changeProductUserRoleStatus: ProductUserRole;
  changeProjectStatus: Project;
  changeRiskItemOwner: RiskItem;
  changeRiskItemStatus: RiskItem;
  changeSchemaFieldStatus: SchemaField;
  changeSchemaSectionStatus: SchemaSection;
  changeSeverityPresetStatus: SeverityPreset;
  changeSeverityRatingStatus: SeverityRating;
  changeUserCompany: User;
  changeUserStatus: User;
  createUploadAttachmentPreSignedUrl: AttachmentPreSignedUrl;
  disableClaimType: DisabledClaimTypeOverride;
  editAuthorizationWorkflow: AuthorizationWorkflow;
  editAuthorizationWorkflowActionMapping: AuthorizationWorkflowActionMapping;
  editCompany: Company;
  editCompanyLookupCollection: CompanyLookupCollection;
  editContract: Contract;
  editContractBinding: ContractBinding;
  editDailyDiaryDelayRecord: DailyDiaryDelayRecord;
  editDailyDiaryEquipmentRecord: DailyDiaryResourceRecord;
  editDailyDiaryGeneralRecord: DailyDiaryGeneralRecord;
  editDailyDiaryHSERecord: DailyDiaryHseRecord;
  editDailyDiaryManpowerRecord: DailyDiaryResourceRecord;
  editDailyDiaryPreset: DailyDiaryPreset;
  editDailyDiaryWeatherRecord: DailyDiaryWeatherRecord;
  editDailyDiaryWorkRecord: DailyDiaryWorkRecord;
  editDocumentTemplate: DocumentTemplate;
  editDocumentTemplateRoute: DocumentTemplateRoute;
  editDocumentTemplateRouteOverride: DocumentTemplateRoute;
  editDraftAssessment: DraftAssessment;
  editDraftClaimAgreement: DraftClaimAgreement;
  editDraftClaimDetermination: DraftClaimDetermination;
  editDraftDetailedClaim: DraftDetailedClaim;
  editDraftEarlyWarningItem: EarlyWarningItem;
  editDraftEventItem: EventItem;
  editDraftInstructionItem: InstructionItem;
  editDraftQuotation: DraftQuotation;
  editDraftRiskItem: RiskItem;
  editDraftVariationAgreement: DraftVariationAgreement;
  editDraftVariationDetermination: DraftVariationDetermination;
  editDraftVariationProposal: DraftVariationProposal;
  editEarlyWarningItem: EarlyWarningItem;
  editEffectPreset: EffectPreset;
  editEmailTemplate: EmailTemplate;
  editEventItem: EventItem;
  editInstructionItem: InstructionItem;
  editItemStatusCollection: ItemStatusCollection;
  editItemStatusOption: ItemStatusOption;
  editItemStatusOptionReason: ItemStatusOptionReason;
  editLookupCollection: LookupCollection;
  editLookupOption: LookupOption;
  editProductInstance: ProductInstance;
  editProductRole: ProductRole;
  editProductRoleAction: ProductRoleAction;
  editProductSchema: ProductSchema;
  editProductUserRole: ProductUserRole;
  editProject: Project;
  editRiskItem: RiskItem;
  editSchemaField: SchemaField;
  editSchemaSection: SchemaSection;
  editSeverityPreset: SeverityPreset;
  editSeverityRating: SeverityRating;
  editUser: User;
  enableClaimType: Scalars["Boolean"];
  generateEarlyWarningPreview: EarlyWarningItemPreview;
  generateInstructionPreview: InstructionItemPreview;
  generateRecordClaimAgreementNoticePreview: RecordClaimAgreementNoticePreview;
  generateRecordClaimDetailsResponsePreview: RecordClaimDetailsResponsePreview;
  generateRecordClaimDeterminationNoticePreview: RecordClaimDeterminationNoticePreview;
  generateRecordClaimErrorAgreementDisagreementPreview: RecordClaimErrorAgreementDisagreementPreview;
  generateRecordClaimErrorAgreementNoticePreview: RecordClaimErrorAgreementNoticePreview;
  generateRecordClaimErrorDeterminationDisagreementPreview: RecordClaimErrorDeterminationDisagreementPreview;
  generateRecordClaimErrorDeterminationNoticePreview: RecordClaimErrorDeterminationNoticePreview;
  generateRecordClaimFinalClaimDetailsPreview: RecordClaimFinalClaimDetailsPreview;
  generateRecordClaimFurtherParticularsRequestPreview: RecordClaimFurtherParticularsRequestPreview;
  generateRecordClaimFurtherParticularsSubmissionPreview: RecordClaimFurtherParticularsSubmissionPreview;
  generateRecordClaimInterimClaimDetailsPreview: RecordClaimInterimClaimDetailsPreview;
  generateRecordClaimNoAgreementNoticePreview: RecordClaimNoAgreementNoticePreview;
  generateRecordClaimNoticeInvalidNoticePreview: RecordClaimNoticeInvalidNoticePreview;
  generateRecordClaimNoticeLapsedNoticePreview: RecordClaimNoticeLapsedNoticePreview;
  generateRecordClaimNoticePreview: RecordClaimNoticePreview;
  generateRecordCompEventAbandonInstructionPreview: RecordCompEventAbandonInstructionPreview;
  generateRecordCompEventConfirmationPreview: RecordCompEventConfirmationPreview;
  generateRecordCompEventDenyingOfMoreTimePreview: RecordCompEventDenyingOfMoreTimePreview;
  generateRecordCompEventGrantingOfMoreTimePreview: RecordCompEventGrantingOfMoreTimePreview;
  generateRecordCompEventNoticeOfSilenceCompEventNoticePreview: RecordCompEventNoticeOfSilenceCompEventNoticePreview;
  generateRecordCompEventNoticeOfSilenceOwnAssessmentPreview: RecordCompEventNoticeOfSilenceOwnAssessmentPreview;
  generateRecordCompEventNoticeOfSilenceQuotationPreview: RecordCompEventNoticeOfSilenceQuotationPreview;
  generateRecordCompEventNoticePreview: RecordCompEventNoticePreview;
  generateRecordCompEventNoticeRequestQuotationPreview: RecordCompEventNoticeRequestQuotationPreview;
  generateRecordCompEventOwnAssessmentNoticePreview: RecordCompEventOwnAssessmentNoticePreview;
  generateRecordCompEventOwnAssessmentPreview: RecordCompEventOwnAssessmentPreview;
  generateRecordCompEventQuotationAcceptancePreview: RecordCompEventQuotationAcceptancePreview;
  generateRecordCompEventQuotationPreview: RecordCompEventQuotationPreview;
  generateRecordCompEventQuotationRevisionRequestPreview: RecordCompEventQuotationRevisionRequestPreview;
  generateRecordCompEventRejectionPreview: RecordCompEventRejectionPreview;
  generateRecordCompEventRequestForMoreTimePreview: RecordCompEventRequestForMoreTimePreview;
  generateRecordVariationAgreementNoticePreview: RecordVariationAgreementNoticePreview;
  generateRecordVariationConfirmationPreview: RecordVariationConfirmationPreview;
  generateRecordVariationDeterminationNoticePreview: RecordVariationDeterminationNoticePreview;
  generateRecordVariationErrorAgreementDisagreementPreview: RecordVariationErrorAgreementDisagreementPreview;
  generateRecordVariationErrorAgreementNoticePreview: RecordVariationErrorAgreementNoticePreview;
  generateRecordVariationErrorDeterminationDisagreementPreview: RecordVariationErrorDeterminationDisagreementPreview;
  generateRecordVariationErrorDeterminationNoticePreview: RecordVariationErrorDeterminationNoticePreview;
  generateRecordVariationFurtherParticularsRequestPreview: RecordVariationFurtherParticularsRequestPreview;
  generateRecordVariationFurtherParticularsSubmissionPreview: RecordVariationFurtherParticularsSubmissionPreview;
  generateRecordVariationInstructionCommenceWorkPreview: RecordVariationInstructionCommenceWorkPreview;
  generateRecordVariationNoAgreementNoticePreview: RecordVariationNoAgreementNoticePreview;
  generateRecordVariationObjectionProposedVariationPreview: RecordVariationObjectionProposedVariationPreview;
  generateRecordVariationObjectionVariationPreview: RecordVariationObjectionVariationPreview;
  generateRecordVariationProposalAbandonmentPreview: RecordVariationProposalAbandonmentPreview;
  generateRecordVariationProposalConsentForProposedVariationPreview: RecordVariationProposalConsentForProposedVariationPreview;
  generateRecordVariationProposalForProposedVariationPreview: RecordVariationProposalForProposedVariationPreview;
  generateRecordVariationProposalForVariationPreview: RecordVariationProposalForVariationPreview;
  generateRecordVariationRevisedProposalForProposedVariationRequestPreview: RecordVariationRevisedProposalForProposedVariationRequestPreview;
  generateRecordVariationRevocationPreview: RecordVariationRevocationPreview;
  generateSendClaimAgreementNoticePreview: SendClaimAgreementNoticePreview;
  generateSendClaimDetailsResponsePreview: SendClaimDetailsResponsePreview;
  generateSendClaimDeterminationNoticePreview: SendClaimDeterminationNoticePreview;
  generateSendClaimErrorAgreementDisagreementPreview: SendClaimErrorAgreementDisagreementPreview;
  generateSendClaimErrorAgreementNoticePreview: SendClaimErrorAgreementNoticePreview;
  generateSendClaimErrorDeterminationDisagreementPreview: SendClaimErrorDeterminationDisagreementPreview;
  generateSendClaimErrorDeterminationNoticePreview: SendClaimErrorDeterminationNoticePreview;
  generateSendClaimFinalClaimDetailsPreview: SendClaimFinalClaimDetailsPreview;
  generateSendClaimFurtherParticularsRequestPreview: SendClaimFurtherParticularsRequestPreview;
  generateSendClaimFurtherParticularsSubmissionPreview: SendClaimFurtherParticularsSubmissionPreview;
  generateSendClaimInterimClaimDetailsPreview: SendClaimInterimClaimDetailsPreview;
  generateSendClaimNoAgreementNoticePreview: SendClaimNoAgreementNoticePreview;
  generateSendClaimNoticeInvalidNoticePreview: SendClaimNoticeInvalidNoticePreview;
  generateSendClaimNoticeLapsedNoticePreview: SendClaimNoticeLapsedNoticePreview;
  generateSendClaimNoticePreview: SendClaimNoticePreview;
  generateSendCompEventAbandonInstructionPreview: SendCompEventAbandonInstructionPreview;
  generateSendCompEventConfirmationPreview: SendCompEventConfirmationPreview;
  generateSendCompEventDenyingOfMoreTimePreview: SendCompEventDenyingOfMoreTimePreview;
  generateSendCompEventGrantingOfMoreTimePreview: SendCompEventGrantingOfMoreTimePreview;
  generateSendCompEventNoticeOfSilenceCompEventNoticePreview: SendCompEventNoticeOfSilenceCompEventNoticePreview;
  generateSendCompEventNoticeOfSilenceOwnAssessmentPreview: SendCompEventNoticeOfSilenceOwnAssessmentPreview;
  generateSendCompEventNoticeOfSilenceQuotationPreview: SendCompEventNoticeOfSilenceQuotationPreview;
  generateSendCompEventNoticePreview: SendCompEventNoticePreview;
  generateSendCompEventNoticeRequestQuotationPreview: SendCompEventNoticeRequestQuotationPreview;
  generateSendCompEventOwnAssessmentNoticePreview: SendCompEventOwnAssessmentNoticePreview;
  generateSendCompEventOwnAssessmentPreview: SendCompEventOwnAssessmentPreview;
  generateSendCompEventQuotationAcceptancePreview: SendCompEventQuotationAcceptancePreview;
  generateSendCompEventQuotationPreview: SendCompEventQuotationPreview;
  generateSendCompEventQuotationRevisionRequestPreview: SendCompEventQuotationRevisionRequestPreview;
  generateSendCompEventRejectionPreview: SendCompEventRejectionPreview;
  generateSendCompEventRequestForMoreTimePreview: SendCompEventRequestForMoreTimePreview;
  generateSendVariationAgreementNoticePreview: SendVariationAgreementNoticePreview;
  generateSendVariationConfirmationPreview: SendVariationConfirmationPreview;
  generateSendVariationDeterminationNoticePreview: SendVariationDeterminationNoticePreview;
  generateSendVariationErrorAgreementDisagreementPreview: SendVariationErrorAgreementDisagreementPreview;
  generateSendVariationErrorAgreementNoticePreview: SendVariationErrorAgreementNoticePreview;
  generateSendVariationErrorDeterminationDisagreementPreview: SendVariationErrorDeterminationDisagreementPreview;
  generateSendVariationErrorDeterminationNoticePreview: SendVariationErrorDeterminationNoticePreview;
  generateSendVariationFurtherParticularsRequestPreview: SendVariationFurtherParticularsRequestPreview;
  generateSendVariationFurtherParticularsSubmissionPreview: SendVariationFurtherParticularsSubmissionPreview;
  generateSendVariationInstructionCommenceWorkPreview: SendVariationInstructionCommenceWorkPreview;
  generateSendVariationNoAgreementNoticePreview: SendVariationNoAgreementNoticePreview;
  generateSendVariationObjectionProposedVariationPreview: SendVariationObjectionProposedVariationPreview;
  generateSendVariationObjectionVariationPreview: SendVariationObjectionVariationPreview;
  generateSendVariationProposalAbandonmentPreview: SendVariationProposalAbandonmentPreview;
  generateSendVariationProposalConsentForProposedVariationPreview: SendVariationProposalConsentForProposedVariationPreview;
  generateSendVariationProposalForProposedVariationPreview: SendVariationProposalForProposedVariationPreview;
  generateSendVariationProposalForVariationPreview: SendVariationProposalForVariationPreview;
  generateSendVariationRevisedProposalForProposedVariationRequestPreview: SendVariationRevisedProposalForProposedVariationRequestPreview;
  generateSendVariationRevocationPreview: SendVariationRevocationPreview;
  importDailyDiaryDelayRecords: Scalars["Boolean"];
  importDailyDiaryEquipmentRecords: Scalars["Boolean"];
  importDailyDiaryGeneralRecords: Scalars["Boolean"];
  importDailyDiaryHSERecords: Scalars["Boolean"];
  importDailyDiaryManpowerRecords: Scalars["Boolean"];
  importDailyDiaryWeatherRecords: Scalars["Boolean"];
  importDailyDiaryWorkRecords: Scalars["Boolean"];
  inviteUser: InviteUserResponse;
  markAllNotificationsAsRead: Scalars["Boolean"];
  markNotification: Notification;
  recordClaimAgreementNotice: ClaimItem;
  recordClaimDetailsResponse: ClaimItem;
  recordClaimDeterminationNotice: ClaimItem;
  recordClaimErrorAgreementDisagreement: ClaimItem;
  recordClaimErrorAgreementNotice: ClaimItem;
  recordClaimErrorDeterminationDisagreement: ClaimItem;
  recordClaimErrorDeterminationNotice: ClaimItem;
  recordClaimFinalClaimDetails: ClaimItem;
  recordClaimFurtherParticularsRequest: ClaimItem;
  recordClaimFurtherParticularsSubmission: ClaimItem;
  recordClaimInterimClaimDetails: ClaimItem;
  recordClaimNoAgreementNotice: ClaimItem;
  recordClaimNotice: ClaimItem;
  recordClaimNoticeInvalidNotice: ClaimItem;
  recordClaimNoticeLapsedNotice: ClaimItem;
  recordCompEventAbandonInstruction: CompEventItem;
  recordCompEventConfirmation: CompEventItem;
  recordCompEventDenyingOfMoreTime: CompEventItem;
  recordCompEventGrantingOfMoreTime: CompEventItem;
  recordCompEventNotice: CompEventItem;
  recordCompEventNoticeOfSilenceCompEventNotice: CompEventItem;
  recordCompEventNoticeOfSilenceOwnAssessment: CompEventItem;
  recordCompEventNoticeOfSilenceQuotation: CompEventItem;
  recordCompEventNoticeRequestQuotation: CompEventItem;
  recordCompEventOwnAssessment: CompEventItem;
  recordCompEventOwnAssessmentNotice: CompEventItem;
  recordCompEventQuotation: CompEventItem;
  recordCompEventQuotationAcceptance: CompEventItem;
  recordCompEventQuotationRevisionRequest: CompEventItem;
  recordCompEventRejection: CompEventItem;
  recordCompEventRequestForMoreTime: CompEventItem;
  recordEarlyWarningItem: EarlyWarningItem;
  recordInstructionItem: InstructionItem;
  recordVariationAgreementNotice: VariationItem;
  recordVariationConfirmation: VariationItem;
  recordVariationDeterminationNotice: VariationItem;
  recordVariationErrorAgreementDisagreement: VariationItem;
  recordVariationErrorAgreementNotice: VariationItem;
  recordVariationErrorDeterminationDisagreement: VariationItem;
  recordVariationErrorDeterminationNotice: VariationItem;
  recordVariationFurtherParticularsRequest: VariationItem;
  recordVariationFurtherParticularsSubmission: VariationItem;
  recordVariationInstructionCommenceWork: VariationItem;
  recordVariationNoAgreementNotice: VariationItem;
  recordVariationObjectionProposedVariation: VariationItem;
  recordVariationObjectionVariation: VariationItem;
  recordVariationProposalAbandonment: VariationItem;
  recordVariationProposalConsentForProposedVariation: VariationItem;
  recordVariationProposalForProposedVariation: VariationItem;
  recordVariationProposalForVariation: VariationItem;
  recordVariationRevisedProposalForProposedVariationRequest: VariationItem;
  recordVariationRevocation: VariationItem;
  registerUser: User;
  removeAttachmentFile: Scalars["Boolean"];
  removeAuthorizationWorkflowActionMapping: AuthorizationWorkflowActionMapping;
  removeClaimPreview: Scalars["Boolean"];
  removeCompEventPreview: Scalars["Boolean"];
  removeDailyDiaryDelayRecord?: Maybe<Scalars["Boolean"]>;
  removeDailyDiaryEquipmentRecord: Scalars["Boolean"];
  removeDailyDiaryGeneralRecord?: Maybe<Scalars["Boolean"]>;
  removeDailyDiaryHSERecord?: Maybe<Scalars["Boolean"]>;
  removeDailyDiaryManpowerRecord: Scalars["Boolean"];
  removeDailyDiaryWeatherRecord: Scalars["Boolean"];
  removeDailyDiaryWorkRecord?: Maybe<Scalars["Boolean"]>;
  removeDocumentTemplateRoute: Scalars["Boolean"];
  removeDocumentTemplateRouteOverride: Scalars["Boolean"];
  removeDraftAssessment: Scalars["Boolean"];
  removeDraftClaimAgreement: Scalars["Boolean"];
  removeDraftClaimDetermination: Scalars["Boolean"];
  removeDraftDetailedClaim: Scalars["Boolean"];
  removeDraftEarlyWarningItem: Scalars["Boolean"];
  removeDraftEventItem: Scalars["Boolean"];
  removeDraftInstructionItem: Scalars["Boolean"];
  removeDraftQuotation: Scalars["Boolean"];
  removeDraftRiskItem: Scalars["Boolean"];
  removeDraftVariationAgreement: Scalars["Boolean"];
  removeDraftVariationDetermination: Scalars["Boolean"];
  removeDraftVariationProposal: Scalars["Boolean"];
  removeEarlyWarningPreview: Scalars["Boolean"];
  removeInstructionPreview: Scalars["Boolean"];
  removeTimePeriodOverride: Scalars["Boolean"];
  removeVariationPreview: Scalars["Boolean"];
  replyComment: CommentReply;
  resendInvite: InviteUserResponse;
  reviseDailyDiaryItem: DailyDiaryItem;
  /** @deprecated Field no longer supported */
  sendAuthorizationAcceptance: Scalars["Boolean"];
  sendAuthorizationAcceptanceWithResult: SendAuthorizationAcceptanceResult;
  sendAuthorizationDenial: Scalars["Boolean"];
  sendClaimAgreementNotice: ClaimItem;
  sendClaimDetailsResponse: ClaimItem;
  sendClaimDeterminationNotice: ClaimItem;
  sendClaimErrorAgreementDisagreement: ClaimItem;
  sendClaimErrorAgreementNotice: ClaimItem;
  sendClaimErrorDeterminationDisagreement: ClaimItem;
  sendClaimErrorDeterminationNotice: ClaimItem;
  sendClaimFinalClaimDetails: ClaimItem;
  sendClaimFurtherParticularsRequest: ClaimItem;
  sendClaimFurtherParticularsSubmission: ClaimItem;
  sendClaimInterimClaimDetails: ClaimItem;
  sendClaimNoAgreementNotice: ClaimItem;
  sendClaimNotice: ClaimItem;
  sendClaimNoticeInvalidNotice: ClaimItem;
  sendClaimNoticeLapsedNotice: ClaimItem;
  sendCompEventAbandonInstruction: CompEventItem;
  sendCompEventConfirmation: CompEventItem;
  sendCompEventDenyingOfMoreTime: CompEventItem;
  sendCompEventGrantingOfMoreTime: CompEventItem;
  sendCompEventNotice: CompEventItem;
  sendCompEventNoticeOfSilenceCompEventNotice: CompEventItem;
  sendCompEventNoticeOfSilenceOwnAssessment: CompEventItem;
  sendCompEventNoticeOfSilenceQuotation: CompEventItem;
  sendCompEventNoticeRequestQuotation: CompEventItem;
  sendCompEventOwnAssessment: CompEventItem;
  sendCompEventOwnAssessmentNotice: CompEventItem;
  sendCompEventQuotation: CompEventItem;
  sendCompEventQuotationAcceptance: CompEventItem;
  sendCompEventQuotationRevisionRequest: CompEventItem;
  sendCompEventRejection: CompEventItem;
  sendCompEventRequestForMoreTime: CompEventItem;
  sendDailyDiaryItem: DailyDiaryItem;
  sendEarlyWarningItem: EarlyWarningItem;
  sendInstructionItem: InstructionItem;
  sendVariationAgreementNotice: VariationItem;
  sendVariationConfirmation: VariationItem;
  sendVariationDeterminationNotice: VariationItem;
  sendVariationErrorAgreementDisagreement: VariationItem;
  sendVariationErrorAgreementNotice: VariationItem;
  sendVariationErrorDeterminationDisagreement: VariationItem;
  sendVariationErrorDeterminationNotice: VariationItem;
  sendVariationFurtherParticularsRequest: VariationItem;
  sendVariationFurtherParticularsSubmission: VariationItem;
  sendVariationInstructionCommenceWork: VariationItem;
  sendVariationNoAgreementNotice: VariationItem;
  sendVariationObjectionProposedVariation: VariationItem;
  sendVariationObjectionVariation: VariationItem;
  sendVariationProposalAbandonment: VariationItem;
  sendVariationProposalConsentForProposedVariation: VariationItem;
  sendVariationProposalForProposedVariation: VariationItem;
  sendVariationProposalForVariation: VariationItem;
  sendVariationRevisedProposalForProposedVariationRequest: VariationItem;
  sendVariationRevocation: VariationItem;
  setUserPreferences: UserPreferences;
  userChangePassword: UserChangePasswordResponse;
};

export type MutationAddAuthorizationWorkflowArgs = {
  input: AddAuthorizationWorkflowInput;
};

export type MutationAddAuthorizationWorkflowActionMappingArgs = {
  input: AddAuthorizationActionInput;
};

export type MutationAddCommentArgs = {
  input: AddCommentInput;
};

export type MutationAddCompanyArgs = {
  input: AddCompanyInput;
};

export type MutationAddCompanyLookupCollectionArgs = {
  input: AddCompanyLookupCollectionInput;
};

export type MutationAddContractArgs = {
  input: AddContractInput;
};

export type MutationAddContractBindingArgs = {
  input: AddContractBindingInput;
};

export type MutationAddDailyDiaryDelayRecordArgs = {
  input: AddDailyDiaryDelayRecordInput;
};

export type MutationAddDailyDiaryEquipmentRecordArgs = {
  input: AddDailyDiaryResourceRecordInput;
};

export type MutationAddDailyDiaryGeneralRecordArgs = {
  input: AddDailyDiaryGeneralRecordInput;
};

export type MutationAddDailyDiaryHseRecordArgs = {
  input: AddDailyDiaryHseRecordInput;
};

export type MutationAddDailyDiaryManpowerRecordArgs = {
  input: AddDailyDiaryResourceRecordInput;
};

export type MutationAddDailyDiaryPresetArgs = {
  input: AddDailyDiaryPresetInput;
};

export type MutationAddDailyDiaryWeatherRecordArgs = {
  input: AddDailyDiaryWeatherRecordInput;
};

export type MutationAddDailyDiaryWorkRecordArgs = {
  input: AddDailyDiaryWorkRecordInput;
};

export type MutationAddDocumentTemplateArgs = {
  input: AddDocumentTemplateInput;
};

export type MutationAddDocumentTemplateRouteArgs = {
  input: AddDocumentTemplateRouteInput;
};

export type MutationAddDocumentTemplateRouteOverrideArgs = {
  input: AddDocumentTemplateRouteOverrideInput;
};

export type MutationAddDraftAssessmentArgs = {
  input: AddDraftAssessmentInput;
};

export type MutationAddDraftClaimAgreementArgs = {
  input: AddDraftClaimAgreementInput;
};

export type MutationAddDraftClaimDeterminationArgs = {
  input: AddDraftClaimDeterminationInput;
};

export type MutationAddDraftDetailedClaimArgs = {
  input: AddDraftDetailedClaimInput;
};

export type MutationAddDraftEarlyWarningItemArgs = {
  input: SendEarlyWarningItemInput;
};

export type MutationAddDraftEventItemArgs = {
  input: AddEventItemInput;
};

export type MutationAddDraftInstructionItemArgs = {
  input: SendInstructionItemInput;
};

export type MutationAddDraftQuotationArgs = {
  input: AddDraftQuotationInput;
};

export type MutationAddDraftRiskItemArgs = {
  input: AddRiskItemInput;
};

export type MutationAddDraftVariationAgreementArgs = {
  input: AddDraftVariationAgreementInput;
};

export type MutationAddDraftVariationDeterminationArgs = {
  input: AddDraftVariationDeterminationInput;
};

export type MutationAddDraftVariationProposalArgs = {
  input: AddDraftVariationProposalInput;
};

export type MutationAddEffectOptionArgs = {
  input: AddEffectOptionInput;
};

export type MutationAddEffectPresetArgs = {
  input: AddEffectPresetInput;
};

export type MutationAddEmailTemplateArgs = {
  input: AddEmailTemplateInput;
};

export type MutationAddEventItemArgs = {
  draftId?: InputMaybe<Scalars["ID"]>;
  input: AddEventItemInput;
};

export type MutationAddItemStatusCollectionArgs = {
  input: AddItemStatusCollectionInput;
};

export type MutationAddItemStatusOptionArgs = {
  input: AddItemStatusOptionInput;
};

export type MutationAddItemStatusOptionReasonArgs = {
  input: AddItemStatusOptionReasonInput;
};

export type MutationAddLookupCollectionArgs = {
  input: AddLookupCollectionInput;
};

export type MutationAddLookupOptionArgs = {
  input: AddLookupOptionInput;
};

export type MutationAddNewClaimTypeOverrideArgs = {
  input: AddNewClaimTypeOverrideInput;
};

export type MutationAddProductInstanceArgs = {
  input: AddProductInstanceInput;
};

export type MutationAddProductRoleArgs = {
  input: AddProductRoleInput;
};

export type MutationAddProductRoleActionArgs = {
  input: AddProductRoleActionInput;
};

export type MutationAddProductSchemaArgs = {
  input: AddProductSchemaInput;
};

export type MutationAddProductUserRoleArgs = {
  input: AddProductUserRoleInput;
};

export type MutationAddProjectArgs = {
  input: AddProjectInput;
};

export type MutationAddRiskItemArgs = {
  draftId?: InputMaybe<Scalars["ID"]>;
  input: AddRiskItemInput;
};

export type MutationAddSchemaFieldArgs = {
  input: AddSchemaFieldInput;
};

export type MutationAddSchemaSectionArgs = {
  input: AddSchemaSectionInput;
};

export type MutationAddSeverityPresetArgs = {
  input: AddSeverityPresetInput;
};

export type MutationAddSeverityRatingArgs = {
  input: AddSeverityRatingInput;
};

export type MutationAddTimePeriodOverrideArgs = {
  input: AddTimePeriodOverrideInput;
};

export type MutationAdminResetPasswordArgs = {
  input: AdminResetPasswordInput;
};

export type MutationBulkAssignUserRolesArgs = {
  input: BulkUserRoleAssignmentInput;
};

export type MutationChangeAuthorizationWorkflowStatusArgs = {
  id: Scalars["ID"];
  status: AuthorizationWorkflowStatus;
};

export type MutationChangeCompanyLookupCollectionStatusArgs = {
  id: Scalars["ID"];
  status: CompanyLookupCollectionStatus;
};

export type MutationChangeCompanyStatusArgs = {
  id: Scalars["ID"];
  status: CompanyStatus;
};

export type MutationChangeContractBindingStatusArgs = {
  id: Scalars["ID"];
  status: ContractBindingStatus;
};

export type MutationChangeContractStatusArgs = {
  id: Scalars["ID"];
  status: ContractStatus;
};

export type MutationChangeDailyDiaryPresetStatusArgs = {
  id: Scalars["ID"];
  status: DailyDiaryPresetStatus;
};

export type MutationChangeDocumentTemplateStatusArgs = {
  id: Scalars["ID"];
  status: DocumentTemplateStatus;
};

export type MutationChangeEarlyWarningItemOwnerArgs = {
  id: Scalars["ID"];
  ownerId: Scalars["ID"];
};

export type MutationChangeEarlyWarningItemStatusArgs = {
  input: ChangeEarlyWarningItemStatusInput;
};

export type MutationChangeEffectOptionStatusArgs = {
  id: Scalars["ID"];
  status: EffectOptionStatus;
};

export type MutationChangeEffectPresetStatusArgs = {
  id: Scalars["ID"];
  status: EffectPresetStatus;
};

export type MutationChangeEmailTemplateStatusArgs = {
  id: Scalars["ID"];
  status: EmailTemplateStatus;
};

export type MutationChangeEventItemOwnerArgs = {
  id: Scalars["ID"];
  ownerId: Scalars["ID"];
};

export type MutationChangeEventItemStatusArgs = {
  input: ChangeEventItemStatusInput;
};

export type MutationChangeInstructionItemOwnerArgs = {
  id: Scalars["ID"];
  ownerId: Scalars["ID"];
};

export type MutationChangeInstructionItemStatusArgs = {
  input: ChangeInstructionItemStatusInput;
};

export type MutationChangeItemStatusCollectionStatusArgs = {
  id: Scalars["ID"];
  status: ItemStatusCollectionStatus;
};

export type MutationChangeItemStatusOptionReasonStatusArgs = {
  id: Scalars["ID"];
  status: ItemStatusOptionReasonStatus;
};

export type MutationChangeItemStatusOptionStatusArgs = {
  id: Scalars["ID"];
  status: ItemStatusOptionStatus;
};

export type MutationChangeLookupCollectionStatusArgs = {
  id: Scalars["ID"];
  status: LookupCollectionStatus;
};

export type MutationChangeLookupOptionStatusArgs = {
  id: Scalars["ID"];
  status: LookupOptionStatus;
};

export type MutationChangeNewClaimTypeOverrideStatusArgs = {
  id: Scalars["ID"];
  status: NewClaimTypeOverrideStatus;
};

export type MutationChangeProductInstanceStatusArgs = {
  id: Scalars["ID"];
  status: ProductInstanceStatus;
};

export type MutationChangeProductRoleActionStatusArgs = {
  id: Scalars["ID"];
  status: ProductRoleActionStatus;
};

export type MutationChangeProductRoleStatusArgs = {
  id: Scalars["ID"];
  status: ProductRoleStatus;
};

export type MutationChangeProductSchemaStatusArgs = {
  id: Scalars["ID"];
  status: ProductSchemaStatus;
};

export type MutationChangeProductUserRoleStatusArgs = {
  id: Scalars["ID"];
  status: ProductUserRoleStatus;
};

export type MutationChangeProjectStatusArgs = {
  id: Scalars["ID"];
  status: ProjectStatus;
};

export type MutationChangeRiskItemOwnerArgs = {
  id: Scalars["ID"];
  ownerId: Scalars["ID"];
};

export type MutationChangeRiskItemStatusArgs = {
  input: ChangeRiskItemStatusInput;
};

export type MutationChangeSchemaFieldStatusArgs = {
  id: Scalars["ID"];
  status: SchemaFieldStatus;
};

export type MutationChangeSchemaSectionStatusArgs = {
  id: Scalars["ID"];
  status: SchemaSectionStatus;
};

export type MutationChangeSeverityPresetStatusArgs = {
  id: Scalars["ID"];
  status: SeverityPresetStatus;
};

export type MutationChangeSeverityRatingStatusArgs = {
  id: Scalars["ID"];
  status: SeverityRatingStatus;
};

export type MutationChangeUserCompanyArgs = {
  companyId: Scalars["ID"];
  id: Scalars["ID"];
};

export type MutationChangeUserStatusArgs = {
  id: Scalars["ID"];
  status: UserStatus;
};

export type MutationCreateUploadAttachmentPreSignedUrlArgs = {
  input: CreateUploadAttachmentPreSignedUrlInput;
};

export type MutationDisableClaimTypeArgs = {
  claimTypeId: Scalars["ID"];
  contractId: Scalars["ID"];
};

export type MutationEditAuthorizationWorkflowArgs = {
  input: EditAuthorizationWorkflowInput;
};

export type MutationEditAuthorizationWorkflowActionMappingArgs = {
  input: EditAuthorizationActionInput;
};

export type MutationEditCompanyArgs = {
  input: EditCompanyInput;
};

export type MutationEditCompanyLookupCollectionArgs = {
  input: EditCompanyLookupCollectionInput;
};

export type MutationEditContractArgs = {
  input: EditContractInput;
};

export type MutationEditContractBindingArgs = {
  input: EditContractBindingInput;
};

export type MutationEditDailyDiaryDelayRecordArgs = {
  input: EditDailyDiaryDelayRecordInput;
};

export type MutationEditDailyDiaryEquipmentRecordArgs = {
  input: EditDailyDiaryResourceRecordInput;
};

export type MutationEditDailyDiaryGeneralRecordArgs = {
  input: EditDailyDiaryGeneralRecordInput;
};

export type MutationEditDailyDiaryHseRecordArgs = {
  input: EditDailyDiaryHseRecordInput;
};

export type MutationEditDailyDiaryManpowerRecordArgs = {
  input: EditDailyDiaryResourceRecordInput;
};

export type MutationEditDailyDiaryPresetArgs = {
  input: EditDailyDiaryPresetInput;
};

export type MutationEditDailyDiaryWeatherRecordArgs = {
  input: EditDailyDiaryWeatherRecordInput;
};

export type MutationEditDailyDiaryWorkRecordArgs = {
  input: EditDailyDiaryWorkRecordInput;
};

export type MutationEditDocumentTemplateArgs = {
  input: EditDocumentTemplateInput;
};

export type MutationEditDocumentTemplateRouteArgs = {
  input: EditDocumentTemplateRouteInput;
};

export type MutationEditDocumentTemplateRouteOverrideArgs = {
  input: EditDocumentTemplateRouteOverrideInput;
};

export type MutationEditDraftAssessmentArgs = {
  input: EditDraftAssessmentInput;
};

export type MutationEditDraftClaimAgreementArgs = {
  input: EditDraftClaimAgreementInput;
};

export type MutationEditDraftClaimDeterminationArgs = {
  input: EditDraftClaimDeterminationInput;
};

export type MutationEditDraftDetailedClaimArgs = {
  input: EditDraftDetailedClaimInput;
};

export type MutationEditDraftEarlyWarningItemArgs = {
  input: EditDraftEarlyWarningItemInput;
};

export type MutationEditDraftEventItemArgs = {
  input: EditEventItemInput;
};

export type MutationEditDraftInstructionItemArgs = {
  input: EditDraftInstructionItemInput;
};

export type MutationEditDraftQuotationArgs = {
  input: EditDraftQuotationInput;
};

export type MutationEditDraftRiskItemArgs = {
  input: EditRiskItemInput;
};

export type MutationEditDraftVariationAgreementArgs = {
  input: EditDraftVariationAgreementInput;
};

export type MutationEditDraftVariationDeterminationArgs = {
  input: EditDraftVariationDeterminationInput;
};

export type MutationEditDraftVariationProposalArgs = {
  input: EditDraftVariationProposalInput;
};

export type MutationEditEarlyWarningItemArgs = {
  input: EditEarlyWarningItemInput;
};

export type MutationEditEffectPresetArgs = {
  input: EditEffectPresetInput;
};

export type MutationEditEmailTemplateArgs = {
  input: EditEmailTemplateInput;
};

export type MutationEditEventItemArgs = {
  input: EditEventItemInput;
};

export type MutationEditInstructionItemArgs = {
  input: EditInstructionItemInput;
};

export type MutationEditItemStatusCollectionArgs = {
  input: EditItemStatusCollectionInput;
};

export type MutationEditItemStatusOptionArgs = {
  input: EditItemStatusOptionInput;
};

export type MutationEditItemStatusOptionReasonArgs = {
  input: EditItemStatusOptionReasonInput;
};

export type MutationEditLookupCollectionArgs = {
  input: EditLookupCollectionInput;
};

export type MutationEditLookupOptionArgs = {
  input: EditLookupOptionInput;
};

export type MutationEditProductInstanceArgs = {
  input: EditProductInstanceInput;
};

export type MutationEditProductRoleArgs = {
  input: EditProductRoleInput;
};

export type MutationEditProductRoleActionArgs = {
  input: EditProductRoleActionInput;
};

export type MutationEditProductSchemaArgs = {
  input: EditProductSchemaInput;
};

export type MutationEditProductUserRoleArgs = {
  input: EditProductUserRoleInput;
};

export type MutationEditProjectArgs = {
  input: EditProjectInput;
};

export type MutationEditRiskItemArgs = {
  input: EditRiskItemInput;
};

export type MutationEditSchemaFieldArgs = {
  input: EditSchemaFieldInput;
};

export type MutationEditSchemaSectionArgs = {
  input: EditSchemaSectionInput;
};

export type MutationEditSeverityPresetArgs = {
  input: EditSeverityPresetInput;
};

export type MutationEditSeverityRatingArgs = {
  input: EditSeverityRatingInput;
};

export type MutationEditUserArgs = {
  input: EditUserInput;
};

export type MutationEnableClaimTypeArgs = {
  disabledClaimTypeId: Scalars["ID"];
};

export type MutationGenerateEarlyWarningPreviewArgs = {
  input: SendEarlyWarningItemInput;
};

export type MutationGenerateInstructionPreviewArgs = {
  input: SendInstructionItemInput;
};

export type MutationGenerateRecordClaimAgreementNoticePreviewArgs = {
  input: RecordClaimAgreementNoticePreviewInput;
};

export type MutationGenerateRecordClaimDetailsResponsePreviewArgs = {
  input: RecordClaimDetailsResponsePreviewInput;
};

export type MutationGenerateRecordClaimDeterminationNoticePreviewArgs = {
  input: RecordClaimDeterminationNoticePreviewInput;
};

export type MutationGenerateRecordClaimErrorAgreementDisagreementPreviewArgs = {
  input: RecordClaimErrorAgreementDisagreementPreviewInput;
};

export type MutationGenerateRecordClaimErrorAgreementNoticePreviewArgs = {
  input: RecordClaimErrorAgreementNoticePreviewInput;
};

export type MutationGenerateRecordClaimErrorDeterminationDisagreementPreviewArgs =
  {
    input: RecordClaimErrorDeterminationDisagreementPreviewInput;
  };

export type MutationGenerateRecordClaimErrorDeterminationNoticePreviewArgs = {
  input: RecordClaimErrorDeterminationNoticePreviewInput;
};

export type MutationGenerateRecordClaimFinalClaimDetailsPreviewArgs = {
  input: RecordClaimFinalClaimDetailsPreviewInput;
};

export type MutationGenerateRecordClaimFurtherParticularsRequestPreviewArgs = {
  input: RecordClaimFurtherParticularsRequestPreviewInput;
};

export type MutationGenerateRecordClaimFurtherParticularsSubmissionPreviewArgs =
  {
    input: RecordClaimFurtherParticularsSubmissionPreviewInput;
  };

export type MutationGenerateRecordClaimInterimClaimDetailsPreviewArgs = {
  input: RecordClaimInterimClaimDetailsPreviewInput;
};

export type MutationGenerateRecordClaimNoAgreementNoticePreviewArgs = {
  input: RecordClaimNoAgreementNoticePreviewInput;
};

export type MutationGenerateRecordClaimNoticeInvalidNoticePreviewArgs = {
  input: RecordClaimNoticeInvalidNoticePreviewInput;
};

export type MutationGenerateRecordClaimNoticeLapsedNoticePreviewArgs = {
  input: RecordClaimNoticeLapsedNoticePreviewInput;
};

export type MutationGenerateRecordClaimNoticePreviewArgs = {
  input: RecordClaimNoticePreviewInput;
};

export type MutationGenerateRecordCompEventAbandonInstructionPreviewArgs = {
  input: RecordCompEventAbandonInstructionPreviewInput;
};

export type MutationGenerateRecordCompEventConfirmationPreviewArgs = {
  input: RecordCompEventConfirmationPreviewInput;
};

export type MutationGenerateRecordCompEventDenyingOfMoreTimePreviewArgs = {
  input: RecordCompEventDenyingOfMoreTimePreviewInput;
};

export type MutationGenerateRecordCompEventGrantingOfMoreTimePreviewArgs = {
  input: RecordCompEventGrantingOfMoreTimePreviewInput;
};

export type MutationGenerateRecordCompEventNoticeOfSilenceCompEventNoticePreviewArgs =
  {
    input: RecordCompEventNoticeOfSilenceCompEventNoticePreviewInput;
  };

export type MutationGenerateRecordCompEventNoticeOfSilenceOwnAssessmentPreviewArgs =
  {
    input: RecordCompEventNoticeOfSilenceOwnAssessmentPreviewInput;
  };

export type MutationGenerateRecordCompEventNoticeOfSilenceQuotationPreviewArgs =
  {
    input: RecordCompEventNoticeOfSilenceQuotationPreviewInput;
  };

export type MutationGenerateRecordCompEventNoticePreviewArgs = {
  input: RecordCompEventNoticePreviewInput;
};

export type MutationGenerateRecordCompEventNoticeRequestQuotationPreviewArgs = {
  input: RecordCompEventNoticeRequestQuotationPreviewInput;
};

export type MutationGenerateRecordCompEventOwnAssessmentNoticePreviewArgs = {
  input: RecordCompEventOwnAssessmentNoticePreviewInput;
};

export type MutationGenerateRecordCompEventOwnAssessmentPreviewArgs = {
  input: RecordCompEventOwnAssessmentPreviewInput;
};

export type MutationGenerateRecordCompEventQuotationAcceptancePreviewArgs = {
  input: RecordCompEventQuotationAcceptancePreviewInput;
};

export type MutationGenerateRecordCompEventQuotationPreviewArgs = {
  input: RecordCompEventQuotationPreviewInput;
};

export type MutationGenerateRecordCompEventQuotationRevisionRequestPreviewArgs =
  {
    input: RecordCompEventQuotationRevisionRequestPreviewInput;
  };

export type MutationGenerateRecordCompEventRejectionPreviewArgs = {
  input: RecordCompEventRejectionPreviewInput;
};

export type MutationGenerateRecordCompEventRequestForMoreTimePreviewArgs = {
  input: RecordCompEventRequestForMoreTimePreviewInput;
};

export type MutationGenerateRecordVariationAgreementNoticePreviewArgs = {
  input: RecordVariationAgreementNoticePreviewInput;
};

export type MutationGenerateRecordVariationConfirmationPreviewArgs = {
  input: RecordVariationConfirmationPreviewInput;
};

export type MutationGenerateRecordVariationDeterminationNoticePreviewArgs = {
  input: RecordVariationDeterminationNoticePreviewInput;
};

export type MutationGenerateRecordVariationErrorAgreementDisagreementPreviewArgs =
  {
    input: RecordVariationErrorAgreementDisagreementPreviewInput;
  };

export type MutationGenerateRecordVariationErrorAgreementNoticePreviewArgs = {
  input: RecordVariationErrorAgreementNoticePreviewInput;
};

export type MutationGenerateRecordVariationErrorDeterminationDisagreementPreviewArgs =
  {
    input: RecordVariationErrorDeterminationDisagreementPreviewInput;
  };

export type MutationGenerateRecordVariationErrorDeterminationNoticePreviewArgs =
  {
    input: RecordVariationErrorDeterminationNoticePreviewInput;
  };

export type MutationGenerateRecordVariationFurtherParticularsRequestPreviewArgs =
  {
    input: RecordVariationFurtherParticularsRequestPreviewInput;
  };

export type MutationGenerateRecordVariationFurtherParticularsSubmissionPreviewArgs =
  {
    input: RecordVariationFurtherParticularsSubmissionPreviewInput;
  };

export type MutationGenerateRecordVariationInstructionCommenceWorkPreviewArgs =
  {
    input: RecordVariationInstructionCommenceWorkPreviewInput;
  };

export type MutationGenerateRecordVariationNoAgreementNoticePreviewArgs = {
  input: RecordVariationNoAgreementNoticePreviewInput;
};

export type MutationGenerateRecordVariationObjectionProposedVariationPreviewArgs =
  {
    input: RecordVariationObjectionProposedVariationPreviewInput;
  };

export type MutationGenerateRecordVariationObjectionVariationPreviewArgs = {
  input: RecordVariationObjectionVariationPreviewInput;
};

export type MutationGenerateRecordVariationProposalAbandonmentPreviewArgs = {
  input: RecordVariationProposalAbandonmentPreviewInput;
};

export type MutationGenerateRecordVariationProposalConsentForProposedVariationPreviewArgs =
  {
    input: RecordVariationProposalConsentForProposedVariationPreviewInput;
  };

export type MutationGenerateRecordVariationProposalForProposedVariationPreviewArgs =
  {
    input: RecordVariationProposalForProposedVariationPreviewInput;
  };

export type MutationGenerateRecordVariationProposalForVariationPreviewArgs = {
  input: RecordVariationProposalForVariationPreviewInput;
};

export type MutationGenerateRecordVariationRevisedProposalForProposedVariationRequestPreviewArgs =
  {
    input: RecordVariationRevisedProposalForProposedVariationRequestPreviewInput;
  };

export type MutationGenerateRecordVariationRevocationPreviewArgs = {
  input: RecordVariationRevocationPreviewInput;
};

export type MutationGenerateSendClaimAgreementNoticePreviewArgs = {
  input: SendClaimAgreementNoticePreviewInput;
};

export type MutationGenerateSendClaimDetailsResponsePreviewArgs = {
  input: SendClaimDetailsResponsePreviewInput;
};

export type MutationGenerateSendClaimDeterminationNoticePreviewArgs = {
  input: SendClaimDeterminationNoticePreviewInput;
};

export type MutationGenerateSendClaimErrorAgreementDisagreementPreviewArgs = {
  input: SendClaimErrorAgreementDisagreementPreviewInput;
};

export type MutationGenerateSendClaimErrorAgreementNoticePreviewArgs = {
  input: SendClaimErrorAgreementNoticePreviewInput;
};

export type MutationGenerateSendClaimErrorDeterminationDisagreementPreviewArgs =
  {
    input: SendClaimErrorDeterminationDisagreementPreviewInput;
  };

export type MutationGenerateSendClaimErrorDeterminationNoticePreviewArgs = {
  input: SendClaimErrorDeterminationNoticePreviewInput;
};

export type MutationGenerateSendClaimFinalClaimDetailsPreviewArgs = {
  input: SendClaimFinalClaimDetailsPreviewInput;
};

export type MutationGenerateSendClaimFurtherParticularsRequestPreviewArgs = {
  input: SendClaimFurtherParticularsRequestPreviewInput;
};

export type MutationGenerateSendClaimFurtherParticularsSubmissionPreviewArgs = {
  input: SendClaimFurtherParticularsSubmissionPreviewInput;
};

export type MutationGenerateSendClaimInterimClaimDetailsPreviewArgs = {
  input: SendClaimInterimClaimDetailsPreviewInput;
};

export type MutationGenerateSendClaimNoAgreementNoticePreviewArgs = {
  input: SendClaimNoAgreementNoticePreviewInput;
};

export type MutationGenerateSendClaimNoticeInvalidNoticePreviewArgs = {
  input: SendClaimNoticeInvalidNoticePreviewInput;
};

export type MutationGenerateSendClaimNoticeLapsedNoticePreviewArgs = {
  input: SendClaimNoticeLapsedNoticePreviewInput;
};

export type MutationGenerateSendClaimNoticePreviewArgs = {
  input: SendClaimNoticePreviewInput;
};

export type MutationGenerateSendCompEventAbandonInstructionPreviewArgs = {
  input: SendCompEventAbandonInstructionPreviewInput;
};

export type MutationGenerateSendCompEventConfirmationPreviewArgs = {
  input: SendCompEventConfirmationPreviewInput;
};

export type MutationGenerateSendCompEventDenyingOfMoreTimePreviewArgs = {
  input: SendCompEventDenyingOfMoreTimePreviewInput;
};

export type MutationGenerateSendCompEventGrantingOfMoreTimePreviewArgs = {
  input: SendCompEventGrantingOfMoreTimePreviewInput;
};

export type MutationGenerateSendCompEventNoticeOfSilenceCompEventNoticePreviewArgs =
  {
    input: SendCompEventNoticeOfSilenceCompEventNoticePreviewInput;
  };

export type MutationGenerateSendCompEventNoticeOfSilenceOwnAssessmentPreviewArgs =
  {
    input: SendCompEventNoticeOfSilenceOwnAssessmentPreviewInput;
  };

export type MutationGenerateSendCompEventNoticeOfSilenceQuotationPreviewArgs = {
  input: SendCompEventNoticeOfSilenceQuotationPreviewInput;
};

export type MutationGenerateSendCompEventNoticePreviewArgs = {
  input: SendCompEventNoticePreviewInput;
};

export type MutationGenerateSendCompEventNoticeRequestQuotationPreviewArgs = {
  input: SendCompEventNoticeRequestQuotationPreviewInput;
};

export type MutationGenerateSendCompEventOwnAssessmentNoticePreviewArgs = {
  input: SendCompEventOwnAssessmentNoticePreviewInput;
};

export type MutationGenerateSendCompEventOwnAssessmentPreviewArgs = {
  input: SendCompEventOwnAssessmentPreviewInput;
};

export type MutationGenerateSendCompEventQuotationAcceptancePreviewArgs = {
  input: SendCompEventQuotationAcceptancePreviewInput;
};

export type MutationGenerateSendCompEventQuotationPreviewArgs = {
  input: SendCompEventQuotationPreviewInput;
};

export type MutationGenerateSendCompEventQuotationRevisionRequestPreviewArgs = {
  input: SendCompEventQuotationRevisionRequestPreviewInput;
};

export type MutationGenerateSendCompEventRejectionPreviewArgs = {
  input: SendCompEventRejectionPreviewInput;
};

export type MutationGenerateSendCompEventRequestForMoreTimePreviewArgs = {
  input: SendCompEventRequestForMoreTimePreviewInput;
};

export type MutationGenerateSendVariationAgreementNoticePreviewArgs = {
  input: SendVariationAgreementNoticePreviewInput;
};

export type MutationGenerateSendVariationConfirmationPreviewArgs = {
  input: SendVariationConfirmationPreviewInput;
};

export type MutationGenerateSendVariationDeterminationNoticePreviewArgs = {
  input: SendVariationDeterminationNoticePreviewInput;
};

export type MutationGenerateSendVariationErrorAgreementDisagreementPreviewArgs =
  {
    input: SendVariationErrorAgreementDisagreementPreviewInput;
  };

export type MutationGenerateSendVariationErrorAgreementNoticePreviewArgs = {
  input: SendVariationErrorAgreementNoticePreviewInput;
};

export type MutationGenerateSendVariationErrorDeterminationDisagreementPreviewArgs =
  {
    input: SendVariationErrorDeterminationDisagreementPreviewInput;
  };

export type MutationGenerateSendVariationErrorDeterminationNoticePreviewArgs = {
  input: SendVariationErrorDeterminationNoticePreviewInput;
};

export type MutationGenerateSendVariationFurtherParticularsRequestPreviewArgs =
  {
    input: SendVariationFurtherParticularsRequestPreviewInput;
  };

export type MutationGenerateSendVariationFurtherParticularsSubmissionPreviewArgs =
  {
    input: SendVariationFurtherParticularsSubmissionPreviewInput;
  };

export type MutationGenerateSendVariationInstructionCommenceWorkPreviewArgs = {
  input: SendVariationInstructionCommenceWorkPreviewInput;
};

export type MutationGenerateSendVariationNoAgreementNoticePreviewArgs = {
  input: SendVariationNoAgreementNoticePreviewInput;
};

export type MutationGenerateSendVariationObjectionProposedVariationPreviewArgs =
  {
    input: SendVariationObjectionProposedVariationPreviewInput;
  };

export type MutationGenerateSendVariationObjectionVariationPreviewArgs = {
  input: SendVariationObjectionVariationPreviewInput;
};

export type MutationGenerateSendVariationProposalAbandonmentPreviewArgs = {
  input: SendVariationProposalAbandonmentPreviewInput;
};

export type MutationGenerateSendVariationProposalConsentForProposedVariationPreviewArgs =
  {
    input: SendVariationProposalConsentForProposedVariationPreviewInput;
  };

export type MutationGenerateSendVariationProposalForProposedVariationPreviewArgs =
  {
    input: SendVariationProposalForProposedVariationPreviewInput;
  };

export type MutationGenerateSendVariationProposalForVariationPreviewArgs = {
  input: SendVariationProposalForVariationPreviewInput;
};

export type MutationGenerateSendVariationRevisedProposalForProposedVariationRequestPreviewArgs =
  {
    input: SendVariationRevisedProposalForProposedVariationRequestPreviewInput;
  };

export type MutationGenerateSendVariationRevocationPreviewArgs = {
  input: SendVariationRevocationPreviewInput;
};

export type MutationImportDailyDiaryDelayRecordsArgs = {
  input: ImportDailyDiaryRecordsInput;
};

export type MutationImportDailyDiaryEquipmentRecordsArgs = {
  input: ImportDailyDiaryRecordsInput;
};

export type MutationImportDailyDiaryGeneralRecordsArgs = {
  input: ImportDailyDiaryRecordsInput;
};

export type MutationImportDailyDiaryHseRecordsArgs = {
  input: ImportDailyDiaryRecordsInput;
};

export type MutationImportDailyDiaryManpowerRecordsArgs = {
  input: ImportDailyDiaryRecordsInput;
};

export type MutationImportDailyDiaryWeatherRecordsArgs = {
  input: ImportDailyDiaryRecordsInput;
};

export type MutationImportDailyDiaryWorkRecordsArgs = {
  input: ImportDailyDiaryRecordsInput;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationMarkNotificationArgs = {
  id: Scalars["ID"];
  status: NotificationStatus;
};

export type MutationRecordClaimAgreementNoticeArgs = {
  input: RecordClaimAgreementNoticeInput;
};

export type MutationRecordClaimDetailsResponseArgs = {
  input: RecordClaimDetailsResponseInput;
};

export type MutationRecordClaimDeterminationNoticeArgs = {
  input: RecordClaimDeterminationNoticeInput;
};

export type MutationRecordClaimErrorAgreementDisagreementArgs = {
  input: RecordClaimErrorAgreementDisagreementInput;
};

export type MutationRecordClaimErrorAgreementNoticeArgs = {
  input: RecordClaimErrorAgreementNoticeInput;
};

export type MutationRecordClaimErrorDeterminationDisagreementArgs = {
  input: RecordClaimErrorDeterminationDisagreementInput;
};

export type MutationRecordClaimErrorDeterminationNoticeArgs = {
  input: RecordClaimErrorDeterminationNoticeInput;
};

export type MutationRecordClaimFinalClaimDetailsArgs = {
  input: RecordClaimFinalClaimDetailsInput;
};

export type MutationRecordClaimFurtherParticularsRequestArgs = {
  input: RecordClaimFurtherParticularsRequestInput;
};

export type MutationRecordClaimFurtherParticularsSubmissionArgs = {
  input: RecordClaimFurtherParticularsSubmissionInput;
};

export type MutationRecordClaimInterimClaimDetailsArgs = {
  input: RecordClaimInterimClaimDetailsInput;
};

export type MutationRecordClaimNoAgreementNoticeArgs = {
  input: RecordClaimNoAgreementNoticeInput;
};

export type MutationRecordClaimNoticeArgs = {
  input: RecordClaimNoticeInput;
};

export type MutationRecordClaimNoticeInvalidNoticeArgs = {
  input: RecordClaimNoticeInvalidNoticeInput;
};

export type MutationRecordClaimNoticeLapsedNoticeArgs = {
  input: RecordClaimNoticeLapsedNoticeInput;
};

export type MutationRecordCompEventAbandonInstructionArgs = {
  input: RecordCompEventAbandonInstructionInput;
};

export type MutationRecordCompEventConfirmationArgs = {
  input: RecordCompEventConfirmationInput;
};

export type MutationRecordCompEventDenyingOfMoreTimeArgs = {
  input: RecordCompEventDenyingOfMoreTimeInput;
};

export type MutationRecordCompEventGrantingOfMoreTimeArgs = {
  input: RecordCompEventGrantingOfMoreTimeInput;
};

export type MutationRecordCompEventNoticeArgs = {
  input: RecordCompEventNoticeInput;
};

export type MutationRecordCompEventNoticeOfSilenceCompEventNoticeArgs = {
  input: RecordCompEventNoticeOfSilenceCompEventNoticeInput;
};

export type MutationRecordCompEventNoticeOfSilenceOwnAssessmentArgs = {
  input: RecordCompEventNoticeOfSilenceOwnAssessmentInput;
};

export type MutationRecordCompEventNoticeOfSilenceQuotationArgs = {
  input: RecordCompEventNoticeOfSilenceQuotationInput;
};

export type MutationRecordCompEventNoticeRequestQuotationArgs = {
  input: RecordCompEventNoticeRequestQuotationInput;
};

export type MutationRecordCompEventOwnAssessmentArgs = {
  input: RecordCompEventOwnAssessmentInput;
};

export type MutationRecordCompEventOwnAssessmentNoticeArgs = {
  input: RecordCompEventOwnAssessmentNoticeInput;
};

export type MutationRecordCompEventQuotationArgs = {
  input: RecordCompEventQuotationInput;
};

export type MutationRecordCompEventQuotationAcceptanceArgs = {
  input: RecordCompEventQuotationAcceptanceInput;
};

export type MutationRecordCompEventQuotationRevisionRequestArgs = {
  input: RecordCompEventQuotationRevisionRequestInput;
};

export type MutationRecordCompEventRejectionArgs = {
  input: RecordCompEventRejectionInput;
};

export type MutationRecordCompEventRequestForMoreTimeArgs = {
  input: RecordCompEventRequestForMoreTimeInput;
};

export type MutationRecordEarlyWarningItemArgs = {
  draftId?: InputMaybe<Scalars["ID"]>;
  input: RecordEarlyWarningItemInput;
};

export type MutationRecordInstructionItemArgs = {
  draftId?: InputMaybe<Scalars["ID"]>;
  input: RecordInstructionItemInput;
};

export type MutationRecordVariationAgreementNoticeArgs = {
  input: RecordVariationAgreementNoticeInput;
};

export type MutationRecordVariationConfirmationArgs = {
  input: RecordVariationConfirmationInput;
};

export type MutationRecordVariationDeterminationNoticeArgs = {
  input: RecordVariationDeterminationNoticeInput;
};

export type MutationRecordVariationErrorAgreementDisagreementArgs = {
  input: RecordVariationErrorAgreementDisagreementInput;
};

export type MutationRecordVariationErrorAgreementNoticeArgs = {
  input: RecordVariationErrorAgreementNoticeInput;
};

export type MutationRecordVariationErrorDeterminationDisagreementArgs = {
  input: RecordVariationErrorDeterminationDisagreementInput;
};

export type MutationRecordVariationErrorDeterminationNoticeArgs = {
  input: RecordVariationErrorDeterminationNoticeInput;
};

export type MutationRecordVariationFurtherParticularsRequestArgs = {
  input: RecordVariationFurtherParticularsRequestInput;
};

export type MutationRecordVariationFurtherParticularsSubmissionArgs = {
  input: RecordVariationFurtherParticularsSubmissionInput;
};

export type MutationRecordVariationInstructionCommenceWorkArgs = {
  input: RecordVariationInstructionCommenceWorkInput;
};

export type MutationRecordVariationNoAgreementNoticeArgs = {
  input: RecordVariationNoAgreementNoticeInput;
};

export type MutationRecordVariationObjectionProposedVariationArgs = {
  input: RecordVariationObjectionProposedVariationInput;
};

export type MutationRecordVariationObjectionVariationArgs = {
  input: RecordVariationObjectionVariationInput;
};

export type MutationRecordVariationProposalAbandonmentArgs = {
  input: RecordVariationProposalAbandonmentInput;
};

export type MutationRecordVariationProposalConsentForProposedVariationArgs = {
  input: RecordVariationProposalConsentForProposedVariationInput;
};

export type MutationRecordVariationProposalForProposedVariationArgs = {
  input: RecordVariationProposalForProposedVariationInput;
};

export type MutationRecordVariationProposalForVariationArgs = {
  input: RecordVariationProposalForVariationInput;
};

export type MutationRecordVariationRevisedProposalForProposedVariationRequestArgs =
  {
    input: RecordVariationRevisedProposalForProposedVariationRequestInput;
  };

export type MutationRecordVariationRevocationArgs = {
  input: RecordVariationRevocationInput;
};

export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};

export type MutationRemoveAttachmentFileArgs = {
  fileName: Scalars["String"];
};

export type MutationRemoveAuthorizationWorkflowActionMappingArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveClaimPreviewArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveCompEventPreviewArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDailyDiaryDelayRecordArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDailyDiaryEquipmentRecordArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDailyDiaryGeneralRecordArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDailyDiaryHseRecordArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDailyDiaryManpowerRecordArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDailyDiaryWeatherRecordArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDailyDiaryWorkRecordArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDocumentTemplateRouteArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDocumentTemplateRouteOverrideArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftAssessmentArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftClaimAgreementArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftClaimDeterminationArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftDetailedClaimArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftEarlyWarningItemArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftEventItemArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftInstructionItemArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftQuotationArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftRiskItemArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftVariationAgreementArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftVariationDeterminationArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveDraftVariationProposalArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveEarlyWarningPreviewArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveInstructionPreviewArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveTimePeriodOverrideArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveVariationPreviewArgs = {
  id: Scalars["ID"];
};

export type MutationReplyCommentArgs = {
  input: ReplyCommentInput;
};

export type MutationResendInviteArgs = {
  input: InviteUserInput;
};

export type MutationReviseDailyDiaryItemArgs = {
  id: Scalars["ID"];
};

export type MutationSendAuthorizationAcceptanceArgs = {
  authorizationWorkflowAuditId: Scalars["ID"];
};

export type MutationSendAuthorizationAcceptanceWithResultArgs = {
  authorizationWorkflowAuditId: Scalars["ID"];
};

export type MutationSendAuthorizationDenialArgs = {
  authorizationWorkflowAuditId: Scalars["ID"];
  denialReason: Scalars["String"];
};

export type MutationSendClaimAgreementNoticeArgs = {
  input: SendClaimAgreementNoticeInput;
};

export type MutationSendClaimDetailsResponseArgs = {
  input: SendClaimDetailsResponseInput;
};

export type MutationSendClaimDeterminationNoticeArgs = {
  input: SendClaimDeterminationNoticeInput;
};

export type MutationSendClaimErrorAgreementDisagreementArgs = {
  input: SendClaimErrorAgreementDisagreementInput;
};

export type MutationSendClaimErrorAgreementNoticeArgs = {
  input: SendClaimErrorAgreementNoticeInput;
};

export type MutationSendClaimErrorDeterminationDisagreementArgs = {
  input: SendClaimErrorDeterminationDisagreementInput;
};

export type MutationSendClaimErrorDeterminationNoticeArgs = {
  input: SendClaimErrorDeterminationNoticeInput;
};

export type MutationSendClaimFinalClaimDetailsArgs = {
  input: SendClaimFinalClaimDetailsInput;
};

export type MutationSendClaimFurtherParticularsRequestArgs = {
  input: SendClaimFurtherParticularsRequestInput;
};

export type MutationSendClaimFurtherParticularsSubmissionArgs = {
  input: SendClaimFurtherParticularsSubmissionInput;
};

export type MutationSendClaimInterimClaimDetailsArgs = {
  input: SendClaimInterimClaimDetailsInput;
};

export type MutationSendClaimNoAgreementNoticeArgs = {
  input: SendClaimNoAgreementNoticeInput;
};

export type MutationSendClaimNoticeArgs = {
  input: SendClaimNoticeInput;
};

export type MutationSendClaimNoticeInvalidNoticeArgs = {
  input: SendClaimNoticeInvalidNoticeInput;
};

export type MutationSendClaimNoticeLapsedNoticeArgs = {
  input: SendClaimNoticeLapsedNoticeInput;
};

export type MutationSendCompEventAbandonInstructionArgs = {
  input: SendCompEventAbandonInstructionInput;
};

export type MutationSendCompEventConfirmationArgs = {
  input: SendCompEventConfirmationInput;
};

export type MutationSendCompEventDenyingOfMoreTimeArgs = {
  input: SendCompEventDenyingOfMoreTimeInput;
};

export type MutationSendCompEventGrantingOfMoreTimeArgs = {
  input: SendCompEventGrantingOfMoreTimeInput;
};

export type MutationSendCompEventNoticeArgs = {
  input: SendCompEventNoticeInput;
};

export type MutationSendCompEventNoticeOfSilenceCompEventNoticeArgs = {
  input: SendCompEventNoticeOfSilenceCompEventNoticeInput;
};

export type MutationSendCompEventNoticeOfSilenceOwnAssessmentArgs = {
  input: SendCompEventNoticeOfSilenceOwnAssessmentInput;
};

export type MutationSendCompEventNoticeOfSilenceQuotationArgs = {
  input: SendCompEventNoticeOfSilenceQuotationInput;
};

export type MutationSendCompEventNoticeRequestQuotationArgs = {
  input: SendCompEventNoticeRequestQuotationInput;
};

export type MutationSendCompEventOwnAssessmentArgs = {
  input: SendCompEventOwnAssessmentInput;
};

export type MutationSendCompEventOwnAssessmentNoticeArgs = {
  input: SendCompEventOwnAssessmentNoticeInput;
};

export type MutationSendCompEventQuotationArgs = {
  input: SendCompEventQuotationInput;
};

export type MutationSendCompEventQuotationAcceptanceArgs = {
  input: SendCompEventQuotationAcceptanceInput;
};

export type MutationSendCompEventQuotationRevisionRequestArgs = {
  input: SendCompEventQuotationRevisionRequestInput;
};

export type MutationSendCompEventRejectionArgs = {
  input: SendCompEventRejectionInput;
};

export type MutationSendCompEventRequestForMoreTimeArgs = {
  input: SendCompEventRequestForMoreTimeInput;
};

export type MutationSendDailyDiaryItemArgs = {
  id: Scalars["ID"];
};

export type MutationSendEarlyWarningItemArgs = {
  draftId?: InputMaybe<Scalars["ID"]>;
  input: SendEarlyWarningItemInput;
};

export type MutationSendInstructionItemArgs = {
  draftId?: InputMaybe<Scalars["ID"]>;
  input: SendInstructionItemInput;
};

export type MutationSendVariationAgreementNoticeArgs = {
  input: SendVariationAgreementNoticeInput;
};

export type MutationSendVariationConfirmationArgs = {
  input: SendVariationConfirmationInput;
};

export type MutationSendVariationDeterminationNoticeArgs = {
  input: SendVariationDeterminationNoticeInput;
};

export type MutationSendVariationErrorAgreementDisagreementArgs = {
  input: SendVariationErrorAgreementDisagreementInput;
};

export type MutationSendVariationErrorAgreementNoticeArgs = {
  input: SendVariationErrorAgreementNoticeInput;
};

export type MutationSendVariationErrorDeterminationDisagreementArgs = {
  input: SendVariationErrorDeterminationDisagreementInput;
};

export type MutationSendVariationErrorDeterminationNoticeArgs = {
  input: SendVariationErrorDeterminationNoticeInput;
};

export type MutationSendVariationFurtherParticularsRequestArgs = {
  input: SendVariationFurtherParticularsRequestInput;
};

export type MutationSendVariationFurtherParticularsSubmissionArgs = {
  input: SendVariationFurtherParticularsSubmissionInput;
};

export type MutationSendVariationInstructionCommenceWorkArgs = {
  input: SendVariationInstructionCommenceWorkInput;
};

export type MutationSendVariationNoAgreementNoticeArgs = {
  input: SendVariationNoAgreementNoticeInput;
};

export type MutationSendVariationObjectionProposedVariationArgs = {
  input: SendVariationObjectionProposedVariationInput;
};

export type MutationSendVariationObjectionVariationArgs = {
  input: SendVariationObjectionVariationInput;
};

export type MutationSendVariationProposalAbandonmentArgs = {
  input: SendVariationProposalAbandonmentInput;
};

export type MutationSendVariationProposalConsentForProposedVariationArgs = {
  input: SendVariationProposalConsentForProposedVariationInput;
};

export type MutationSendVariationProposalForProposedVariationArgs = {
  input: SendVariationProposalForProposedVariationInput;
};

export type MutationSendVariationProposalForVariationArgs = {
  input: SendVariationProposalForVariationInput;
};

export type MutationSendVariationRevisedProposalForProposedVariationRequestArgs =
  {
    input: SendVariationRevisedProposalForProposedVariationRequestInput;
  };

export type MutationSendVariationRevocationArgs = {
  input: SendVariationRevocationInput;
};

export type MutationSetUserPreferencesArgs = {
  input: UserPreferencesInput;
};

export type MutationUserChangePasswordArgs = {
  input: UserChangePasswordInput;
};

export type NewClaimTypeOverride = {
  __typename?: "NewClaimTypeOverride";
  clause: Scalars["String"];
  contract: Contract;
  contractId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  status: NewClaimTypeOverrideStatus;
};

export type NewClaimTypeOverrideList = {
  __typename?: "NewClaimTypeOverrideList";
  items: Array<NewClaimTypeOverride>;
};

export enum NewClaimTypeOverrideStatus {
  Active = "Active",
  Removed = "Removed",
  Suspended = "Suspended",
}

export type NewCommentAddedDetails = EventDetails & {
  __typename?: "NewCommentAddedDetails";
  content: Scalars["String"];
  title: Scalars["String"];
  versionId?: Maybe<Scalars["ID"]>;
};

export type Notification = {
  __typename?: "Notification";
  contract: Contract;
  contractId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  details: EventDetails;
  id: Scalars["ID"];
  itemId: Scalars["ID"];
  number: Scalars["String"];
  productInstanceId: Scalars["ID"];
  productType: ProductType;
  project: Project;
  projectId: Scalars["ID"];
  recipient: User;
  recipientId: Scalars["ID"];
  status: NotificationStatus;
  versionId?: Maybe<Scalars["ID"]>;
};

export type NotificationList = {
  __typename?: "NotificationList";
  items: Array<Notification>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type NotificationRecipient = {
  __typename?: "NotificationRecipient";
  name: Scalars["String"];
  preference: UserPreferencesValue;
  userId: Scalars["ID"];
};

export enum NotificationStatus {
  Read = "Read",
  UnRead = "UnRead",
}

export type Product = {
  allowMultipleInstances: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  numberingFormat: Scalars["String"];
  outputActions: ProductOutputActionList;
  productSchemas: ProductSchemaList;
  roles: ProductRoleList;
  soloModeSupported: Scalars["Boolean"];
  status: ProductStatus;
  statusCollections: ItemStatusCollectionList;
};

export type ProductInstance = {
  __typename?: "ProductInstance";
  authorizationWorkflowActionMappings?: Maybe<AuthorizationWorkflowActionMappingsList>;
  contract: Contract;
  contractId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  description: Scalars["String"];
  extraConfig?: Maybe<ExtraConfigType>;
  id: Scalars["ID"];
  numberingFormat: Scalars["String"];
  product: Product;
  productId: Scalars["ID"];
  productSchema: ProductSchema;
  productSchemaId: Scalars["ID"];
  roles: ProductUserRoleList;
  soloModeSupported: Scalars["Boolean"];
  status: ProductInstanceStatus;
  statusCollection: ItemStatusCollection;
  statusCollectionId: Scalars["ID"];
};

export type ProductInstanceList = {
  __typename?: "ProductInstanceList";
  items: Array<ProductInstance>;
};

export enum ProductInstanceStatus {
  Active = "Active",
  Closed = "Closed",
  Offline = "Offline",
  Removed = "Removed",
}

export type ProductItem =
  | ClaimItem
  | CompEventItem
  | DailyDiaryItem
  | EarlyWarningItem
  | EventItem
  | InstructionItem
  | RiskItem
  | VariationItem;

export type ProductItemList = {
  __typename?: "ProductItemList";
  items: Array<ProductItem>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type ProductList = {
  __typename?: "ProductList";
  items: Array<Product>;
};

export type ProductOutputAction = {
  __typename?: "ProductOutputAction";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  name: Scalars["String"];
  product: Product;
  productId: Scalars["ID"];
  status: ProductOutputActionStatus;
};

export type ProductOutputActionList = {
  __typename?: "ProductOutputActionList";
  items: Array<ProductOutputAction>;
};

export type ProductOutputActionOriginatingParty = {
  __typename?: "ProductOutputActionOriginatingParty";
  originatingParty?: Maybe<ContractBindingType>;
  productOutputAction: ProductOutputAction;
};

export type ProductOutputActionOriginatingPartyList = {
  __typename?: "ProductOutputActionOriginatingPartyList";
  items: Array<ProductOutputActionOriginatingParty>;
};

export enum ProductOutputActionStatus {
  Active = "Active",
  Removed = "Removed",
}

export type ProductRole = {
  __typename?: "ProductRole";
  actionIds: Array<Scalars["ID"]>;
  actions: ProductRoleActionList;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  name: Scalars["String"];
  productId: Scalars["ID"];
  status: ProductRoleStatus;
};

export type ProductRoleAction = {
  __typename?: "ProductRoleAction";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  name: Scalars["String"];
  status: ProductRoleActionStatus;
};

export type ProductRoleActionList = {
  __typename?: "ProductRoleActionList";
  items: Array<ProductRoleAction>;
};

export enum ProductRoleActionStatus {
  Active = "Active",
  Removed = "Removed",
}

export type ProductRoleList = {
  __typename?: "ProductRoleList";
  items: Array<ProductRole>;
};

export enum ProductRoleStatus {
  Active = "Active",
  Removed = "Removed",
}

export type ProductSchema = {
  __typename?: "ProductSchema";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
  isInternal: Scalars["Boolean"];
  name: Scalars["String"];
  productId: Scalars["ID"];
  schemaSections: SchemaSectionList;
  status: ProductSchemaStatus;
};

export type ProductSchemaList = {
  __typename?: "ProductSchemaList";
  items: Array<ProductSchema>;
};

export enum ProductSchemaStatus {
  Active = "Active",
  Offline = "Offline",
  Removed = "Removed",
}

export enum ProductStatus {
  Active = "Active",
  Offline = "Offline",
  Removed = "Removed",
}

export enum ProductType {
  Claims = "Claims",
  CompEvents = "CompEvents",
  DailyDiary = "DailyDiary",
  EarlyWarnings = "EarlyWarnings",
  Events = "Events",
  Instructions = "Instructions",
  RisksRegister = "RisksRegister",
  Variations = "Variations",
}

export type ProductUserRole = {
  __typename?: "ProductUserRole";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  productInstance: ProductInstance;
  productInstanceId: Scalars["ID"];
  productRole: ProductRole;
  productRoleId: Scalars["ID"];
  status: ProductUserRoleStatus;
  user: User;
  userId: Scalars["ID"];
};

export type ProductUserRoleList = {
  __typename?: "ProductUserRoleList";
  items: Array<ProductUserRole>;
};

export enum ProductUserRoleStatus {
  Active = "Active",
  Removed = "Removed",
}

export type Project = {
  __typename?: "Project";
  billingCompany: Company;
  billingCompanyId: Scalars["ID"];
  contracts: ContractList;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  description?: Maybe<Scalars["String"]>;
  friendlyName: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  number?: Maybe<Scalars["String"]>;
  sector: ProjectSector;
  status: ProjectStatus;
};

export type ProjectList = {
  __typename?: "ProjectList";
  items: Array<Project>;
};

export enum ProjectSector {
  Building = "Building",
  EnergyFossil = "Energy_Fossil",
  EnergyNuclear = "Energy_Nuclear",
  EnergyRenewable = "Energy_Renewable",
  MiningMinerals = "Mining_Minerals",
  OilGas = "Oil_Gas",
  Other = "Other",
  Petrochem = "Petrochem",
  Rail = "Rail",
  Roads = "Roads",
  Telecoms = "Telecoms",
}

export enum ProjectStatus {
  Active = "Active",
  Closed = "Closed",
  Offline = "Offline",
  Removed = "Removed",
}

export type Query = {
  __typename?: "Query";
  activeClaimTypes: Array<ListItem>;
  attachmentDownloadPreSignedUrl: Scalars["String"];
  auditCompEventRequestQuotationPrompt: CompEventNoticeRequestQuotationAuditPrompt;
  authenticationData: AuthenticationData;
  authorizationWorkflow?: Maybe<AuthorizationWorkflow>;
  authorizationWorkflowActionMappings: AuthorizationWorkflowActionMappingsList;
  authorizationWorkflowAudit?: Maybe<AuthorizationWorkflowAudit>;
  authorizationWorkflows?: Maybe<AuthorizationWorkflowList>;
  availableLookupsByContract: CompanyLookupCollectionList;
  claimAction: ClaimActionList;
  claimAgreement?: Maybe<ClaimAgreement>;
  claimAgreementNoticePrompt: ClaimAgreementNoticePrompt;
  claimDetailedClaim?: Maybe<DetailedClaim>;
  claimDetailsExplorerItems: ClaimDetailsExplorerItemList;
  claimDetailsResponsePrompt: ClaimDetailsResponsePrompt;
  claimDetermination?: Maybe<ClaimDetermination>;
  claimDeterminationNoticePrompt: ClaimDeterminationNoticePrompt;
  claimDraftClaimAgreement?: Maybe<DraftClaimAgreement>;
  claimDraftClaimAgreementPrompt: CreateDraftClaimAgreementPrompt;
  claimDraftClaimAgreements: DraftClaimAgreementList;
  claimDraftClaimDetermination?: Maybe<DraftClaimDetermination>;
  claimDraftClaimDeterminationPrompt: CreateDraftClaimDeterminationPrompt;
  claimDraftClaimDeterminations: DraftClaimDeterminationList;
  claimDraftDetailedClaim?: Maybe<DraftDetailedClaim>;
  claimDraftDetailedClaims: DraftDetailedClaimList;
  claimErrorAgreementDisagreementPrompt: ClaimErrorAgreementDisagreementPrompt;
  claimErrorAgreementNoticePrompt: ClaimErrorAgreementNoticePrompt;
  claimErrorDeterminationDisagreementPrompt: ClaimErrorDeterminationDisagreementPrompt;
  claimErrorDeterminationNoticePrompt: ClaimErrorDeterminationNoticePrompt;
  claimFinalClaimDetailsPrompt: ClaimFinalClaimDetailsPrompt;
  claimFurtherParticularsRequestPrompt: ClaimFurtherParticularsRequestPrompt;
  claimFurtherParticularsSubmissionPrompt: ClaimFurtherParticularsSubmissionPrompt;
  claimHistoryItem?: Maybe<ClaimHistoryItem>;
  claimHistoryItems: ClaimHistoryItemList;
  claimInterimClaimDetailsPrompt: ClaimInterimClaimDetailsPrompt;
  claimItem?: Maybe<ClaimItem>;
  claimItems: ClaimItemList;
  claimNoAgreementNoticePrompt: ClaimNoAgreementNoticePrompt;
  claimNoticeInvalidNoticePrompt: ClaimNoticeInvalidNoticePrompt;
  claimNoticeLapsedNoticePrompt: ClaimNoticeLapsedNoticePrompt;
  claimNoticePrompt: ClaimNoticePrompt;
  claimType: ClaimType;
  claimTypes: ClaimTypeList;
  compEventAbandonInstructionPrompt: CompEventAbandonInstructionPrompt;
  compEventAction: CompEventActionList;
  compEventAssessment?: Maybe<CompEventAssessment>;
  compEventConfirmationPrompt: CompEventConfirmationPrompt;
  compEventDenyingOfMoreTimePrompt: CompEventDenyingOfMoreTimePrompt;
  compEventDraftAssessment?: Maybe<DraftAssessment>;
  compEventDraftAssessments: DraftAssessmentList;
  compEventDraftQuotation?: Maybe<DraftQuotation>;
  compEventDraftQuotations: DraftQuotationList;
  compEventGrantingOfMoreTimePrompt: CompEventGrantingOfMoreTimePrompt;
  compEventHistoryItem?: Maybe<CompEventHistoryItem>;
  compEventHistoryItems: CompEventHistoryItemList;
  compEventItem?: Maybe<CompEventItem>;
  compEventItems: CompEventItemList;
  compEventNoticeOfSilenceCompEventNoticePrompt: CompEventNoticeOfSilenceCompEventNoticePrompt;
  compEventNoticeOfSilenceOwnAssessmentPrompt: CompEventNoticeOfSilenceOwnAssessmentPrompt;
  compEventNoticeOfSilenceQuotationPrompt: CompEventNoticeOfSilenceQuotationPrompt;
  compEventNoticePrompt: CompEventNoticePrompt;
  compEventNoticeRequestQuotationPrompt: CompEventNoticeRequestQuotationPrompt;
  compEventOwnAssessmentNoticePrompt: CompEventOwnAssessmentNoticePrompt;
  compEventOwnAssessmentPrompt: CompEventOwnAssessmentPrompt;
  compEventQuotation?: Maybe<CompEventQuotation>;
  compEventQuotationAcceptancePrompt: CompEventQuotationAcceptancePrompt;
  compEventQuotationAssessments: CompEventQuotationAssessmentList;
  compEventQuotationPrompt: CompEventQuotationPrompt;
  compEventQuotationRevisionRequestPrompt: CompEventQuotationRevisionRequestPrompt;
  compEventRejectionPrompt: CompEventRejectionPrompt;
  compEventRequestForMoreTimePrompt: CompEventRequestForMoreTimePrompt;
  companies: CompanyList;
  company?: Maybe<Company>;
  companyExists: Scalars["Boolean"];
  companyLookupCollection: CompanyLookupCollection;
  companyLookupCollections: CompanyLookupCollectionList;
  contract: Contract;
  contractBinding: ContractBinding;
  contractBindingType: ContractBindingType;
  contractType: ContractType;
  contractTypes: ContractTypeList;
  dailyDiaryDelayImportSourceItems: DailyDiaryItemList;
  dailyDiaryDelayRecords: Array<DailyDiaryDelayRecord>;
  dailyDiaryEquipmentImportSourceItems: DailyDiaryItemList;
  dailyDiaryEquipmentRecords: Array<DailyDiaryResourceRecord>;
  dailyDiaryGeneralImportSourceItems: DailyDiaryItemList;
  dailyDiaryGeneralRecords: Array<DailyDiaryGeneralRecord>;
  dailyDiaryHSEImportSourceItems: DailyDiaryItemList;
  dailyDiaryHSERecords: Array<DailyDiaryHseRecord>;
  dailyDiaryItem?: Maybe<DailyDiaryItemMixed>;
  dailyDiaryItemByDate?: Maybe<DailyDiaryItemMixed>;
  dailyDiaryItemComments: CommentList;
  dailyDiaryItems: DailyDiaryItemList;
  dailyDiaryItemsRequiresMyAttention: DailyDiaryItemMixedList;
  dailyDiaryItemsReviewedByMe: DailyDiaryItemList;
  dailyDiaryItemsSentByMe: DailyDiaryItemList;
  dailyDiaryItemsSubmittedByMe: DailyDiaryItemList;
  dailyDiaryManpowerImportSourceItems: DailyDiaryItemList;
  dailyDiaryManpowerRecords: Array<DailyDiaryResourceRecord>;
  dailyDiaryPreset: DailyDiaryPreset;
  dailyDiaryPresetSchema: DailyDiaryPresetSchema;
  dailyDiaryPresets: DailyDiaryPresetList;
  dailyDiaryWeatherImportSourceItems: DailyDiaryItemList;
  dailyDiaryWeatherRecords: Array<DailyDiaryWeatherRecord>;
  dailyDiaryWorkImportSourceItems: DailyDiaryItemList;
  dailyDiaryWorkRecords: Array<DailyDiaryWorkRecord>;
  disabledClaimTypeOverride: DisabledClaimTypeOverride;
  disabledClaimTypesOverride: DisabledClaimTypeOverrideList;
  documentTemplate: DocumentTemplate;
  documentTemplateRoute: DocumentTemplateRoute;
  documentTemplateRoutes: DocumentTemplateRouteList;
  documentTemplateRoutesOverride: DocumentTemplateRouteList;
  documentTemplates: DocumentTemplateList;
  draftEarlyWarningItem?: Maybe<EarlyWarningItem>;
  draftEarlyWarningItems: EarlyWarningItemList;
  draftEventItem: EventItem;
  draftEventItems: EventItemList;
  draftInstructionItem?: Maybe<InstructionItem>;
  draftInstructionItems: InstructionItemList;
  draftRiskItem: RiskItem;
  draftRiskItems: RiskItemList;
  earlyWarningItem?: Maybe<EarlyWarningItem>;
  earlyWarningItems: EarlyWarningItemList;
  effectOption: EffectOption;
  effectPreset: EffectPreset;
  effectPresets: EffectPresetList;
  emailTemplate: EmailTemplate;
  emailTemplates: EmailTemplateList;
  eventItem: EventItem;
  eventItemRecipients: Array<NotificationRecipient>;
  eventItems: EventItemList;
  fieldType: FieldType;
  fieldTypes: FieldTypeList;
  globalConfiguration: GlobalConfiguration;
  instructionItem?: Maybe<InstructionItem>;
  instructionItems: InstructionItemList;
  itemEffectPreset: EffectPreset;
  itemStatusCollection: ItemStatusCollection;
  itemStatusOption: ItemStatusOption;
  itemStatusOptionReason: ItemStatusOptionReason;
  lookupCollection: LookupCollection;
  lookupCollections: LookupCollectionList;
  lookupOption: LookupOption;
  newClaimTypeOverride: NewClaimTypeOverride;
  newClaimTypesOverride: NewClaimTypeOverrideList;
  newNotifications: Scalars["Int"];
  notifications: NotificationList;
  product: Product;
  productInstance: ProductInstance;
  productItemNextStepsAll: ProductItemList;
  productItemNextStepsDeeming: ProductItemList;
  productItemNextStepsOtherParties: ProductItemList;
  productItemNextStepsYourParty: ProductItemList;
  productOutputAction: ProductOutputAction;
  productRole: ProductRole;
  productRoleAction: ProductRoleAction;
  productRoleActions: ProductRoleActionList;
  productSchema: ProductSchema;
  products: ProductList;
  project: Project;
  projectAuthorizationAudits: AuthorizationWorkflowAuditList;
  projects: ProjectList;
  riskItem: RiskItem;
  riskItemRecipients: Array<NotificationRecipient>;
  riskItems: RiskItemList;
  schemaField: SchemaField;
  schemaSection: SchemaSection;
  severityPreset: SeverityPreset;
  severityPresets: SeverityPresetList;
  severityRating: SeverityRating;
  systemVersion: SystemVersion;
  timePeriod: TimePeriod;
  timePeriodOverride: TimePeriodOverride;
  timePeriods: TimePeriodList;
  timePeriodsOverride: TimePeriodOverrideList;
  unreadNotifications: Scalars["Int"];
  user?: Maybe<User>;
  userPendingAuthorizationActions: Array<AuthorizationWorkflowAudit>;
  userPendingAuthorizationAudits: AuthorizationWorkflowAuditList;
  userPreferences: UserPreferences;
  userRegistration?: Maybe<UserRegistration>;
  usersByContract: UserList;
  variationAction: VariationActionList;
  variationAgreement?: Maybe<VariationAgreement>;
  variationAgreementNoticePrompt: VariationAgreementNoticePrompt;
  variationConfirmationPrompt: VariationConfirmationPrompt;
  variationDetermination?: Maybe<VariationDetermination>;
  variationDeterminationNoticePrompt: VariationDeterminationNoticePrompt;
  variationDraftVariationAgreement?: Maybe<DraftVariationAgreement>;
  variationDraftVariationAgreementPrompt: CreateDraftVariationAgreementPrompt;
  variationDraftVariationAgreements: DraftVariationAgreementList;
  variationDraftVariationDetermination?: Maybe<DraftVariationDetermination>;
  variationDraftVariationDeterminationPrompt: CreateDraftVariationDeterminationPrompt;
  variationDraftVariationDeterminations: DraftVariationDeterminationList;
  variationDraftVariationProposal?: Maybe<DraftVariationProposal>;
  variationDraftVariationProposals: DraftVariationProposalList;
  variationErrorAgreementDisagreementPrompt: VariationErrorAgreementDisagreementPrompt;
  variationErrorAgreementNoticePrompt: VariationErrorAgreementNoticePrompt;
  variationErrorDeterminationDisagreementPrompt: VariationErrorDeterminationDisagreementPrompt;
  variationErrorDeterminationNoticePrompt: VariationErrorDeterminationNoticePrompt;
  variationFurtherParticularsRequestPrompt: VariationFurtherParticularsRequestPrompt;
  variationFurtherParticularsSubmissionPrompt: VariationFurtherParticularsSubmissionPrompt;
  variationHistoryItem?: Maybe<VariationHistoryItem>;
  variationHistoryItems: VariationHistoryItemList;
  variationInstructionCommenceWorkPrompt: VariationInstructionCommenceWorkPrompt;
  variationItem?: Maybe<VariationItem>;
  variationItems: VariationItemList;
  variationNoAgreementNoticePrompt: VariationNoAgreementNoticePrompt;
  variationObjectionProposedVariationPrompt: VariationObjectionProposedVariationPrompt;
  variationObjectionVariationPrompt: VariationObjectionVariationPrompt;
  variationProposal?: Maybe<VariationProposal>;
  variationProposalAbandonmentPrompt: VariationProposalAbandonmentPrompt;
  variationProposalConsentForProposedVariationPrompt: VariationProposalConsentForProposedVariationPrompt;
  variationProposalExplorerItems: VariationProposalExplorerItemList;
  variationProposalForProposedVariationPrompt: VariationProposalForProposedVariationPrompt;
  variationProposalForVariationPrompt: VariationProposalForVariationPrompt;
  variationRevisedProposalForProposedVariationRequestPrompt: VariationRevisedProposalForProposedVariationRequestPrompt;
  variationRevocationPrompt: VariationRevocationPrompt;
  workflowActionsPrompt: WorkflowActionPromptList;
};

export type QueryActiveClaimTypesArgs = {
  contractId: Scalars["ID"];
};

export type QueryAttachmentDownloadPreSignedUrlArgs = {
  fileName?: InputMaybe<Scalars["String"]>;
  fileUrl: Scalars["String"];
};

export type QueryAuditCompEventRequestQuotationPromptArgs = {
  auditId: Scalars["ID"];
};

export type QueryAuthorizationWorkflowArgs = {
  id: Scalars["ID"];
};

export type QueryAuthorizationWorkflowActionMappingsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryAuthorizationWorkflowAuditArgs = {
  id: Scalars["ID"];
};

export type QueryAuthorizationWorkflowsArgs = {
  projectId: Scalars["ID"];
};

export type QueryAvailableLookupsByContractArgs = {
  contractId: Scalars["ID"];
};

export type QueryClaimActionArgs = {
  input: ClaimActionInput;
};

export type QueryClaimAgreementArgs = {
  id: Scalars["ID"];
};

export type QueryClaimAgreementNoticePromptArgs = {
  input: ClaimAgreementNoticePromptInput;
};

export type QueryClaimDetailedClaimArgs = {
  id: Scalars["ID"];
};

export type QueryClaimDetailsExplorerItemsArgs = {
  claimId: Scalars["ID"];
};

export type QueryClaimDetailsResponsePromptArgs = {
  input: ClaimDetailsResponsePromptInput;
};

export type QueryClaimDeterminationArgs = {
  id: Scalars["ID"];
};

export type QueryClaimDeterminationNoticePromptArgs = {
  input: ClaimDeterminationNoticePromptInput;
};

export type QueryClaimDraftClaimAgreementArgs = {
  id: Scalars["ID"];
};

export type QueryClaimDraftClaimAgreementPromptArgs = {
  claimId: Scalars["ID"];
};

export type QueryClaimDraftClaimAgreementsArgs = {
  claimId: Scalars["ID"];
};

export type QueryClaimDraftClaimDeterminationArgs = {
  id: Scalars["ID"];
};

export type QueryClaimDraftClaimDeterminationPromptArgs = {
  claimId: Scalars["ID"];
};

export type QueryClaimDraftClaimDeterminationsArgs = {
  claimId: Scalars["ID"];
};

export type QueryClaimDraftDetailedClaimArgs = {
  id: Scalars["ID"];
};

export type QueryClaimDraftDetailedClaimsArgs = {
  claimId: Scalars["ID"];
};

export type QueryClaimErrorAgreementDisagreementPromptArgs = {
  input: ClaimErrorAgreementDisagreementPromptInput;
};

export type QueryClaimErrorAgreementNoticePromptArgs = {
  input: ClaimErrorAgreementNoticePromptInput;
};

export type QueryClaimErrorDeterminationDisagreementPromptArgs = {
  input: ClaimErrorDeterminationDisagreementPromptInput;
};

export type QueryClaimErrorDeterminationNoticePromptArgs = {
  input: ClaimErrorDeterminationNoticePromptInput;
};

export type QueryClaimFinalClaimDetailsPromptArgs = {
  input: ClaimFinalClaimDetailsPromptInput;
};

export type QueryClaimFurtherParticularsRequestPromptArgs = {
  input: ClaimFurtherParticularsRequestPromptInput;
};

export type QueryClaimFurtherParticularsSubmissionPromptArgs = {
  input: ClaimFurtherParticularsSubmissionPromptInput;
};

export type QueryClaimHistoryItemArgs = {
  id: Scalars["ID"];
};

export type QueryClaimHistoryItemsArgs = {
  claimId: Scalars["ID"];
};

export type QueryClaimInterimClaimDetailsPromptArgs = {
  input: ClaimInterimClaimDetailsPromptInput;
};

export type QueryClaimItemArgs = {
  id: Scalars["ID"];
};

export type QueryClaimItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryClaimNoAgreementNoticePromptArgs = {
  input: ClaimNoAgreementNoticePromptInput;
};

export type QueryClaimNoticeInvalidNoticePromptArgs = {
  input: ClaimNoticeInvalidNoticePromptInput;
};

export type QueryClaimNoticeLapsedNoticePromptArgs = {
  input: ClaimNoticeLapsedNoticePromptInput;
};

export type QueryClaimNoticePromptArgs = {
  input: ClaimNoticePromptInput;
};

export type QueryClaimTypeArgs = {
  id: Scalars["ID"];
};

export type QueryClaimTypesArgs = {
  contractTypeId: Scalars["ID"];
};

export type QueryCompEventAbandonInstructionPromptArgs = {
  input: CompEventAbandonInstructionPromptInput;
};

export type QueryCompEventActionArgs = {
  input: CompEventActionInput;
};

export type QueryCompEventAssessmentArgs = {
  id: Scalars["ID"];
};

export type QueryCompEventConfirmationPromptArgs = {
  input: CompEventConfirmationPromptInput;
};

export type QueryCompEventDenyingOfMoreTimePromptArgs = {
  input: CompEventDenyingOfMoreTimePromptInput;
};

export type QueryCompEventDraftAssessmentArgs = {
  id: Scalars["ID"];
};

export type QueryCompEventDraftAssessmentsArgs = {
  compEventId: Scalars["ID"];
};

export type QueryCompEventDraftQuotationArgs = {
  id: Scalars["ID"];
};

export type QueryCompEventDraftQuotationsArgs = {
  compEventId: Scalars["ID"];
};

export type QueryCompEventGrantingOfMoreTimePromptArgs = {
  input: CompEventGrantingOfMoreTimePromptInput;
};

export type QueryCompEventHistoryItemArgs = {
  id: Scalars["ID"];
};

export type QueryCompEventHistoryItemsArgs = {
  compEventId: Scalars["ID"];
};

export type QueryCompEventItemArgs = {
  id: Scalars["ID"];
};

export type QueryCompEventItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryCompEventNoticeOfSilenceCompEventNoticePromptArgs = {
  input: CompEventNoticeOfSilenceCompEventNoticePromptInput;
};

export type QueryCompEventNoticeOfSilenceOwnAssessmentPromptArgs = {
  input: CompEventNoticeOfSilenceOwnAssessmentPromptInput;
};

export type QueryCompEventNoticeOfSilenceQuotationPromptArgs = {
  input: CompEventNoticeOfSilenceQuotationPromptInput;
};

export type QueryCompEventNoticePromptArgs = {
  input: CompEventNoticePromptInput;
};

export type QueryCompEventNoticeRequestQuotationPromptArgs = {
  input: CompEventNoticeRequestQuotationPromptInput;
};

export type QueryCompEventOwnAssessmentNoticePromptArgs = {
  input: CompEventOwnAssessmentNoticePromptInput;
};

export type QueryCompEventOwnAssessmentPromptArgs = {
  input: CompEventOwnAssessmentPromptInput;
};

export type QueryCompEventQuotationArgs = {
  id: Scalars["ID"];
};

export type QueryCompEventQuotationAcceptancePromptArgs = {
  input: CompEventQuotationAcceptancePromptInput;
};

export type QueryCompEventQuotationAssessmentsArgs = {
  compEventId: Scalars["ID"];
};

export type QueryCompEventQuotationPromptArgs = {
  input: CompEventQuotationPromptInput;
};

export type QueryCompEventQuotationRevisionRequestPromptArgs = {
  input: CompEventQuotationRevisionRequestPromptInput;
};

export type QueryCompEventRejectionPromptArgs = {
  input: CompEventRejectionPromptInput;
};

export type QueryCompEventRequestForMoreTimePromptArgs = {
  input: CompEventRequestForMoreTimePromptInput;
};

export type QueryCompanyArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryCompanyExistsArgs = {
  registeredName: Scalars["String"];
  tradingName: Scalars["String"];
};

export type QueryCompanyLookupCollectionArgs = {
  id: Scalars["ID"];
};

export type QueryCompanyLookupCollectionsArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type QueryContractArgs = {
  id: Scalars["ID"];
};

export type QueryContractBindingArgs = {
  id: Scalars["ID"];
};

export type QueryContractBindingTypeArgs = {
  id: Scalars["ID"];
};

export type QueryContractTypeArgs = {
  id: Scalars["ID"];
};

export type QueryDailyDiaryDelayImportSourceItemsArgs = {
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryDelayRecordsArgs = {
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDailyDiaryEquipmentImportSourceItemsArgs = {
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryEquipmentRecordsArgs = {
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDailyDiaryGeneralImportSourceItemsArgs = {
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryGeneralRecordsArgs = {
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDailyDiaryHseImportSourceItemsArgs = {
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryHseRecordsArgs = {
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDailyDiaryItemArgs = {
  id: Scalars["ID"];
};

export type QueryDailyDiaryItemByDateArgs = {
  date: Scalars["AWSDate"];
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryItemCommentsArgs = {
  dailyDiaryId: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
  revisionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDailyDiaryItemsArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryItemsRequiresMyAttentionArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryItemsReviewedByMeArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryItemsSentByMeArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryItemsSubmittedByMeArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryManpowerImportSourceItemsArgs = {
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryManpowerRecordsArgs = {
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDailyDiaryPresetArgs = {
  id: Scalars["ID"];
};

export type QueryDailyDiaryWeatherImportSourceItemsArgs = {
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryWeatherRecordsArgs = {
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDailyDiaryWorkImportSourceItemsArgs = {
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
  productInstanceId: Scalars["ID"];
};

export type QueryDailyDiaryWorkRecordsArgs = {
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDisabledClaimTypeOverrideArgs = {
  id: Scalars["ID"];
};

export type QueryDisabledClaimTypesOverrideArgs = {
  contractId: Scalars["ID"];
};

export type QueryDocumentTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryDocumentTemplateRouteArgs = {
  id: Scalars["ID"];
};

export type QueryDocumentTemplateRoutesOverrideArgs = {
  contractId: Scalars["ID"];
};

export type QueryDocumentTemplatesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type QueryDraftEarlyWarningItemArgs = {
  id: Scalars["ID"];
};

export type QueryDraftEarlyWarningItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryDraftEventItemArgs = {
  id: Scalars["ID"];
};

export type QueryDraftEventItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryDraftInstructionItemArgs = {
  id: Scalars["ID"];
};

export type QueryDraftInstructionItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryDraftRiskItemArgs = {
  id: Scalars["ID"];
};

export type QueryDraftRiskItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryEarlyWarningItemArgs = {
  id: Scalars["ID"];
};

export type QueryEarlyWarningItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryEffectOptionArgs = {
  id: Scalars["ID"];
};

export type QueryEffectPresetArgs = {
  id: Scalars["ID"];
};

export type QueryEmailTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryEventItemArgs = {
  id: Scalars["ID"];
};

export type QueryEventItemRecipientsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryEventItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryFieldTypeArgs = {
  id: Scalars["ID"];
};

export type QueryInstructionItemArgs = {
  id: Scalars["ID"];
};

export type QueryInstructionItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryItemEffectPresetArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryItemStatusCollectionArgs = {
  id: Scalars["ID"];
};

export type QueryItemStatusOptionArgs = {
  id: Scalars["ID"];
};

export type QueryItemStatusOptionReasonArgs = {
  id: Scalars["ID"];
};

export type QueryLookupCollectionArgs = {
  id: Scalars["ID"];
};

export type QueryLookupOptionArgs = {
  id: Scalars["ID"];
};

export type QueryNewClaimTypeOverrideArgs = {
  id: Scalars["ID"];
};

export type QueryNewClaimTypesOverrideArgs = {
  contractId: Scalars["ID"];
};

export type QueryNewNotificationsArgs = {
  lastChecked: Scalars["AWSDateTime"];
};

export type QueryNotificationsArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type QueryProductArgs = {
  id: Scalars["ID"];
};

export type QueryProductInstanceArgs = {
  id: Scalars["ID"];
};

export type QueryProductItemNextStepsAllArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type QueryProductItemNextStepsDeemingArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type QueryProductItemNextStepsOtherPartiesArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type QueryProductItemNextStepsYourPartyArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type QueryProductOutputActionArgs = {
  id: Scalars["ID"];
};

export type QueryProductRoleArgs = {
  id: Scalars["ID"];
};

export type QueryProductRoleActionArgs = {
  id: Scalars["ID"];
};

export type QueryProductSchemaArgs = {
  id: Scalars["ID"];
};

export type QueryProjectArgs = {
  id: Scalars["ID"];
};

export type QueryProjectAuthorizationAuditsArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["ID"];
};

export type QueryRiskItemArgs = {
  id: Scalars["ID"];
};

export type QueryRiskItemRecipientsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryRiskItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QuerySchemaFieldArgs = {
  id: Scalars["ID"];
};

export type QuerySchemaSectionArgs = {
  id: Scalars["ID"];
};

export type QuerySeverityPresetArgs = {
  id: Scalars["ID"];
};

export type QuerySeverityRatingArgs = {
  id: Scalars["ID"];
};

export type QueryTimePeriodArgs = {
  id: Scalars["ID"];
};

export type QueryTimePeriodOverrideArgs = {
  id: Scalars["ID"];
};

export type QueryTimePeriodsArgs = {
  contractTypeId: Scalars["ID"];
};

export type QueryTimePeriodsOverrideArgs = {
  contractId: Scalars["ID"];
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryUserPendingAuthorizationAuditsArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type QueryUserRegistrationArgs = {
  invitationId?: InputMaybe<Scalars["ID"]>;
};

export type QueryUsersByContractArgs = {
  contractId: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type QueryVariationActionArgs = {
  input: VariationActionInput;
};

export type QueryVariationAgreementArgs = {
  id: Scalars["ID"];
};

export type QueryVariationAgreementNoticePromptArgs = {
  input: VariationAgreementNoticePromptInput;
};

export type QueryVariationConfirmationPromptArgs = {
  input: VariationConfirmationPromptInput;
};

export type QueryVariationDeterminationArgs = {
  id: Scalars["ID"];
};

export type QueryVariationDeterminationNoticePromptArgs = {
  input: VariationDeterminationNoticePromptInput;
};

export type QueryVariationDraftVariationAgreementArgs = {
  id: Scalars["ID"];
};

export type QueryVariationDraftVariationAgreementPromptArgs = {
  variationId: Scalars["ID"];
};

export type QueryVariationDraftVariationAgreementsArgs = {
  variationId: Scalars["ID"];
};

export type QueryVariationDraftVariationDeterminationArgs = {
  id: Scalars["ID"];
};

export type QueryVariationDraftVariationDeterminationPromptArgs = {
  variationId: Scalars["ID"];
};

export type QueryVariationDraftVariationDeterminationsArgs = {
  variationId: Scalars["ID"];
};

export type QueryVariationDraftVariationProposalArgs = {
  id: Scalars["ID"];
};

export type QueryVariationDraftVariationProposalsArgs = {
  variationId: Scalars["ID"];
};

export type QueryVariationErrorAgreementDisagreementPromptArgs = {
  input: VariationErrorAgreementDisagreementPromptInput;
};

export type QueryVariationErrorAgreementNoticePromptArgs = {
  input: VariationErrorAgreementNoticePromptInput;
};

export type QueryVariationErrorDeterminationDisagreementPromptArgs = {
  input: VariationErrorDeterminationDisagreementPromptInput;
};

export type QueryVariationErrorDeterminationNoticePromptArgs = {
  input: VariationErrorDeterminationNoticePromptInput;
};

export type QueryVariationFurtherParticularsRequestPromptArgs = {
  input: VariationFurtherParticularsRequestPromptInput;
};

export type QueryVariationFurtherParticularsSubmissionPromptArgs = {
  input: VariationFurtherParticularsSubmissionPromptInput;
};

export type QueryVariationHistoryItemArgs = {
  id: Scalars["ID"];
};

export type QueryVariationHistoryItemsArgs = {
  variationId: Scalars["ID"];
};

export type QueryVariationInstructionCommenceWorkPromptArgs = {
  input: VariationInstructionCommenceWorkPromptInput;
};

export type QueryVariationItemArgs = {
  id: Scalars["ID"];
};

export type QueryVariationItemsArgs = {
  productInstanceId: Scalars["ID"];
};

export type QueryVariationNoAgreementNoticePromptArgs = {
  input: VariationNoAgreementNoticePromptInput;
};

export type QueryVariationObjectionProposedVariationPromptArgs = {
  input: VariationObjectionProposedVariationPromptInput;
};

export type QueryVariationObjectionVariationPromptArgs = {
  input: VariationObjectionVariationPromptInput;
};

export type QueryVariationProposalArgs = {
  id: Scalars["ID"];
};

export type QueryVariationProposalAbandonmentPromptArgs = {
  input: VariationProposalAbandonmentPromptInput;
};

export type QueryVariationProposalConsentForProposedVariationPromptArgs = {
  input: VariationProposalConsentForProposedVariationPromptInput;
};

export type QueryVariationProposalExplorerItemsArgs = {
  variationId: Scalars["ID"];
};

export type QueryVariationProposalForProposedVariationPromptArgs = {
  input: VariationProposalForProposedVariationPromptInput;
};

export type QueryVariationProposalForVariationPromptArgs = {
  input: VariationProposalForVariationPromptInput;
};

export type QueryVariationRevisedProposalForProposedVariationRequestPromptArgs =
  {
    input: VariationRevisedProposalForProposedVariationRequestPromptInput;
  };

export type QueryVariationRevocationPromptArgs = {
  input: VariationRevocationPromptInput;
};

export type QueryWorkflowActionsPromptArgs = {
  input: WorkflowActionsPromptInput;
};

export type RecordClaimAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  draftClaimAgreementId: Scalars["ID"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordClaimAgreementNoticePreview = {
  __typename?: "RecordClaimAgreementNoticePreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimAgreementNoticePreviewInput = {
  claimId: Scalars["ID"];
  draftClaimAgreementId: Scalars["ID"];
};

export type RecordClaimDetailsResponseInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  detailedClaimId: Scalars["ID"];
  details: Scalars["String"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordClaimDetailsResponsePreview = {
  __typename?: "RecordClaimDetailsResponsePreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimDetailsResponsePreviewInput = {
  claimId: Scalars["ID"];
  detailedClaimId: Scalars["ID"];
  details: Scalars["String"];
};

export type RecordClaimDeterminationNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  draftClaimDeterminationId: Scalars["ID"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordClaimDeterminationNoticePreview = {
  __typename?: "RecordClaimDeterminationNoticePreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimDeterminationNoticePreviewInput = {
  claimId: Scalars["ID"];
  draftClaimDeterminationId: Scalars["ID"];
};

export type RecordClaimErrorAgreementDisagreementInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
};

export type RecordClaimErrorAgreementDisagreementPreview = {
  __typename?: "RecordClaimErrorAgreementDisagreementPreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimErrorAgreementDisagreementPreviewInput = {
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type RecordClaimErrorAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  details: Scalars["String"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordClaimErrorAgreementNoticePreview = {
  __typename?: "RecordClaimErrorAgreementNoticePreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimErrorAgreementNoticePreviewInput = {
  claimId: Scalars["ID"];
  details: Scalars["String"];
};

export type RecordClaimErrorDeterminationDisagreementInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
};

export type RecordClaimErrorDeterminationDisagreementPreview = {
  __typename?: "RecordClaimErrorDeterminationDisagreementPreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimErrorDeterminationDisagreementPreviewInput = {
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type RecordClaimErrorDeterminationNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  details: Scalars["String"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordClaimErrorDeterminationNoticePreview = {
  __typename?: "RecordClaimErrorDeterminationNoticePreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimErrorDeterminationNoticePreviewInput = {
  claimId: Scalars["ID"];
  details: Scalars["String"];
};

export type RecordClaimFinalClaimDetailsInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  draftDetailedClaimId: Scalars["ID"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordClaimFinalClaimDetailsPreview = {
  __typename?: "RecordClaimFinalClaimDetailsPreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimFinalClaimDetailsPreviewInput = {
  claimId: Scalars["ID"];
  draftDetailedClaimId: Scalars["ID"];
};

export type RecordClaimFurtherParticularsRequestInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  reasons: Scalars["String"];
};

export type RecordClaimFurtherParticularsRequestPreview = {
  __typename?: "RecordClaimFurtherParticularsRequestPreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimFurtherParticularsRequestPreviewInput = {
  claimId: Scalars["ID"];
  reasons: Scalars["String"];
};

export type RecordClaimFurtherParticularsSubmissionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  details: Scalars["String"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordClaimFurtherParticularsSubmissionPreview = {
  __typename?: "RecordClaimFurtherParticularsSubmissionPreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimFurtherParticularsSubmissionPreviewInput = {
  claimId: Scalars["ID"];
  details: Scalars["String"];
};

export type RecordClaimInterimClaimDetailsInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  draftDetailedClaimId: Scalars["ID"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordClaimInterimClaimDetailsPreview = {
  __typename?: "RecordClaimInterimClaimDetailsPreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimInterimClaimDetailsPreviewInput = {
  claimId: Scalars["ID"];
  draftDetailedClaimId: Scalars["ID"];
};

export type RecordClaimNoAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
};

export type RecordClaimNoAgreementNoticePreview = {
  __typename?: "RecordClaimNoAgreementNoticePreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimNoAgreementNoticePreviewInput = {
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type RecordClaimNoticeInput = {
  advanceWarningId?: InputMaybe<Scalars["ID"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimTypeId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: ClaimRegardingType;
};

export type RecordClaimNoticeInvalidNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  reasons: Scalars["String"];
};

export type RecordClaimNoticeInvalidNoticePreview = {
  __typename?: "RecordClaimNoticeInvalidNoticePreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimNoticeInvalidNoticePreviewInput = {
  claimId: Scalars["ID"];
  reasons: Scalars["String"];
};

export type RecordClaimNoticeLapsedNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
};

export type RecordClaimNoticeLapsedNoticePreview = {
  __typename?: "RecordClaimNoticeLapsedNoticePreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimNoticeLapsedNoticePreviewInput = {
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type RecordClaimNoticePreview = {
  __typename?: "RecordClaimNoticePreview";
  recipients: Array<ClaimRecipient>;
};

export type RecordClaimNoticePreviewInput = {
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: ClaimRegardingType;
};

export type RecordCompEventAbandonInstructionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type RecordCompEventAbandonInstructionPreview = {
  __typename?: "RecordCompEventAbandonInstructionPreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventAbandonInstructionPreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventConfirmationInput = {
  assumptions?: InputMaybe<Scalars["String"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  ewCouldveBeenGiven: Scalars["Boolean"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordCompEventConfirmationPreview = {
  __typename?: "RecordCompEventConfirmationPreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventConfirmationPreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventDenyingOfMoreTimeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type RecordCompEventDenyingOfMoreTimePreview = {
  __typename?: "RecordCompEventDenyingOfMoreTimePreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventDenyingOfMoreTimePreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventGrantingOfMoreTimeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  grantedDays: Scalars["Int"];
  number: Scalars["String"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type RecordCompEventGrantingOfMoreTimePreview = {
  __typename?: "RecordCompEventGrantingOfMoreTimePreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventGrantingOfMoreTimePreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimTypeId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  earlyWarningId?: InputMaybe<Scalars["ID"]>;
  givenById: Scalars["ID"];
  number: Scalars["String"];
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: CompEventRegardingType;
};

export type RecordCompEventNoticeOfSilenceCompEventNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type RecordCompEventNoticeOfSilenceCompEventNoticePreview = {
  __typename?: "RecordCompEventNoticeOfSilenceCompEventNoticePreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventNoticeOfSilenceCompEventNoticePreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventNoticeOfSilenceOwnAssessmentInput = {
  acceptedQuotationId: Scalars["ID"];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type RecordCompEventNoticeOfSilenceOwnAssessmentPreview = {
  __typename?: "RecordCompEventNoticeOfSilenceOwnAssessmentPreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventNoticeOfSilenceOwnAssessmentPreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventNoticeOfSilenceQuotationInput = {
  acceptedQuotationId: Scalars["ID"];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type RecordCompEventNoticeOfSilenceQuotationPreview = {
  __typename?: "RecordCompEventNoticeOfSilenceQuotationPreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventNoticeOfSilenceQuotationPreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventNoticePreview = {
  __typename?: "RecordCompEventNoticePreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventNoticePreviewInput = {
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: CompEventRegardingType;
};

export type RecordCompEventNoticeRequestQuotationInput = {
  assumptions?: InputMaybe<Scalars["String"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimTypeId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: CompEventRegardingType;
};

export type RecordCompEventNoticeRequestQuotationPreview = {
  __typename?: "RecordCompEventNoticeRequestQuotationPreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventNoticeRequestQuotationPreviewInput = {
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: CompEventRegardingType;
};

export type RecordCompEventOwnAssessmentInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  draftAssessmentId: Scalars["ID"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordCompEventOwnAssessmentNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  reason: Scalars["String"];
};

export type RecordCompEventOwnAssessmentNoticePreview = {
  __typename?: "RecordCompEventOwnAssessmentNoticePreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventOwnAssessmentNoticePreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventOwnAssessmentPreview = {
  __typename?: "RecordCompEventOwnAssessmentPreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventOwnAssessmentPreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventQuotationAcceptanceInput = {
  acceptedQuotationId: Scalars["ID"];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
};

export type RecordCompEventQuotationAcceptancePreview = {
  __typename?: "RecordCompEventQuotationAcceptancePreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventQuotationAcceptancePreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventQuotationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  preferredQuotationId: Scalars["ID"];
  quotationIds: Array<Scalars["ID"]>;
};

export type RecordCompEventQuotationPreview = {
  __typename?: "RecordCompEventQuotationPreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventQuotationPreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventQuotationRevisionRequestInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  reason: Scalars["String"];
};

export type RecordCompEventQuotationRevisionRequestPreview = {
  __typename?: "RecordCompEventQuotationRevisionRequestPreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventQuotationRevisionRequestPreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventRejectionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  reason: Scalars["String"];
};

export type RecordCompEventRejectionPreview = {
  __typename?: "RecordCompEventRejectionPreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventRejectionPreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordCompEventRequestForMoreTimeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks?: InputMaybe<Scalars["String"]>;
  requestedDays: Scalars["Int"];
};

export type RecordCompEventRequestForMoreTimePreview = {
  __typename?: "RecordCompEventRequestForMoreTimePreview";
  recipients: Array<CompEventRecipient>;
};

export type RecordCompEventRequestForMoreTimePreviewInput = {
  compEventId: Scalars["ID"];
};

export type RecordEarlyWarningItemInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  data: ItemDataInput;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  productInstanceId: Scalars["ID"];
};

export type RecordInstructionItemInput = {
  assumptions?: InputMaybe<Scalars["String"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimTypeId?: InputMaybe<Scalars["ID"]>;
  data: ItemDataInput;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  productInstanceId: Scalars["ID"];
};

export type RecordVariationAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  draftVariationAgreementId: Scalars["ID"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationAgreementNoticePreview = {
  __typename?: "RecordVariationAgreementNoticePreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationAgreementNoticePreviewInput = {
  draftVariationAgreementId: Scalars["ID"];
  variationId: Scalars["ID"];
};

export type RecordVariationConfirmationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationConfirmationPreview = {
  __typename?: "RecordVariationConfirmationPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationConfirmationPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationDeterminationNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  draftVariationDeterminationId: Scalars["ID"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationDeterminationNoticePreview = {
  __typename?: "RecordVariationDeterminationNoticePreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationDeterminationNoticePreviewInput = {
  draftVariationDeterminationId: Scalars["ID"];
  variationId: Scalars["ID"];
};

export type RecordVariationErrorAgreementDisagreementInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationErrorAgreementDisagreementPreview = {
  __typename?: "RecordVariationErrorAgreementDisagreementPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationErrorAgreementDisagreementPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationErrorAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  details: Scalars["String"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationErrorAgreementNoticePreview = {
  __typename?: "RecordVariationErrorAgreementNoticePreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationErrorAgreementNoticePreviewInput = {
  details: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationErrorDeterminationDisagreementInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationErrorDeterminationDisagreementPreview = {
  __typename?: "RecordVariationErrorDeterminationDisagreementPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationErrorDeterminationDisagreementPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationErrorDeterminationNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  details: Scalars["String"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationErrorDeterminationNoticePreview = {
  __typename?: "RecordVariationErrorDeterminationNoticePreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationErrorDeterminationNoticePreviewInput = {
  details: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationFurtherParticularsRequestInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationFurtherParticularsRequestPreview = {
  __typename?: "RecordVariationFurtherParticularsRequestPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationFurtherParticularsRequestPreviewInput = {
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationFurtherParticularsSubmissionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  details: Scalars["String"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationFurtherParticularsSubmissionPreview = {
  __typename?: "RecordVariationFurtherParticularsSubmissionPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationFurtherParticularsSubmissionPreviewInput = {
  details: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationInstructionCommenceWorkInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationInstructionCommenceWorkPreview = {
  __typename?: "RecordVariationInstructionCommenceWorkPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationInstructionCommenceWorkPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationNoAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationNoAgreementNoticePreview = {
  __typename?: "RecordVariationNoAgreementNoticePreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationNoAgreementNoticePreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationObjectionProposedVariationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationObjectionProposedVariationPreview = {
  __typename?: "RecordVariationObjectionProposedVariationPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationObjectionProposedVariationPreviewInput = {
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationObjectionVariationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationObjectionVariationPreview = {
  __typename?: "RecordVariationObjectionVariationPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationObjectionVariationPreviewInput = {
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationProposalAbandonmentInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationProposalAbandonmentPreview = {
  __typename?: "RecordVariationProposalAbandonmentPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationProposalAbandonmentPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationProposalConsentForProposedVariationInput = {
  acceptedProposalId: Scalars["ID"];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationProposalConsentForProposedVariationPreview = {
  __typename?: "RecordVariationProposalConsentForProposedVariationPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationProposalConsentForProposedVariationPreviewInput = {
  acceptedProposalId: Scalars["ID"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationProposalForProposedVariationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  preferredProposalId: Scalars["ID"];
  proposalIds: Array<Scalars["ID"]>;
  variationId: Scalars["ID"];
};

export type RecordVariationProposalForProposedVariationPreview = {
  __typename?: "RecordVariationProposalForProposedVariationPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationProposalForProposedVariationPreviewInput = {
  preferredProposalId: Scalars["ID"];
  proposalIds: Array<Scalars["ID"]>;
  variationId: Scalars["ID"];
};

export type RecordVariationProposalForVariationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  preferredProposalId: Scalars["ID"];
  proposalIds: Array<Scalars["ID"]>;
  variationId: Scalars["ID"];
};

export type RecordVariationProposalForVariationPreview = {
  __typename?: "RecordVariationProposalForVariationPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationProposalForVariationPreviewInput = {
  preferredProposalId: Scalars["ID"];
  proposalIds: Array<Scalars["ID"]>;
  variationId: Scalars["ID"];
};

export type RecordVariationRevisedProposalForProposedVariationRequestInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationRevisedProposalForProposedVariationRequestPreview = {
  __typename?: "RecordVariationRevisedProposalForProposedVariationRequestPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationRevisedProposalForProposedVariationRequestPreviewInput =
  {
    remarks: Scalars["String"];
    variationId: Scalars["ID"];
  };

export type RecordVariationRevocationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  dateSent: Scalars["AWSDateTime"];
  givenById: Scalars["ID"];
  number: Scalars["String"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RecordVariationRevocationPreview = {
  __typename?: "RecordVariationRevocationPreview";
  recipients: Array<VariationRecipient>;
};

export type RecordVariationRevocationPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type RegisterUserInput = {
  alternateNumber?: InputMaybe<Scalars["AWSPhone"]>;
  country: Scalars["String"];
  dateOfBirth: Scalars["AWSDate"];
  firstname: Scalars["String"];
  invitationId: Scalars["ID"];
  jobTitle: Scalars["String"];
  mobileNumber: Scalars["AWSPhone"];
  password: Scalars["String"];
  profilePicture?: InputMaybe<Scalars["String"]>;
  surname: Scalars["String"];
};

export type ReplyCommentInput = {
  commentId: Scalars["ID"];
  content: Scalars["String"];
  productInstanceId: Scalars["ID"];
};

export type RiskAddedDetails = EventDetails & {
  __typename?: "RiskAddedDetails";
  attachments: Array<ItemAttachmentDataDetails>;
  title: Scalars["String"];
};

export type RiskEditedDetails = EventDetails & {
  __typename?: "RiskEditedDetails";
  fieldChanges: Array<ItemFieldChangeDetails>;
  title: Scalars["String"];
};

export type RiskItem = {
  __typename?: "RiskItem";
  attachments?: Maybe<Array<Attachment>>;
  changeLog: ChangeLogItemList;
  comments: CommentList;
  creator: User;
  creatorId: Scalars["ID"];
  data: ItemData;
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  notificationRecipients: Array<NotificationRecipient>;
  number: Scalars["String"];
  owner: User;
  ownerId: Scalars["ID"];
  productInstance: ProductInstance;
  productInstanceId: Scalars["ID"];
  severity: Scalars["String"];
  statusOption: ItemStatusOption;
  statusOptionId: Scalars["ID"];
  title: Scalars["String"];
};

export type RiskItemCommentsArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type RiskItemList = {
  __typename?: "RiskItemList";
  items: Array<RiskItem>;
};

export type RiskOwnerChangedDetails = EventDetails & {
  __typename?: "RiskOwnerChangedDetails";
  newOwner: Scalars["String"];
  oldOwner: Scalars["String"];
  title: Scalars["String"];
};

export type RiskStatusChangedDetails = EventDetails & {
  __typename?: "RiskStatusChangedDetails";
  details?: Maybe<Scalars["String"]>;
  newStatus: Scalars["String"];
  oldStatus: Scalars["String"];
  reason: Scalars["String"];
  title: Scalars["String"];
};

export type RisksRegister = Product & {
  __typename?: "RisksRegister";
  allowMultipleInstances: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  numberingFormat: Scalars["String"];
  outputActions: ProductOutputActionList;
  productSchemas: ProductSchemaList;
  roles: ProductRoleList;
  soloModeSupported: Scalars["Boolean"];
  status: ProductStatus;
  statusCollections: ItemStatusCollectionList;
};

export type SchemaField = {
  __typename?: "SchemaField";
  complexTypeItemId?: Maybe<Scalars["ID"]>;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  displayOrder: Scalars["Int"];
  displayText: Scalars["String"];
  extraData?: Maybe<Scalars["String"]>;
  fieldType: FieldType;
  fieldTypeId: Scalars["ID"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  isRequired: Scalars["Boolean"];
  name: Scalars["String"];
  schemaSectionId: Scalars["ID"];
  status: SchemaFieldStatus;
};

export type SchemaFieldList = {
  __typename?: "SchemaFieldList";
  items: Array<SchemaField>;
};

export enum SchemaFieldStatus {
  Active = "Active",
  Removed = "Removed",
}

export type SchemaSection = {
  __typename?: "SchemaSection";
  columnPlacement: ColumnPlacementType;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  displayOrder: Scalars["Int"];
  displayText: Scalars["String"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  name: Scalars["String"];
  productSchemaId: Scalars["ID"];
  schemaFields: SchemaFieldList;
  status: SchemaSectionStatus;
};

export type SchemaSectionList = {
  __typename?: "SchemaSectionList";
  items: Array<SchemaSection>;
};

export enum SchemaSectionStatus {
  Active = "Active",
  Removed = "Removed",
}

export type SendAuthorizationAcceptanceResult = {
  __typename?: "SendAuthorizationAcceptanceResult";
  actionExecuted: Scalars["Boolean"];
  success: Scalars["Boolean"];
};

export type SendClaimAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  draftClaimAgreementId: Scalars["ID"];
};

export type SendClaimAgreementNoticePreview = {
  __typename?: "SendClaimAgreementNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimAgreementNoticePreviewInput = {
  claimId: Scalars["ID"];
  draftClaimAgreementId: Scalars["ID"];
};

export type SendClaimDetailsResponseInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  detailedClaimId: Scalars["ID"];
  details: Scalars["String"];
};

export type SendClaimDetailsResponsePreview = {
  __typename?: "SendClaimDetailsResponsePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimDetailsResponsePreviewInput = {
  claimId: Scalars["ID"];
  detailedClaimId: Scalars["ID"];
  details: Scalars["String"];
};

export type SendClaimDeterminationNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  draftClaimDeterminationId: Scalars["ID"];
};

export type SendClaimDeterminationNoticePreview = {
  __typename?: "SendClaimDeterminationNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimDeterminationNoticePreviewInput = {
  claimId: Scalars["ID"];
  draftClaimDeterminationId: Scalars["ID"];
};

export type SendClaimErrorAgreementDisagreementInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type SendClaimErrorAgreementDisagreementPreview = {
  __typename?: "SendClaimErrorAgreementDisagreementPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimErrorAgreementDisagreementPreviewInput = {
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type SendClaimErrorAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  details: Scalars["String"];
};

export type SendClaimErrorAgreementNoticePreview = {
  __typename?: "SendClaimErrorAgreementNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimErrorAgreementNoticePreviewInput = {
  claimId: Scalars["ID"];
  details: Scalars["String"];
};

export type SendClaimErrorDeterminationDisagreementInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type SendClaimErrorDeterminationDisagreementPreview = {
  __typename?: "SendClaimErrorDeterminationDisagreementPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimErrorDeterminationDisagreementPreviewInput = {
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type SendClaimErrorDeterminationNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  details: Scalars["String"];
};

export type SendClaimErrorDeterminationNoticePreview = {
  __typename?: "SendClaimErrorDeterminationNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimErrorDeterminationNoticePreviewInput = {
  claimId: Scalars["ID"];
  details: Scalars["String"];
};

export type SendClaimFinalClaimDetailsInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  draftDetailedClaimId: Scalars["ID"];
};

export type SendClaimFinalClaimDetailsPreview = {
  __typename?: "SendClaimFinalClaimDetailsPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimFinalClaimDetailsPreviewInput = {
  claimId: Scalars["ID"];
  draftDetailedClaimId: Scalars["ID"];
};

export type SendClaimFurtherParticularsRequestInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  reasons: Scalars["String"];
};

export type SendClaimFurtherParticularsRequestPreview = {
  __typename?: "SendClaimFurtherParticularsRequestPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimFurtherParticularsRequestPreviewInput = {
  claimId: Scalars["ID"];
  reasons: Scalars["String"];
};

export type SendClaimFurtherParticularsSubmissionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  details: Scalars["String"];
};

export type SendClaimFurtherParticularsSubmissionPreview = {
  __typename?: "SendClaimFurtherParticularsSubmissionPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimFurtherParticularsSubmissionPreviewInput = {
  claimId: Scalars["ID"];
  details: Scalars["String"];
};

export type SendClaimInterimClaimDetailsInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  draftDetailedClaimId: Scalars["ID"];
};

export type SendClaimInterimClaimDetailsPreview = {
  __typename?: "SendClaimInterimClaimDetailsPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimInterimClaimDetailsPreviewInput = {
  claimId: Scalars["ID"];
  draftDetailedClaimId: Scalars["ID"];
};

export type SendClaimNoAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type SendClaimNoAgreementNoticePreview = {
  __typename?: "SendClaimNoAgreementNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimNoAgreementNoticePreviewInput = {
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type SendClaimNoticeInput = {
  advanceWarningId?: InputMaybe<Scalars["ID"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimTypeId: Scalars["ID"];
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: ClaimRegardingType;
};

export type SendClaimNoticeInvalidNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  reasons: Scalars["String"];
};

export type SendClaimNoticeInvalidNoticePreview = {
  __typename?: "SendClaimNoticeInvalidNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimNoticeInvalidNoticePreviewInput = {
  claimId: Scalars["ID"];
  reasons: Scalars["String"];
};

export type SendClaimNoticeLapsedNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type SendClaimNoticeLapsedNoticePreview = {
  __typename?: "SendClaimNoticeLapsedNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimNoticeLapsedNoticePreviewInput = {
  claimId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type SendClaimNoticePreview = {
  __typename?: "SendClaimNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<ClaimRecipient>;
};

export type SendClaimNoticePreviewInput = {
  advanceWarningId?: InputMaybe<Scalars["ID"]>;
  claimTypeId: Scalars["ID"];
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: ClaimRegardingType;
};

export type SendCompEventAbandonInstructionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventAbandonInstructionPreview = {
  __typename?: "SendCompEventAbandonInstructionPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventAbandonInstructionPreviewInput = {
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventConfirmationInput = {
  assumptions?: InputMaybe<Scalars["String"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  ewCouldveBeenGiven: Scalars["Boolean"];
};

export type SendCompEventConfirmationPreview = {
  __typename?: "SendCompEventConfirmationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventConfirmationPreviewInput = {
  assumptions?: InputMaybe<Scalars["String"]>;
  compEventId: Scalars["ID"];
  ewCouldveBeenGiven: Scalars["Boolean"];
};

export type SendCompEventDenyingOfMoreTimeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventDenyingOfMoreTimePreview = {
  __typename?: "SendCompEventDenyingOfMoreTimePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventDenyingOfMoreTimePreviewInput = {
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventGrantingOfMoreTimeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  grantedDays: Scalars["Int"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventGrantingOfMoreTimePreview = {
  __typename?: "SendCompEventGrantingOfMoreTimePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventGrantingOfMoreTimePreviewInput = {
  compEventId: Scalars["ID"];
  grantedDays: Scalars["Int"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimTypeId: Scalars["ID"];
  earlyWarningId?: InputMaybe<Scalars["ID"]>;
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: CompEventRegardingType;
};

export type SendCompEventNoticeOfSilenceCompEventNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventNoticeOfSilenceCompEventNoticePreview = {
  __typename?: "SendCompEventNoticeOfSilenceCompEventNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventNoticeOfSilenceCompEventNoticePreviewInput = {
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventNoticeOfSilenceOwnAssessmentInput = {
  acceptedQuotationId: Scalars["ID"];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventNoticeOfSilenceOwnAssessmentPreview = {
  __typename?: "SendCompEventNoticeOfSilenceOwnAssessmentPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventNoticeOfSilenceOwnAssessmentPreviewInput = {
  acceptedQuotationId: Scalars["ID"];
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventNoticeOfSilenceQuotationInput = {
  acceptedQuotationId: Scalars["ID"];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventNoticeOfSilenceQuotationPreview = {
  __typename?: "SendCompEventNoticeOfSilenceQuotationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventNoticeOfSilenceQuotationPreviewInput = {
  acceptedQuotationId: Scalars["ID"];
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
};

export type SendCompEventNoticePreview = {
  __typename?: "SendCompEventNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventNoticePreviewInput = {
  claimTypeId: Scalars["ID"];
  earlyWarningId?: InputMaybe<Scalars["ID"]>;
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: CompEventRegardingType;
};

export type SendCompEventNoticeRequestQuotationInput = {
  assumptions?: InputMaybe<Scalars["String"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimTypeId: Scalars["ID"];
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: CompEventRegardingType;
};

export type SendCompEventNoticeRequestQuotationPreview = {
  __typename?: "SendCompEventNoticeRequestQuotationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventNoticeRequestQuotationPreviewInput = {
  assumptions?: InputMaybe<Scalars["String"]>;
  claimTypeId: Scalars["ID"];
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingType: CompEventRegardingType;
};

export type SendCompEventOwnAssessmentInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  draftAssessmentId: Scalars["ID"];
};

export type SendCompEventOwnAssessmentNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  reason: Scalars["String"];
};

export type SendCompEventOwnAssessmentNoticePreview = {
  __typename?: "SendCompEventOwnAssessmentNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventOwnAssessmentNoticePreviewInput = {
  compEventId: Scalars["ID"];
  reason: Scalars["String"];
};

export type SendCompEventOwnAssessmentPreview = {
  __typename?: "SendCompEventOwnAssessmentPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventOwnAssessmentPreviewInput = {
  compEventId: Scalars["ID"];
  draftAssessmentId: Scalars["ID"];
};

export type SendCompEventQuotationAcceptanceInput = {
  acceptedQuotationId: Scalars["ID"];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
};

export type SendCompEventQuotationAcceptancePreview = {
  __typename?: "SendCompEventQuotationAcceptancePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventQuotationAcceptancePreviewInput = {
  acceptedQuotationId: Scalars["ID"];
  compEventId: Scalars["ID"];
};

export type SendCompEventQuotationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  preferredQuotationId: Scalars["ID"];
  quotationIds: Array<Scalars["ID"]>;
};

export type SendCompEventQuotationPreview = {
  __typename?: "SendCompEventQuotationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventQuotationPreviewInput = {
  compEventId: Scalars["ID"];
  quotationIds: Array<Scalars["ID"]>;
};

export type SendCompEventQuotationRevisionRequestInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  reason: Scalars["String"];
};

export type SendCompEventQuotationRevisionRequestPreview = {
  __typename?: "SendCompEventQuotationRevisionRequestPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventQuotationRevisionRequestPreviewInput = {
  compEventId: Scalars["ID"];
  reason: Scalars["String"];
};

export type SendCompEventRejectionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  reason: Scalars["String"];
};

export type SendCompEventRejectionPreview = {
  __typename?: "SendCompEventRejectionPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventRejectionPreviewInput = {
  compEventId: Scalars["ID"];
  reason: Scalars["String"];
};

export type SendCompEventRequestForMoreTimeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
  requestedDays: Scalars["Int"];
};

export type SendCompEventRequestForMoreTimePreview = {
  __typename?: "SendCompEventRequestForMoreTimePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<CompEventRecipient>;
};

export type SendCompEventRequestForMoreTimePreviewInput = {
  compEventId: Scalars["ID"];
  remarks?: InputMaybe<Scalars["String"]>;
  requestedDays: Scalars["Int"];
};

export type SendEarlyWarningItemInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  data: ItemDataInput;
  productInstanceId: Scalars["ID"];
};

export type SendInstructionItemInput = {
  assumptions?: InputMaybe<Scalars["String"]>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  claimTypeId?: InputMaybe<Scalars["ID"]>;
  data: ItemDataInput;
  productInstanceId: Scalars["ID"];
};

export type SendVariationAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  draftVariationAgreementId: Scalars["ID"];
  variationId: Scalars["ID"];
};

export type SendVariationAgreementNoticePreview = {
  __typename?: "SendVariationAgreementNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationAgreementNoticePreviewInput = {
  draftVariationAgreementId: Scalars["ID"];
  variationId: Scalars["ID"];
};

export type SendVariationConfirmationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationConfirmationPreview = {
  __typename?: "SendVariationConfirmationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationConfirmationPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationDeterminationNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  draftVariationDeterminationId: Scalars["ID"];
  variationId: Scalars["ID"];
};

export type SendVariationDeterminationNoticePreview = {
  __typename?: "SendVariationDeterminationNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationDeterminationNoticePreviewInput = {
  draftVariationDeterminationId: Scalars["ID"];
  variationId: Scalars["ID"];
};

export type SendVariationErrorAgreementDisagreementInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationErrorAgreementDisagreementPreview = {
  __typename?: "SendVariationErrorAgreementDisagreementPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationErrorAgreementDisagreementPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationErrorAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  details: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationErrorAgreementNoticePreview = {
  __typename?: "SendVariationErrorAgreementNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationErrorAgreementNoticePreviewInput = {
  details: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationErrorDeterminationDisagreementInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationErrorDeterminationDisagreementPreview = {
  __typename?: "SendVariationErrorDeterminationDisagreementPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationErrorDeterminationDisagreementPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationErrorDeterminationNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  details: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationErrorDeterminationNoticePreview = {
  __typename?: "SendVariationErrorDeterminationNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationErrorDeterminationNoticePreviewInput = {
  details: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationFurtherParticularsRequestInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationFurtherParticularsRequestPreview = {
  __typename?: "SendVariationFurtherParticularsRequestPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationFurtherParticularsRequestPreviewInput = {
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationFurtherParticularsSubmissionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  details: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationFurtherParticularsSubmissionPreview = {
  __typename?: "SendVariationFurtherParticularsSubmissionPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationFurtherParticularsSubmissionPreviewInput = {
  details: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationInstructionCommenceWorkInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationInstructionCommenceWorkPreview = {
  __typename?: "SendVariationInstructionCommenceWorkPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationInstructionCommenceWorkPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationNoAgreementNoticeInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationNoAgreementNoticePreview = {
  __typename?: "SendVariationNoAgreementNoticePreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationNoAgreementNoticePreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationObjectionProposedVariationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationObjectionProposedVariationPreview = {
  __typename?: "SendVariationObjectionProposedVariationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationObjectionProposedVariationPreviewInput = {
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationObjectionVariationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationObjectionVariationPreview = {
  __typename?: "SendVariationObjectionVariationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationObjectionVariationPreviewInput = {
  reasons: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationProposalAbandonmentInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationProposalAbandonmentPreview = {
  __typename?: "SendVariationProposalAbandonmentPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationProposalAbandonmentPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationProposalConsentForProposedVariationInput = {
  acceptedProposalId: Scalars["ID"];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationProposalConsentForProposedVariationPreview = {
  __typename?: "SendVariationProposalConsentForProposedVariationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationProposalConsentForProposedVariationPreviewInput = {
  acceptedProposalId: Scalars["ID"];
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationProposalForProposedVariationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  preferredProposalId: Scalars["ID"];
  proposalIds: Array<Scalars["ID"]>;
  variationId: Scalars["ID"];
};

export type SendVariationProposalForProposedVariationPreview = {
  __typename?: "SendVariationProposalForProposedVariationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationProposalForProposedVariationPreviewInput = {
  preferredProposalId: Scalars["ID"];
  proposalIds: Array<Scalars["ID"]>;
  variationId: Scalars["ID"];
};

export type SendVariationProposalForVariationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  preferredProposalId: Scalars["ID"];
  proposalIds: Array<Scalars["ID"]>;
  variationId: Scalars["ID"];
};

export type SendVariationProposalForVariationPreview = {
  __typename?: "SendVariationProposalForVariationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationProposalForVariationPreviewInput = {
  preferredProposalId: Scalars["ID"];
  proposalIds: Array<Scalars["ID"]>;
  variationId: Scalars["ID"];
};

export type SendVariationRevisedProposalForProposedVariationRequestInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationRevisedProposalForProposedVariationRequestPreview = {
  __typename?: "SendVariationRevisedProposalForProposedVariationRequestPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationRevisedProposalForProposedVariationRequestPreviewInput =
  {
    remarks: Scalars["String"];
    variationId: Scalars["ID"];
  };

export type SendVariationRevocationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SendVariationRevocationPreview = {
  __typename?: "SendVariationRevocationPreview";
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  recipients: Array<VariationRecipient>;
};

export type SendVariationRevocationPreviewInput = {
  remarks: Scalars["String"];
  variationId: Scalars["ID"];
};

export type SeverityPreset = {
  __typename?: "SeverityPreset";
  consequenceLabel: Scalars["String"];
  consequenceSets: Array<Scalars["String"]>;
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
  isInternal: Scalars["Boolean"];
  likelihoodLabel: Scalars["String"];
  likelihoodSets: Array<Scalars["String"]>;
  name: Scalars["String"];
  ratings: SeverityRatingList;
  status: SeverityPresetStatus;
};

export type SeverityPresetList = {
  __typename?: "SeverityPresetList";
  items: Array<SeverityPreset>;
};

export enum SeverityPresetStatus {
  Active = "Active",
  Removed = "Removed",
}

export type SeverityRating = {
  __typename?: "SeverityRating";
  colour: Scalars["String"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  label: Scalars["String"];
  lowerRange: Scalars["Int"];
  name: Scalars["String"];
  severityPresetId: Scalars["ID"];
  status: SeverityRatingStatus;
  upperRange: Scalars["Int"];
};

export type SeverityRatingList = {
  __typename?: "SeverityRatingList";
  items: Array<SeverityRating>;
};

export enum SeverityRatingStatus {
  Active = "Active",
  Removed = "Removed",
}

export type SystemVersion = {
  __typename?: "SystemVersion";
  assemblyVersion: Scalars["String"];
  environment: Scalars["String"];
};

export type TimePeriod = {
  __typename?: "TimePeriod";
  contractType: ContractType;
  contractTypeId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  description: Scalars["String"];
  duration: Scalars["Int"];
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  reference: Scalars["String"];
  status: TimePeriodStatus;
};

export type TimePeriodList = {
  __typename?: "TimePeriodList";
  items: Array<TimePeriod>;
};

export type TimePeriodOverride = {
  __typename?: "TimePeriodOverride";
  contract: Contract;
  contractId: Scalars["ID"];
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  duration: Scalars["Int"];
  id: Scalars["ID"];
  timePeriod: TimePeriod;
  timePeriodId: Scalars["ID"];
};

export type TimePeriodOverrideList = {
  __typename?: "TimePeriodOverrideList";
  items: Array<TimePeriodOverride>;
};

export enum TimePeriodStatus {
  Active = "Active",
  Removed = "Removed",
}

export type User = {
  __typename?: "User";
  alternateNumber?: Maybe<Scalars["AWSPhone"]>;
  company: Company;
  companyId: Scalars["ID"];
  country: Scalars["String"];
  dateInvited: Scalars["AWSDateTime"];
  dateOfBirth?: Maybe<Scalars["AWSDate"]>;
  email: Scalars["AWSEmail"];
  firstname: Scalars["String"];
  id: Scalars["ID"];
  invitedBy: User;
  jobTitle: Scalars["String"];
  lastActive?: Maybe<Scalars["AWSDateTime"]>;
  mobileNumber?: Maybe<Scalars["AWSPhone"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  registered?: Maybe<Scalars["Boolean"]>;
  registeredDate?: Maybe<Scalars["AWSDateTime"]>;
  roles: ProductUserRoleList;
  status: UserStatus;
  surname: Scalars["String"];
};

export type UserChangePasswordInput = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type UserChangePasswordResponse = {
  __typename?: "UserChangePasswordResponse";
  success: Scalars["Boolean"];
};

export type UserList = {
  __typename?: "UserList";
  items: Array<User>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type UserMentionedDetails = EventDetails & {
  __typename?: "UserMentionedDetails";
  content: Scalars["String"];
  title: Scalars["String"];
  versionId?: Maybe<Scalars["ID"]>;
};

export type UserPreferences = {
  __typename?: "UserPreferences";
  anyNewCEActionRecorded: UserPreferencesValue;
  anyNewCEActionSent: UserPreferencesValue;
  anyNewClaimActionRecorded: UserPreferencesValue;
  anyNewClaimActionSent: UserPreferencesValue;
  anyNewEvent: UserPreferencesValue;
  anyNewRecordedEW: UserPreferencesValue;
  anyNewRecordedInstruction: UserPreferencesValue;
  anyNewRisk: UserPreferencesValue;
  anyNewSentEW: UserPreferencesValue;
  anyNewSentInstruction: UserPreferencesValue;
  anyNewVariationActionRecorded: UserPreferencesValue;
  anyNewVariationActionSent: UserPreferencesValue;
  attachmentChanges: UserPreferencesValue;
  authorizationRequired: UserPreferencesValue;
  authorizedActionExecuted: UserPreferencesValue;
  authorizedActionPrevented: UserPreferencesValue;
  changesToAnyEW: UserPreferencesValue;
  changesToAnyEvent: UserPreferencesValue;
  changesToAnyInstruction: UserPreferencesValue;
  changesToAnyRisk: UserPreferencesValue;
  commentsMentioned: UserPreferencesValue;
  commentsReplied: UserPreferencesValue;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  newComments: UserPreferencesValue;
  ownershipAssignment: UserPreferencesValue;
  userId: Scalars["ID"];
};

export type UserPreferencesInput = {
  anyNewCEActionRecorded: UserPreferencesValueInput;
  anyNewCEActionSent: UserPreferencesValueInput;
  anyNewClaimActionRecorded: UserPreferencesValueInput;
  anyNewClaimActionSent: UserPreferencesValueInput;
  anyNewEvent: UserPreferencesValueInput;
  anyNewRecordedEW: UserPreferencesValueInput;
  anyNewRecordedInstruction: UserPreferencesValueInput;
  anyNewRisk: UserPreferencesValueInput;
  anyNewSentEW: UserPreferencesValueInput;
  anyNewSentInstruction: UserPreferencesValueInput;
  anyNewVariationActionRecorded: UserPreferencesValueInput;
  anyNewVariationActionSent: UserPreferencesValueInput;
  attachmentChanges: UserPreferencesValueInput;
  authorizationRequired: UserPreferencesValueInput;
  authorizedActionExecuted: UserPreferencesValueInput;
  authorizedActionPrevented: UserPreferencesValueInput;
  changesToAnyEW: UserPreferencesValueInput;
  changesToAnyEvent: UserPreferencesValueInput;
  changesToAnyInstruction: UserPreferencesValueInput;
  changesToAnyRisk: UserPreferencesValueInput;
  commentsMentioned: UserPreferencesValueInput;
  commentsReplied: UserPreferencesValueInput;
  newComments: UserPreferencesValueInput;
  ownershipAssignment: UserPreferencesValueInput;
};

export type UserPreferencesValue = {
  __typename?: "UserPreferencesValue";
  email: Scalars["Boolean"];
  emailReadOnly: Scalars["Boolean"];
  inApp: Scalars["Boolean"];
  inAppReadOnly: Scalars["Boolean"];
};

export type UserPreferencesValueInput = {
  email: Scalars["Boolean"];
  inApp: Scalars["Boolean"];
};

export type UserRegistration = {
  __typename?: "UserRegistration";
  company: Company;
  companyId: Scalars["ID"];
  dateInvited: Scalars["AWSDateTime"];
  email: Scalars["AWSEmail"];
  invitationId: Scalars["ID"];
};

export type UserRoleAssignmentInput = {
  productInstanceId: Scalars["ID"];
  productRoleId: Scalars["ID"];
};

export enum UserStatus {
  Active = "Active",
  Removed = "Removed",
  Suspended = "Suspended",
}

export type VariationAction = {
  __typename?: "VariationAction";
  actionMapping?: Maybe<AuthorizationWorkflowActionMapping>;
  type: VariationActionType;
};

export type VariationActionInput = {
  variationId: Scalars["ID"];
};

export type VariationActionList = {
  __typename?: "VariationActionList";
  items: Array<VariationAction>;
};

export enum VariationActionType {
  AbandonProposedVariation = "AbandonProposedVariation",
  ConfirmInstructionOfVariation = "ConfirmInstructionOfVariation",
  ConsentToProposalForProposedVariation = "ConsentToProposalForProposedVariation",
  DenyRequestForMoreTime = "DenyRequestForMoreTime",
  DisagreeWithNoticeErrorAgreement = "DisagreeWithNoticeErrorAgreement",
  DisagreeWithNoticeErrorDetermination = "DisagreeWithNoticeErrorDetermination",
  GiveReasonsWhyProposalCannotBeGivenForProposedVariation = "GiveReasonsWhyProposalCannotBeGivenForProposedVariation",
  GrantRequestForMoreTime = "GrantRequestForMoreTime",
  InstructWorkToCommenceOnVariation = "InstructWorkToCommenceOnVariation",
  None = "None",
  NotifyAgreementVariation = "NotifyAgreementVariation",
  NotifyAgreementVariationNotReached = "NotifyAgreementVariationNotReached",
  NotifyDeterminationVariation = "NotifyDeterminationVariation",
  NotifyErrorFoundAgreement = "NotifyErrorFoundAgreement",
  NotifyErrorFoundDetermination = "NotifyErrorFoundDetermination",
  ObjectToInstructionOfVariation = "ObjectToInstructionOfVariation",
  ProvideFurtherParticulars = "ProvideFurtherParticulars",
  RequestFurtherParticulars = "RequestFurtherParticulars",
  RequestMoreTime = "RequestMoreTime",
  RequestRevisedProposalForProposedVariation = "RequestRevisedProposalForProposedVariation",
  RevokeInstructionOfVariation = "RevokeInstructionOfVariation",
  SubmitProposalForProposedVariation = "SubmitProposalForProposedVariation",
  SubmitProposalForVariation = "SubmitProposalForVariation",
}

export type VariationAgreement = {
  __typename?: "VariationAgreement";
  attachments: Array<Attachment>;
  creatorId: Scalars["ID"];
  currency: Scalars["String"];
  dateCreated: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<VariationProposalTimeChange>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type VariationAgreementNoticePrompt = {
  __typename?: "VariationAgreementNoticePrompt";
  draftVariationAgreements: Array<DraftVariationAgreement>;
};

export type VariationAgreementNoticePromptInput = {
  variationId: Scalars["ID"];
};

export type VariationAgreementNoticeRecordedDetails = EventDetails & {
  __typename?: "VariationAgreementNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationAgreementNoticeSentDetails = EventDetails & {
  __typename?: "VariationAgreementNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationConfirmationPrompt = {
  __typename?: "VariationConfirmationPrompt";
  remarks: Scalars["String"];
};

export type VariationConfirmationPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationConfirmationRecordedDetails = EventDetails & {
  __typename?: "VariationConfirmationRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationConfirmationSentDetails = EventDetails & {
  __typename?: "VariationConfirmationSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationDetermination = {
  __typename?: "VariationDetermination";
  attachments: Array<Attachment>;
  creatorId: Scalars["ID"];
  currency: Scalars["String"];
  dateCreated: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<VariationProposalTimeChange>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type VariationDeterminationNoticePrompt = {
  __typename?: "VariationDeterminationNoticePrompt";
  draftVariationDeterminations: Array<DraftVariationDetermination>;
};

export type VariationDeterminationNoticePromptInput = {
  variationId: Scalars["ID"];
};

export type VariationDeterminationNoticeRecordedDetails = EventDetails & {
  __typename?: "VariationDeterminationNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationDeterminationNoticeSentDetails = EventDetails & {
  __typename?: "VariationDeterminationNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationErrorAgreementDisagreementPrompt = {
  __typename?: "VariationErrorAgreementDisagreementPrompt";
  remarks: Scalars["String"];
};

export type VariationErrorAgreementDisagreementPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationErrorAgreementDisagreementRecordedDetails =
  EventDetails & {
    __typename?: "VariationErrorAgreementDisagreementRecordedDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    title: Scalars["String"];
  };

export type VariationErrorAgreementDisagreementSentDetails = EventDetails & {
  __typename?: "VariationErrorAgreementDisagreementSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationErrorAgreementNoticePrompt = {
  __typename?: "VariationErrorAgreementNoticePrompt";
  details: Scalars["String"];
};

export type VariationErrorAgreementNoticePromptInput = {
  variationId: Scalars["ID"];
};

export type VariationErrorAgreementNoticeRecordedDetails = EventDetails & {
  __typename?: "VariationErrorAgreementNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationErrorAgreementNoticeSentDetails = EventDetails & {
  __typename?: "VariationErrorAgreementNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationErrorDeterminationDisagreementPrompt = {
  __typename?: "VariationErrorDeterminationDisagreementPrompt";
  remarks: Scalars["String"];
};

export type VariationErrorDeterminationDisagreementPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationErrorDeterminationDisagreementRecordedDetails =
  EventDetails & {
    __typename?: "VariationErrorDeterminationDisagreementRecordedDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    title: Scalars["String"];
  };

export type VariationErrorDeterminationDisagreementSentDetails =
  EventDetails & {
    __typename?: "VariationErrorDeterminationDisagreementSentDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    title: Scalars["String"];
  };

export type VariationErrorDeterminationNoticePrompt = {
  __typename?: "VariationErrorDeterminationNoticePrompt";
  details: Scalars["String"];
};

export type VariationErrorDeterminationNoticePromptInput = {
  variationId: Scalars["ID"];
};

export type VariationErrorDeterminationNoticeRecordedDetails = EventDetails & {
  __typename?: "VariationErrorDeterminationNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationErrorDeterminationNoticeSentDetails = EventDetails & {
  __typename?: "VariationErrorDeterminationNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationFurtherParticularsEntry = {
  __typename?: "VariationFurtherParticularsEntry";
  requestDateSent: Scalars["AWSDateTime"];
  requestReasons: Scalars["String"];
  responseDateSent: Scalars["AWSDateTime"];
  responseDetails: Scalars["String"];
};

export type VariationFurtherParticularsRequestPrompt = {
  __typename?: "VariationFurtherParticularsRequestPrompt";
  previousRequests: Array<VariationFurtherParticularsEntry>;
  reasons: Scalars["String"];
};

export type VariationFurtherParticularsRequestPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationFurtherParticularsRequestRecordedDetails = EventDetails & {
  __typename?: "VariationFurtherParticularsRequestRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationFurtherParticularsRequestSentDetails = EventDetails & {
  __typename?: "VariationFurtherParticularsRequestSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationFurtherParticularsSubmissionPrompt = {
  __typename?: "VariationFurtherParticularsSubmissionPrompt";
  details: Scalars["String"];
};

export type VariationFurtherParticularsSubmissionPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationFurtherParticularsSubmissionRecordedDetails =
  EventDetails & {
    __typename?: "VariationFurtherParticularsSubmissionRecordedDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    title: Scalars["String"];
  };

export type VariationFurtherParticularsSubmissionSentDetails = EventDetails & {
  __typename?: "VariationFurtherParticularsSubmissionSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationHistoryAbandonmentAnswers = {
  __typename?: "VariationHistoryAbandonmentAnswers";
  remarks: Scalars["String"];
};

export type VariationHistoryAgreementAnswers = {
  __typename?: "VariationHistoryAgreementAnswers";
  agreement?: Maybe<VariationAgreement>;
  agreementId?: Maybe<Scalars["ID"]>;
  draftAgreement?: Maybe<DraftVariationAgreement>;
  draftAgreementId?: Maybe<Scalars["ID"]>;
};

export type VariationHistoryAnswers =
  | VariationHistoryAbandonmentAnswers
  | VariationHistoryAgreementAnswers
  | VariationHistoryConfirmationVariationAnswers
  | VariationHistoryConsentForProposedVariationAnswers
  | VariationHistoryDeterminationAnswers
  | VariationHistoryErrorAgreementDisagreementAnswers
  | VariationHistoryErrorAgreementNoticeAnswers
  | VariationHistoryErrorDeterminationDisagreementAnswers
  | VariationHistoryErrorDeterminationNoticeAnswers
  | VariationHistoryFurtherParticularsRequestAnswers
  | VariationHistoryFurtherParticularsSubmissionAnswers
  | VariationHistoryInstructionCommenceWorkAnswers
  | VariationHistoryNoAgreementReachedAnswers
  | VariationHistoryObjectionProposedVariationAnswers
  | VariationHistoryObjectionVariationAnswers
  | VariationHistoryProposalForProposedVariationAnswers
  | VariationHistoryProposalForVariationAnswers
  | VariationHistoryRevisedProposalRequestAnswers
  | VariationHistoryRevocationVariationAnswers;

export type VariationHistoryConfirmationVariationAnswers = {
  __typename?: "VariationHistoryConfirmationVariationAnswers";
  remarks: Scalars["String"];
};

export type VariationHistoryConsentForProposedVariationAnswers = {
  __typename?: "VariationHistoryConsentForProposedVariationAnswers";
  proposal: VariationProposal;
  proposalId: Scalars["ID"];
  remarks: Scalars["String"];
};

export type VariationHistoryDeterminationAnswers = {
  __typename?: "VariationHistoryDeterminationAnswers";
  determination?: Maybe<VariationDetermination>;
  determinationId?: Maybe<Scalars["ID"]>;
  draftDetermination?: Maybe<DraftVariationDetermination>;
  draftDeterminationId?: Maybe<Scalars["ID"]>;
};

export type VariationHistoryErrorAgreementDisagreementAnswers = {
  __typename?: "VariationHistoryErrorAgreementDisagreementAnswers";
  remarks: Scalars["String"];
};

export type VariationHistoryErrorAgreementNoticeAnswers = {
  __typename?: "VariationHistoryErrorAgreementNoticeAnswers";
  details: Scalars["String"];
};

export type VariationHistoryErrorDeterminationDisagreementAnswers = {
  __typename?: "VariationHistoryErrorDeterminationDisagreementAnswers";
  remarks: Scalars["String"];
};

export type VariationHistoryErrorDeterminationNoticeAnswers = {
  __typename?: "VariationHistoryErrorDeterminationNoticeAnswers";
  details: Scalars["String"];
};

export type VariationHistoryFurtherParticularsRequestAnswers = {
  __typename?: "VariationHistoryFurtherParticularsRequestAnswers";
  reasons: Scalars["String"];
};

export type VariationHistoryFurtherParticularsSubmissionAnswers = {
  __typename?: "VariationHistoryFurtherParticularsSubmissionAnswers";
  details: Scalars["String"];
};

export type VariationHistoryInstructionCommenceWorkAnswers = {
  __typename?: "VariationHistoryInstructionCommenceWorkAnswers";
  agreementSubmitted: Scalars["Boolean"];
  remarks: Scalars["String"];
};

export type VariationHistoryItem = {
  __typename?: "VariationHistoryItem";
  actionNumber: Scalars["String"];
  actionType: VariationActionType;
  answers?: Maybe<VariationHistoryAnswers>;
  attachments: Array<Attachment>;
  authorizationWorkflowAudit?: Maybe<AuthorizationWorkflowAudit>;
  dateCreated: Scalars["AWSDateTime"];
  dateSent: Scalars["AWSDateTime"];
  daysLate: Scalars["Int"];
  dueDate?: Maybe<Scalars["AWSDate"]>;
  id: Scalars["ID"];
  isIncomplete?: Maybe<Scalars["Boolean"]>;
  offline: Scalars["Boolean"];
  recipients: Array<VariationRecipient>;
  sentByParty?: Maybe<ContractBindingType>;
  sentByPartyId?: Maybe<Scalars["ID"]>;
  sentByUser: User;
  sentByUserId: Scalars["ID"];
  variationId: Scalars["ID"];
};

export type VariationHistoryItemList = {
  __typename?: "VariationHistoryItemList";
  items: Array<VariationHistoryItem>;
};

export type VariationHistoryNoAgreementReachedAnswers = {
  __typename?: "VariationHistoryNoAgreementReachedAnswers";
  remarks: Scalars["String"];
};

export type VariationHistoryObjectionProposedVariationAnswers = {
  __typename?: "VariationHistoryObjectionProposedVariationAnswers";
  reasons: Scalars["String"];
};

export type VariationHistoryObjectionVariationAnswers = {
  __typename?: "VariationHistoryObjectionVariationAnswers";
  reasons: Scalars["String"];
};

export type VariationHistoryProposalForProposedVariationAnswers = {
  __typename?: "VariationHistoryProposalForProposedVariationAnswers";
  proposal: VariationProposal;
  proposalId: Scalars["ID"];
};

export type VariationHistoryProposalForVariationAnswers = {
  __typename?: "VariationHistoryProposalForVariationAnswers";
  proposal: VariationProposal;
  proposalId: Scalars["ID"];
};

export type VariationHistoryRevisedProposalRequestAnswers = {
  __typename?: "VariationHistoryRevisedProposalRequestAnswers";
  remarks: Scalars["String"];
};

export type VariationHistoryRevocationVariationAnswers = {
  __typename?: "VariationHistoryRevocationVariationAnswers";
  remarks: Scalars["String"];
};

export type VariationInstructionCommenceWorkPrompt = {
  __typename?: "VariationInstructionCommenceWorkPrompt";
  agreementSubmitted: Scalars["Boolean"];
  remarks: Scalars["String"];
};

export type VariationInstructionCommenceWorkPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationInstructionCommenceWorkRecordedDetails = EventDetails & {
  __typename?: "VariationInstructionCommenceWorkRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationInstructionCommenceWorkSentDetails = EventDetails & {
  __typename?: "VariationInstructionCommenceWorkSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationItem = {
  __typename?: "VariationItem";
  creator: User;
  creatorId: Scalars["ID"];
  dateCreated: Scalars["AWSDateTime"];
  dateModified: Scalars["AWSDateTime"];
  id: Scalars["ID"];
  lastAction: VariationItemLastAction;
  nextStep?: Maybe<VariationItemNextStep>;
  number: Scalars["String"];
  ongoingAuthorizationAudit?: Maybe<AuthorizationWorkflowAudit>;
  priceGranted?: Maybe<Scalars["Float"]>;
  priceSought?: Maybe<Scalars["Float"]>;
  productInstance: ProductInstance;
  productInstanceId: Scalars["ID"];
  regardingId: Scalars["ID"];
  regardingInstruction?: Maybe<InstructionItem>;
  status: VariationStatus;
  timeGranted?: Maybe<Scalars["Int"]>;
  timeSought?: Maybe<Scalars["Int"]>;
  type: VariationType;
};

export type VariationItemLastAction = {
  __typename?: "VariationItemLastAction";
  actionType: VariationActionType;
  date: Scalars["AWSDateTime"];
  daysLate?: Maybe<Scalars["Int"]>;
  party: ContractBindingType;
  partyId: Scalars["ID"];
};

export type VariationItemList = {
  __typename?: "VariationItemList";
  items: Array<VariationItem>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type VariationItemNextStep = {
  __typename?: "VariationItemNextStep";
  actionType: VariationActionType;
  dueDate: Scalars["AWSDate"];
  party: ContractBindingType;
  partyId: Scalars["ID"];
};

export type VariationNoAgreementNoticePrompt = {
  __typename?: "VariationNoAgreementNoticePrompt";
  remarks: Scalars["String"];
};

export type VariationNoAgreementNoticePromptInput = {
  variationId: Scalars["ID"];
};

export type VariationNoAgreementNoticeRecordedDetails = EventDetails & {
  __typename?: "VariationNoAgreementNoticeRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationNoAgreementNoticeSentDetails = EventDetails & {
  __typename?: "VariationNoAgreementNoticeSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationObjectionProposedVariationPrompt = {
  __typename?: "VariationObjectionProposedVariationPrompt";
  defaultText: Scalars["String"];
};

export type VariationObjectionProposedVariationPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationObjectionProposedVariationRecordedDetails =
  EventDetails & {
    __typename?: "VariationObjectionProposedVariationRecordedDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    title: Scalars["String"];
  };

export type VariationObjectionProposedVariationSentDetails = EventDetails & {
  __typename?: "VariationObjectionProposedVariationSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationObjectionVariationPrompt = {
  __typename?: "VariationObjectionVariationPrompt";
  defaultText: Scalars["String"];
};

export type VariationObjectionVariationPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationObjectionVariationRecordedDetails = EventDetails & {
  __typename?: "VariationObjectionVariationRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationObjectionVariationSentDetails = EventDetails & {
  __typename?: "VariationObjectionVariationSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationProposal = {
  __typename?: "VariationProposal";
  attachments: Array<Attachment>;
  consented: Scalars["Boolean"];
  creatorId: Scalars["ID"];
  currency: Scalars["String"];
  dateCreated: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  reference: Scalars["String"];
  sectionalChanges: Array<VariationProposalTimeChange>;
  time: Scalars["Int"];
  variationId: Scalars["ID"];
};

export type VariationProposalAbandonmentPrompt = {
  __typename?: "VariationProposalAbandonmentPrompt";
  remarks: Scalars["String"];
};

export type VariationProposalAbandonmentPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationProposalAbandonmentRecordedDetails = EventDetails & {
  __typename?: "VariationProposalAbandonmentRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationProposalAbandonmentSentDetails = EventDetails & {
  __typename?: "VariationProposalAbandonmentSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationProposalConsentForProposedVariationPrompt = {
  __typename?: "VariationProposalConsentForProposedVariationPrompt";
  proposals: Array<VariationProposal>;
};

export type VariationProposalConsentForProposedVariationPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationProposalConsentForProposedVariationRecordedDetails =
  EventDetails & {
    __typename?: "VariationProposalConsentForProposedVariationRecordedDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    title: Scalars["String"];
  };

export type VariationProposalConsentForProposedVariationSentDetails =
  EventDetails & {
    __typename?: "VariationProposalConsentForProposedVariationSentDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    title: Scalars["String"];
  };

export type VariationProposalExplorerItem =
  | VariationAgreement
  | VariationDetermination
  | VariationProposal;

export type VariationProposalExplorerItemList = {
  __typename?: "VariationProposalExplorerItemList";
  items: Array<VariationProposalExplorerItem>;
};

export type VariationProposalForProposedVariationPrompt = {
  __typename?: "VariationProposalForProposedVariationPrompt";
  draftProposals: Array<DraftVariationProposal>;
};

export type VariationProposalForProposedVariationPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationProposalForProposedVariationRecordedDetails =
  EventDetails & {
    __typename?: "VariationProposalForProposedVariationRecordedDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    title: Scalars["String"];
  };

export type VariationProposalForProposedVariationSentDetails = EventDetails & {
  __typename?: "VariationProposalForProposedVariationSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationProposalForVariationPrompt = {
  __typename?: "VariationProposalForVariationPrompt";
  draftProposals: Array<DraftVariationProposal>;
};

export type VariationProposalForVariationPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationProposalForVariationRecordedDetails = EventDetails & {
  __typename?: "VariationProposalForVariationRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationProposalForVariationSentDetails = EventDetails & {
  __typename?: "VariationProposalForVariationSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationProposalTimeChange = {
  __typename?: "VariationProposalTimeChange";
  days: Scalars["Int"];
  description: Scalars["String"];
  number?: Maybe<Scalars["Int"]>;
};

export type VariationRecipient = {
  __typename?: "VariationRecipient";
  name: Scalars["String"];
  preference: UserPreferencesValue;
  userId: Scalars["ID"];
};

export type VariationRevisedProposalForProposedVariationRequestPrompt = {
  __typename?: "VariationRevisedProposalForProposedVariationRequestPrompt";
  remarks: Scalars["String"];
};

export type VariationRevisedProposalForProposedVariationRequestPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationRevisedProposalForProposedVariationRequestRecordedDetails =
  EventDetails & {
    __typename?: "VariationRevisedProposalForProposedVariationRequestRecordedDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    title: Scalars["String"];
  };

export type VariationRevisedProposalForProposedVariationRequestSentDetails =
  EventDetails & {
    __typename?: "VariationRevisedProposalForProposedVariationRequestSentDetails";
    actionNumber: Scalars["String"];
    attachments: Array<ItemAttachmentDataDetails>;
    regardingId: Scalars["ID"];
    regardingProductInstanceId: Scalars["ID"];
    title: Scalars["String"];
  };

export type VariationRevocationPrompt = {
  __typename?: "VariationRevocationPrompt";
  remarks: Scalars["String"];
};

export type VariationRevocationPromptInput = {
  variationId: Scalars["ID"];
};

export type VariationRevocationRecordedDetails = EventDetails & {
  __typename?: "VariationRevocationRecordedDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export type VariationRevocationSentDetails = EventDetails & {
  __typename?: "VariationRevocationSentDetails";
  actionNumber: Scalars["String"];
  attachments: Array<ItemAttachmentDataDetails>;
  regardingId: Scalars["ID"];
  regardingProductInstanceId: Scalars["ID"];
  title: Scalars["String"];
};

export enum VariationStatus {
  Abandoned = "Abandoned",
  Accepted = "Accepted",
  Agreed = "Agreed",
  Determined = "Determined",
  Instructed = "Instructed",
  None = "None",
  Priced = "Priced",
  Proposed = "Proposed",
  Rejected = "Rejected",
  Revoked = "Revoked",
}

export enum VariationType {
  ProvideProposal = "ProvideProposal",
  PutIntoEffect = "PutIntoEffect",
}

export type Variations = Product & {
  __typename?: "Variations";
  allowMultipleInstances: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  numberingFormat: Scalars["String"];
  outputActions: ProductOutputActionList;
  productSchemas: ProductSchemaList;
  roles: ProductRoleList;
  soloModeSupported: Scalars["Boolean"];
  status: ProductStatus;
  statusCollections: ItemStatusCollectionList;
};

export type WorkflowActionPromptList = {
  __typename?: "WorkflowActionPromptList";
  /** @deprecated Field no longer supported */
  contractBindingTypes: ContractBindingTypeList;
  outputActions: ProductOutputActionOriginatingPartyList;
  /** @deprecated Field no longer supported */
  productOutputActions: ProductOutputActionList;
  workflows: AuthorizationWorkflowList;
};

export type WorkflowActionsPromptInput = {
  productInstanceId: Scalars["ID"];
  projectId?: InputMaybe<Scalars["ID"]>;
};

export type NotificationsQueryVariables = Exact<{
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type NotificationsQuery = {
  __typename?: "Query";
  notifications: {
    __typename?: "NotificationList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "Notification";
      id: string;
      itemId: string;
      productInstanceId: string;
      productType: ProductType;
      versionId?: string | null;
      number: string;
      status: NotificationStatus;
      creatorId: string;
      dateCreated: any;
      recipientId: string;
      dateModified: any;
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
      };
      contract: {
        __typename?: "Contract";
        id: string;
        friendlyName: string;
        timeZone: string;
        name: string;
        contractType: {
          __typename?: "ContractType";
          id: string;
          description: string;
          subType?: string | null;
          version?: string | null;
        };
      };
      details:
        | {
            __typename: "ActionAuthorizedDetails";
            actionName: string;
            authorizationAuditId?: string | null;
            title: string;
            productDetails?: {
              __typename?: "CompEventAuthorizationNotificationData";
              regardingType: CompEventRegardingType;
              regardingItemId: string;
            } | null;
          }
        | {
            __typename: "ActionPreventedDetails";
            actionName: string;
            authorizationAuditId?: string | null;
            title: string;
            productDetails?: {
              __typename?: "CompEventAuthorizationNotificationData";
              regardingType: CompEventRegardingType;
              regardingItemId: string;
            } | null;
          }
        | {
            __typename: "AttachmentAddedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "AttachmentDescriptionAddedDetails";
            title: string;
            attachmentChanges: Array<{
              __typename?: "ItemAttachmentChangeDetails";
              fileName: string;
              oldDescription: string;
              newDescription: string;
            }>;
          }
        | {
            __typename: "AttachmentDescriptionEditedDetails";
            title: string;
            attachmentChanges: Array<{
              __typename?: "ItemAttachmentChangeDetails";
              fileName: string;
              oldDescription: string;
              newDescription: string;
            }>;
          }
        | {
            __typename: "AttachmentRemovedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "AuthorizationRequiredDetails";
            actionName: string;
            initiatorName: string;
            authorizationAuditId?: string | null;
            title: string;
            productDetails?: {
              __typename?: "CompEventAuthorizationNotificationData";
              regardingType: CompEventRegardingType;
              regardingItemId: string;
            } | null;
          }
        | { __typename: "ClaimAgreementNoticeRecordedDetails"; title: string }
        | { __typename: "ClaimAgreementNoticeSentDetails"; title: string }
        | { __typename: "ClaimDetailsResponseRecordedDetails"; title: string }
        | { __typename: "ClaimDetailsResponseSentDetails"; title: string }
        | {
            __typename: "ClaimDeterminationNoticeRecordedDetails";
            title: string;
          }
        | { __typename: "ClaimDeterminationNoticeSentDetails"; title: string }
        | {
            __typename: "ClaimErrorAgreementDisagreementRecordedDetails";
            title: string;
          }
        | {
            __typename: "ClaimErrorAgreementDisagreementSentDetails";
            title: string;
          }
        | {
            __typename: "ClaimErrorAgreementNoticeRecordedDetails";
            title: string;
          }
        | { __typename: "ClaimErrorAgreementNoticeSentDetails"; title: string }
        | {
            __typename: "ClaimErrorDeterminationDisagreementRecordedDetails";
            title: string;
          }
        | {
            __typename: "ClaimErrorDeterminationDisagreementSentDetails";
            title: string;
          }
        | {
            __typename: "ClaimErrorDeterminationNoticeRecordedDetails";
            title: string;
          }
        | {
            __typename: "ClaimErrorDeterminationNoticeSentDetails";
            title: string;
          }
        | { __typename: "ClaimFinalClaimDetailsRecordedDetails"; title: string }
        | { __typename: "ClaimFinalClaimDetailsSentDetails"; title: string }
        | {
            __typename: "ClaimFurtherParticularsRequestRecordedDetails";
            title: string;
          }
        | {
            __typename: "ClaimFurtherParticularsRequestSentDetails";
            title: string;
          }
        | {
            __typename: "ClaimFurtherParticularsSubmissionRecordedDetails";
            title: string;
          }
        | {
            __typename: "ClaimFurtherParticularsSubmissionSentDetails";
            title: string;
          }
        | {
            __typename: "ClaimInterimClaimDetailsRecordedDetails";
            title: string;
          }
        | { __typename: "ClaimInterimClaimDetailsSentDetails"; title: string }
        | { __typename: "ClaimNoAgreementNoticeRecordedDetails"; title: string }
        | { __typename: "ClaimNoAgreementNoticeSentDetails"; title: string }
        | {
            __typename: "ClaimNoticeInvalidNoticeRecordedDetails";
            title: string;
          }
        | { __typename: "ClaimNoticeInvalidNoticeSentDetails"; title: string }
        | {
            __typename: "ClaimNoticeLapsedNoticeRecordedDetails";
            title: string;
          }
        | { __typename: "ClaimNoticeLapsedNoticeSentDetails"; title: string }
        | { __typename: "ClaimNoticeRecordedDetails"; title: string }
        | { __typename: "ClaimNoticeSentDetails"; title: string }
        | {
            __typename: "CommentRepliedDetails";
            content: string;
            title: string;
          }
        | {
            __typename: "CompEventAbandonInstructionRecordedDetails";
            title: string;
          }
        | {
            __typename: "CompEventAbandonInstructionSentDetails";
            title: string;
          }
        | {
            __typename: "CompEventConfirmationRecordedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventConfirmationSentDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | { __typename: "CompEventDeemingConfirmedDetails"; title: string }
        | {
            __typename: "CompEventDeemingQuotationAcceptedDetails";
            title: string;
          }
        | {
            __typename: "CompEventDenyingOfMoreTimeRecordedDetails";
            title: string;
          }
        | { __typename: "CompEventDenyingOfMoreTimeSentDetails"; title: string }
        | {
            __typename: "CompEventGrantingOfMoreTimeRecordedDetails";
            title: string;
          }
        | {
            __typename: "CompEventGrantingOfMoreTimeSentDetails";
            title: string;
          }
        | {
            __typename: "CompEventNoticeOfSilenceCompEventNoticeRecordedDetails";
            title: string;
          }
        | {
            __typename: "CompEventNoticeOfSilenceCompEventNoticeSentDetails";
            title: string;
          }
        | {
            __typename: "CompEventNoticeOfSilenceOwnAssessmentRecordedDetails";
            title: string;
          }
        | {
            __typename: "CompEventNoticeOfSilenceOwnAssessmentSentDetails";
            title: string;
          }
        | {
            __typename: "CompEventNoticeOfSilenceQuotationRecordedDetails";
            title: string;
          }
        | {
            __typename: "CompEventNoticeOfSilenceQuotationSentDetails";
            title: string;
          }
        | {
            __typename: "CompEventNoticeProposedInstructionRecordedDetails";
            title: string;
          }
        | {
            __typename: "CompEventNoticeProposedInstructionSentDetails";
            title: string;
          }
        | {
            __typename: "CompEventNoticeRecordedDetails";
            notifiedByParty: string;
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventNoticeRequestQuotationRecordedDetails";
            notifiedByParty: string;
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventNoticeRequestQuotationSentDetails";
            notifiedByParty: string;
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventNoticeSentDetails";
            notifiedByParty: string;
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventOwnAssessmentNoticeRecordedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventOwnAssessmentNoticeSentDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventOwnAssessmentRecordedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventOwnAssessmentSentDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventQuotationAcceptanceRecordedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventQuotationAcceptanceSentDetails";
            regardingType: CompEventRegardingType;
            regardingProductInstanceId: string;
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventQuotationRecordedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventQuotationRevisionRequestRecordedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventQuotationRevisionRequestSentDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventQuotationSentDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventRejectionRecordedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventRejectionSentDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "CompEventRequestForMoreTimeRecordedDetails";
            title: string;
          }
        | {
            __typename: "CompEventRequestForMoreTimeSentDetails";
            title: string;
          }
        | {
            __typename: "EventAddedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "EventEditedDetails";
            title: string;
            fieldChanges: Array<{
              __typename?: "ItemFieldChangeDetails";
              fieldName: string;
              oldValue: string;
              newValue: string;
            }>;
          }
        | {
            __typename: "EventOwnerChangedDetails";
            oldOwner: string;
            newOwner: string;
            title: string;
          }
        | {
            __typename: "EventStatusChangedDetails";
            oldStatus: string;
            newStatus: string;
            reason: string;
            details?: string | null;
            title: string;
          }
        | {
            __typename: "EwEditedDetails";
            title: string;
            fieldChanges: Array<{
              __typename?: "ItemFieldChangeDetails";
              fieldName: string;
              oldValue: string;
              newValue: string;
            }>;
          }
        | {
            __typename: "EwOwnerChangedDetails";
            oldOwner: string;
            newOwner: string;
            title: string;
          }
        | {
            __typename: "EwRecordedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "EwSentDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "EwStatusChangedDetails";
            oldStatus: string;
            newStatus: string;
            reason: string;
            details?: string | null;
            title: string;
          }
        | {
            __typename: "InstructionEditedDetails";
            title: string;
            fieldChanges: Array<{
              __typename?: "ItemFieldChangeDetails";
              fieldName: string;
              oldValue: string;
              newValue: string;
            }>;
          }
        | {
            __typename: "InstructionOwnerChangedDetails";
            oldOwner: string;
            newOwner: string;
            title: string;
          }
        | {
            __typename: "InstructionRecordedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "InstructionSentDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "InstructionStatusChangedDetails";
            oldStatus: string;
            newStatus: string;
            reason: string;
            details?: string | null;
            title: string;
          }
        | {
            __typename: "NewCommentAddedDetails";
            content: string;
            title: string;
          }
        | {
            __typename: "RiskAddedDetails";
            title: string;
            attachments: Array<{
              __typename?: "ItemAttachmentDataDetails";
              fileName: string;
              description: string;
            }>;
          }
        | {
            __typename: "RiskEditedDetails";
            title: string;
            fieldChanges: Array<{
              __typename?: "ItemFieldChangeDetails";
              fieldName: string;
              oldValue: string;
              newValue: string;
            }>;
          }
        | {
            __typename: "RiskOwnerChangedDetails";
            oldOwner: string;
            newOwner: string;
            title: string;
          }
        | {
            __typename: "RiskStatusChangedDetails";
            oldStatus: string;
            newStatus: string;
            reason: string;
            details?: string | null;
            title: string;
          }
        | { __typename: "UserMentionedDetails"; content: string; title: string }
        | {
            __typename: "VariationAgreementNoticeRecordedDetails";
            title: string;
          }
        | { __typename: "VariationAgreementNoticeSentDetails"; title: string }
        | { __typename: "VariationConfirmationRecordedDetails"; title: string }
        | { __typename: "VariationConfirmationSentDetails"; title: string }
        | {
            __typename: "VariationDeterminationNoticeRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationDeterminationNoticeSentDetails";
            title: string;
          }
        | {
            __typename: "VariationErrorAgreementDisagreementRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationErrorAgreementDisagreementSentDetails";
            title: string;
          }
        | {
            __typename: "VariationErrorAgreementNoticeRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationErrorAgreementNoticeSentDetails";
            title: string;
          }
        | {
            __typename: "VariationErrorDeterminationDisagreementRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationErrorDeterminationDisagreementSentDetails";
            title: string;
          }
        | {
            __typename: "VariationErrorDeterminationNoticeRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationErrorDeterminationNoticeSentDetails";
            title: string;
          }
        | {
            __typename: "VariationFurtherParticularsRequestRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationFurtherParticularsRequestSentDetails";
            title: string;
          }
        | {
            __typename: "VariationFurtherParticularsSubmissionRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationFurtherParticularsSubmissionSentDetails";
            title: string;
          }
        | {
            __typename: "VariationInstructionCommenceWorkRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationInstructionCommenceWorkSentDetails";
            title: string;
          }
        | {
            __typename: "VariationNoAgreementNoticeRecordedDetails";
            title: string;
          }
        | { __typename: "VariationNoAgreementNoticeSentDetails"; title: string }
        | {
            __typename: "VariationObjectionProposedVariationRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationObjectionProposedVariationSentDetails";
            title: string;
          }
        | {
            __typename: "VariationObjectionVariationRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationObjectionVariationSentDetails";
            title: string;
          }
        | {
            __typename: "VariationProposalAbandonmentRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationProposalAbandonmentSentDetails";
            title: string;
          }
        | {
            __typename: "VariationProposalConsentForProposedVariationRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationProposalConsentForProposedVariationSentDetails";
            title: string;
          }
        | {
            __typename: "VariationProposalForProposedVariationRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationProposalForProposedVariationSentDetails";
            title: string;
          }
        | {
            __typename: "VariationProposalForVariationRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationProposalForVariationSentDetails";
            title: string;
          }
        | {
            __typename: "VariationRevisedProposalForProposedVariationRequestRecordedDetails";
            title: string;
          }
        | {
            __typename: "VariationRevisedProposalForProposedVariationRequestSentDetails";
            title: string;
          }
        | { __typename: "VariationRevocationRecordedDetails"; title: string }
        | { __typename: "VariationRevocationSentDetails"; title: string };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        profilePicture?: string | null;
      };
      recipient: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
      };
    }>;
  };
};

export type NewNotificationsQueryVariables = Exact<{
  lastChecked: Scalars["AWSDateTime"];
}>;

export type NewNotificationsQuery = {
  __typename?: "Query";
  newNotifications: number;
};

export type UnreadNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type UnreadNotificationsQuery = {
  __typename?: "Query";
  unreadNotifications: number;
};

export type MarkNotificationMutationVariables = Exact<{
  id: Scalars["ID"];
  status: NotificationStatus;
}>;

export type MarkNotificationMutation = {
  __typename?: "Mutation";
  markNotification: {
    __typename?: "Notification";
    id: string;
    status: NotificationStatus;
  };
};

export type MarkAllNotificationsAsReadMutationVariables = Exact<{
  [key: string]: never;
}>;

export type MarkAllNotificationsAsReadMutation = {
  __typename?: "Mutation";
  markAllNotificationsAsRead: boolean;
};

export type CompEventItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CompEventItemQuery = {
  __typename?: "Query";
  compEventItem?: {
    __typename: "CompEventItem";
    id: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
  } | null;
};

export type ClaimItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ClaimItemQuery = {
  __typename?: "Query";
  claimItem?: {
    __typename: "ClaimItem";
    id: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: ClaimRegardingType;
    status: ClaimStatus;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
  } | null;
};

export type VariationItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type VariationItemQuery = {
  __typename?: "Query";
  variationItem?: {
    __typename: "VariationItem";
    id: string;
    productInstanceId: string;
    regardingId: string;
    status: VariationStatus;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
  } | null;
};

export type ChangeCompanyStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: CompanyStatus;
}>;

export type ChangeCompanyStatusMutation = {
  __typename?: "Mutation";
  changeCompanyStatus: {
    __typename?: "Company";
    id: string;
    type?: CompanyType | null;
    registeredName: string;
    tradingName: string;
    registrationNumber?: string | null;
    logo?: string | null;
    vatRegistrationNumber?: string | null;
    status: CompanyStatus;
    creatorId: string;
    dateCreated: any;
    physicalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    postalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type GetCompanyDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetCompanyDetailsQuery = {
  __typename?: "Query";
  company?: {
    __typename?: "Company";
    id: string;
    type?: CompanyType | null;
    registeredName: string;
    tradingName: string;
    registrationNumber?: string | null;
    logo?: string | null;
    vatRegistrationNumber?: string | null;
    status: CompanyStatus;
    creatorId: string;
    dateCreated: any;
    contracts: {
      __typename?: "ContractList";
      items: Array<{
        __typename?: "Contract";
        id: string;
        friendlyName: string;
        number: string;
        country: string;
        province?: string | null;
        timeZone: string;
        status: ContractStatus;
        contractTypeId: string;
        dateCreated: any;
        project: { __typename?: "Project"; id: string; friendlyName: string };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
    users: {
      __typename?: "UserList";
      items: Array<{
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        companyId: string;
        registered?: boolean | null;
        status: UserStatus;
        lastActive?: any | null;
        dateInvited: any;
        invitedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
    physicalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    postalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  } | null;
};

export type ChangeUserStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: UserStatus;
}>;

export type ChangeUserStatusMutation = {
  __typename?: "Mutation";
  changeUserStatus: { __typename?: "User"; id: string; status: UserStatus };
};

export type EditCompanyMutationVariables = Exact<{
  input: EditCompanyInput;
}>;

export type EditCompanyMutation = {
  __typename?: "Mutation";
  editCompany: {
    __typename?: "Company";
    id: string;
    type?: CompanyType | null;
    registeredName: string;
    tradingName: string;
    registrationNumber?: string | null;
    logo?: string | null;
    vatRegistrationNumber?: string | null;
    status: CompanyStatus;
    creatorId: string;
    dateCreated: any;
    physicalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    postalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type ResendInviteMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type ResendInviteMutation = {
  __typename?: "Mutation";
  resendInvite: { __typename?: "InviteUserResponse"; success: boolean };
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: "Mutation";
  inviteUser: { __typename?: "InviteUserResponse"; success: boolean };
};

export type CompanyProductInstancesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CompanyProductInstancesQuery = {
  __typename?: "Query";
  company?: {
    __typename?: "Company";
    id: string;
    registeredName: string;
    tradingName: string;
    contractBindings: {
      __typename?: "ContractBindingList";
      items: Array<{
        __typename?: "ContractBinding";
        id: string;
        contractBindingTypeId: string;
        contractId: string;
        companyId: string;
        defaultCompany: boolean;
        representativeId?: string | null;
        isCompanyBilled: boolean;
        status: ContractBindingStatus;
        dateCreated: any;
        creatorId: string;
      }>;
    };
    projects: {
      __typename?: "ProjectList";
      items: Array<{
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        status: ProjectStatus;
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
            productInstances: {
              __typename?: "ProductInstanceList";
              items: Array<{
                __typename?: "ProductInstance";
                id: string;
                description: string;
                status: ProductInstanceStatus;
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      name: string;
                      status: ProductStatus;
                      roles: {
                        __typename?: "ProductRoleList";
                        items: Array<{
                          __typename?: "ProductRole";
                          id: string;
                          name: string;
                          status: ProductRoleStatus;
                        }>;
                      };
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      name: string;
                      status: ProductStatus;
                      roles: {
                        __typename?: "ProductRoleList";
                        items: Array<{
                          __typename?: "ProductRole";
                          id: string;
                          name: string;
                          status: ProductRoleStatus;
                        }>;
                      };
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      name: string;
                      status: ProductStatus;
                      roles: {
                        __typename?: "ProductRoleList";
                        items: Array<{
                          __typename?: "ProductRole";
                          id: string;
                          name: string;
                          status: ProductRoleStatus;
                        }>;
                      };
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      name: string;
                      status: ProductStatus;
                      roles: {
                        __typename?: "ProductRoleList";
                        items: Array<{
                          __typename?: "ProductRole";
                          id: string;
                          name: string;
                          status: ProductRoleStatus;
                        }>;
                      };
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      name: string;
                      status: ProductStatus;
                      roles: {
                        __typename?: "ProductRoleList";
                        items: Array<{
                          __typename?: "ProductRole";
                          id: string;
                          name: string;
                          status: ProductRoleStatus;
                        }>;
                      };
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      name: string;
                      status: ProductStatus;
                      roles: {
                        __typename?: "ProductRoleList";
                        items: Array<{
                          __typename?: "ProductRole";
                          id: string;
                          name: string;
                          status: ProductRoleStatus;
                        }>;
                      };
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      name: string;
                      status: ProductStatus;
                      roles: {
                        __typename?: "ProductRoleList";
                        items: Array<{
                          __typename?: "ProductRole";
                          id: string;
                          name: string;
                          status: ProductRoleStatus;
                        }>;
                      };
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      name: string;
                      status: ProductStatus;
                      roles: {
                        __typename?: "ProductRoleList";
                        items: Array<{
                          __typename?: "ProductRole";
                          id: string;
                          name: string;
                          status: ProductRoleStatus;
                        }>;
                      };
                    };
              }>;
            };
          }>;
        };
      }>;
    };
  } | null;
};

export type AddCompanyMutationVariables = Exact<{
  input: AddCompanyInput;
}>;

export type AddCompanyMutation = {
  __typename?: "Mutation";
  addCompany: {
    __typename?: "Company";
    id: string;
    type?: CompanyType | null;
    registeredName: string;
    tradingName: string;
    registrationNumber?: string | null;
    logo?: string | null;
    vatRegistrationNumber?: string | null;
    status: CompanyStatus;
    creatorId: string;
    dateCreated: any;
    physicalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    postalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type ChangeUserCompanyMutationVariables = Exact<{
  id: Scalars["ID"];
  companyId: Scalars["ID"];
}>;

export type ChangeUserCompanyMutation = {
  __typename?: "Mutation";
  changeUserCompany: { __typename?: "User"; id: string };
};

export type GetUserByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
}>;

export type GetUserByIdQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    jobTitle: string;
    country: string;
    mobileNumber?: any | null;
    alternateNumber?: any | null;
    dateOfBirth?: any | null;
    profilePicture?: string | null;
    email: any;
    companyId: string;
    registered?: boolean | null;
    status: UserStatus;
    lastActive?: any | null;
    dateInvited: any;
    registeredDate?: any | null;
    invitedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  } | null;
};

export type GetUserRolesByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetUserRolesByIdQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    companyId: string;
    company: {
      __typename?: "Company";
      id: string;
      type?: CompanyType | null;
      registeredName: string;
      tradingName: string;
    };
    roles: {
      __typename?: "ProductUserRoleList";
      items: Array<{
        __typename?: "ProductUserRole";
        id: string;
        userId: string;
        productInstanceId: string;
        productRoleId: string;
        status: ProductUserRoleStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              description?: string | null;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                roles: {
                  __typename?: "ProductRoleList";
                  items: Array<{
                    __typename?: "ProductRole";
                    id: string;
                    name: string;
                    productId: string;
                  }>;
                };
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                roles: {
                  __typename?: "ProductRoleList";
                  items: Array<{
                    __typename?: "ProductRole";
                    id: string;
                    name: string;
                    productId: string;
                  }>;
                };
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                roles: {
                  __typename?: "ProductRoleList";
                  items: Array<{
                    __typename?: "ProductRole";
                    id: string;
                    name: string;
                    productId: string;
                  }>;
                };
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                roles: {
                  __typename?: "ProductRoleList";
                  items: Array<{
                    __typename?: "ProductRole";
                    id: string;
                    name: string;
                    productId: string;
                  }>;
                };
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                roles: {
                  __typename?: "ProductRoleList";
                  items: Array<{
                    __typename?: "ProductRole";
                    id: string;
                    name: string;
                    productId: string;
                  }>;
                };
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                roles: {
                  __typename?: "ProductRoleList";
                  items: Array<{
                    __typename?: "ProductRole";
                    id: string;
                    name: string;
                    productId: string;
                  }>;
                };
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                roles: {
                  __typename?: "ProductRoleList";
                  items: Array<{
                    __typename?: "ProductRole";
                    id: string;
                    name: string;
                    productId: string;
                  }>;
                };
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                roles: {
                  __typename?: "ProductRoleList";
                  items: Array<{
                    __typename?: "ProductRole";
                    id: string;
                    name: string;
                    productId: string;
                  }>;
                };
              };
        };
        productRole: {
          __typename?: "ProductRole";
          id: string;
          name: string;
          productId: string;
        };
      }>;
    };
  } | null;
};

export type GetUserProductInstancesByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetUserProductInstancesByIdQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    companyId: string;
    company: {
      __typename?: "Company";
      id: string;
      type?: CompanyType | null;
      registeredName: string;
      tradingName: string;
      projects: {
        __typename?: "ProjectList";
        items: Array<{
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              activeBindings: {
                __typename?: "ContractBindingList";
                items: Array<{
                  __typename?: "ContractBinding";
                  id: string;
                  companyId: string;
                }>;
              };
              productInstances: {
                __typename?: "ProductInstanceList";
                items: Array<{
                  __typename?: "ProductInstance";
                  id: string;
                  description: string;
                  product:
                    | {
                        __typename?: "Claims";
                        id: string;
                        name: string;
                        roles: {
                          __typename?: "ProductRoleList";
                          items: Array<{
                            __typename?: "ProductRole";
                            id: string;
                            name: string;
                          }>;
                        };
                      }
                    | {
                        __typename?: "CompEvents";
                        id: string;
                        name: string;
                        roles: {
                          __typename?: "ProductRoleList";
                          items: Array<{
                            __typename?: "ProductRole";
                            id: string;
                            name: string;
                          }>;
                        };
                      }
                    | {
                        __typename?: "DailyDiary";
                        id: string;
                        name: string;
                        roles: {
                          __typename?: "ProductRoleList";
                          items: Array<{
                            __typename?: "ProductRole";
                            id: string;
                            name: string;
                          }>;
                        };
                      }
                    | {
                        __typename?: "EarlyWarnings";
                        id: string;
                        name: string;
                        roles: {
                          __typename?: "ProductRoleList";
                          items: Array<{
                            __typename?: "ProductRole";
                            id: string;
                            name: string;
                          }>;
                        };
                      }
                    | {
                        __typename?: "Events";
                        id: string;
                        name: string;
                        roles: {
                          __typename?: "ProductRoleList";
                          items: Array<{
                            __typename?: "ProductRole";
                            id: string;
                            name: string;
                          }>;
                        };
                      }
                    | {
                        __typename?: "Instructions";
                        id: string;
                        name: string;
                        roles: {
                          __typename?: "ProductRoleList";
                          items: Array<{
                            __typename?: "ProductRole";
                            id: string;
                            name: string;
                          }>;
                        };
                      }
                    | {
                        __typename?: "RisksRegister";
                        id: string;
                        name: string;
                        roles: {
                          __typename?: "ProductRoleList";
                          items: Array<{
                            __typename?: "ProductRole";
                            id: string;
                            name: string;
                          }>;
                        };
                      }
                    | {
                        __typename?: "Variations";
                        id: string;
                        name: string;
                        roles: {
                          __typename?: "ProductRoleList";
                          items: Array<{
                            __typename?: "ProductRole";
                            id: string;
                            name: string;
                          }>;
                        };
                      };
                }>;
              };
            }>;
          };
        }>;
      };
    };
  } | null;
};

export type ContractBindingQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ContractBindingQuery = {
  __typename?: "Query";
  contractBinding: {
    __typename?: "ContractBinding";
    id: string;
    contractBindingTypeId: string;
    status: ContractBindingStatus;
    defaultCompany: boolean;
    representativeId?: string | null;
    isCompanyBilled: boolean;
    dateCreated: any;
    companyId: string;
    contractId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    company: {
      __typename?: "Company";
      id: string;
      registeredName: string;
      tradingName: string;
    };
    contractBindingType: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
    };
    representative?: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    } | null;
  };
};

export type EditContractBindingMutationVariables = Exact<{
  input: EditContractBindingInput;
}>;

export type EditContractBindingMutation = {
  __typename?: "Mutation";
  editContractBinding: {
    __typename?: "ContractBinding";
    id: string;
    contractBindingTypeId: string;
    status: ContractBindingStatus;
    defaultCompany: boolean;
    representativeId?: string | null;
    isCompanyBilled: boolean;
    dateCreated: any;
    companyId: string;
    contractId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    company: {
      __typename?: "Company";
      id: string;
      registeredName: string;
      tradingName: string;
    };
    contractBindingType: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
    };
    representative?: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    } | null;
  };
};

export type ContractQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ContractQuery = {
  __typename?: "Query";
  contract: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
    number: string;
    valueCurrency: string;
    value: number;
    startDate?: any | null;
    endDate?: any | null;
    contractTypeId: string;
    projectId: string;
    country: string;
    province?: string | null;
    timeZone: string;
    coordinatesLatitude?: number | null;
    coordinatesLongitude?: number | null;
    status: ContractStatus;
    dateCreated: any;
    contractType: {
      __typename?: "ContractType";
      id: string;
      description: string;
      subType?: string | null;
      version?: string | null;
      status: ContractTypeStatus;
      contractBindingTypes: {
        __typename?: "ContractBindingTypeList";
        items: Array<{
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          status: ContractBindingTypeStatus;
        }>;
      };
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    sections: Array<{
      __typename?: "ContractSection";
      id: string;
      number?: number | null;
      description: string;
      completionDate: any;
      status: ContractSectionStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
    keyDates: Array<{
      __typename?: "ContractKeyDate";
      id: string;
      number?: number | null;
      conditionToBeMet: string;
      keyDate: any;
      status: ContractKeyDateStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
    contractBindings: {
      __typename?: "ContractBindingList";
      items: Array<{
        __typename?: "ContractBinding";
        id: string;
        status: ContractBindingStatus;
        defaultCompany: boolean;
        representativeId?: string | null;
        isCompanyBilled: boolean;
        dateCreated: any;
        creatorId: string;
        companyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
        contractBindingType: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
        };
        representative?: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        } | null;
      }>;
    };
    productInstances: {
      __typename?: "ProductInstanceList";
      items: Array<{
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        contractId: string;
        productId: string;
        productSchemaId: string;
        statusCollectionId: string;
        status: ProductInstanceStatus;
        dateCreated: any;
        creatorId: string;
        productSchema: {
          __typename?: "ProductSchema";
          id: string;
          name: string;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
      }>;
    };
    templateRoutesOverride: {
      __typename?: "DocumentTemplateRouteList";
      items: Array<{
        __typename?: "DocumentTemplateRoute";
        id: string;
        productOutputActionId: string;
        contractTypeId: string;
        originatingPartyId: string;
        contractId?: string | null;
        targetPartyId: string;
        defaultNumbering?: string | null;
        clause?: string | null;
        documentTemplateId: string;
        isInternal: boolean;
        dateCreated: any;
        creatorId: string;
        outputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                status: ProductStatus;
              };
        };
        contractType: {
          __typename?: "ContractType";
          id: string;
          description: string;
          subType?: string | null;
          version?: string | null;
          status: ContractTypeStatus;
        };
        originatingParty: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          status: ContractBindingTypeStatus;
        };
        targetParty: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          status: ContractBindingTypeStatus;
        };
        documentTemplate: {
          __typename?: "DocumentTemplate";
          id: string;
          name: string;
          description: string;
          content: string;
          status: DocumentTemplateStatus;
        };
      }>;
    };
  };
};

export type EditContractMutationVariables = Exact<{
  input: EditContractInput;
}>;

export type EditContractMutation = {
  __typename?: "Mutation";
  editContract: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
    number: string;
    valueCurrency: string;
    value: number;
    startDate?: any | null;
    endDate?: any | null;
    contractTypeId: string;
    projectId: string;
    country: string;
    province?: string | null;
    timeZone: string;
    coordinatesLatitude?: number | null;
    coordinatesLongitude?: number | null;
    status: ContractStatus;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    contractBindings: {
      __typename?: "ContractBindingList";
      items: Array<{
        __typename?: "ContractBinding";
        id: string;
        status: ContractBindingStatus;
        defaultCompany: boolean;
        representativeId?: string | null;
        isCompanyBilled: boolean;
        dateCreated: any;
        creatorId: string;
        companyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
    sections: Array<{
      __typename?: "ContractSection";
      id: string;
      number?: number | null;
      description: string;
      completionDate: any;
      status: ContractSectionStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
    keyDates: Array<{
      __typename?: "ContractKeyDate";
      id: string;
      number?: number | null;
      conditionToBeMet: string;
      keyDate: any;
      status: ContractKeyDateStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type ProductInstanceQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProductInstanceQuery = {
  __typename?: "Query";
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    soloModeSupported: boolean;
    contractId: string;
    productId: string;
    productSchemaId: string;
    statusCollectionId: string;
    status: ProductInstanceStatus;
    dateCreated: any;
    creatorId: string;
    productSchema: { __typename?: "ProductSchema"; id: string; name: string };
    statusCollection: {
      __typename?: "ItemStatusCollection";
      id: string;
      name: string;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
    };
    product:
      | {
          __typename: "Claims";
          id: string;
          name: string;
          roles: {
            __typename?: "ProductRoleList";
            items: Array<{
              __typename?: "ProductRole";
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: "CompEvents";
          id: string;
          name: string;
          roles: {
            __typename?: "ProductRoleList";
            items: Array<{
              __typename?: "ProductRole";
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: "DailyDiary";
          id: string;
          name: string;
          roles: {
            __typename?: "ProductRoleList";
            items: Array<{
              __typename?: "ProductRole";
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: "EarlyWarnings";
          id: string;
          name: string;
          roles: {
            __typename?: "ProductRoleList";
            items: Array<{
              __typename?: "ProductRole";
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: "Events";
          id: string;
          name: string;
          roles: {
            __typename?: "ProductRoleList";
            items: Array<{
              __typename?: "ProductRole";
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: "Instructions";
          id: string;
          name: string;
          roles: {
            __typename?: "ProductRoleList";
            items: Array<{
              __typename?: "ProductRole";
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: "RisksRegister";
          id: string;
          name: string;
          roles: {
            __typename?: "ProductRoleList";
            items: Array<{
              __typename?: "ProductRole";
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: "Variations";
          id: string;
          name: string;
          roles: {
            __typename?: "ProductRoleList";
            items: Array<{
              __typename?: "ProductRole";
              id: string;
              name: string;
            }>;
          };
        };
    roles: {
      __typename?: "ProductUserRoleList";
      items: Array<{
        __typename?: "ProductUserRole";
        id: string;
        userId: string;
        productInstanceId: string;
        productRoleId: string;
        status: ProductUserRoleStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        user: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          jobTitle: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
          };
        };
        productRole: {
          __typename?: "ProductRole";
          id: string;
          name: string;
          productId: string;
          actionIds: Array<string>;
          status: ProductRoleStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      }>;
    };
    extraConfig?:
      | { __typename: "CompEventExtraConfig" }
      | {
          __typename: "DailyDiaryExtraConfig";
          presetId: string;
          manPowerLookupId?: string | null;
          equipmentLookupId?: string | null;
          areaLookupId?: string | null;
          hseTypeLookupId?: string | null;
          companyLookupId?: string | null;
          teamLookupId?: string | null;
          shiftLookupId?: string | null;
          startDate: any;
        }
      | null;
  };
};

export type EditProductInstanceMutationVariables = Exact<{
  input: EditProductInstanceInput;
}>;

export type EditProductInstanceMutation = {
  __typename?: "Mutation";
  editProductInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
  };
};

export type AddProductUserRoleMutationVariables = Exact<{
  input: AddProductUserRoleInput;
}>;

export type AddProductUserRoleMutation = {
  __typename?: "Mutation";
  addProductUserRole: {
    __typename?: "ProductUserRole";
    id: string;
    userId: string;
    productInstanceId: string;
    productRoleId: string;
    status: ProductUserRoleStatus;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    user: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      jobTitle: string;
      country: string;
      mobileNumber?: any | null;
      alternateNumber?: any | null;
      dateOfBirth?: any | null;
      profilePicture?: string | null;
      email: any;
      companyId: string;
      registered?: boolean | null;
      status: UserStatus;
      lastActive?: any | null;
      dateInvited: any;
      registeredDate?: any | null;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
    productRole: {
      __typename?: "ProductRole";
      id: string;
      name: string;
      productId: string;
      actionIds: Array<string>;
      status: ProductRoleStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
      };
      actions: {
        __typename?: "ProductRoleActionList";
        items: Array<{
          __typename?: "ProductRoleAction";
          id: string;
          name: string;
          status: ProductRoleActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
          };
        }>;
      };
    };
  };
};

export type GetAvailableUsersForRolesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAvailableUsersForRolesQuery = {
  __typename?: "Query";
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    soloModeSupported: boolean;
    contractId: string;
    productId: string;
    productSchemaId: string;
    statusCollectionId: string;
    status: ProductInstanceStatus;
    dateCreated: any;
    creatorId: string;
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      projectId: string;
      status: ContractStatus;
      activeBindings: {
        __typename?: "ContractBindingList";
        items: Array<{
          __typename?: "ContractBinding";
          id: string;
          contractBindingTypeId: string;
          status: ContractBindingStatus;
          defaultCompany: boolean;
          representativeId?: string | null;
          isCompanyBilled: boolean;
          companyId: string;
          contractId: string;
          company: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            status: CompanyStatus;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                registered?: boolean | null;
                status: UserStatus;
                jobTitle: string;
              }>;
            };
          };
        }>;
      };
    };
  };
};

export type ChangeProductUserRoleStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ProductUserRoleStatus;
}>;

export type ChangeProductUserRoleStatusMutation = {
  __typename?: "Mutation";
  changeProductUserRoleStatus: {
    __typename?: "ProductUserRole";
    userId: string;
    productInstanceId: string;
    productRoleId: string;
  };
};

export type EditProductUserRoleMutationVariables = Exact<{
  input: EditProductUserRoleInput;
}>;

export type EditProductUserRoleMutation = {
  __typename?: "Mutation";
  editProductUserRole: {
    __typename?: "ProductUserRole";
    userId: string;
    productInstanceId: string;
    productRoleId: string;
  };
};

export type ChangeProductInstanceStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ProductInstanceStatus;
}>;

export type ChangeProductInstanceStatusMutation = {
  __typename?: "Mutation";
  changeProductInstanceStatus: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    status: ProductInstanceStatus;
  };
};

export type UsersByContractQueryVariables = Exact<{
  contractId: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type UsersByContractQuery = {
  __typename?: "Query";
  usersByContract: {
    __typename?: "UserList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      companyId: string;
      registered?: boolean | null;
      status: UserStatus;
      company: { __typename?: "Company"; id: string; tradingName: string };
      roles: {
        __typename?: "ProductUserRoleList";
        items: Array<{
          __typename?: "ProductUserRole";
          id: string;
          userId: string;
          productInstanceId: string;
          productRoleId: string;
          status: ProductUserRoleStatus;
          dateCreated: any;
          creatorId: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            product:
              | {
                  __typename: "Claims";
                  id: string;
                  name: string;
                  status: ProductStatus;
                }
              | {
                  __typename: "CompEvents";
                  id: string;
                  name: string;
                  status: ProductStatus;
                }
              | {
                  __typename: "DailyDiary";
                  id: string;
                  name: string;
                  status: ProductStatus;
                }
              | {
                  __typename: "EarlyWarnings";
                  id: string;
                  name: string;
                  status: ProductStatus;
                }
              | {
                  __typename: "Events";
                  id: string;
                  name: string;
                  status: ProductStatus;
                }
              | {
                  __typename: "Instructions";
                  id: string;
                  name: string;
                  status: ProductStatus;
                }
              | {
                  __typename: "RisksRegister";
                  id: string;
                  name: string;
                  status: ProductStatus;
                }
              | {
                  __typename: "Variations";
                  id: string;
                  name: string;
                  status: ProductStatus;
                };
          };
          productRole: {
            __typename?: "ProductRole";
            id: string;
            name: string;
            productId: string;
            isInternal: boolean;
            status: ProductRoleStatus;
            dateCreated: any;
            creatorId: string;
          };
        }>;
      };
    }>;
  };
};

export type ContractProductInstancesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ContractProductInstancesQuery = {
  __typename?: "Query";
  contract: {
    __typename?: "Contract";
    id: string;
    productInstances: {
      __typename?: "ProductInstanceList";
      items: Array<{
        __typename: "ProductInstance";
        id: string;
        description: string;
        status: ProductInstanceStatus;
        product:
          | {
              __typename?: "Claims";
              id: string;
              roles: {
                __typename?: "ProductRoleList";
                items: Array<{
                  __typename?: "ProductRole";
                  id: string;
                  name: string;
                  status: ProductRoleStatus;
                }>;
              };
            }
          | {
              __typename?: "CompEvents";
              id: string;
              roles: {
                __typename?: "ProductRoleList";
                items: Array<{
                  __typename?: "ProductRole";
                  id: string;
                  name: string;
                  status: ProductRoleStatus;
                }>;
              };
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              roles: {
                __typename?: "ProductRoleList";
                items: Array<{
                  __typename?: "ProductRole";
                  id: string;
                  name: string;
                  status: ProductRoleStatus;
                }>;
              };
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              roles: {
                __typename?: "ProductRoleList";
                items: Array<{
                  __typename?: "ProductRole";
                  id: string;
                  name: string;
                  status: ProductRoleStatus;
                }>;
              };
            }
          | {
              __typename?: "Events";
              id: string;
              roles: {
                __typename?: "ProductRoleList";
                items: Array<{
                  __typename?: "ProductRole";
                  id: string;
                  name: string;
                  status: ProductRoleStatus;
                }>;
              };
            }
          | {
              __typename?: "Instructions";
              id: string;
              roles: {
                __typename?: "ProductRoleList";
                items: Array<{
                  __typename?: "ProductRole";
                  id: string;
                  name: string;
                  status: ProductRoleStatus;
                }>;
              };
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              roles: {
                __typename?: "ProductRoleList";
                items: Array<{
                  __typename?: "ProductRole";
                  id: string;
                  name: string;
                  status: ProductRoleStatus;
                }>;
              };
            }
          | {
              __typename?: "Variations";
              id: string;
              roles: {
                __typename?: "ProductRoleList";
                items: Array<{
                  __typename?: "ProductRole";
                  id: string;
                  name: string;
                  status: ProductRoleStatus;
                }>;
              };
            };
      }>;
    };
  };
};

export type BulkAssignUserRolesMutationVariables = Exact<{
  input: BulkUserRoleAssignmentInput;
}>;

export type BulkAssignUserRolesMutation = {
  __typename?: "Mutation";
  bulkAssignUserRoles: boolean;
};

export type AddContractBindingMutationVariables = Exact<{
  input: AddContractBindingInput;
}>;

export type AddContractBindingMutation = {
  __typename?: "Mutation";
  addContractBinding: {
    __typename?: "ContractBinding";
    id: string;
    contractBindingTypeId: string;
    status: ContractBindingStatus;
    defaultCompany: boolean;
    representativeId?: string | null;
    isCompanyBilled: boolean;
    dateCreated: any;
    companyId: string;
    contractId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type AddProductInstanceMutationVariables = Exact<{
  input: AddProductInstanceInput;
}>;

export type AddProductInstanceMutation = {
  __typename?: "Mutation";
  addProductInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
  };
};

export type AuthorizationWorkflowCandidatesQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type AuthorizationWorkflowCandidatesQuery = {
  __typename?: "Query";
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    friendlyName: string;
    status: ProjectStatus;
    contracts: {
      __typename?: "ContractList";
      items: Array<{
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        activeBindings: {
          __typename?: "ContractBindingList";
          items: Array<{
            __typename?: "ContractBinding";
            id: string;
            status: ContractBindingStatus;
            company: {
              __typename?: "Company";
              id: string;
              registeredName: string;
              tradingName: string;
              status: CompanyStatus;
              users: {
                __typename?: "UserList";
                items: Array<{
                  __typename?: "User";
                  registered?: boolean | null;
                  status: UserStatus;
                  email: any;
                  id: string;
                  firstname: string;
                  surname: string;
                }>;
              };
            };
          }>;
        };
      }>;
    };
  };
};

export type AddContractMutationVariables = Exact<{
  input: AddContractInput;
}>;

export type AddContractMutation = {
  __typename?: "Mutation";
  addContract: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
  };
};

export type AddProjectMutationVariables = Exact<{
  input: AddProjectInput;
}>;

export type AddProjectMutation = {
  __typename?: "Mutation";
  addProject: {
    __typename?: "Project";
    id: string;
    name: string;
    dateCreated: any;
    status: ProjectStatus;
    friendlyName: string;
    number?: string | null;
    sector: ProjectSector;
    description?: string | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type ProjectQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProjectQuery = {
  __typename?: "Query";
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    friendlyName: string;
    number?: string | null;
    sector: ProjectSector;
    description?: string | null;
    status: ProjectStatus;
    dateCreated: any;
    creatorId: string;
    billingCompanyId: string;
    contracts: {
      __typename?: "ContractList";
      items: Array<{
        __typename?: "Contract";
        id: string;
        friendlyName: string;
        name: string;
        number: string;
        country: string;
        province?: string | null;
        timeZone: string;
        status: ContractStatus;
        contractTypeId: string;
        dateCreated: any;
        startDate?: any | null;
        endDate?: any | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contractType: {
          __typename?: "ContractType";
          description: string;
          version?: string | null;
          subType?: string | null;
        };
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    billingCompany: {
      __typename?: "Company";
      id: string;
      type?: CompanyType | null;
      registeredName: string;
      tradingName: string;
      registrationNumber?: string | null;
      logo?: string | null;
      vatRegistrationNumber?: string | null;
      status: CompanyStatus;
      creatorId: string;
      dateCreated: any;
      physicalAddress?: {
        __typename?: "Address";
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        provinceState?: string | null;
        country: string;
        code?: string | null;
      } | null;
      postalAddress?: {
        __typename?: "Address";
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        provinceState?: string | null;
        country: string;
        code?: string | null;
      } | null;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
  };
};

export type EditProjectMutationVariables = Exact<{
  input: EditProjectInput;
}>;

export type EditProjectMutation = {
  __typename?: "Mutation";
  editProject: {
    __typename?: "Project";
    id: string;
    name: string;
    friendlyName: string;
    number?: string | null;
    sector: ProjectSector;
    description?: string | null;
    status: ProjectStatus;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    contracts: {
      __typename?: "ContractList";
      items: Array<{
        __typename?: "Contract";
        id: string;
        friendlyName: string;
        number: string;
        country: string;
        province?: string | null;
        timeZone: string;
        status: ContractStatus;
        contractTypeId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
  };
};

export type ProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type ProjectsQuery = {
  __typename?: "Query";
  projects: {
    __typename?: "ProjectList";
    items: Array<{
      __typename?: "Project";
      id: string;
      name: string;
      dateCreated: any;
      status: ProjectStatus;
      number?: string | null;
      description?: string | null;
      friendlyName: string;
      sector: ProjectSector;
      contracts: {
        __typename?: "ContractList";
        items: Array<{
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          status: ContractStatus;
        }>;
      };
      billingCompany: {
        __typename?: "Company";
        id: string;
        type?: CompanyType | null;
        registeredName: string;
        tradingName: string;
        registrationNumber?: string | null;
        logo?: string | null;
        vatRegistrationNumber?: string | null;
        status: CompanyStatus;
        creatorId: string;
        dateCreated: any;
        physicalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        postalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
      };
    }>;
  };
};

export type CompanyProjectsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CompanyProjectsQuery = {
  __typename?: "Query";
  company?: {
    __typename?: "Company";
    id: string;
    registeredName: string;
    tradingName: string;
    projects: {
      __typename?: "ProjectList";
      items: Array<{
        __typename?: "Project";
        id: string;
        dateCreated: any;
        status: ProjectStatus;
        friendlyName: string;
        sector: ProjectSector;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
  } | null;
};

export type ChangeProjectStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ProjectStatus;
}>;

export type ChangeProjectStatusMutation = {
  __typename?: "Mutation";
  changeProjectStatus: {
    __typename?: "Project";
    id: string;
    name: string;
    friendlyName: string;
    status: ProjectStatus;
  };
};

export type EffectPresetsQueryVariables = Exact<{ [key: string]: never }>;

export type EffectPresetsQuery = {
  __typename?: "Query";
  effectPresets: {
    __typename?: "EffectPresetList";
    items: Array<{
      __typename?: "EffectPreset";
      id: string;
      name: string;
      contractTypeId: string;
      isDefault: boolean;
      isInternal: boolean;
      status: EffectPresetStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
      };
      options: {
        __typename?: "EffectOptionList";
        items: Array<{
          __typename?: "EffectOption";
          id: string;
          label: string;
          isInternal: boolean;
          estimationType: EffectEstimationType;
          unit: string;
          effectPresetId: string;
          status: EffectOptionStatus;
          creatorId: string;
          dateCreated: any;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
          };
        }>;
      };
    }>;
  };
};

export type ChangeEffectPresetStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: EffectPresetStatus;
}>;

export type ChangeEffectPresetStatusMutation = {
  __typename?: "Mutation";
  changeEffectPresetStatus: {
    __typename?: "EffectPreset";
    id: string;
    status: EffectPresetStatus;
  };
};

export type AddEffectPresetMutationVariables = Exact<{
  input: AddEffectPresetInput;
}>;

export type AddEffectPresetMutation = {
  __typename?: "Mutation";
  addEffectPreset: {
    __typename?: "EffectPreset";
    id: string;
    name: string;
    status: EffectPresetStatus;
  };
};

export type EditEffectPresetMutationVariables = Exact<{
  input: EditEffectPresetInput;
}>;

export type EditEffectPresetMutation = {
  __typename?: "Mutation";
  editEffectPreset: {
    __typename?: "EffectPreset";
    id: string;
    name: string;
    status: EffectPresetStatus;
  };
};

export type ChangeEffectOptionStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: EffectOptionStatus;
}>;

export type ChangeEffectOptionStatusMutation = {
  __typename?: "Mutation";
  changeEffectOptionStatus: {
    __typename?: "EffectOption";
    id: string;
    status: EffectOptionStatus;
  };
};

export type AddEffectOptionMutationVariables = Exact<{
  input: AddEffectOptionInput;
}>;

export type AddEffectOptionMutation = {
  __typename?: "Mutation";
  addEffectOption: {
    __typename?: "EffectOption";
    id: string;
    label: string;
    status: EffectOptionStatus;
  };
};

export type ProductQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProductQuery = {
  __typename?: "Query";
  product:
    | {
        __typename?: "Claims";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
        productSchemas: {
          __typename?: "ProductSchemaList";
          items: Array<{
            __typename?: "ProductSchema";
            id: string;
            name: string;
            productId: string;
            status: ProductSchemaStatus;
            creatorId: string;
            isDefault: boolean;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
        statusCollections: {
          __typename?: "ItemStatusCollectionList";
          items: Array<{
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            status: ItemStatusCollectionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
      }
    | {
        __typename?: "CompEvents";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
        productSchemas: {
          __typename?: "ProductSchemaList";
          items: Array<{
            __typename?: "ProductSchema";
            id: string;
            name: string;
            productId: string;
            status: ProductSchemaStatus;
            creatorId: string;
            isDefault: boolean;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
        statusCollections: {
          __typename?: "ItemStatusCollectionList";
          items: Array<{
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            status: ItemStatusCollectionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
      }
    | {
        __typename?: "DailyDiary";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
        productSchemas: {
          __typename?: "ProductSchemaList";
          items: Array<{
            __typename?: "ProductSchema";
            id: string;
            name: string;
            productId: string;
            status: ProductSchemaStatus;
            creatorId: string;
            isDefault: boolean;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
        statusCollections: {
          __typename?: "ItemStatusCollectionList";
          items: Array<{
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            status: ItemStatusCollectionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
      }
    | {
        __typename?: "EarlyWarnings";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
        productSchemas: {
          __typename?: "ProductSchemaList";
          items: Array<{
            __typename?: "ProductSchema";
            id: string;
            name: string;
            productId: string;
            status: ProductSchemaStatus;
            creatorId: string;
            isDefault: boolean;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
        statusCollections: {
          __typename?: "ItemStatusCollectionList";
          items: Array<{
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            status: ItemStatusCollectionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
      }
    | {
        __typename?: "Events";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
        productSchemas: {
          __typename?: "ProductSchemaList";
          items: Array<{
            __typename?: "ProductSchema";
            id: string;
            name: string;
            productId: string;
            status: ProductSchemaStatus;
            creatorId: string;
            isDefault: boolean;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
        statusCollections: {
          __typename?: "ItemStatusCollectionList";
          items: Array<{
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            status: ItemStatusCollectionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
      }
    | {
        __typename?: "Instructions";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
        productSchemas: {
          __typename?: "ProductSchemaList";
          items: Array<{
            __typename?: "ProductSchema";
            id: string;
            name: string;
            productId: string;
            status: ProductSchemaStatus;
            creatorId: string;
            isDefault: boolean;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
        statusCollections: {
          __typename?: "ItemStatusCollectionList";
          items: Array<{
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            status: ItemStatusCollectionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
      }
    | {
        __typename?: "RisksRegister";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
        productSchemas: {
          __typename?: "ProductSchemaList";
          items: Array<{
            __typename?: "ProductSchema";
            id: string;
            name: string;
            productId: string;
            status: ProductSchemaStatus;
            creatorId: string;
            isDefault: boolean;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
        statusCollections: {
          __typename?: "ItemStatusCollectionList";
          items: Array<{
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            status: ItemStatusCollectionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
      }
    | {
        __typename?: "Variations";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
        productSchemas: {
          __typename?: "ProductSchemaList";
          items: Array<{
            __typename?: "ProductSchema";
            id: string;
            name: string;
            productId: string;
            status: ProductSchemaStatus;
            creatorId: string;
            isDefault: boolean;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
        statusCollections: {
          __typename?: "ItemStatusCollectionList";
          items: Array<{
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            status: ItemStatusCollectionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
          }>;
        };
      };
};

export type AddProductSchemaMutationVariables = Exact<{
  input: AddProductSchemaInput;
}>;

export type AddProductSchemaMutation = {
  __typename?: "Mutation";
  addProductSchema: {
    __typename?: "ProductSchema";
    id: string;
    name: string;
    isDefault: boolean;
    productId: string;
    status: ProductSchemaStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type AddItemStatusCollectionMutationVariables = Exact<{
  input: AddItemStatusCollectionInput;
}>;

export type AddItemStatusCollectionMutation = {
  __typename?: "Mutation";
  addItemStatusCollection: {
    __typename?: "ItemStatusCollection";
    id: string;
    name: string;
    isDefault: boolean;
    productId: string;
    status: ItemStatusCollectionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type EditItemStatusCollectionMutationVariables = Exact<{
  input: EditItemStatusCollectionInput;
}>;

export type EditItemStatusCollectionMutation = {
  __typename?: "Mutation";
  editItemStatusCollection: {
    __typename?: "ItemStatusCollection";
    id: string;
    name: string;
    isDefault: boolean;
    productId: string;
    status: ItemStatusCollectionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type ProductSchemaQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProductSchemaQuery = {
  __typename?: "Query";
  productSchema: {
    __typename?: "ProductSchema";
    id: string;
    name: string;
    isDefault: boolean;
    productId: string;
    isInternal: boolean;
    status: ProductSchemaStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    schemaSections: {
      __typename?: "SchemaSectionList";
      items: Array<{
        __typename?: "SchemaSection";
        id: string;
        name: string;
        displayText: string;
        displayOrder: number;
        productSchemaId: string;
        isInternal: boolean;
        status: SchemaSectionStatus;
        dateCreated: any;
        columnPlacement: ColumnPlacementType;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
        schemaFields: {
          __typename?: "SchemaFieldList";
          items: Array<{
            __typename?: "SchemaField";
            id: string;
            name: string;
            schemaSectionId: string;
            fieldTypeId: string;
            displayText: string;
            displayOrder: number;
            isRequired: boolean;
            isInternal: boolean;
            complexTypeItemId?: string | null;
            extraData?: string | null;
            status: SchemaFieldStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            fieldType: {
              __typename?: "FieldType";
              id: string;
              description: string;
              shortCode: FieldTypeEnum;
              status: FieldTypeStatus;
            };
          }>;
        };
      }>;
    };
  };
};

export type AddSchemaSectionMutationVariables = Exact<{
  input: AddSchemaSectionInput;
}>;

export type AddSchemaSectionMutation = {
  __typename?: "Mutation";
  addSchemaSection: {
    __typename?: "SchemaSection";
    id: string;
    name: string;
    displayText: string;
    displayOrder: number;
    productSchemaId: string;
    status: SchemaSectionStatus;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    schemaFields: {
      __typename?: "SchemaFieldList";
      items: Array<{
        __typename?: "SchemaField";
        id: string;
        name: string;
        schemaSectionId: string;
        fieldTypeId: string;
        displayText: string;
        displayOrder: number;
        isRequired: boolean;
        isInternal: boolean;
        complexTypeItemId?: string | null;
        extraData?: string | null;
        status: SchemaFieldStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        fieldType: {
          __typename?: "FieldType";
          id: string;
          description: string;
          shortCode: FieldTypeEnum;
          status: FieldTypeStatus;
        };
      }>;
    };
  };
};

export type AddSchemaFieldMutationVariables = Exact<{
  input: AddSchemaFieldInput;
}>;

export type AddSchemaFieldMutation = {
  __typename?: "Mutation";
  addSchemaField: { __typename?: "SchemaField"; id: string; name: string };
};

export type ChangeSchemaSectionStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: SchemaSectionStatus;
}>;

export type ChangeSchemaSectionStatusMutation = {
  __typename?: "Mutation";
  changeSchemaSectionStatus: {
    __typename?: "SchemaSection";
    id: string;
    name: string;
    status: SchemaSectionStatus;
  };
};

export type ChangeSchemaFieldStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: SchemaFieldStatus;
}>;

export type ChangeSchemaFieldStatusMutation = {
  __typename?: "Mutation";
  changeSchemaFieldStatus: {
    __typename?: "SchemaField";
    id: string;
    name: string;
    status: SchemaFieldStatus;
  };
};

export type EditSchemaSectionMutationVariables = Exact<{
  input: EditSchemaSectionInput;
}>;

export type EditSchemaSectionMutation = {
  __typename?: "Mutation";
  editSchemaSection: {
    __typename?: "SchemaSection";
    id: string;
    name: string;
    status: SchemaSectionStatus;
  };
};

export type EditSchemaFieldMutationVariables = Exact<{
  input: EditSchemaFieldInput;
}>;

export type EditSchemaFieldMutation = {
  __typename?: "Mutation";
  editSchemaField: {
    __typename?: "SchemaField";
    id: string;
    name: string;
    status: SchemaFieldStatus;
  };
};

export type FieldTypesQueryVariables = Exact<{ [key: string]: never }>;

export type FieldTypesQuery = {
  __typename?: "Query";
  fieldTypes: {
    __typename?: "FieldTypeList";
    items: Array<{
      __typename?: "FieldType";
      id: string;
      description: string;
      shortCode: FieldTypeEnum;
      status: FieldTypeStatus;
    }>;
  };
};

export type ItemStatusCollectionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ItemStatusCollectionQuery = {
  __typename?: "Query";
  itemStatusCollection: {
    __typename?: "ItemStatusCollection";
    id: string;
    name: string;
    isDefault: boolean;
    productId: string;
    status: ItemStatusCollectionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    statusOptions: {
      __typename?: "ItemStatusOptionList";
      items: Array<{
        __typename?: "ItemStatusOption";
        id: string;
        displayOrder: number;
        description: string;
        equatesToRemoved: boolean;
        style: ItemStatusOptionStyles;
        statusCollectionId: string;
        status: ItemStatusOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
      }>;
    };
  };
};

export type AddItemStatusOptionMutationVariables = Exact<{
  input: AddItemStatusOptionInput;
}>;

export type AddItemStatusOptionMutation = {
  __typename?: "Mutation";
  addItemStatusOption: {
    __typename?: "ItemStatusOption";
    id: string;
    displayOrder: number;
    description: string;
    equatesToRemoved: boolean;
    style: ItemStatusOptionStyles;
    statusCollectionId: string;
    status: ItemStatusOptionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type EditItemStatusOptionMutationVariables = Exact<{
  input: EditItemStatusOptionInput;
}>;

export type EditItemStatusOptionMutation = {
  __typename?: "Mutation";
  editItemStatusOption: {
    __typename?: "ItemStatusOption";
    id: string;
    displayOrder: number;
    description: string;
    equatesToRemoved: boolean;
    style: ItemStatusOptionStyles;
    statusCollectionId: string;
    status: ItemStatusOptionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type ItemStatusOptionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ItemStatusOptionQuery = {
  __typename?: "Query";
  itemStatusOption: {
    __typename?: "ItemStatusOption";
    id: string;
    displayOrder: number;
    description: string;
    equatesToRemoved: boolean;
    style: ItemStatusOptionStyles;
    statusCollectionId: string;
    status: ItemStatusOptionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    optionReasons: {
      __typename?: "ItemStatusOptionReasonList";
      items: Array<{
        __typename?: "ItemStatusOptionReason";
        id: string;
        displayOrder: number;
        description: string;
        statusOptionId: string;
        status: ItemStatusOptionReasonStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
      }>;
    };
  };
};

export type AddItemStatusOptionReasonMutationVariables = Exact<{
  input: AddItemStatusOptionReasonInput;
}>;

export type AddItemStatusOptionReasonMutation = {
  __typename?: "Mutation";
  addItemStatusOptionReason: {
    __typename?: "ItemStatusOptionReason";
    id: string;
    displayOrder: number;
    description: string;
    statusOptionId: string;
    status: ItemStatusOptionReasonStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type EditItemStatusOptionReasonMutationVariables = Exact<{
  input: EditItemStatusOptionReasonInput;
}>;

export type EditItemStatusOptionReasonMutation = {
  __typename?: "Mutation";
  editItemStatusOptionReason: {
    __typename?: "ItemStatusOptionReason";
    id: string;
    displayOrder: number;
    description: string;
  };
};

export type ChangeItemStatusOptionReasonStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ItemStatusOptionReasonStatus;
}>;

export type ChangeItemStatusOptionReasonStatusMutation = {
  __typename?: "Mutation";
  changeItemStatusOptionReasonStatus: {
    __typename?: "ItemStatusOptionReason";
    id: string;
    displayOrder: number;
    description: string;
  };
};

export type DocumentTemplateRoutesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DocumentTemplateRoutesQuery = {
  __typename?: "Query";
  documentTemplateRoutes: {
    __typename?: "DocumentTemplateRouteList";
    items: Array<{
      __typename?: "DocumentTemplateRoute";
      id: string;
      productOutputActionId: string;
      contractTypeId: string;
      originatingPartyId: string;
      contractId?: string | null;
      targetPartyId: string;
      defaultNumbering?: string | null;
      clause?: string | null;
      documentTemplateId: string;
      isInternal: boolean;
      dateCreated: any;
      outputAction: {
        __typename?: "ProductOutputAction";
        id: string;
        name: string;
        productId: string;
        isInternal: boolean;
        status: ProductOutputActionStatus;
        dateCreated: any;
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              status: ProductStatus;
            };
      };
      contractType: {
        __typename?: "ContractType";
        id: string;
        description: string;
        subType?: string | null;
        version?: string | null;
        status: ContractTypeStatus;
        contractBindingTypes: {
          __typename?: "ContractBindingTypeList";
          items: Array<{
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            status: ContractBindingTypeStatus;
          }>;
        };
      };
      originatingParty: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        status: ContractBindingTypeStatus;
      };
      targetParty: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        status: ContractBindingTypeStatus;
      };
      documentTemplate: {
        __typename?: "DocumentTemplate";
        id: string;
        name: string;
        description: string;
        content: string;
        isInternal: boolean;
        status: DocumentTemplateStatus;
        dateCreated: any;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
      };
    }>;
  };
};

export type DocumentTemplateRouteLiteFieldsFragment = {
  __typename?: "DocumentTemplateRoute";
  id: string;
  productOutputActionId: string;
  contractTypeId: string;
  originatingPartyId: string;
  contractId?: string | null;
  targetPartyId: string;
  defaultNumbering?: string | null;
  clause?: string | null;
  documentTemplateId: string;
  isInternal: boolean;
  dateCreated: any;
  creatorId: string;
};

export type AddDocumentTemplateRouteMutationVariables = Exact<{
  input: AddDocumentTemplateRouteInput;
}>;

export type AddDocumentTemplateRouteMutation = {
  __typename?: "Mutation";
  addDocumentTemplateRoute: {
    __typename?: "DocumentTemplateRoute";
    id: string;
    productOutputActionId: string;
    contractTypeId: string;
    originatingPartyId: string;
    contractId?: string | null;
    targetPartyId: string;
    defaultNumbering?: string | null;
    clause?: string | null;
    documentTemplateId: string;
    isInternal: boolean;
    dateCreated: any;
    creatorId: string;
  };
};

export type EditDocumentTemplateRouteMutationVariables = Exact<{
  input: EditDocumentTemplateRouteInput;
}>;

export type EditDocumentTemplateRouteMutation = {
  __typename?: "Mutation";
  editDocumentTemplateRoute: {
    __typename?: "DocumentTemplateRoute";
    id: string;
    productOutputActionId: string;
    contractTypeId: string;
    originatingPartyId: string;
    contractId?: string | null;
    targetPartyId: string;
    defaultNumbering?: string | null;
    clause?: string | null;
    documentTemplateId: string;
    isInternal: boolean;
    dateCreated: any;
    creatorId: string;
  };
};

export type RemoveDocumentTemplateRouteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDocumentTemplateRouteMutation = {
  __typename?: "Mutation";
  removeDocumentTemplateRoute: boolean;
};

export type AddDocumentTemplateRouteOverrideMutationVariables = Exact<{
  input: AddDocumentTemplateRouteOverrideInput;
}>;

export type AddDocumentTemplateRouteOverrideMutation = {
  __typename?: "Mutation";
  addDocumentTemplateRouteOverride: {
    __typename?: "DocumentTemplateRoute";
    id: string;
    productOutputActionId: string;
    contractTypeId: string;
    originatingPartyId: string;
    contractId?: string | null;
    targetPartyId: string;
    defaultNumbering?: string | null;
    clause?: string | null;
    documentTemplateId: string;
    isInternal: boolean;
    dateCreated: any;
    creatorId: string;
  };
};

export type EditDocumentTemplateRouteOverrideMutationVariables = Exact<{
  input: EditDocumentTemplateRouteOverrideInput;
}>;

export type EditDocumentTemplateRouteOverrideMutation = {
  __typename?: "Mutation";
  editDocumentTemplateRouteOverride: {
    __typename?: "DocumentTemplateRoute";
    id: string;
    productOutputActionId: string;
    contractTypeId: string;
    originatingPartyId: string;
    contractId?: string | null;
    targetPartyId: string;
    defaultNumbering?: string | null;
    clause?: string | null;
    documentTemplateId: string;
    isInternal: boolean;
    dateCreated: any;
    creatorId: string;
  };
};

export type RemoveDocumentTemplateRouteOverrideMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDocumentTemplateRouteOverrideMutation = {
  __typename?: "Mutation";
  removeDocumentTemplateRouteOverride: boolean;
};

export type AddDocumentTemplateMutationVariables = Exact<{
  input: AddDocumentTemplateInput;
}>;

export type AddDocumentTemplateMutation = {
  __typename?: "Mutation";
  addDocumentTemplate: {
    __typename?: "DocumentTemplate";
    id: string;
    name: string;
    description: string;
    content: string;
    status: DocumentTemplateStatus;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type ProductsWithSchemasQueryVariables = Exact<{ [key: string]: never }>;

export type ProductsWithSchemasQuery = {
  __typename?: "Query";
  products: {
    __typename?: "ProductList";
    items: Array<
      | {
          __typename?: "Claims";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
          productSchemas: {
            __typename?: "ProductSchemaList";
            items: Array<{
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      status: SchemaFieldStatus;
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            }>;
          };
        }
      | {
          __typename?: "CompEvents";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
          productSchemas: {
            __typename?: "ProductSchemaList";
            items: Array<{
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      status: SchemaFieldStatus;
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            }>;
          };
        }
      | {
          __typename?: "DailyDiary";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
          productSchemas: {
            __typename?: "ProductSchemaList";
            items: Array<{
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      status: SchemaFieldStatus;
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            }>;
          };
        }
      | {
          __typename?: "EarlyWarnings";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
          productSchemas: {
            __typename?: "ProductSchemaList";
            items: Array<{
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      status: SchemaFieldStatus;
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            }>;
          };
        }
      | {
          __typename?: "Events";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
          productSchemas: {
            __typename?: "ProductSchemaList";
            items: Array<{
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      status: SchemaFieldStatus;
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            }>;
          };
        }
      | {
          __typename?: "Instructions";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
          productSchemas: {
            __typename?: "ProductSchemaList";
            items: Array<{
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      status: SchemaFieldStatus;
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            }>;
          };
        }
      | {
          __typename?: "RisksRegister";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
          productSchemas: {
            __typename?: "ProductSchemaList";
            items: Array<{
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      status: SchemaFieldStatus;
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            }>;
          };
        }
      | {
          __typename?: "Variations";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
          productSchemas: {
            __typename?: "ProductSchemaList";
            items: Array<{
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      status: SchemaFieldStatus;
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            }>;
          };
        }
    >;
  };
};

export type DocumentTemplateQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DocumentTemplateQuery = {
  __typename?: "Query";
  documentTemplate: {
    __typename?: "DocumentTemplate";
    id: string;
    name: string;
    description: string;
    content: string;
    isInternal: boolean;
    status: DocumentTemplateStatus;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type EditDocumentTemplateMutationVariables = Exact<{
  input: EditDocumentTemplateInput;
}>;

export type EditDocumentTemplateMutation = {
  __typename?: "Mutation";
  editDocumentTemplate: {
    __typename?: "DocumentTemplate";
    id: string;
    name: string;
    description: string;
    content: string;
    isInternal: boolean;
    status: DocumentTemplateStatus;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type LocalDailyDiaryItemFieldsFragment = {
  __typename?: "DailyDiaryItem";
  id: string;
  date: any;
  number: string;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  sentById?: string | null;
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    soloModeSupported: boolean;
    status: ProductInstanceStatus;
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      timeZone: string;
      number: string;
      status: ContractStatus;
      dateCreated: any;
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        status: ProjectStatus;
      };
    };
    product:
      | {
          __typename?: "Claims";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "CompEvents";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "DailyDiary";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "EarlyWarnings";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Events";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Instructions";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "RisksRegister";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Variations";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        };
  };
};

export type LocalAuthorizationWorkflowAuditFieldsFragment = {
  __typename?: "AuthorizationWorkflowAudit";
  id: string;
  initiatedById: string;
  regardingProductItemId: string;
  regardingProductType: ProductType;
  regardingItemHistoryId?: string | null;
  actionId: string;
  workflowId: string;
  status: AuthorizationWorkflowAuditStatus;
  dateCreated: any;
  dateInitiated: any;
  initiatedBy: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  workflow: {
    __typename?: "AuthorizationWorkflow";
    id: string;
    name: string;
    status: AuthorizationWorkflowStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    modifiedById: string;
    projectId: string;
    levels: Array<{
      __typename?: "AuthorizationWorkflowLevel";
      sequence: number;
      mode: AuthorizationWorkflowLevelMode;
      userIds: Array<string>;
      users: {
        __typename?: "UserList";
        items: Array<{
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          jobTitle: string;
          country: string;
          mobileNumber?: any | null;
          alternateNumber?: any | null;
          dateOfBirth?: any | null;
          profilePicture?: string | null;
          email: any;
          companyId: string;
          registered?: boolean | null;
          status: UserStatus;
          lastActive?: any | null;
          dateInvited: any;
          registeredDate?: any | null;
          company: {
            __typename?: "Company";
            id: string;
            tradingName: string;
            registeredName: string;
          };
          invitedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      friendlyName: string;
      number?: string | null;
      sector: ProjectSector;
      description?: string | null;
      status: ProjectStatus;
      dateCreated: any;
      creatorId: string;
      billingCompanyId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contracts: {
        __typename?: "ContractList";
        items: Array<{
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          status: ContractStatus;
        }>;
      };
      billingCompany: {
        __typename?: "Company";
        id: string;
        type?: CompanyType | null;
        registeredName: string;
        tradingName: string;
        registrationNumber?: string | null;
        logo?: string | null;
        vatRegistrationNumber?: string | null;
        status: CompanyStatus;
        creatorId: string;
        dateCreated: any;
        physicalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        postalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
    };
  };
  action: {
    __typename?: "AuthorizationWorkflowActionMapping";
    id: string;
    productOutputActionId: string;
    workflowId: string;
    partyId?: string | null;
    productInstanceId: string;
    status: AuthorizationWorkflowActionMappingStatus;
    dateCreated: any;
    creatorId: string;
    modifiedById: string;
    dateModified: any;
    productOutputAction: {
      __typename?: "ProductOutputAction";
      id: string;
      name: string;
      productId: string;
      isInternal: boolean;
      status: ProductOutputActionStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      product:
        | {
            __typename?: "Claims";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "CompEvents";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "DailyDiary";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "EarlyWarnings";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Events";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Instructions";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "RisksRegister";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Variations";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          };
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    party?: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    } | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contractId: string;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        friendlyName: string;
        timeZone: string;
        valueCurrency: string;
        project: { __typename?: "Project"; id: string; friendlyName: string };
      };
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
  regardingProductItem:
    | {
        __typename: "ClaimItem";
        id: string;
        number: string;
        productInstanceId: string;
        regardingId: string;
        advanceWarningId?: string | null;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        notifiedByPartyId: string;
        claimStatus: ClaimStatus;
        claimRegardingType: ClaimRegardingType;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          status: ProductInstanceStatus;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
          };
        };
        advanceWarning?: {
          __typename?: "EarlyWarningItem";
          id: string;
          title: string;
          dateSent: any;
          number: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            product:
              | { __typename?: "Claims"; id: string }
              | { __typename?: "CompEvents"; id: string }
              | { __typename?: "DailyDiary"; id: string }
              | { __typename?: "EarlyWarnings"; id: string }
              | { __typename?: "Events"; id: string }
              | { __typename?: "Instructions"; id: string }
              | { __typename?: "RisksRegister"; id: string }
              | { __typename?: "Variations"; id: string };
          };
        } | null;
        regardingEvent?: {
          __typename?: "EventItem";
          id: string;
          title: string;
          productInstanceId: string;
          number: string;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            product:
              | { __typename?: "Claims"; id: string }
              | { __typename?: "CompEvents"; id: string }
              | { __typename?: "DailyDiary"; id: string }
              | { __typename?: "EarlyWarnings"; id: string }
              | { __typename?: "Events"; id: string }
              | { __typename?: "Instructions"; id: string }
              | { __typename?: "RisksRegister"; id: string }
              | { __typename?: "Variations"; id: string };
          };
        } | null;
        regardingInstruction?: {
          __typename?: "InstructionItem";
          id: string;
          title: string;
          number: string;
          productInstanceId: string;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            product:
              | { __typename?: "Claims"; id: string }
              | { __typename?: "CompEvents"; id: string }
              | { __typename?: "DailyDiary"; id: string }
              | { __typename?: "EarlyWarnings"; id: string }
              | { __typename?: "Events"; id: string }
              | { __typename?: "Instructions"; id: string }
              | { __typename?: "RisksRegister"; id: string }
              | { __typename?: "Variations"; id: string };
          };
        } | null;
        notifiedByParty: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        };
        claimNextStep?: {
          __typename?: "ClaimItemNextStep";
          actionType: ClaimActionType;
          dueDate: any;
          partyId: string;
          party: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
        } | null;
        claimLastAction: {
          __typename?: "ClaimItemLastAction";
          actionType: ClaimActionType;
          date: any;
          daysLate?: number | null;
          party: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
        };
      }
    | {
        __typename: "CompEventItem";
        id: string;
        number: string;
        productInstanceId: string;
        regardingId: string;
        regardingType: CompEventRegardingType;
        status: CompEventStatus;
        price?: number | null;
        time?: number | null;
        earlyWarningId?: string | null;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        deemingDate?: any | null;
        lastAction: {
          __typename?: "CompEventItemLastAction";
          actionType: CompEventActionType;
          daysLate?: number | null;
          date: any;
        };
        nextStep?: {
          __typename?: "CompEventItemNextStep";
          actionType: CompEventActionType;
          dueDate: any;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          status: ProductInstanceStatus;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
          };
        };
        earlyWarning?: {
          __typename?: "EarlyWarningItem";
          id: string;
          title: string;
          dateSent: any;
          number: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            product:
              | { __typename?: "Claims"; id: string }
              | { __typename?: "CompEvents"; id: string }
              | { __typename?: "DailyDiary"; id: string }
              | { __typename?: "EarlyWarnings"; id: string }
              | { __typename?: "Events"; id: string }
              | { __typename?: "Instructions"; id: string }
              | { __typename?: "RisksRegister"; id: string }
              | { __typename?: "Variations"; id: string };
          };
        } | null;
        regardingEvent?: {
          __typename?: "EventItem";
          id: string;
          title: string;
          productInstanceId: string;
          number: string;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            product:
              | { __typename?: "Claims"; id: string }
              | { __typename?: "CompEvents"; id: string }
              | { __typename?: "DailyDiary"; id: string }
              | { __typename?: "EarlyWarnings"; id: string }
              | { __typename?: "Events"; id: string }
              | { __typename?: "Instructions"; id: string }
              | { __typename?: "RisksRegister"; id: string }
              | { __typename?: "Variations"; id: string };
          };
        } | null;
        regardingInstruction?: {
          __typename?: "InstructionItem";
          id: string;
          title: string;
          number: string;
          productInstanceId: string;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            product:
              | { __typename?: "Claims"; id: string }
              | { __typename?: "CompEvents"; id: string }
              | { __typename?: "DailyDiary"; id: string }
              | { __typename?: "EarlyWarnings"; id: string }
              | { __typename?: "Events"; id: string }
              | { __typename?: "Instructions"; id: string }
              | { __typename?: "RisksRegister"; id: string }
              | { __typename?: "Variations"; id: string };
          };
        } | null;
        notifiedByParty: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        };
      }
    | {
        __typename?: "DailyDiaryItem";
        id: string;
        date: any;
        number: string;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        sentById?: string | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          status: ProductInstanceStatus;
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            timeZone: string;
            number: string;
            status: ContractStatus;
            dateCreated: any;
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
      }
    | {
        __typename?: "EarlyWarningItem";
        id: string;
        title: string;
        severity: string;
        number: string;
        productInstanceId: string;
        ownerId: string;
        statusOptionId: string;
        dateCreated: any;
        creatorId: string;
        dateModified: any;
        offline: boolean;
        givenById?: string | null;
        dateSent: any;
        data: {
          __typename?: "ItemData";
          sections: Array<{
            __typename?: "ItemDataSection";
            name: string;
            entries: Array<{
              __typename?: "ItemDataEntry";
              name: string;
              value: string;
            }>;
          }>;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        owner: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          productSchema: {
            __typename?: "ProductSchema";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            isInternal: boolean;
            status: ProductSchemaStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaSections: {
              __typename?: "SchemaSectionList";
              items: Array<{
                __typename?: "SchemaSection";
                id: string;
                name: string;
                displayText: string;
                displayOrder: number;
                columnPlacement: ColumnPlacementType;
                productSchemaId: string;
                isInternal: boolean;
                status: SchemaSectionStatus;
                dateCreated: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaFields: {
                  __typename?: "SchemaFieldList";
                  items: Array<{
                    __typename?: "SchemaField";
                    id: string;
                    name: string;
                    schemaSectionId: string;
                    fieldTypeId: string;
                    displayText: string;
                    displayOrder: number;
                    isRequired: boolean;
                    isInternal: boolean;
                    complexTypeItemId?: string | null;
                    extraData?: string | null;
                    status: SchemaFieldStatus;
                    creatorId: string;
                    dateCreated: any;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    fieldType: {
                      __typename?: "FieldType";
                      id: string;
                      description: string;
                      shortCode: FieldTypeEnum;
                      status: FieldTypeStatus;
                    };
                  }>;
                };
              }>;
            };
          };
          contract: {
            __typename?: "Contract";
            id: string;
            valueCurrency: string;
            timeZone: string;
          };
          product:
            | { __typename?: "Claims"; id: string; numberingFormat: string }
            | { __typename?: "CompEvents"; id: string; numberingFormat: string }
            | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                numberingFormat: string;
              }
            | { __typename?: "Events"; id: string; numberingFormat: string }
            | {
                __typename?: "Instructions";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "Variations";
                id: string;
                numberingFormat: string;
              };
        };
        attachments?: Array<{
          __typename?: "Attachment";
          id: string;
          fileName: string;
          fileUrl: string;
          mimeType: string;
          description?: string | null;
          locked: boolean;
          fileSize: number;
          status: AttachmentStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
            company: {
              __typename?: "Company";
              id: string;
              registeredName: string;
              tradingName: string;
            };
          };
        }> | null;
      }
    | { __typename?: "EventItem" }
    | {
        __typename?: "InstructionItem";
        id: string;
        title: string;
        severity: string;
        number: string;
        productInstanceId: string;
        ownerId: string;
        statusOptionId: string;
        dateCreated: any;
        dateModified: any;
        notifyDueDate: any;
        creatorId: string;
        offline: boolean;
        givenById?: string | null;
        dateSent: any;
        attachments?: Array<{
          __typename?: "Attachment";
          id: string;
          fileName: string;
          fileUrl: string;
          mimeType: string;
          description?: string | null;
          locked: boolean;
          fileSize: number;
          status: AttachmentStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
            company: {
              __typename?: "Company";
              id: string;
              registeredName: string;
              tradingName: string;
            };
          };
        }> | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          productSchema: {
            __typename?: "ProductSchema";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            isInternal: boolean;
            status: ProductSchemaStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaSections: {
              __typename?: "SchemaSectionList";
              items: Array<{
                __typename?: "SchemaSection";
                id: string;
                name: string;
                displayText: string;
                displayOrder: number;
                columnPlacement: ColumnPlacementType;
                productSchemaId: string;
                isInternal: boolean;
                status: SchemaSectionStatus;
                dateCreated: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaFields: {
                  __typename?: "SchemaFieldList";
                  items: Array<{
                    __typename?: "SchemaField";
                    id: string;
                    name: string;
                    schemaSectionId: string;
                    fieldTypeId: string;
                    displayText: string;
                    displayOrder: number;
                    isRequired: boolean;
                    isInternal: boolean;
                    complexTypeItemId?: string | null;
                    extraData?: string | null;
                    status: SchemaFieldStatus;
                    creatorId: string;
                    dateCreated: any;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    fieldType: {
                      __typename?: "FieldType";
                      id: string;
                      description: string;
                      shortCode: FieldTypeEnum;
                      status: FieldTypeStatus;
                    };
                  }>;
                };
              }>;
            };
          };
          contract: {
            __typename?: "Contract";
            id: string;
            contractTypeId: string;
            valueCurrency: string;
            timeZone: string;
          };
          product:
            | { __typename?: "Claims"; id: string; numberingFormat: string }
            | { __typename?: "CompEvents"; id: string; numberingFormat: string }
            | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                numberingFormat: string;
              }
            | { __typename?: "Events"; id: string; numberingFormat: string }
            | {
                __typename?: "Instructions";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "Variations";
                id: string;
                numberingFormat: string;
              };
        };
        data: {
          __typename?: "ItemData";
          sections: Array<{
            __typename?: "ItemDataSection";
            name: string;
            entries: Array<{
              __typename?: "ItemDataEntry";
              name: string;
              value: string;
            }>;
          }>;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        statusOption: {
          __typename?: "ItemStatusOption";
          id: string;
          displayOrder: number;
          description: string;
          equatesToRemoved: boolean;
          style: ItemStatusOptionStyles;
          statusCollectionId: string;
          isInternal: boolean;
          status: ItemStatusOptionStatus;
          creatorId: string;
          dateCreated: any;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
        owner: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }
    | { __typename?: "RiskItem" }
    | {
        __typename: "VariationItem";
        id: string;
        number: string;
        productInstanceId: string;
        regardingId: string;
        type: VariationType;
        priceSought?: number | null;
        priceGranted?: number | null;
        timeSought?: number | null;
        timeGranted?: number | null;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        voStatus: VariationStatus;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          status: ProductInstanceStatus;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
          };
        };
        regardingInstruction?: {
          __typename?: "InstructionItem";
          id: string;
          title: string;
          number: string;
          productInstanceId: string;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            product:
              | { __typename?: "Claims"; id: string }
              | { __typename?: "CompEvents"; id: string }
              | { __typename?: "DailyDiary"; id: string }
              | { __typename?: "EarlyWarnings"; id: string }
              | { __typename?: "Events"; id: string }
              | { __typename?: "Instructions"; id: string }
              | { __typename?: "RisksRegister"; id: string }
              | { __typename?: "Variations"; id: string };
          };
        } | null;
        voNextStep?: {
          __typename?: "VariationItemNextStep";
          actionType: VariationActionType;
          dueDate: any;
          partyId: string;
          party: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
        } | null;
        voLastAction: {
          __typename?: "VariationItemLastAction";
          actionType: VariationActionType;
          date: any;
          daysLate?: number | null;
          party: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
        };
      };
  auditTrails?: Array<{
    __typename?: "AuthorizationWorkflowAuditTrail";
    id: string;
    auditId: string;
    regardingProductItemId: string;
    regardingItemHistoryId?: string | null;
    regardingProductType: ProductType;
    productInstanceId: string;
    actionId: string;
    status: AuthorizationActionStatus;
    denialReason?: string | null;
    dateCreated: any;
    creatorId: string;
    productInstance?: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      soloModeSupported: boolean;
      contractId: string;
      productId: string;
      productSchemaId: string;
      statusCollectionId: string;
      status: ProductInstanceStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        number: string;
        valueCurrency: string;
        value: number;
        startDate?: any | null;
        endDate?: any | null;
        contractTypeId: string;
        projectId: string;
        country: string;
        province?: string | null;
        timeZone: string;
        coordinatesLatitude?: number | null;
        coordinatesLongitude?: number | null;
        status: ContractStatus;
        dateCreated: any;
        creatorId: string;
        sections: Array<{
          __typename?: "ContractSection";
          id: string;
          number?: number | null;
          description: string;
          completionDate: any;
          status: ContractSectionStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        keyDates: Array<{
          __typename?: "ContractKeyDate";
          id: string;
          number?: number | null;
          conditionToBeMet: string;
          keyDate: any;
          status: ContractKeyDateStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
        contractType: {
          __typename?: "ContractType";
          id: string;
          description: string;
          subType?: string | null;
          version?: string | null;
          status: ContractTypeStatus;
        };
      };
      product:
        | {
            __typename?: "Claims";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "CompEvents";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "DailyDiary";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "EarlyWarnings";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Events";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Instructions";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "RisksRegister";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Variations";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          };
      statusCollection: {
        __typename?: "ItemStatusCollection";
        id: string;
        name: string;
        isDefault: boolean;
        status: ItemStatusCollectionStatus;
      };
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }> | null;
};

export type AuthAuditDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AuthAuditDetailsQuery = {
  __typename?: "Query";
  authorizationWorkflowAudit?: {
    __typename?: "AuthorizationWorkflowAudit";
    id: string;
    initiatedById: string;
    regardingProductItemId: string;
    regardingProductType: ProductType;
    regardingItemHistoryId?: string | null;
    actionId: string;
    workflowId: string;
    status: AuthorizationWorkflowAuditStatus;
    dateCreated: any;
    dateInitiated: any;
    initiatedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    action: {
      __typename?: "AuthorizationWorkflowActionMapping";
      id: string;
      productOutputActionId: string;
      workflowId: string;
      partyId?: string | null;
      productInstanceId: string;
      status: AuthorizationWorkflowActionMappingStatus;
      dateCreated: any;
      creatorId: string;
      modifiedById: string;
      dateModified: any;
      productOutputAction: {
        __typename?: "ProductOutputAction";
        id: string;
        name: string;
        productId: string;
        isInternal: boolean;
        status: ProductOutputActionStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      party?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        contractId: string;
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
        contract: {
          __typename?: "Contract";
          id: string;
          friendlyName: string;
          timeZone: string;
          valueCurrency: string;
          project: { __typename?: "Project"; id: string; friendlyName: string };
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    regardingProductItem:
      | {
          __typename: "ClaimItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          advanceWarningId?: string | null;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          notifiedByPartyId: string;
          claimStatus: ClaimStatus;
          claimRegardingType: ClaimRegardingType;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            status: ProductInstanceStatus;
            product:
              | { __typename?: "Claims"; id: string; name: string }
              | { __typename?: "CompEvents"; id: string; name: string }
              | { __typename?: "DailyDiary"; id: string; name: string }
              | { __typename?: "EarlyWarnings"; id: string; name: string }
              | { __typename?: "Events"; id: string; name: string }
              | { __typename?: "Instructions"; id: string; name: string }
              | { __typename?: "RisksRegister"; id: string; name: string }
              | { __typename?: "Variations"; id: string; name: string };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
              timeZone: string;
              valueCurrency: string;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
          };
          advanceWarning?: {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            dateSent: any;
            number: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          notifiedByParty: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
          claimNextStep?: {
            __typename?: "ClaimItemNextStep";
            actionType: ClaimActionType;
            dueDate: any;
            partyId: string;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          } | null;
          claimLastAction: {
            __typename?: "ClaimItemLastAction";
            actionType: ClaimActionType;
            date: any;
            daysLate?: number | null;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          };
        }
      | {
          __typename: "CompEventItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          regardingType: CompEventRegardingType;
          status: CompEventStatus;
          price?: number | null;
          time?: number | null;
          earlyWarningId?: string | null;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          deemingDate?: any | null;
          lastAction: {
            __typename?: "CompEventItemLastAction";
            actionType: CompEventActionType;
            daysLate?: number | null;
            date: any;
          };
          nextStep?: {
            __typename?: "CompEventItemNextStep";
            actionType: CompEventActionType;
            dueDate: any;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            status: ProductInstanceStatus;
            product:
              | { __typename?: "Claims"; id: string; name: string }
              | { __typename?: "CompEvents"; id: string; name: string }
              | { __typename?: "DailyDiary"; id: string; name: string }
              | { __typename?: "EarlyWarnings"; id: string; name: string }
              | { __typename?: "Events"; id: string; name: string }
              | { __typename?: "Instructions"; id: string; name: string }
              | { __typename?: "RisksRegister"; id: string; name: string }
              | { __typename?: "Variations"; id: string; name: string };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
              timeZone: string;
              valueCurrency: string;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
          };
          earlyWarning?: {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            dateSent: any;
            number: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          notifiedByParty: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
        }
      | {
          __typename?: "DailyDiaryItem";
          id: string;
          date: any;
          number: string;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          sentById?: string | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              timeZone: string;
              number: string;
              status: ContractStatus;
              dateCreated: any;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
          };
        }
      | {
          __typename?: "EarlyWarningItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          creatorId: string;
          dateModified: any;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          owner: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            productSchema: {
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  displayOrder: number;
                  columnPlacement: ColumnPlacementType;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  dateCreated: any;
                  creatorId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      displayOrder: number;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      extraData?: string | null;
                      status: SchemaFieldStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            };
            contract: {
              __typename?: "Contract";
              id: string;
              valueCurrency: string;
              timeZone: string;
            };
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
          };
          attachments?: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }> | null;
        }
      | { __typename?: "EventItem" }
      | {
          __typename?: "InstructionItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          dateModified: any;
          notifyDueDate: any;
          creatorId: string;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
          attachments?: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }> | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            productSchema: {
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  displayOrder: number;
                  columnPlacement: ColumnPlacementType;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  dateCreated: any;
                  creatorId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      displayOrder: number;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      extraData?: string | null;
                      status: SchemaFieldStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            };
            contract: {
              __typename?: "Contract";
              id: string;
              contractTypeId: string;
              valueCurrency: string;
              timeZone: string;
            };
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
          };
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          statusOption: {
            __typename?: "ItemStatusOption";
            id: string;
            displayOrder: number;
            description: string;
            equatesToRemoved: boolean;
            style: ItemStatusOptionStyles;
            statusCollectionId: string;
            isInternal: boolean;
            status: ItemStatusOptionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
          owner: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }
      | { __typename?: "RiskItem" }
      | {
          __typename: "VariationItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          type: VariationType;
          priceSought?: number | null;
          priceGranted?: number | null;
          timeSought?: number | null;
          timeGranted?: number | null;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          voStatus: VariationStatus;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            status: ProductInstanceStatus;
            product:
              | { __typename?: "Claims"; id: string; name: string }
              | { __typename?: "CompEvents"; id: string; name: string }
              | { __typename?: "DailyDiary"; id: string; name: string }
              | { __typename?: "EarlyWarnings"; id: string; name: string }
              | { __typename?: "Events"; id: string; name: string }
              | { __typename?: "Instructions"; id: string; name: string }
              | { __typename?: "RisksRegister"; id: string; name: string }
              | { __typename?: "Variations"; id: string; name: string };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
              timeZone: string;
              valueCurrency: string;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
          };
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          voNextStep?: {
            __typename?: "VariationItemNextStep";
            actionType: VariationActionType;
            dueDate: any;
            partyId: string;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          } | null;
          voLastAction: {
            __typename?: "VariationItemLastAction";
            actionType: VariationActionType;
            date: any;
            daysLate?: number | null;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          };
        };
    auditTrails?: Array<{
      __typename?: "AuthorizationWorkflowAuditTrail";
      id: string;
      auditId: string;
      regardingProductItemId: string;
      regardingItemHistoryId?: string | null;
      regardingProductType: ProductType;
      productInstanceId: string;
      actionId: string;
      status: AuthorizationActionStatus;
      denialReason?: string | null;
      dateCreated: any;
      creatorId: string;
      productInstance?: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        contractId: string;
        productId: string;
        productSchemaId: string;
        statusCollectionId: string;
        status: ProductInstanceStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contract: {
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          number: string;
          valueCurrency: string;
          value: number;
          startDate?: any | null;
          endDate?: any | null;
          contractTypeId: string;
          projectId: string;
          country: string;
          province?: string | null;
          timeZone: string;
          coordinatesLatitude?: number | null;
          coordinatesLongitude?: number | null;
          status: ContractStatus;
          dateCreated: any;
          creatorId: string;
          sections: Array<{
            __typename?: "ContractSection";
            id: string;
            number?: number | null;
            description: string;
            completionDate: any;
            status: ContractSectionStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          keyDates: Array<{
            __typename?: "ContractKeyDate";
            id: string;
            number?: number | null;
            conditionToBeMet: string;
            keyDate: any;
            status: ContractKeyDateStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            status: ProjectStatus;
          };
          contractType: {
            __typename?: "ContractType";
            id: string;
            description: string;
            subType?: string | null;
            version?: string | null;
            status: ContractTypeStatus;
          };
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
        statusCollection: {
          __typename?: "ItemStatusCollection";
          id: string;
          name: string;
          isDefault: boolean;
          status: ItemStatusCollectionStatus;
        };
      } | null;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }> | null;
  } | null;
};

export type AuthAuditDetailsLiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AuthAuditDetailsLiteQuery = {
  __typename?: "Query";
  authorizationWorkflowAudit?: {
    __typename?: "AuthorizationWorkflowAudit";
    id: string;
    initiatedById: string;
    regardingProductItemId: string;
    regardingProductType: ProductType;
    regardingItemHistoryId?: string | null;
    actionId: string;
    status: AuthorizationWorkflowAuditStatus;
    dateCreated: any;
    dateInitiated: any;
    workflowId: string;
    initiatedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    auditTrails?: Array<{
      __typename?: "AuthorizationWorkflowAuditTrail";
      id: string;
      auditId: string;
      regardingProductItemId: string;
      regardingItemHistoryId?: string | null;
      regardingProductType: ProductType;
      productInstanceId: string;
      actionId: string;
      status: AuthorizationActionStatus;
      denialReason?: string | null;
      dateCreated: any;
      creatorId: string;
      productInstance?: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        contractId: string;
        productId: string;
        productSchemaId: string;
        statusCollectionId: string;
        status: ProductInstanceStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contract: {
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          number: string;
          valueCurrency: string;
          value: number;
          startDate?: any | null;
          endDate?: any | null;
          contractTypeId: string;
          projectId: string;
          country: string;
          province?: string | null;
          timeZone: string;
          coordinatesLatitude?: number | null;
          coordinatesLongitude?: number | null;
          status: ContractStatus;
          dateCreated: any;
          creatorId: string;
          sections: Array<{
            __typename?: "ContractSection";
            id: string;
            number?: number | null;
            description: string;
            completionDate: any;
            status: ContractSectionStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          keyDates: Array<{
            __typename?: "ContractKeyDate";
            id: string;
            number?: number | null;
            conditionToBeMet: string;
            keyDate: any;
            status: ContractKeyDateStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            status: ProjectStatus;
          };
          contractType: {
            __typename?: "ContractType";
            id: string;
            description: string;
            subType?: string | null;
            version?: string | null;
            status: ContractTypeStatus;
          };
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
        statusCollection: {
          __typename?: "ItemStatusCollection";
          id: string;
          name: string;
          isDefault: boolean;
          status: ItemStatusCollectionStatus;
        };
      } | null;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }> | null;
  } | null;
};

export type ClaimHistoryItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ClaimHistoryItemQuery = {
  __typename?: "Query";
  claimHistoryItem?: {
    __typename?: "ClaimHistoryItem";
    id: string;
    claimId: string;
    actionType: ClaimActionType;
    actionNumber: string;
    dateCreated: any;
    dateSent: any;
    dueDate?: any | null;
    sentByUserId: string;
    sentByPartyId?: string | null;
    offline: boolean;
    daysLate: number;
    isIncomplete?: boolean | null;
    answers?:
      | {
          __typename: "ClaimHistoryAgreementAnswers";
          agreementId?: string | null;
          draftAgreementId?: string | null;
          agreement?: {
            __typename?: "ClaimAgreement";
            id: string;
            claimId: string;
            reference: string;
            details: string;
            price: number;
            currency: string;
            time: number;
            dateCreated: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "DetailedClaimTimeChange";
              number?: number | null;
              description: string;
              days: number;
            }>;
          } | null;
          draftAgreement?: {
            __typename?: "DraftClaimAgreement";
            id: string;
            claimId: string;
            reference: string;
            details: string;
            price: number;
            time: number;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "ContractTimeImpact";
              id: string;
              days: number;
            } | null>;
          } | null;
        }
      | {
          __typename: "ClaimHistoryClaimDetailsResponseAnswers";
          details: string;
          detailedClaimId: string;
          detailedClaim: {
            __typename?: "DetailedClaim";
            id: string;
            claimId: string;
            reference: string;
            details: string;
            price: number;
            currency: string;
            time: number;
            dateCreated: any;
            creatorId: string;
            sectionalChanges: Array<{
              __typename?: "DetailedClaimTimeChange";
              number?: number | null;
              description: string;
              days: number;
            }>;
          };
        }
      | {
          __typename: "ClaimHistoryDeterminationAnswers";
          determinationId?: string | null;
          draftDeterminationId?: string | null;
          determination?: {
            __typename?: "ClaimDetermination";
            id: string;
            claimId: string;
            reference: string;
            details: string;
            price: number;
            currency: string;
            time: number;
            dateCreated: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "DetailedClaimTimeChange";
              number?: number | null;
              description: string;
              days: number;
            }>;
          } | null;
          draftDetermination?: {
            __typename?: "DraftClaimDetermination";
            id: string;
            claimId: string;
            reference: string;
            details: string;
            price: number;
            time: number;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "ContractTimeImpact";
              id: string;
              days: number;
            } | null>;
          } | null;
        }
      | {
          __typename: "ClaimHistoryErrorAgreementDisagreementAnswers";
          remarks: string;
        }
      | {
          __typename: "ClaimHistoryErrorAgreementNoticeAnswers";
          details: string;
        }
      | {
          __typename: "ClaimHistoryErrorDeterminationDisagreementAnswers";
          remarks: string;
        }
      | {
          __typename: "ClaimHistoryErrorDeterminationNoticeAnswers";
          details: string;
        }
      | {
          __typename: "ClaimHistoryFinalDetailsAnswers";
          detailedClaimId: string;
          detailedClaim: {
            __typename?: "DetailedClaim";
            id: string;
            claimId: string;
            reference: string;
            details: string;
            price: number;
            currency: string;
            time: number;
            dateCreated: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "DetailedClaimTimeChange";
              number?: number | null;
              description: string;
              days: number;
            }>;
          };
        }
      | {
          __typename: "ClaimHistoryFurtherParticularsRequestAnswers";
          reasons: string;
        }
      | {
          __typename: "ClaimHistoryFurtherParticularsSubmissionAnswers";
          details: string;
        }
      | {
          __typename: "ClaimHistoryInterimDetailsAnswers";
          detailedClaimId: string;
          detailedClaim: {
            __typename?: "DetailedClaim";
            id: string;
            claimId: string;
            reference: string;
            details: string;
            price: number;
            currency: string;
            time: number;
            dateCreated: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "DetailedClaimTimeChange";
              number?: number | null;
              description: string;
              days: number;
            }>;
          };
        }
      | { __typename: "ClaimHistoryNoAgreementReachedAnswers"; remarks: string }
      | {
          __typename: "ClaimHistoryNoticeAnswers";
          claimTypeId: string;
          advanceWarningId?: string | null;
          claimType: {
            __typename?: "ClaimTypeInfo";
            id: string;
            clause: string;
            description: string;
          };
          advanceWarning?: {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            creatorId: string;
            dateModified: any;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
          } | null;
        }
      | {
          __typename: "ClaimHistoryNoticeInvalidNoticeAnswers";
          reasons: string;
        }
      | {
          __typename: "ClaimHistoryNoticeValidityLapsedAnswers";
          remarks: string;
        }
      | null;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        email: boolean;
      };
    }>;
    authorizationWorkflowAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      workflowId: string;
      actionId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      dateInitiated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      regardingProductItem:
        | {
            __typename: "ClaimItem";
            id: string;
            number: string;
            regardingId: string;
            claimRegardingType: ClaimRegardingType;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename: "CompEventItem";
            id: string;
            number: string;
            productInstanceId: string;
            regardingId: string;
            regardingType: CompEventRegardingType;
            status: CompEventStatus;
            price?: number | null;
            time?: number | null;
            earlyWarningId?: string | null;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            deemingDate?: any | null;
            lastAction: {
              __typename?: "CompEventItemLastAction";
              actionType: CompEventActionType;
              daysLate?: number | null;
              date: any;
            };
            nextStep?: {
              __typename?: "CompEventItemNextStep";
              actionType: CompEventActionType;
              dueDate: any;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              status: ProductInstanceStatus;
              product:
                | { __typename?: "Claims"; id: string; name: string }
                | { __typename?: "CompEvents"; id: string; name: string }
                | { __typename?: "DailyDiary"; id: string; name: string }
                | { __typename?: "EarlyWarnings"; id: string; name: string }
                | { __typename?: "Events"; id: string; name: string }
                | { __typename?: "Instructions"; id: string; name: string }
                | { __typename?: "RisksRegister"; id: string; name: string }
                | { __typename?: "Variations"; id: string; name: string };
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
                timeZone: string;
                valueCurrency: string;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
            };
            earlyWarning?: {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              dateSent: any;
              number: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingEvent?: {
              __typename?: "EventItem";
              id: string;
              title: string;
              productInstanceId: string;
              number: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingInstruction?: {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              number: string;
              productInstanceId: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            notifiedByParty: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          }
        | {
            __typename?: "DailyDiaryItem";
            id: string;
            date: any;
            number: string;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            sentById?: string | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              status: ProductInstanceStatus;
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                timeZone: string;
                number: string;
                status: ContractStatus;
                dateCreated: any;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
              product:
                | {
                    __typename?: "Claims";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Events";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Instructions";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  };
            };
          }
        | {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            creatorId: string;
            dateModified: any;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
          }
        | { __typename?: "EventItem" }
        | {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            dateModified: any;
            notifyDueDate: any;
            creatorId: string;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                contractTypeId: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            statusOption: {
              __typename?: "ItemStatusOption";
              id: string;
              displayOrder: number;
              description: string;
              equatesToRemoved: boolean;
              style: ItemStatusOptionStyles;
              statusCollectionId: string;
              isInternal: boolean;
              status: ItemStatusOptionStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }
        | { __typename?: "RiskItem" }
        | {
            __typename: "VariationItem";
            id: string;
            number: string;
            regardingId: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    } | null;
    sentByUser: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    sentByParty?: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    } | null;
  } | null;
};

export type CompEventHistoryItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CompEventHistoryItemQuery = {
  __typename?: "Query";
  compEventHistoryItem?: {
    __typename?: "CompEventHistoryItem";
    id: string;
    compEventId: string;
    actionType: CompEventActionType;
    actionNumber: string;
    isIncomplete?: boolean | null;
    dateSent: any;
    dateCreated: any;
    dueDate?: any | null;
    sentByUserId: string;
    sentByPartyId?: string | null;
    offline: boolean;
    daysLate: number;
    authorizationWorkflowAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      workflowId: string;
      actionId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      dateInitiated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      regardingProductItem:
        | {
            __typename: "ClaimItem";
            id: string;
            number: string;
            regardingId: string;
            claimRegardingType: ClaimRegardingType;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename: "CompEventItem";
            id: string;
            number: string;
            productInstanceId: string;
            regardingId: string;
            regardingType: CompEventRegardingType;
            status: CompEventStatus;
            price?: number | null;
            time?: number | null;
            earlyWarningId?: string | null;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            deemingDate?: any | null;
            lastAction: {
              __typename?: "CompEventItemLastAction";
              actionType: CompEventActionType;
              daysLate?: number | null;
              date: any;
            };
            nextStep?: {
              __typename?: "CompEventItemNextStep";
              actionType: CompEventActionType;
              dueDate: any;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              status: ProductInstanceStatus;
              product:
                | { __typename?: "Claims"; id: string; name: string }
                | { __typename?: "CompEvents"; id: string; name: string }
                | { __typename?: "DailyDiary"; id: string; name: string }
                | { __typename?: "EarlyWarnings"; id: string; name: string }
                | { __typename?: "Events"; id: string; name: string }
                | { __typename?: "Instructions"; id: string; name: string }
                | { __typename?: "RisksRegister"; id: string; name: string }
                | { __typename?: "Variations"; id: string; name: string };
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
                timeZone: string;
                valueCurrency: string;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
            };
            earlyWarning?: {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              dateSent: any;
              number: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingEvent?: {
              __typename?: "EventItem";
              id: string;
              title: string;
              productInstanceId: string;
              number: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingInstruction?: {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              number: string;
              productInstanceId: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            notifiedByParty: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          }
        | {
            __typename?: "DailyDiaryItem";
            id: string;
            date: any;
            number: string;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            sentById?: string | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              status: ProductInstanceStatus;
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                timeZone: string;
                number: string;
                status: ContractStatus;
                dateCreated: any;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
              product:
                | {
                    __typename?: "Claims";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Events";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Instructions";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  };
            };
          }
        | {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            creatorId: string;
            dateModified: any;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
          }
        | { __typename?: "EventItem" }
        | {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            dateModified: any;
            notifyDueDate: any;
            creatorId: string;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                contractTypeId: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            statusOption: {
              __typename?: "ItemStatusOption";
              id: string;
              displayOrder: number;
              description: string;
              equatesToRemoved: boolean;
              style: ItemStatusOptionStyles;
              statusCollectionId: string;
              isInternal: boolean;
              status: ItemStatusOptionStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }
        | { __typename?: "RiskItem" }
        | {
            __typename: "VariationItem";
            id: string;
            number: string;
            regardingId: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    } | null;
    noticeAnswers?: {
      __typename?: "CompEventHistoryNoticeAnswers";
      claimTypeId: string;
      earlyWarningId?: string | null;
      claimType: {
        __typename?: "ClaimTypeInfo";
        id: string;
        clause: string;
        description: string;
      };
      earlyWarning?: {
        __typename?: "EarlyWarningItem";
        id: string;
        title: string;
        severity: string;
        number: string;
        productInstanceId: string;
        ownerId: string;
        statusOptionId: string;
        dateCreated: any;
        creatorId: string;
        dateModified: any;
        offline: boolean;
        givenById?: string | null;
        dateSent: any;
      } | null;
    } | null;
    confirmationAnswers?: {
      __typename?: "CompEventHistoryConfirmationAnswers";
      assumptions?: string | null;
      ewCouldveBeenGiven: boolean;
    } | null;
    rejectionAnswers?: {
      __typename?: "CompEventHistoryRejectionAnswers";
      reason: string;
    } | null;
    noticeRequestQuotationAnswers?: {
      __typename?: "CompEventHistoryNoticeRequestQuotationAnswers";
      claimTypeId: string;
      assumptions?: string | null;
      claimType: {
        __typename?: "ClaimTypeInfo";
        id: string;
        clause: string;
        description: string;
      };
    } | null;
    quotationRevisionRequestAnswers?: {
      __typename?: "CompEventHistoryQuotationRevisionRequestAnswers";
      reason: string;
    } | null;
    quotationAcceptanceAnswers?: {
      __typename?: "CompEventHistoryQuotationAcceptanceAnswers";
      acceptedQuotationId: string;
      acceptedQuotation: {
        __typename?: "CompEventQuotation";
        id: string;
        compEventId: string;
        reference: string;
        details: string;
        price: number;
        currency: string;
        time: number;
        accepted?: boolean | null;
        revision: number;
        dateCreated: any;
        creatorId: string;
        keyDatesChanges: Array<{
          __typename?: "CompEventQuotationTimeChange";
          number?: number | null;
          description: string;
          days: number;
        }>;
        sectionalChanges: Array<{
          __typename?: "CompEventQuotationTimeChange";
          number?: number | null;
          description: string;
          days: number;
        }>;
        attachments: Array<{
          __typename?: "Attachment";
          id: string;
          fileName: string;
          fileUrl: string;
          mimeType: string;
          description?: string | null;
          locked: boolean;
          fileSize: number;
          status: AttachmentStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            company: { __typename?: "Company"; registeredName: string };
          };
        }>;
      };
    } | null;
    noticeOfSilenceCompEventNoticeAnswers?: {
      __typename?: "CompEventHistoryNoticeOfSilenceCompEventNoticeAnswers";
      remarks?: string | null;
      deemedAcceptedDate: any;
    } | null;
    noticeOfSilenceQuotationAnswers?: {
      __typename?: "CompEventHistoryNoticeOfSilenceQuotationAnswers";
      acceptedQuotationId: string;
      remarks?: string | null;
      deemedAcceptedDate: any;
      acceptedQuotation: {
        __typename?: "CompEventQuotation";
        id: string;
        compEventId: string;
        reference: string;
        details: string;
        price: number;
        currency: string;
        time: number;
        accepted?: boolean | null;
        revision: number;
        dateCreated: any;
        creatorId: string;
        keyDatesChanges: Array<{
          __typename?: "CompEventQuotationTimeChange";
          number?: number | null;
          description: string;
          days: number;
        }>;
        sectionalChanges: Array<{
          __typename?: "CompEventQuotationTimeChange";
          number?: number | null;
          description: string;
          days: number;
        }>;
        attachments: Array<{
          __typename?: "Attachment";
          id: string;
          fileName: string;
          fileUrl: string;
          mimeType: string;
          description?: string | null;
          locked: boolean;
          fileSize: number;
          status: AttachmentStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            company: { __typename?: "Company"; registeredName: string };
          };
        }>;
      };
    } | null;
    noticeOfSilenceOwnAssessmentAnswers?: {
      __typename?: "CompEventHistoryNoticeOfSilenceOwnAssessmentAnswers";
      acceptedQuotationId: string;
      remarks?: string | null;
      deemedAcceptedDate: any;
      acceptedQuotation: {
        __typename?: "CompEventQuotation";
        id: string;
        compEventId: string;
        reference: string;
        details: string;
        price: number;
        currency: string;
        time: number;
        accepted?: boolean | null;
        revision: number;
        dateCreated: any;
        creatorId: string;
        keyDatesChanges: Array<{
          __typename?: "CompEventQuotationTimeChange";
          number?: number | null;
          description: string;
          days: number;
        }>;
        sectionalChanges: Array<{
          __typename?: "CompEventQuotationTimeChange";
          number?: number | null;
          description: string;
          days: number;
        }>;
        attachments: Array<{
          __typename?: "Attachment";
          id: string;
          fileName: string;
          fileUrl: string;
          mimeType: string;
          description?: string | null;
          locked: boolean;
          fileSize: number;
          status: AttachmentStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            company: { __typename?: "Company"; registeredName: string };
          };
        }>;
      };
    } | null;
    noticeProposedInstructionAnswers?: {
      __typename?: "CompEventHistoryNoticeProposedInstructionAnswers";
      claimTypeId: string;
      assumptions?: string | null;
      claimType: {
        __typename?: "ClaimTypeInfo";
        id: string;
        clause: string;
        description: string;
      };
    } | null;
    abandonInstructionAnswers?: {
      __typename?: "CompEventHistoryAbandonInstructionAnswers";
      remarks: string;
    } | null;
    quotationAnswers?: {
      __typename?: "CompEventHistoryQuotationAnswers";
      preferredQuotationId: string;
      preferredQuotation: {
        __typename?: "CompEventQuotation";
        id: string;
        compEventId: string;
        reference: string;
        details: string;
        price: number;
        currency: string;
        time: number;
        accepted?: boolean | null;
        revision: number;
        dateCreated: any;
        creatorId: string;
        keyDatesChanges: Array<{
          __typename?: "CompEventQuotationTimeChange";
          number?: number | null;
          description: string;
          days: number;
        }>;
        sectionalChanges: Array<{
          __typename?: "CompEventQuotationTimeChange";
          number?: number | null;
          description: string;
          days: number;
        }>;
        attachments: Array<{
          __typename?: "Attachment";
          id: string;
          fileName: string;
          fileUrl: string;
          mimeType: string;
          description?: string | null;
          locked: boolean;
          fileSize: number;
          status: AttachmentStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            company: { __typename?: "Company"; registeredName: string };
          };
        }>;
      };
    } | null;
    ownAssessmentNoticeAnswers?: {
      __typename?: "CompEventHistoryOwnAssessmentNoticeAnswers";
      reason: string;
    } | null;
    ownAssessmentAnswers?: {
      __typename?: "CompEventHistoryOwnAssessmentAnswers";
      draftAssessmentId?: string | null;
      assessmentId?: string | null;
      draftAssessment?: {
        __typename?: "DraftAssessment";
        id: string;
        compEventId: string;
        reference: string;
        details: string;
        price: number;
        time: number;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        keyDatesChanges: Array<{
          __typename?: "ContractTimeImpact";
          id: string;
          days: number;
        } | null>;
        sectionalChanges: Array<{
          __typename?: "ContractTimeImpact";
          id: string;
          days: number;
        } | null>;
        attachments: Array<{
          __typename?: "Attachment";
          id: string;
          fileName: string;
          fileUrl: string;
          mimeType: string;
          description?: string | null;
          locked: boolean;
          fileSize: number;
          status: AttachmentStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
            company: {
              __typename?: "Company";
              id: string;
              registeredName: string;
              tradingName: string;
            };
          };
        }>;
      } | null;
      assessment?: {
        __typename?: "CompEventAssessment";
        id: string;
        compEventId: string;
        reference: string;
        details: string;
        price: number;
        currency: string;
        time: number;
        revision: number;
        dateCreated: any;
        creatorId: string;
        keyDatesChanges: Array<{
          __typename?: "CompEventQuotationTimeChange";
          number?: number | null;
          description: string;
          days: number;
        }>;
        sectionalChanges: Array<{
          __typename?: "CompEventQuotationTimeChange";
          number?: number | null;
          description: string;
          days: number;
        }>;
        attachments: Array<{
          __typename?: "Attachment";
          id: string;
          fileName: string;
          fileUrl: string;
          mimeType: string;
          description?: string | null;
          locked: boolean;
          fileSize: number;
          status: AttachmentStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            company: { __typename?: "Company"; registeredName: string };
          };
        }>;
      } | null;
    } | null;
    requestForMoreTimeAnswers?: {
      __typename?: "CompEventHistoryRequestForMoreTimeAnswers";
      requestedDays: number;
      remarks?: string | null;
    } | null;
    grantingOfMoreTimeAnswers?: {
      __typename?: "CompEventHistoryGrantingOfMoreTimeAnswers";
      grantedDays: number;
      remarks?: string | null;
    } | null;
    denyingOfMoreTimeAnswers?: {
      __typename?: "CompEventHistoryDenyingOfMoreTimeAnswers";
      remarks?: string | null;
    } | null;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        email: boolean;
      };
    }>;
    sentByUser: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    sentByParty?: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    } | null;
  } | null;
};

export type VariationHistoryItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type VariationHistoryItemQuery = {
  __typename?: "Query";
  variationHistoryItem?: {
    __typename?: "VariationHistoryItem";
    id: string;
    variationId: string;
    actionType: VariationActionType;
    actionNumber: string;
    dateCreated: any;
    dateSent: any;
    dueDate?: any | null;
    sentByUserId: string;
    sentByPartyId?: string | null;
    offline: boolean;
    daysLate: number;
    isIncomplete?: boolean | null;
    answers?:
      | { __typename: "VariationHistoryAbandonmentAnswers"; remarks: string }
      | {
          __typename: "VariationHistoryAgreementAnswers";
          draftAgreementId?: string | null;
          agreement?: {
            __typename?: "VariationAgreement";
            id: string;
            variationId: string;
            reference: string;
            details: string;
            price: number;
            currency: string;
            time: number;
            dateCreated: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "VariationProposalTimeChange";
              number?: number | null;
              description: string;
              days: number;
            }>;
          } | null;
          draftAgreement?: {
            __typename?: "DraftVariationAgreement";
            id: string;
            variationId: string;
            reference: string;
            details: string;
            price: number;
            time: number;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "ContractTimeImpact";
              id: string;
              days: number;
            } | null>;
          } | null;
        }
      | {
          __typename: "VariationHistoryConfirmationVariationAnswers";
          remarks: string;
        }
      | {
          __typename: "VariationHistoryConsentForProposedVariationAnswers";
          remarks: string;
          proposalId: string;
          proposal: {
            __typename?: "VariationProposal";
            id: string;
            variationId: string;
            reference: string;
            details: string;
            price: number;
            currency: string;
            consented: boolean;
            time: number;
            dateCreated: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "VariationProposalTimeChange";
              number?: number | null;
              description: string;
              days: number;
            }>;
          };
        }
      | {
          __typename: "VariationHistoryDeterminationAnswers";
          draftDeterminationId?: string | null;
          determination?: {
            __typename?: "VariationDetermination";
            id: string;
            variationId: string;
            reference: string;
            details: string;
            price: number;
            currency: string;
            time: number;
            dateCreated: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "VariationProposalTimeChange";
              number?: number | null;
              description: string;
              days: number;
            }>;
          } | null;
          draftDetermination?: {
            __typename?: "DraftVariationDetermination";
            id: string;
            variationId: string;
            reference: string;
            details: string;
            price: number;
            time: number;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "ContractTimeImpact";
              id: string;
              days: number;
            } | null>;
          } | null;
        }
      | {
          __typename: "VariationHistoryErrorAgreementDisagreementAnswers";
          remarks: string;
        }
      | {
          __typename: "VariationHistoryErrorAgreementNoticeAnswers";
          details: string;
        }
      | {
          __typename: "VariationHistoryErrorDeterminationDisagreementAnswers";
          remarks: string;
        }
      | {
          __typename: "VariationHistoryErrorDeterminationNoticeAnswers";
          details: string;
        }
      | {
          __typename: "VariationHistoryFurtherParticularsRequestAnswers";
          reasons: string;
        }
      | {
          __typename: "VariationHistoryFurtherParticularsSubmissionAnswers";
          details: string;
        }
      | {
          __typename: "VariationHistoryInstructionCommenceWorkAnswers";
          remarks: string;
          agreementSubmitted: boolean;
        }
      | {
          __typename: "VariationHistoryNoAgreementReachedAnswers";
          remarks: string;
        }
      | {
          __typename: "VariationHistoryObjectionProposedVariationAnswers";
          reasons: string;
        }
      | {
          __typename: "VariationHistoryObjectionVariationAnswers";
          reasons: string;
        }
      | {
          __typename: "VariationHistoryProposalForProposedVariationAnswers";
          proposalId: string;
          proposal: {
            __typename?: "VariationProposal";
            id: string;
            variationId: string;
            reference: string;
            details: string;
            price: number;
            currency: string;
            consented: boolean;
            time: number;
            dateCreated: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "VariationProposalTimeChange";
              number?: number | null;
              description: string;
              days: number;
            }>;
          };
        }
      | {
          __typename: "VariationHistoryProposalForVariationAnswers";
          proposalId: string;
          proposal: {
            __typename?: "VariationProposal";
            id: string;
            variationId: string;
            reference: string;
            details: string;
            price: number;
            currency: string;
            consented: boolean;
            time: number;
            dateCreated: any;
            creatorId: string;
            attachments: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }>;
            sectionalChanges: Array<{
              __typename?: "VariationProposalTimeChange";
              number?: number | null;
              description: string;
              days: number;
            }>;
          };
        }
      | {
          __typename: "VariationHistoryRevisedProposalRequestAnswers";
          remarks: string;
        }
      | {
          __typename: "VariationHistoryRevocationVariationAnswers";
          remarks: string;
        }
      | null;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        email: boolean;
      };
    }>;
    authorizationWorkflowAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      workflowId: string;
      actionId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      dateInitiated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      regardingProductItem:
        | {
            __typename: "ClaimItem";
            id: string;
            number: string;
            regardingId: string;
            claimRegardingType: ClaimRegardingType;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename: "CompEventItem";
            id: string;
            number: string;
            productInstanceId: string;
            regardingId: string;
            regardingType: CompEventRegardingType;
            status: CompEventStatus;
            price?: number | null;
            time?: number | null;
            earlyWarningId?: string | null;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            deemingDate?: any | null;
            lastAction: {
              __typename?: "CompEventItemLastAction";
              actionType: CompEventActionType;
              daysLate?: number | null;
              date: any;
            };
            nextStep?: {
              __typename?: "CompEventItemNextStep";
              actionType: CompEventActionType;
              dueDate: any;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              status: ProductInstanceStatus;
              product:
                | { __typename?: "Claims"; id: string; name: string }
                | { __typename?: "CompEvents"; id: string; name: string }
                | { __typename?: "DailyDiary"; id: string; name: string }
                | { __typename?: "EarlyWarnings"; id: string; name: string }
                | { __typename?: "Events"; id: string; name: string }
                | { __typename?: "Instructions"; id: string; name: string }
                | { __typename?: "RisksRegister"; id: string; name: string }
                | { __typename?: "Variations"; id: string; name: string };
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
                timeZone: string;
                valueCurrency: string;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
            };
            earlyWarning?: {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              dateSent: any;
              number: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingEvent?: {
              __typename?: "EventItem";
              id: string;
              title: string;
              productInstanceId: string;
              number: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingInstruction?: {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              number: string;
              productInstanceId: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            notifiedByParty: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          }
        | {
            __typename?: "DailyDiaryItem";
            id: string;
            date: any;
            number: string;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            sentById?: string | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              status: ProductInstanceStatus;
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                timeZone: string;
                number: string;
                status: ContractStatus;
                dateCreated: any;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
              product:
                | {
                    __typename?: "Claims";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Events";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Instructions";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  };
            };
          }
        | {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            creatorId: string;
            dateModified: any;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
          }
        | { __typename?: "EventItem" }
        | {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            dateModified: any;
            notifyDueDate: any;
            creatorId: string;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                contractTypeId: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            statusOption: {
              __typename?: "ItemStatusOption";
              id: string;
              displayOrder: number;
              description: string;
              equatesToRemoved: boolean;
              style: ItemStatusOptionStyles;
              statusCollectionId: string;
              isInternal: boolean;
              status: ItemStatusOptionStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }
        | { __typename?: "RiskItem" }
        | {
            __typename: "VariationItem";
            id: string;
            number: string;
            regardingId: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    } | null;
    sentByUser: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    sentByParty?: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    } | null;
  } | null;
};

export type LocalProductInstanceFieldsFragment = {
  __typename?: "ProductInstance";
  id: string;
  description: string;
  status: ProductInstanceStatus;
  contract: {
    __typename?: "Contract";
    id: string;
    friendlyName: string;
    timeZone: string;
    project: { __typename?: "Project"; id: string; friendlyName: string };
    contractType: {
      __typename?: "ContractType";
      id: string;
      description: string;
      subType?: string | null;
      version?: string | null;
      status: ContractTypeStatus;
    };
  };
};

export type LocalRegardingEventFieldsFragment = {
  __typename?: "EventItem";
  id: string;
  title: string;
  productInstanceId: string;
  number: string;
  data: {
    __typename?: "ItemData";
    sections: Array<{
      __typename?: "ItemDataSection";
      name: string;
      entries: Array<{
        __typename?: "ItemDataEntry";
        name: string;
        value: string;
      }>;
    }>;
  };
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    product:
      | { __typename?: "Claims"; id: string }
      | { __typename?: "CompEvents"; id: string }
      | { __typename?: "DailyDiary"; id: string }
      | { __typename?: "EarlyWarnings"; id: string }
      | { __typename?: "Events"; id: string }
      | { __typename?: "Instructions"; id: string }
      | { __typename?: "RisksRegister"; id: string }
      | { __typename?: "Variations"; id: string };
  };
};

export type LocalRegardingInstructionFieldsFragment = {
  __typename?: "InstructionItem";
  id: string;
  title: string;
  number: string;
  productInstanceId: string;
  data: {
    __typename?: "ItemData";
    sections: Array<{
      __typename?: "ItemDataSection";
      name: string;
      entries: Array<{
        __typename?: "ItemDataEntry";
        name: string;
        value: string;
      }>;
    }>;
  };
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    product:
      | { __typename?: "Claims"; id: string }
      | { __typename?: "CompEvents"; id: string }
      | { __typename?: "DailyDiary"; id: string }
      | { __typename?: "EarlyWarnings"; id: string }
      | { __typename?: "Events"; id: string }
      | { __typename?: "Instructions"; id: string }
      | { __typename?: "RisksRegister"; id: string }
      | { __typename?: "Variations"; id: string };
  };
};

export type NextStepsCompEventItemFieldsFragment = {
  __typename?: "CompEventItem";
  id: string;
  number: string;
  productInstanceId: string;
  regardingId: string;
  deemingDate?: any | null;
  ceRegardingType: CompEventRegardingType;
  nextStep?: {
    __typename?: "CompEventItemNextStep";
    dueDate: any;
    ceActionType: CompEventActionType;
    party: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
    };
  } | null;
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    status: ProductInstanceStatus;
    contract: {
      __typename?: "Contract";
      id: string;
      friendlyName: string;
      timeZone: string;
      project: { __typename?: "Project"; id: string; friendlyName: string };
      contractType: {
        __typename?: "ContractType";
        id: string;
        description: string;
        subType?: string | null;
        version?: string | null;
        status: ContractTypeStatus;
      };
    };
  };
  regardingEvent?: {
    __typename?: "EventItem";
    id: string;
    title: string;
    productInstanceId: string;
    number: string;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
  regardingInstruction?: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
};

export type NextStepsVariationItemFieldsFragment = {
  __typename?: "VariationItem";
  id: string;
  number: string;
  productInstanceId: string;
  regardingId: string;
  nextStep?: {
    __typename?: "VariationItemNextStep";
    dueDate: any;
    voActionType: VariationActionType;
    party: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
    };
  } | null;
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    status: ProductInstanceStatus;
    contract: {
      __typename?: "Contract";
      id: string;
      friendlyName: string;
      timeZone: string;
      project: { __typename?: "Project"; id: string; friendlyName: string };
      contractType: {
        __typename?: "ContractType";
        id: string;
        description: string;
        subType?: string | null;
        version?: string | null;
        status: ContractTypeStatus;
      };
    };
  };
  regardingInstruction?: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
};

export type NextStepsClaimItemFieldsFragment = {
  __typename?: "ClaimItem";
  id: string;
  number: string;
  productInstanceId: string;
  regardingId: string;
  claimRegardingType: ClaimRegardingType;
  nextStep?: {
    __typename?: "ClaimItemNextStep";
    dueDate: any;
    claimActionType: ClaimActionType;
    party: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
    };
  } | null;
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    status: ProductInstanceStatus;
    contract: {
      __typename?: "Contract";
      id: string;
      friendlyName: string;
      timeZone: string;
      project: { __typename?: "Project"; id: string; friendlyName: string };
      contractType: {
        __typename?: "ContractType";
        id: string;
        description: string;
        subType?: string | null;
        version?: string | null;
        status: ContractTypeStatus;
      };
    };
  };
  regardingEvent?: {
    __typename?: "EventItem";
    id: string;
    title: string;
    productInstanceId: string;
    number: string;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
  regardingInstruction?: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
};

export type ProductItemNextStepsAllQueryVariables = Exact<{
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type ProductItemNextStepsAllQuery = {
  __typename?: "Query";
  productItemNextStepsAll: {
    __typename?: "ProductItemList";
    nextToken?: string | null;
    items: Array<
      | {
          __typename?: "ClaimItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          claimRegardingType: ClaimRegardingType;
          nextStep?: {
            __typename?: "ClaimItemNextStep";
            dueDate: any;
            claimActionType: ClaimActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
      | {
          __typename?: "CompEventItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          deemingDate?: any | null;
          ceRegardingType: CompEventRegardingType;
          nextStep?: {
            __typename?: "CompEventItemNextStep";
            dueDate: any;
            ceActionType: CompEventActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
      | { __typename?: "DailyDiaryItem" }
      | { __typename?: "EarlyWarningItem" }
      | { __typename?: "EventItem" }
      | { __typename?: "InstructionItem" }
      | { __typename?: "RiskItem" }
      | {
          __typename?: "VariationItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          nextStep?: {
            __typename?: "VariationItemNextStep";
            dueDate: any;
            voActionType: VariationActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
    >;
  };
};

export type ProductItemNextStepsYourPartyQueryVariables = Exact<{
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type ProductItemNextStepsYourPartyQuery = {
  __typename?: "Query";
  productItemNextStepsYourParty: {
    __typename?: "ProductItemList";
    nextToken?: string | null;
    items: Array<
      | {
          __typename?: "ClaimItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          claimRegardingType: ClaimRegardingType;
          nextStep?: {
            __typename?: "ClaimItemNextStep";
            dueDate: any;
            claimActionType: ClaimActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
      | {
          __typename?: "CompEventItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          deemingDate?: any | null;
          ceRegardingType: CompEventRegardingType;
          nextStep?: {
            __typename?: "CompEventItemNextStep";
            dueDate: any;
            ceActionType: CompEventActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
      | { __typename?: "DailyDiaryItem" }
      | { __typename?: "EarlyWarningItem" }
      | { __typename?: "EventItem" }
      | { __typename?: "InstructionItem" }
      | { __typename?: "RiskItem" }
      | {
          __typename?: "VariationItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          nextStep?: {
            __typename?: "VariationItemNextStep";
            dueDate: any;
            voActionType: VariationActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
    >;
  };
};

export type ProductItemNextStepsOtherPartiesQueryVariables = Exact<{
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type ProductItemNextStepsOtherPartiesQuery = {
  __typename?: "Query";
  productItemNextStepsOtherParties: {
    __typename?: "ProductItemList";
    nextToken?: string | null;
    items: Array<
      | {
          __typename?: "ClaimItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          claimRegardingType: ClaimRegardingType;
          nextStep?: {
            __typename?: "ClaimItemNextStep";
            dueDate: any;
            claimActionType: ClaimActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
      | {
          __typename?: "CompEventItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          deemingDate?: any | null;
          ceRegardingType: CompEventRegardingType;
          nextStep?: {
            __typename?: "CompEventItemNextStep";
            dueDate: any;
            ceActionType: CompEventActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
      | { __typename?: "DailyDiaryItem" }
      | { __typename?: "EarlyWarningItem" }
      | { __typename?: "EventItem" }
      | { __typename?: "InstructionItem" }
      | { __typename?: "RiskItem" }
      | {
          __typename?: "VariationItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          nextStep?: {
            __typename?: "VariationItemNextStep";
            dueDate: any;
            voActionType: VariationActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
    >;
  };
};

export type ProductItemNextStepsDeemingQueryVariables = Exact<{
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type ProductItemNextStepsDeemingQuery = {
  __typename?: "Query";
  productItemNextStepsDeeming: {
    __typename?: "ProductItemList";
    nextToken?: string | null;
    items: Array<
      | {
          __typename?: "ClaimItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          claimRegardingType: ClaimRegardingType;
          nextStep?: {
            __typename?: "ClaimItemNextStep";
            dueDate: any;
            claimActionType: ClaimActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
      | {
          __typename?: "CompEventItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          deemingDate?: any | null;
          ceRegardingType: CompEventRegardingType;
          nextStep?: {
            __typename?: "CompEventItemNextStep";
            dueDate: any;
            ceActionType: CompEventActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
      | { __typename?: "DailyDiaryItem" }
      | { __typename?: "EarlyWarningItem" }
      | { __typename?: "EventItem" }
      | { __typename?: "InstructionItem" }
      | { __typename?: "RiskItem" }
      | {
          __typename?: "VariationItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          nextStep?: {
            __typename?: "VariationItemNextStep";
            dueDate: any;
            voActionType: VariationActionType;
            party: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
            };
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
          };
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
        }
    >;
  };
};

export type RiskItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type RiskItemsQuery = {
  __typename?: "Query";
  riskItems: {
    __typename?: "RiskItemList";
    items: Array<{
      __typename?: "RiskItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      ownerId: string;
      statusOptionId: string;
      severity: string;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        contract: {
          __typename?: "Contract";
          id: string;
          valueCurrency: string;
          timeZone: string;
        };
      };
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      statusOption: {
        __typename?: "ItemStatusOption";
        id: string;
        displayOrder: number;
        description: string;
        equatesToRemoved: boolean;
        style: ItemStatusOptionStyles;
        statusCollectionId: string;
        isInternal: boolean;
        status: ItemStatusOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      owner: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type GetProductInstanceStatusCollectionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetProductInstanceStatusCollectionQuery = {
  __typename?: "Query";
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    product:
      | { __typename?: "Claims"; id: string; name: string }
      | { __typename?: "CompEvents"; id: string; name: string }
      | { __typename?: "DailyDiary"; id: string; name: string }
      | { __typename?: "EarlyWarnings"; id: string; name: string }
      | { __typename?: "Events"; id: string; name: string }
      | { __typename?: "Instructions"; id: string; name: string }
      | { __typename?: "RisksRegister"; id: string; name: string }
      | { __typename?: "Variations"; id: string; name: string };
    statusCollection: {
      __typename?: "ItemStatusCollection";
      id: string;
      name: string;
      status: ItemStatusCollectionStatus;
      statusOptions: {
        __typename?: "ItemStatusOptionList";
        items: Array<{
          __typename?: "ItemStatusOption";
          id: string;
          displayOrder: number;
          description: string;
          equatesToRemoved: boolean;
          style: ItemStatusOptionStyles;
          statusCollectionId: string;
          isInternal: boolean;
          status: ItemStatusOptionStatus;
          creatorId: string;
          dateCreated: any;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    };
  };
};

export type EarlyWarningItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type EarlyWarningItemsQuery = {
  __typename?: "Query";
  earlyWarningItems: {
    __typename?: "EarlyWarningItemList";
    items: Array<{
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      severity: string;
      number: string;
      productInstanceId: string;
      ownerId: string;
      statusOptionId: string;
      dateCreated: any;
      creatorId: string;
      dateModified: any;
      offline: boolean;
      givenById?: string | null;
      dateSent: any;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      statusOption: {
        __typename?: "ItemStatusOption";
        id: string;
        displayOrder: number;
        description: string;
        equatesToRemoved: boolean;
        style: ItemStatusOptionStyles;
        statusCollectionId: string;
        isInternal: boolean;
        status: ItemStatusOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      owner: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        contract: {
          __typename?: "Contract";
          id: string;
          valueCurrency: string;
          timeZone: string;
        };
      };
    }>;
  };
};

export type DraftRiskItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type DraftRiskItemsQuery = {
  __typename?: "Query";
  draftRiskItems: {
    __typename?: "RiskItemList";
    items: Array<{
      __typename?: "RiskItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      ownerId: string;
      statusOptionId: string;
      severity: string;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        contract: { __typename?: "Contract"; id: string; timeZone: string };
      };
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      statusOption: {
        __typename?: "ItemStatusOption";
        id: string;
        displayOrder: number;
        description: string;
        equatesToRemoved: boolean;
        style: ItemStatusOptionStyles;
        statusCollectionId: string;
        isInternal: boolean;
        status: ItemStatusOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      owner: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type DraftEarlyWarningItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type DraftEarlyWarningItemsQuery = {
  __typename?: "Query";
  draftEarlyWarningItems: {
    __typename?: "EarlyWarningItemList";
    items: Array<{
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      severity: string;
      number: string;
      productInstanceId: string;
      ownerId: string;
      statusOptionId: string;
      dateCreated: any;
      creatorId: string;
      dateModified: any;
      offline: boolean;
      givenById?: string | null;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      statusOption: {
        __typename?: "ItemStatusOption";
        id: string;
        displayOrder: number;
        description: string;
        equatesToRemoved: boolean;
        style: ItemStatusOptionStyles;
        statusCollectionId: string;
        isInternal: boolean;
        status: ItemStatusOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      owner: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      productInstance: {
        __typename?: "ProductInstance";
        contract: {
          __typename?: "Contract";
          valueCurrency: string;
          timeZone: string;
        };
      };
    }>;
  };
};

export type ChangelogFieldsFragment = {
  __typename?: "ChangeLogItemList";
  items: Array<{
    __typename?: "ChangeLogItem";
    id: string;
    itemId: string;
    creatorId: string;
    dateCreated: any;
    details:
      | { __typename: "ActionAuthorizedDetails"; title: string }
      | { __typename: "ActionPreventedDetails"; title: string }
      | {
          __typename: "AttachmentAddedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "AttachmentDescriptionAddedDetails";
          title: string;
          attachmentChanges: Array<{
            __typename?: "ItemAttachmentChangeDetails";
            fileName: string;
            oldDescription: string;
            newDescription: string;
          }>;
        }
      | {
          __typename: "AttachmentDescriptionEditedDetails";
          title: string;
          attachmentChanges: Array<{
            __typename?: "ItemAttachmentChangeDetails";
            fileName: string;
            oldDescription: string;
            newDescription: string;
          }>;
        }
      | {
          __typename: "AttachmentRemovedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | { __typename: "AuthorizationRequiredDetails"; title: string }
      | { __typename: "ClaimAgreementNoticeRecordedDetails"; title: string }
      | { __typename: "ClaimAgreementNoticeSentDetails"; title: string }
      | { __typename: "ClaimDetailsResponseRecordedDetails"; title: string }
      | { __typename: "ClaimDetailsResponseSentDetails"; title: string }
      | { __typename: "ClaimDeterminationNoticeRecordedDetails"; title: string }
      | { __typename: "ClaimDeterminationNoticeSentDetails"; title: string }
      | {
          __typename: "ClaimErrorAgreementDisagreementRecordedDetails";
          title: string;
        }
      | {
          __typename: "ClaimErrorAgreementDisagreementSentDetails";
          title: string;
        }
      | {
          __typename: "ClaimErrorAgreementNoticeRecordedDetails";
          title: string;
        }
      | { __typename: "ClaimErrorAgreementNoticeSentDetails"; title: string }
      | {
          __typename: "ClaimErrorDeterminationDisagreementRecordedDetails";
          title: string;
        }
      | {
          __typename: "ClaimErrorDeterminationDisagreementSentDetails";
          title: string;
        }
      | {
          __typename: "ClaimErrorDeterminationNoticeRecordedDetails";
          title: string;
        }
      | {
          __typename: "ClaimErrorDeterminationNoticeSentDetails";
          title: string;
        }
      | { __typename: "ClaimFinalClaimDetailsRecordedDetails"; title: string }
      | { __typename: "ClaimFinalClaimDetailsSentDetails"; title: string }
      | {
          __typename: "ClaimFurtherParticularsRequestRecordedDetails";
          title: string;
        }
      | {
          __typename: "ClaimFurtherParticularsRequestSentDetails";
          title: string;
        }
      | {
          __typename: "ClaimFurtherParticularsSubmissionRecordedDetails";
          title: string;
        }
      | {
          __typename: "ClaimFurtherParticularsSubmissionSentDetails";
          title: string;
        }
      | { __typename: "ClaimInterimClaimDetailsRecordedDetails"; title: string }
      | { __typename: "ClaimInterimClaimDetailsSentDetails"; title: string }
      | { __typename: "ClaimNoAgreementNoticeRecordedDetails"; title: string }
      | { __typename: "ClaimNoAgreementNoticeSentDetails"; title: string }
      | { __typename: "ClaimNoticeInvalidNoticeRecordedDetails"; title: string }
      | { __typename: "ClaimNoticeInvalidNoticeSentDetails"; title: string }
      | { __typename: "ClaimNoticeLapsedNoticeRecordedDetails"; title: string }
      | { __typename: "ClaimNoticeLapsedNoticeSentDetails"; title: string }
      | { __typename: "ClaimNoticeRecordedDetails"; title: string }
      | { __typename: "ClaimNoticeSentDetails"; title: string }
      | { __typename: "CommentRepliedDetails"; content: string; title: string }
      | {
          __typename: "CompEventAbandonInstructionRecordedDetails";
          title: string;
        }
      | { __typename: "CompEventAbandonInstructionSentDetails"; title: string }
      | {
          __typename: "CompEventConfirmationRecordedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventConfirmationSentDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | { __typename: "CompEventDeemingConfirmedDetails"; title: string }
      | {
          __typename: "CompEventDeemingQuotationAcceptedDetails";
          title: string;
        }
      | {
          __typename: "CompEventDenyingOfMoreTimeRecordedDetails";
          title: string;
        }
      | { __typename: "CompEventDenyingOfMoreTimeSentDetails"; title: string }
      | {
          __typename: "CompEventGrantingOfMoreTimeRecordedDetails";
          title: string;
        }
      | { __typename: "CompEventGrantingOfMoreTimeSentDetails"; title: string }
      | {
          __typename: "CompEventNoticeOfSilenceCompEventNoticeRecordedDetails";
          title: string;
        }
      | {
          __typename: "CompEventNoticeOfSilenceCompEventNoticeSentDetails";
          title: string;
        }
      | {
          __typename: "CompEventNoticeOfSilenceOwnAssessmentRecordedDetails";
          title: string;
        }
      | {
          __typename: "CompEventNoticeOfSilenceOwnAssessmentSentDetails";
          title: string;
        }
      | {
          __typename: "CompEventNoticeOfSilenceQuotationRecordedDetails";
          title: string;
        }
      | {
          __typename: "CompEventNoticeOfSilenceQuotationSentDetails";
          title: string;
        }
      | {
          __typename: "CompEventNoticeProposedInstructionRecordedDetails";
          title: string;
        }
      | {
          __typename: "CompEventNoticeProposedInstructionSentDetails";
          title: string;
        }
      | {
          __typename: "CompEventNoticeRecordedDetails";
          notifiedByParty: string;
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventNoticeRequestQuotationRecordedDetails";
          notifiedByParty: string;
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventNoticeRequestQuotationSentDetails";
          notifiedByParty: string;
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventNoticeSentDetails";
          notifiedByParty: string;
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventOwnAssessmentNoticeRecordedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventOwnAssessmentNoticeSentDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventOwnAssessmentRecordedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventOwnAssessmentSentDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventQuotationAcceptanceRecordedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventQuotationAcceptanceSentDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventQuotationRecordedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventQuotationRevisionRequestRecordedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventQuotationRevisionRequestSentDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventQuotationSentDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventRejectionRecordedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventRejectionSentDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "CompEventRequestForMoreTimeRecordedDetails";
          title: string;
        }
      | { __typename: "CompEventRequestForMoreTimeSentDetails"; title: string }
      | {
          __typename: "EventAddedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "EventEditedDetails";
          title: string;
          fieldChanges: Array<{
            __typename?: "ItemFieldChangeDetails";
            fieldName: string;
            oldValue: string;
            newValue: string;
          }>;
        }
      | {
          __typename: "EventOwnerChangedDetails";
          oldOwner: string;
          newOwner: string;
          title: string;
        }
      | {
          __typename: "EventStatusChangedDetails";
          oldStatus: string;
          newStatus: string;
          reason: string;
          details?: string | null;
          title: string;
        }
      | {
          __typename: "EwEditedDetails";
          title: string;
          fieldChanges: Array<{
            __typename?: "ItemFieldChangeDetails";
            fieldName: string;
            oldValue: string;
            newValue: string;
          }>;
        }
      | {
          __typename: "EwOwnerChangedDetails";
          oldOwner: string;
          newOwner: string;
          title: string;
        }
      | {
          __typename: "EwRecordedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "EwSentDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "EwStatusChangedDetails";
          oldStatus: string;
          newStatus: string;
          reason: string;
          details?: string | null;
          title: string;
        }
      | {
          __typename: "InstructionEditedDetails";
          title: string;
          fieldChanges: Array<{
            __typename?: "ItemFieldChangeDetails";
            fieldName: string;
            oldValue: string;
            newValue: string;
          }>;
        }
      | {
          __typename: "InstructionOwnerChangedDetails";
          oldOwner: string;
          newOwner: string;
          title: string;
        }
      | {
          __typename: "InstructionRecordedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "InstructionSentDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "InstructionStatusChangedDetails";
          oldStatus: string;
          newStatus: string;
          reason: string;
          details?: string | null;
          title: string;
        }
      | { __typename: "NewCommentAddedDetails"; content: string; title: string }
      | {
          __typename: "RiskAddedDetails";
          title: string;
          attachments: Array<{
            __typename?: "ItemAttachmentDataDetails";
            fileName: string;
            description: string;
          }>;
        }
      | {
          __typename: "RiskEditedDetails";
          title: string;
          fieldChanges: Array<{
            __typename?: "ItemFieldChangeDetails";
            fieldName: string;
            oldValue: string;
            newValue: string;
          }>;
        }
      | {
          __typename: "RiskOwnerChangedDetails";
          oldOwner: string;
          newOwner: string;
          title: string;
        }
      | {
          __typename: "RiskStatusChangedDetails";
          oldStatus: string;
          newStatus: string;
          reason: string;
          details?: string | null;
          title: string;
        }
      | { __typename: "UserMentionedDetails"; content: string; title: string }
      | { __typename: "VariationAgreementNoticeRecordedDetails"; title: string }
      | { __typename: "VariationAgreementNoticeSentDetails"; title: string }
      | { __typename: "VariationConfirmationRecordedDetails"; title: string }
      | { __typename: "VariationConfirmationSentDetails"; title: string }
      | {
          __typename: "VariationDeterminationNoticeRecordedDetails";
          title: string;
        }
      | { __typename: "VariationDeterminationNoticeSentDetails"; title: string }
      | {
          __typename: "VariationErrorAgreementDisagreementRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationErrorAgreementDisagreementSentDetails";
          title: string;
        }
      | {
          __typename: "VariationErrorAgreementNoticeRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationErrorAgreementNoticeSentDetails";
          title: string;
        }
      | {
          __typename: "VariationErrorDeterminationDisagreementRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationErrorDeterminationDisagreementSentDetails";
          title: string;
        }
      | {
          __typename: "VariationErrorDeterminationNoticeRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationErrorDeterminationNoticeSentDetails";
          title: string;
        }
      | {
          __typename: "VariationFurtherParticularsRequestRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationFurtherParticularsRequestSentDetails";
          title: string;
        }
      | {
          __typename: "VariationFurtherParticularsSubmissionRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationFurtherParticularsSubmissionSentDetails";
          title: string;
        }
      | {
          __typename: "VariationInstructionCommenceWorkRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationInstructionCommenceWorkSentDetails";
          title: string;
        }
      | {
          __typename: "VariationNoAgreementNoticeRecordedDetails";
          title: string;
        }
      | { __typename: "VariationNoAgreementNoticeSentDetails"; title: string }
      | {
          __typename: "VariationObjectionProposedVariationRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationObjectionProposedVariationSentDetails";
          title: string;
        }
      | {
          __typename: "VariationObjectionVariationRecordedDetails";
          title: string;
        }
      | { __typename: "VariationObjectionVariationSentDetails"; title: string }
      | {
          __typename: "VariationProposalAbandonmentRecordedDetails";
          title: string;
        }
      | { __typename: "VariationProposalAbandonmentSentDetails"; title: string }
      | {
          __typename: "VariationProposalConsentForProposedVariationRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationProposalConsentForProposedVariationSentDetails";
          title: string;
        }
      | {
          __typename: "VariationProposalForProposedVariationRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationProposalForProposedVariationSentDetails";
          title: string;
        }
      | {
          __typename: "VariationProposalForVariationRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationProposalForVariationSentDetails";
          title: string;
        }
      | {
          __typename: "VariationRevisedProposalForProposedVariationRequestRecordedDetails";
          title: string;
        }
      | {
          __typename: "VariationRevisedProposalForProposedVariationRequestSentDetails";
          title: string;
        }
      | { __typename: "VariationRevocationRecordedDetails"; title: string }
      | { __typename: "VariationRevocationSentDetails"; title: string };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  }>;
};

export type RiskItemChangelogQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RiskItemChangelogQuery = {
  __typename?: "Query";
  riskItem: {
    __typename?: "RiskItem";
    id: string;
    changeLog: {
      __typename?: "ChangeLogItemList";
      items: Array<{
        __typename?: "ChangeLogItem";
        id: string;
        itemId: string;
        creatorId: string;
        dateCreated: any;
        details:
          | { __typename: "ActionAuthorizedDetails"; title: string }
          | { __typename: "ActionPreventedDetails"; title: string }
          | {
              __typename: "AttachmentAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "AttachmentDescriptionAddedDetails";
              title: string;
              attachmentChanges: Array<{
                __typename?: "ItemAttachmentChangeDetails";
                fileName: string;
                oldDescription: string;
                newDescription: string;
              }>;
            }
          | {
              __typename: "AttachmentDescriptionEditedDetails";
              title: string;
              attachmentChanges: Array<{
                __typename?: "ItemAttachmentChangeDetails";
                fileName: string;
                oldDescription: string;
                newDescription: string;
              }>;
            }
          | {
              __typename: "AttachmentRemovedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | { __typename: "AuthorizationRequiredDetails"; title: string }
          | { __typename: "ClaimAgreementNoticeRecordedDetails"; title: string }
          | { __typename: "ClaimAgreementNoticeSentDetails"; title: string }
          | { __typename: "ClaimDetailsResponseRecordedDetails"; title: string }
          | { __typename: "ClaimDetailsResponseSentDetails"; title: string }
          | {
              __typename: "ClaimDeterminationNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimDeterminationNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimErrorAgreementDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimFinalClaimDetailsRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimFinalClaimDetailsSentDetails"; title: string }
          | {
              __typename: "ClaimFurtherParticularsRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsRequestSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsSubmissionRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsSubmissionSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimInterimClaimDetailsRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimInterimClaimDetailsSentDetails"; title: string }
          | {
              __typename: "ClaimNoAgreementNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoAgreementNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimNoticeInvalidNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoticeInvalidNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimNoticeLapsedNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoticeLapsedNoticeSentDetails"; title: string }
          | { __typename: "ClaimNoticeRecordedDetails"; title: string }
          | { __typename: "ClaimNoticeSentDetails"; title: string }
          | {
              __typename: "CommentRepliedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "CompEventAbandonInstructionRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventAbandonInstructionSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventConfirmationRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventConfirmationSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | { __typename: "CompEventDeemingConfirmedDetails"; title: string }
          | {
              __typename: "CompEventDeemingQuotationAcceptedDetails";
              title: string;
            }
          | {
              __typename: "CompEventDenyingOfMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventDenyingOfMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventGrantingOfMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventGrantingOfMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceCompEventNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceCompEventNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceOwnAssessmentRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceOwnAssessmentSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceQuotationRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceQuotationSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeProposedInstructionRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeProposedInstructionSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeRecordedDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeRequestQuotationRecordedDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeRequestQuotationSentDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeSentDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentNoticeRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentNoticeSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationAcceptanceRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationAcceptanceSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRevisionRequestRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRevisionRequestSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRejectionRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRejectionSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRequestForMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventRequestForMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "EventAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EventEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "EventOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "EventStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "EwEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "EwOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "EwRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EwSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EwStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "InstructionEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "InstructionOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "InstructionRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "InstructionSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "InstructionStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "NewCommentAddedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "RiskAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "RiskEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "RiskOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "RiskStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "UserMentionedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "VariationAgreementNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "VariationAgreementNoticeSentDetails"; title: string }
          | {
              __typename: "VariationConfirmationRecordedDetails";
              title: string;
            }
          | { __typename: "VariationConfirmationSentDetails"; title: string }
          | {
              __typename: "VariationDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsRequestSentDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsSubmissionRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsSubmissionSentDetails";
              title: string;
            }
          | {
              __typename: "VariationInstructionCommenceWorkRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationInstructionCommenceWorkSentDetails";
              title: string;
            }
          | {
              __typename: "VariationNoAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationNoAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalAbandonmentRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalAbandonmentSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalConsentForProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalConsentForProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationRevisedProposalForProposedVariationRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationRevisedProposalForProposedVariationRequestSentDetails";
              title: string;
            }
          | { __typename: "VariationRevocationRecordedDetails"; title: string }
          | { __typename: "VariationRevocationSentDetails"; title: string };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
      }>;
    };
  };
};

export type EarlyWarningItemChangelogQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EarlyWarningItemChangelogQuery = {
  __typename?: "Query";
  earlyWarningItem?: {
    __typename?: "EarlyWarningItem";
    id: string;
    changeLog: {
      __typename?: "ChangeLogItemList";
      items: Array<{
        __typename?: "ChangeLogItem";
        id: string;
        itemId: string;
        creatorId: string;
        dateCreated: any;
        details:
          | { __typename: "ActionAuthorizedDetails"; title: string }
          | { __typename: "ActionPreventedDetails"; title: string }
          | {
              __typename: "AttachmentAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "AttachmentDescriptionAddedDetails";
              title: string;
              attachmentChanges: Array<{
                __typename?: "ItemAttachmentChangeDetails";
                fileName: string;
                oldDescription: string;
                newDescription: string;
              }>;
            }
          | {
              __typename: "AttachmentDescriptionEditedDetails";
              title: string;
              attachmentChanges: Array<{
                __typename?: "ItemAttachmentChangeDetails";
                fileName: string;
                oldDescription: string;
                newDescription: string;
              }>;
            }
          | {
              __typename: "AttachmentRemovedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | { __typename: "AuthorizationRequiredDetails"; title: string }
          | { __typename: "ClaimAgreementNoticeRecordedDetails"; title: string }
          | { __typename: "ClaimAgreementNoticeSentDetails"; title: string }
          | { __typename: "ClaimDetailsResponseRecordedDetails"; title: string }
          | { __typename: "ClaimDetailsResponseSentDetails"; title: string }
          | {
              __typename: "ClaimDeterminationNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimDeterminationNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimErrorAgreementDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimFinalClaimDetailsRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimFinalClaimDetailsSentDetails"; title: string }
          | {
              __typename: "ClaimFurtherParticularsRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsRequestSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsSubmissionRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsSubmissionSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimInterimClaimDetailsRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimInterimClaimDetailsSentDetails"; title: string }
          | {
              __typename: "ClaimNoAgreementNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoAgreementNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimNoticeInvalidNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoticeInvalidNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimNoticeLapsedNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoticeLapsedNoticeSentDetails"; title: string }
          | { __typename: "ClaimNoticeRecordedDetails"; title: string }
          | { __typename: "ClaimNoticeSentDetails"; title: string }
          | {
              __typename: "CommentRepliedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "CompEventAbandonInstructionRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventAbandonInstructionSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventConfirmationRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventConfirmationSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | { __typename: "CompEventDeemingConfirmedDetails"; title: string }
          | {
              __typename: "CompEventDeemingQuotationAcceptedDetails";
              title: string;
            }
          | {
              __typename: "CompEventDenyingOfMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventDenyingOfMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventGrantingOfMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventGrantingOfMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceCompEventNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceCompEventNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceOwnAssessmentRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceOwnAssessmentSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceQuotationRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceQuotationSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeProposedInstructionRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeProposedInstructionSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeRecordedDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeRequestQuotationRecordedDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeRequestQuotationSentDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeSentDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentNoticeRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentNoticeSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationAcceptanceRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationAcceptanceSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRevisionRequestRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRevisionRequestSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRejectionRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRejectionSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRequestForMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventRequestForMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "EventAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EventEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "EventOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "EventStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "EwEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "EwOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "EwRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EwSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EwStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "InstructionEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "InstructionOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "InstructionRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "InstructionSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "InstructionStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "NewCommentAddedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "RiskAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "RiskEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "RiskOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "RiskStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "UserMentionedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "VariationAgreementNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "VariationAgreementNoticeSentDetails"; title: string }
          | {
              __typename: "VariationConfirmationRecordedDetails";
              title: string;
            }
          | { __typename: "VariationConfirmationSentDetails"; title: string }
          | {
              __typename: "VariationDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsRequestSentDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsSubmissionRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsSubmissionSentDetails";
              title: string;
            }
          | {
              __typename: "VariationInstructionCommenceWorkRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationInstructionCommenceWorkSentDetails";
              title: string;
            }
          | {
              __typename: "VariationNoAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationNoAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalAbandonmentRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalAbandonmentSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalConsentForProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalConsentForProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationRevisedProposalForProposedVariationRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationRevisedProposalForProposedVariationRequestSentDetails";
              title: string;
            }
          | { __typename: "VariationRevocationRecordedDetails"; title: string }
          | { __typename: "VariationRevocationSentDetails"; title: string };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
      }>;
    };
  } | null;
};

export type EventItemChangelogQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EventItemChangelogQuery = {
  __typename?: "Query";
  eventItem: {
    __typename?: "EventItem";
    id: string;
    changeLog: {
      __typename?: "ChangeLogItemList";
      items: Array<{
        __typename?: "ChangeLogItem";
        id: string;
        itemId: string;
        creatorId: string;
        dateCreated: any;
        details:
          | { __typename: "ActionAuthorizedDetails"; title: string }
          | { __typename: "ActionPreventedDetails"; title: string }
          | {
              __typename: "AttachmentAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "AttachmentDescriptionAddedDetails";
              title: string;
              attachmentChanges: Array<{
                __typename?: "ItemAttachmentChangeDetails";
                fileName: string;
                oldDescription: string;
                newDescription: string;
              }>;
            }
          | {
              __typename: "AttachmentDescriptionEditedDetails";
              title: string;
              attachmentChanges: Array<{
                __typename?: "ItemAttachmentChangeDetails";
                fileName: string;
                oldDescription: string;
                newDescription: string;
              }>;
            }
          | {
              __typename: "AttachmentRemovedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | { __typename: "AuthorizationRequiredDetails"; title: string }
          | { __typename: "ClaimAgreementNoticeRecordedDetails"; title: string }
          | { __typename: "ClaimAgreementNoticeSentDetails"; title: string }
          | { __typename: "ClaimDetailsResponseRecordedDetails"; title: string }
          | { __typename: "ClaimDetailsResponseSentDetails"; title: string }
          | {
              __typename: "ClaimDeterminationNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimDeterminationNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimErrorAgreementDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimFinalClaimDetailsRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimFinalClaimDetailsSentDetails"; title: string }
          | {
              __typename: "ClaimFurtherParticularsRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsRequestSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsSubmissionRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsSubmissionSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimInterimClaimDetailsRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimInterimClaimDetailsSentDetails"; title: string }
          | {
              __typename: "ClaimNoAgreementNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoAgreementNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimNoticeInvalidNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoticeInvalidNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimNoticeLapsedNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoticeLapsedNoticeSentDetails"; title: string }
          | { __typename: "ClaimNoticeRecordedDetails"; title: string }
          | { __typename: "ClaimNoticeSentDetails"; title: string }
          | {
              __typename: "CommentRepliedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "CompEventAbandonInstructionRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventAbandonInstructionSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventConfirmationRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventConfirmationSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | { __typename: "CompEventDeemingConfirmedDetails"; title: string }
          | {
              __typename: "CompEventDeemingQuotationAcceptedDetails";
              title: string;
            }
          | {
              __typename: "CompEventDenyingOfMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventDenyingOfMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventGrantingOfMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventGrantingOfMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceCompEventNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceCompEventNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceOwnAssessmentRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceOwnAssessmentSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceQuotationRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceQuotationSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeProposedInstructionRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeProposedInstructionSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeRecordedDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeRequestQuotationRecordedDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeRequestQuotationSentDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeSentDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentNoticeRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentNoticeSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationAcceptanceRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationAcceptanceSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRevisionRequestRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRevisionRequestSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRejectionRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRejectionSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRequestForMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventRequestForMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "EventAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EventEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "EventOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "EventStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "EwEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "EwOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "EwRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EwSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EwStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "InstructionEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "InstructionOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "InstructionRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "InstructionSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "InstructionStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "NewCommentAddedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "RiskAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "RiskEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "RiskOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "RiskStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "UserMentionedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "VariationAgreementNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "VariationAgreementNoticeSentDetails"; title: string }
          | {
              __typename: "VariationConfirmationRecordedDetails";
              title: string;
            }
          | { __typename: "VariationConfirmationSentDetails"; title: string }
          | {
              __typename: "VariationDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsRequestSentDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsSubmissionRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsSubmissionSentDetails";
              title: string;
            }
          | {
              __typename: "VariationInstructionCommenceWorkRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationInstructionCommenceWorkSentDetails";
              title: string;
            }
          | {
              __typename: "VariationNoAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationNoAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalAbandonmentRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalAbandonmentSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalConsentForProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalConsentForProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationRevisedProposalForProposedVariationRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationRevisedProposalForProposedVariationRequestSentDetails";
              title: string;
            }
          | { __typename: "VariationRevocationRecordedDetails"; title: string }
          | { __typename: "VariationRevocationSentDetails"; title: string };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
      }>;
    };
  };
};

export type InstructionItemChangelogQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InstructionItemChangelogQuery = {
  __typename?: "Query";
  instructionItem?: {
    __typename?: "InstructionItem";
    id: string;
    changeLog: {
      __typename?: "ChangeLogItemList";
      items: Array<{
        __typename?: "ChangeLogItem";
        id: string;
        itemId: string;
        creatorId: string;
        dateCreated: any;
        details:
          | { __typename: "ActionAuthorizedDetails"; title: string }
          | { __typename: "ActionPreventedDetails"; title: string }
          | {
              __typename: "AttachmentAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "AttachmentDescriptionAddedDetails";
              title: string;
              attachmentChanges: Array<{
                __typename?: "ItemAttachmentChangeDetails";
                fileName: string;
                oldDescription: string;
                newDescription: string;
              }>;
            }
          | {
              __typename: "AttachmentDescriptionEditedDetails";
              title: string;
              attachmentChanges: Array<{
                __typename?: "ItemAttachmentChangeDetails";
                fileName: string;
                oldDescription: string;
                newDescription: string;
              }>;
            }
          | {
              __typename: "AttachmentRemovedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | { __typename: "AuthorizationRequiredDetails"; title: string }
          | { __typename: "ClaimAgreementNoticeRecordedDetails"; title: string }
          | { __typename: "ClaimAgreementNoticeSentDetails"; title: string }
          | { __typename: "ClaimDetailsResponseRecordedDetails"; title: string }
          | { __typename: "ClaimDetailsResponseSentDetails"; title: string }
          | {
              __typename: "ClaimDeterminationNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimDeterminationNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimErrorAgreementDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimErrorDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimFinalClaimDetailsRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimFinalClaimDetailsSentDetails"; title: string }
          | {
              __typename: "ClaimFurtherParticularsRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsRequestSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsSubmissionRecordedDetails";
              title: string;
            }
          | {
              __typename: "ClaimFurtherParticularsSubmissionSentDetails";
              title: string;
            }
          | {
              __typename: "ClaimInterimClaimDetailsRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimInterimClaimDetailsSentDetails"; title: string }
          | {
              __typename: "ClaimNoAgreementNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoAgreementNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimNoticeInvalidNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoticeInvalidNoticeSentDetails"; title: string }
          | {
              __typename: "ClaimNoticeLapsedNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "ClaimNoticeLapsedNoticeSentDetails"; title: string }
          | { __typename: "ClaimNoticeRecordedDetails"; title: string }
          | { __typename: "ClaimNoticeSentDetails"; title: string }
          | {
              __typename: "CommentRepliedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "CompEventAbandonInstructionRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventAbandonInstructionSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventConfirmationRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventConfirmationSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | { __typename: "CompEventDeemingConfirmedDetails"; title: string }
          | {
              __typename: "CompEventDeemingQuotationAcceptedDetails";
              title: string;
            }
          | {
              __typename: "CompEventDenyingOfMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventDenyingOfMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventGrantingOfMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventGrantingOfMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceCompEventNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceCompEventNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceOwnAssessmentRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceOwnAssessmentSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceQuotationRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeOfSilenceQuotationSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeProposedInstructionRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeProposedInstructionSentDetails";
              title: string;
            }
          | {
              __typename: "CompEventNoticeRecordedDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeRequestQuotationRecordedDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeRequestQuotationSentDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventNoticeSentDetails";
              notifiedByParty: string;
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentNoticeRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentNoticeSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventOwnAssessmentSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationAcceptanceRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationAcceptanceSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRevisionRequestRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationRevisionRequestSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventQuotationSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRejectionRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRejectionSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "CompEventRequestForMoreTimeRecordedDetails";
              title: string;
            }
          | {
              __typename: "CompEventRequestForMoreTimeSentDetails";
              title: string;
            }
          | {
              __typename: "EventAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EventEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "EventOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "EventStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "EwEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "EwOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "EwRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EwSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "EwStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "InstructionEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "InstructionOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "InstructionRecordedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "InstructionSentDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "InstructionStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "NewCommentAddedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "RiskAddedDetails";
              title: string;
              attachments: Array<{
                __typename?: "ItemAttachmentDataDetails";
                fileName: string;
                description: string;
              }>;
            }
          | {
              __typename: "RiskEditedDetails";
              title: string;
              fieldChanges: Array<{
                __typename?: "ItemFieldChangeDetails";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            }
          | {
              __typename: "RiskOwnerChangedDetails";
              oldOwner: string;
              newOwner: string;
              title: string;
            }
          | {
              __typename: "RiskStatusChangedDetails";
              oldStatus: string;
              newStatus: string;
              reason: string;
              details?: string | null;
              title: string;
            }
          | {
              __typename: "UserMentionedDetails";
              content: string;
              title: string;
            }
          | {
              __typename: "VariationAgreementNoticeRecordedDetails";
              title: string;
            }
          | { __typename: "VariationAgreementNoticeSentDetails"; title: string }
          | {
              __typename: "VariationConfirmationRecordedDetails";
              title: string;
            }
          | { __typename: "VariationConfirmationSentDetails"; title: string }
          | {
              __typename: "VariationDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationDisagreementRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationDisagreementSentDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationErrorDeterminationNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsRequestSentDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsSubmissionRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationFurtherParticularsSubmissionSentDetails";
              title: string;
            }
          | {
              __typename: "VariationInstructionCommenceWorkRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationInstructionCommenceWorkSentDetails";
              title: string;
            }
          | {
              __typename: "VariationNoAgreementNoticeRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationNoAgreementNoticeSentDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationObjectionVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalAbandonmentRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalAbandonmentSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalConsentForProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalConsentForProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForProposedVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForProposedVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForVariationRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationProposalForVariationSentDetails";
              title: string;
            }
          | {
              __typename: "VariationRevisedProposalForProposedVariationRequestRecordedDetails";
              title: string;
            }
          | {
              __typename: "VariationRevisedProposalForProposedVariationRequestSentDetails";
              title: string;
            }
          | { __typename: "VariationRevocationRecordedDetails"; title: string }
          | { __typename: "VariationRevocationSentDetails"; title: string };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
      }>;
    };
  } | null;
};

export type ClaimNoticeInvalidNoticePromptQueryVariables = Exact<{
  input: ClaimNoticeInvalidNoticePromptInput;
}>;

export type ClaimNoticeInvalidNoticePromptQuery = {
  __typename?: "Query";
  claimNoticeInvalidNoticePrompt: {
    __typename?: "ClaimNoticeInvalidNoticePrompt";
    reasons: string;
  };
};

export type SendClaimNoticeInvalidNoticeMutationVariables = Exact<{
  input: SendClaimNoticeInvalidNoticeInput;
}>;

export type SendClaimNoticeInvalidNoticeMutation = {
  __typename?: "Mutation";
  sendClaimNoticeInvalidNotice: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimNoticeInvalidNoticeMutationVariables = Exact<{
  input: RecordClaimNoticeInvalidNoticeInput;
}>;

export type RecordClaimNoticeInvalidNoticeMutation = {
  __typename?: "Mutation";
  recordClaimNoticeInvalidNotice: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimNoticeInvalidNoticePreviewMutationVariables =
  Exact<{
    input: SendClaimNoticeInvalidNoticePreviewInput;
  }>;

export type GenerateSendClaimNoticeInvalidNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimNoticeInvalidNoticePreview: {
    __typename?: "SendClaimNoticeInvalidNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimNoticeInvalidNoticePreviewMutationVariables =
  Exact<{
    input: RecordClaimNoticeInvalidNoticePreviewInput;
  }>;

export type GenerateRecordClaimNoticeInvalidNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimNoticeInvalidNoticePreview: {
    __typename?: "RecordClaimNoticeInvalidNoticePreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimErrorAgreementDisagreementPromptQueryVariables = Exact<{
  input: ClaimErrorAgreementDisagreementPromptInput;
}>;

export type ClaimErrorAgreementDisagreementPromptQuery = {
  __typename?: "Query";
  claimErrorAgreementDisagreementPrompt: {
    __typename?: "ClaimErrorAgreementDisagreementPrompt";
    remarks: string;
  };
};

export type SendClaimErrorAgreementDisagreementMutationVariables = Exact<{
  input: SendClaimErrorAgreementDisagreementInput;
}>;

export type SendClaimErrorAgreementDisagreementMutation = {
  __typename?: "Mutation";
  sendClaimErrorAgreementDisagreement: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimErrorAgreementDisagreementMutationVariables = Exact<{
  input: RecordClaimErrorAgreementDisagreementInput;
}>;

export type RecordClaimErrorAgreementDisagreementMutation = {
  __typename?: "Mutation";
  recordClaimErrorAgreementDisagreement: {
    __typename?: "ClaimItem";
    id: string;
  };
};

export type GenerateSendClaimErrorAgreementDisagreementPreviewMutationVariables =
  Exact<{
    input: SendClaimErrorAgreementDisagreementPreviewInput;
  }>;

export type GenerateSendClaimErrorAgreementDisagreementPreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimErrorAgreementDisagreementPreview: {
    __typename?: "SendClaimErrorAgreementDisagreementPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimErrorAgreementDisagreementPreviewMutationVariables =
  Exact<{
    input: RecordClaimErrorAgreementDisagreementPreviewInput;
  }>;

export type GenerateRecordClaimErrorAgreementDisagreementPreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimErrorAgreementDisagreementPreview: {
    __typename?: "RecordClaimErrorAgreementDisagreementPreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimErrorDeterminationDisagreementPromptQueryVariables = Exact<{
  input: ClaimErrorDeterminationDisagreementPromptInput;
}>;

export type ClaimErrorDeterminationDisagreementPromptQuery = {
  __typename?: "Query";
  claimErrorDeterminationDisagreementPrompt: {
    __typename?: "ClaimErrorDeterminationDisagreementPrompt";
    remarks: string;
  };
};

export type SendClaimErrorDeterminationDisagreementMutationVariables = Exact<{
  input: SendClaimErrorDeterminationDisagreementInput;
}>;

export type SendClaimErrorDeterminationDisagreementMutation = {
  __typename?: "Mutation";
  sendClaimErrorDeterminationDisagreement: {
    __typename?: "ClaimItem";
    id: string;
  };
};

export type RecordClaimErrorDeterminationDisagreementMutationVariables = Exact<{
  input: RecordClaimErrorDeterminationDisagreementInput;
}>;

export type RecordClaimErrorDeterminationDisagreementMutation = {
  __typename?: "Mutation";
  recordClaimErrorDeterminationDisagreement: {
    __typename?: "ClaimItem";
    id: string;
  };
};

export type GenerateSendClaimErrorDeterminationDisagreementPreviewMutationVariables =
  Exact<{
    input: SendClaimErrorDeterminationDisagreementPreviewInput;
  }>;

export type GenerateSendClaimErrorDeterminationDisagreementPreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimErrorDeterminationDisagreementPreview: {
    __typename?: "SendClaimErrorDeterminationDisagreementPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimErrorDeterminationDisagreementPreviewMutationVariables =
  Exact<{
    input: RecordClaimErrorDeterminationDisagreementPreviewInput;
  }>;

export type GenerateRecordClaimErrorDeterminationDisagreementPreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimErrorDeterminationDisagreementPreview: {
    __typename?: "RecordClaimErrorDeterminationDisagreementPreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimAgreementNoticePromptQueryVariables = Exact<{
  input: ClaimAgreementNoticePromptInput;
}>;

export type ClaimAgreementNoticePromptQuery = {
  __typename?: "Query";
  claimAgreementNoticePrompt: {
    __typename?: "ClaimAgreementNoticePrompt";
    draftClaimAgreements: Array<{
      __typename?: "DraftClaimAgreement";
      id: string;
      claimId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    }>;
  };
};

export type SendClaimAgreementNoticeMutationVariables = Exact<{
  input: SendClaimAgreementNoticeInput;
}>;

export type SendClaimAgreementNoticeMutation = {
  __typename?: "Mutation";
  sendClaimAgreementNotice: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimAgreementNoticeMutationVariables = Exact<{
  input: RecordClaimAgreementNoticeInput;
}>;

export type RecordClaimAgreementNoticeMutation = {
  __typename?: "Mutation";
  recordClaimAgreementNotice: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimAgreementNoticePreviewMutationVariables = Exact<{
  input: SendClaimAgreementNoticePreviewInput;
}>;

export type GenerateSendClaimAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimAgreementNoticePreview: {
    __typename?: "SendClaimAgreementNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimAgreementNoticePreviewMutationVariables = Exact<{
  input: RecordClaimAgreementNoticePreviewInput;
}>;

export type GenerateRecordClaimAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimAgreementNoticePreview: {
    __typename?: "RecordClaimAgreementNoticePreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimNoticePromptQueryVariables = Exact<{
  input: ClaimNoticePromptInput;
}>;

export type ClaimNoticePromptQuery = {
  __typename?: "Query";
  claimNoticePrompt: {
    __typename?: "ClaimNoticePrompt";
    claimTypes: Array<{ __typename?: "ListItem"; id: string; name: string }>;
    advanceWarnings: Array<{
      __typename?: "ListItem";
      id: string;
      name: string;
    }>;
  };
};

export type SendClaimNoticeMutationVariables = Exact<{
  input: SendClaimNoticeInput;
}>;

export type SendClaimNoticeMutation = {
  __typename?: "Mutation";
  sendClaimNotice: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimNoticeMutationVariables = Exact<{
  input: RecordClaimNoticeInput;
}>;

export type RecordClaimNoticeMutation = {
  __typename?: "Mutation";
  recordClaimNotice: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimNoticePreviewMutationVariables = Exact<{
  input: SendClaimNoticePreviewInput;
}>;

export type GenerateSendClaimNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimNoticePreview: {
    __typename?: "SendClaimNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimNoticePreviewMutationVariables = Exact<{
  input: RecordClaimNoticePreviewInput;
}>;

export type GenerateRecordClaimNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimNoticePreview: {
    __typename?: "RecordClaimNoticePreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimDeterminationNoticePromptQueryVariables = Exact<{
  input: ClaimDeterminationNoticePromptInput;
}>;

export type ClaimDeterminationNoticePromptQuery = {
  __typename?: "Query";
  claimDeterminationNoticePrompt: {
    __typename?: "ClaimDeterminationNoticePrompt";
    draftClaimDeterminations: Array<{
      __typename?: "DraftClaimDetermination";
      id: string;
      claimId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    }>;
  };
};

export type SendClaimDeterminationNoticeMutationVariables = Exact<{
  input: SendClaimDeterminationNoticeInput;
}>;

export type SendClaimDeterminationNoticeMutation = {
  __typename?: "Mutation";
  sendClaimDeterminationNotice: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimDeterminationNoticeMutationVariables = Exact<{
  input: RecordClaimDeterminationNoticeInput;
}>;

export type RecordClaimDeterminationNoticeMutation = {
  __typename?: "Mutation";
  recordClaimDeterminationNotice: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimDeterminationNoticePreviewMutationVariables =
  Exact<{
    input: SendClaimDeterminationNoticePreviewInput;
  }>;

export type GenerateSendClaimDeterminationNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimDeterminationNoticePreview: {
    __typename?: "SendClaimDeterminationNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimDeterminationNoticePreviewMutationVariables =
  Exact<{
    input: RecordClaimDeterminationNoticePreviewInput;
  }>;

export type GenerateRecordClaimDeterminationNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimDeterminationNoticePreview: {
    __typename?: "RecordClaimDeterminationNoticePreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimNoticeLapsedNoticePromptQueryVariables = Exact<{
  input: ClaimNoticeLapsedNoticePromptInput;
}>;

export type ClaimNoticeLapsedNoticePromptQuery = {
  __typename?: "Query";
  claimNoticeLapsedNoticePrompt: {
    __typename?: "ClaimNoticeLapsedNoticePrompt";
    remarks: string;
  };
};

export type SendClaimNoticeLapsedNoticeMutationVariables = Exact<{
  input: SendClaimNoticeLapsedNoticeInput;
}>;

export type SendClaimNoticeLapsedNoticeMutation = {
  __typename?: "Mutation";
  sendClaimNoticeLapsedNotice: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimNoticeLapsedNoticeMutationVariables = Exact<{
  input: RecordClaimNoticeLapsedNoticeInput;
}>;

export type RecordClaimNoticeLapsedNoticeMutation = {
  __typename?: "Mutation";
  recordClaimNoticeLapsedNotice: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimNoticeLapsedNoticePreviewMutationVariables =
  Exact<{
    input: SendClaimNoticeLapsedNoticePreviewInput;
  }>;

export type GenerateSendClaimNoticeLapsedNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimNoticeLapsedNoticePreview: {
    __typename?: "SendClaimNoticeLapsedNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimNoticeLapsedNoticePreviewMutationVariables =
  Exact<{
    input: RecordClaimNoticeLapsedNoticePreviewInput;
  }>;

export type GenerateRecordClaimNoticeLapsedNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimNoticeLapsedNoticePreview: {
    __typename?: "RecordClaimNoticeLapsedNoticePreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimErrorAgreementNoticePromptQueryVariables = Exact<{
  input: ClaimErrorAgreementNoticePromptInput;
}>;

export type ClaimErrorAgreementNoticePromptQuery = {
  __typename?: "Query";
  claimErrorAgreementNoticePrompt: {
    __typename?: "ClaimErrorAgreementNoticePrompt";
    details: string;
  };
};

export type SendClaimErrorAgreementNoticeMutationVariables = Exact<{
  input: SendClaimErrorAgreementNoticeInput;
}>;

export type SendClaimErrorAgreementNoticeMutation = {
  __typename?: "Mutation";
  sendClaimErrorAgreementNotice: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimErrorAgreementNoticeMutationVariables = Exact<{
  input: RecordClaimErrorAgreementNoticeInput;
}>;

export type RecordClaimErrorAgreementNoticeMutation = {
  __typename?: "Mutation";
  recordClaimErrorAgreementNotice: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimErrorAgreementNoticePreviewMutationVariables =
  Exact<{
    input: SendClaimErrorAgreementNoticePreviewInput;
  }>;

export type GenerateSendClaimErrorAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimErrorAgreementNoticePreview: {
    __typename?: "SendClaimErrorAgreementNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimErrorAgreementNoticePreviewMutationVariables =
  Exact<{
    input: RecordClaimErrorAgreementNoticePreviewInput;
  }>;

export type GenerateRecordClaimErrorAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimErrorAgreementNoticePreview: {
    __typename?: "RecordClaimErrorAgreementNoticePreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimErrorDeterminationNoticePromptQueryVariables = Exact<{
  input: ClaimErrorDeterminationNoticePromptInput;
}>;

export type ClaimErrorDeterminationNoticePromptQuery = {
  __typename?: "Query";
  claimErrorDeterminationNoticePrompt: {
    __typename?: "ClaimErrorDeterminationNoticePrompt";
    details: string;
  };
};

export type SendClaimErrorDeterminationNoticeMutationVariables = Exact<{
  input: SendClaimErrorDeterminationNoticeInput;
}>;

export type SendClaimErrorDeterminationNoticeMutation = {
  __typename?: "Mutation";
  sendClaimErrorDeterminationNotice: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimErrorDeterminationNoticeMutationVariables = Exact<{
  input: RecordClaimErrorDeterminationNoticeInput;
}>;

export type RecordClaimErrorDeterminationNoticeMutation = {
  __typename?: "Mutation";
  recordClaimErrorDeterminationNotice: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimErrorDeterminationNoticePreviewMutationVariables =
  Exact<{
    input: SendClaimErrorDeterminationNoticePreviewInput;
  }>;

export type GenerateSendClaimErrorDeterminationNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimErrorDeterminationNoticePreview: {
    __typename?: "SendClaimErrorDeterminationNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimErrorDeterminationNoticePreviewMutationVariables =
  Exact<{
    input: RecordClaimErrorDeterminationNoticePreviewInput;
  }>;

export type GenerateRecordClaimErrorDeterminationNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimErrorDeterminationNoticePreview: {
    __typename?: "RecordClaimErrorDeterminationNoticePreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type SendClaimNoAgreementNoticeMutationVariables = Exact<{
  input: SendClaimNoAgreementNoticeInput;
}>;

export type SendClaimNoAgreementNoticeMutation = {
  __typename?: "Mutation";
  sendClaimNoAgreementNotice: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimNoAgreementNoticeMutationVariables = Exact<{
  input: RecordClaimNoAgreementNoticeInput;
}>;

export type RecordClaimNoAgreementNoticeMutation = {
  __typename?: "Mutation";
  recordClaimNoAgreementNotice: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimNoAgreementNoticePreviewMutationVariables = Exact<{
  input: SendClaimNoAgreementNoticePreviewInput;
}>;

export type GenerateSendClaimNoAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimNoAgreementNoticePreview: {
    __typename?: "SendClaimNoAgreementNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimNoAgreementNoticePreviewMutationVariables =
  Exact<{
    input: RecordClaimNoAgreementNoticePreviewInput;
  }>;

export type GenerateRecordClaimNoAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimNoAgreementNoticePreview: {
    __typename?: "RecordClaimNoAgreementNoticePreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimFurtherParticularsSubmissionPromptQueryVariables = Exact<{
  input: ClaimFurtherParticularsSubmissionPromptInput;
}>;

export type ClaimFurtherParticularsSubmissionPromptQuery = {
  __typename?: "Query";
  claimFurtherParticularsSubmissionPrompt: {
    __typename?: "ClaimFurtherParticularsSubmissionPrompt";
    details: string;
  };
};

export type SendClaimFurtherParticularsSubmissionMutationVariables = Exact<{
  input: SendClaimFurtherParticularsSubmissionInput;
}>;

export type SendClaimFurtherParticularsSubmissionMutation = {
  __typename?: "Mutation";
  sendClaimFurtherParticularsSubmission: {
    __typename?: "ClaimItem";
    id: string;
  };
};

export type RecordClaimFurtherParticularsSubmissionMutationVariables = Exact<{
  input: RecordClaimFurtherParticularsSubmissionInput;
}>;

export type RecordClaimFurtherParticularsSubmissionMutation = {
  __typename?: "Mutation";
  recordClaimFurtherParticularsSubmission: {
    __typename?: "ClaimItem";
    id: string;
  };
};

export type GenerateSendClaimFurtherParticularsSubmissionPreviewMutationVariables =
  Exact<{
    input: SendClaimFurtherParticularsSubmissionPreviewInput;
  }>;

export type GenerateSendClaimFurtherParticularsSubmissionPreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimFurtherParticularsSubmissionPreview: {
    __typename?: "SendClaimFurtherParticularsSubmissionPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimFurtherParticularsSubmissionPreviewMutationVariables =
  Exact<{
    input: RecordClaimFurtherParticularsSubmissionPreviewInput;
  }>;

export type GenerateRecordClaimFurtherParticularsSubmissionPreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimFurtherParticularsSubmissionPreview: {
    __typename?: "RecordClaimFurtherParticularsSubmissionPreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimFurtherParticularsRequestPromptQueryVariables = Exact<{
  input: ClaimFurtherParticularsRequestPromptInput;
}>;

export type ClaimFurtherParticularsRequestPromptQuery = {
  __typename?: "Query";
  claimFurtherParticularsRequestPrompt: {
    __typename?: "ClaimFurtherParticularsRequestPrompt";
    reasons: string;
  };
};

export type SendClaimFurtherParticularsRequestMutationVariables = Exact<{
  input: SendClaimFurtherParticularsRequestInput;
}>;

export type SendClaimFurtherParticularsRequestMutation = {
  __typename?: "Mutation";
  sendClaimFurtherParticularsRequest: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimFurtherParticularsRequestMutationVariables = Exact<{
  input: RecordClaimFurtherParticularsRequestInput;
}>;

export type RecordClaimFurtherParticularsRequestMutation = {
  __typename?: "Mutation";
  recordClaimFurtherParticularsRequest: {
    __typename?: "ClaimItem";
    id: string;
  };
};

export type GenerateSendClaimFurtherParticularsRequestPreviewMutationVariables =
  Exact<{
    input: SendClaimFurtherParticularsRequestPreviewInput;
  }>;

export type GenerateSendClaimFurtherParticularsRequestPreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimFurtherParticularsRequestPreview: {
    __typename?: "SendClaimFurtherParticularsRequestPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimFurtherParticularsRequestPreviewMutationVariables =
  Exact<{
    input: RecordClaimFurtherParticularsRequestPreviewInput;
  }>;

export type GenerateRecordClaimFurtherParticularsRequestPreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimFurtherParticularsRequestPreview: {
    __typename?: "RecordClaimFurtherParticularsRequestPreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimDetailsResponsePromptQueryVariables = Exact<{
  input: ClaimDetailsResponsePromptInput;
}>;

export type ClaimDetailsResponsePromptQuery = {
  __typename?: "Query";
  claimDetailsResponsePrompt: {
    __typename?: "ClaimDetailsResponsePrompt";
    details: string;
    pendingDetailedClaims: Array<{
      __typename?: "DetailedClaim";
      id: string;
      claimId: string;
      reference: string;
      details: string;
      price: number;
      currency: string;
      time: number;
      dateCreated: any;
      creatorId: string;
      sectionalChanges: Array<{
        __typename?: "DetailedClaimTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
    }>;
  };
};

export type SendClaimDetailsResponseMutationVariables = Exact<{
  input: SendClaimDetailsResponseInput;
}>;

export type SendClaimDetailsResponseMutation = {
  __typename?: "Mutation";
  sendClaimDetailsResponse: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimDetailsResponseMutationVariables = Exact<{
  input: RecordClaimDetailsResponseInput;
}>;

export type RecordClaimDetailsResponseMutation = {
  __typename?: "Mutation";
  recordClaimDetailsResponse: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimDetailsResponsePreviewMutationVariables = Exact<{
  input: SendClaimDetailsResponsePreviewInput;
}>;

export type GenerateSendClaimDetailsResponsePreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimDetailsResponsePreview: {
    __typename?: "SendClaimDetailsResponsePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimDetailsResponsePreviewMutationVariables = Exact<{
  input: RecordClaimDetailsResponsePreviewInput;
}>;

export type GenerateRecordClaimDetailsResponsePreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimDetailsResponsePreview: {
    __typename?: "RecordClaimDetailsResponsePreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimFinalClaimDetailsPromptQueryVariables = Exact<{
  input: ClaimFinalClaimDetailsPromptInput;
}>;

export type ClaimFinalClaimDetailsPromptQuery = {
  __typename?: "Query";
  claimFinalClaimDetailsPrompt: {
    __typename?: "ClaimFinalClaimDetailsPrompt";
    draftDetailedClaims: Array<{
      __typename?: "DraftDetailedClaim";
      id: string;
      claimId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    }>;
  };
};

export type SendClaimFinalClaimDetailsMutationVariables = Exact<{
  input: SendClaimFinalClaimDetailsInput;
}>;

export type SendClaimFinalClaimDetailsMutation = {
  __typename?: "Mutation";
  sendClaimFinalClaimDetails: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimFinalClaimDetailsMutationVariables = Exact<{
  input: RecordClaimFinalClaimDetailsInput;
}>;

export type RecordClaimFinalClaimDetailsMutation = {
  __typename?: "Mutation";
  recordClaimFinalClaimDetails: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimFinalClaimDetailsPreviewMutationVariables = Exact<{
  input: SendClaimFinalClaimDetailsPreviewInput;
}>;

export type GenerateSendClaimFinalClaimDetailsPreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimFinalClaimDetailsPreview: {
    __typename?: "SendClaimFinalClaimDetailsPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimFinalClaimDetailsPreviewMutationVariables =
  Exact<{
    input: RecordClaimFinalClaimDetailsPreviewInput;
  }>;

export type GenerateRecordClaimFinalClaimDetailsPreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimFinalClaimDetailsPreview: {
    __typename?: "RecordClaimFinalClaimDetailsPreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimInterimClaimDetailsPromptQueryVariables = Exact<{
  input: ClaimInterimClaimDetailsPromptInput;
}>;

export type ClaimInterimClaimDetailsPromptQuery = {
  __typename?: "Query";
  claimInterimClaimDetailsPrompt: {
    __typename?: "ClaimInterimClaimDetailsPrompt";
    draftDetailedClaims: Array<{
      __typename?: "DraftDetailedClaim";
      id: string;
      claimId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    }>;
  };
};

export type SendClaimInterimClaimDetailsMutationVariables = Exact<{
  input: SendClaimInterimClaimDetailsInput;
}>;

export type SendClaimInterimClaimDetailsMutation = {
  __typename?: "Mutation";
  sendClaimInterimClaimDetails: { __typename?: "ClaimItem"; id: string };
};

export type RecordClaimInterimClaimDetailsMutationVariables = Exact<{
  input: RecordClaimInterimClaimDetailsInput;
}>;

export type RecordClaimInterimClaimDetailsMutation = {
  __typename?: "Mutation";
  recordClaimInterimClaimDetails: { __typename?: "ClaimItem"; id: string };
};

export type GenerateSendClaimInterimClaimDetailsPreviewMutationVariables =
  Exact<{
    input: SendClaimInterimClaimDetailsPreviewInput;
  }>;

export type GenerateSendClaimInterimClaimDetailsPreviewMutation = {
  __typename?: "Mutation";
  generateSendClaimInterimClaimDetailsPreview: {
    __typename?: "SendClaimInterimClaimDetailsPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordClaimInterimClaimDetailsPreviewMutationVariables =
  Exact<{
    input: RecordClaimInterimClaimDetailsPreviewInput;
  }>;

export type GenerateRecordClaimInterimClaimDetailsPreviewMutation = {
  __typename?: "Mutation";
  generateRecordClaimInterimClaimDetailsPreview: {
    __typename?: "RecordClaimInterimClaimDetailsPreview";
    recipients: Array<{
      __typename?: "ClaimRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ClaimHistoryItemsQueryVariables = Exact<{
  claimId: Scalars["ID"];
}>;

export type ClaimHistoryItemsQuery = {
  __typename?: "Query";
  claimHistoryItems: {
    __typename?: "ClaimHistoryItemList";
    items: Array<{
      __typename?: "ClaimHistoryItem";
      id: string;
      claimId: string;
      actionType: ClaimActionType;
      actionNumber: string;
      dateCreated: any;
      dateSent: any;
      dueDate?: any | null;
      sentByUserId: string;
      sentByPartyId?: string | null;
      offline: boolean;
      daysLate: number;
      isIncomplete?: boolean | null;
      answers?:
        | {
            __typename: "ClaimHistoryAgreementAnswers";
            agreementId?: string | null;
            draftAgreementId?: string | null;
            agreement?: {
              __typename?: "ClaimAgreement";
              id: string;
              claimId: string;
              reference: string;
              details: string;
              price: number;
              currency: string;
              time: number;
              dateCreated: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "DetailedClaimTimeChange";
                number?: number | null;
                description: string;
                days: number;
              }>;
            } | null;
            draftAgreement?: {
              __typename?: "DraftClaimAgreement";
              id: string;
              claimId: string;
              reference: string;
              details: string;
              price: number;
              time: number;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "ContractTimeImpact";
                id: string;
                days: number;
              } | null>;
            } | null;
          }
        | {
            __typename: "ClaimHistoryClaimDetailsResponseAnswers";
            details: string;
            detailedClaimId: string;
            detailedClaim: {
              __typename?: "DetailedClaim";
              id: string;
              claimId: string;
              reference: string;
              details: string;
              price: number;
              currency: string;
              time: number;
              dateCreated: any;
              creatorId: string;
              sectionalChanges: Array<{
                __typename?: "DetailedClaimTimeChange";
                number?: number | null;
                description: string;
                days: number;
              }>;
            };
          }
        | {
            __typename: "ClaimHistoryDeterminationAnswers";
            determinationId?: string | null;
            draftDeterminationId?: string | null;
            determination?: {
              __typename?: "ClaimDetermination";
              id: string;
              claimId: string;
              reference: string;
              details: string;
              price: number;
              currency: string;
              time: number;
              dateCreated: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "DetailedClaimTimeChange";
                number?: number | null;
                description: string;
                days: number;
              }>;
            } | null;
            draftDetermination?: {
              __typename?: "DraftClaimDetermination";
              id: string;
              claimId: string;
              reference: string;
              details: string;
              price: number;
              time: number;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "ContractTimeImpact";
                id: string;
                days: number;
              } | null>;
            } | null;
          }
        | {
            __typename: "ClaimHistoryErrorAgreementDisagreementAnswers";
            remarks: string;
          }
        | {
            __typename: "ClaimHistoryErrorAgreementNoticeAnswers";
            details: string;
          }
        | {
            __typename: "ClaimHistoryErrorDeterminationDisagreementAnswers";
            remarks: string;
          }
        | {
            __typename: "ClaimHistoryErrorDeterminationNoticeAnswers";
            details: string;
          }
        | {
            __typename: "ClaimHistoryFinalDetailsAnswers";
            detailedClaimId: string;
            detailedClaim: {
              __typename?: "DetailedClaim";
              id: string;
              claimId: string;
              reference: string;
              details: string;
              price: number;
              currency: string;
              time: number;
              dateCreated: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "DetailedClaimTimeChange";
                number?: number | null;
                description: string;
                days: number;
              }>;
            };
          }
        | {
            __typename: "ClaimHistoryFurtherParticularsRequestAnswers";
            reasons: string;
          }
        | {
            __typename: "ClaimHistoryFurtherParticularsSubmissionAnswers";
            details: string;
          }
        | {
            __typename: "ClaimHistoryInterimDetailsAnswers";
            detailedClaimId: string;
            detailedClaim: {
              __typename?: "DetailedClaim";
              id: string;
              claimId: string;
              reference: string;
              details: string;
              price: number;
              currency: string;
              time: number;
              dateCreated: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "DetailedClaimTimeChange";
                number?: number | null;
                description: string;
                days: number;
              }>;
            };
          }
        | {
            __typename: "ClaimHistoryNoAgreementReachedAnswers";
            remarks: string;
          }
        | {
            __typename: "ClaimHistoryNoticeAnswers";
            claimTypeId: string;
            advanceWarningId?: string | null;
            claimType: {
              __typename?: "ClaimTypeInfo";
              id: string;
              clause: string;
              description: string;
            };
            advanceWarning?: {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              severity: string;
              number: string;
              productInstanceId: string;
              ownerId: string;
              statusOptionId: string;
              dateCreated: any;
              creatorId: string;
              dateModified: any;
              offline: boolean;
              givenById?: string | null;
              dateSent: any;
            } | null;
          }
        | {
            __typename: "ClaimHistoryNoticeInvalidNoticeAnswers";
            reasons: string;
          }
        | {
            __typename: "ClaimHistoryNoticeValidityLapsedAnswers";
            remarks: string;
          }
        | null;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
      recipients: Array<{
        __typename?: "ClaimRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          email: boolean;
        };
      }>;
      authorizationWorkflowAudit?: {
        __typename?: "AuthorizationWorkflowAudit";
        id: string;
        initiatedById: string;
        regardingProductItemId: string;
        regardingProductType: ProductType;
        regardingItemHistoryId?: string | null;
        workflowId: string;
        actionId: string;
        status: AuthorizationWorkflowAuditStatus;
        dateCreated: any;
        dateInitiated: any;
        initiatedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        action: {
          __typename?: "AuthorizationWorkflowActionMapping";
          id: string;
          productOutputActionId: string;
          workflowId: string;
          partyId?: string | null;
          productInstanceId: string;
          status: AuthorizationWorkflowActionMappingStatus;
          dateCreated: any;
          creatorId: string;
          modifiedById: string;
          dateModified: any;
          productOutputAction: {
            __typename?: "ProductOutputAction";
            id: string;
            name: string;
            productId: string;
            isInternal: boolean;
            status: ProductOutputActionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
          };
          workflow: {
            __typename?: "AuthorizationWorkflow";
            id: string;
            name: string;
            status: AuthorizationWorkflowStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            modifiedById: string;
            projectId: string;
            levels: Array<{
              __typename?: "AuthorizationWorkflowLevel";
              sequence: number;
              mode: AuthorizationWorkflowLevelMode;
              userIds: Array<string>;
              users: {
                __typename?: "UserList";
                items: Array<{
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  jobTitle: string;
                  country: string;
                  mobileNumber?: any | null;
                  alternateNumber?: any | null;
                  dateOfBirth?: any | null;
                  profilePicture?: string | null;
                  email: any;
                  companyId: string;
                  registered?: boolean | null;
                  status: UserStatus;
                  lastActive?: any | null;
                  dateInvited: any;
                  registeredDate?: any | null;
                  company: {
                    __typename?: "Company";
                    id: string;
                    tradingName: string;
                    registeredName: string;
                  };
                  invitedBy: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                }>;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            modifiedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              number?: string | null;
              sector: ProjectSector;
              description?: string | null;
              status: ProjectStatus;
              dateCreated: any;
              creatorId: string;
              billingCompanyId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              contracts: {
                __typename?: "ContractList";
                items: Array<{
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ContractStatus;
                }>;
              };
              billingCompany: {
                __typename?: "Company";
                id: string;
                type?: CompanyType | null;
                registeredName: string;
                tradingName: string;
                registrationNumber?: string | null;
                logo?: string | null;
                vatRegistrationNumber?: string | null;
                status: CompanyStatus;
                creatorId: string;
                dateCreated: any;
                physicalAddress?: {
                  __typename?: "Address";
                  line1?: string | null;
                  line2?: string | null;
                  city?: string | null;
                  provinceState?: string | null;
                  country: string;
                  code?: string | null;
                } | null;
                postalAddress?: {
                  __typename?: "Address";
                  line1?: string | null;
                  line2?: string | null;
                  city?: string | null;
                  provinceState?: string | null;
                  country: string;
                  code?: string | null;
                } | null;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              };
            };
          };
          party?: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            contractId: string;
            product:
              | { __typename?: "Claims"; id: string; name: string }
              | { __typename?: "CompEvents"; id: string; name: string }
              | { __typename?: "DailyDiary"; id: string; name: string }
              | { __typename?: "EarlyWarnings"; id: string; name: string }
              | { __typename?: "Events"; id: string; name: string }
              | { __typename?: "Instructions"; id: string; name: string }
              | { __typename?: "RisksRegister"; id: string; name: string }
              | { __typename?: "Variations"; id: string; name: string };
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              valueCurrency: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
            };
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
        regardingProductItem:
          | {
              __typename: "ClaimItem";
              id: string;
              number: string;
              regardingId: string;
              claimRegardingType: ClaimRegardingType;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                contract: {
                  __typename?: "Contract";
                  id: string;
                  timeZone: string;
                };
              };
            }
          | {
              __typename: "CompEventItem";
              id: string;
              number: string;
              productInstanceId: string;
              regardingId: string;
              regardingType: CompEventRegardingType;
              status: CompEventStatus;
              price?: number | null;
              time?: number | null;
              earlyWarningId?: string | null;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              deemingDate?: any | null;
              lastAction: {
                __typename?: "CompEventItemLastAction";
                actionType: CompEventActionType;
                daysLate?: number | null;
                date: any;
              };
              nextStep?: {
                __typename?: "CompEventItemNextStep";
                actionType: CompEventActionType;
                dueDate: any;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                status: ProductInstanceStatus;
                product:
                  | { __typename?: "Claims"; id: string; name: string }
                  | { __typename?: "CompEvents"; id: string; name: string }
                  | { __typename?: "DailyDiary"; id: string; name: string }
                  | { __typename?: "EarlyWarnings"; id: string; name: string }
                  | { __typename?: "Events"; id: string; name: string }
                  | { __typename?: "Instructions"; id: string; name: string }
                  | { __typename?: "RisksRegister"; id: string; name: string }
                  | { __typename?: "Variations"; id: string; name: string };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ContractStatus;
                  timeZone: string;
                  valueCurrency: string;
                  project: {
                    __typename?: "Project";
                    id: string;
                    name: string;
                    friendlyName: string;
                    status: ProjectStatus;
                  };
                };
              };
              earlyWarning?: {
                __typename?: "EarlyWarningItem";
                id: string;
                title: string;
                dateSent: any;
                number: string;
                productInstance: {
                  __typename?: "ProductInstance";
                  id: string;
                  product:
                    | { __typename?: "Claims"; id: string }
                    | { __typename?: "CompEvents"; id: string }
                    | { __typename?: "DailyDiary"; id: string }
                    | { __typename?: "EarlyWarnings"; id: string }
                    | { __typename?: "Events"; id: string }
                    | { __typename?: "Instructions"; id: string }
                    | { __typename?: "RisksRegister"; id: string }
                    | { __typename?: "Variations"; id: string };
                };
              } | null;
              regardingEvent?: {
                __typename?: "EventItem";
                id: string;
                title: string;
                productInstanceId: string;
                number: string;
                data: {
                  __typename?: "ItemData";
                  sections: Array<{
                    __typename?: "ItemDataSection";
                    name: string;
                    entries: Array<{
                      __typename?: "ItemDataEntry";
                      name: string;
                      value: string;
                    }>;
                  }>;
                };
                productInstance: {
                  __typename?: "ProductInstance";
                  id: string;
                  product:
                    | { __typename?: "Claims"; id: string }
                    | { __typename?: "CompEvents"; id: string }
                    | { __typename?: "DailyDiary"; id: string }
                    | { __typename?: "EarlyWarnings"; id: string }
                    | { __typename?: "Events"; id: string }
                    | { __typename?: "Instructions"; id: string }
                    | { __typename?: "RisksRegister"; id: string }
                    | { __typename?: "Variations"; id: string };
                };
              } | null;
              regardingInstruction?: {
                __typename?: "InstructionItem";
                id: string;
                title: string;
                number: string;
                productInstanceId: string;
                data: {
                  __typename?: "ItemData";
                  sections: Array<{
                    __typename?: "ItemDataSection";
                    name: string;
                    entries: Array<{
                      __typename?: "ItemDataEntry";
                      name: string;
                      value: string;
                    }>;
                  }>;
                };
                productInstance: {
                  __typename?: "ProductInstance";
                  id: string;
                  product:
                    | { __typename?: "Claims"; id: string }
                    | { __typename?: "CompEvents"; id: string }
                    | { __typename?: "DailyDiary"; id: string }
                    | { __typename?: "EarlyWarnings"; id: string }
                    | { __typename?: "Events"; id: string }
                    | { __typename?: "Instructions"; id: string }
                    | { __typename?: "RisksRegister"; id: string }
                    | { __typename?: "Variations"; id: string };
                };
              } | null;
              notifiedByParty: {
                __typename?: "ContractBindingType";
                id: string;
                description: string;
                contractTypeId: string;
                status: ContractBindingTypeStatus;
              };
            }
          | {
              __typename?: "DailyDiaryItem";
              id: string;
              date: any;
              number: string;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              sentById?: string | null;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                status: ProductInstanceStatus;
                contract: {
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  timeZone: string;
                  number: string;
                  status: ContractStatus;
                  dateCreated: any;
                  project: {
                    __typename?: "Project";
                    id: string;
                    name: string;
                    friendlyName: string;
                    status: ProjectStatus;
                  };
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    };
              };
            }
          | {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              severity: string;
              number: string;
              productInstanceId: string;
              ownerId: string;
              statusOptionId: string;
              dateCreated: any;
              creatorId: string;
              dateModified: any;
              offline: boolean;
              givenById?: string | null;
              dateSent: any;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              owner: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                productSchema: {
                  __typename?: "ProductSchema";
                  id: string;
                  name: string;
                  isDefault: boolean;
                  productId: string;
                  isInternal: boolean;
                  status: ProductSchemaStatus;
                  creatorId: string;
                  dateCreated: any;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaSections: {
                    __typename?: "SchemaSectionList";
                    items: Array<{
                      __typename?: "SchemaSection";
                      id: string;
                      name: string;
                      displayText: string;
                      displayOrder: number;
                      columnPlacement: ColumnPlacementType;
                      productSchemaId: string;
                      isInternal: boolean;
                      status: SchemaSectionStatus;
                      dateCreated: any;
                      creatorId: string;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      schemaFields: {
                        __typename?: "SchemaFieldList";
                        items: Array<{
                          __typename?: "SchemaField";
                          id: string;
                          name: string;
                          schemaSectionId: string;
                          fieldTypeId: string;
                          displayText: string;
                          displayOrder: number;
                          isRequired: boolean;
                          isInternal: boolean;
                          complexTypeItemId?: string | null;
                          extraData?: string | null;
                          status: SchemaFieldStatus;
                          creatorId: string;
                          dateCreated: any;
                          creator: {
                            __typename?: "User";
                            id: string;
                            firstname: string;
                            surname: string;
                            email: any;
                          };
                          fieldType: {
                            __typename?: "FieldType";
                            id: string;
                            description: string;
                            shortCode: FieldTypeEnum;
                            status: FieldTypeStatus;
                          };
                        }>;
                      };
                    }>;
                  };
                };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  valueCurrency: string;
                  timeZone: string;
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      numberingFormat: string;
                    };
              };
              attachments?: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }> | null;
            }
          | { __typename?: "EventItem" }
          | {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              severity: string;
              number: string;
              productInstanceId: string;
              ownerId: string;
              statusOptionId: string;
              dateCreated: any;
              dateModified: any;
              notifyDueDate: any;
              creatorId: string;
              offline: boolean;
              givenById?: string | null;
              dateSent: any;
              attachments?: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }> | null;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                productSchema: {
                  __typename?: "ProductSchema";
                  id: string;
                  name: string;
                  isDefault: boolean;
                  productId: string;
                  isInternal: boolean;
                  status: ProductSchemaStatus;
                  creatorId: string;
                  dateCreated: any;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaSections: {
                    __typename?: "SchemaSectionList";
                    items: Array<{
                      __typename?: "SchemaSection";
                      id: string;
                      name: string;
                      displayText: string;
                      displayOrder: number;
                      columnPlacement: ColumnPlacementType;
                      productSchemaId: string;
                      isInternal: boolean;
                      status: SchemaSectionStatus;
                      dateCreated: any;
                      creatorId: string;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      schemaFields: {
                        __typename?: "SchemaFieldList";
                        items: Array<{
                          __typename?: "SchemaField";
                          id: string;
                          name: string;
                          schemaSectionId: string;
                          fieldTypeId: string;
                          displayText: string;
                          displayOrder: number;
                          isRequired: boolean;
                          isInternal: boolean;
                          complexTypeItemId?: string | null;
                          extraData?: string | null;
                          status: SchemaFieldStatus;
                          creatorId: string;
                          dateCreated: any;
                          creator: {
                            __typename?: "User";
                            id: string;
                            firstname: string;
                            surname: string;
                            email: any;
                          };
                          fieldType: {
                            __typename?: "FieldType";
                            id: string;
                            description: string;
                            shortCode: FieldTypeEnum;
                            status: FieldTypeStatus;
                          };
                        }>;
                      };
                    }>;
                  };
                };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  contractTypeId: string;
                  valueCurrency: string;
                  timeZone: string;
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      numberingFormat: string;
                    };
              };
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              statusOption: {
                __typename?: "ItemStatusOption";
                id: string;
                displayOrder: number;
                description: string;
                equatesToRemoved: boolean;
                style: ItemStatusOptionStyles;
                statusCollectionId: string;
                isInternal: boolean;
                status: ItemStatusOptionStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              };
              owner: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }
          | { __typename?: "RiskItem" }
          | {
              __typename: "VariationItem";
              id: string;
              number: string;
              regardingId: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                contract: {
                  __typename?: "Contract";
                  id: string;
                  timeZone: string;
                };
              };
            };
        auditTrails?: Array<{
          __typename?: "AuthorizationWorkflowAuditTrail";
          id: string;
          auditId: string;
          regardingProductItemId: string;
          regardingItemHistoryId?: string | null;
          regardingProductType: ProductType;
          productInstanceId: string;
          actionId: string;
          status: AuthorizationActionStatus;
          denialReason?: string | null;
          dateCreated: any;
          creatorId: string;
          productInstance?: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            contractId: string;
            productId: string;
            productSchemaId: string;
            statusCollectionId: string;
            status: ProductInstanceStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              number: string;
              valueCurrency: string;
              value: number;
              startDate?: any | null;
              endDate?: any | null;
              contractTypeId: string;
              projectId: string;
              country: string;
              province?: string | null;
              timeZone: string;
              coordinatesLatitude?: number | null;
              coordinatesLongitude?: number | null;
              status: ContractStatus;
              dateCreated: any;
              creatorId: string;
              sections: Array<{
                __typename?: "ContractSection";
                id: string;
                number?: number | null;
                description: string;
                completionDate: any;
                status: ContractSectionStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
              keyDates: Array<{
                __typename?: "ContractKeyDate";
                id: string;
                number?: number | null;
                conditionToBeMet: string;
                keyDate: any;
                status: ContractKeyDateStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
            statusCollection: {
              __typename?: "ItemStatusCollection";
              id: string;
              name: string;
              isDefault: boolean;
              status: ItemStatusCollectionStatus;
            };
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }> | null;
      } | null;
      sentByUser: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      sentByParty?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
    }>;
  };
};

export type LocalClaimItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LocalClaimItemQuery = {
  __typename?: "Query";
  claimItem?: {
    __typename: "ClaimItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: ClaimRegardingType;
    status: ClaimStatus;
    advanceWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    notifiedByPartyId: string;
    ongoingAuthorizationAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      actionId: string;
      workflowId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    } | null;
    lastAction: {
      __typename?: "ClaimItemLastAction";
      actionType: ClaimActionType;
      date: any;
      daysLate?: number | null;
      party: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      };
    };
    nextStep?: {
      __typename?: "ClaimItemNextStep";
      actionType: ClaimActionType;
      dueDate: any;
      partyId: string;
      party: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      };
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    advanceWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  } | null;
};

export type AddDraftDetailedClaimMutationVariables = Exact<{
  input: AddDraftDetailedClaimInput;
}>;

export type AddDraftDetailedClaimMutation = {
  __typename?: "Mutation";
  addDraftDetailedClaim: {
    __typename?: "DraftDetailedClaim";
    id: string;
    claimId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type EditDraftDetailedClaimMutationVariables = Exact<{
  input: EditDraftDetailedClaimInput;
}>;

export type EditDraftDetailedClaimMutation = {
  __typename?: "Mutation";
  editDraftDetailedClaim: {
    __typename?: "DraftDetailedClaim";
    id: string;
    claimId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type RemoveDraftDetailedClaimMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftDetailedClaimMutation = {
  __typename?: "Mutation";
  removeDraftDetailedClaim: boolean;
};

export type AddDraftClaimAgreementMutationVariables = Exact<{
  input: AddDraftClaimAgreementInput;
}>;

export type AddDraftClaimAgreementMutation = {
  __typename?: "Mutation";
  addDraftClaimAgreement: {
    __typename?: "DraftClaimAgreement";
    id: string;
    claimId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type EditDraftClaimAgreementMutationVariables = Exact<{
  input: EditDraftClaimAgreementInput;
}>;

export type EditDraftClaimAgreementMutation = {
  __typename?: "Mutation";
  editDraftClaimAgreement: {
    __typename?: "DraftClaimAgreement";
    id: string;
    claimId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type RemoveDraftClaimAgreementMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftClaimAgreementMutation = {
  __typename?: "Mutation";
  removeDraftClaimAgreement: boolean;
};

export type AddDraftClaimDeterminationMutationVariables = Exact<{
  input: AddDraftClaimDeterminationInput;
}>;

export type AddDraftClaimDeterminationMutation = {
  __typename?: "Mutation";
  addDraftClaimDetermination: {
    __typename?: "DraftClaimDetermination";
    id: string;
    claimId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type EditDraftClaimDeterminationMutationVariables = Exact<{
  input: EditDraftClaimDeterminationInput;
}>;

export type EditDraftClaimDeterminationMutation = {
  __typename?: "Mutation";
  editDraftClaimDetermination: {
    __typename?: "DraftClaimDetermination";
    id: string;
    claimId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type RemoveDraftClaimDeterminationMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftClaimDeterminationMutation = {
  __typename?: "Mutation";
  removeDraftClaimDetermination: boolean;
};

export type ClaimDetailsExplorerItemsQueryVariables = Exact<{
  claimId: Scalars["ID"];
}>;

export type ClaimDetailsExplorerItemsQuery = {
  __typename?: "Query";
  claimDetailsExplorerItems: {
    __typename?: "ClaimDetailsExplorerItemList";
    items: Array<
      | {
          __typename?: "ClaimAgreement";
          id: string;
          claimId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "DetailedClaimTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        }
      | {
          __typename?: "ClaimDetermination";
          id: string;
          claimId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "DetailedClaimTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        }
      | {
          __typename?: "DetailedClaim";
          id: string;
          claimId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "DetailedClaimTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        }
    >;
  };
};

export type LocalCreatorFieldsFragment = {
  __typename?: "User";
  id: string;
  firstname: string;
  surname: string;
  profilePicture?: string | null;
  company: { __typename?: "Company"; tradingName: string };
};

export type CommentReplyFieldsFragment = {
  __typename?: "CommentReply";
  id: string;
  commentId: string;
  content: string;
  dateCreated: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    profilePicture?: string | null;
    company: { __typename?: "Company"; tradingName: string };
  };
};

export type CommentFieldsFragment = {
  __typename?: "Comment";
  id: string;
  itemId: string;
  content: string;
  dateCreated: any;
  creatorId: string;
  replies: {
    __typename?: "CommentReplyList";
    items: Array<{
      __typename?: "CommentReply";
      id: string;
      commentId: string;
      content: string;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        profilePicture?: string | null;
        company: { __typename?: "Company"; tradingName: string };
      };
    }>;
  };
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    profilePicture?: string | null;
    company: { __typename?: "Company"; tradingName: string };
  };
};

export type RiskItemCommentsQueryVariables = Exact<{
  id: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type RiskItemCommentsQuery = {
  __typename?: "Query";
  riskItem: {
    __typename?: "RiskItem";
    id: string;
    title: string;
    comments: {
      __typename?: "CommentList";
      nextToken?: string | null;
      items: Array<{
        __typename?: "Comment";
        id: string;
        itemId: string;
        content: string;
        dateCreated: any;
        creatorId: string;
        replies: {
          __typename?: "CommentReplyList";
          items: Array<{
            __typename?: "CommentReply";
            id: string;
            commentId: string;
            content: string;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              profilePicture?: string | null;
              company: { __typename?: "Company"; tradingName: string };
            };
          }>;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          profilePicture?: string | null;
          company: { __typename?: "Company"; tradingName: string };
        };
      }>;
    };
  };
};

export type EarlyWarningItemCommentsQueryVariables = Exact<{
  id: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type EarlyWarningItemCommentsQuery = {
  __typename?: "Query";
  earlyWarningItem?: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
    comments: {
      __typename?: "CommentList";
      nextToken?: string | null;
      items: Array<{
        __typename?: "Comment";
        id: string;
        itemId: string;
        content: string;
        dateCreated: any;
        creatorId: string;
        replies: {
          __typename?: "CommentReplyList";
          items: Array<{
            __typename?: "CommentReply";
            id: string;
            commentId: string;
            content: string;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              profilePicture?: string | null;
              company: { __typename?: "Company"; tradingName: string };
            };
          }>;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          profilePicture?: string | null;
          company: { __typename?: "Company"; tradingName: string };
        };
      }>;
    };
  } | null;
};

export type EventItemCommentsQueryVariables = Exact<{
  id: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type EventItemCommentsQuery = {
  __typename?: "Query";
  eventItem: {
    __typename?: "EventItem";
    id: string;
    title: string;
    comments: {
      __typename?: "CommentList";
      nextToken?: string | null;
      items: Array<{
        __typename?: "Comment";
        id: string;
        itemId: string;
        content: string;
        dateCreated: any;
        creatorId: string;
        replies: {
          __typename?: "CommentReplyList";
          items: Array<{
            __typename?: "CommentReply";
            id: string;
            commentId: string;
            content: string;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              profilePicture?: string | null;
              company: { __typename?: "Company"; tradingName: string };
            };
          }>;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          profilePicture?: string | null;
          company: { __typename?: "Company"; tradingName: string };
        };
      }>;
    };
  };
};

export type InstructionItemCommentsQueryVariables = Exact<{
  id: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type InstructionItemCommentsQuery = {
  __typename?: "Query";
  instructionItem?: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    comments: {
      __typename?: "CommentList";
      nextToken?: string | null;
      items: Array<{
        __typename?: "Comment";
        id: string;
        itemId: string;
        content: string;
        dateCreated: any;
        creatorId: string;
        replies: {
          __typename?: "CommentReplyList";
          items: Array<{
            __typename?: "CommentReply";
            id: string;
            commentId: string;
            content: string;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              profilePicture?: string | null;
              company: { __typename?: "Company"; tradingName: string };
            };
          }>;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          profilePicture?: string | null;
          company: { __typename?: "Company"; tradingName: string };
        };
      }>;
    };
  } | null;
};

export type DailyDiaryItemCommentsQueryVariables = Exact<{
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type DailyDiaryItemCommentsQuery = {
  __typename?: "Query";
  dailyDiaryItemComments: {
    __typename?: "CommentList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "Comment";
      id: string;
      itemId: string;
      content: string;
      dateCreated: any;
      creatorId: string;
      replies: {
        __typename?: "CommentReplyList";
        items: Array<{
          __typename?: "CommentReply";
          id: string;
          commentId: string;
          content: string;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            profilePicture?: string | null;
            company: { __typename?: "Company"; tradingName: string };
          };
        }>;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        profilePicture?: string | null;
        company: { __typename?: "Company"; tradingName: string };
      };
    }>;
  };
};

export type AddCommentMutationVariables = Exact<{
  input: AddCommentInput;
}>;

export type AddCommentMutation = {
  __typename?: "Mutation";
  addComment: {
    __typename?: "Comment";
    id: string;
    itemId: string;
    content: string;
    dateCreated: any;
    creatorId: string;
    replies: {
      __typename?: "CommentReplyList";
      items: Array<{
        __typename?: "CommentReply";
        id: string;
        commentId: string;
        content: string;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          profilePicture?: string | null;
          company: { __typename?: "Company"; tradingName: string };
        };
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      profilePicture?: string | null;
      company: { __typename?: "Company"; tradingName: string };
    };
  };
};

export type ReplyCommentMutationVariables = Exact<{
  input: ReplyCommentInput;
}>;

export type ReplyCommentMutation = {
  __typename?: "Mutation";
  replyComment: {
    __typename?: "CommentReply";
    id: string;
    commentId: string;
    content: string;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      profilePicture?: string | null;
      company: { __typename?: "Company"; tradingName: string };
    };
  };
};

export type LocalCompEventItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LocalCompEventItemQuery = {
  __typename?: "Query";
  compEventItem?: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    ongoingAuthorizationAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      actionId: string;
      workflowId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    } | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  } | null;
};

export type DailyDiaryItemRegisterViewFieldsFragment = {
  __typename?: "DailyDiaryItem";
  id: string;
  date: any;
  number: string;
  status: DailyDiaryItemStatus;
  dateSent?: any | null;
  latestRevision: {
    __typename?: "DailyDiaryItemRevision";
    id: string;
    number: string;
    status: DailyDiaryItemStatus;
  };
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    contract: { __typename?: "Contract"; id: string; timeZone: string };
  };
  sentBy?: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  } | null;
};

export type DailyDiaryItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type DailyDiaryItemsQuery = {
  __typename?: "Query";
  dailyDiaryItems: {
    __typename?: "DailyDiaryItemList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "DailyDiaryItem";
      id: string;
      date: any;
      number: string;
      status: DailyDiaryItemStatus;
      dateSent?: any | null;
      latestRevision: {
        __typename?: "DailyDiaryItemRevision";
        id: string;
        number: string;
        status: DailyDiaryItemStatus;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        contract: { __typename?: "Contract"; id: string; timeZone: string };
      };
      sentBy?: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      } | null;
    }>;
  };
};

export type DailyDiaryItemsRequiresMyAttentionQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type DailyDiaryItemsRequiresMyAttentionQuery = {
  __typename?: "Query";
  dailyDiaryItemsRequiresMyAttention: {
    __typename?: "DailyDiaryItemMixedList";
    nextToken?: string | null;
    items: Array<
      | {
          __typename: "DailyDiaryItem";
          id: string;
          date: any;
          number: string;
          status: DailyDiaryItemStatus;
          dateSent?: any | null;
          latestRevision: {
            __typename?: "DailyDiaryItemRevision";
            id: string;
            number: string;
            status: DailyDiaryItemStatus;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
          sentBy?: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          } | null;
        }
      | {
          __typename: "EmptyDailyDiaryItem";
          productInstanceId: string;
          date: any;
        }
    >;
  };
};

export type DailyDiaryItemsReviewedByMeQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type DailyDiaryItemsReviewedByMeQuery = {
  __typename?: "Query";
  dailyDiaryItemsReviewedByMe: {
    __typename?: "DailyDiaryItemList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "DailyDiaryItem";
      id: string;
      date: any;
      number: string;
      status: DailyDiaryItemStatus;
      dateSent?: any | null;
      latestRevision: {
        __typename?: "DailyDiaryItemRevision";
        id: string;
        number: string;
        status: DailyDiaryItemStatus;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        contract: { __typename?: "Contract"; id: string; timeZone: string };
      };
      sentBy?: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      } | null;
    }>;
  };
};

export type DailyDiaryItemsSentByMeQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type DailyDiaryItemsSentByMeQuery = {
  __typename?: "Query";
  dailyDiaryItemsSentByMe: {
    __typename?: "DailyDiaryItemList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "DailyDiaryItem";
      id: string;
      date: any;
      number: string;
      status: DailyDiaryItemStatus;
      dateSent?: any | null;
      latestRevision: {
        __typename?: "DailyDiaryItemRevision";
        id: string;
        number: string;
        status: DailyDiaryItemStatus;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        contract: { __typename?: "Contract"; id: string; timeZone: string };
      };
      sentBy?: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      } | null;
    }>;
  };
};

export type GetExplorerDataQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetExplorerDataQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
    roles: {
      __typename?: "ProductUserRoleList";
      items: Array<{
        __typename?: "ProductUserRole";
        userId: string;
        productInstanceId: string;
        productRoleId: string;
        status: ProductUserRoleStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              number?: string | null;
              sector: ProjectSector;
              description?: string | null;
              status: ProjectStatus;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
              };
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        productRole: {
          __typename?: "ProductRole";
          id: string;
          name: string;
          productId: string;
          actionIds: Array<string>;
          status: ProductRoleStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
          };
        };
      }>;
    };
  } | null;
};

export type VariationProposalAbandonmentPromptQueryVariables = Exact<{
  input: VariationProposalAbandonmentPromptInput;
}>;

export type VariationProposalAbandonmentPromptQuery = {
  __typename?: "Query";
  variationProposalAbandonmentPrompt: {
    __typename?: "VariationProposalAbandonmentPrompt";
    remarks: string;
  };
};

export type SendVariationProposalAbandonmentMutationVariables = Exact<{
  input: SendVariationProposalAbandonmentInput;
}>;

export type SendVariationProposalAbandonmentMutation = {
  __typename?: "Mutation";
  sendVariationProposalAbandonment: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationProposalAbandonmentMutationVariables = Exact<{
  input: RecordVariationProposalAbandonmentInput;
}>;

export type RecordVariationProposalAbandonmentMutation = {
  __typename?: "Mutation";
  recordVariationProposalAbandonment: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationProposalAbandonmentPreviewMutationVariables =
  Exact<{
    input: SendVariationProposalAbandonmentPreviewInput;
  }>;

export type GenerateSendVariationProposalAbandonmentPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationProposalAbandonmentPreview: {
    __typename?: "SendVariationProposalAbandonmentPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationProposalAbandonmentPreviewMutationVariables =
  Exact<{
    input: RecordVariationProposalAbandonmentPreviewInput;
  }>;

export type GenerateRecordVariationProposalAbandonmentPreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationProposalAbandonmentPreview: {
    __typename?: "RecordVariationProposalAbandonmentPreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationRevocationPromptQueryVariables = Exact<{
  input: VariationRevocationPromptInput;
}>;

export type VariationRevocationPromptQuery = {
  __typename?: "Query";
  variationRevocationPrompt: {
    __typename?: "VariationRevocationPrompt";
    remarks: string;
  };
};

export type SendVariationRevocationMutationVariables = Exact<{
  input: SendVariationRevocationInput;
}>;

export type SendVariationRevocationMutation = {
  __typename?: "Mutation";
  sendVariationRevocation: { __typename?: "VariationItem"; id: string };
};

export type RecordVariationRevocationMutationVariables = Exact<{
  input: RecordVariationRevocationInput;
}>;

export type RecordVariationRevocationMutation = {
  __typename?: "Mutation";
  recordVariationRevocation: { __typename?: "VariationItem"; id: string };
};

export type GenerateSendVariationRevocationPreviewMutationVariables = Exact<{
  input: SendVariationRevocationPreviewInput;
}>;

export type GenerateSendVariationRevocationPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationRevocationPreview: {
    __typename?: "SendVariationRevocationPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationRevocationPreviewMutationVariables = Exact<{
  input: RecordVariationRevocationPreviewInput;
}>;

export type GenerateRecordVariationRevocationPreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationRevocationPreview: {
    __typename?: "RecordVariationRevocationPreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationConfirmationPromptQueryVariables = Exact<{
  input: VariationConfirmationPromptInput;
}>;

export type VariationConfirmationPromptQuery = {
  __typename?: "Query";
  variationConfirmationPrompt: {
    __typename?: "VariationConfirmationPrompt";
    remarks: string;
  };
};

export type SendVariationConfirmationMutationVariables = Exact<{
  input: SendVariationConfirmationInput;
}>;

export type SendVariationConfirmationMutation = {
  __typename?: "Mutation";
  sendVariationConfirmation: { __typename?: "VariationItem"; id: string };
};

export type RecordVariationConfirmationMutationVariables = Exact<{
  input: RecordVariationConfirmationInput;
}>;

export type RecordVariationConfirmationMutation = {
  __typename?: "Mutation";
  recordVariationConfirmation: { __typename?: "VariationItem"; id: string };
};

export type GenerateSendVariationConfirmationPreviewMutationVariables = Exact<{
  input: SendVariationConfirmationPreviewInput;
}>;

export type GenerateSendVariationConfirmationPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationConfirmationPreview: {
    __typename?: "SendVariationConfirmationPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationConfirmationPreviewMutationVariables =
  Exact<{
    input: RecordVariationConfirmationPreviewInput;
  }>;

export type GenerateRecordVariationConfirmationPreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationConfirmationPreview: {
    __typename?: "RecordVariationConfirmationPreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationProposalConsentForProposedVariationPromptQueryVariables =
  Exact<{
    input: VariationProposalConsentForProposedVariationPromptInput;
  }>;

export type VariationProposalConsentForProposedVariationPromptQuery = {
  __typename?: "Query";
  variationProposalConsentForProposedVariationPrompt: {
    __typename?: "VariationProposalConsentForProposedVariationPrompt";
    proposals: Array<{
      __typename?: "VariationProposal";
      id: string;
      variationId: string;
      reference: string;
      details: string;
      price: number;
      currency: string;
      consented: boolean;
      time: number;
      dateCreated: any;
      creatorId: string;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
      sectionalChanges: Array<{
        __typename?: "VariationProposalTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
    }>;
  };
};

export type SendVariationProposalConsentForProposedVariationMutationVariables =
  Exact<{
    input: SendVariationProposalConsentForProposedVariationInput;
  }>;

export type SendVariationProposalConsentForProposedVariationMutation = {
  __typename?: "Mutation";
  sendVariationProposalConsentForProposedVariation: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationProposalConsentForProposedVariationMutationVariables =
  Exact<{
    input: RecordVariationProposalConsentForProposedVariationInput;
  }>;

export type RecordVariationProposalConsentForProposedVariationMutation = {
  __typename?: "Mutation";
  recordVariationProposalConsentForProposedVariation: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationProposalConsentForProposedVariationPreviewMutationVariables =
  Exact<{
    input: SendVariationProposalConsentForProposedVariationPreviewInput;
  }>;

export type GenerateSendVariationProposalConsentForProposedVariationPreviewMutation =
  {
    __typename?: "Mutation";
    generateSendVariationProposalConsentForProposedVariationPreview: {
      __typename?: "SendVariationProposalConsentForProposedVariationPreview";
      id: string;
      fileUrl: string;
      recipients: Array<{
        __typename?: "VariationRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type GenerateRecordVariationProposalConsentForProposedVariationPreviewMutationVariables =
  Exact<{
    input: RecordVariationProposalConsentForProposedVariationPreviewInput;
  }>;

export type GenerateRecordVariationProposalConsentForProposedVariationPreviewMutation =
  {
    __typename?: "Mutation";
    generateRecordVariationProposalConsentForProposedVariationPreview: {
      __typename?: "RecordVariationProposalConsentForProposedVariationPreview";
      recipients: Array<{
        __typename?: "VariationRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type VariationErrorAgreementDisagreementPromptQueryVariables = Exact<{
  input: VariationErrorAgreementDisagreementPromptInput;
}>;

export type VariationErrorAgreementDisagreementPromptQuery = {
  __typename?: "Query";
  variationErrorAgreementDisagreementPrompt: {
    __typename?: "VariationErrorAgreementDisagreementPrompt";
    remarks: string;
  };
};

export type SendVariationErrorAgreementDisagreementMutationVariables = Exact<{
  input: SendVariationErrorAgreementDisagreementInput;
}>;

export type SendVariationErrorAgreementDisagreementMutation = {
  __typename?: "Mutation";
  sendVariationErrorAgreementDisagreement: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationErrorAgreementDisagreementMutationVariables = Exact<{
  input: RecordVariationErrorAgreementDisagreementInput;
}>;

export type RecordVariationErrorAgreementDisagreementMutation = {
  __typename?: "Mutation";
  recordVariationErrorAgreementDisagreement: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationErrorAgreementDisagreementPreviewMutationVariables =
  Exact<{
    input: SendVariationErrorAgreementDisagreementPreviewInput;
  }>;

export type GenerateSendVariationErrorAgreementDisagreementPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationErrorAgreementDisagreementPreview: {
    __typename?: "SendVariationErrorAgreementDisagreementPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationErrorAgreementDisagreementPreviewMutationVariables =
  Exact<{
    input: RecordVariationErrorAgreementDisagreementPreviewInput;
  }>;

export type GenerateRecordVariationErrorAgreementDisagreementPreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationErrorAgreementDisagreementPreview: {
    __typename?: "RecordVariationErrorAgreementDisagreementPreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationErrorDeterminationDisagreementPromptQueryVariables =
  Exact<{
    input: VariationErrorDeterminationDisagreementPromptInput;
  }>;

export type VariationErrorDeterminationDisagreementPromptQuery = {
  __typename?: "Query";
  variationErrorDeterminationDisagreementPrompt: {
    __typename?: "VariationErrorDeterminationDisagreementPrompt";
    remarks: string;
  };
};

export type SendVariationErrorDeterminationDisagreementMutationVariables =
  Exact<{
    input: SendVariationErrorDeterminationDisagreementInput;
  }>;

export type SendVariationErrorDeterminationDisagreementMutation = {
  __typename?: "Mutation";
  sendVariationErrorDeterminationDisagreement: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationErrorDeterminationDisagreementMutationVariables =
  Exact<{
    input: RecordVariationErrorDeterminationDisagreementInput;
  }>;

export type RecordVariationErrorDeterminationDisagreementMutation = {
  __typename?: "Mutation";
  recordVariationErrorDeterminationDisagreement: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationErrorDeterminationDisagreementPreviewMutationVariables =
  Exact<{
    input: SendVariationErrorDeterminationDisagreementPreviewInput;
  }>;

export type GenerateSendVariationErrorDeterminationDisagreementPreviewMutation =
  {
    __typename?: "Mutation";
    generateSendVariationErrorDeterminationDisagreementPreview: {
      __typename?: "SendVariationErrorDeterminationDisagreementPreview";
      id: string;
      fileUrl: string;
      recipients: Array<{
        __typename?: "VariationRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type GenerateRecordVariationErrorDeterminationDisagreementPreviewMutationVariables =
  Exact<{
    input: RecordVariationErrorDeterminationDisagreementPreviewInput;
  }>;

export type GenerateRecordVariationErrorDeterminationDisagreementPreviewMutation =
  {
    __typename?: "Mutation";
    generateRecordVariationErrorDeterminationDisagreementPreview: {
      __typename?: "RecordVariationErrorDeterminationDisagreementPreview";
      recipients: Array<{
        __typename?: "VariationRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type VariationInstructionCommenceWorkPromptQueryVariables = Exact<{
  input: VariationInstructionCommenceWorkPromptInput;
}>;

export type VariationInstructionCommenceWorkPromptQuery = {
  __typename?: "Query";
  variationInstructionCommenceWorkPrompt: {
    __typename?: "VariationInstructionCommenceWorkPrompt";
    remarks: string;
    agreementSubmitted: boolean;
  };
};

export type SendVariationInstructionCommenceWorkMutationVariables = Exact<{
  input: SendVariationInstructionCommenceWorkInput;
}>;

export type SendVariationInstructionCommenceWorkMutation = {
  __typename?: "Mutation";
  sendVariationInstructionCommenceWork: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationInstructionCommenceWorkMutationVariables = Exact<{
  input: RecordVariationInstructionCommenceWorkInput;
}>;

export type RecordVariationInstructionCommenceWorkMutation = {
  __typename?: "Mutation";
  recordVariationInstructionCommenceWork: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationInstructionCommenceWorkPreviewMutationVariables =
  Exact<{
    input: SendVariationInstructionCommenceWorkPreviewInput;
  }>;

export type GenerateSendVariationInstructionCommenceWorkPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationInstructionCommenceWorkPreview: {
    __typename?: "SendVariationInstructionCommenceWorkPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationInstructionCommenceWorkPreviewMutationVariables =
  Exact<{
    input: RecordVariationInstructionCommenceWorkPreviewInput;
  }>;

export type GenerateRecordVariationInstructionCommenceWorkPreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationInstructionCommenceWorkPreview: {
    __typename?: "RecordVariationInstructionCommenceWorkPreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationAgreementNoticePromptQueryVariables = Exact<{
  input: VariationAgreementNoticePromptInput;
}>;

export type VariationAgreementNoticePromptQuery = {
  __typename?: "Query";
  variationAgreementNoticePrompt: {
    __typename?: "VariationAgreementNoticePrompt";
    draftVariationAgreements: Array<{
      __typename?: "DraftVariationAgreement";
      id: string;
      variationId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    }>;
  };
};

export type SendVariationAgreementNoticeMutationVariables = Exact<{
  input: SendVariationAgreementNoticeInput;
}>;

export type SendVariationAgreementNoticeMutation = {
  __typename?: "Mutation";
  sendVariationAgreementNotice: { __typename?: "VariationItem"; id: string };
};

export type RecordVariationAgreementNoticeMutationVariables = Exact<{
  input: RecordVariationAgreementNoticeInput;
}>;

export type RecordVariationAgreementNoticeMutation = {
  __typename?: "Mutation";
  recordVariationAgreementNotice: { __typename?: "VariationItem"; id: string };
};

export type GenerateSendVariationAgreementNoticePreviewMutationVariables =
  Exact<{
    input: SendVariationAgreementNoticePreviewInput;
  }>;

export type GenerateSendVariationAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationAgreementNoticePreview: {
    __typename?: "SendVariationAgreementNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationAgreementNoticePreviewMutationVariables =
  Exact<{
    input: RecordVariationAgreementNoticePreviewInput;
  }>;

export type GenerateRecordVariationAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationAgreementNoticePreview: {
    __typename?: "RecordVariationAgreementNoticePreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationDeterminationNoticePromptQueryVariables = Exact<{
  input: VariationDeterminationNoticePromptInput;
}>;

export type VariationDeterminationNoticePromptQuery = {
  __typename?: "Query";
  variationDeterminationNoticePrompt: {
    __typename?: "VariationDeterminationNoticePrompt";
    draftVariationDeterminations: Array<{
      __typename?: "DraftVariationDetermination";
      id: string;
      variationId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    }>;
  };
};

export type SendVariationDeterminationNoticeMutationVariables = Exact<{
  input: SendVariationDeterminationNoticeInput;
}>;

export type SendVariationDeterminationNoticeMutation = {
  __typename?: "Mutation";
  sendVariationDeterminationNotice: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationDeterminationNoticeMutationVariables = Exact<{
  input: RecordVariationDeterminationNoticeInput;
}>;

export type RecordVariationDeterminationNoticeMutation = {
  __typename?: "Mutation";
  recordVariationDeterminationNotice: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationDeterminationNoticePreviewMutationVariables =
  Exact<{
    input: SendVariationDeterminationNoticePreviewInput;
  }>;

export type GenerateSendVariationDeterminationNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationDeterminationNoticePreview: {
    __typename?: "SendVariationDeterminationNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationDeterminationNoticePreviewMutationVariables =
  Exact<{
    input: RecordVariationDeterminationNoticePreviewInput;
  }>;

export type GenerateRecordVariationDeterminationNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationDeterminationNoticePreview: {
    __typename?: "RecordVariationDeterminationNoticePreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationErrorAgreementNoticePromptQueryVariables = Exact<{
  input: VariationErrorAgreementNoticePromptInput;
}>;

export type VariationErrorAgreementNoticePromptQuery = {
  __typename?: "Query";
  variationErrorAgreementNoticePrompt: {
    __typename?: "VariationErrorAgreementNoticePrompt";
    details: string;
  };
};

export type SendVariationErrorAgreementNoticeMutationVariables = Exact<{
  input: SendVariationErrorAgreementNoticeInput;
}>;

export type SendVariationErrorAgreementNoticeMutation = {
  __typename?: "Mutation";
  sendVariationErrorAgreementNotice: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationErrorAgreementNoticeMutationVariables = Exact<{
  input: RecordVariationErrorAgreementNoticeInput;
}>;

export type RecordVariationErrorAgreementNoticeMutation = {
  __typename?: "Mutation";
  recordVariationErrorAgreementNotice: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationErrorAgreementNoticePreviewMutationVariables =
  Exact<{
    input: SendVariationErrorAgreementNoticePreviewInput;
  }>;

export type GenerateSendVariationErrorAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationErrorAgreementNoticePreview: {
    __typename?: "SendVariationErrorAgreementNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationErrorAgreementNoticePreviewMutationVariables =
  Exact<{
    input: RecordVariationErrorAgreementNoticePreviewInput;
  }>;

export type GenerateRecordVariationErrorAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationErrorAgreementNoticePreview: {
    __typename?: "RecordVariationErrorAgreementNoticePreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationErrorDeterminationNoticePromptQueryVariables = Exact<{
  input: VariationErrorDeterminationNoticePromptInput;
}>;

export type VariationErrorDeterminationNoticePromptQuery = {
  __typename?: "Query";
  variationErrorDeterminationNoticePrompt: {
    __typename?: "VariationErrorDeterminationNoticePrompt";
    details: string;
  };
};

export type SendVariationErrorDeterminationNoticeMutationVariables = Exact<{
  input: SendVariationErrorDeterminationNoticeInput;
}>;

export type SendVariationErrorDeterminationNoticeMutation = {
  __typename?: "Mutation";
  sendVariationErrorDeterminationNotice: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationErrorDeterminationNoticeMutationVariables = Exact<{
  input: RecordVariationErrorDeterminationNoticeInput;
}>;

export type RecordVariationErrorDeterminationNoticeMutation = {
  __typename?: "Mutation";
  recordVariationErrorDeterminationNotice: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationErrorDeterminationNoticePreviewMutationVariables =
  Exact<{
    input: SendVariationErrorDeterminationNoticePreviewInput;
  }>;

export type GenerateSendVariationErrorDeterminationNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationErrorDeterminationNoticePreview: {
    __typename?: "SendVariationErrorDeterminationNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationErrorDeterminationNoticePreviewMutationVariables =
  Exact<{
    input: RecordVariationErrorDeterminationNoticePreviewInput;
  }>;

export type GenerateRecordVariationErrorDeterminationNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationErrorDeterminationNoticePreview: {
    __typename?: "RecordVariationErrorDeterminationNoticePreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationNoAgreementNoticePromptQueryVariables = Exact<{
  input: VariationNoAgreementNoticePromptInput;
}>;

export type VariationNoAgreementNoticePromptQuery = {
  __typename?: "Query";
  variationNoAgreementNoticePrompt: {
    __typename?: "VariationNoAgreementNoticePrompt";
    remarks: string;
  };
};

export type SendVariationNoAgreementNoticeMutationVariables = Exact<{
  input: SendVariationNoAgreementNoticeInput;
}>;

export type SendVariationNoAgreementNoticeMutation = {
  __typename?: "Mutation";
  sendVariationNoAgreementNotice: { __typename?: "VariationItem"; id: string };
};

export type RecordVariationNoAgreementNoticeMutationVariables = Exact<{
  input: RecordVariationNoAgreementNoticeInput;
}>;

export type RecordVariationNoAgreementNoticeMutation = {
  __typename?: "Mutation";
  recordVariationNoAgreementNotice: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationNoAgreementNoticePreviewMutationVariables =
  Exact<{
    input: SendVariationNoAgreementNoticePreviewInput;
  }>;

export type GenerateSendVariationNoAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationNoAgreementNoticePreview: {
    __typename?: "SendVariationNoAgreementNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationNoAgreementNoticePreviewMutationVariables =
  Exact<{
    input: RecordVariationNoAgreementNoticePreviewInput;
  }>;

export type GenerateRecordVariationNoAgreementNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationNoAgreementNoticePreview: {
    __typename?: "RecordVariationNoAgreementNoticePreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationObjectionVariationPromptQueryVariables = Exact<{
  input: VariationObjectionVariationPromptInput;
}>;

export type VariationObjectionVariationPromptQuery = {
  __typename?: "Query";
  variationObjectionVariationPrompt: {
    __typename?: "VariationObjectionVariationPrompt";
    defaultText: string;
  };
};

export type SendVariationObjectionVariationMutationVariables = Exact<{
  input: SendVariationObjectionVariationInput;
}>;

export type SendVariationObjectionVariationMutation = {
  __typename?: "Mutation";
  sendVariationObjectionVariation: { __typename?: "VariationItem"; id: string };
};

export type RecordVariationObjectionVariationMutationVariables = Exact<{
  input: RecordVariationObjectionVariationInput;
}>;

export type RecordVariationObjectionVariationMutation = {
  __typename?: "Mutation";
  recordVariationObjectionVariation: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationObjectionVariationPreviewMutationVariables =
  Exact<{
    input: SendVariationObjectionVariationPreviewInput;
  }>;

export type GenerateSendVariationObjectionVariationPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationObjectionVariationPreview: {
    __typename?: "SendVariationObjectionVariationPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationObjectionVariationPreviewMutationVariables =
  Exact<{
    input: RecordVariationObjectionVariationPreviewInput;
  }>;

export type GenerateRecordVariationObjectionVariationPreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationObjectionVariationPreview: {
    __typename?: "RecordVariationObjectionVariationPreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationObjectionProposedVariationPromptQueryVariables = Exact<{
  input: VariationObjectionProposedVariationPromptInput;
}>;

export type VariationObjectionProposedVariationPromptQuery = {
  __typename?: "Query";
  variationObjectionProposedVariationPrompt: {
    __typename?: "VariationObjectionProposedVariationPrompt";
    defaultText: string;
  };
};

export type SendVariationObjectionProposedVariationMutationVariables = Exact<{
  input: SendVariationObjectionProposedVariationInput;
}>;

export type SendVariationObjectionProposedVariationMutation = {
  __typename?: "Mutation";
  sendVariationObjectionProposedVariation: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationObjectionProposedVariationMutationVariables = Exact<{
  input: RecordVariationObjectionProposedVariationInput;
}>;

export type RecordVariationObjectionProposedVariationMutation = {
  __typename?: "Mutation";
  recordVariationObjectionProposedVariation: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationObjectionProposedVariationPreviewMutationVariables =
  Exact<{
    input: SendVariationObjectionProposedVariationPreviewInput;
  }>;

export type GenerateSendVariationObjectionProposedVariationPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationObjectionProposedVariationPreview: {
    __typename?: "SendVariationObjectionProposedVariationPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationObjectionProposedVariationPreviewMutationVariables =
  Exact<{
    input: RecordVariationObjectionProposedVariationPreviewInput;
  }>;

export type GenerateRecordVariationObjectionProposedVariationPreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationObjectionProposedVariationPreview: {
    __typename?: "RecordVariationObjectionProposedVariationPreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationFurtherParticularsSubmissionPromptQueryVariables = Exact<{
  input: VariationFurtherParticularsSubmissionPromptInput;
}>;

export type VariationFurtherParticularsSubmissionPromptQuery = {
  __typename?: "Query";
  variationFurtherParticularsSubmissionPrompt: {
    __typename?: "VariationFurtherParticularsSubmissionPrompt";
    details: string;
  };
};

export type SendVariationFurtherParticularsSubmissionMutationVariables = Exact<{
  input: SendVariationFurtherParticularsSubmissionInput;
}>;

export type SendVariationFurtherParticularsSubmissionMutation = {
  __typename?: "Mutation";
  sendVariationFurtherParticularsSubmission: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationFurtherParticularsSubmissionMutationVariables =
  Exact<{
    input: RecordVariationFurtherParticularsSubmissionInput;
  }>;

export type RecordVariationFurtherParticularsSubmissionMutation = {
  __typename?: "Mutation";
  recordVariationFurtherParticularsSubmission: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationFurtherParticularsSubmissionPreviewMutationVariables =
  Exact<{
    input: SendVariationFurtherParticularsSubmissionPreviewInput;
  }>;

export type GenerateSendVariationFurtherParticularsSubmissionPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationFurtherParticularsSubmissionPreview: {
    __typename?: "SendVariationFurtherParticularsSubmissionPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationFurtherParticularsSubmissionPreviewMutationVariables =
  Exact<{
    input: RecordVariationFurtherParticularsSubmissionPreviewInput;
  }>;

export type GenerateRecordVariationFurtherParticularsSubmissionPreviewMutation =
  {
    __typename?: "Mutation";
    generateRecordVariationFurtherParticularsSubmissionPreview: {
      __typename?: "RecordVariationFurtherParticularsSubmissionPreview";
      recipients: Array<{
        __typename?: "VariationRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type VariationFurtherParticularsRequestPromptQueryVariables = Exact<{
  input: VariationFurtherParticularsRequestPromptInput;
}>;

export type VariationFurtherParticularsRequestPromptQuery = {
  __typename?: "Query";
  variationFurtherParticularsRequestPrompt: {
    __typename?: "VariationFurtherParticularsRequestPrompt";
    reasons: string;
  };
};

export type SendVariationFurtherParticularsRequestMutationVariables = Exact<{
  input: SendVariationFurtherParticularsRequestInput;
}>;

export type SendVariationFurtherParticularsRequestMutation = {
  __typename?: "Mutation";
  sendVariationFurtherParticularsRequest: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationFurtherParticularsRequestMutationVariables = Exact<{
  input: RecordVariationFurtherParticularsRequestInput;
}>;

export type RecordVariationFurtherParticularsRequestMutation = {
  __typename?: "Mutation";
  recordVariationFurtherParticularsRequest: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationFurtherParticularsRequestPreviewMutationVariables =
  Exact<{
    input: SendVariationFurtherParticularsRequestPreviewInput;
  }>;

export type GenerateSendVariationFurtherParticularsRequestPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationFurtherParticularsRequestPreview: {
    __typename?: "SendVariationFurtherParticularsRequestPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationFurtherParticularsRequestPreviewMutationVariables =
  Exact<{
    input: RecordVariationFurtherParticularsRequestPreviewInput;
  }>;

export type GenerateRecordVariationFurtherParticularsRequestPreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationFurtherParticularsRequestPreview: {
    __typename?: "RecordVariationFurtherParticularsRequestPreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type VariationRevisedProposalForProposedVariationRequestPromptQueryVariables =
  Exact<{
    input: VariationRevisedProposalForProposedVariationRequestPromptInput;
  }>;

export type VariationRevisedProposalForProposedVariationRequestPromptQuery = {
  __typename?: "Query";
  variationRevisedProposalForProposedVariationRequestPrompt: {
    __typename?: "VariationRevisedProposalForProposedVariationRequestPrompt";
    remarks: string;
  };
};

export type SendVariationRevisedProposalForProposedVariationRequestMutationVariables =
  Exact<{
    input: SendVariationRevisedProposalForProposedVariationRequestInput;
  }>;

export type SendVariationRevisedProposalForProposedVariationRequestMutation = {
  __typename?: "Mutation";
  sendVariationRevisedProposalForProposedVariationRequest: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationRevisedProposalForProposedVariationRequestMutationVariables =
  Exact<{
    input: RecordVariationRevisedProposalForProposedVariationRequestInput;
  }>;

export type RecordVariationRevisedProposalForProposedVariationRequestMutation =
  {
    __typename?: "Mutation";
    recordVariationRevisedProposalForProposedVariationRequest: {
      __typename?: "VariationItem";
      id: string;
    };
  };

export type GenerateSendVariationRevisedProposalForProposedVariationRequestPreviewMutationVariables =
  Exact<{
    input: SendVariationRevisedProposalForProposedVariationRequestPreviewInput;
  }>;

export type GenerateSendVariationRevisedProposalForProposedVariationRequestPreviewMutation =
  {
    __typename?: "Mutation";
    generateSendVariationRevisedProposalForProposedVariationRequestPreview: {
      __typename?: "SendVariationRevisedProposalForProposedVariationRequestPreview";
      id: string;
      fileUrl: string;
      recipients: Array<{
        __typename?: "VariationRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type GenerateRecordVariationRevisedProposalForProposedVariationRequestPreviewMutationVariables =
  Exact<{
    input: RecordVariationRevisedProposalForProposedVariationRequestPreviewInput;
  }>;

export type GenerateRecordVariationRevisedProposalForProposedVariationRequestPreviewMutation =
  {
    __typename?: "Mutation";
    generateRecordVariationRevisedProposalForProposedVariationRequestPreview: {
      __typename?: "RecordVariationRevisedProposalForProposedVariationRequestPreview";
      recipients: Array<{
        __typename?: "VariationRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type VariationProposalForProposedVariationPromptQueryVariables = Exact<{
  input: VariationProposalForProposedVariationPromptInput;
}>;

export type VariationProposalForProposedVariationPromptQuery = {
  __typename?: "Query";
  variationProposalForProposedVariationPrompt: {
    __typename?: "VariationProposalForProposedVariationPrompt";
    draftProposals: Array<{
      __typename?: "DraftVariationProposal";
      id: string;
      variationId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    }>;
  };
};

export type SendVariationProposalForProposedVariationMutationVariables = Exact<{
  input: SendVariationProposalForProposedVariationInput;
}>;

export type SendVariationProposalForProposedVariationMutation = {
  __typename?: "Mutation";
  sendVariationProposalForProposedVariation: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationProposalForProposedVariationMutationVariables =
  Exact<{
    input: RecordVariationProposalForProposedVariationInput;
  }>;

export type RecordVariationProposalForProposedVariationMutation = {
  __typename?: "Mutation";
  recordVariationProposalForProposedVariation: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationProposalForProposedVariationPreviewMutationVariables =
  Exact<{
    input: SendVariationProposalForProposedVariationPreviewInput;
  }>;

export type GenerateSendVariationProposalForProposedVariationPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationProposalForProposedVariationPreview: {
    __typename?: "SendVariationProposalForProposedVariationPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationProposalForProposedVariationPreviewMutationVariables =
  Exact<{
    input: RecordVariationProposalForProposedVariationPreviewInput;
  }>;

export type GenerateRecordVariationProposalForProposedVariationPreviewMutation =
  {
    __typename?: "Mutation";
    generateRecordVariationProposalForProposedVariationPreview: {
      __typename?: "RecordVariationProposalForProposedVariationPreview";
      recipients: Array<{
        __typename?: "VariationRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type VariationProposalForVariationPromptQueryVariables = Exact<{
  input: VariationProposalForVariationPromptInput;
}>;

export type VariationProposalForVariationPromptQuery = {
  __typename?: "Query";
  variationProposalForVariationPrompt: {
    __typename?: "VariationProposalForVariationPrompt";
    draftProposals: Array<{
      __typename?: "DraftVariationProposal";
      id: string;
      variationId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    }>;
  };
};

export type SendVariationProposalForVariationMutationVariables = Exact<{
  input: SendVariationProposalForVariationInput;
}>;

export type SendVariationProposalForVariationMutation = {
  __typename?: "Mutation";
  sendVariationProposalForVariation: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type RecordVariationProposalForVariationMutationVariables = Exact<{
  input: RecordVariationProposalForVariationInput;
}>;

export type RecordVariationProposalForVariationMutation = {
  __typename?: "Mutation";
  recordVariationProposalForVariation: {
    __typename?: "VariationItem";
    id: string;
  };
};

export type GenerateSendVariationProposalForVariationPreviewMutationVariables =
  Exact<{
    input: SendVariationProposalForVariationPreviewInput;
  }>;

export type GenerateSendVariationProposalForVariationPreviewMutation = {
  __typename?: "Mutation";
  generateSendVariationProposalForVariationPreview: {
    __typename?: "SendVariationProposalForVariationPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordVariationProposalForVariationPreviewMutationVariables =
  Exact<{
    input: RecordVariationProposalForVariationPreviewInput;
  }>;

export type GenerateRecordVariationProposalForVariationPreviewMutation = {
  __typename?: "Mutation";
  generateRecordVariationProposalForVariationPreview: {
    __typename?: "RecordVariationProposalForVariationPreview";
    recipients: Array<{
      __typename?: "VariationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type RemoveVariationPreviewMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveVariationPreviewMutation = {
  __typename?: "Mutation";
  removeVariationPreview: boolean;
};

export type AddDraftVariationProposalMutationVariables = Exact<{
  input: AddDraftVariationProposalInput;
}>;

export type AddDraftVariationProposalMutation = {
  __typename?: "Mutation";
  addDraftVariationProposal: {
    __typename?: "DraftVariationProposal";
    id: string;
    variationId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type EditDraftVariationProposalMutationVariables = Exact<{
  input: EditDraftVariationProposalInput;
}>;

export type EditDraftVariationProposalMutation = {
  __typename?: "Mutation";
  editDraftVariationProposal: {
    __typename?: "DraftVariationProposal";
    id: string;
    variationId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type RemoveDraftVariationProposalMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftVariationProposalMutation = {
  __typename?: "Mutation";
  removeDraftVariationProposal: boolean;
};

export type AddDraftVariationAgreementMutationVariables = Exact<{
  input: AddDraftVariationAgreementInput;
}>;

export type AddDraftVariationAgreementMutation = {
  __typename?: "Mutation";
  addDraftVariationAgreement: {
    __typename?: "DraftVariationAgreement";
    id: string;
    variationId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type EditDraftVariationAgreementMutationVariables = Exact<{
  input: EditDraftVariationAgreementInput;
}>;

export type EditDraftVariationAgreementMutation = {
  __typename?: "Mutation";
  editDraftVariationAgreement: {
    __typename?: "DraftVariationAgreement";
    id: string;
    variationId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type RemoveDraftVariationAgreementMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftVariationAgreementMutation = {
  __typename?: "Mutation";
  removeDraftVariationAgreement: boolean;
};

export type AddDraftVariationDeterminationMutationVariables = Exact<{
  input: AddDraftVariationDeterminationInput;
}>;

export type AddDraftVariationDeterminationMutation = {
  __typename?: "Mutation";
  addDraftVariationDetermination: {
    __typename?: "DraftVariationDetermination";
    id: string;
    variationId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type EditDraftVariationDeterminationMutationVariables = Exact<{
  input: EditDraftVariationDeterminationInput;
}>;

export type EditDraftVariationDeterminationMutation = {
  __typename?: "Mutation";
  editDraftVariationDetermination: {
    __typename?: "DraftVariationDetermination";
    id: string;
    variationId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type RemoveDraftVariationDeterminationMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftVariationDeterminationMutation = {
  __typename?: "Mutation";
  removeDraftVariationDetermination: boolean;
};

export type VariationHistoryItemsQueryVariables = Exact<{
  variationId: Scalars["ID"];
}>;

export type VariationHistoryItemsQuery = {
  __typename?: "Query";
  variationHistoryItems: {
    __typename?: "VariationHistoryItemList";
    items: Array<{
      __typename?: "VariationHistoryItem";
      id: string;
      variationId: string;
      actionType: VariationActionType;
      actionNumber: string;
      dateCreated: any;
      dateSent: any;
      dueDate?: any | null;
      sentByUserId: string;
      sentByPartyId?: string | null;
      offline: boolean;
      daysLate: number;
      isIncomplete?: boolean | null;
      answers?:
        | { __typename: "VariationHistoryAbandonmentAnswers"; remarks: string }
        | {
            __typename: "VariationHistoryAgreementAnswers";
            draftAgreementId?: string | null;
            agreement?: {
              __typename?: "VariationAgreement";
              id: string;
              variationId: string;
              reference: string;
              details: string;
              price: number;
              currency: string;
              time: number;
              dateCreated: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "VariationProposalTimeChange";
                number?: number | null;
                description: string;
                days: number;
              }>;
            } | null;
            draftAgreement?: {
              __typename?: "DraftVariationAgreement";
              id: string;
              variationId: string;
              reference: string;
              details: string;
              price: number;
              time: number;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "ContractTimeImpact";
                id: string;
                days: number;
              } | null>;
            } | null;
          }
        | {
            __typename: "VariationHistoryConfirmationVariationAnswers";
            remarks: string;
          }
        | {
            __typename: "VariationHistoryConsentForProposedVariationAnswers";
            remarks: string;
            proposalId: string;
            proposal: {
              __typename?: "VariationProposal";
              id: string;
              variationId: string;
              reference: string;
              details: string;
              price: number;
              currency: string;
              consented: boolean;
              time: number;
              dateCreated: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "VariationProposalTimeChange";
                number?: number | null;
                description: string;
                days: number;
              }>;
            };
          }
        | {
            __typename: "VariationHistoryDeterminationAnswers";
            draftDeterminationId?: string | null;
            determination?: {
              __typename?: "VariationDetermination";
              id: string;
              variationId: string;
              reference: string;
              details: string;
              price: number;
              currency: string;
              time: number;
              dateCreated: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "VariationProposalTimeChange";
                number?: number | null;
                description: string;
                days: number;
              }>;
            } | null;
            draftDetermination?: {
              __typename?: "DraftVariationDetermination";
              id: string;
              variationId: string;
              reference: string;
              details: string;
              price: number;
              time: number;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "ContractTimeImpact";
                id: string;
                days: number;
              } | null>;
            } | null;
          }
        | {
            __typename: "VariationHistoryErrorAgreementDisagreementAnswers";
            remarks: string;
          }
        | {
            __typename: "VariationHistoryErrorAgreementNoticeAnswers";
            details: string;
          }
        | {
            __typename: "VariationHistoryErrorDeterminationDisagreementAnswers";
            remarks: string;
          }
        | {
            __typename: "VariationHistoryErrorDeterminationNoticeAnswers";
            details: string;
          }
        | {
            __typename: "VariationHistoryFurtherParticularsRequestAnswers";
            reasons: string;
          }
        | {
            __typename: "VariationHistoryFurtherParticularsSubmissionAnswers";
            details: string;
          }
        | {
            __typename: "VariationHistoryInstructionCommenceWorkAnswers";
            remarks: string;
            agreementSubmitted: boolean;
          }
        | {
            __typename: "VariationHistoryNoAgreementReachedAnswers";
            remarks: string;
          }
        | {
            __typename: "VariationHistoryObjectionProposedVariationAnswers";
            reasons: string;
          }
        | {
            __typename: "VariationHistoryObjectionVariationAnswers";
            reasons: string;
          }
        | {
            __typename: "VariationHistoryProposalForProposedVariationAnswers";
            proposalId: string;
            proposal: {
              __typename?: "VariationProposal";
              id: string;
              variationId: string;
              reference: string;
              details: string;
              price: number;
              currency: string;
              consented: boolean;
              time: number;
              dateCreated: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "VariationProposalTimeChange";
                number?: number | null;
                description: string;
                days: number;
              }>;
            };
          }
        | {
            __typename: "VariationHistoryProposalForVariationAnswers";
            proposalId: string;
            proposal: {
              __typename?: "VariationProposal";
              id: string;
              variationId: string;
              reference: string;
              details: string;
              price: number;
              currency: string;
              consented: boolean;
              time: number;
              dateCreated: any;
              creatorId: string;
              attachments: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }>;
              sectionalChanges: Array<{
                __typename?: "VariationProposalTimeChange";
                number?: number | null;
                description: string;
                days: number;
              }>;
            };
          }
        | {
            __typename: "VariationHistoryRevisedProposalRequestAnswers";
            remarks: string;
          }
        | {
            __typename: "VariationHistoryRevocationVariationAnswers";
            remarks: string;
          }
        | null;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
      recipients: Array<{
        __typename?: "VariationRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          email: boolean;
        };
      }>;
      authorizationWorkflowAudit?: {
        __typename?: "AuthorizationWorkflowAudit";
        id: string;
        initiatedById: string;
        regardingProductItemId: string;
        regardingProductType: ProductType;
        regardingItemHistoryId?: string | null;
        workflowId: string;
        actionId: string;
        status: AuthorizationWorkflowAuditStatus;
        dateCreated: any;
        dateInitiated: any;
        initiatedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        action: {
          __typename?: "AuthorizationWorkflowActionMapping";
          id: string;
          productOutputActionId: string;
          workflowId: string;
          partyId?: string | null;
          productInstanceId: string;
          status: AuthorizationWorkflowActionMappingStatus;
          dateCreated: any;
          creatorId: string;
          modifiedById: string;
          dateModified: any;
          productOutputAction: {
            __typename?: "ProductOutputAction";
            id: string;
            name: string;
            productId: string;
            isInternal: boolean;
            status: ProductOutputActionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
          };
          workflow: {
            __typename?: "AuthorizationWorkflow";
            id: string;
            name: string;
            status: AuthorizationWorkflowStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            modifiedById: string;
            projectId: string;
            levels: Array<{
              __typename?: "AuthorizationWorkflowLevel";
              sequence: number;
              mode: AuthorizationWorkflowLevelMode;
              userIds: Array<string>;
              users: {
                __typename?: "UserList";
                items: Array<{
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  jobTitle: string;
                  country: string;
                  mobileNumber?: any | null;
                  alternateNumber?: any | null;
                  dateOfBirth?: any | null;
                  profilePicture?: string | null;
                  email: any;
                  companyId: string;
                  registered?: boolean | null;
                  status: UserStatus;
                  lastActive?: any | null;
                  dateInvited: any;
                  registeredDate?: any | null;
                  company: {
                    __typename?: "Company";
                    id: string;
                    tradingName: string;
                    registeredName: string;
                  };
                  invitedBy: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                }>;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            modifiedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              number?: string | null;
              sector: ProjectSector;
              description?: string | null;
              status: ProjectStatus;
              dateCreated: any;
              creatorId: string;
              billingCompanyId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              contracts: {
                __typename?: "ContractList";
                items: Array<{
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ContractStatus;
                }>;
              };
              billingCompany: {
                __typename?: "Company";
                id: string;
                type?: CompanyType | null;
                registeredName: string;
                tradingName: string;
                registrationNumber?: string | null;
                logo?: string | null;
                vatRegistrationNumber?: string | null;
                status: CompanyStatus;
                creatorId: string;
                dateCreated: any;
                physicalAddress?: {
                  __typename?: "Address";
                  line1?: string | null;
                  line2?: string | null;
                  city?: string | null;
                  provinceState?: string | null;
                  country: string;
                  code?: string | null;
                } | null;
                postalAddress?: {
                  __typename?: "Address";
                  line1?: string | null;
                  line2?: string | null;
                  city?: string | null;
                  provinceState?: string | null;
                  country: string;
                  code?: string | null;
                } | null;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              };
            };
          };
          party?: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            contractId: string;
            product:
              | { __typename?: "Claims"; id: string; name: string }
              | { __typename?: "CompEvents"; id: string; name: string }
              | { __typename?: "DailyDiary"; id: string; name: string }
              | { __typename?: "EarlyWarnings"; id: string; name: string }
              | { __typename?: "Events"; id: string; name: string }
              | { __typename?: "Instructions"; id: string; name: string }
              | { __typename?: "RisksRegister"; id: string; name: string }
              | { __typename?: "Variations"; id: string; name: string };
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              valueCurrency: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
            };
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
        regardingProductItem:
          | {
              __typename: "ClaimItem";
              id: string;
              number: string;
              regardingId: string;
              claimRegardingType: ClaimRegardingType;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                contract: {
                  __typename?: "Contract";
                  id: string;
                  timeZone: string;
                };
              };
            }
          | {
              __typename: "CompEventItem";
              id: string;
              number: string;
              productInstanceId: string;
              regardingId: string;
              regardingType: CompEventRegardingType;
              status: CompEventStatus;
              price?: number | null;
              time?: number | null;
              earlyWarningId?: string | null;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              deemingDate?: any | null;
              lastAction: {
                __typename?: "CompEventItemLastAction";
                actionType: CompEventActionType;
                daysLate?: number | null;
                date: any;
              };
              nextStep?: {
                __typename?: "CompEventItemNextStep";
                actionType: CompEventActionType;
                dueDate: any;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                status: ProductInstanceStatus;
                product:
                  | { __typename?: "Claims"; id: string; name: string }
                  | { __typename?: "CompEvents"; id: string; name: string }
                  | { __typename?: "DailyDiary"; id: string; name: string }
                  | { __typename?: "EarlyWarnings"; id: string; name: string }
                  | { __typename?: "Events"; id: string; name: string }
                  | { __typename?: "Instructions"; id: string; name: string }
                  | { __typename?: "RisksRegister"; id: string; name: string }
                  | { __typename?: "Variations"; id: string; name: string };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ContractStatus;
                  timeZone: string;
                  valueCurrency: string;
                  project: {
                    __typename?: "Project";
                    id: string;
                    name: string;
                    friendlyName: string;
                    status: ProjectStatus;
                  };
                };
              };
              earlyWarning?: {
                __typename?: "EarlyWarningItem";
                id: string;
                title: string;
                dateSent: any;
                number: string;
                productInstance: {
                  __typename?: "ProductInstance";
                  id: string;
                  product:
                    | { __typename?: "Claims"; id: string }
                    | { __typename?: "CompEvents"; id: string }
                    | { __typename?: "DailyDiary"; id: string }
                    | { __typename?: "EarlyWarnings"; id: string }
                    | { __typename?: "Events"; id: string }
                    | { __typename?: "Instructions"; id: string }
                    | { __typename?: "RisksRegister"; id: string }
                    | { __typename?: "Variations"; id: string };
                };
              } | null;
              regardingEvent?: {
                __typename?: "EventItem";
                id: string;
                title: string;
                productInstanceId: string;
                number: string;
                data: {
                  __typename?: "ItemData";
                  sections: Array<{
                    __typename?: "ItemDataSection";
                    name: string;
                    entries: Array<{
                      __typename?: "ItemDataEntry";
                      name: string;
                      value: string;
                    }>;
                  }>;
                };
                productInstance: {
                  __typename?: "ProductInstance";
                  id: string;
                  product:
                    | { __typename?: "Claims"; id: string }
                    | { __typename?: "CompEvents"; id: string }
                    | { __typename?: "DailyDiary"; id: string }
                    | { __typename?: "EarlyWarnings"; id: string }
                    | { __typename?: "Events"; id: string }
                    | { __typename?: "Instructions"; id: string }
                    | { __typename?: "RisksRegister"; id: string }
                    | { __typename?: "Variations"; id: string };
                };
              } | null;
              regardingInstruction?: {
                __typename?: "InstructionItem";
                id: string;
                title: string;
                number: string;
                productInstanceId: string;
                data: {
                  __typename?: "ItemData";
                  sections: Array<{
                    __typename?: "ItemDataSection";
                    name: string;
                    entries: Array<{
                      __typename?: "ItemDataEntry";
                      name: string;
                      value: string;
                    }>;
                  }>;
                };
                productInstance: {
                  __typename?: "ProductInstance";
                  id: string;
                  product:
                    | { __typename?: "Claims"; id: string }
                    | { __typename?: "CompEvents"; id: string }
                    | { __typename?: "DailyDiary"; id: string }
                    | { __typename?: "EarlyWarnings"; id: string }
                    | { __typename?: "Events"; id: string }
                    | { __typename?: "Instructions"; id: string }
                    | { __typename?: "RisksRegister"; id: string }
                    | { __typename?: "Variations"; id: string };
                };
              } | null;
              notifiedByParty: {
                __typename?: "ContractBindingType";
                id: string;
                description: string;
                contractTypeId: string;
                status: ContractBindingTypeStatus;
              };
            }
          | {
              __typename?: "DailyDiaryItem";
              id: string;
              date: any;
              number: string;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              sentById?: string | null;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                status: ProductInstanceStatus;
                contract: {
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  timeZone: string;
                  number: string;
                  status: ContractStatus;
                  dateCreated: any;
                  project: {
                    __typename?: "Project";
                    id: string;
                    name: string;
                    friendlyName: string;
                    status: ProjectStatus;
                  };
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    };
              };
            }
          | {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              severity: string;
              number: string;
              productInstanceId: string;
              ownerId: string;
              statusOptionId: string;
              dateCreated: any;
              creatorId: string;
              dateModified: any;
              offline: boolean;
              givenById?: string | null;
              dateSent: any;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              owner: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                productSchema: {
                  __typename?: "ProductSchema";
                  id: string;
                  name: string;
                  isDefault: boolean;
                  productId: string;
                  isInternal: boolean;
                  status: ProductSchemaStatus;
                  creatorId: string;
                  dateCreated: any;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaSections: {
                    __typename?: "SchemaSectionList";
                    items: Array<{
                      __typename?: "SchemaSection";
                      id: string;
                      name: string;
                      displayText: string;
                      displayOrder: number;
                      columnPlacement: ColumnPlacementType;
                      productSchemaId: string;
                      isInternal: boolean;
                      status: SchemaSectionStatus;
                      dateCreated: any;
                      creatorId: string;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      schemaFields: {
                        __typename?: "SchemaFieldList";
                        items: Array<{
                          __typename?: "SchemaField";
                          id: string;
                          name: string;
                          schemaSectionId: string;
                          fieldTypeId: string;
                          displayText: string;
                          displayOrder: number;
                          isRequired: boolean;
                          isInternal: boolean;
                          complexTypeItemId?: string | null;
                          extraData?: string | null;
                          status: SchemaFieldStatus;
                          creatorId: string;
                          dateCreated: any;
                          creator: {
                            __typename?: "User";
                            id: string;
                            firstname: string;
                            surname: string;
                            email: any;
                          };
                          fieldType: {
                            __typename?: "FieldType";
                            id: string;
                            description: string;
                            shortCode: FieldTypeEnum;
                            status: FieldTypeStatus;
                          };
                        }>;
                      };
                    }>;
                  };
                };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  valueCurrency: string;
                  timeZone: string;
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      numberingFormat: string;
                    };
              };
              attachments?: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }> | null;
            }
          | { __typename?: "EventItem" }
          | {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              severity: string;
              number: string;
              productInstanceId: string;
              ownerId: string;
              statusOptionId: string;
              dateCreated: any;
              dateModified: any;
              notifyDueDate: any;
              creatorId: string;
              offline: boolean;
              givenById?: string | null;
              dateSent: any;
              attachments?: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }> | null;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                productSchema: {
                  __typename?: "ProductSchema";
                  id: string;
                  name: string;
                  isDefault: boolean;
                  productId: string;
                  isInternal: boolean;
                  status: ProductSchemaStatus;
                  creatorId: string;
                  dateCreated: any;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaSections: {
                    __typename?: "SchemaSectionList";
                    items: Array<{
                      __typename?: "SchemaSection";
                      id: string;
                      name: string;
                      displayText: string;
                      displayOrder: number;
                      columnPlacement: ColumnPlacementType;
                      productSchemaId: string;
                      isInternal: boolean;
                      status: SchemaSectionStatus;
                      dateCreated: any;
                      creatorId: string;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      schemaFields: {
                        __typename?: "SchemaFieldList";
                        items: Array<{
                          __typename?: "SchemaField";
                          id: string;
                          name: string;
                          schemaSectionId: string;
                          fieldTypeId: string;
                          displayText: string;
                          displayOrder: number;
                          isRequired: boolean;
                          isInternal: boolean;
                          complexTypeItemId?: string | null;
                          extraData?: string | null;
                          status: SchemaFieldStatus;
                          creatorId: string;
                          dateCreated: any;
                          creator: {
                            __typename?: "User";
                            id: string;
                            firstname: string;
                            surname: string;
                            email: any;
                          };
                          fieldType: {
                            __typename?: "FieldType";
                            id: string;
                            description: string;
                            shortCode: FieldTypeEnum;
                            status: FieldTypeStatus;
                          };
                        }>;
                      };
                    }>;
                  };
                };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  contractTypeId: string;
                  valueCurrency: string;
                  timeZone: string;
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      numberingFormat: string;
                    };
              };
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              statusOption: {
                __typename?: "ItemStatusOption";
                id: string;
                displayOrder: number;
                description: string;
                equatesToRemoved: boolean;
                style: ItemStatusOptionStyles;
                statusCollectionId: string;
                isInternal: boolean;
                status: ItemStatusOptionStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              };
              owner: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }
          | { __typename?: "RiskItem" }
          | {
              __typename: "VariationItem";
              id: string;
              number: string;
              regardingId: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                contract: {
                  __typename?: "Contract";
                  id: string;
                  timeZone: string;
                };
              };
            };
        auditTrails?: Array<{
          __typename?: "AuthorizationWorkflowAuditTrail";
          id: string;
          auditId: string;
          regardingProductItemId: string;
          regardingItemHistoryId?: string | null;
          regardingProductType: ProductType;
          productInstanceId: string;
          actionId: string;
          status: AuthorizationActionStatus;
          denialReason?: string | null;
          dateCreated: any;
          creatorId: string;
          productInstance?: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            contractId: string;
            productId: string;
            productSchemaId: string;
            statusCollectionId: string;
            status: ProductInstanceStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              number: string;
              valueCurrency: string;
              value: number;
              startDate?: any | null;
              endDate?: any | null;
              contractTypeId: string;
              projectId: string;
              country: string;
              province?: string | null;
              timeZone: string;
              coordinatesLatitude?: number | null;
              coordinatesLongitude?: number | null;
              status: ContractStatus;
              dateCreated: any;
              creatorId: string;
              sections: Array<{
                __typename?: "ContractSection";
                id: string;
                number?: number | null;
                description: string;
                completionDate: any;
                status: ContractSectionStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
              keyDates: Array<{
                __typename?: "ContractKeyDate";
                id: string;
                number?: number | null;
                conditionToBeMet: string;
                keyDate: any;
                status: ContractKeyDateStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
            statusCollection: {
              __typename?: "ItemStatusCollection";
              id: string;
              name: string;
              isDefault: boolean;
              status: ItemStatusCollectionStatus;
            };
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }> | null;
      } | null;
      sentByUser: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      sentByParty?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
    }>;
  };
};

export type LocalVariationItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LocalVariationItemQuery = {
  __typename?: "Query";
  variationItem?: {
    __typename: "VariationItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    type: VariationType;
    status: VariationStatus;
    priceSought?: number | null;
    priceGranted?: number | null;
    timeSought?: number | null;
    timeGranted?: number | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    ongoingAuthorizationAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      actionId: string;
      workflowId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    } | null;
    lastAction: {
      __typename?: "VariationItemLastAction";
      actionType: VariationActionType;
      date: any;
      daysLate?: number | null;
      party: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      };
    };
    nextStep?: {
      __typename?: "VariationItemNextStep";
      actionType: VariationActionType;
      dueDate: any;
      partyId: string;
      party: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      };
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
  } | null;
};

export type VariationProposalExplorerItemsQueryVariables = Exact<{
  variationId: Scalars["ID"];
}>;

export type VariationProposalExplorerItemsQuery = {
  __typename?: "Query";
  variationProposalExplorerItems: {
    __typename?: "VariationProposalExplorerItemList";
    items: Array<
      | {
          __typename?: "VariationAgreement";
          id: string;
          variationId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "VariationProposalTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        }
      | {
          __typename?: "VariationDetermination";
          id: string;
          variationId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "VariationProposalTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        }
      | {
          __typename?: "VariationProposal";
          id: string;
          variationId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          consented: boolean;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "VariationProposalTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        }
    >;
  };
};

export type DailyDiaryExplorerDataQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DailyDiaryExplorerDataQuery = {
  __typename?: "Query";
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    contract: {
      __typename?: "Contract";
      id: string;
      projectId: string;
      timeZone: string;
    };
    product:
      | { __typename?: "Claims"; id: string }
      | { __typename?: "CompEvents"; id: string }
      | { __typename?: "DailyDiary"; id: string }
      | { __typename?: "EarlyWarnings"; id: string }
      | { __typename?: "Events"; id: string }
      | { __typename?: "Instructions"; id: string }
      | { __typename?: "RisksRegister"; id: string }
      | { __typename?: "Variations"; id: string };
  };
};

export type DailyDiaryDelayRecordsQueryVariables = Exact<{
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
}>;

export type DailyDiaryDelayRecordsQuery = {
  __typename?: "Query";
  dailyDiaryDelayRecords: Array<{
    __typename?: "DailyDiaryDelayRecord";
    id: string;
    description: string;
    delayTypeOptionId: string;
    delayTypeLookupId: string;
    areaOptionId?: string | null;
    areaLookupId?: string | null;
    disciplineOptionId?: string | null;
    disciplineLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    duration?: any | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }>;
};

export type DailyDiaryDelayImportSourceItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
}>;

export type DailyDiaryDelayImportSourceItemsQuery = {
  __typename?: "Query";
  dailyDiaryDelayImportSourceItems: {
    __typename?: "DailyDiaryItemList";
    items: Array<{
      __typename?: "DailyDiaryItem";
      id: string;
      productInstanceId: string;
      date: any;
      number: string;
      status: DailyDiaryItemStatus;
      dateCreated: any;
      creatorId: string;
      sentById?: string | null;
      dateSent?: any | null;
      latestRevision: {
        __typename?: "DailyDiaryItemRevision";
        id: string;
        number: string;
        status: DailyDiaryItemStatus;
        dateSent?: any | null;
      };
    }>;
  };
};

export type ImportDailyDiaryDelayRecordsMutationVariables = Exact<{
  input: ImportDailyDiaryRecordsInput;
}>;

export type ImportDailyDiaryDelayRecordsMutation = {
  __typename?: "Mutation";
  importDailyDiaryDelayRecords: boolean;
};

export type DailyDiaryEquipmentRecordsQueryVariables = Exact<{
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
}>;

export type DailyDiaryEquipmentRecordsQuery = {
  __typename?: "Query";
  dailyDiaryEquipmentRecords: Array<{
    __typename?: "DailyDiaryResourceRecord";
    id: string;
    resourceOptionId: string;
    resourceLookupId: string;
    quantity: number;
    hours: any;
    totalHours: string;
    remarks?: string | null;
    companyOptionId?: string | null;
    companyLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }>;
};

export type DailyDiaryEquipmentImportSourceItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
}>;

export type DailyDiaryEquipmentImportSourceItemsQuery = {
  __typename?: "Query";
  dailyDiaryEquipmentImportSourceItems: {
    __typename?: "DailyDiaryItemList";
    items: Array<{
      __typename?: "DailyDiaryItem";
      id: string;
      productInstanceId: string;
      date: any;
      number: string;
      status: DailyDiaryItemStatus;
      dateCreated: any;
      creatorId: string;
      sentById?: string | null;
      dateSent?: any | null;
      latestRevision: {
        __typename?: "DailyDiaryItemRevision";
        id: string;
        number: string;
        status: DailyDiaryItemStatus;
        dateSent?: any | null;
      };
    }>;
  };
};

export type ImportDailyDiaryEquipmentRecordsMutationVariables = Exact<{
  input: ImportDailyDiaryRecordsInput;
}>;

export type ImportDailyDiaryEquipmentRecordsMutation = {
  __typename?: "Mutation";
  importDailyDiaryEquipmentRecords: boolean;
};

export type DailyDiaryGeneralRecordsQueryVariables = Exact<{
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
}>;

export type DailyDiaryGeneralRecordsQuery = {
  __typename?: "Query";
  dailyDiaryGeneralRecords: Array<{
    __typename?: "DailyDiaryGeneralRecord";
    id: string;
    description: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }>;
};

export type DailyDiaryGeneralImportSourceItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
}>;

export type DailyDiaryGeneralImportSourceItemsQuery = {
  __typename?: "Query";
  dailyDiaryGeneralImportSourceItems: {
    __typename?: "DailyDiaryItemList";
    items: Array<{
      __typename?: "DailyDiaryItem";
      id: string;
      productInstanceId: string;
      date: any;
      number: string;
      status: DailyDiaryItemStatus;
      dateCreated: any;
      creatorId: string;
      sentById?: string | null;
      dateSent?: any | null;
      latestRevision: {
        __typename?: "DailyDiaryItemRevision";
        id: string;
        number: string;
        status: DailyDiaryItemStatus;
        dateSent?: any | null;
      };
    }>;
  };
};

export type ImportDailyDiaryGeneralRecordsMutationVariables = Exact<{
  input: ImportDailyDiaryRecordsInput;
}>;

export type ImportDailyDiaryGeneralRecordsMutation = {
  __typename?: "Mutation";
  importDailyDiaryGeneralRecords: boolean;
};

export type DailyDiaryHseRecordsQueryVariables = Exact<{
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
}>;

export type DailyDiaryHseRecordsQuery = {
  __typename?: "Query";
  dailyDiaryHSERecords: Array<{
    __typename?: "DailyDiaryHSERecord";
    id: string;
    description: string;
    hseTypeOptionId: string;
    hseTypeLookupId: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }>;
};

export type DailyDiaryHseImportSourceItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
}>;

export type DailyDiaryHseImportSourceItemsQuery = {
  __typename?: "Query";
  dailyDiaryHSEImportSourceItems: {
    __typename?: "DailyDiaryItemList";
    items: Array<{
      __typename?: "DailyDiaryItem";
      id: string;
      productInstanceId: string;
      date: any;
      number: string;
      status: DailyDiaryItemStatus;
      dateCreated: any;
      creatorId: string;
      sentById?: string | null;
      dateSent?: any | null;
      latestRevision: {
        __typename?: "DailyDiaryItemRevision";
        id: string;
        number: string;
        status: DailyDiaryItemStatus;
        dateSent?: any | null;
      };
    }>;
  };
};

export type ImportDailyDiaryHseRecordsMutationVariables = Exact<{
  input: ImportDailyDiaryRecordsInput;
}>;

export type ImportDailyDiaryHseRecordsMutation = {
  __typename?: "Mutation";
  importDailyDiaryHSERecords: boolean;
};

export type DailyDiaryManpowerRecordsQueryVariables = Exact<{
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
}>;

export type DailyDiaryManpowerRecordsQuery = {
  __typename?: "Query";
  dailyDiaryManpowerRecords: Array<{
    __typename?: "DailyDiaryResourceRecord";
    id: string;
    resourceOptionId: string;
    resourceLookupId: string;
    quantity: number;
    hours: any;
    totalHours: string;
    remarks?: string | null;
    companyOptionId?: string | null;
    companyLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }>;
};

export type DailyDiaryManpowerImportSourceItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
}>;

export type DailyDiaryManpowerImportSourceItemsQuery = {
  __typename?: "Query";
  dailyDiaryManpowerImportSourceItems: {
    __typename?: "DailyDiaryItemList";
    items: Array<{
      __typename?: "DailyDiaryItem";
      id: string;
      productInstanceId: string;
      date: any;
      number: string;
      status: DailyDiaryItemStatus;
      dateCreated: any;
      creatorId: string;
      sentById?: string | null;
      dateSent?: any | null;
      latestRevision: {
        __typename?: "DailyDiaryItemRevision";
        id: string;
        number: string;
        status: DailyDiaryItemStatus;
        dateSent?: any | null;
      };
    }>;
  };
};

export type ImportDailyDiaryManpowerRecordsMutationVariables = Exact<{
  input: ImportDailyDiaryRecordsInput;
}>;

export type ImportDailyDiaryManpowerRecordsMutation = {
  __typename?: "Mutation";
  importDailyDiaryManpowerRecords: boolean;
};

export type DailyDiaryWeatherRecordsQueryVariables = Exact<{
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
}>;

export type DailyDiaryWeatherRecordsQuery = {
  __typename?: "Query";
  dailyDiaryWeatherRecords: Array<{
    __typename?: "DailyDiaryWeatherRecord";
    id: string;
    conditionsOptionId: string;
    conditionsLookupId: string;
    temperature?: number | null;
    rainFall?: number | null;
    windSpeed?: number | null;
    humidity?: number | null;
    timeOfMeasurement: any;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }>;
};

export type DailyDiaryWeatherImportSourceItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
}>;

export type DailyDiaryWeatherImportSourceItemsQuery = {
  __typename?: "Query";
  dailyDiaryWeatherImportSourceItems: {
    __typename?: "DailyDiaryItemList";
    items: Array<{
      __typename?: "DailyDiaryItem";
      id: string;
      productInstanceId: string;
      date: any;
      number: string;
      status: DailyDiaryItemStatus;
      dateCreated: any;
      creatorId: string;
      sentById?: string | null;
      dateSent?: any | null;
      latestRevision: {
        __typename?: "DailyDiaryItemRevision";
        id: string;
        number: string;
        status: DailyDiaryItemStatus;
        dateSent?: any | null;
      };
    }>;
  };
};

export type ImportDailyDiaryWeatherRecordsMutationVariables = Exact<{
  input: ImportDailyDiaryRecordsInput;
}>;

export type ImportDailyDiaryWeatherRecordsMutation = {
  __typename?: "Mutation";
  importDailyDiaryWeatherRecords: boolean;
};

export type DailyDiaryWorkRecordsQueryVariables = Exact<{
  dailyDiaryId: Scalars["ID"];
  revisionId?: InputMaybe<Scalars["ID"]>;
}>;

export type DailyDiaryWorkRecordsQuery = {
  __typename?: "Query";
  dailyDiaryWorkRecords: Array<{
    __typename?: "DailyDiaryWorkRecord";
    id: string;
    description?: string | null;
    areaOptionId?: string | null;
    areaLookupId?: string | null;
    disciplineOptionId?: string | null;
    disciplineLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    activityOptionId?: string | null;
    activityLookupId?: string | null;
    workTypeOptionId?: string | null;
    workTypeLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    amount?: number | null;
    unitOfMeasurementOptionId?: string | null;
    unitOfMeasurementLookupId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }>;
};

export type DailyDiaryWorkImportSourceItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  dateFrom: Scalars["AWSDate"];
  dateTo: Scalars["AWSDate"];
}>;

export type DailyDiaryWorkImportSourceItemsQuery = {
  __typename?: "Query";
  dailyDiaryWorkImportSourceItems: {
    __typename?: "DailyDiaryItemList";
    items: Array<{
      __typename?: "DailyDiaryItem";
      id: string;
      productInstanceId: string;
      date: any;
      number: string;
      status: DailyDiaryItemStatus;
      dateCreated: any;
      creatorId: string;
      sentById?: string | null;
      dateSent?: any | null;
      latestRevision: {
        __typename?: "DailyDiaryItemRevision";
        id: string;
        number: string;
        status: DailyDiaryItemStatus;
        dateSent?: any | null;
      };
    }>;
  };
};

export type ImportDailyDiaryWorkRecordsMutationVariables = Exact<{
  input: ImportDailyDiaryRecordsInput;
}>;

export type ImportDailyDiaryWorkRecordsMutation = {
  __typename?: "Mutation";
  importDailyDiaryWorkRecords: boolean;
};

export type SendDailyDiaryItemMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SendDailyDiaryItemMutation = {
  __typename?: "Mutation";
  sendDailyDiaryItem: { __typename?: "DailyDiaryItem"; id: string };
};

export type AttachmentFieldsFragment = {
  __typename?: "Attachment";
  id: string;
  fileName: string;
  fileUrl: string;
  mimeType: string;
  description?: string | null;
  locked: boolean;
  fileSize: number;
  status: AttachmentStatus;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    company: { __typename?: "Company"; registeredName: string };
  };
};

export type StatusOptionFieldsFragment = {
  __typename?: "ItemStatusOption";
  id: string;
  description: string;
  equatesToRemoved: boolean;
  statusCollectionId: string;
  isInternal: boolean;
  status: ItemStatusOptionStatus;
};

export type LightUserFieldsFragment = {
  __typename?: "User";
  id: string;
  firstname: string;
  surname: string;
};

export type EarlyWarningItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EarlyWarningItemQuery = {
  __typename?: "Query";
  earlyWarningItem?: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    creatorId: string;
    dateModified: any;
    offline: boolean;
    givenById?: string | null;
    dateSent: any;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      description: string;
      equatesToRemoved: boolean;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        number: string;
        valueCurrency: string;
        value: number;
        startDate?: any | null;
        endDate?: any | null;
        contractTypeId: string;
        projectId: string;
        country: string;
        province?: string | null;
        timeZone: string;
        coordinatesLatitude?: number | null;
        coordinatesLongitude?: number | null;
        status: ContractStatus;
        dateCreated: any;
        creatorId: string;
        sections: Array<{
          __typename?: "ContractSection";
          id: string;
          number?: number | null;
          description: string;
          completionDate: any;
          status: ContractSectionStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        keyDates: Array<{
          __typename?: "ContractKeyDate";
          id: string;
          number?: number | null;
          conditionToBeMet: string;
          keyDate: any;
          status: ContractKeyDateStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
        contractType: {
          __typename?: "ContractType";
          id: string;
          description: string;
          subType?: string | null;
          version?: string | null;
          status: ContractTypeStatus;
        };
      };
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      statusCollection: {
        __typename?: "ItemStatusCollection";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ItemStatusCollectionStatus;
        creatorId: string;
        statusOptions: {
          __typename?: "ItemStatusOptionList";
          items: Array<{
            __typename?: "ItemStatusOption";
            id: string;
            displayOrder: number;
            description: string;
            equatesToRemoved: boolean;
            style: ItemStatusOptionStyles;
            statusCollectionId: string;
            isInternal: boolean;
            status: ItemStatusOptionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            optionReasons: {
              __typename?: "ItemStatusOptionReasonList";
              items: Array<{
                __typename?: "ItemStatusOptionReason";
                id: string;
                displayOrder: number;
                description: string;
                statusOptionId: string;
                isInternal: boolean;
                status: ItemStatusOptionReasonStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
        };
      };
    };
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    authorizationWorkflowAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      workflowId: string;
      actionId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      dateInitiated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      regardingProductItem:
        | {
            __typename: "ClaimItem";
            id: string;
            number: string;
            regardingId: string;
            claimRegardingType: ClaimRegardingType;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename: "CompEventItem";
            id: string;
            number: string;
            productInstanceId: string;
            regardingId: string;
            regardingType: CompEventRegardingType;
            status: CompEventStatus;
            price?: number | null;
            time?: number | null;
            earlyWarningId?: string | null;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            deemingDate?: any | null;
            lastAction: {
              __typename?: "CompEventItemLastAction";
              actionType: CompEventActionType;
              daysLate?: number | null;
              date: any;
            };
            nextStep?: {
              __typename?: "CompEventItemNextStep";
              actionType: CompEventActionType;
              dueDate: any;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              status: ProductInstanceStatus;
              product:
                | { __typename?: "Claims"; id: string; name: string }
                | { __typename?: "CompEvents"; id: string; name: string }
                | { __typename?: "DailyDiary"; id: string; name: string }
                | { __typename?: "EarlyWarnings"; id: string; name: string }
                | { __typename?: "Events"; id: string; name: string }
                | { __typename?: "Instructions"; id: string; name: string }
                | { __typename?: "RisksRegister"; id: string; name: string }
                | { __typename?: "Variations"; id: string; name: string };
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
                timeZone: string;
                valueCurrency: string;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
            };
            earlyWarning?: {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              dateSent: any;
              number: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingEvent?: {
              __typename?: "EventItem";
              id: string;
              title: string;
              productInstanceId: string;
              number: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingInstruction?: {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              number: string;
              productInstanceId: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            notifiedByParty: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          }
        | {
            __typename?: "DailyDiaryItem";
            id: string;
            date: any;
            number: string;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            sentById?: string | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              status: ProductInstanceStatus;
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                timeZone: string;
                number: string;
                status: ContractStatus;
                dateCreated: any;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
              product:
                | {
                    __typename?: "Claims";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Events";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Instructions";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  };
            };
          }
        | {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            creatorId: string;
            dateModified: any;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
          }
        | { __typename?: "EventItem" }
        | {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            dateModified: any;
            notifyDueDate: any;
            creatorId: string;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                contractTypeId: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            statusOption: {
              __typename?: "ItemStatusOption";
              id: string;
              displayOrder: number;
              description: string;
              equatesToRemoved: boolean;
              style: ItemStatusOptionStyles;
              statusCollectionId: string;
              isInternal: boolean;
              status: ItemStatusOptionStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }
        | { __typename?: "RiskItem" }
        | {
            __typename: "VariationItem";
            id: string;
            number: string;
            regardingId: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    } | null;
    notificationRecipients: Array<{
      __typename?: "NotificationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  } | null;
};

export type EventItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EventItemQuery = {
  __typename?: "Query";
  eventItem: {
    __typename?: "EventItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    creatorId: string;
    dateModified: any;
    notifyDueDate: any;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        number: string;
        valueCurrency: string;
        value: number;
        startDate?: any | null;
        endDate?: any | null;
        contractTypeId: string;
        projectId: string;
        country: string;
        province?: string | null;
        timeZone: string;
        coordinatesLatitude?: number | null;
        coordinatesLongitude?: number | null;
        status: ContractStatus;
        dateCreated: any;
        creatorId: string;
        sections: Array<{
          __typename?: "ContractSection";
          id: string;
          number?: number | null;
          description: string;
          completionDate: any;
          status: ContractSectionStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        keyDates: Array<{
          __typename?: "ContractKeyDate";
          id: string;
          number?: number | null;
          conditionToBeMet: string;
          keyDate: any;
          status: ContractKeyDateStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
        contractType: {
          __typename?: "ContractType";
          id: string;
          description: string;
          subType?: string | null;
          version?: string | null;
          status: ContractTypeStatus;
        };
      };
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      statusCollection: {
        __typename?: "ItemStatusCollection";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ItemStatusCollectionStatus;
        creatorId: string;
        statusOptions: {
          __typename?: "ItemStatusOptionList";
          items: Array<{
            __typename?: "ItemStatusOption";
            id: string;
            displayOrder: number;
            description: string;
            equatesToRemoved: boolean;
            style: ItemStatusOptionStyles;
            statusCollectionId: string;
            isInternal: boolean;
            status: ItemStatusOptionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            optionReasons: {
              __typename?: "ItemStatusOptionReasonList";
              items: Array<{
                __typename?: "ItemStatusOptionReason";
                id: string;
                displayOrder: number;
                description: string;
                statusOptionId: string;
                isInternal: boolean;
                status: ItemStatusOptionReasonStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
        };
      };
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      description: string;
      equatesToRemoved: boolean;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    compEvent?: { __typename?: "CompEventItem"; id: string } | null;
    claim?: { __typename?: "ClaimItem"; id: string } | null;
    notificationRecipients: Array<{
      __typename?: "NotificationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type InstructionItemExtendedQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InstructionItemExtendedQuery = {
  __typename?: "Query";
  instructionItem?: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    notifyDueDate: any;
    creatorId: string;
    offline: boolean;
    givenById?: string | null;
    dateSent: any;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        number: string;
        valueCurrency: string;
        value: number;
        startDate?: any | null;
        endDate?: any | null;
        contractTypeId: string;
        projectId: string;
        country: string;
        province?: string | null;
        timeZone: string;
        coordinatesLatitude?: number | null;
        coordinatesLongitude?: number | null;
        status: ContractStatus;
        dateCreated: any;
        creatorId: string;
        sections: Array<{
          __typename?: "ContractSection";
          id: string;
          number?: number | null;
          description: string;
          completionDate: any;
          status: ContractSectionStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        keyDates: Array<{
          __typename?: "ContractKeyDate";
          id: string;
          number?: number | null;
          conditionToBeMet: string;
          keyDate: any;
          status: ContractKeyDateStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
        contractType: {
          __typename?: "ContractType";
          id: string;
          description: string;
          subType?: string | null;
          version?: string | null;
          status: ContractTypeStatus;
        };
      };
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      statusCollection: {
        __typename?: "ItemStatusCollection";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ItemStatusCollectionStatus;
        creatorId: string;
        statusOptions: {
          __typename?: "ItemStatusOptionList";
          items: Array<{
            __typename?: "ItemStatusOption";
            id: string;
            displayOrder: number;
            description: string;
            equatesToRemoved: boolean;
            style: ItemStatusOptionStyles;
            statusCollectionId: string;
            isInternal: boolean;
            status: ItemStatusOptionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            optionReasons: {
              __typename?: "ItemStatusOptionReasonList";
              items: Array<{
                __typename?: "ItemStatusOptionReason";
                id: string;
                displayOrder: number;
                description: string;
                statusOptionId: string;
                isInternal: boolean;
                status: ItemStatusOptionReasonStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
        };
      };
    };
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    compEvent?: { __typename?: "CompEventItem"; id: string } | null;
    claim?: { __typename?: "ClaimItem"; id: string } | null;
    variation?: { __typename?: "VariationItem"; id: string } | null;
    authorizationWorkflowAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      workflowId: string;
      actionId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      dateInitiated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      regardingProductItem:
        | {
            __typename: "ClaimItem";
            id: string;
            number: string;
            regardingId: string;
            claimRegardingType: ClaimRegardingType;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename: "CompEventItem";
            id: string;
            number: string;
            productInstanceId: string;
            regardingId: string;
            regardingType: CompEventRegardingType;
            status: CompEventStatus;
            price?: number | null;
            time?: number | null;
            earlyWarningId?: string | null;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            deemingDate?: any | null;
            lastAction: {
              __typename?: "CompEventItemLastAction";
              actionType: CompEventActionType;
              daysLate?: number | null;
              date: any;
            };
            nextStep?: {
              __typename?: "CompEventItemNextStep";
              actionType: CompEventActionType;
              dueDate: any;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              status: ProductInstanceStatus;
              product:
                | { __typename?: "Claims"; id: string; name: string }
                | { __typename?: "CompEvents"; id: string; name: string }
                | { __typename?: "DailyDiary"; id: string; name: string }
                | { __typename?: "EarlyWarnings"; id: string; name: string }
                | { __typename?: "Events"; id: string; name: string }
                | { __typename?: "Instructions"; id: string; name: string }
                | { __typename?: "RisksRegister"; id: string; name: string }
                | { __typename?: "Variations"; id: string; name: string };
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
                timeZone: string;
                valueCurrency: string;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
            };
            earlyWarning?: {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              dateSent: any;
              number: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingEvent?: {
              __typename?: "EventItem";
              id: string;
              title: string;
              productInstanceId: string;
              number: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingInstruction?: {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              number: string;
              productInstanceId: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            notifiedByParty: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          }
        | {
            __typename?: "DailyDiaryItem";
            id: string;
            date: any;
            number: string;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            sentById?: string | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              status: ProductInstanceStatus;
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                timeZone: string;
                number: string;
                status: ContractStatus;
                dateCreated: any;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
              product:
                | {
                    __typename?: "Claims";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Events";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Instructions";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  };
            };
          }
        | {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            creatorId: string;
            dateModified: any;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
          }
        | { __typename?: "EventItem" }
        | {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            dateModified: any;
            notifyDueDate: any;
            creatorId: string;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                contractTypeId: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            statusOption: {
              __typename?: "ItemStatusOption";
              id: string;
              displayOrder: number;
              description: string;
              equatesToRemoved: boolean;
              style: ItemStatusOptionStyles;
              statusCollectionId: string;
              isInternal: boolean;
              status: ItemStatusOptionStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }
        | { __typename?: "RiskItem" }
        | {
            __typename: "VariationItem";
            id: string;
            number: string;
            regardingId: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    } | null;
    notificationRecipients: Array<{
      __typename?: "NotificationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  } | null;
};

export type SendEarlyWarningItemMutationVariables = Exact<{
  input: SendEarlyWarningItemInput;
  draftId?: InputMaybe<Scalars["ID"]>;
}>;

export type SendEarlyWarningItemMutation = {
  __typename?: "Mutation";
  sendEarlyWarningItem: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
  };
};

export type RecordEarlyWarningItemMutationVariables = Exact<{
  input: RecordEarlyWarningItemInput;
  draftId?: InputMaybe<Scalars["ID"]>;
}>;

export type RecordEarlyWarningItemMutation = {
  __typename?: "Mutation";
  recordEarlyWarningItem: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
  };
};

export type AddDraftEarlyWarningItemMutationVariables = Exact<{
  input: SendEarlyWarningItemInput;
}>;

export type AddDraftEarlyWarningItemMutation = {
  __typename?: "Mutation";
  addDraftEarlyWarningItem: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
  };
};

export type EditDraftEarlyWarningItemMutationVariables = Exact<{
  input: EditDraftEarlyWarningItemInput;
}>;

export type EditDraftEarlyWarningItemMutation = {
  __typename?: "Mutation";
  editDraftEarlyWarningItem: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
  };
};

export type GenerateEarlyWarningPreviewMutationVariables = Exact<{
  input: SendEarlyWarningItemInput;
}>;

export type GenerateEarlyWarningPreviewMutation = {
  __typename?: "Mutation";
  generateEarlyWarningPreview: {
    __typename?: "EarlyWarningItemPreview";
    id: string;
    fileUrl: string;
    actionMapping?: {
      __typename?: "AuthorizationWorkflowActionMapping";
      id: string;
      productOutputActionId: string;
      workflowId: string;
      partyId?: string | null;
      productInstanceId: string;
      status: AuthorizationWorkflowActionMappingStatus;
      dateCreated: any;
      creatorId: string;
      modifiedById: string;
      dateModified: any;
      productOutputAction: {
        __typename?: "ProductOutputAction";
        id: string;
        name: string;
        productId: string;
        isInternal: boolean;
        status: ProductOutputActionStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      party?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        contractId: string;
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
        contract: {
          __typename?: "Contract";
          id: string;
          friendlyName: string;
          timeZone: string;
          valueCurrency: string;
          project: { __typename?: "Project"; id: string; friendlyName: string };
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    } | null;
    recipients: Array<{
      __typename?: "EarlyWarningRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type RemoveEarlyWarningPreviewMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveEarlyWarningPreviewMutation = {
  __typename?: "Mutation";
  removeEarlyWarningPreview: boolean;
};

export type DraftEarlyWarningItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DraftEarlyWarningItemQuery = {
  __typename?: "Query";
  draftEarlyWarningItem?: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    creatorId: string;
    dateModified: any;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      description: string;
      equatesToRemoved: boolean;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
      };
    }> | null;
  } | null;
};

export type AddRiskItemMutationVariables = Exact<{
  input: AddRiskItemInput;
  draftId?: InputMaybe<Scalars["ID"]>;
}>;

export type AddRiskItemMutation = {
  __typename?: "Mutation";
  addRiskItem: {
    __typename?: "RiskItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    creatorId: string;
    dateModified: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      description: string;
      equatesToRemoved: boolean;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type AddDraftRiskItemMutationVariables = Exact<{
  input: AddRiskItemInput;
}>;

export type AddDraftRiskItemMutation = {
  __typename?: "Mutation";
  addDraftRiskItem: {
    __typename?: "RiskItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    creatorId: string;
    dateModified: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      description: string;
      equatesToRemoved: boolean;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type EditDraftRiskItemMutationVariables = Exact<{
  input: EditRiskItemInput;
}>;

export type EditDraftRiskItemMutation = {
  __typename?: "Mutation";
  editDraftRiskItem: {
    __typename?: "RiskItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    creatorId: string;
    dateModified: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      description: string;
      equatesToRemoved: boolean;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type DraftRiskItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DraftRiskItemQuery = {
  __typename?: "Query";
  draftRiskItem: {
    __typename?: "RiskItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    creatorId: string;
    dateModified: any;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      description: string;
      equatesToRemoved: boolean;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
      };
    }> | null;
  };
};

export type EventItemRecipientsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type EventItemRecipientsQuery = {
  __typename?: "Query";
  eventItemRecipients: Array<{
    __typename?: "NotificationRecipient";
    userId: string;
    name: string;
    preference: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
  }>;
};

export type RiskItemRecipientsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type RiskItemRecipientsQuery = {
  __typename?: "Query";
  riskItemRecipients: Array<{
    __typename?: "NotificationRecipient";
    userId: string;
    name: string;
    preference: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
  }>;
};

export type ProductInstanceFieldsFragment = {
  __typename?: "ProductInstance";
  id: string;
  description: string;
  contract: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
    number: string;
    valueCurrency: string;
    value: number;
    startDate?: any | null;
    endDate?: any | null;
    contractTypeId: string;
    projectId: string;
    country: string;
    province?: string | null;
    timeZone: string;
    coordinatesLatitude?: number | null;
    coordinatesLongitude?: number | null;
    status: ContractStatus;
    dateCreated: any;
    creatorId: string;
    sections: Array<{
      __typename?: "ContractSection";
      id: string;
      number?: number | null;
      description: string;
      completionDate: any;
      status: ContractSectionStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
    keyDates: Array<{
      __typename?: "ContractKeyDate";
      id: string;
      number?: number | null;
      conditionToBeMet: string;
      keyDate: any;
      status: ContractKeyDateStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      friendlyName: string;
      status: ProjectStatus;
    };
    contractType: {
      __typename?: "ContractType";
      id: string;
      description: string;
      subType?: string | null;
      version?: string | null;
      status: ContractTypeStatus;
    };
  };
  product:
    | { __typename?: "Claims"; id: string; name: string }
    | { __typename?: "CompEvents"; id: string; name: string }
    | { __typename?: "DailyDiary"; id: string; name: string }
    | { __typename?: "EarlyWarnings"; id: string; name: string }
    | { __typename?: "Events"; id: string; name: string }
    | { __typename?: "Instructions"; id: string; name: string }
    | { __typename?: "RisksRegister"; id: string; name: string }
    | { __typename?: "Variations"; id: string; name: string };
  productSchema: {
    __typename?: "ProductSchema";
    id: string;
    name: string;
    isDefault: boolean;
    productId: string;
    isInternal: boolean;
    status: ProductSchemaStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    schemaSections: {
      __typename?: "SchemaSectionList";
      items: Array<{
        __typename?: "SchemaSection";
        id: string;
        name: string;
        displayText: string;
        displayOrder: number;
        columnPlacement: ColumnPlacementType;
        productSchemaId: string;
        isInternal: boolean;
        status: SchemaSectionStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaFields: {
          __typename?: "SchemaFieldList";
          items: Array<{
            __typename?: "SchemaField";
            id: string;
            name: string;
            schemaSectionId: string;
            fieldTypeId: string;
            displayText: string;
            displayOrder: number;
            isRequired: boolean;
            isInternal: boolean;
            complexTypeItemId?: string | null;
            extraData?: string | null;
            status: SchemaFieldStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            fieldType: {
              __typename?: "FieldType";
              id: string;
              description: string;
              shortCode: FieldTypeEnum;
              status: FieldTypeStatus;
            };
          }>;
        };
      }>;
    };
  };
  statusCollection: {
    __typename?: "ItemStatusCollection";
    id: string;
    name: string;
    isDefault: boolean;
    productId: string;
    isInternal: boolean;
    status: ItemStatusCollectionStatus;
    creatorId: string;
    statusOptions: {
      __typename?: "ItemStatusOptionList";
      items: Array<{
        __typename?: "ItemStatusOption";
        id: string;
        displayOrder: number;
        description: string;
        equatesToRemoved: boolean;
        style: ItemStatusOptionStyles;
        statusCollectionId: string;
        isInternal: boolean;
        status: ItemStatusOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        optionReasons: {
          __typename?: "ItemStatusOptionReasonList";
          items: Array<{
            __typename?: "ItemStatusOptionReason";
            id: string;
            displayOrder: number;
            description: string;
            statusOptionId: string;
            isInternal: boolean;
            status: ItemStatusOptionReasonStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
    };
  };
};

export type RiskItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RiskItemQuery = {
  __typename?: "Query";
  riskItem: {
    __typename?: "RiskItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    creatorId: string;
    dateModified: any;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        number: string;
        valueCurrency: string;
        value: number;
        startDate?: any | null;
        endDate?: any | null;
        contractTypeId: string;
        projectId: string;
        country: string;
        province?: string | null;
        timeZone: string;
        coordinatesLatitude?: number | null;
        coordinatesLongitude?: number | null;
        status: ContractStatus;
        dateCreated: any;
        creatorId: string;
        sections: Array<{
          __typename?: "ContractSection";
          id: string;
          number?: number | null;
          description: string;
          completionDate: any;
          status: ContractSectionStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        keyDates: Array<{
          __typename?: "ContractKeyDate";
          id: string;
          number?: number | null;
          conditionToBeMet: string;
          keyDate: any;
          status: ContractKeyDateStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
        contractType: {
          __typename?: "ContractType";
          id: string;
          description: string;
          subType?: string | null;
          version?: string | null;
          status: ContractTypeStatus;
        };
      };
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      statusCollection: {
        __typename?: "ItemStatusCollection";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ItemStatusCollectionStatus;
        creatorId: string;
        statusOptions: {
          __typename?: "ItemStatusOptionList";
          items: Array<{
            __typename?: "ItemStatusOption";
            id: string;
            displayOrder: number;
            description: string;
            equatesToRemoved: boolean;
            style: ItemStatusOptionStyles;
            statusCollectionId: string;
            isInternal: boolean;
            status: ItemStatusOptionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            optionReasons: {
              __typename?: "ItemStatusOptionReasonList";
              items: Array<{
                __typename?: "ItemStatusOptionReason";
                id: string;
                displayOrder: number;
                description: string;
                statusOptionId: string;
                isInternal: boolean;
                status: ItemStatusOptionReasonStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
        };
      };
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      description: string;
      equatesToRemoved: boolean;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    notificationRecipients: Array<{
      __typename?: "NotificationRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type ProductItemAuthWorkflowAuditFieldsFragment = {
  __typename?: "AuthorizationWorkflowAudit";
  id: string;
  initiatedById: string;
  regardingProductItemId: string;
  regardingProductType: ProductType;
  regardingItemHistoryId?: string | null;
  actionId: string;
  workflowId: string;
  status: AuthorizationWorkflowAuditStatus;
  dateCreated: any;
  initiatedBy: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  action: {
    __typename?: "AuthorizationWorkflowActionMapping";
    id: string;
    productOutputActionId: string;
    workflowId: string;
    partyId?: string | null;
    productInstanceId: string;
    status: AuthorizationWorkflowActionMappingStatus;
    dateCreated: any;
    creatorId: string;
    modifiedById: string;
    dateModified: any;
    productOutputAction: {
      __typename?: "ProductOutputAction";
      id: string;
      name: string;
      productId: string;
      isInternal: boolean;
      status: ProductOutputActionStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      product:
        | {
            __typename?: "Claims";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "CompEvents";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "DailyDiary";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "EarlyWarnings";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Events";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Instructions";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "RisksRegister";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Variations";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          };
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    party?: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    } | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contractId: string;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        friendlyName: string;
        timeZone: string;
        valueCurrency: string;
        project: { __typename?: "Project"; id: string; friendlyName: string };
      };
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
  workflow: {
    __typename?: "AuthorizationWorkflow";
    id: string;
    name: string;
    status: AuthorizationWorkflowStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    modifiedById: string;
    projectId: string;
    levels: Array<{
      __typename?: "AuthorizationWorkflowLevel";
      sequence: number;
      mode: AuthorizationWorkflowLevelMode;
      userIds: Array<string>;
      users: {
        __typename?: "UserList";
        items: Array<{
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          jobTitle: string;
          country: string;
          mobileNumber?: any | null;
          alternateNumber?: any | null;
          dateOfBirth?: any | null;
          profilePicture?: string | null;
          email: any;
          companyId: string;
          registered?: boolean | null;
          status: UserStatus;
          lastActive?: any | null;
          dateInvited: any;
          registeredDate?: any | null;
          company: {
            __typename?: "Company";
            id: string;
            tradingName: string;
            registeredName: string;
          };
          invitedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      friendlyName: string;
      number?: string | null;
      sector: ProjectSector;
      description?: string | null;
      status: ProjectStatus;
      dateCreated: any;
      creatorId: string;
      billingCompanyId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contracts: {
        __typename?: "ContractList";
        items: Array<{
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          status: ContractStatus;
        }>;
      };
      billingCompany: {
        __typename?: "Company";
        id: string;
        type?: CompanyType | null;
        registeredName: string;
        tradingName: string;
        registrationNumber?: string | null;
        logo?: string | null;
        vatRegistrationNumber?: string | null;
        status: CompanyStatus;
        creatorId: string;
        dateCreated: any;
        physicalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        postalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
    };
  };
  auditTrails?: Array<{
    __typename?: "AuthorizationWorkflowAuditTrail";
    id: string;
    auditId: string;
    regardingProductItemId: string;
    regardingItemHistoryId?: string | null;
    regardingProductType: ProductType;
    productInstanceId: string;
    actionId: string;
    status: AuthorizationActionStatus;
    denialReason?: string | null;
    dateCreated: any;
    creatorId: string;
    productInstance?: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      soloModeSupported: boolean;
      contractId: string;
      productId: string;
      productSchemaId: string;
      statusCollectionId: string;
      status: ProductInstanceStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        number: string;
        valueCurrency: string;
        value: number;
        startDate?: any | null;
        endDate?: any | null;
        contractTypeId: string;
        projectId: string;
        country: string;
        province?: string | null;
        timeZone: string;
        coordinatesLatitude?: number | null;
        coordinatesLongitude?: number | null;
        status: ContractStatus;
        dateCreated: any;
        creatorId: string;
        sections: Array<{
          __typename?: "ContractSection";
          id: string;
          number?: number | null;
          description: string;
          completionDate: any;
          status: ContractSectionStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        keyDates: Array<{
          __typename?: "ContractKeyDate";
          id: string;
          number?: number | null;
          conditionToBeMet: string;
          keyDate: any;
          status: ContractKeyDateStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
        contractType: {
          __typename?: "ContractType";
          id: string;
          description: string;
          subType?: string | null;
          version?: string | null;
          status: ContractTypeStatus;
        };
      };
      product:
        | {
            __typename?: "Claims";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "CompEvents";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "DailyDiary";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "EarlyWarnings";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Events";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Instructions";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "RisksRegister";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Variations";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          };
      statusCollection: {
        __typename?: "ItemStatusCollection";
        id: string;
        name: string;
        isDefault: boolean;
        status: ItemStatusCollectionStatus;
      };
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }> | null;
};

export type ClaimItemsRegisterQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type ClaimItemsRegisterQuery = {
  __typename?: "Query";
  claimItems: {
    __typename?: "ClaimItemList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "ClaimItem";
      id: string;
      number: string;
      productInstanceId: string;
      regardingId: string;
      regardingType: ClaimRegardingType;
      status: ClaimStatus;
      priceSought?: number | null;
      priceGranted?: number | null;
      timeSought?: number | null;
      timeGranted?: number | null;
      advanceWarningId?: string | null;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      notifiedByPartyId: string;
      lastAction: {
        __typename?: "ClaimItemLastAction";
        actionType: ClaimActionType;
        date: any;
        daysLate?: number | null;
      };
      nextStep?: {
        __typename?: "ClaimItemNextStep";
        actionType: ClaimActionType;
        dueDate: any;
        partyId: string;
        party: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        };
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        contract: { __typename?: "Contract"; id: string; timeZone: string };
      };
      advanceWarning?: {
        __typename?: "EarlyWarningItem";
        id: string;
        title: string;
        number: string;
      } | null;
      regardingEvent?: {
        __typename?: "EventItem";
        id: string;
        title: string;
        productInstanceId: string;
        number: string;
        data: {
          __typename?: "ItemData";
          sections: Array<{
            __typename?: "ItemDataSection";
            name: string;
            entries: Array<{
              __typename?: "ItemDataEntry";
              name: string;
              value: string;
            }>;
          }>;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          product:
            | { __typename?: "Claims"; id: string }
            | { __typename?: "CompEvents"; id: string }
            | { __typename?: "DailyDiary"; id: string }
            | { __typename?: "EarlyWarnings"; id: string }
            | { __typename?: "Events"; id: string }
            | { __typename?: "Instructions"; id: string }
            | { __typename?: "RisksRegister"; id: string }
            | { __typename?: "Variations"; id: string };
        };
      } | null;
      regardingInstruction?: {
        __typename?: "InstructionItem";
        id: string;
        title: string;
        number: string;
        productInstanceId: string;
        data: {
          __typename?: "ItemData";
          sections: Array<{
            __typename?: "ItemDataSection";
            name: string;
            entries: Array<{
              __typename?: "ItemDataEntry";
              name: string;
              value: string;
            }>;
          }>;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          product:
            | { __typename?: "Claims"; id: string }
            | { __typename?: "CompEvents"; id: string }
            | { __typename?: "DailyDiary"; id: string }
            | { __typename?: "EarlyWarnings"; id: string }
            | { __typename?: "Events"; id: string }
            | { __typename?: "Instructions"; id: string }
            | { __typename?: "RisksRegister"; id: string }
            | { __typename?: "Variations"; id: string };
        };
      } | null;
      notifiedByParty: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      };
    }>;
  };
};

export type ContractByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ContractByIdQuery = {
  __typename?: "Query";
  contract: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
    number: string;
    contractTypeId: string;
    timeZone: string;
    valueCurrency: string;
    value: number;
    contractType: {
      __typename?: "ContractType";
      description: string;
      subType?: string | null;
      version?: string | null;
      id: string;
    };
    sections: Array<{
      __typename?: "ContractSection";
      id: string;
      number?: number | null;
      description: string;
      completionDate: any;
      status: ContractSectionStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
    keyDates: Array<{
      __typename?: "ContractKeyDate";
      id: string;
      number?: number | null;
      conditionToBeMet: string;
      keyDate: any;
      status: ContractKeyDateStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type ContractByProductInstanceQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ContractByProductInstanceQuery = {
  __typename?: "Query";
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    status: ProductInstanceStatus;
    product:
      | { __typename?: "Claims"; id: string; name: string }
      | { __typename?: "CompEvents"; id: string; name: string }
      | { __typename?: "DailyDiary"; id: string; name: string }
      | { __typename?: "EarlyWarnings"; id: string; name: string }
      | { __typename?: "Events"; id: string; name: string }
      | { __typename?: "Instructions"; id: string; name: string }
      | { __typename?: "RisksRegister"; id: string; name: string }
      | { __typename?: "Variations"; id: string; name: string };
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      status: ContractStatus;
      timeZone: string;
      valueCurrency: string;
      contractType: {
        __typename?: "ContractType";
        description: string;
        subType?: string | null;
        version?: string | null;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        status: ProjectStatus;
      };
    };
  };
};

export type VariationItemsRegisterQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type VariationItemsRegisterQuery = {
  __typename?: "Query";
  variationItems: {
    __typename?: "VariationItemList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "VariationItem";
      id: string;
      number: string;
      productInstanceId: string;
      type: VariationType;
      regardingId: string;
      status: VariationStatus;
      priceSought?: number | null;
      priceGranted?: number | null;
      timeSought?: number | null;
      timeGranted?: number | null;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      lastAction: {
        __typename?: "VariationItemLastAction";
        actionType: VariationActionType;
        date: any;
        daysLate?: number | null;
      };
      nextStep?: {
        __typename?: "VariationItemNextStep";
        actionType: VariationActionType;
        dueDate: any;
        partyId: string;
        party: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        };
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        contract: { __typename?: "Contract"; id: string; timeZone: string };
      };
      regardingInstruction?: {
        __typename?: "InstructionItem";
        id: string;
        title: string;
        number: string;
        productInstanceId: string;
        dateCreated: any;
        data: {
          __typename?: "ItemData";
          sections: Array<{
            __typename?: "ItemDataSection";
            name: string;
            entries: Array<{
              __typename?: "ItemDataEntry";
              name: string;
              value: string;
            }>;
          }>;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          product:
            | { __typename?: "Claims"; id: string }
            | { __typename?: "CompEvents"; id: string }
            | { __typename?: "DailyDiary"; id: string }
            | { __typename?: "EarlyWarnings"; id: string }
            | { __typename?: "Events"; id: string }
            | { __typename?: "Instructions"; id: string }
            | { __typename?: "RisksRegister"; id: string }
            | { __typename?: "Variations"; id: string };
        };
      } | null;
    }>;
  };
};

export type ClaimActionQueryVariables = Exact<{
  input: ClaimActionInput;
}>;

export type ClaimActionQuery = {
  __typename?: "Query";
  claimAction: {
    __typename?: "ClaimActionList";
    items: Array<{
      __typename?: "ClaimAction";
      type: ClaimActionType;
      actionMapping?: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      } | null;
    }>;
  };
};

export type ProductInstanceContractActiveBindingsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProductInstanceContractActiveBindingsQuery = {
  __typename?: "Query";
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    contract: {
      __typename?: "Contract";
      id: string;
      timeZone: string;
      activeBindings: {
        __typename?: "ContractBindingList";
        items: Array<{
          __typename?: "ContractBinding";
          id: string;
          status: ContractBindingStatus;
          companyId: string;
          contractBindingType: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
        }>;
      };
    };
  };
};

export type VariationActionQueryVariables = Exact<{
  input: VariationActionInput;
}>;

export type VariationActionQuery = {
  __typename?: "Query";
  variationAction: {
    __typename?: "VariationActionList";
    items: Array<{
      __typename?: "VariationAction";
      type: VariationActionType;
      actionMapping?: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      } | null;
    }>;
  };
};

export type UserRegistrationQueryVariables = Exact<{
  invitationId?: InputMaybe<Scalars["ID"]>;
}>;

export type UserRegistrationQuery = {
  __typename?: "Query";
  userRegistration?: {
    __typename?: "UserRegistration";
    invitationId: string;
    email: any;
    dateInvited: any;
    company: { __typename?: "Company"; id: string; tradingName: string };
  } | null;
};

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput;
}>;

export type RegisterUserMutation = {
  __typename?: "Mutation";
  registerUser: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    jobTitle: string;
    country: string;
    mobileNumber?: any | null;
    alternateNumber?: any | null;
    dateOfBirth?: any | null;
    profilePicture?: string | null;
    email: any;
    companyId: string;
    registered?: boolean | null;
    status: UserStatus;
    lastActive?: any | null;
    dateInvited: any;
  };
};

export type PreferenceValueCommonFieldsFragment = {
  __typename?: "UserPreferencesValue";
  inApp: boolean;
  inAppReadOnly: boolean;
  email: boolean;
  emailReadOnly: boolean;
};

export type PreferenceValuesFieldsFragment = {
  __typename?: "UserPreferences";
  userId: string;
  creatorId: string;
  dateCreated: any;
  dateModified: any;
  ownershipAssignment: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewRisk: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  changesToAnyRisk: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewSentEW: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewRecordedEW: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  changesToAnyEW: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  attachmentChanges: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  newComments: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  commentsMentioned: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  commentsReplied: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewEvent: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  changesToAnyEvent: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewRecordedInstruction: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewSentInstruction: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  changesToAnyInstruction: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewCEActionRecorded: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewCEActionSent: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewClaimActionSent: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewClaimActionRecorded: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewVariationActionSent: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  anyNewVariationActionRecorded: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  authorizationRequired: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  authorizedActionExecuted: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
  authorizedActionPrevented: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
};

export type UserPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type UserPreferencesQuery = {
  __typename?: "Query";
  userPreferences: {
    __typename?: "UserPreferences";
    userId: string;
    creatorId: string;
    dateCreated: any;
    dateModified: any;
    ownershipAssignment: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewRisk: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    changesToAnyRisk: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewSentEW: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewRecordedEW: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    changesToAnyEW: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    attachmentChanges: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    newComments: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    commentsMentioned: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    commentsReplied: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewEvent: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    changesToAnyEvent: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewRecordedInstruction: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewSentInstruction: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    changesToAnyInstruction: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewCEActionRecorded: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewCEActionSent: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewClaimActionSent: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewClaimActionRecorded: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewVariationActionSent: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewVariationActionRecorded: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    authorizationRequired: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    authorizedActionExecuted: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    authorizedActionPrevented: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
  };
};

export type SetUserPreferencesMutationVariables = Exact<{
  input: UserPreferencesInput;
}>;

export type SetUserPreferencesMutation = {
  __typename?: "Mutation";
  setUserPreferences: {
    __typename?: "UserPreferences";
    userId: string;
    creatorId: string;
    dateCreated: any;
    dateModified: any;
    ownershipAssignment: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewRisk: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    changesToAnyRisk: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewSentEW: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewRecordedEW: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    changesToAnyEW: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    attachmentChanges: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    newComments: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    commentsMentioned: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    commentsReplied: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewEvent: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    changesToAnyEvent: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewRecordedInstruction: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewSentInstruction: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    changesToAnyInstruction: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewCEActionRecorded: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewCEActionSent: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewClaimActionSent: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewClaimActionRecorded: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewVariationActionSent: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    anyNewVariationActionRecorded: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    authorizationRequired: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    authorizedActionExecuted: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
    authorizedActionPrevented: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      inAppReadOnly: boolean;
      email: boolean;
      emailReadOnly: boolean;
    };
  };
};

export type UserChangePasswordMutationVariables = Exact<{
  input: UserChangePasswordInput;
}>;

export type UserChangePasswordMutation = {
  __typename?: "Mutation";
  userChangePassword: {
    __typename?: "UserChangePasswordResponse";
    success: boolean;
  };
};

export type AddressFieldsFragment = {
  __typename?: "Address";
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  provinceState?: string | null;
  country: string;
  code?: string | null;
};

export type AttachmentLiteFieldsFragment = {
  __typename?: "Attachment";
  id: string;
  fileName: string;
  fileUrl: string;
  mimeType: string;
  description?: string | null;
  locked: boolean;
  fileSize: number;
  status: AttachmentStatus;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
    company: {
      __typename?: "Company";
      id: string;
      registeredName: string;
      tradingName: string;
    };
  };
};

export type AuthorizationWorkflowFieldsFragment = {
  __typename?: "AuthorizationWorkflow";
  id: string;
  name: string;
  status: AuthorizationWorkflowStatus;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  modifiedById: string;
  projectId: string;
  levels: Array<{
    __typename?: "AuthorizationWorkflowLevel";
    sequence: number;
    mode: AuthorizationWorkflowLevelMode;
    userIds: Array<string>;
    users: {
      __typename?: "UserList";
      items: Array<{
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        jobTitle: string;
        country: string;
        mobileNumber?: any | null;
        alternateNumber?: any | null;
        dateOfBirth?: any | null;
        profilePicture?: string | null;
        email: any;
        companyId: string;
        registered?: boolean | null;
        status: UserStatus;
        lastActive?: any | null;
        dateInvited: any;
        registeredDate?: any | null;
        company: {
          __typename?: "Company";
          id: string;
          tradingName: string;
          registeredName: string;
        };
        invitedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
  }>;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  modifiedBy: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    friendlyName: string;
    number?: string | null;
    sector: ProjectSector;
    description?: string | null;
    status: ProjectStatus;
    dateCreated: any;
    creatorId: string;
    billingCompanyId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    contracts: {
      __typename?: "ContractList";
      items: Array<{
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
      }>;
    };
    billingCompany: {
      __typename?: "Company";
      id: string;
      type?: CompanyType | null;
      registeredName: string;
      tradingName: string;
      registrationNumber?: string | null;
      logo?: string | null;
      vatRegistrationNumber?: string | null;
      status: CompanyStatus;
      creatorId: string;
      dateCreated: any;
      physicalAddress?: {
        __typename?: "Address";
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        provinceState?: string | null;
        country: string;
        code?: string | null;
      } | null;
      postalAddress?: {
        __typename?: "Address";
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        provinceState?: string | null;
        country: string;
        code?: string | null;
      } | null;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
  };
};

export type AuthorizationWorkflowActionMappingFieldsFragment = {
  __typename?: "AuthorizationWorkflowActionMapping";
  id: string;
  productOutputActionId: string;
  workflowId: string;
  partyId?: string | null;
  productInstanceId: string;
  status: AuthorizationWorkflowActionMappingStatus;
  dateCreated: any;
  creatorId: string;
  modifiedById: string;
  dateModified: any;
  productOutputAction: {
    __typename?: "ProductOutputAction";
    id: string;
    name: string;
    productId: string;
    isInternal: boolean;
    status: ProductOutputActionStatus;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    product:
      | {
          __typename?: "Claims";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "CompEvents";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "DailyDiary";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "EarlyWarnings";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Events";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Instructions";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "RisksRegister";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Variations";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        };
  };
  workflow: {
    __typename?: "AuthorizationWorkflow";
    id: string;
    name: string;
    status: AuthorizationWorkflowStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    modifiedById: string;
    projectId: string;
    levels: Array<{
      __typename?: "AuthorizationWorkflowLevel";
      sequence: number;
      mode: AuthorizationWorkflowLevelMode;
      userIds: Array<string>;
      users: {
        __typename?: "UserList";
        items: Array<{
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          jobTitle: string;
          country: string;
          mobileNumber?: any | null;
          alternateNumber?: any | null;
          dateOfBirth?: any | null;
          profilePicture?: string | null;
          email: any;
          companyId: string;
          registered?: boolean | null;
          status: UserStatus;
          lastActive?: any | null;
          dateInvited: any;
          registeredDate?: any | null;
          company: {
            __typename?: "Company";
            id: string;
            tradingName: string;
            registeredName: string;
          };
          invitedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      friendlyName: string;
      number?: string | null;
      sector: ProjectSector;
      description?: string | null;
      status: ProjectStatus;
      dateCreated: any;
      creatorId: string;
      billingCompanyId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contracts: {
        __typename?: "ContractList";
        items: Array<{
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          status: ContractStatus;
        }>;
      };
      billingCompany: {
        __typename?: "Company";
        id: string;
        type?: CompanyType | null;
        registeredName: string;
        tradingName: string;
        registrationNumber?: string | null;
        logo?: string | null;
        vatRegistrationNumber?: string | null;
        status: CompanyStatus;
        creatorId: string;
        dateCreated: any;
        physicalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        postalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
    };
  };
  party?: {
    __typename?: "ContractBindingType";
    id: string;
    description: string;
    contractTypeId: string;
    status: ContractBindingTypeStatus;
  } | null;
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    contractId: string;
    product:
      | { __typename?: "Claims"; id: string; name: string }
      | { __typename?: "CompEvents"; id: string; name: string }
      | { __typename?: "DailyDiary"; id: string; name: string }
      | { __typename?: "EarlyWarnings"; id: string; name: string }
      | { __typename?: "Events"; id: string; name: string }
      | { __typename?: "Instructions"; id: string; name: string }
      | { __typename?: "RisksRegister"; id: string; name: string }
      | { __typename?: "Variations"; id: string; name: string };
    contract: {
      __typename?: "Contract";
      id: string;
      friendlyName: string;
      timeZone: string;
      valueCurrency: string;
      project: { __typename?: "Project"; id: string; friendlyName: string };
    };
  };
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  modifiedBy: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type AuthorizationWorkflowAuditFieldsFragment = {
  __typename?: "AuthorizationWorkflowAudit";
  id: string;
  initiatedById: string;
  regardingProductItemId: string;
  regardingProductType: ProductType;
  regardingItemHistoryId?: string | null;
  workflowId: string;
  actionId: string;
  status: AuthorizationWorkflowAuditStatus;
  dateCreated: any;
  dateInitiated: any;
  initiatedBy: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  workflow: {
    __typename?: "AuthorizationWorkflow";
    id: string;
    name: string;
    status: AuthorizationWorkflowStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    modifiedById: string;
    projectId: string;
    levels: Array<{
      __typename?: "AuthorizationWorkflowLevel";
      sequence: number;
      mode: AuthorizationWorkflowLevelMode;
      userIds: Array<string>;
      users: {
        __typename?: "UserList";
        items: Array<{
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          jobTitle: string;
          country: string;
          mobileNumber?: any | null;
          alternateNumber?: any | null;
          dateOfBirth?: any | null;
          profilePicture?: string | null;
          email: any;
          companyId: string;
          registered?: boolean | null;
          status: UserStatus;
          lastActive?: any | null;
          dateInvited: any;
          registeredDate?: any | null;
          company: {
            __typename?: "Company";
            id: string;
            tradingName: string;
            registeredName: string;
          };
          invitedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      friendlyName: string;
      number?: string | null;
      sector: ProjectSector;
      description?: string | null;
      status: ProjectStatus;
      dateCreated: any;
      creatorId: string;
      billingCompanyId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contracts: {
        __typename?: "ContractList";
        items: Array<{
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          status: ContractStatus;
        }>;
      };
      billingCompany: {
        __typename?: "Company";
        id: string;
        type?: CompanyType | null;
        registeredName: string;
        tradingName: string;
        registrationNumber?: string | null;
        logo?: string | null;
        vatRegistrationNumber?: string | null;
        status: CompanyStatus;
        creatorId: string;
        dateCreated: any;
        physicalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        postalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
    };
  };
  action: {
    __typename?: "AuthorizationWorkflowActionMapping";
    id: string;
    productOutputActionId: string;
    workflowId: string;
    partyId?: string | null;
    productInstanceId: string;
    status: AuthorizationWorkflowActionMappingStatus;
    dateCreated: any;
    creatorId: string;
    modifiedById: string;
    dateModified: any;
    productOutputAction: {
      __typename?: "ProductOutputAction";
      id: string;
      name: string;
      productId: string;
      isInternal: boolean;
      status: ProductOutputActionStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      product:
        | {
            __typename?: "Claims";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "CompEvents";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "DailyDiary";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "EarlyWarnings";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Events";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Instructions";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "RisksRegister";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Variations";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          };
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    party?: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    } | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contractId: string;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        friendlyName: string;
        timeZone: string;
        valueCurrency: string;
        project: { __typename?: "Project"; id: string; friendlyName: string };
      };
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
  regardingProductItem:
    | {
        __typename: "ClaimItem";
        id: string;
        number: string;
        regardingId: string;
        claimRegardingType: ClaimRegardingType;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          contract: { __typename?: "Contract"; id: string; timeZone: string };
        };
      }
    | {
        __typename: "CompEventItem";
        id: string;
        number: string;
        productInstanceId: string;
        regardingId: string;
        regardingType: CompEventRegardingType;
        status: CompEventStatus;
        price?: number | null;
        time?: number | null;
        earlyWarningId?: string | null;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        deemingDate?: any | null;
        lastAction: {
          __typename?: "CompEventItemLastAction";
          actionType: CompEventActionType;
          daysLate?: number | null;
          date: any;
        };
        nextStep?: {
          __typename?: "CompEventItemNextStep";
          actionType: CompEventActionType;
          dueDate: any;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          status: ProductInstanceStatus;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
          };
        };
        earlyWarning?: {
          __typename?: "EarlyWarningItem";
          id: string;
          title: string;
          dateSent: any;
          number: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            product:
              | { __typename?: "Claims"; id: string }
              | { __typename?: "CompEvents"; id: string }
              | { __typename?: "DailyDiary"; id: string }
              | { __typename?: "EarlyWarnings"; id: string }
              | { __typename?: "Events"; id: string }
              | { __typename?: "Instructions"; id: string }
              | { __typename?: "RisksRegister"; id: string }
              | { __typename?: "Variations"; id: string };
          };
        } | null;
        regardingEvent?: {
          __typename?: "EventItem";
          id: string;
          title: string;
          productInstanceId: string;
          number: string;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            product:
              | { __typename?: "Claims"; id: string }
              | { __typename?: "CompEvents"; id: string }
              | { __typename?: "DailyDiary"; id: string }
              | { __typename?: "EarlyWarnings"; id: string }
              | { __typename?: "Events"; id: string }
              | { __typename?: "Instructions"; id: string }
              | { __typename?: "RisksRegister"; id: string }
              | { __typename?: "Variations"; id: string };
          };
        } | null;
        regardingInstruction?: {
          __typename?: "InstructionItem";
          id: string;
          title: string;
          number: string;
          productInstanceId: string;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            product:
              | { __typename?: "Claims"; id: string }
              | { __typename?: "CompEvents"; id: string }
              | { __typename?: "DailyDiary"; id: string }
              | { __typename?: "EarlyWarnings"; id: string }
              | { __typename?: "Events"; id: string }
              | { __typename?: "Instructions"; id: string }
              | { __typename?: "RisksRegister"; id: string }
              | { __typename?: "Variations"; id: string };
          };
        } | null;
        notifiedByParty: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        };
      }
    | {
        __typename?: "DailyDiaryItem";
        id: string;
        date: any;
        number: string;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        sentById?: string | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          status: ProductInstanceStatus;
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            timeZone: string;
            number: string;
            status: ContractStatus;
            dateCreated: any;
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
      }
    | {
        __typename?: "EarlyWarningItem";
        id: string;
        title: string;
        severity: string;
        number: string;
        productInstanceId: string;
        ownerId: string;
        statusOptionId: string;
        dateCreated: any;
        creatorId: string;
        dateModified: any;
        offline: boolean;
        givenById?: string | null;
        dateSent: any;
        data: {
          __typename?: "ItemData";
          sections: Array<{
            __typename?: "ItemDataSection";
            name: string;
            entries: Array<{
              __typename?: "ItemDataEntry";
              name: string;
              value: string;
            }>;
          }>;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        owner: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          productSchema: {
            __typename?: "ProductSchema";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            isInternal: boolean;
            status: ProductSchemaStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaSections: {
              __typename?: "SchemaSectionList";
              items: Array<{
                __typename?: "SchemaSection";
                id: string;
                name: string;
                displayText: string;
                displayOrder: number;
                columnPlacement: ColumnPlacementType;
                productSchemaId: string;
                isInternal: boolean;
                status: SchemaSectionStatus;
                dateCreated: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaFields: {
                  __typename?: "SchemaFieldList";
                  items: Array<{
                    __typename?: "SchemaField";
                    id: string;
                    name: string;
                    schemaSectionId: string;
                    fieldTypeId: string;
                    displayText: string;
                    displayOrder: number;
                    isRequired: boolean;
                    isInternal: boolean;
                    complexTypeItemId?: string | null;
                    extraData?: string | null;
                    status: SchemaFieldStatus;
                    creatorId: string;
                    dateCreated: any;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    fieldType: {
                      __typename?: "FieldType";
                      id: string;
                      description: string;
                      shortCode: FieldTypeEnum;
                      status: FieldTypeStatus;
                    };
                  }>;
                };
              }>;
            };
          };
          contract: {
            __typename?: "Contract";
            id: string;
            valueCurrency: string;
            timeZone: string;
          };
          product:
            | { __typename?: "Claims"; id: string; numberingFormat: string }
            | { __typename?: "CompEvents"; id: string; numberingFormat: string }
            | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                numberingFormat: string;
              }
            | { __typename?: "Events"; id: string; numberingFormat: string }
            | {
                __typename?: "Instructions";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "Variations";
                id: string;
                numberingFormat: string;
              };
        };
        attachments?: Array<{
          __typename?: "Attachment";
          id: string;
          fileName: string;
          fileUrl: string;
          mimeType: string;
          description?: string | null;
          locked: boolean;
          fileSize: number;
          status: AttachmentStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
            company: {
              __typename?: "Company";
              id: string;
              registeredName: string;
              tradingName: string;
            };
          };
        }> | null;
      }
    | { __typename?: "EventItem" }
    | {
        __typename?: "InstructionItem";
        id: string;
        title: string;
        severity: string;
        number: string;
        productInstanceId: string;
        ownerId: string;
        statusOptionId: string;
        dateCreated: any;
        dateModified: any;
        notifyDueDate: any;
        creatorId: string;
        offline: boolean;
        givenById?: string | null;
        dateSent: any;
        attachments?: Array<{
          __typename?: "Attachment";
          id: string;
          fileName: string;
          fileUrl: string;
          mimeType: string;
          description?: string | null;
          locked: boolean;
          fileSize: number;
          status: AttachmentStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
            company: {
              __typename?: "Company";
              id: string;
              registeredName: string;
              tradingName: string;
            };
          };
        }> | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          productSchema: {
            __typename?: "ProductSchema";
            id: string;
            name: string;
            isDefault: boolean;
            productId: string;
            isInternal: boolean;
            status: ProductSchemaStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaSections: {
              __typename?: "SchemaSectionList";
              items: Array<{
                __typename?: "SchemaSection";
                id: string;
                name: string;
                displayText: string;
                displayOrder: number;
                columnPlacement: ColumnPlacementType;
                productSchemaId: string;
                isInternal: boolean;
                status: SchemaSectionStatus;
                dateCreated: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaFields: {
                  __typename?: "SchemaFieldList";
                  items: Array<{
                    __typename?: "SchemaField";
                    id: string;
                    name: string;
                    schemaSectionId: string;
                    fieldTypeId: string;
                    displayText: string;
                    displayOrder: number;
                    isRequired: boolean;
                    isInternal: boolean;
                    complexTypeItemId?: string | null;
                    extraData?: string | null;
                    status: SchemaFieldStatus;
                    creatorId: string;
                    dateCreated: any;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    fieldType: {
                      __typename?: "FieldType";
                      id: string;
                      description: string;
                      shortCode: FieldTypeEnum;
                      status: FieldTypeStatus;
                    };
                  }>;
                };
              }>;
            };
          };
          contract: {
            __typename?: "Contract";
            id: string;
            contractTypeId: string;
            valueCurrency: string;
            timeZone: string;
          };
          product:
            | { __typename?: "Claims"; id: string; numberingFormat: string }
            | { __typename?: "CompEvents"; id: string; numberingFormat: string }
            | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                numberingFormat: string;
              }
            | { __typename?: "Events"; id: string; numberingFormat: string }
            | {
                __typename?: "Instructions";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "Variations";
                id: string;
                numberingFormat: string;
              };
        };
        data: {
          __typename?: "ItemData";
          sections: Array<{
            __typename?: "ItemDataSection";
            name: string;
            entries: Array<{
              __typename?: "ItemDataEntry";
              name: string;
              value: string;
            }>;
          }>;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        statusOption: {
          __typename?: "ItemStatusOption";
          id: string;
          displayOrder: number;
          description: string;
          equatesToRemoved: boolean;
          style: ItemStatusOptionStyles;
          statusCollectionId: string;
          isInternal: boolean;
          status: ItemStatusOptionStatus;
          creatorId: string;
          dateCreated: any;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
        owner: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }
    | { __typename?: "RiskItem" }
    | {
        __typename: "VariationItem";
        id: string;
        number: string;
        regardingId: string;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          contract: { __typename?: "Contract"; id: string; timeZone: string };
        };
      };
  auditTrails?: Array<{
    __typename?: "AuthorizationWorkflowAuditTrail";
    id: string;
    auditId: string;
    regardingProductItemId: string;
    regardingItemHistoryId?: string | null;
    regardingProductType: ProductType;
    productInstanceId: string;
    actionId: string;
    status: AuthorizationActionStatus;
    denialReason?: string | null;
    dateCreated: any;
    creatorId: string;
    productInstance?: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      soloModeSupported: boolean;
      contractId: string;
      productId: string;
      productSchemaId: string;
      statusCollectionId: string;
      status: ProductInstanceStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        number: string;
        valueCurrency: string;
        value: number;
        startDate?: any | null;
        endDate?: any | null;
        contractTypeId: string;
        projectId: string;
        country: string;
        province?: string | null;
        timeZone: string;
        coordinatesLatitude?: number | null;
        coordinatesLongitude?: number | null;
        status: ContractStatus;
        dateCreated: any;
        creatorId: string;
        sections: Array<{
          __typename?: "ContractSection";
          id: string;
          number?: number | null;
          description: string;
          completionDate: any;
          status: ContractSectionStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        keyDates: Array<{
          __typename?: "ContractKeyDate";
          id: string;
          number?: number | null;
          conditionToBeMet: string;
          keyDate: any;
          status: ContractKeyDateStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
        contractType: {
          __typename?: "ContractType";
          id: string;
          description: string;
          subType?: string | null;
          version?: string | null;
          status: ContractTypeStatus;
        };
      };
      product:
        | {
            __typename?: "Claims";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "CompEvents";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "DailyDiary";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "EarlyWarnings";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Events";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Instructions";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "RisksRegister";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Variations";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          };
      statusCollection: {
        __typename?: "ItemStatusCollection";
        id: string;
        name: string;
        isDefault: boolean;
        status: ItemStatusCollectionStatus;
      };
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }> | null;
};

export type AuthorizationWorkflowAuditTrailFieldsFragment = {
  __typename?: "AuthorizationWorkflowAuditTrail";
  id: string;
  auditId: string;
  regardingProductItemId: string;
  regardingItemHistoryId?: string | null;
  regardingProductType: ProductType;
  productInstanceId: string;
  actionId: string;
  status: AuthorizationActionStatus;
  denialReason?: string | null;
  dateCreated: any;
  creatorId: string;
  productInstance?: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    soloModeSupported: boolean;
    contractId: string;
    productId: string;
    productSchemaId: string;
    statusCollectionId: string;
    status: ProductInstanceStatus;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      number: string;
      valueCurrency: string;
      value: number;
      startDate?: any | null;
      endDate?: any | null;
      contractTypeId: string;
      projectId: string;
      country: string;
      province?: string | null;
      timeZone: string;
      coordinatesLatitude?: number | null;
      coordinatesLongitude?: number | null;
      status: ContractStatus;
      dateCreated: any;
      creatorId: string;
      sections: Array<{
        __typename?: "ContractSection";
        id: string;
        number?: number | null;
        description: string;
        completionDate: any;
        status: ContractSectionStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
      keyDates: Array<{
        __typename?: "ContractKeyDate";
        id: string;
        number?: number | null;
        conditionToBeMet: string;
        keyDate: any;
        status: ContractKeyDateStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        status: ProjectStatus;
      };
      contractType: {
        __typename?: "ContractType";
        id: string;
        description: string;
        subType?: string | null;
        version?: string | null;
        status: ContractTypeStatus;
      };
    };
    product:
      | {
          __typename?: "Claims";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "CompEvents";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "DailyDiary";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "EarlyWarnings";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Events";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Instructions";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "RisksRegister";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Variations";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        };
    statusCollection: {
      __typename?: "ItemStatusCollection";
      id: string;
      name: string;
      isDefault: boolean;
      status: ItemStatusCollectionStatus;
    };
  } | null;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type AuthorizationWorkflowLevelFieldsFragment = {
  __typename?: "AuthorizationWorkflowLevel";
  sequence: number;
  mode: AuthorizationWorkflowLevelMode;
  userIds: Array<string>;
  users: {
    __typename?: "UserList";
    items: Array<{
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      jobTitle: string;
      country: string;
      mobileNumber?: any | null;
      alternateNumber?: any | null;
      dateOfBirth?: any | null;
      profilePicture?: string | null;
      email: any;
      companyId: string;
      registered?: boolean | null;
      status: UserStatus;
      lastActive?: any | null;
      dateInvited: any;
      registeredDate?: any | null;
      company: {
        __typename?: "Company";
        id: string;
        tradingName: string;
        registeredName: string;
      };
      invitedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type ClaimAgreementLiteFieldsFragment = {
  __typename?: "ClaimAgreement";
  id: string;
  claimId: string;
  reference: string;
  details: string;
  price: number;
  currency: string;
  time: number;
  dateCreated: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "DetailedClaimTimeChange";
    number?: number | null;
    description: string;
    days: number;
  }>;
};

export type ClaimDeterminationLiteFieldsFragment = {
  __typename?: "ClaimDetermination";
  id: string;
  claimId: string;
  reference: string;
  details: string;
  price: number;
  currency: string;
  time: number;
  dateCreated: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "DetailedClaimTimeChange";
    number?: number | null;
    description: string;
    days: number;
  }>;
};

export type ClaimHistoryItemFieldsFragment = {
  __typename?: "ClaimHistoryItem";
  id: string;
  claimId: string;
  actionType: ClaimActionType;
  actionNumber: string;
  dateCreated: any;
  dateSent: any;
  dueDate?: any | null;
  sentByUserId: string;
  sentByPartyId?: string | null;
  offline: boolean;
  daysLate: number;
  isIncomplete?: boolean | null;
  answers?:
    | {
        __typename: "ClaimHistoryAgreementAnswers";
        agreementId?: string | null;
        draftAgreementId?: string | null;
        agreement?: {
          __typename?: "ClaimAgreement";
          id: string;
          claimId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "DetailedClaimTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        } | null;
        draftAgreement?: {
          __typename?: "DraftClaimAgreement";
          id: string;
          claimId: string;
          reference: string;
          details: string;
          price: number;
          time: number;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "ContractTimeImpact";
            id: string;
            days: number;
          } | null>;
        } | null;
      }
    | {
        __typename: "ClaimHistoryClaimDetailsResponseAnswers";
        details: string;
        detailedClaimId: string;
        detailedClaim: {
          __typename?: "DetailedClaim";
          id: string;
          claimId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          sectionalChanges: Array<{
            __typename?: "DetailedClaimTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        };
      }
    | {
        __typename: "ClaimHistoryDeterminationAnswers";
        determinationId?: string | null;
        draftDeterminationId?: string | null;
        determination?: {
          __typename?: "ClaimDetermination";
          id: string;
          claimId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "DetailedClaimTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        } | null;
        draftDetermination?: {
          __typename?: "DraftClaimDetermination";
          id: string;
          claimId: string;
          reference: string;
          details: string;
          price: number;
          time: number;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "ContractTimeImpact";
            id: string;
            days: number;
          } | null>;
        } | null;
      }
    | {
        __typename: "ClaimHistoryErrorAgreementDisagreementAnswers";
        remarks: string;
      }
    | { __typename: "ClaimHistoryErrorAgreementNoticeAnswers"; details: string }
    | {
        __typename: "ClaimHistoryErrorDeterminationDisagreementAnswers";
        remarks: string;
      }
    | {
        __typename: "ClaimHistoryErrorDeterminationNoticeAnswers";
        details: string;
      }
    | {
        __typename: "ClaimHistoryFinalDetailsAnswers";
        detailedClaimId: string;
        detailedClaim: {
          __typename?: "DetailedClaim";
          id: string;
          claimId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "DetailedClaimTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        };
      }
    | {
        __typename: "ClaimHistoryFurtherParticularsRequestAnswers";
        reasons: string;
      }
    | {
        __typename: "ClaimHistoryFurtherParticularsSubmissionAnswers";
        details: string;
      }
    | {
        __typename: "ClaimHistoryInterimDetailsAnswers";
        detailedClaimId: string;
        detailedClaim: {
          __typename?: "DetailedClaim";
          id: string;
          claimId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "DetailedClaimTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        };
      }
    | { __typename: "ClaimHistoryNoAgreementReachedAnswers"; remarks: string }
    | {
        __typename: "ClaimHistoryNoticeAnswers";
        claimTypeId: string;
        advanceWarningId?: string | null;
        claimType: {
          __typename?: "ClaimTypeInfo";
          id: string;
          clause: string;
          description: string;
        };
        advanceWarning?: {
          __typename?: "EarlyWarningItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          creatorId: string;
          dateModified: any;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
        } | null;
      }
    | { __typename: "ClaimHistoryNoticeInvalidNoticeAnswers"; reasons: string }
    | { __typename: "ClaimHistoryNoticeValidityLapsedAnswers"; remarks: string }
    | null;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
  recipients: Array<{
    __typename?: "ClaimRecipient";
    userId: string;
    name: string;
    preference: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      email: boolean;
    };
  }>;
  authorizationWorkflowAudit?: {
    __typename?: "AuthorizationWorkflowAudit";
    id: string;
    initiatedById: string;
    regardingProductItemId: string;
    regardingProductType: ProductType;
    regardingItemHistoryId?: string | null;
    workflowId: string;
    actionId: string;
    status: AuthorizationWorkflowAuditStatus;
    dateCreated: any;
    dateInitiated: any;
    initiatedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    action: {
      __typename?: "AuthorizationWorkflowActionMapping";
      id: string;
      productOutputActionId: string;
      workflowId: string;
      partyId?: string | null;
      productInstanceId: string;
      status: AuthorizationWorkflowActionMappingStatus;
      dateCreated: any;
      creatorId: string;
      modifiedById: string;
      dateModified: any;
      productOutputAction: {
        __typename?: "ProductOutputAction";
        id: string;
        name: string;
        productId: string;
        isInternal: boolean;
        status: ProductOutputActionStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      party?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        contractId: string;
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
        contract: {
          __typename?: "Contract";
          id: string;
          friendlyName: string;
          timeZone: string;
          valueCurrency: string;
          project: { __typename?: "Project"; id: string; friendlyName: string };
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    regardingProductItem:
      | {
          __typename: "ClaimItem";
          id: string;
          number: string;
          regardingId: string;
          claimRegardingType: ClaimRegardingType;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        }
      | {
          __typename: "CompEventItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          regardingType: CompEventRegardingType;
          status: CompEventStatus;
          price?: number | null;
          time?: number | null;
          earlyWarningId?: string | null;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          deemingDate?: any | null;
          lastAction: {
            __typename?: "CompEventItemLastAction";
            actionType: CompEventActionType;
            daysLate?: number | null;
            date: any;
          };
          nextStep?: {
            __typename?: "CompEventItemNextStep";
            actionType: CompEventActionType;
            dueDate: any;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            status: ProductInstanceStatus;
            product:
              | { __typename?: "Claims"; id: string; name: string }
              | { __typename?: "CompEvents"; id: string; name: string }
              | { __typename?: "DailyDiary"; id: string; name: string }
              | { __typename?: "EarlyWarnings"; id: string; name: string }
              | { __typename?: "Events"; id: string; name: string }
              | { __typename?: "Instructions"; id: string; name: string }
              | { __typename?: "RisksRegister"; id: string; name: string }
              | { __typename?: "Variations"; id: string; name: string };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
              timeZone: string;
              valueCurrency: string;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
          };
          earlyWarning?: {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            dateSent: any;
            number: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          notifiedByParty: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
        }
      | {
          __typename?: "DailyDiaryItem";
          id: string;
          date: any;
          number: string;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          sentById?: string | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              timeZone: string;
              number: string;
              status: ContractStatus;
              dateCreated: any;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
          };
        }
      | {
          __typename?: "EarlyWarningItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          creatorId: string;
          dateModified: any;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          owner: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            productSchema: {
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  displayOrder: number;
                  columnPlacement: ColumnPlacementType;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  dateCreated: any;
                  creatorId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      displayOrder: number;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      extraData?: string | null;
                      status: SchemaFieldStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            };
            contract: {
              __typename?: "Contract";
              id: string;
              valueCurrency: string;
              timeZone: string;
            };
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
          };
          attachments?: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }> | null;
        }
      | { __typename?: "EventItem" }
      | {
          __typename?: "InstructionItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          dateModified: any;
          notifyDueDate: any;
          creatorId: string;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
          attachments?: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }> | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            productSchema: {
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  displayOrder: number;
                  columnPlacement: ColumnPlacementType;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  dateCreated: any;
                  creatorId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      displayOrder: number;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      extraData?: string | null;
                      status: SchemaFieldStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            };
            contract: {
              __typename?: "Contract";
              id: string;
              contractTypeId: string;
              valueCurrency: string;
              timeZone: string;
            };
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
          };
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          statusOption: {
            __typename?: "ItemStatusOption";
            id: string;
            displayOrder: number;
            description: string;
            equatesToRemoved: boolean;
            style: ItemStatusOptionStyles;
            statusCollectionId: string;
            isInternal: boolean;
            status: ItemStatusOptionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
          owner: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }
      | { __typename?: "RiskItem" }
      | {
          __typename: "VariationItem";
          id: string;
          number: string;
          regardingId: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        };
    auditTrails?: Array<{
      __typename?: "AuthorizationWorkflowAuditTrail";
      id: string;
      auditId: string;
      regardingProductItemId: string;
      regardingItemHistoryId?: string | null;
      regardingProductType: ProductType;
      productInstanceId: string;
      actionId: string;
      status: AuthorizationActionStatus;
      denialReason?: string | null;
      dateCreated: any;
      creatorId: string;
      productInstance?: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        contractId: string;
        productId: string;
        productSchemaId: string;
        statusCollectionId: string;
        status: ProductInstanceStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contract: {
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          number: string;
          valueCurrency: string;
          value: number;
          startDate?: any | null;
          endDate?: any | null;
          contractTypeId: string;
          projectId: string;
          country: string;
          province?: string | null;
          timeZone: string;
          coordinatesLatitude?: number | null;
          coordinatesLongitude?: number | null;
          status: ContractStatus;
          dateCreated: any;
          creatorId: string;
          sections: Array<{
            __typename?: "ContractSection";
            id: string;
            number?: number | null;
            description: string;
            completionDate: any;
            status: ContractSectionStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          keyDates: Array<{
            __typename?: "ContractKeyDate";
            id: string;
            number?: number | null;
            conditionToBeMet: string;
            keyDate: any;
            status: ContractKeyDateStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            status: ProjectStatus;
          };
          contractType: {
            __typename?: "ContractType";
            id: string;
            description: string;
            subType?: string | null;
            version?: string | null;
            status: ContractTypeStatus;
          };
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
        statusCollection: {
          __typename?: "ItemStatusCollection";
          id: string;
          name: string;
          isDefault: boolean;
          status: ItemStatusCollectionStatus;
        };
      } | null;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }> | null;
  } | null;
  sentByUser: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  sentByParty?: {
    __typename?: "ContractBindingType";
    id: string;
    description: string;
    contractTypeId: string;
    status: ContractBindingTypeStatus;
  } | null;
};

export type ClaimLastActionFieldsFragment = {
  __typename?: "ClaimItemLastAction";
  actionType: ClaimActionType;
  date: any;
  daysLate?: number | null;
  party: {
    __typename?: "ContractBindingType";
    id: string;
    description: string;
    contractTypeId: string;
    status: ContractBindingTypeStatus;
  };
};

export type ClaimNextStepFieldsFragment = {
  __typename?: "ClaimItemNextStep";
  actionType: ClaimActionType;
  dueDate: any;
  partyId: string;
  party: {
    __typename?: "ContractBindingType";
    id: string;
    description: string;
    contractTypeId: string;
    status: ContractBindingTypeStatus;
  };
};

export type ClaimItemFieldsFragment = {
  __typename: "ClaimItem";
  id: string;
  number: string;
  productInstanceId: string;
  regardingId: string;
  regardingType: ClaimRegardingType;
  status: ClaimStatus;
  advanceWarningId?: string | null;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  notifiedByPartyId: string;
  lastAction: {
    __typename?: "ClaimItemLastAction";
    actionType: ClaimActionType;
    date: any;
    daysLate?: number | null;
    party: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
  nextStep?: {
    __typename?: "ClaimItemNextStep";
    actionType: ClaimActionType;
    dueDate: any;
    partyId: string;
    party: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  } | null;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    status: ProductInstanceStatus;
    product:
      | { __typename?: "Claims"; id: string; name: string }
      | { __typename?: "CompEvents"; id: string; name: string }
      | { __typename?: "DailyDiary"; id: string; name: string }
      | { __typename?: "EarlyWarnings"; id: string; name: string }
      | { __typename?: "Events"; id: string; name: string }
      | { __typename?: "Instructions"; id: string; name: string }
      | { __typename?: "RisksRegister"; id: string; name: string }
      | { __typename?: "Variations"; id: string; name: string };
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      status: ContractStatus;
      timeZone: string;
      valueCurrency: string;
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        status: ProjectStatus;
      };
    };
  };
  advanceWarning?: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
    dateSent: any;
    number: string;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
  regardingEvent?: {
    __typename?: "EventItem";
    id: string;
    title: string;
    productInstanceId: string;
    number: string;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
  regardingInstruction?: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
  notifiedByParty: {
    __typename?: "ContractBindingType";
    id: string;
    description: string;
    contractTypeId: string;
    status: ContractBindingTypeStatus;
  };
};

export type ClaimItemLiteFieldsFragment = {
  __typename?: "ClaimItem";
  id: string;
  number: string;
  productInstanceId: string;
  regardingId: string;
  regardingType: ClaimRegardingType;
  status: ClaimStatus;
  priceSought?: number | null;
  priceGranted?: number | null;
  timeSought?: number | null;
  timeGranted?: number | null;
  advanceWarningId?: string | null;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  notifiedByPartyId: string;
};

export type ClaimRecipientFieldsFragment = {
  __typename?: "ClaimRecipient";
  userId: string;
  name: string;
  preference: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
};

export type ClaimTypeFieldsFragment = {
  __typename?: "ClaimType";
  id: string;
  contractTypeId: string;
  clause: string;
  description: string;
  isInternal: boolean;
  status: ClaimTypeStatus;
  dateCreated: any;
  creatorId: string;
  contractType: {
    __typename?: "ContractType";
    id: string;
    description: string;
    subType?: string | null;
    version?: string | null;
    status: ContractTypeStatus;
  };
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type ClaimTypeInfoFieldsFragment = {
  __typename?: "ClaimTypeInfo";
  id: string;
  clause: string;
  description: string;
};

export type CompEventAssessmentFieldsFragment = {
  __typename?: "CompEventAssessment";
  id: string;
  compEventId: string;
  reference: string;
  details: string;
  price: number;
  currency: string;
  time: number;
  revision: number;
  dateCreated: any;
  creatorId: string;
  keyDatesChanges: Array<{
    __typename?: "CompEventQuotationTimeChange";
    number?: number | null;
    description: string;
    days: number;
  }>;
  sectionalChanges: Array<{
    __typename?: "CompEventQuotationTimeChange";
    number?: number | null;
    description: string;
    days: number;
  }>;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      company: { __typename?: "Company"; registeredName: string };
    };
  }>;
};

export type CompEventHistoryConfirmationAnswersFieldsFragment = {
  __typename?: "CompEventHistoryConfirmationAnswers";
  assumptions?: string | null;
  ewCouldveBeenGiven: boolean;
};

export type CompEventHistoryItemFieldsFragment = {
  __typename?: "CompEventHistoryItem";
  id: string;
  compEventId: string;
  actionType: CompEventActionType;
  actionNumber: string;
  isIncomplete?: boolean | null;
  dateSent: any;
  dateCreated: any;
  dueDate?: any | null;
  sentByUserId: string;
  sentByPartyId?: string | null;
  offline: boolean;
  daysLate: number;
  authorizationWorkflowAudit?: {
    __typename?: "AuthorizationWorkflowAudit";
    id: string;
    initiatedById: string;
    regardingProductItemId: string;
    regardingProductType: ProductType;
    regardingItemHistoryId?: string | null;
    workflowId: string;
    actionId: string;
    status: AuthorizationWorkflowAuditStatus;
    dateCreated: any;
    dateInitiated: any;
    initiatedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    action: {
      __typename?: "AuthorizationWorkflowActionMapping";
      id: string;
      productOutputActionId: string;
      workflowId: string;
      partyId?: string | null;
      productInstanceId: string;
      status: AuthorizationWorkflowActionMappingStatus;
      dateCreated: any;
      creatorId: string;
      modifiedById: string;
      dateModified: any;
      productOutputAction: {
        __typename?: "ProductOutputAction";
        id: string;
        name: string;
        productId: string;
        isInternal: boolean;
        status: ProductOutputActionStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      party?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        contractId: string;
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
        contract: {
          __typename?: "Contract";
          id: string;
          friendlyName: string;
          timeZone: string;
          valueCurrency: string;
          project: { __typename?: "Project"; id: string; friendlyName: string };
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    regardingProductItem:
      | {
          __typename: "ClaimItem";
          id: string;
          number: string;
          regardingId: string;
          claimRegardingType: ClaimRegardingType;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        }
      | {
          __typename: "CompEventItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          regardingType: CompEventRegardingType;
          status: CompEventStatus;
          price?: number | null;
          time?: number | null;
          earlyWarningId?: string | null;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          deemingDate?: any | null;
          lastAction: {
            __typename?: "CompEventItemLastAction";
            actionType: CompEventActionType;
            daysLate?: number | null;
            date: any;
          };
          nextStep?: {
            __typename?: "CompEventItemNextStep";
            actionType: CompEventActionType;
            dueDate: any;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            status: ProductInstanceStatus;
            product:
              | { __typename?: "Claims"; id: string; name: string }
              | { __typename?: "CompEvents"; id: string; name: string }
              | { __typename?: "DailyDiary"; id: string; name: string }
              | { __typename?: "EarlyWarnings"; id: string; name: string }
              | { __typename?: "Events"; id: string; name: string }
              | { __typename?: "Instructions"; id: string; name: string }
              | { __typename?: "RisksRegister"; id: string; name: string }
              | { __typename?: "Variations"; id: string; name: string };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
              timeZone: string;
              valueCurrency: string;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
          };
          earlyWarning?: {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            dateSent: any;
            number: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          notifiedByParty: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
        }
      | {
          __typename?: "DailyDiaryItem";
          id: string;
          date: any;
          number: string;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          sentById?: string | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              timeZone: string;
              number: string;
              status: ContractStatus;
              dateCreated: any;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
          };
        }
      | {
          __typename?: "EarlyWarningItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          creatorId: string;
          dateModified: any;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          owner: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            productSchema: {
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  displayOrder: number;
                  columnPlacement: ColumnPlacementType;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  dateCreated: any;
                  creatorId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      displayOrder: number;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      extraData?: string | null;
                      status: SchemaFieldStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            };
            contract: {
              __typename?: "Contract";
              id: string;
              valueCurrency: string;
              timeZone: string;
            };
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
          };
          attachments?: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }> | null;
        }
      | { __typename?: "EventItem" }
      | {
          __typename?: "InstructionItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          dateModified: any;
          notifyDueDate: any;
          creatorId: string;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
          attachments?: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }> | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            productSchema: {
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  displayOrder: number;
                  columnPlacement: ColumnPlacementType;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  dateCreated: any;
                  creatorId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      displayOrder: number;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      extraData?: string | null;
                      status: SchemaFieldStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            };
            contract: {
              __typename?: "Contract";
              id: string;
              contractTypeId: string;
              valueCurrency: string;
              timeZone: string;
            };
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
          };
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          statusOption: {
            __typename?: "ItemStatusOption";
            id: string;
            displayOrder: number;
            description: string;
            equatesToRemoved: boolean;
            style: ItemStatusOptionStyles;
            statusCollectionId: string;
            isInternal: boolean;
            status: ItemStatusOptionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
          owner: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }
      | { __typename?: "RiskItem" }
      | {
          __typename: "VariationItem";
          id: string;
          number: string;
          regardingId: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        };
    auditTrails?: Array<{
      __typename?: "AuthorizationWorkflowAuditTrail";
      id: string;
      auditId: string;
      regardingProductItemId: string;
      regardingItemHistoryId?: string | null;
      regardingProductType: ProductType;
      productInstanceId: string;
      actionId: string;
      status: AuthorizationActionStatus;
      denialReason?: string | null;
      dateCreated: any;
      creatorId: string;
      productInstance?: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        contractId: string;
        productId: string;
        productSchemaId: string;
        statusCollectionId: string;
        status: ProductInstanceStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contract: {
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          number: string;
          valueCurrency: string;
          value: number;
          startDate?: any | null;
          endDate?: any | null;
          contractTypeId: string;
          projectId: string;
          country: string;
          province?: string | null;
          timeZone: string;
          coordinatesLatitude?: number | null;
          coordinatesLongitude?: number | null;
          status: ContractStatus;
          dateCreated: any;
          creatorId: string;
          sections: Array<{
            __typename?: "ContractSection";
            id: string;
            number?: number | null;
            description: string;
            completionDate: any;
            status: ContractSectionStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          keyDates: Array<{
            __typename?: "ContractKeyDate";
            id: string;
            number?: number | null;
            conditionToBeMet: string;
            keyDate: any;
            status: ContractKeyDateStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            status: ProjectStatus;
          };
          contractType: {
            __typename?: "ContractType";
            id: string;
            description: string;
            subType?: string | null;
            version?: string | null;
            status: ContractTypeStatus;
          };
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
        statusCollection: {
          __typename?: "ItemStatusCollection";
          id: string;
          name: string;
          isDefault: boolean;
          status: ItemStatusCollectionStatus;
        };
      } | null;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }> | null;
  } | null;
  noticeAnswers?: {
    __typename?: "CompEventHistoryNoticeAnswers";
    claimTypeId: string;
    earlyWarningId?: string | null;
    claimType: {
      __typename?: "ClaimTypeInfo";
      id: string;
      clause: string;
      description: string;
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      severity: string;
      number: string;
      productInstanceId: string;
      ownerId: string;
      statusOptionId: string;
      dateCreated: any;
      creatorId: string;
      dateModified: any;
      offline: boolean;
      givenById?: string | null;
      dateSent: any;
    } | null;
  } | null;
  confirmationAnswers?: {
    __typename?: "CompEventHistoryConfirmationAnswers";
    assumptions?: string | null;
    ewCouldveBeenGiven: boolean;
  } | null;
  rejectionAnswers?: {
    __typename?: "CompEventHistoryRejectionAnswers";
    reason: string;
  } | null;
  noticeRequestQuotationAnswers?: {
    __typename?: "CompEventHistoryNoticeRequestQuotationAnswers";
    claimTypeId: string;
    assumptions?: string | null;
    claimType: {
      __typename?: "ClaimTypeInfo";
      id: string;
      clause: string;
      description: string;
    };
  } | null;
  quotationRevisionRequestAnswers?: {
    __typename?: "CompEventHistoryQuotationRevisionRequestAnswers";
    reason: string;
  } | null;
  quotationAcceptanceAnswers?: {
    __typename?: "CompEventHistoryQuotationAcceptanceAnswers";
    acceptedQuotationId: string;
    acceptedQuotation: {
      __typename?: "CompEventQuotation";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      currency: string;
      time: number;
      accepted?: boolean | null;
      revision: number;
      dateCreated: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      sectionalChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          company: { __typename?: "Company"; registeredName: string };
        };
      }>;
    };
  } | null;
  noticeOfSilenceCompEventNoticeAnswers?: {
    __typename?: "CompEventHistoryNoticeOfSilenceCompEventNoticeAnswers";
    remarks?: string | null;
    deemedAcceptedDate: any;
  } | null;
  noticeOfSilenceQuotationAnswers?: {
    __typename?: "CompEventHistoryNoticeOfSilenceQuotationAnswers";
    acceptedQuotationId: string;
    remarks?: string | null;
    deemedAcceptedDate: any;
    acceptedQuotation: {
      __typename?: "CompEventQuotation";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      currency: string;
      time: number;
      accepted?: boolean | null;
      revision: number;
      dateCreated: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      sectionalChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          company: { __typename?: "Company"; registeredName: string };
        };
      }>;
    };
  } | null;
  noticeOfSilenceOwnAssessmentAnswers?: {
    __typename?: "CompEventHistoryNoticeOfSilenceOwnAssessmentAnswers";
    acceptedQuotationId: string;
    remarks?: string | null;
    deemedAcceptedDate: any;
    acceptedQuotation: {
      __typename?: "CompEventQuotation";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      currency: string;
      time: number;
      accepted?: boolean | null;
      revision: number;
      dateCreated: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      sectionalChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          company: { __typename?: "Company"; registeredName: string };
        };
      }>;
    };
  } | null;
  noticeProposedInstructionAnswers?: {
    __typename?: "CompEventHistoryNoticeProposedInstructionAnswers";
    claimTypeId: string;
    assumptions?: string | null;
    claimType: {
      __typename?: "ClaimTypeInfo";
      id: string;
      clause: string;
      description: string;
    };
  } | null;
  abandonInstructionAnswers?: {
    __typename?: "CompEventHistoryAbandonInstructionAnswers";
    remarks: string;
  } | null;
  quotationAnswers?: {
    __typename?: "CompEventHistoryQuotationAnswers";
    preferredQuotationId: string;
    preferredQuotation: {
      __typename?: "CompEventQuotation";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      currency: string;
      time: number;
      accepted?: boolean | null;
      revision: number;
      dateCreated: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      sectionalChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          company: { __typename?: "Company"; registeredName: string };
        };
      }>;
    };
  } | null;
  ownAssessmentNoticeAnswers?: {
    __typename?: "CompEventHistoryOwnAssessmentNoticeAnswers";
    reason: string;
  } | null;
  ownAssessmentAnswers?: {
    __typename?: "CompEventHistoryOwnAssessmentAnswers";
    draftAssessmentId?: string | null;
    assessmentId?: string | null;
    draftAssessment?: {
      __typename?: "DraftAssessment";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    } | null;
    assessment?: {
      __typename?: "CompEventAssessment";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      currency: string;
      time: number;
      revision: number;
      dateCreated: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      sectionalChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          company: { __typename?: "Company"; registeredName: string };
        };
      }>;
    } | null;
  } | null;
  requestForMoreTimeAnswers?: {
    __typename?: "CompEventHistoryRequestForMoreTimeAnswers";
    requestedDays: number;
    remarks?: string | null;
  } | null;
  grantingOfMoreTimeAnswers?: {
    __typename?: "CompEventHistoryGrantingOfMoreTimeAnswers";
    grantedDays: number;
    remarks?: string | null;
  } | null;
  denyingOfMoreTimeAnswers?: {
    __typename?: "CompEventHistoryDenyingOfMoreTimeAnswers";
    remarks?: string | null;
  } | null;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
  recipients: Array<{
    __typename?: "CompEventRecipient";
    userId: string;
    name: string;
    preference: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      email: boolean;
    };
  }>;
  sentByUser: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  sentByParty?: {
    __typename?: "ContractBindingType";
    id: string;
    description: string;
    contractTypeId: string;
    status: ContractBindingTypeStatus;
  } | null;
};

export type CompEventHistoryNoticeAnswersFieldsFragment = {
  __typename?: "CompEventHistoryNoticeAnswers";
  claimTypeId: string;
  earlyWarningId?: string | null;
  claimType: {
    __typename?: "ClaimTypeInfo";
    id: string;
    clause: string;
    description: string;
  };
  earlyWarning?: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    creatorId: string;
    dateModified: any;
    offline: boolean;
    givenById?: string | null;
    dateSent: any;
  } | null;
};

export type CompEventHistoryRejectionAnswersFieldsFragment = {
  __typename?: "CompEventHistoryRejectionAnswers";
  reason: string;
};

export type CompEventItemFieldsFragment = {
  __typename: "CompEventItem";
  id: string;
  number: string;
  productInstanceId: string;
  regardingId: string;
  regardingType: CompEventRegardingType;
  status: CompEventStatus;
  price?: number | null;
  time?: number | null;
  earlyWarningId?: string | null;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  deemingDate?: any | null;
  lastAction: {
    __typename?: "CompEventItemLastAction";
    actionType: CompEventActionType;
    daysLate?: number | null;
    date: any;
  };
  nextStep?: {
    __typename?: "CompEventItemNextStep";
    actionType: CompEventActionType;
    dueDate: any;
  } | null;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    status: ProductInstanceStatus;
    product:
      | { __typename?: "Claims"; id: string; name: string }
      | { __typename?: "CompEvents"; id: string; name: string }
      | { __typename?: "DailyDiary"; id: string; name: string }
      | { __typename?: "EarlyWarnings"; id: string; name: string }
      | { __typename?: "Events"; id: string; name: string }
      | { __typename?: "Instructions"; id: string; name: string }
      | { __typename?: "RisksRegister"; id: string; name: string }
      | { __typename?: "Variations"; id: string; name: string };
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      status: ContractStatus;
      timeZone: string;
      valueCurrency: string;
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        status: ProjectStatus;
      };
    };
  };
  earlyWarning?: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
    dateSent: any;
    number: string;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
  regardingEvent?: {
    __typename?: "EventItem";
    id: string;
    title: string;
    productInstanceId: string;
    number: string;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
  regardingInstruction?: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
  notifiedByParty: {
    __typename?: "ContractBindingType";
    id: string;
    description: string;
    contractTypeId: string;
    status: ContractBindingTypeStatus;
  };
};

export type CeListItemFieldsFragment = {
  __typename?: "ListItem";
  id: string;
  name: string;
};

export type CompEventQuotationTimeChangeFieldsFragment = {
  __typename?: "CompEventQuotationTimeChange";
  number?: number | null;
  description: string;
  days: number;
};

export type CompEventQuotationFieldsFragment = {
  __typename?: "CompEventQuotation";
  id: string;
  compEventId: string;
  reference: string;
  details: string;
  price: number;
  currency: string;
  time: number;
  accepted?: boolean | null;
  revision: number;
  dateCreated: any;
  creatorId: string;
  keyDatesChanges: Array<{
    __typename?: "CompEventQuotationTimeChange";
    number?: number | null;
    description: string;
    days: number;
  }>;
  sectionalChanges: Array<{
    __typename?: "CompEventQuotationTimeChange";
    number?: number | null;
    description: string;
    days: number;
  }>;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      company: { __typename?: "Company"; registeredName: string };
    };
  }>;
};

export type CompEventRecipientFieldsFragment = {
  __typename?: "CompEventRecipient";
  userId: string;
  name: string;
  preference: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
};

export type CompanyLiteFieldsFragment = {
  __typename?: "Company";
  id: string;
  type?: CompanyType | null;
  registeredName: string;
  tradingName: string;
  registrationNumber?: string | null;
  logo?: string | null;
  vatRegistrationNumber?: string | null;
  status: CompanyStatus;
  creatorId: string;
  dateCreated: any;
  physicalAddress?: {
    __typename?: "Address";
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    provinceState?: string | null;
    country: string;
    code?: string | null;
  } | null;
  postalAddress?: {
    __typename?: "Address";
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    provinceState?: string | null;
    country: string;
    code?: string | null;
  } | null;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type CompanyLookupCollectionFieldsFragment = {
  __typename?: "CompanyLookupCollection";
  id: string;
  companyId: string;
  name: string;
  optionsLockedForUsers: boolean;
  optionsCharLengthLimit?: number | null;
  usage: CompanyLookupCollectionUsage;
  status: CompanyLookupCollectionStatus;
  creatorId: string;
  dateCreated: any;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  options: {
    __typename?: "LookupOptionList";
    items: Array<{
      __typename?: "LookupOption";
      id: string;
      value: string;
      displayOrder: number;
      collectionId: string;
      isInternal: boolean;
      status: LookupOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type ContractBindingLiteFieldsFragment = {
  __typename?: "ContractBinding";
  id: string;
  contractBindingTypeId: string;
  contractId: string;
  companyId: string;
  defaultCompany: boolean;
  representativeId?: string | null;
  isCompanyBilled: boolean;
  status: ContractBindingStatus;
  dateCreated: any;
  creatorId: string;
};

export type ContractBindingTypeFieldsFragment = {
  __typename?: "ContractBindingType";
  id: string;
  description: string;
  contractTypeId: string;
  status: ContractBindingTypeStatus;
};

export type ContractKeyDateFieldsFragment = {
  __typename?: "ContractKeyDate";
  id: string;
  number?: number | null;
  conditionToBeMet: string;
  keyDate: any;
  status: ContractKeyDateStatus;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type ContractLiteFieldsFragment = {
  __typename?: "Contract";
  id: string;
  name: string;
  friendlyName: string;
  number: string;
  valueCurrency: string;
  value: number;
  startDate?: any | null;
  endDate?: any | null;
  contractTypeId: string;
  projectId: string;
  country: string;
  province?: string | null;
  timeZone: string;
  coordinatesLatitude?: number | null;
  coordinatesLongitude?: number | null;
  status: ContractStatus;
  dateCreated: any;
  creatorId: string;
  sections: Array<{
    __typename?: "ContractSection";
    id: string;
    number?: number | null;
    description: string;
    completionDate: any;
    status: ContractSectionStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }>;
  keyDates: Array<{
    __typename?: "ContractKeyDate";
    id: string;
    number?: number | null;
    conditionToBeMet: string;
    keyDate: any;
    status: ContractKeyDateStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }>;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    friendlyName: string;
    status: ProjectStatus;
  };
  contractType: {
    __typename?: "ContractType";
    id: string;
    description: string;
    subType?: string | null;
    version?: string | null;
    status: ContractTypeStatus;
  };
};

export type ContractSectionFieldsFragment = {
  __typename?: "ContractSection";
  id: string;
  number?: number | null;
  description: string;
  completionDate: any;
  status: ContractSectionStatus;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type ContractTypeLiteFieldsFragment = {
  __typename?: "ContractType";
  id: string;
  description: string;
  subType?: string | null;
  version?: string | null;
  status: ContractTypeStatus;
};

export type CreatorFieldsFragment = {
  __typename?: "User";
  id: string;
  firstname: string;
  surname: string;
  email: any;
};

export type DailyDiaryDelayRecordLiteFieldsFragment = {
  __typename?: "DailyDiaryDelayRecord";
  id: string;
  description: string;
  delayTypeOptionId: string;
  delayTypeLookupId: string;
  areaOptionId?: string | null;
  areaLookupId?: string | null;
  disciplineOptionId?: string | null;
  disciplineLookupId?: string | null;
  shiftOptionId?: string | null;
  shiftLookupId?: string | null;
  teamOptionId?: string | null;
  teamLookupId?: string | null;
  duration?: any | null;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type DailyDiaryGeneralRecordLiteFieldsFragment = {
  __typename?: "DailyDiaryGeneralRecord";
  id: string;
  description: string;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type DailyDiaryHseRecordLiteFieldsFragment = {
  __typename?: "DailyDiaryHSERecord";
  id: string;
  description: string;
  hseTypeOptionId: string;
  hseTypeLookupId: string;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type DailyDiaryItemFieldsFragment = {
  __typename?: "DailyDiaryItem";
  id: string;
  productInstanceId: string;
  date: any;
  number: string;
  status: DailyDiaryItemStatus;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  sentById?: string | null;
  dateSent?: any | null;
  revisions: Array<{
    __typename?: "DailyDiaryItemRevision";
    id: string;
    number: string;
    sentById?: string | null;
    dateSent?: any | null;
    status: DailyDiaryItemStatus;
    authorizationWorkflowAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      workflowId: string;
      actionId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      dateInitiated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      regardingProductItem:
        | {
            __typename: "ClaimItem";
            id: string;
            number: string;
            regardingId: string;
            claimRegardingType: ClaimRegardingType;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename: "CompEventItem";
            id: string;
            number: string;
            productInstanceId: string;
            regardingId: string;
            regardingType: CompEventRegardingType;
            status: CompEventStatus;
            price?: number | null;
            time?: number | null;
            earlyWarningId?: string | null;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            deemingDate?: any | null;
            lastAction: {
              __typename?: "CompEventItemLastAction";
              actionType: CompEventActionType;
              daysLate?: number | null;
              date: any;
            };
            nextStep?: {
              __typename?: "CompEventItemNextStep";
              actionType: CompEventActionType;
              dueDate: any;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              status: ProductInstanceStatus;
              product:
                | { __typename?: "Claims"; id: string; name: string }
                | { __typename?: "CompEvents"; id: string; name: string }
                | { __typename?: "DailyDiary"; id: string; name: string }
                | { __typename?: "EarlyWarnings"; id: string; name: string }
                | { __typename?: "Events"; id: string; name: string }
                | { __typename?: "Instructions"; id: string; name: string }
                | { __typename?: "RisksRegister"; id: string; name: string }
                | { __typename?: "Variations"; id: string; name: string };
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
                timeZone: string;
                valueCurrency: string;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
            };
            earlyWarning?: {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              dateSent: any;
              number: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingEvent?: {
              __typename?: "EventItem";
              id: string;
              title: string;
              productInstanceId: string;
              number: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingInstruction?: {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              number: string;
              productInstanceId: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            notifiedByParty: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          }
        | {
            __typename?: "DailyDiaryItem";
            id: string;
            date: any;
            number: string;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            sentById?: string | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              status: ProductInstanceStatus;
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                timeZone: string;
                number: string;
                status: ContractStatus;
                dateCreated: any;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
              product:
                | {
                    __typename?: "Claims";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Events";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Instructions";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  };
            };
          }
        | {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            creatorId: string;
            dateModified: any;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
          }
        | { __typename?: "EventItem" }
        | {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            dateModified: any;
            notifyDueDate: any;
            creatorId: string;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                contractTypeId: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            statusOption: {
              __typename?: "ItemStatusOption";
              id: string;
              displayOrder: number;
              description: string;
              equatesToRemoved: boolean;
              style: ItemStatusOptionStyles;
              statusCollectionId: string;
              isInternal: boolean;
              status: ItemStatusOptionStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }
        | { __typename?: "RiskItem" }
        | {
            __typename: "VariationItem";
            id: string;
            number: string;
            regardingId: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    } | null;
    sentBy?: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    } | null;
  }>;
  latestRevision: {
    __typename?: "DailyDiaryItemRevision";
    id: string;
    number: string;
    sentById?: string | null;
    dateSent?: any | null;
    status: DailyDiaryItemStatus;
    authorizationWorkflowAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      workflowId: string;
      actionId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      dateInitiated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      regardingProductItem:
        | {
            __typename: "ClaimItem";
            id: string;
            number: string;
            regardingId: string;
            claimRegardingType: ClaimRegardingType;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename: "CompEventItem";
            id: string;
            number: string;
            productInstanceId: string;
            regardingId: string;
            regardingType: CompEventRegardingType;
            status: CompEventStatus;
            price?: number | null;
            time?: number | null;
            earlyWarningId?: string | null;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            deemingDate?: any | null;
            lastAction: {
              __typename?: "CompEventItemLastAction";
              actionType: CompEventActionType;
              daysLate?: number | null;
              date: any;
            };
            nextStep?: {
              __typename?: "CompEventItemNextStep";
              actionType: CompEventActionType;
              dueDate: any;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              status: ProductInstanceStatus;
              product:
                | { __typename?: "Claims"; id: string; name: string }
                | { __typename?: "CompEvents"; id: string; name: string }
                | { __typename?: "DailyDiary"; id: string; name: string }
                | { __typename?: "EarlyWarnings"; id: string; name: string }
                | { __typename?: "Events"; id: string; name: string }
                | { __typename?: "Instructions"; id: string; name: string }
                | { __typename?: "RisksRegister"; id: string; name: string }
                | { __typename?: "Variations"; id: string; name: string };
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
                timeZone: string;
                valueCurrency: string;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
            };
            earlyWarning?: {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              dateSent: any;
              number: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingEvent?: {
              __typename?: "EventItem";
              id: string;
              title: string;
              productInstanceId: string;
              number: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingInstruction?: {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              number: string;
              productInstanceId: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            notifiedByParty: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          }
        | {
            __typename?: "DailyDiaryItem";
            id: string;
            date: any;
            number: string;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            sentById?: string | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              status: ProductInstanceStatus;
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                timeZone: string;
                number: string;
                status: ContractStatus;
                dateCreated: any;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
              product:
                | {
                    __typename?: "Claims";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Events";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Instructions";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  };
            };
          }
        | {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            creatorId: string;
            dateModified: any;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
          }
        | { __typename?: "EventItem" }
        | {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            dateModified: any;
            notifyDueDate: any;
            creatorId: string;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                contractTypeId: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            statusOption: {
              __typename?: "ItemStatusOption";
              id: string;
              displayOrder: number;
              description: string;
              equatesToRemoved: boolean;
              style: ItemStatusOptionStyles;
              statusCollectionId: string;
              isInternal: boolean;
              status: ItemStatusOptionStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }
        | { __typename?: "RiskItem" }
        | {
            __typename: "VariationItem";
            id: string;
            number: string;
            regardingId: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    } | null;
    sentBy?: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    } | null;
  };
  submissions: Array<{
    __typename?: "DailyDiaryItemSubmission";
    userId: string;
    date?: any | null;
    user: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  }>;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  sentBy?: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  } | null;
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    soloModeSupported: boolean;
    contractId: string;
    productId: string;
    productSchemaId: string;
    statusCollectionId: string;
    status: ProductInstanceStatus;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      number: string;
      valueCurrency: string;
      value: number;
      startDate?: any | null;
      endDate?: any | null;
      contractTypeId: string;
      projectId: string;
      country: string;
      province?: string | null;
      timeZone: string;
      coordinatesLatitude?: number | null;
      coordinatesLongitude?: number | null;
      status: ContractStatus;
      dateCreated: any;
      creatorId: string;
      sections: Array<{
        __typename?: "ContractSection";
        id: string;
        number?: number | null;
        description: string;
        completionDate: any;
        status: ContractSectionStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
      keyDates: Array<{
        __typename?: "ContractKeyDate";
        id: string;
        number?: number | null;
        conditionToBeMet: string;
        keyDate: any;
        status: ContractKeyDateStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        status: ProjectStatus;
      };
      contractType: {
        __typename?: "ContractType";
        id: string;
        description: string;
        subType?: string | null;
        version?: string | null;
        status: ContractTypeStatus;
      };
    };
    product:
      | {
          __typename?: "Claims";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "CompEvents";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "DailyDiary";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "EarlyWarnings";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Events";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Instructions";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "RisksRegister";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Variations";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        };
    statusCollection: {
      __typename?: "ItemStatusCollection";
      id: string;
      name: string;
      isDefault: boolean;
      status: ItemStatusCollectionStatus;
    };
  };
};

export type DailyDiaryItemSubmissionFieldsFragment = {
  __typename?: "DailyDiaryItemSubmission";
  userId: string;
  date?: any | null;
  user: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type DailyDiaryPresetFieldsFragment = {
  __typename?: "DailyDiaryPreset";
  id: string;
  name: string;
  status: DailyDiaryPresetStatus;
  dateCreated: any;
  creatorId: string;
  sections: Array<{
    __typename?: "DailyDiaryPresetSection";
    name: string;
    fields: Array<{
      __typename?: "DailyDiaryPresetSectionField";
      name: string;
      displayName?: string | null;
      fieldType: FieldTypeEnum;
      isRequired: boolean;
      lookupId?: string | null;
      isHidden: boolean;
      unit?: string | null;
    }>;
  }>;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type DailyDiaryPresetSchemaSectionFieldFieldsFragment = {
  __typename?: "DailyDiaryPresetSchemaSectionField";
  name: string;
  displayName?: string | null;
  fieldType: FieldTypeEnum;
  isRequired: boolean;
  canBeHidden: boolean;
  units: Array<string>;
};

export type DailyDiaryPresetSectionFieldFieldsFragment = {
  __typename?: "DailyDiaryPresetSectionField";
  name: string;
  displayName?: string | null;
  fieldType: FieldTypeEnum;
  isRequired: boolean;
  lookupId?: string | null;
  isHidden: boolean;
  unit?: string | null;
};

export type DailyDiaryResourceRecordLiteFieldsFragment = {
  __typename?: "DailyDiaryResourceRecord";
  id: string;
  resourceOptionId: string;
  resourceLookupId: string;
  quantity: number;
  hours: any;
  totalHours: string;
  remarks?: string | null;
  companyOptionId?: string | null;
  companyLookupId?: string | null;
  shiftOptionId?: string | null;
  shiftLookupId?: string | null;
  teamOptionId?: string | null;
  teamLookupId?: string | null;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type DailyDiaryWeatherRecordLiteFieldsFragment = {
  __typename?: "DailyDiaryWeatherRecord";
  id: string;
  conditionsOptionId: string;
  conditionsLookupId: string;
  temperature?: number | null;
  rainFall?: number | null;
  windSpeed?: number | null;
  humidity?: number | null;
  timeOfMeasurement: any;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type DailyDiaryWorkRecordLiteFieldsFragment = {
  __typename?: "DailyDiaryWorkRecord";
  id: string;
  description?: string | null;
  areaOptionId?: string | null;
  areaLookupId?: string | null;
  disciplineOptionId?: string | null;
  disciplineLookupId?: string | null;
  teamOptionId?: string | null;
  teamLookupId?: string | null;
  activityOptionId?: string | null;
  activityLookupId?: string | null;
  workTypeOptionId?: string | null;
  workTypeLookupId?: string | null;
  shiftOptionId?: string | null;
  shiftLookupId?: string | null;
  amount?: number | null;
  unitOfMeasurementOptionId?: string | null;
  unitOfMeasurementLookupId?: string | null;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type DetailedClaimLiteFieldsFragment = {
  __typename?: "DetailedClaim";
  id: string;
  claimId: string;
  reference: string;
  details: string;
  price: number;
  currency: string;
  time: number;
  dateCreated: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "DetailedClaimTimeChange";
    number?: number | null;
    description: string;
    days: number;
  }>;
};

export type DisabledClaimTypeOverrideFieldsFragment = {
  __typename?: "DisabledClaimTypeOverride";
  id: string;
  contractId: string;
  claimTypeId: string;
  dateCreated: any;
  creatorId: string;
  claimType: {
    __typename?: "ClaimType";
    id: string;
    contractTypeId: string;
    clause: string;
    description: string;
    isInternal: boolean;
    status: ClaimTypeStatus;
    dateCreated: any;
    creatorId: string;
    contractType: {
      __typename?: "ContractType";
      id: string;
      description: string;
      subType?: string | null;
      version?: string | null;
      status: ContractTypeStatus;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
  contract: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
    timeZone: string;
  };
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type DocumentTemplateFieldsFragment = {
  __typename?: "DocumentTemplate";
  id: string;
  name: string;
  description: string;
  content: string;
  isInternal: boolean;
  status: DocumentTemplateStatus;
  dateCreated: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
  };
};

export type DraftAssessmentFieldsFragment = {
  __typename?: "DraftAssessment";
  id: string;
  compEventId: string;
  reference: string;
  details: string;
  price: number;
  time: number;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  keyDatesChanges: Array<{
    __typename?: "ContractTimeImpact";
    id: string;
    days: number;
  } | null>;
  sectionalChanges: Array<{
    __typename?: "ContractTimeImpact";
    id: string;
    days: number;
  } | null>;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
};

export type DraftClaimAgreementFieldsFragment = {
  __typename?: "DraftClaimAgreement";
  id: string;
  claimId: string;
  reference: string;
  details: string;
  price: number;
  time: number;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "ContractTimeImpact";
    id: string;
    days: number;
  } | null>;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
};

export type DraftClaimDeterminationFieldsFragment = {
  __typename?: "DraftClaimDetermination";
  id: string;
  claimId: string;
  reference: string;
  details: string;
  price: number;
  time: number;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "ContractTimeImpact";
    id: string;
    days: number;
  } | null>;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
};

export type DraftDetailedClaimFieldsFragment = {
  __typename?: "DraftDetailedClaim";
  id: string;
  claimId: string;
  reference: string;
  details: string;
  price: number;
  time: number;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "ContractTimeImpact";
    id: string;
    days: number;
  } | null>;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
};

export type DraftQuotationFieldsFragment = {
  __typename?: "DraftQuotation";
  id: string;
  compEventId: string;
  reference: string;
  details: string;
  price: number;
  time: number;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  keyDatesChanges: Array<{
    __typename?: "ContractTimeImpact";
    id: string;
    days: number;
  } | null>;
  sectionalChanges: Array<{
    __typename?: "ContractTimeImpact";
    id: string;
    days: number;
  } | null>;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
};

export type DraftVariationAgreementFieldsFragment = {
  __typename?: "DraftVariationAgreement";
  id: string;
  variationId: string;
  reference: string;
  details: string;
  price: number;
  time: number;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "ContractTimeImpact";
    id: string;
    days: number;
  } | null>;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
};

export type DraftVariationDeterminationFieldsFragment = {
  __typename?: "DraftVariationDetermination";
  id: string;
  variationId: string;
  reference: string;
  details: string;
  price: number;
  time: number;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "ContractTimeImpact";
    id: string;
    days: number;
  } | null>;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
};

export type DraftVariationProposalFieldsFragment = {
  __typename?: "DraftVariationProposal";
  id: string;
  variationId: string;
  reference: string;
  details: string;
  price: number;
  time: number;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "ContractTimeImpact";
    id: string;
    days: number;
  } | null>;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
};

export type EarlyWarningItemFieldsFragment = {
  __typename?: "EarlyWarningItem";
  id: string;
  title: string;
  severity: string;
  number: string;
  productInstanceId: string;
  ownerId: string;
  statusOptionId: string;
  dateCreated: any;
  creatorId: string;
  dateModified: any;
  offline: boolean;
  givenById?: string | null;
  dateSent: any;
  data: {
    __typename?: "ItemData";
    sections: Array<{
      __typename?: "ItemDataSection";
      name: string;
      entries: Array<{
        __typename?: "ItemDataEntry";
        name: string;
        value: string;
      }>;
    }>;
  };
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  owner: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    productSchema: {
      __typename?: "ProductSchema";
      id: string;
      name: string;
      isDefault: boolean;
      productId: string;
      isInternal: boolean;
      status: ProductSchemaStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      schemaSections: {
        __typename?: "SchemaSectionList";
        items: Array<{
          __typename?: "SchemaSection";
          id: string;
          name: string;
          displayText: string;
          displayOrder: number;
          columnPlacement: ColumnPlacementType;
          productSchemaId: string;
          isInternal: boolean;
          status: SchemaSectionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          schemaFields: {
            __typename?: "SchemaFieldList";
            items: Array<{
              __typename?: "SchemaField";
              id: string;
              name: string;
              schemaSectionId: string;
              fieldTypeId: string;
              displayText: string;
              displayOrder: number;
              isRequired: boolean;
              isInternal: boolean;
              complexTypeItemId?: string | null;
              extraData?: string | null;
              status: SchemaFieldStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              fieldType: {
                __typename?: "FieldType";
                id: string;
                description: string;
                shortCode: FieldTypeEnum;
                status: FieldTypeStatus;
              };
            }>;
          };
        }>;
      };
    };
    contract: {
      __typename?: "Contract";
      id: string;
      valueCurrency: string;
      timeZone: string;
    };
    product:
      | { __typename?: "Claims"; id: string; numberingFormat: string }
      | { __typename?: "CompEvents"; id: string; numberingFormat: string }
      | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
      | { __typename?: "EarlyWarnings"; id: string; numberingFormat: string }
      | { __typename?: "Events"; id: string; numberingFormat: string }
      | { __typename?: "Instructions"; id: string; numberingFormat: string }
      | { __typename?: "RisksRegister"; id: string; numberingFormat: string }
      | { __typename?: "Variations"; id: string; numberingFormat: string };
  };
  attachments?: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }> | null;
};

export type EarlyWarningItemLiteFieldsFragment = {
  __typename?: "EarlyWarningItem";
  id: string;
  title: string;
  severity: string;
  number: string;
  productInstanceId: string;
  ownerId: string;
  statusOptionId: string;
  dateCreated: any;
  creatorId: string;
  dateModified: any;
  offline: boolean;
  givenById?: string | null;
  dateSent: any;
};

export type EventItemFieldsFragment = {
  __typename?: "EventItem";
  id: string;
  title: string;
  number: string;
  productInstanceId: string;
  ownerId: string;
  statusOptionId: string;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    contract: {
      __typename?: "Contract";
      valueCurrency: string;
      timeZone: string;
    };
  };
  data: {
    __typename?: "ItemData";
    sections: Array<{
      __typename?: "ItemDataSection";
      name: string;
      entries: Array<{
        __typename?: "ItemDataEntry";
        name: string;
        value: string;
      }>;
    }>;
  };
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  statusOption: {
    __typename?: "ItemStatusOption";
    id: string;
    displayOrder: number;
    description: string;
    equatesToRemoved: boolean;
    style: ItemStatusOptionStyles;
    statusCollectionId: string;
    isInternal: boolean;
    status: ItemStatusOptionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
  owner: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type InstructionItemBaseFieldsFragment = {
  __typename?: "InstructionItem";
  id: string;
  title: string;
  severity: string;
  number: string;
  productInstanceId: string;
  ownerId: string;
  statusOptionId: string;
  dateCreated: any;
  dateModified: any;
  notifyDueDate: any;
  creatorId: string;
  offline: boolean;
  givenById?: string | null;
  dateSent: any;
  data: {
    __typename?: "ItemData";
    sections: Array<{
      __typename?: "ItemDataSection";
      name: string;
      entries: Array<{
        __typename?: "ItemDataEntry";
        name: string;
        value: string;
      }>;
    }>;
  };
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  statusOption: {
    __typename?: "ItemStatusOption";
    id: string;
    displayOrder: number;
    description: string;
    equatesToRemoved: boolean;
    style: ItemStatusOptionStyles;
    statusCollectionId: string;
    isInternal: boolean;
    status: ItemStatusOptionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
  owner: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type InstructionItemFieldsFragment = {
  __typename?: "InstructionItem";
  id: string;
  title: string;
  severity: string;
  number: string;
  productInstanceId: string;
  ownerId: string;
  statusOptionId: string;
  dateCreated: any;
  dateModified: any;
  notifyDueDate: any;
  creatorId: string;
  offline: boolean;
  givenById?: string | null;
  dateSent: any;
  attachments?: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }> | null;
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    productSchema: {
      __typename?: "ProductSchema";
      id: string;
      name: string;
      isDefault: boolean;
      productId: string;
      isInternal: boolean;
      status: ProductSchemaStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      schemaSections: {
        __typename?: "SchemaSectionList";
        items: Array<{
          __typename?: "SchemaSection";
          id: string;
          name: string;
          displayText: string;
          displayOrder: number;
          columnPlacement: ColumnPlacementType;
          productSchemaId: string;
          isInternal: boolean;
          status: SchemaSectionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          schemaFields: {
            __typename?: "SchemaFieldList";
            items: Array<{
              __typename?: "SchemaField";
              id: string;
              name: string;
              schemaSectionId: string;
              fieldTypeId: string;
              displayText: string;
              displayOrder: number;
              isRequired: boolean;
              isInternal: boolean;
              complexTypeItemId?: string | null;
              extraData?: string | null;
              status: SchemaFieldStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              fieldType: {
                __typename?: "FieldType";
                id: string;
                description: string;
                shortCode: FieldTypeEnum;
                status: FieldTypeStatus;
              };
            }>;
          };
        }>;
      };
    };
    contract: {
      __typename?: "Contract";
      id: string;
      contractTypeId: string;
      valueCurrency: string;
      timeZone: string;
    };
    product:
      | { __typename?: "Claims"; id: string; numberingFormat: string }
      | { __typename?: "CompEvents"; id: string; numberingFormat: string }
      | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
      | { __typename?: "EarlyWarnings"; id: string; numberingFormat: string }
      | { __typename?: "Events"; id: string; numberingFormat: string }
      | { __typename?: "Instructions"; id: string; numberingFormat: string }
      | { __typename?: "RisksRegister"; id: string; numberingFormat: string }
      | { __typename?: "Variations"; id: string; numberingFormat: string };
  };
  data: {
    __typename?: "ItemData";
    sections: Array<{
      __typename?: "ItemDataSection";
      name: string;
      entries: Array<{
        __typename?: "ItemDataEntry";
        name: string;
        value: string;
      }>;
    }>;
  };
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  statusOption: {
    __typename?: "ItemStatusOption";
    id: string;
    displayOrder: number;
    description: string;
    equatesToRemoved: boolean;
    style: ItemStatusOptionStyles;
    statusCollectionId: string;
    isInternal: boolean;
    status: ItemStatusOptionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
  owner: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type ItemDataFieldsFragment = {
  __typename?: "ItemData";
  sections: Array<{
    __typename?: "ItemDataSection";
    name: string;
    entries: Array<{
      __typename?: "ItemDataEntry";
      name: string;
      value: string;
    }>;
  }>;
};

export type ItemStatusOptionFieldsFragment = {
  __typename?: "ItemStatusOption";
  id: string;
  displayOrder: number;
  description: string;
  equatesToRemoved: boolean;
  style: ItemStatusOptionStyles;
  statusCollectionId: string;
  isInternal: boolean;
  status: ItemStatusOptionStatus;
  creatorId: string;
  dateCreated: any;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type LookupOptionFieldsFragment = {
  __typename?: "LookupOption";
  id: string;
  value: string;
  displayOrder: number;
  collectionId: string;
  isInternal: boolean;
  status: LookupOptionStatus;
  creatorId: string;
  dateCreated: any;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type NewClaimTypeOverrideFieldsFragment = {
  __typename?: "NewClaimTypeOverride";
  id: string;
  contractId: string;
  clause: string;
  description: string;
  status: NewClaimTypeOverrideStatus;
  dateCreated: any;
  creatorId: string;
  contract: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
    timeZone: string;
  };
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type NotificationRecipientFieldsFragment = {
  __typename?: "NotificationRecipient";
  userId: string;
  name: string;
  preference: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
};

type ExtraConfigTypeFields_CompEventExtraConfig_Fragment = {
  __typename: "CompEventExtraConfig";
};

type ExtraConfigTypeFields_DailyDiaryExtraConfig_Fragment = {
  __typename: "DailyDiaryExtraConfig";
  manPowerLookupId?: string | null;
  equipmentLookupId?: string | null;
  startDate: any;
};

export type ExtraConfigTypeFieldsFragment =
  | ExtraConfigTypeFields_CompEventExtraConfig_Fragment
  | ExtraConfigTypeFields_DailyDiaryExtraConfig_Fragment;

export type ProductInstanceLiteFieldsFragment = {
  __typename?: "ProductInstance";
  id: string;
  description: string;
  numberingFormat: string;
  soloModeSupported: boolean;
  contractId: string;
  productId: string;
  productSchemaId: string;
  statusCollectionId: string;
  status: ProductInstanceStatus;
  dateCreated: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  contract: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
    number: string;
    valueCurrency: string;
    value: number;
    startDate?: any | null;
    endDate?: any | null;
    contractTypeId: string;
    projectId: string;
    country: string;
    province?: string | null;
    timeZone: string;
    coordinatesLatitude?: number | null;
    coordinatesLongitude?: number | null;
    status: ContractStatus;
    dateCreated: any;
    creatorId: string;
    sections: Array<{
      __typename?: "ContractSection";
      id: string;
      number?: number | null;
      description: string;
      completionDate: any;
      status: ContractSectionStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
    keyDates: Array<{
      __typename?: "ContractKeyDate";
      id: string;
      number?: number | null;
      conditionToBeMet: string;
      keyDate: any;
      status: ContractKeyDateStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      friendlyName: string;
      status: ProjectStatus;
    };
    contractType: {
      __typename?: "ContractType";
      id: string;
      description: string;
      subType?: string | null;
      version?: string | null;
      status: ContractTypeStatus;
    };
  };
  product:
    | {
        __typename?: "Claims";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "CompEvents";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "DailyDiary";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "EarlyWarnings";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "Events";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "Instructions";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "RisksRegister";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "Variations";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      };
  statusCollection: {
    __typename?: "ItemStatusCollection";
    id: string;
    name: string;
    isDefault: boolean;
    status: ItemStatusCollectionStatus;
  };
};

type ProductLiteFields_Claims_Fragment = {
  __typename?: "Claims";
  id: string;
  name: string;
  numberingFormat: string;
  soloModeSupported: boolean;
  allowMultipleInstances: boolean;
  status: ProductStatus;
};

type ProductLiteFields_CompEvents_Fragment = {
  __typename?: "CompEvents";
  id: string;
  name: string;
  numberingFormat: string;
  soloModeSupported: boolean;
  allowMultipleInstances: boolean;
  status: ProductStatus;
};

type ProductLiteFields_DailyDiary_Fragment = {
  __typename?: "DailyDiary";
  id: string;
  name: string;
  numberingFormat: string;
  soloModeSupported: boolean;
  allowMultipleInstances: boolean;
  status: ProductStatus;
};

type ProductLiteFields_EarlyWarnings_Fragment = {
  __typename?: "EarlyWarnings";
  id: string;
  name: string;
  numberingFormat: string;
  soloModeSupported: boolean;
  allowMultipleInstances: boolean;
  status: ProductStatus;
};

type ProductLiteFields_Events_Fragment = {
  __typename?: "Events";
  id: string;
  name: string;
  numberingFormat: string;
  soloModeSupported: boolean;
  allowMultipleInstances: boolean;
  status: ProductStatus;
};

type ProductLiteFields_Instructions_Fragment = {
  __typename?: "Instructions";
  id: string;
  name: string;
  numberingFormat: string;
  soloModeSupported: boolean;
  allowMultipleInstances: boolean;
  status: ProductStatus;
};

type ProductLiteFields_RisksRegister_Fragment = {
  __typename?: "RisksRegister";
  id: string;
  name: string;
  numberingFormat: string;
  soloModeSupported: boolean;
  allowMultipleInstances: boolean;
  status: ProductStatus;
};

type ProductLiteFields_Variations_Fragment = {
  __typename?: "Variations";
  id: string;
  name: string;
  numberingFormat: string;
  soloModeSupported: boolean;
  allowMultipleInstances: boolean;
  status: ProductStatus;
};

export type ProductLiteFieldsFragment =
  | ProductLiteFields_Claims_Fragment
  | ProductLiteFields_CompEvents_Fragment
  | ProductLiteFields_DailyDiary_Fragment
  | ProductLiteFields_EarlyWarnings_Fragment
  | ProductLiteFields_Events_Fragment
  | ProductLiteFields_Instructions_Fragment
  | ProductLiteFields_RisksRegister_Fragment
  | ProductLiteFields_Variations_Fragment;

export type ProductOutputActionFieldsFragment = {
  __typename?: "ProductOutputAction";
  id: string;
  name: string;
  productId: string;
  isInternal: boolean;
  status: ProductOutputActionStatus;
  dateCreated: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  product:
    | {
        __typename?: "Claims";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "CompEvents";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "DailyDiary";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "EarlyWarnings";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "Events";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "Instructions";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "RisksRegister";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      }
    | {
        __typename?: "Variations";
        id: string;
        name: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        allowMultipleInstances: boolean;
        status: ProductStatus;
      };
};

export type ProductSchemaFieldsFragment = {
  __typename?: "ProductSchema";
  id: string;
  name: string;
  isDefault: boolean;
  productId: string;
  isInternal: boolean;
  status: ProductSchemaStatus;
  creatorId: string;
  dateCreated: any;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  schemaSections: {
    __typename?: "SchemaSectionList";
    items: Array<{
      __typename?: "SchemaSection";
      id: string;
      name: string;
      displayText: string;
      displayOrder: number;
      columnPlacement: ColumnPlacementType;
      productSchemaId: string;
      isInternal: boolean;
      status: SchemaSectionStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      schemaFields: {
        __typename?: "SchemaFieldList";
        items: Array<{
          __typename?: "SchemaField";
          id: string;
          name: string;
          schemaSectionId: string;
          fieldTypeId: string;
          displayText: string;
          displayOrder: number;
          isRequired: boolean;
          isInternal: boolean;
          complexTypeItemId?: string | null;
          extraData?: string | null;
          status: SchemaFieldStatus;
          creatorId: string;
          dateCreated: any;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          fieldType: {
            __typename?: "FieldType";
            id: string;
            description: string;
            shortCode: FieldTypeEnum;
            status: FieldTypeStatus;
          };
        }>;
      };
    }>;
  };
};

export type ProjectLiteFieldsFragment = {
  __typename?: "Project";
  id: string;
  name: string;
  friendlyName: string;
  number?: string | null;
  sector: ProjectSector;
  description?: string | null;
  status: ProjectStatus;
  dateCreated: any;
  creatorId: string;
  billingCompanyId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  contracts: {
    __typename?: "ContractList";
    items: Array<{
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      status: ContractStatus;
    }>;
  };
  billingCompany: {
    __typename?: "Company";
    id: string;
    type?: CompanyType | null;
    registeredName: string;
    tradingName: string;
    registrationNumber?: string | null;
    logo?: string | null;
    vatRegistrationNumber?: string | null;
    status: CompanyStatus;
    creatorId: string;
    dateCreated: any;
    physicalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    postalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type SchemaFieldFieldsFragment = {
  __typename?: "SchemaField";
  id: string;
  name: string;
  schemaSectionId: string;
  fieldTypeId: string;
  displayText: string;
  displayOrder: number;
  isRequired: boolean;
  isInternal: boolean;
  complexTypeItemId?: string | null;
  extraData?: string | null;
  status: SchemaFieldStatus;
  creatorId: string;
  dateCreated: any;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  fieldType: {
    __typename?: "FieldType";
    id: string;
    description: string;
    shortCode: FieldTypeEnum;
    status: FieldTypeStatus;
  };
};

export type SchemaSectionFieldsFragment = {
  __typename?: "SchemaSection";
  id: string;
  name: string;
  displayText: string;
  displayOrder: number;
  columnPlacement: ColumnPlacementType;
  productSchemaId: string;
  isInternal: boolean;
  status: SchemaSectionStatus;
  dateCreated: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  schemaFields: {
    __typename?: "SchemaFieldList";
    items: Array<{
      __typename?: "SchemaField";
      id: string;
      name: string;
      schemaSectionId: string;
      fieldTypeId: string;
      displayText: string;
      displayOrder: number;
      isRequired: boolean;
      isInternal: boolean;
      complexTypeItemId?: string | null;
      extraData?: string | null;
      status: SchemaFieldStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      fieldType: {
        __typename?: "FieldType";
        id: string;
        description: string;
        shortCode: FieldTypeEnum;
        status: FieldTypeStatus;
      };
    }>;
  };
};

export type TimePeriodLiteFieldsFragment = {
  __typename?: "TimePeriod";
  id: string;
  contractTypeId: string;
  reference: string;
  description: string;
  duration: number;
  status: TimePeriodStatus;
  isInternal: boolean;
  dateCreated: any;
  creatorId: string;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type TimePeriodOverrideFieldsFragment = {
  __typename?: "TimePeriodOverride";
  id: string;
  contractId: string;
  timePeriodId: string;
  duration: number;
  dateCreated: any;
  creatorId: string;
  timePeriod: {
    __typename?: "TimePeriod";
    id: string;
    contractTypeId: string;
    reference: string;
    description: string;
    duration: number;
    status: TimePeriodStatus;
    isInternal: boolean;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
  contract: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
    timeZone: string;
  };
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type UserFieldsFragment = {
  __typename?: "User";
  id: string;
  firstname: string;
  surname: string;
  jobTitle: string;
  country: string;
  mobileNumber?: any | null;
  alternateNumber?: any | null;
  dateOfBirth?: any | null;
  profilePicture?: string | null;
  email: any;
  companyId: string;
  registered?: boolean | null;
  status: UserStatus;
  lastActive?: any | null;
  dateInvited: any;
  registeredDate?: any | null;
  company: {
    __typename?: "Company";
    id: string;
    type?: CompanyType | null;
    registeredName: string;
    tradingName: string;
    registrationNumber?: string | null;
    logo?: string | null;
    vatRegistrationNumber?: string | null;
    status: CompanyStatus;
    creatorId: string;
    dateCreated: any;
    physicalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    postalAddress?: {
      __typename?: "Address";
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      provinceState?: string | null;
      country: string;
      code?: string | null;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
  invitedBy: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  roles: {
    __typename?: "ProductUserRoleList";
    items: Array<{
      __typename?: "ProductUserRole";
      userId: string;
      productInstanceId: string;
      productRoleId: string;
      status: ProductUserRoleStatus;
      dateCreated: any;
      creatorId: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        contractId: string;
        productId: string;
        productSchemaId: string;
        statusCollectionId: string;
        status: ProductInstanceStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contract: {
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          number: string;
          valueCurrency: string;
          value: number;
          startDate?: any | null;
          endDate?: any | null;
          contractTypeId: string;
          projectId: string;
          country: string;
          province?: string | null;
          timeZone: string;
          coordinatesLatitude?: number | null;
          coordinatesLongitude?: number | null;
          status: ContractStatus;
          dateCreated: any;
          creatorId: string;
          sections: Array<{
            __typename?: "ContractSection";
            id: string;
            number?: number | null;
            description: string;
            completionDate: any;
            status: ContractSectionStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          keyDates: Array<{
            __typename?: "ContractKeyDate";
            id: string;
            number?: number | null;
            conditionToBeMet: string;
            keyDate: any;
            status: ContractKeyDateStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            status: ProjectStatus;
          };
          contractType: {
            __typename?: "ContractType";
            id: string;
            description: string;
            subType?: string | null;
            version?: string | null;
            status: ContractTypeStatus;
          };
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
        statusCollection: {
          __typename?: "ItemStatusCollection";
          id: string;
          name: string;
          isDefault: boolean;
          status: ItemStatusCollectionStatus;
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      user: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      productRole: {
        __typename?: "ProductRole";
        id: string;
        name: string;
        productId: string;
        actionIds: Array<string>;
        isInternal: boolean;
        status: ProductRoleStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        actions: {
          __typename?: "ProductRoleActionList";
          items: Array<{
            __typename?: "ProductRoleAction";
            id: string;
            name: string;
            isInternal: boolean;
            status: ProductRoleActionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      };
    }>;
  };
};

export type UserLiteFieldsFragment = {
  __typename?: "User";
  id: string;
  firstname: string;
  surname: string;
  jobTitle: string;
  country: string;
  mobileNumber?: any | null;
  alternateNumber?: any | null;
  dateOfBirth?: any | null;
  profilePicture?: string | null;
  email: any;
  companyId: string;
  registered?: boolean | null;
  status: UserStatus;
  lastActive?: any | null;
  dateInvited: any;
  registeredDate?: any | null;
  company: {
    __typename?: "Company";
    id: string;
    tradingName: string;
    registeredName: string;
  };
  invitedBy: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type UserPreferencesValueFieldsFragment = {
  __typename?: "UserPreferencesValue";
  inApp: boolean;
  inAppReadOnly: boolean;
  email: boolean;
  emailReadOnly: boolean;
};

export type VariationAgreementLiteFieldsFragment = {
  __typename?: "VariationAgreement";
  id: string;
  variationId: string;
  reference: string;
  details: string;
  price: number;
  currency: string;
  time: number;
  dateCreated: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "VariationProposalTimeChange";
    number?: number | null;
    description: string;
    days: number;
  }>;
};

export type VariationDeterminationLiteFieldsFragment = {
  __typename?: "VariationDetermination";
  id: string;
  variationId: string;
  reference: string;
  details: string;
  price: number;
  currency: string;
  time: number;
  dateCreated: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "VariationProposalTimeChange";
    number?: number | null;
    description: string;
    days: number;
  }>;
};

export type VariationHistoryItemFieldsFragment = {
  __typename?: "VariationHistoryItem";
  id: string;
  variationId: string;
  actionType: VariationActionType;
  actionNumber: string;
  dateCreated: any;
  dateSent: any;
  dueDate?: any | null;
  sentByUserId: string;
  sentByPartyId?: string | null;
  offline: boolean;
  daysLate: number;
  isIncomplete?: boolean | null;
  answers?:
    | { __typename: "VariationHistoryAbandonmentAnswers"; remarks: string }
    | {
        __typename: "VariationHistoryAgreementAnswers";
        draftAgreementId?: string | null;
        agreement?: {
          __typename?: "VariationAgreement";
          id: string;
          variationId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "VariationProposalTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        } | null;
        draftAgreement?: {
          __typename?: "DraftVariationAgreement";
          id: string;
          variationId: string;
          reference: string;
          details: string;
          price: number;
          time: number;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "ContractTimeImpact";
            id: string;
            days: number;
          } | null>;
        } | null;
      }
    | {
        __typename: "VariationHistoryConfirmationVariationAnswers";
        remarks: string;
      }
    | {
        __typename: "VariationHistoryConsentForProposedVariationAnswers";
        remarks: string;
        proposalId: string;
        proposal: {
          __typename?: "VariationProposal";
          id: string;
          variationId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          consented: boolean;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "VariationProposalTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        };
      }
    | {
        __typename: "VariationHistoryDeterminationAnswers";
        draftDeterminationId?: string | null;
        determination?: {
          __typename?: "VariationDetermination";
          id: string;
          variationId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "VariationProposalTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        } | null;
        draftDetermination?: {
          __typename?: "DraftVariationDetermination";
          id: string;
          variationId: string;
          reference: string;
          details: string;
          price: number;
          time: number;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "ContractTimeImpact";
            id: string;
            days: number;
          } | null>;
        } | null;
      }
    | {
        __typename: "VariationHistoryErrorAgreementDisagreementAnswers";
        remarks: string;
      }
    | {
        __typename: "VariationHistoryErrorAgreementNoticeAnswers";
        details: string;
      }
    | {
        __typename: "VariationHistoryErrorDeterminationDisagreementAnswers";
        remarks: string;
      }
    | {
        __typename: "VariationHistoryErrorDeterminationNoticeAnswers";
        details: string;
      }
    | {
        __typename: "VariationHistoryFurtherParticularsRequestAnswers";
        reasons: string;
      }
    | {
        __typename: "VariationHistoryFurtherParticularsSubmissionAnswers";
        details: string;
      }
    | {
        __typename: "VariationHistoryInstructionCommenceWorkAnswers";
        remarks: string;
        agreementSubmitted: boolean;
      }
    | {
        __typename: "VariationHistoryNoAgreementReachedAnswers";
        remarks: string;
      }
    | {
        __typename: "VariationHistoryObjectionProposedVariationAnswers";
        reasons: string;
      }
    | {
        __typename: "VariationHistoryObjectionVariationAnswers";
        reasons: string;
      }
    | {
        __typename: "VariationHistoryProposalForProposedVariationAnswers";
        proposalId: string;
        proposal: {
          __typename?: "VariationProposal";
          id: string;
          variationId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          consented: boolean;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "VariationProposalTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        };
      }
    | {
        __typename: "VariationHistoryProposalForVariationAnswers";
        proposalId: string;
        proposal: {
          __typename?: "VariationProposal";
          id: string;
          variationId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          consented: boolean;
          time: number;
          dateCreated: any;
          creatorId: string;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
          sectionalChanges: Array<{
            __typename?: "VariationProposalTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
        };
      }
    | {
        __typename: "VariationHistoryRevisedProposalRequestAnswers";
        remarks: string;
      }
    | {
        __typename: "VariationHistoryRevocationVariationAnswers";
        remarks: string;
      }
    | null;
  attachments: Array<{
    __typename?: "Attachment";
    id: string;
    fileName: string;
    fileUrl: string;
    mimeType: string;
    description?: string | null;
    locked: boolean;
    fileSize: number;
    status: AttachmentStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  }>;
  recipients: Array<{
    __typename?: "VariationRecipient";
    userId: string;
    name: string;
    preference: {
      __typename?: "UserPreferencesValue";
      inApp: boolean;
      email: boolean;
    };
  }>;
  authorizationWorkflowAudit?: {
    __typename?: "AuthorizationWorkflowAudit";
    id: string;
    initiatedById: string;
    regardingProductItemId: string;
    regardingProductType: ProductType;
    regardingItemHistoryId?: string | null;
    workflowId: string;
    actionId: string;
    status: AuthorizationWorkflowAuditStatus;
    dateCreated: any;
    dateInitiated: any;
    initiatedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    action: {
      __typename?: "AuthorizationWorkflowActionMapping";
      id: string;
      productOutputActionId: string;
      workflowId: string;
      partyId?: string | null;
      productInstanceId: string;
      status: AuthorizationWorkflowActionMappingStatus;
      dateCreated: any;
      creatorId: string;
      modifiedById: string;
      dateModified: any;
      productOutputAction: {
        __typename?: "ProductOutputAction";
        id: string;
        name: string;
        productId: string;
        isInternal: boolean;
        status: ProductOutputActionStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      party?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        contractId: string;
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
        contract: {
          __typename?: "Contract";
          id: string;
          friendlyName: string;
          timeZone: string;
          valueCurrency: string;
          project: { __typename?: "Project"; id: string; friendlyName: string };
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    regardingProductItem:
      | {
          __typename: "ClaimItem";
          id: string;
          number: string;
          regardingId: string;
          claimRegardingType: ClaimRegardingType;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        }
      | {
          __typename: "CompEventItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          regardingType: CompEventRegardingType;
          status: CompEventStatus;
          price?: number | null;
          time?: number | null;
          earlyWarningId?: string | null;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          deemingDate?: any | null;
          lastAction: {
            __typename?: "CompEventItemLastAction";
            actionType: CompEventActionType;
            daysLate?: number | null;
            date: any;
          };
          nextStep?: {
            __typename?: "CompEventItemNextStep";
            actionType: CompEventActionType;
            dueDate: any;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            status: ProductInstanceStatus;
            product:
              | { __typename?: "Claims"; id: string; name: string }
              | { __typename?: "CompEvents"; id: string; name: string }
              | { __typename?: "DailyDiary"; id: string; name: string }
              | { __typename?: "EarlyWarnings"; id: string; name: string }
              | { __typename?: "Events"; id: string; name: string }
              | { __typename?: "Instructions"; id: string; name: string }
              | { __typename?: "RisksRegister"; id: string; name: string }
              | { __typename?: "Variations"; id: string; name: string };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
              timeZone: string;
              valueCurrency: string;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
          };
          earlyWarning?: {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            dateSent: any;
            number: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          notifiedByParty: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
        }
      | {
          __typename?: "DailyDiaryItem";
          id: string;
          date: any;
          number: string;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          sentById?: string | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              timeZone: string;
              number: string;
              status: ContractStatus;
              dateCreated: any;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
          };
        }
      | {
          __typename?: "EarlyWarningItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          creatorId: string;
          dateModified: any;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          owner: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            productSchema: {
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  displayOrder: number;
                  columnPlacement: ColumnPlacementType;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  dateCreated: any;
                  creatorId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      displayOrder: number;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      extraData?: string | null;
                      status: SchemaFieldStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            };
            contract: {
              __typename?: "Contract";
              id: string;
              valueCurrency: string;
              timeZone: string;
            };
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
          };
          attachments?: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }> | null;
        }
      | { __typename?: "EventItem" }
      | {
          __typename?: "InstructionItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          dateModified: any;
          notifyDueDate: any;
          creatorId: string;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
          attachments?: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }> | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            productSchema: {
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  displayOrder: number;
                  columnPlacement: ColumnPlacementType;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  dateCreated: any;
                  creatorId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      displayOrder: number;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      extraData?: string | null;
                      status: SchemaFieldStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            };
            contract: {
              __typename?: "Contract";
              id: string;
              contractTypeId: string;
              valueCurrency: string;
              timeZone: string;
            };
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
          };
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          statusOption: {
            __typename?: "ItemStatusOption";
            id: string;
            displayOrder: number;
            description: string;
            equatesToRemoved: boolean;
            style: ItemStatusOptionStyles;
            statusCollectionId: string;
            isInternal: boolean;
            status: ItemStatusOptionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
          owner: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }
      | { __typename?: "RiskItem" }
      | {
          __typename: "VariationItem";
          id: string;
          number: string;
          regardingId: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        };
    auditTrails?: Array<{
      __typename?: "AuthorizationWorkflowAuditTrail";
      id: string;
      auditId: string;
      regardingProductItemId: string;
      regardingItemHistoryId?: string | null;
      regardingProductType: ProductType;
      productInstanceId: string;
      actionId: string;
      status: AuthorizationActionStatus;
      denialReason?: string | null;
      dateCreated: any;
      creatorId: string;
      productInstance?: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        contractId: string;
        productId: string;
        productSchemaId: string;
        statusCollectionId: string;
        status: ProductInstanceStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contract: {
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          number: string;
          valueCurrency: string;
          value: number;
          startDate?: any | null;
          endDate?: any | null;
          contractTypeId: string;
          projectId: string;
          country: string;
          province?: string | null;
          timeZone: string;
          coordinatesLatitude?: number | null;
          coordinatesLongitude?: number | null;
          status: ContractStatus;
          dateCreated: any;
          creatorId: string;
          sections: Array<{
            __typename?: "ContractSection";
            id: string;
            number?: number | null;
            description: string;
            completionDate: any;
            status: ContractSectionStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          keyDates: Array<{
            __typename?: "ContractKeyDate";
            id: string;
            number?: number | null;
            conditionToBeMet: string;
            keyDate: any;
            status: ContractKeyDateStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            status: ProjectStatus;
          };
          contractType: {
            __typename?: "ContractType";
            id: string;
            description: string;
            subType?: string | null;
            version?: string | null;
            status: ContractTypeStatus;
          };
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
        statusCollection: {
          __typename?: "ItemStatusCollection";
          id: string;
          name: string;
          isDefault: boolean;
          status: ItemStatusCollectionStatus;
        };
      } | null;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }> | null;
  } | null;
  sentByUser: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  sentByParty?: {
    __typename?: "ContractBindingType";
    id: string;
    description: string;
    contractTypeId: string;
    status: ContractBindingTypeStatus;
  } | null;
};

export type VoLastActionFieldsFragment = {
  __typename?: "VariationItemLastAction";
  actionType: VariationActionType;
  date: any;
  daysLate?: number | null;
  party: {
    __typename?: "ContractBindingType";
    id: string;
    description: string;
    contractTypeId: string;
    status: ContractBindingTypeStatus;
  };
};

export type VoNextStepFieldsFragment = {
  __typename?: "VariationItemNextStep";
  actionType: VariationActionType;
  dueDate: any;
  partyId: string;
  party: {
    __typename?: "ContractBindingType";
    id: string;
    description: string;
    contractTypeId: string;
    status: ContractBindingTypeStatus;
  };
};

export type VariationItemFieldsFragment = {
  __typename: "VariationItem";
  id: string;
  number: string;
  productInstanceId: string;
  regardingId: string;
  type: VariationType;
  status: VariationStatus;
  priceSought?: number | null;
  priceGranted?: number | null;
  timeSought?: number | null;
  timeGranted?: number | null;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
  lastAction: {
    __typename?: "VariationItemLastAction";
    actionType: VariationActionType;
    date: any;
    daysLate?: number | null;
    party: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
  nextStep?: {
    __typename?: "VariationItemNextStep";
    actionType: VariationActionType;
    dueDate: any;
    partyId: string;
    party: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  } | null;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    status: ProductInstanceStatus;
    product:
      | { __typename?: "Claims"; id: string; name: string }
      | { __typename?: "CompEvents"; id: string; name: string }
      | { __typename?: "DailyDiary"; id: string; name: string }
      | { __typename?: "EarlyWarnings"; id: string; name: string }
      | { __typename?: "Events"; id: string; name: string }
      | { __typename?: "Instructions"; id: string; name: string }
      | { __typename?: "RisksRegister"; id: string; name: string }
      | { __typename?: "Variations"; id: string; name: string };
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      status: ContractStatus;
      timeZone: string;
      valueCurrency: string;
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        status: ProjectStatus;
      };
    };
  };
  regardingInstruction?: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      product:
        | { __typename?: "Claims"; id: string }
        | { __typename?: "CompEvents"; id: string }
        | { __typename?: "DailyDiary"; id: string }
        | { __typename?: "EarlyWarnings"; id: string }
        | { __typename?: "Events"; id: string }
        | { __typename?: "Instructions"; id: string }
        | { __typename?: "RisksRegister"; id: string }
        | { __typename?: "Variations"; id: string };
    };
  } | null;
};

export type VariationItemLiteFieldsFragment = {
  __typename?: "VariationItem";
  id: string;
  number: string;
  productInstanceId: string;
  type: VariationType;
  regardingId: string;
  status: VariationStatus;
  priceSought?: number | null;
  priceGranted?: number | null;
  timeSought?: number | null;
  timeGranted?: number | null;
  dateCreated: any;
  dateModified: any;
  creatorId: string;
};

export type VariationProposalLiteFieldsFragment = {
  __typename?: "VariationProposal";
  id: string;
  variationId: string;
  reference: string;
  details: string;
  price: number;
  currency: string;
  consented: boolean;
  time: number;
  dateCreated: any;
  creatorId: string;
  sectionalChanges: Array<{
    __typename?: "VariationProposalTimeChange";
    number?: number | null;
    description: string;
    days: number;
  }>;
};

export type VariationRecipientFieldsFragment = {
  __typename?: "VariationRecipient";
  userId: string;
  name: string;
  preference: {
    __typename?: "UserPreferencesValue";
    inApp: boolean;
    inAppReadOnly: boolean;
    email: boolean;
    emailReadOnly: boolean;
  };
};

export type AddAuthorizationWorkflowMutationVariables = Exact<{
  input: AddAuthorizationWorkflowInput;
}>;

export type AddAuthorizationWorkflowMutation = {
  __typename?: "Mutation";
  addAuthorizationWorkflow: {
    __typename?: "AuthorizationWorkflow";
    id: string;
    name: string;
    status: AuthorizationWorkflowStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    modifiedById: string;
    projectId: string;
    levels: Array<{
      __typename?: "AuthorizationWorkflowLevel";
      sequence: number;
      mode: AuthorizationWorkflowLevelMode;
      userIds: Array<string>;
      users: {
        __typename?: "UserList";
        items: Array<{
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          jobTitle: string;
          country: string;
          mobileNumber?: any | null;
          alternateNumber?: any | null;
          dateOfBirth?: any | null;
          profilePicture?: string | null;
          email: any;
          companyId: string;
          registered?: boolean | null;
          status: UserStatus;
          lastActive?: any | null;
          dateInvited: any;
          registeredDate?: any | null;
          company: {
            __typename?: "Company";
            id: string;
            tradingName: string;
            registeredName: string;
          };
          invitedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      friendlyName: string;
      number?: string | null;
      sector: ProjectSector;
      description?: string | null;
      status: ProjectStatus;
      dateCreated: any;
      creatorId: string;
      billingCompanyId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contracts: {
        __typename?: "ContractList";
        items: Array<{
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          status: ContractStatus;
        }>;
      };
      billingCompany: {
        __typename?: "Company";
        id: string;
        type?: CompanyType | null;
        registeredName: string;
        tradingName: string;
        registrationNumber?: string | null;
        logo?: string | null;
        vatRegistrationNumber?: string | null;
        status: CompanyStatus;
        creatorId: string;
        dateCreated: any;
        physicalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        postalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
    };
  };
};

export type AddAuthorizationWorkflowActionMappingMutationVariables = Exact<{
  input: AddAuthorizationActionInput;
}>;

export type AddAuthorizationWorkflowActionMappingMutation = {
  __typename?: "Mutation";
  addAuthorizationWorkflowActionMapping: {
    __typename?: "AuthorizationWorkflowActionMapping";
    id: string;
    productOutputActionId: string;
    workflowId: string;
    partyId?: string | null;
    productInstanceId: string;
    status: AuthorizationWorkflowActionMappingStatus;
    dateCreated: any;
    creatorId: string;
    modifiedById: string;
    dateModified: any;
    productOutputAction: {
      __typename?: "ProductOutputAction";
      id: string;
      name: string;
      productId: string;
      isInternal: boolean;
      status: ProductOutputActionStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      product:
        | {
            __typename?: "Claims";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "CompEvents";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "DailyDiary";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "EarlyWarnings";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Events";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Instructions";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "RisksRegister";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Variations";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          };
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    party?: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    } | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contractId: string;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        friendlyName: string;
        timeZone: string;
        valueCurrency: string;
        project: { __typename?: "Project"; id: string; friendlyName: string };
      };
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddNewClaimTypeOverrideMutationVariables = Exact<{
  input: AddNewClaimTypeOverrideInput;
}>;

export type AddNewClaimTypeOverrideMutation = {
  __typename?: "Mutation";
  addNewClaimTypeOverride: {
    __typename?: "NewClaimTypeOverride";
    id: string;
    contractId: string;
    clause: string;
    description: string;
    status: NewClaimTypeOverrideStatus;
    dateCreated: any;
    creatorId: string;
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      timeZone: string;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddCompanyLookupCollectionMutationVariables = Exact<{
  input: AddCompanyLookupCollectionInput;
}>;

export type AddCompanyLookupCollectionMutation = {
  __typename?: "Mutation";
  addCompanyLookupCollection: {
    __typename?: "CompanyLookupCollection";
    id: string;
    companyId: string;
    name: string;
    optionsLockedForUsers: boolean;
    optionsCharLengthLimit?: number | null;
    usage: CompanyLookupCollectionUsage;
    status: CompanyLookupCollectionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    options: {
      __typename?: "LookupOptionList";
      items: Array<{
        __typename?: "LookupOption";
        id: string;
        value: string;
        displayOrder: number;
        collectionId: string;
        isInternal: boolean;
        status: LookupOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
  };
};

export type AddDailyDiaryDelayRecordMutationVariables = Exact<{
  input: AddDailyDiaryDelayRecordInput;
}>;

export type AddDailyDiaryDelayRecordMutation = {
  __typename?: "Mutation";
  addDailyDiaryDelayRecord: {
    __typename?: "DailyDiaryDelayRecord";
    id: string;
    description: string;
    delayTypeOptionId: string;
    delayTypeLookupId: string;
    areaOptionId?: string | null;
    areaLookupId?: string | null;
    disciplineOptionId?: string | null;
    disciplineLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    duration?: any | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddDailyDiaryEquipmentRecordMutationVariables = Exact<{
  input: AddDailyDiaryResourceRecordInput;
}>;

export type AddDailyDiaryEquipmentRecordMutation = {
  __typename?: "Mutation";
  addDailyDiaryEquipmentRecord: {
    __typename?: "DailyDiaryResourceRecord";
    id: string;
    resourceOptionId: string;
    resourceLookupId: string;
    quantity: number;
    hours: any;
    totalHours: string;
    remarks?: string | null;
    companyOptionId?: string | null;
    companyLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddDailyDiaryGeneralRecordMutationVariables = Exact<{
  input: AddDailyDiaryGeneralRecordInput;
}>;

export type AddDailyDiaryGeneralRecordMutation = {
  __typename?: "Mutation";
  addDailyDiaryGeneralRecord: {
    __typename?: "DailyDiaryGeneralRecord";
    id: string;
    description: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddDailyDiaryHseRecordMutationVariables = Exact<{
  input: AddDailyDiaryHseRecordInput;
}>;

export type AddDailyDiaryHseRecordMutation = {
  __typename?: "Mutation";
  addDailyDiaryHSERecord: {
    __typename?: "DailyDiaryHSERecord";
    id: string;
    description: string;
    hseTypeOptionId: string;
    hseTypeLookupId: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddDailyDiaryManpowerRecordMutationVariables = Exact<{
  input: AddDailyDiaryResourceRecordInput;
}>;

export type AddDailyDiaryManpowerRecordMutation = {
  __typename?: "Mutation";
  addDailyDiaryManpowerRecord: {
    __typename?: "DailyDiaryResourceRecord";
    id: string;
    resourceOptionId: string;
    resourceLookupId: string;
    quantity: number;
    hours: any;
    totalHours: string;
    remarks?: string | null;
    companyOptionId?: string | null;
    companyLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddDailyDiaryWeatherRecordMutationVariables = Exact<{
  input: AddDailyDiaryWeatherRecordInput;
}>;

export type AddDailyDiaryWeatherRecordMutation = {
  __typename?: "Mutation";
  addDailyDiaryWeatherRecord: {
    __typename?: "DailyDiaryWeatherRecord";
    id: string;
    conditionsOptionId: string;
    conditionsLookupId: string;
    temperature?: number | null;
    rainFall?: number | null;
    windSpeed?: number | null;
    humidity?: number | null;
    timeOfMeasurement: any;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddDailyDiaryWorkRecordMutationVariables = Exact<{
  input: AddDailyDiaryWorkRecordInput;
}>;

export type AddDailyDiaryWorkRecordMutation = {
  __typename?: "Mutation";
  addDailyDiaryWorkRecord: {
    __typename?: "DailyDiaryWorkRecord";
    id: string;
    description?: string | null;
    areaOptionId?: string | null;
    areaLookupId?: string | null;
    disciplineOptionId?: string | null;
    disciplineLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    activityOptionId?: string | null;
    activityLookupId?: string | null;
    workTypeOptionId?: string | null;
    workTypeLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    amount?: number | null;
    unitOfMeasurementOptionId?: string | null;
    unitOfMeasurementLookupId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddDraftAssessmentMutationVariables = Exact<{
  input: AddDraftAssessmentInput;
}>;

export type AddDraftAssessmentMutation = {
  __typename?: "Mutation";
  addDraftAssessment: {
    __typename?: "DraftAssessment";
    id: string;
    compEventId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    keyDatesChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type AddDraftEventItemMutationVariables = Exact<{
  input: AddEventItemInput;
}>;

export type AddDraftEventItemMutation = {
  __typename?: "Mutation";
  addDraftEventItem: {
    __typename?: "EventItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      contract: {
        __typename?: "Contract";
        valueCurrency: string;
        timeZone: string;
      };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddDraftInstructionItemMutationVariables = Exact<{
  input: SendInstructionItemInput;
}>;

export type AddDraftInstructionItemMutation = {
  __typename?: "Mutation";
  addDraftInstructionItem: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    notifyDueDate: any;
    creatorId: string;
    offline: boolean;
    givenById?: string | null;
    dateSent: any;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }> | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      contract: {
        __typename?: "Contract";
        id: string;
        contractTypeId: string;
        valueCurrency: string;
        timeZone: string;
      };
      product:
        | { __typename?: "Claims"; id: string; numberingFormat: string }
        | { __typename?: "CompEvents"; id: string; numberingFormat: string }
        | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
        | { __typename?: "EarlyWarnings"; id: string; numberingFormat: string }
        | { __typename?: "Events"; id: string; numberingFormat: string }
        | { __typename?: "Instructions"; id: string; numberingFormat: string }
        | { __typename?: "RisksRegister"; id: string; numberingFormat: string }
        | { __typename?: "Variations"; id: string; numberingFormat: string };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddDraftQuotationMutationVariables = Exact<{
  input: AddDraftQuotationInput;
}>;

export type AddDraftQuotationMutation = {
  __typename?: "Mutation";
  addDraftQuotation: {
    __typename?: "DraftQuotation";
    id: string;
    compEventId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    keyDatesChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type AddEventItemMutationVariables = Exact<{
  input: AddEventItemInput;
  draftId?: InputMaybe<Scalars["ID"]>;
}>;

export type AddEventItemMutation = {
  __typename?: "Mutation";
  addEventItem: {
    __typename?: "EventItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      contract: {
        __typename?: "Contract";
        valueCurrency: string;
        timeZone: string;
      };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddLookupCollectionMutationVariables = Exact<{
  input: AddLookupCollectionInput;
}>;

export type AddLookupCollectionMutation = {
  __typename?: "Mutation";
  addLookupCollection: {
    __typename?: "LookupCollection";
    id: string;
    name: string;
    optionsLockedForUsers: boolean;
    optionsCharLengthLimit?: number | null;
    isInternal: boolean;
    status: LookupCollectionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddLookupOptionMutationVariables = Exact<{
  input: AddLookupOptionInput;
}>;

export type AddLookupOptionMutation = {
  __typename?: "Mutation";
  addLookupOption: {
    __typename?: "LookupOption";
    id: string;
    value: string;
    displayOrder: number;
    collectionId: string;
    isInternal: boolean;
    status: LookupOptionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type AddTimePeriodOverrideMutationVariables = Exact<{
  input: AddTimePeriodOverrideInput;
}>;

export type AddTimePeriodOverrideMutation = {
  __typename?: "Mutation";
  addTimePeriodOverride: {
    __typename?: "TimePeriodOverride";
    id: string;
    contractId: string;
    timePeriodId: string;
    duration: number;
    dateCreated: any;
    creatorId: string;
    timePeriod: {
      __typename?: "TimePeriod";
      id: string;
      contractTypeId: string;
      reference: string;
      description: string;
      duration: number;
      status: TimePeriodStatus;
      isInternal: boolean;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      timeZone: string;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type ChangeAuthorizationWorkflowStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: AuthorizationWorkflowStatus;
}>;

export type ChangeAuthorizationWorkflowStatusMutation = {
  __typename?: "Mutation";
  changeAuthorizationWorkflowStatus: {
    __typename?: "AuthorizationWorkflow";
    id: string;
    name: string;
    status: AuthorizationWorkflowStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    modifiedById: string;
    projectId: string;
    levels: Array<{
      __typename?: "AuthorizationWorkflowLevel";
      sequence: number;
      mode: AuthorizationWorkflowLevelMode;
      userIds: Array<string>;
      users: {
        __typename?: "UserList";
        items: Array<{
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          jobTitle: string;
          country: string;
          mobileNumber?: any | null;
          alternateNumber?: any | null;
          dateOfBirth?: any | null;
          profilePicture?: string | null;
          email: any;
          companyId: string;
          registered?: boolean | null;
          status: UserStatus;
          lastActive?: any | null;
          dateInvited: any;
          registeredDate?: any | null;
          company: {
            __typename?: "Company";
            id: string;
            tradingName: string;
            registeredName: string;
          };
          invitedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      friendlyName: string;
      number?: string | null;
      sector: ProjectSector;
      description?: string | null;
      status: ProjectStatus;
      dateCreated: any;
      creatorId: string;
      billingCompanyId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contracts: {
        __typename?: "ContractList";
        items: Array<{
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          status: ContractStatus;
        }>;
      };
      billingCompany: {
        __typename?: "Company";
        id: string;
        type?: CompanyType | null;
        registeredName: string;
        tradingName: string;
        registrationNumber?: string | null;
        logo?: string | null;
        vatRegistrationNumber?: string | null;
        status: CompanyStatus;
        creatorId: string;
        dateCreated: any;
        physicalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        postalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
    };
  };
};

export type ChangeCompanyLookupCollectionStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: CompanyLookupCollectionStatus;
}>;

export type ChangeCompanyLookupCollectionStatusMutation = {
  __typename?: "Mutation";
  changeCompanyLookupCollectionStatus: {
    __typename?: "CompanyLookupCollection";
    id: string;
    companyId: string;
    name: string;
    optionsLockedForUsers: boolean;
    optionsCharLengthLimit?: number | null;
    usage: CompanyLookupCollectionUsage;
    status: CompanyLookupCollectionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    options: {
      __typename?: "LookupOptionList";
      items: Array<{
        __typename?: "LookupOption";
        id: string;
        value: string;
        displayOrder: number;
        collectionId: string;
        isInternal: boolean;
        status: LookupOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
  };
};

export type ChangeContractBindingStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ContractBindingStatus;
}>;

export type ChangeContractBindingStatusMutation = {
  __typename?: "Mutation";
  changeContractBindingStatus: {
    __typename?: "ContractBinding";
    id: string;
    contractBindingTypeId: string;
    status: ContractBindingStatus;
    defaultCompany: boolean;
    representativeId?: string | null;
    isCompanyBilled: boolean;
    dateCreated: any;
    companyId: string;
    contractId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type ChangeContractStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ContractStatus;
}>;

export type ChangeContractStatusMutation = {
  __typename?: "Mutation";
  changeContractStatus: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
    number: string;
    valueCurrency: string;
    value: number;
    startDate?: any | null;
    endDate?: any | null;
    contractTypeId: string;
    country: string;
    province?: string | null;
    timeZone: string;
    coordinatesLatitude?: number | null;
    coordinatesLongitude?: number | null;
    status: ContractStatus;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type ChangeDocumentTemplateStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: DocumentTemplateStatus;
}>;

export type ChangeDocumentTemplateStatusMutation = {
  __typename?: "Mutation";
  changeDocumentTemplateStatus: {
    __typename?: "DocumentTemplate";
    id: string;
    name: string;
    status: DocumentTemplateStatus;
  };
};

export type ChangeEarlyWarningItemOwnerMutationVariables = Exact<{
  id: Scalars["ID"];
  ownerId: Scalars["ID"];
}>;

export type ChangeEarlyWarningItemOwnerMutation = {
  __typename?: "Mutation";
  changeEarlyWarningItemOwner: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
    dateModified: any;
    ownerId: string;
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      jobTitle: string;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  };
};

export type StatusOptionLightFieldsFragment = {
  __typename?: "ItemStatusOption";
  id: string;
  displayOrder: number;
  description: string;
  equatesToRemoved: boolean;
  style: ItemStatusOptionStyles;
  statusCollectionId: string;
  isInternal: boolean;
  status: ItemStatusOptionStatus;
};

export type ChangeEarlyWarningItemStatusMutationVariables = Exact<{
  input: ChangeEarlyWarningItemStatusInput;
}>;

export type ChangeEarlyWarningItemStatusMutation = {
  __typename?: "Mutation";
  changeEarlyWarningItemStatus: {
    __typename?: "EarlyWarningItem";
    id: string;
    title: string;
    dateModified: any;
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
  };
};

export type ChangeEventItemOwnerMutationVariables = Exact<{
  id: Scalars["ID"];
  ownerId: Scalars["ID"];
}>;

export type ChangeEventItemOwnerMutation = {
  __typename?: "Mutation";
  changeEventItemOwner: {
    __typename?: "EventItem";
    id: string;
    title: string;
    dateModified: any;
    ownerId: string;
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      jobTitle: string;
      id: string;
      firstname: string;
      surname: string;
      email: any;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  };
};

export type ChangeEventItemStatusMutationVariables = Exact<{
  input: ChangeEventItemStatusInput;
}>;

export type ChangeEventItemStatusMutation = {
  __typename?: "Mutation";
  changeEventItemStatus: {
    __typename?: "EventItem";
    id: string;
    title: string;
    dateModified: any;
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
  };
};

export type ChangeInstructionItemStatusMutationVariables = Exact<{
  input: ChangeInstructionItemStatusInput;
}>;

export type ChangeInstructionItemStatusMutation = {
  __typename?: "Mutation";
  changeInstructionItemStatus: {
    __typename?: "InstructionItem";
    id: string;
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      description: string;
    };
  };
};

export type ChangeInstructionItemOwnerMutationVariables = Exact<{
  id: Scalars["ID"];
  ownerId: Scalars["ID"];
}>;

export type ChangeInstructionItemOwnerMutation = {
  __typename?: "Mutation";
  changeInstructionItemOwner: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    dateModified: any;
    ownerId: string;
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      jobTitle: string;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  };
};

export type ChangeLookupCollectionStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: LookupCollectionStatus;
}>;

export type ChangeLookupCollectionStatusMutation = {
  __typename?: "Mutation";
  changeLookupCollectionStatus: {
    __typename?: "LookupCollection";
    id: string;
    status: LookupCollectionStatus;
  };
};

export type ChangeLookupOptionStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: LookupOptionStatus;
}>;

export type ChangeLookupOptionStatusMutation = {
  __typename?: "Mutation";
  changeLookupOptionStatus: {
    __typename?: "LookupOption";
    id: string;
    status: LookupOptionStatus;
  };
};

export type ChangeNewClaimTypeOverrideStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: NewClaimTypeOverrideStatus;
}>;

export type ChangeNewClaimTypeOverrideStatusMutation = {
  __typename?: "Mutation";
  changeNewClaimTypeOverrideStatus: {
    __typename?: "NewClaimTypeOverride";
    id: string;
    contractId: string;
    clause: string;
    description: string;
    status: NewClaimTypeOverrideStatus;
    dateCreated: any;
    creatorId: string;
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      timeZone: string;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type ChangeProductSchemaStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ProductSchemaStatus;
}>;

export type ChangeProductSchemaStatusMutation = {
  __typename?: "Mutation";
  changeProductSchemaStatus: {
    __typename?: "ProductSchema";
    id: string;
    name: string;
    isDefault: boolean;
    productId: string;
    status: ProductSchemaStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type ChangeRiskItemOwnerMutationVariables = Exact<{
  id: Scalars["ID"];
  ownerId: Scalars["ID"];
}>;

export type ChangeRiskItemOwnerMutation = {
  __typename?: "Mutation";
  changeRiskItemOwner: {
    __typename?: "RiskItem";
    id: string;
    title: string;
    dateModified: any;
    ownerId: string;
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      jobTitle: string;
      company: {
        __typename?: "Company";
        id: string;
        registeredName: string;
        tradingName: string;
      };
    };
  };
};

export type ChangeRiskItemStatusMutationVariables = Exact<{
  input: ChangeRiskItemStatusInput;
}>;

export type ChangeRiskItemStatusMutation = {
  __typename?: "Mutation";
  changeRiskItemStatus: {
    __typename?: "RiskItem";
    id: string;
    title: string;
    dateModified: any;
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
    };
  };
};

export type ChangeItemStatusOptionStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ItemStatusOptionStatus;
}>;

export type ChangeItemStatusOptionStatusMutation = {
  __typename?: "Mutation";
  changeItemStatusOptionStatus: {
    __typename?: "ItemStatusOption";
    id: string;
    status: ItemStatusOptionStatus;
  };
};

export type ChangeItemStatusCollectionStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ItemStatusCollectionStatus;
}>;

export type ChangeItemStatusCollectionStatusMutation = {
  __typename?: "Mutation";
  changeItemStatusCollectionStatus: {
    __typename?: "ItemStatusCollection";
    id: string;
    name: string;
    isDefault: boolean;
    productId: string;
    status: ItemStatusCollectionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type CreateUploadAttachmentPreSignedUrlMutationVariables = Exact<{
  input: CreateUploadAttachmentPreSignedUrlInput;
}>;

export type CreateUploadAttachmentPreSignedUrlMutation = {
  __typename?: "Mutation";
  createUploadAttachmentPreSignedUrl: {
    __typename?: "AttachmentPreSignedUrl";
    fileName: string;
    fileUrl: string;
  };
};

export type DisableClaimTypeMutationVariables = Exact<{
  claimTypeId: Scalars["ID"];
  contractId: Scalars["ID"];
}>;

export type DisableClaimTypeMutation = {
  __typename?: "Mutation";
  disableClaimType: {
    __typename?: "DisabledClaimTypeOverride";
    id: string;
    contractId: string;
    claimTypeId: string;
    dateCreated: any;
    creatorId: string;
    claimType: {
      __typename?: "ClaimType";
      id: string;
      contractTypeId: string;
      clause: string;
      description: string;
      isInternal: boolean;
      status: ClaimTypeStatus;
      dateCreated: any;
      creatorId: string;
      contractType: {
        __typename?: "ContractType";
        id: string;
        description: string;
        subType?: string | null;
        version?: string | null;
        status: ContractTypeStatus;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      timeZone: string;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditAuthorizationWorkflowMutationVariables = Exact<{
  input: EditAuthorizationWorkflowInput;
}>;

export type EditAuthorizationWorkflowMutation = {
  __typename?: "Mutation";
  editAuthorizationWorkflow: {
    __typename?: "AuthorizationWorkflow";
    id: string;
    name: string;
    status: AuthorizationWorkflowStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    modifiedById: string;
    projectId: string;
    levels: Array<{
      __typename?: "AuthorizationWorkflowLevel";
      sequence: number;
      mode: AuthorizationWorkflowLevelMode;
      userIds: Array<string>;
      users: {
        __typename?: "UserList";
        items: Array<{
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          jobTitle: string;
          country: string;
          mobileNumber?: any | null;
          alternateNumber?: any | null;
          dateOfBirth?: any | null;
          profilePicture?: string | null;
          email: any;
          companyId: string;
          registered?: boolean | null;
          status: UserStatus;
          lastActive?: any | null;
          dateInvited: any;
          registeredDate?: any | null;
          company: {
            __typename?: "Company";
            id: string;
            tradingName: string;
            registeredName: string;
          };
          invitedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      friendlyName: string;
      number?: string | null;
      sector: ProjectSector;
      description?: string | null;
      status: ProjectStatus;
      dateCreated: any;
      creatorId: string;
      billingCompanyId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contracts: {
        __typename?: "ContractList";
        items: Array<{
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          status: ContractStatus;
        }>;
      };
      billingCompany: {
        __typename?: "Company";
        id: string;
        type?: CompanyType | null;
        registeredName: string;
        tradingName: string;
        registrationNumber?: string | null;
        logo?: string | null;
        vatRegistrationNumber?: string | null;
        status: CompanyStatus;
        creatorId: string;
        dateCreated: any;
        physicalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        postalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
    };
  };
};

export type EditAuthorizationWorkflowActionMappingMutationVariables = Exact<{
  input: EditAuthorizationActionInput;
}>;

export type EditAuthorizationWorkflowActionMappingMutation = {
  __typename?: "Mutation";
  editAuthorizationWorkflowActionMapping: {
    __typename?: "AuthorizationWorkflowActionMapping";
    id: string;
    productOutputActionId: string;
    workflowId: string;
    partyId?: string | null;
    productInstanceId: string;
    status: AuthorizationWorkflowActionMappingStatus;
    dateCreated: any;
    creatorId: string;
    modifiedById: string;
    dateModified: any;
    productOutputAction: {
      __typename?: "ProductOutputAction";
      id: string;
      name: string;
      productId: string;
      isInternal: boolean;
      status: ProductOutputActionStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      product:
        | {
            __typename?: "Claims";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "CompEvents";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "DailyDiary";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "EarlyWarnings";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Events";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Instructions";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "RisksRegister";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Variations";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          };
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    party?: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    } | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contractId: string;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        friendlyName: string;
        timeZone: string;
        valueCurrency: string;
        project: { __typename?: "Project"; id: string; friendlyName: string };
      };
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditCompanyLookupCollectionMutationVariables = Exact<{
  input: EditCompanyLookupCollectionInput;
}>;

export type EditCompanyLookupCollectionMutation = {
  __typename?: "Mutation";
  editCompanyLookupCollection: {
    __typename?: "CompanyLookupCollection";
    id: string;
    companyId: string;
    name: string;
    optionsLockedForUsers: boolean;
    optionsCharLengthLimit?: number | null;
    usage: CompanyLookupCollectionUsage;
    status: CompanyLookupCollectionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    options: {
      __typename?: "LookupOptionList";
      items: Array<{
        __typename?: "LookupOption";
        id: string;
        value: string;
        displayOrder: number;
        collectionId: string;
        isInternal: boolean;
        status: LookupOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
  };
};

export type EditDailyDiaryDelayRecordMutationVariables = Exact<{
  input: EditDailyDiaryDelayRecordInput;
}>;

export type EditDailyDiaryDelayRecordMutation = {
  __typename?: "Mutation";
  editDailyDiaryDelayRecord: {
    __typename?: "DailyDiaryDelayRecord";
    id: string;
    description: string;
    delayTypeOptionId: string;
    delayTypeLookupId: string;
    areaOptionId?: string | null;
    areaLookupId?: string | null;
    disciplineOptionId?: string | null;
    disciplineLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    duration?: any | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditDailyDiaryEquipmentRecordMutationVariables = Exact<{
  input: EditDailyDiaryResourceRecordInput;
}>;

export type EditDailyDiaryEquipmentRecordMutation = {
  __typename?: "Mutation";
  editDailyDiaryEquipmentRecord: {
    __typename?: "DailyDiaryResourceRecord";
    id: string;
    resourceOptionId: string;
    resourceLookupId: string;
    quantity: number;
    hours: any;
    totalHours: string;
    remarks?: string | null;
    companyOptionId?: string | null;
    companyLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditDailyDiaryGeneralRecordMutationVariables = Exact<{
  input: EditDailyDiaryGeneralRecordInput;
}>;

export type EditDailyDiaryGeneralRecordMutation = {
  __typename?: "Mutation";
  editDailyDiaryGeneralRecord: {
    __typename?: "DailyDiaryGeneralRecord";
    id: string;
    description: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditDailyDiaryHseRecordMutationVariables = Exact<{
  input: EditDailyDiaryHseRecordInput;
}>;

export type EditDailyDiaryHseRecordMutation = {
  __typename?: "Mutation";
  editDailyDiaryHSERecord: {
    __typename?: "DailyDiaryHSERecord";
    id: string;
    description: string;
    hseTypeOptionId: string;
    hseTypeLookupId: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditDailyDiaryManpowerRecordMutationVariables = Exact<{
  input: EditDailyDiaryResourceRecordInput;
}>;

export type EditDailyDiaryManpowerRecordMutation = {
  __typename?: "Mutation";
  editDailyDiaryManpowerRecord: {
    __typename?: "DailyDiaryResourceRecord";
    id: string;
    resourceOptionId: string;
    resourceLookupId: string;
    quantity: number;
    hours: any;
    totalHours: string;
    remarks?: string | null;
    companyOptionId?: string | null;
    companyLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditDailyDiaryWeatherRecordMutationVariables = Exact<{
  input: EditDailyDiaryWeatherRecordInput;
}>;

export type EditDailyDiaryWeatherRecordMutation = {
  __typename?: "Mutation";
  editDailyDiaryWeatherRecord: {
    __typename?: "DailyDiaryWeatherRecord";
    id: string;
    conditionsOptionId: string;
    conditionsLookupId: string;
    temperature?: number | null;
    rainFall?: number | null;
    windSpeed?: number | null;
    humidity?: number | null;
    timeOfMeasurement: any;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditDailyDiaryWorkRecordMutationVariables = Exact<{
  input: EditDailyDiaryWorkRecordInput;
}>;

export type EditDailyDiaryWorkRecordMutation = {
  __typename?: "Mutation";
  editDailyDiaryWorkRecord: {
    __typename?: "DailyDiaryWorkRecord";
    id: string;
    description?: string | null;
    areaOptionId?: string | null;
    areaLookupId?: string | null;
    disciplineOptionId?: string | null;
    disciplineLookupId?: string | null;
    teamOptionId?: string | null;
    teamLookupId?: string | null;
    activityOptionId?: string | null;
    activityLookupId?: string | null;
    workTypeOptionId?: string | null;
    workTypeLookupId?: string | null;
    shiftOptionId?: string | null;
    shiftLookupId?: string | null;
    amount?: number | null;
    unitOfMeasurementOptionId?: string | null;
    unitOfMeasurementLookupId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditDraftAssessmentMutationVariables = Exact<{
  input: EditDraftAssessmentInput;
}>;

export type EditDraftAssessmentMutation = {
  __typename?: "Mutation";
  editDraftAssessment: {
    __typename?: "DraftAssessment";
    id: string;
    compEventId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    keyDatesChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type EditDraftEventItemMutationVariables = Exact<{
  input: EditEventItemInput;
}>;

export type EditDraftEventItemMutation = {
  __typename?: "Mutation";
  editDraftEventItem: {
    __typename?: "EventItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      contract: {
        __typename?: "Contract";
        valueCurrency: string;
        timeZone: string;
      };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditDraftInstructionItemMutationVariables = Exact<{
  input: EditDraftInstructionItemInput;
}>;

export type EditDraftInstructionItemMutation = {
  __typename?: "Mutation";
  editDraftInstructionItem: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    notifyDueDate: any;
    creatorId: string;
    offline: boolean;
    givenById?: string | null;
    dateSent: any;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }> | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      contract: {
        __typename?: "Contract";
        id: string;
        contractTypeId: string;
        valueCurrency: string;
        timeZone: string;
      };
      product:
        | { __typename?: "Claims"; id: string; numberingFormat: string }
        | { __typename?: "CompEvents"; id: string; numberingFormat: string }
        | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
        | { __typename?: "EarlyWarnings"; id: string; numberingFormat: string }
        | { __typename?: "Events"; id: string; numberingFormat: string }
        | { __typename?: "Instructions"; id: string; numberingFormat: string }
        | { __typename?: "RisksRegister"; id: string; numberingFormat: string }
        | { __typename?: "Variations"; id: string; numberingFormat: string };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditDraftQuotationMutationVariables = Exact<{
  input: EditDraftQuotationInput;
}>;

export type EditDraftQuotationMutation = {
  __typename?: "Mutation";
  editDraftQuotation: {
    __typename?: "DraftQuotation";
    id: string;
    compEventId: string;
    reference: string;
    details: string;
    price: number;
    time: number;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    keyDatesChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    sectionalChanges: Array<{
      __typename?: "ContractTimeImpact";
      id: string;
      days: number;
    } | null>;
    attachments: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }>;
  };
};

export type EditEarlyWarningItemMutationVariables = Exact<{
  input: EditEarlyWarningItemInput;
}>;

export type EditEarlyWarningItemMutation = {
  __typename?: "Mutation";
  editEarlyWarningItem: { __typename?: "EarlyWarningItem"; id: string };
};

export type EditEventItemMutationVariables = Exact<{
  input: EditEventItemInput;
}>;

export type EditEventItemMutation = {
  __typename?: "Mutation";
  editEventItem: {
    __typename?: "EventItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      contract: {
        __typename?: "Contract";
        valueCurrency: string;
        timeZone: string;
      };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditInstructionItemMutationVariables = Exact<{
  input: EditInstructionItemInput;
}>;

export type EditInstructionItemMutation = {
  __typename?: "Mutation";
  editInstructionItem: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    notifyDueDate: any;
    creatorId: string;
    offline: boolean;
    givenById?: string | null;
    dateSent: any;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }> | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      contract: {
        __typename?: "Contract";
        id: string;
        contractTypeId: string;
        valueCurrency: string;
        timeZone: string;
      };
      product:
        | { __typename?: "Claims"; id: string; numberingFormat: string }
        | { __typename?: "CompEvents"; id: string; numberingFormat: string }
        | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
        | { __typename?: "EarlyWarnings"; id: string; numberingFormat: string }
        | { __typename?: "Events"; id: string; numberingFormat: string }
        | { __typename?: "Instructions"; id: string; numberingFormat: string }
        | { __typename?: "RisksRegister"; id: string; numberingFormat: string }
        | { __typename?: "Variations"; id: string; numberingFormat: string };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditLookupCollectionMutationVariables = Exact<{
  input: EditLookupCollectionInput;
}>;

export type EditLookupCollectionMutation = {
  __typename?: "Mutation";
  editLookupCollection: {
    __typename?: "LookupCollection";
    id: string;
    name: string;
    optionsLockedForUsers: boolean;
    optionsCharLengthLimit?: number | null;
    isInternal: boolean;
    status: LookupCollectionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditLookupOptionMutationVariables = Exact<{
  input: EditLookupOptionInput;
}>;

export type EditLookupOptionMutation = {
  __typename?: "Mutation";
  editLookupOption: {
    __typename?: "LookupOption";
    id: string;
    value: string;
    displayOrder: number;
    collectionId: string;
    isInternal: boolean;
    status: LookupOptionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EditProductSchemaMutationVariables = Exact<{
  input: EditProductSchemaInput;
}>;

export type EditProductSchemaMutation = {
  __typename?: "Mutation";
  editProductSchema: {
    __typename?: "ProductSchema";
    id: string;
    name: string;
    isDefault: boolean;
    productId: string;
    status: ProductSchemaStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
  };
};

export type EditRiskItemMutationVariables = Exact<{
  input: EditRiskItemInput;
}>;

export type EditRiskItemMutation = {
  __typename?: "Mutation";
  editRiskItem: {
    __typename?: "RiskItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    creatorId: string;
    dateModified: any;
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      creatorId: string;
      dateCreated: any;
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
      };
      optionReasons: {
        __typename?: "ItemStatusOptionReasonList";
        items: Array<{
          __typename?: "ItemStatusOptionReason";
          id: string;
          displayOrder: number;
          description: string;
          statusOptionId: string;
          isInternal: boolean;
          status: ItemStatusOptionReasonStatus;
          creatorId: string;
          dateCreated: any;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
          };
        }>;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        timeZone: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
        };
      };
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      statusCollection: {
        __typename?: "ItemStatusCollection";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ItemStatusCollectionStatus;
        creatorId: string;
        statusOptions: {
          __typename?: "ItemStatusOptionList";
          items: Array<{
            __typename?: "ItemStatusOption";
            id: string;
            displayOrder: number;
            description: string;
            equatesToRemoved: boolean;
            style: ItemStatusOptionStyles;
            statusCollectionId: string;
            isInternal: boolean;
            status: ItemStatusOptionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
            };
            optionReasons: {
              __typename?: "ItemStatusOptionReasonList";
              items: Array<{
                __typename?: "ItemStatusOptionReason";
                id: string;
                displayOrder: number;
                description: string;
                statusOptionId: string;
                isInternal: boolean;
                status: ItemStatusOptionReasonStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                };
              }>;
            };
          }>;
        };
      };
    };
  };
};

export type EditUserMutationMutationVariables = Exact<{
  input: EditUserInput;
}>;

export type EditUserMutationMutation = {
  __typename?: "Mutation";
  editUser: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    jobTitle: string;
    country: string;
    mobileNumber?: any | null;
    alternateNumber?: any | null;
    dateOfBirth?: any | null;
    profilePicture?: string | null;
    email: any;
    companyId: string;
    registered?: boolean | null;
    status: UserStatus;
    lastActive?: any | null;
    dateInvited: any;
    registeredDate?: any | null;
    invitedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type EnableClaimTypeMutationVariables = Exact<{
  disabledClaimTypeId: Scalars["ID"];
}>;

export type EnableClaimTypeMutation = {
  __typename?: "Mutation";
  enableClaimType: boolean;
};

export type GenerateInstructionPreviewMutationVariables = Exact<{
  input: SendInstructionItemInput;
}>;

export type GenerateInstructionPreviewMutation = {
  __typename?: "Mutation";
  generateInstructionPreview: {
    __typename?: "InstructionItemPreview";
    id: string;
    fileUrl: string;
    actionMapping?: {
      __typename?: "AuthorizationWorkflowActionMapping";
      id: string;
      productOutputActionId: string;
      workflowId: string;
      partyId?: string | null;
      productInstanceId: string;
      status: AuthorizationWorkflowActionMappingStatus;
      dateCreated: any;
      creatorId: string;
      modifiedById: string;
      dateModified: any;
      productOutputAction: {
        __typename?: "ProductOutputAction";
        id: string;
        name: string;
        productId: string;
        isInternal: boolean;
        status: ProductOutputActionStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      party?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        contractId: string;
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
        contract: {
          __typename?: "Contract";
          id: string;
          friendlyName: string;
          timeZone: string;
          valueCurrency: string;
          project: { __typename?: "Project"; id: string; friendlyName: string };
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    } | null;
    recipients: Array<{
      __typename?: "InstructionRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventAbandonInstructionPreviewMutationVariables =
  Exact<{
    input: RecordCompEventAbandonInstructionPreviewInput;
  }>;

export type GenerateRecordCompEventAbandonInstructionPreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventAbandonInstructionPreview: {
    __typename?: "RecordCompEventAbandonInstructionPreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventConfirmationPreviewMutationVariables =
  Exact<{
    input: RecordCompEventConfirmationPreviewInput;
  }>;

export type GenerateRecordCompEventConfirmationPreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventConfirmationPreview: {
    __typename?: "RecordCompEventConfirmationPreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventDenyingOfMoreTimePreviewMutationVariables =
  Exact<{
    input: RecordCompEventDenyingOfMoreTimePreviewInput;
  }>;

export type GenerateRecordCompEventDenyingOfMoreTimePreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventDenyingOfMoreTimePreview: {
    __typename?: "RecordCompEventDenyingOfMoreTimePreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventGrantingOfMoreTimePreviewMutationVariables =
  Exact<{
    input: RecordCompEventGrantingOfMoreTimePreviewInput;
  }>;

export type GenerateRecordCompEventGrantingOfMoreTimePreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventGrantingOfMoreTimePreview: {
    __typename?: "RecordCompEventGrantingOfMoreTimePreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventNoticeOfSilenceCompEventNoticePreviewMutationVariables =
  Exact<{
    input: RecordCompEventNoticeOfSilenceCompEventNoticePreviewInput;
  }>;

export type GenerateRecordCompEventNoticeOfSilenceCompEventNoticePreviewMutation =
  {
    __typename?: "Mutation";
    generateRecordCompEventNoticeOfSilenceCompEventNoticePreview: {
      __typename?: "RecordCompEventNoticeOfSilenceCompEventNoticePreview";
      recipients: Array<{
        __typename?: "CompEventRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type GenerateRecordCompEventNoticeOfSilenceOwnAssessmentPreviewMutationVariables =
  Exact<{
    input: RecordCompEventNoticeOfSilenceOwnAssessmentPreviewInput;
  }>;

export type GenerateRecordCompEventNoticeOfSilenceOwnAssessmentPreviewMutation =
  {
    __typename?: "Mutation";
    generateRecordCompEventNoticeOfSilenceOwnAssessmentPreview: {
      __typename?: "RecordCompEventNoticeOfSilenceOwnAssessmentPreview";
      recipients: Array<{
        __typename?: "CompEventRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type GenerateRecordCompEventNoticeOfSilenceQuotationPreviewMutationVariables =
  Exact<{
    input: RecordCompEventNoticeOfSilenceQuotationPreviewInput;
  }>;

export type GenerateRecordCompEventNoticeOfSilenceQuotationPreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventNoticeOfSilenceQuotationPreview: {
    __typename?: "RecordCompEventNoticeOfSilenceQuotationPreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventNoticePreviewMutationVariables = Exact<{
  input: RecordCompEventNoticePreviewInput;
}>;

export type GenerateRecordCompEventNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventNoticePreview: {
    __typename?: "RecordCompEventNoticePreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventNoticeRequestQuotationPreviewMutationVariables =
  Exact<{
    input: RecordCompEventNoticeRequestQuotationPreviewInput;
  }>;

export type GenerateRecordCompEventNoticeRequestQuotationPreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventNoticeRequestQuotationPreview: {
    __typename?: "RecordCompEventNoticeRequestQuotationPreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventOwnAssessmentNoticePreviewMutationVariables =
  Exact<{
    input: RecordCompEventOwnAssessmentNoticePreviewInput;
  }>;

export type GenerateRecordCompEventOwnAssessmentNoticePreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventOwnAssessmentNoticePreview: {
    __typename?: "RecordCompEventOwnAssessmentNoticePreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventOwnAssessmentPreviewMutationVariables =
  Exact<{
    input: RecordCompEventOwnAssessmentPreviewInput;
  }>;

export type GenerateRecordCompEventOwnAssessmentPreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventOwnAssessmentPreview: {
    __typename?: "RecordCompEventOwnAssessmentPreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventQuotationAcceptancePreviewMutationVariables =
  Exact<{
    input: RecordCompEventQuotationAcceptancePreviewInput;
  }>;

export type GenerateRecordCompEventQuotationAcceptancePreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventQuotationAcceptancePreview: {
    __typename?: "RecordCompEventQuotationAcceptancePreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventQuotationPreviewMutationVariables = Exact<{
  input: RecordCompEventQuotationPreviewInput;
}>;

export type GenerateRecordCompEventQuotationPreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventQuotationPreview: {
    __typename?: "RecordCompEventQuotationPreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventQuotationRevisionRequestPreviewMutationVariables =
  Exact<{
    input: RecordCompEventQuotationRevisionRequestPreviewInput;
  }>;

export type GenerateRecordCompEventQuotationRevisionRequestPreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventQuotationRevisionRequestPreview: {
    __typename?: "RecordCompEventQuotationRevisionRequestPreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventRejectionPreviewMutationVariables = Exact<{
  input: RecordCompEventRejectionPreviewInput;
}>;

export type GenerateRecordCompEventRejectionPreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventRejectionPreview: {
    __typename?: "RecordCompEventRejectionPreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateRecordCompEventRequestForMoreTimePreviewMutationVariables =
  Exact<{
    input: RecordCompEventRequestForMoreTimePreviewInput;
  }>;

export type GenerateRecordCompEventRequestForMoreTimePreviewMutation = {
  __typename?: "Mutation";
  generateRecordCompEventRequestForMoreTimePreview: {
    __typename?: "RecordCompEventRequestForMoreTimePreview";
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventAbandonInstructionPreviewMutationVariables =
  Exact<{
    input: SendCompEventAbandonInstructionPreviewInput;
  }>;

export type GenerateSendCompEventAbandonInstructionPreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventAbandonInstructionPreview: {
    __typename?: "SendCompEventAbandonInstructionPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventConfirmationPreviewMutationVariables = Exact<{
  input: SendCompEventConfirmationPreviewInput;
}>;

export type GenerateSendCompEventConfirmationPreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventConfirmationPreview: {
    __typename?: "SendCompEventConfirmationPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventDenyingOfMoreTimePreviewMutationVariables =
  Exact<{
    input: SendCompEventDenyingOfMoreTimePreviewInput;
  }>;

export type GenerateSendCompEventDenyingOfMoreTimePreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventDenyingOfMoreTimePreview: {
    __typename?: "SendCompEventDenyingOfMoreTimePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventGrantingOfMoreTimePreviewMutationVariables =
  Exact<{
    input: SendCompEventGrantingOfMoreTimePreviewInput;
  }>;

export type GenerateSendCompEventGrantingOfMoreTimePreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventGrantingOfMoreTimePreview: {
    __typename?: "SendCompEventGrantingOfMoreTimePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventNoticeOfSilenceCompEventNoticePreviewMutationVariables =
  Exact<{
    input: SendCompEventNoticeOfSilenceCompEventNoticePreviewInput;
  }>;

export type GenerateSendCompEventNoticeOfSilenceCompEventNoticePreviewMutation =
  {
    __typename?: "Mutation";
    generateSendCompEventNoticeOfSilenceCompEventNoticePreview: {
      __typename?: "SendCompEventNoticeOfSilenceCompEventNoticePreview";
      id: string;
      fileUrl: string;
      recipients: Array<{
        __typename?: "CompEventRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          inAppReadOnly: boolean;
          email: boolean;
          emailReadOnly: boolean;
        };
      }>;
    };
  };

export type GenerateSendCompEventNoticeOfSilenceOwnAssessmentPreviewMutationVariables =
  Exact<{
    input: SendCompEventNoticeOfSilenceOwnAssessmentPreviewInput;
  }>;

export type GenerateSendCompEventNoticeOfSilenceOwnAssessmentPreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventNoticeOfSilenceOwnAssessmentPreview: {
    __typename?: "SendCompEventNoticeOfSilenceOwnAssessmentPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventNoticeOfSilenceQuotationPreviewMutationVariables =
  Exact<{
    input: SendCompEventNoticeOfSilenceQuotationPreviewInput;
  }>;

export type GenerateSendCompEventNoticeOfSilenceQuotationPreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventNoticeOfSilenceQuotationPreview: {
    __typename?: "SendCompEventNoticeOfSilenceQuotationPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventNoticePreviewMutationVariables = Exact<{
  input: SendCompEventNoticePreviewInput;
}>;

export type GenerateSendCompEventNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventNoticePreview: {
    __typename?: "SendCompEventNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventNoticeRequestQuotationPreviewMutationVariables =
  Exact<{
    input: SendCompEventNoticeRequestQuotationPreviewInput;
  }>;

export type GenerateSendCompEventNoticeRequestQuotationPreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventNoticeRequestQuotationPreview: {
    __typename?: "SendCompEventNoticeRequestQuotationPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventOwnAssessmentNoticePreviewMutationVariables =
  Exact<{
    input: SendCompEventOwnAssessmentNoticePreviewInput;
  }>;

export type GenerateSendCompEventOwnAssessmentNoticePreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventOwnAssessmentNoticePreview: {
    __typename?: "SendCompEventOwnAssessmentNoticePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventOwnAssessmentPreviewMutationVariables = Exact<{
  input: SendCompEventOwnAssessmentPreviewInput;
}>;

export type GenerateSendCompEventOwnAssessmentPreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventOwnAssessmentPreview: {
    __typename?: "SendCompEventOwnAssessmentPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventQuotationAcceptancePreviewMutationVariables =
  Exact<{
    input: SendCompEventQuotationAcceptancePreviewInput;
  }>;

export type GenerateSendCompEventQuotationAcceptancePreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventQuotationAcceptancePreview: {
    __typename?: "SendCompEventQuotationAcceptancePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventQuotationPreviewMutationVariables = Exact<{
  input: SendCompEventQuotationPreviewInput;
}>;

export type GenerateSendCompEventQuotationPreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventQuotationPreview: {
    __typename?: "SendCompEventQuotationPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventQuotationRevisionRequestPreviewMutationVariables =
  Exact<{
    input: SendCompEventQuotationRevisionRequestPreviewInput;
  }>;

export type GenerateSendCompEventQuotationRevisionRequestPreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventQuotationRevisionRequestPreview: {
    __typename?: "SendCompEventQuotationRevisionRequestPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventRejectionPreviewMutationVariables = Exact<{
  input: SendCompEventRejectionPreviewInput;
}>;

export type GenerateSendCompEventRejectionPreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventRejectionPreview: {
    __typename?: "SendCompEventRejectionPreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type GenerateSendCompEventRequestForMoreTimePreviewMutationVariables =
  Exact<{
    input: SendCompEventRequestForMoreTimePreviewInput;
  }>;

export type GenerateSendCompEventRequestForMoreTimePreviewMutation = {
  __typename?: "Mutation";
  generateSendCompEventRequestForMoreTimePreview: {
    __typename?: "SendCompEventRequestForMoreTimePreview";
    id: string;
    fileUrl: string;
    recipients: Array<{
      __typename?: "CompEventRecipient";
      userId: string;
      name: string;
      preference: {
        __typename?: "UserPreferencesValue";
        inApp: boolean;
        inAppReadOnly: boolean;
        email: boolean;
        emailReadOnly: boolean;
      };
    }>;
  };
};

export type RecordCompEventAbandonInstructionMutationVariables = Exact<{
  input: RecordCompEventAbandonInstructionInput;
}>;

export type RecordCompEventAbandonInstructionMutation = {
  __typename?: "Mutation";
  recordCompEventAbandonInstruction: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventConfirmationMutationVariables = Exact<{
  input: RecordCompEventConfirmationInput;
}>;

export type RecordCompEventConfirmationMutation = {
  __typename?: "Mutation";
  recordCompEventConfirmation: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventDenyingOfMoreTimeMutationVariables = Exact<{
  input: RecordCompEventDenyingOfMoreTimeInput;
}>;

export type RecordCompEventDenyingOfMoreTimeMutation = {
  __typename?: "Mutation";
  recordCompEventDenyingOfMoreTime: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventGrantingOfMoreTimeMutationVariables = Exact<{
  input: RecordCompEventGrantingOfMoreTimeInput;
}>;

export type RecordCompEventGrantingOfMoreTimeMutation = {
  __typename?: "Mutation";
  recordCompEventGrantingOfMoreTime: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventNoticeMutationVariables = Exact<{
  input: RecordCompEventNoticeInput;
}>;

export type RecordCompEventNoticeMutation = {
  __typename?: "Mutation";
  recordCompEventNotice: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventNoticeOfSilenceCompEventNoticeMutationVariables =
  Exact<{
    input: RecordCompEventNoticeOfSilenceCompEventNoticeInput;
  }>;

export type RecordCompEventNoticeOfSilenceCompEventNoticeMutation = {
  __typename?: "Mutation";
  recordCompEventNoticeOfSilenceCompEventNotice: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventNoticeOfSilenceOwnAssessmentMutationVariables =
  Exact<{
    input: RecordCompEventNoticeOfSilenceOwnAssessmentInput;
  }>;

export type RecordCompEventNoticeOfSilenceOwnAssessmentMutation = {
  __typename?: "Mutation";
  recordCompEventNoticeOfSilenceOwnAssessment: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventNoticeOfSilenceQuotationMutationVariables = Exact<{
  input: RecordCompEventNoticeOfSilenceQuotationInput;
}>;

export type RecordCompEventNoticeOfSilenceQuotationMutation = {
  __typename?: "Mutation";
  recordCompEventNoticeOfSilenceQuotation: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventNoticeRequestQuotationMutationVariables = Exact<{
  input: RecordCompEventNoticeRequestQuotationInput;
}>;

export type RecordCompEventNoticeRequestQuotationMutation = {
  __typename?: "Mutation";
  recordCompEventNoticeRequestQuotation: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventOwnAssessmentMutationVariables = Exact<{
  input: RecordCompEventOwnAssessmentInput;
}>;

export type RecordCompEventOwnAssessmentMutation = {
  __typename?: "Mutation";
  recordCompEventOwnAssessment: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventOwnAssessmentNoticeMutationVariables = Exact<{
  input: RecordCompEventOwnAssessmentNoticeInput;
}>;

export type RecordCompEventOwnAssessmentNoticeMutation = {
  __typename?: "Mutation";
  recordCompEventOwnAssessmentNotice: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventQuotationMutationVariables = Exact<{
  input: RecordCompEventQuotationInput;
}>;

export type RecordCompEventQuotationMutation = {
  __typename?: "Mutation";
  recordCompEventQuotation: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventQuotationAcceptanceMutationVariables = Exact<{
  input: RecordCompEventQuotationAcceptanceInput;
}>;

export type RecordCompEventQuotationAcceptanceMutation = {
  __typename?: "Mutation";
  recordCompEventQuotationAcceptance: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventQuotationRevisionRequestMutationVariables = Exact<{
  input: RecordCompEventQuotationRevisionRequestInput;
}>;

export type RecordCompEventQuotationRevisionRequestMutation = {
  __typename?: "Mutation";
  recordCompEventQuotationRevisionRequest: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventRejectionMutationVariables = Exact<{
  input: RecordCompEventRejectionInput;
}>;

export type RecordCompEventRejectionMutation = {
  __typename?: "Mutation";
  recordCompEventRejection: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordCompEventRequestForMoreTimeMutationVariables = Exact<{
  input: RecordCompEventRequestForMoreTimeInput;
}>;

export type RecordCompEventRequestForMoreTimeMutation = {
  __typename?: "Mutation";
  recordCompEventRequestForMoreTime: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type RecordInstructionItemMutationVariables = Exact<{
  input: RecordInstructionItemInput;
  draftId?: InputMaybe<Scalars["ID"]>;
}>;

export type RecordInstructionItemMutation = {
  __typename?: "Mutation";
  recordInstructionItem: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    notifyDueDate: any;
    creatorId: string;
    offline: boolean;
    givenById?: string | null;
    dateSent: any;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }> | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      contract: {
        __typename?: "Contract";
        id: string;
        contractTypeId: string;
        valueCurrency: string;
        timeZone: string;
      };
      product:
        | { __typename?: "Claims"; id: string; numberingFormat: string }
        | { __typename?: "CompEvents"; id: string; numberingFormat: string }
        | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
        | { __typename?: "EarlyWarnings"; id: string; numberingFormat: string }
        | { __typename?: "Events"; id: string; numberingFormat: string }
        | { __typename?: "Instructions"; id: string; numberingFormat: string }
        | { __typename?: "RisksRegister"; id: string; numberingFormat: string }
        | { __typename?: "Variations"; id: string; numberingFormat: string };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type RemoveAttachmentFileMutationVariables = Exact<{
  fileName: Scalars["String"];
}>;

export type RemoveAttachmentFileMutation = {
  __typename?: "Mutation";
  removeAttachmentFile: boolean;
};

export type RemoveAuthorizationWorkflowActionMappingMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveAuthorizationWorkflowActionMappingMutation = {
  __typename?: "Mutation";
  removeAuthorizationWorkflowActionMapping: {
    __typename?: "AuthorizationWorkflowActionMapping";
    id: string;
  };
};

export type RemoveClaimPreviewMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveClaimPreviewMutation = {
  __typename?: "Mutation";
  removeClaimPreview: boolean;
};

export type RemoveCompEventPreviewMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveCompEventPreviewMutation = {
  __typename?: "Mutation";
  removeCompEventPreview: boolean;
};

export type RemoveDailyDiaryDelayRecordMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDailyDiaryDelayRecordMutation = {
  __typename?: "Mutation";
  removeDailyDiaryDelayRecord?: boolean | null;
};

export type RemoveDailyDiaryEquipmentRecordMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDailyDiaryEquipmentRecordMutation = {
  __typename?: "Mutation";
  removeDailyDiaryEquipmentRecord: boolean;
};

export type RemoveDailyDiaryGeneralRecordMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDailyDiaryGeneralRecordMutation = {
  __typename?: "Mutation";
  removeDailyDiaryGeneralRecord?: boolean | null;
};

export type RemoveDailyDiaryHseRecordMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDailyDiaryHseRecordMutation = {
  __typename?: "Mutation";
  removeDailyDiaryHSERecord?: boolean | null;
};

export type RemoveDailyDiaryManpowerRecordMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDailyDiaryManpowerRecordMutation = {
  __typename?: "Mutation";
  removeDailyDiaryManpowerRecord: boolean;
};

export type RemoveDailyDiaryWeatherRecordMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDailyDiaryWeatherRecordMutation = {
  __typename?: "Mutation";
  removeDailyDiaryWeatherRecord: boolean;
};

export type RemoveDailyDiaryWorkRecordMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDailyDiaryWorkRecordMutation = {
  __typename?: "Mutation";
  removeDailyDiaryWorkRecord?: boolean | null;
};

export type RemoveDraftAssessmentMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftAssessmentMutation = {
  __typename?: "Mutation";
  removeDraftAssessment: boolean;
};

export type RemoveDraftEarlyWarningItemMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftEarlyWarningItemMutation = {
  __typename?: "Mutation";
  removeDraftEarlyWarningItem: boolean;
};

export type RemoveDraftEventItemMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftEventItemMutation = {
  __typename?: "Mutation";
  removeDraftEventItem: boolean;
};

export type RemoveDraftInstructionItemMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftInstructionItemMutation = {
  __typename?: "Mutation";
  removeDraftInstructionItem: boolean;
};

export type RemoveDraftQuotationMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftQuotationMutation = {
  __typename?: "Mutation";
  removeDraftQuotation: boolean;
};

export type RemoveDraftRiskItemMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDraftRiskItemMutation = {
  __typename?: "Mutation";
  removeDraftRiskItem: boolean;
};

export type RemoveInstructionPreviewMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveInstructionPreviewMutation = {
  __typename?: "Mutation";
  removeInstructionPreview: boolean;
};

export type RemoveTimePeriodOverrideMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveTimePeriodOverrideMutation = {
  __typename?: "Mutation";
  removeTimePeriodOverride: boolean;
};

export type ReviseDailyDiaryItemMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ReviseDailyDiaryItemMutation = {
  __typename?: "Mutation";
  reviseDailyDiaryItem: {
    __typename?: "DailyDiaryItem";
    id: string;
    productInstanceId: string;
    date: any;
    number: string;
    status: DailyDiaryItemStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    sentById?: string | null;
    dateSent?: any | null;
    latestRevision: {
      __typename?: "DailyDiaryItemRevision";
      id: string;
      number: string;
      sentById?: string | null;
      dateSent?: any | null;
      status: DailyDiaryItemStatus;
    };
  };
};

export type SendAuthorizationAcceptanceMutationVariables = Exact<{
  authorizationWorkflowAuditId: Scalars["ID"];
}>;

export type SendAuthorizationAcceptanceMutation = {
  __typename?: "Mutation";
  sendAuthorizationAcceptance: boolean;
};

export type SendAuthorizationDenialMutationVariables = Exact<{
  authorizationWorkflowAuditId: Scalars["ID"];
  denialReason: Scalars["String"];
}>;

export type SendAuthorizationDenialMutation = {
  __typename?: "Mutation";
  sendAuthorizationDenial: boolean;
};

export type SendCompEventAbandonInstructionMutationVariables = Exact<{
  input: SendCompEventAbandonInstructionInput;
}>;

export type SendCompEventAbandonInstructionMutation = {
  __typename?: "Mutation";
  sendCompEventAbandonInstruction: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventConfirmationMutationVariables = Exact<{
  input: SendCompEventConfirmationInput;
}>;

export type SendCompEventConfirmationMutation = {
  __typename?: "Mutation";
  sendCompEventConfirmation: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventDenyingOfMoreTimeMutationVariables = Exact<{
  input: SendCompEventDenyingOfMoreTimeInput;
}>;

export type SendCompEventDenyingOfMoreTimeMutation = {
  __typename?: "Mutation";
  sendCompEventDenyingOfMoreTime: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventGrantingOfMoreTimeMutationVariables = Exact<{
  input: SendCompEventGrantingOfMoreTimeInput;
}>;

export type SendCompEventGrantingOfMoreTimeMutation = {
  __typename?: "Mutation";
  sendCompEventGrantingOfMoreTime: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventNoticeMutationVariables = Exact<{
  input: SendCompEventNoticeInput;
}>;

export type SendCompEventNoticeMutation = {
  __typename?: "Mutation";
  sendCompEventNotice: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventNoticeOfSilenceCompEventNoticeMutationVariables =
  Exact<{
    input: SendCompEventNoticeOfSilenceCompEventNoticeInput;
  }>;

export type SendCompEventNoticeOfSilenceCompEventNoticeMutation = {
  __typename?: "Mutation";
  sendCompEventNoticeOfSilenceCompEventNotice: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventNoticeOfSilenceOwnAssessmentMutationVariables = Exact<{
  input: SendCompEventNoticeOfSilenceOwnAssessmentInput;
}>;

export type SendCompEventNoticeOfSilenceOwnAssessmentMutation = {
  __typename?: "Mutation";
  sendCompEventNoticeOfSilenceOwnAssessment: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventNoticeOfSilenceQuotationMutationVariables = Exact<{
  input: SendCompEventNoticeOfSilenceQuotationInput;
}>;

export type SendCompEventNoticeOfSilenceQuotationMutation = {
  __typename?: "Mutation";
  sendCompEventNoticeOfSilenceQuotation: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventNoticeRequestQuotationMutationVariables = Exact<{
  input: SendCompEventNoticeRequestQuotationInput;
}>;

export type SendCompEventNoticeRequestQuotationMutation = {
  __typename?: "Mutation";
  sendCompEventNoticeRequestQuotation: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventOwnAssessmentMutationVariables = Exact<{
  input: SendCompEventOwnAssessmentInput;
}>;

export type SendCompEventOwnAssessmentMutation = {
  __typename?: "Mutation";
  sendCompEventOwnAssessment: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventOwnAssessmentNoticeMutationVariables = Exact<{
  input: SendCompEventOwnAssessmentNoticeInput;
}>;

export type SendCompEventOwnAssessmentNoticeMutation = {
  __typename?: "Mutation";
  sendCompEventOwnAssessmentNotice: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventQuotationMutationVariables = Exact<{
  input: SendCompEventQuotationInput;
}>;

export type SendCompEventQuotationMutation = {
  __typename?: "Mutation";
  sendCompEventQuotation: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventQuotationAcceptanceMutationVariables = Exact<{
  input: SendCompEventQuotationAcceptanceInput;
}>;

export type SendCompEventQuotationAcceptanceMutation = {
  __typename?: "Mutation";
  sendCompEventQuotationAcceptance: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventQuotationRevisionRequestMutationVariables = Exact<{
  input: SendCompEventQuotationRevisionRequestInput;
}>;

export type SendCompEventQuotationRevisionRequestMutation = {
  __typename?: "Mutation";
  sendCompEventQuotationRevisionRequest: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventRejectionMutationVariables = Exact<{
  input: SendCompEventRejectionInput;
}>;

export type SendCompEventRejectionMutation = {
  __typename?: "Mutation";
  sendCompEventRejection: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendCompEventRequestForMoreTimeMutationVariables = Exact<{
  input: SendCompEventRequestForMoreTimeInput;
}>;

export type SendCompEventRequestForMoreTimeMutation = {
  __typename?: "Mutation";
  sendCompEventRequestForMoreTime: {
    __typename: "CompEventItem";
    id: string;
    number: string;
    productInstanceId: string;
    regardingId: string;
    regardingType: CompEventRegardingType;
    status: CompEventStatus;
    price?: number | null;
    time?: number | null;
    earlyWarningId?: string | null;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    deemingDate?: any | null;
    lastAction: {
      __typename?: "CompEventItemLastAction";
      actionType: CompEventActionType;
      daysLate?: number | null;
      date: any;
    };
    nextStep?: {
      __typename?: "CompEventItemNextStep";
      actionType: CompEventActionType;
      dueDate: any;
    } | null;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      status: ProductInstanceStatus;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        status: ContractStatus;
        timeZone: string;
        valueCurrency: string;
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          status: ProjectStatus;
        };
      };
    };
    earlyWarning?: {
      __typename?: "EarlyWarningItem";
      id: string;
      title: string;
      dateSent: any;
      number: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingEvent?: {
      __typename?: "EventItem";
      id: string;
      title: string;
      productInstanceId: string;
      number: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    regardingInstruction?: {
      __typename?: "InstructionItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        product:
          | { __typename?: "Claims"; id: string }
          | { __typename?: "CompEvents"; id: string }
          | { __typename?: "DailyDiary"; id: string }
          | { __typename?: "EarlyWarnings"; id: string }
          | { __typename?: "Events"; id: string }
          | { __typename?: "Instructions"; id: string }
          | { __typename?: "RisksRegister"; id: string }
          | { __typename?: "Variations"; id: string };
      };
    } | null;
    notifiedByParty: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    };
  };
};

export type SendInstructionItemMutationVariables = Exact<{
  input: SendInstructionItemInput;
  draftId?: InputMaybe<Scalars["ID"]>;
}>;

export type SendInstructionItemMutation = {
  __typename?: "Mutation";
  sendInstructionItem: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    notifyDueDate: any;
    creatorId: string;
    offline: boolean;
    givenById?: string | null;
    dateSent: any;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }> | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      contract: {
        __typename?: "Contract";
        id: string;
        contractTypeId: string;
        valueCurrency: string;
        timeZone: string;
      };
      product:
        | { __typename?: "Claims"; id: string; numberingFormat: string }
        | { __typename?: "CompEvents"; id: string; numberingFormat: string }
        | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
        | { __typename?: "EarlyWarnings"; id: string; numberingFormat: string }
        | { __typename?: "Events"; id: string; numberingFormat: string }
        | { __typename?: "Instructions"; id: string; numberingFormat: string }
        | { __typename?: "RisksRegister"; id: string; numberingFormat: string }
        | { __typename?: "Variations"; id: string; numberingFormat: string };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type ActiveClaimTypesQueryVariables = Exact<{
  contractId: Scalars["ID"];
}>;

export type ActiveClaimTypesQuery = {
  __typename?: "Query";
  activeClaimTypes: Array<{
    __typename?: "ListItem";
    id: string;
    name: string;
  }>;
};

export type AttachmentDownloadPreSignedUrlQueryVariables = Exact<{
  fileUrl: Scalars["String"];
  fileName?: InputMaybe<Scalars["String"]>;
}>;

export type AttachmentDownloadPreSignedUrlQuery = {
  __typename?: "Query";
  attachmentDownloadPreSignedUrl: string;
};

export type AuthenticationDataQueryVariables = Exact<{ [key: string]: never }>;

export type AuthenticationDataQuery = {
  __typename?: "Query";
  authenticationData: {
    __typename?: "AuthenticationData";
    isAdmin: boolean;
    intercomIdentityHash: string;
    user: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      jobTitle: string;
      country: string;
      mobileNumber?: any | null;
      alternateNumber?: any | null;
      dateOfBirth?: any | null;
      profilePicture?: string | null;
      email: any;
      companyId: string;
      registered?: boolean | null;
      status: UserStatus;
      lastActive?: any | null;
      dateInvited: any;
      registeredDate?: any | null;
      company: {
        __typename?: "Company";
        id: string;
        type?: CompanyType | null;
        registeredName: string;
        tradingName: string;
        registrationNumber?: string | null;
        logo?: string | null;
        vatRegistrationNumber?: string | null;
        status: CompanyStatus;
        creatorId: string;
        dateCreated: any;
        physicalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        postalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      invitedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      roles: {
        __typename?: "ProductUserRoleList";
        items: Array<{
          __typename?: "ProductUserRole";
          userId: string;
          productInstanceId: string;
          productRoleId: string;
          status: ProductUserRoleStatus;
          dateCreated: any;
          creatorId: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            contractId: string;
            productId: string;
            productSchemaId: string;
            statusCollectionId: string;
            status: ProductInstanceStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              number: string;
              valueCurrency: string;
              value: number;
              startDate?: any | null;
              endDate?: any | null;
              contractTypeId: string;
              projectId: string;
              country: string;
              province?: string | null;
              timeZone: string;
              coordinatesLatitude?: number | null;
              coordinatesLongitude?: number | null;
              status: ContractStatus;
              dateCreated: any;
              creatorId: string;
              sections: Array<{
                __typename?: "ContractSection";
                id: string;
                number?: number | null;
                description: string;
                completionDate: any;
                status: ContractSectionStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
              keyDates: Array<{
                __typename?: "ContractKeyDate";
                id: string;
                number?: number | null;
                conditionToBeMet: string;
                keyDate: any;
                status: ContractKeyDateStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
            statusCollection: {
              __typename?: "ItemStatusCollection";
              id: string;
              name: string;
              isDefault: boolean;
              status: ItemStatusCollectionStatus;
            };
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          user: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productRole: {
            __typename?: "ProductRole";
            id: string;
            name: string;
            productId: string;
            actionIds: Array<string>;
            isInternal: boolean;
            status: ProductRoleStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            actions: {
              __typename?: "ProductRoleActionList";
              items: Array<{
                __typename?: "ProductRoleAction";
                id: string;
                name: string;
                isInternal: boolean;
                status: ProductRoleActionStatus;
                dateCreated: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          };
        }>;
      };
    };
  };
};

export type AuthorizationWorkflowQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AuthorizationWorkflowQuery = {
  __typename?: "Query";
  authorizationWorkflow?: {
    __typename?: "AuthorizationWorkflow";
    id: string;
    name: string;
    status: AuthorizationWorkflowStatus;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    modifiedById: string;
    projectId: string;
    levels: Array<{
      __typename?: "AuthorizationWorkflowLevel";
      sequence: number;
      mode: AuthorizationWorkflowLevelMode;
      userIds: Array<string>;
      users: {
        __typename?: "UserList";
        items: Array<{
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          jobTitle: string;
          country: string;
          mobileNumber?: any | null;
          alternateNumber?: any | null;
          dateOfBirth?: any | null;
          profilePicture?: string | null;
          email: any;
          companyId: string;
          registered?: boolean | null;
          status: UserStatus;
          lastActive?: any | null;
          dateInvited: any;
          registeredDate?: any | null;
          company: {
            __typename?: "Company";
            id: string;
            tradingName: string;
            registeredName: string;
          };
          invitedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    project: {
      __typename?: "Project";
      id: string;
      name: string;
      friendlyName: string;
      number?: string | null;
      sector: ProjectSector;
      description?: string | null;
      status: ProjectStatus;
      dateCreated: any;
      creatorId: string;
      billingCompanyId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      contracts: {
        __typename?: "ContractList";
        items: Array<{
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          status: ContractStatus;
        }>;
      };
      billingCompany: {
        __typename?: "Company";
        id: string;
        type?: CompanyType | null;
        registeredName: string;
        tradingName: string;
        registrationNumber?: string | null;
        logo?: string | null;
        vatRegistrationNumber?: string | null;
        status: CompanyStatus;
        creatorId: string;
        dateCreated: any;
        physicalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        postalAddress?: {
          __typename?: "Address";
          line1?: string | null;
          line2?: string | null;
          city?: string | null;
          provinceState?: string | null;
          country: string;
          code?: string | null;
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
    };
  } | null;
};

export type AuthorizationWorkflowActionMappingsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type AuthorizationWorkflowActionMappingsQuery = {
  __typename?: "Query";
  authorizationWorkflowActionMappings: {
    __typename?: "AuthorizationWorkflowActionMappingsList";
    items: Array<{
      __typename?: "AuthorizationWorkflowActionMapping";
      id: string;
      productOutputActionId: string;
      workflowId: string;
      partyId?: string | null;
      productInstanceId: string;
      status: AuthorizationWorkflowActionMappingStatus;
      dateCreated: any;
      creatorId: string;
      modifiedById: string;
      dateModified: any;
      productOutputAction: {
        __typename?: "ProductOutputAction";
        id: string;
        name: string;
        productId: string;
        isInternal: boolean;
        status: ProductOutputActionStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      party?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        contractId: string;
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
        contract: {
          __typename?: "Contract";
          id: string;
          friendlyName: string;
          timeZone: string;
          valueCurrency: string;
          project: { __typename?: "Project"; id: string; friendlyName: string };
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type AuthorizationWorkflowAuditQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AuthorizationWorkflowAuditQuery = {
  __typename?: "Query";
  authorizationWorkflowAudit?: {
    __typename?: "AuthorizationWorkflowAudit";
    id: string;
    initiatedById: string;
    regardingProductItemId: string;
    regardingProductType: ProductType;
    regardingItemHistoryId?: string | null;
    workflowId: string;
    actionId: string;
    status: AuthorizationWorkflowAuditStatus;
    dateCreated: any;
    dateInitiated: any;
    initiatedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    action: {
      __typename?: "AuthorizationWorkflowActionMapping";
      id: string;
      productOutputActionId: string;
      workflowId: string;
      partyId?: string | null;
      productInstanceId: string;
      status: AuthorizationWorkflowActionMappingStatus;
      dateCreated: any;
      creatorId: string;
      modifiedById: string;
      dateModified: any;
      productOutputAction: {
        __typename?: "ProductOutputAction";
        id: string;
        name: string;
        productId: string;
        isInternal: boolean;
        status: ProductOutputActionStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      party?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        contractId: string;
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
        contract: {
          __typename?: "Contract";
          id: string;
          friendlyName: string;
          timeZone: string;
          valueCurrency: string;
          project: { __typename?: "Project"; id: string; friendlyName: string };
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    regardingProductItem:
      | {
          __typename: "ClaimItem";
          id: string;
          number: string;
          regardingId: string;
          claimRegardingType: ClaimRegardingType;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        }
      | {
          __typename: "CompEventItem";
          id: string;
          number: string;
          productInstanceId: string;
          regardingId: string;
          regardingType: CompEventRegardingType;
          status: CompEventStatus;
          price?: number | null;
          time?: number | null;
          earlyWarningId?: string | null;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          deemingDate?: any | null;
          lastAction: {
            __typename?: "CompEventItemLastAction";
            actionType: CompEventActionType;
            daysLate?: number | null;
            date: any;
          };
          nextStep?: {
            __typename?: "CompEventItemNextStep";
            actionType: CompEventActionType;
            dueDate: any;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            status: ProductInstanceStatus;
            product:
              | { __typename?: "Claims"; id: string; name: string }
              | { __typename?: "CompEvents"; id: string; name: string }
              | { __typename?: "DailyDiary"; id: string; name: string }
              | { __typename?: "EarlyWarnings"; id: string; name: string }
              | { __typename?: "Events"; id: string; name: string }
              | { __typename?: "Instructions"; id: string; name: string }
              | { __typename?: "RisksRegister"; id: string; name: string }
              | { __typename?: "Variations"; id: string; name: string };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
              timeZone: string;
              valueCurrency: string;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
          };
          earlyWarning?: {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            dateSent: any;
            number: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingEvent?: {
            __typename?: "EventItem";
            id: string;
            title: string;
            productInstanceId: string;
            number: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          regardingInstruction?: {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            number: string;
            productInstanceId: string;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              product:
                | { __typename?: "Claims"; id: string }
                | { __typename?: "CompEvents"; id: string }
                | { __typename?: "DailyDiary"; id: string }
                | { __typename?: "EarlyWarnings"; id: string }
                | { __typename?: "Events"; id: string }
                | { __typename?: "Instructions"; id: string }
                | { __typename?: "RisksRegister"; id: string }
                | { __typename?: "Variations"; id: string };
            };
          } | null;
          notifiedByParty: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          };
        }
      | {
          __typename?: "DailyDiaryItem";
          id: string;
          date: any;
          number: string;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          sentById?: string | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            status: ProductInstanceStatus;
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              timeZone: string;
              number: string;
              status: ContractStatus;
              dateCreated: any;
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
          };
        }
      | {
          __typename?: "EarlyWarningItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          creatorId: string;
          dateModified: any;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          owner: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            productSchema: {
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  displayOrder: number;
                  columnPlacement: ColumnPlacementType;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  dateCreated: any;
                  creatorId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      displayOrder: number;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      extraData?: string | null;
                      status: SchemaFieldStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            };
            contract: {
              __typename?: "Contract";
              id: string;
              valueCurrency: string;
              timeZone: string;
            };
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
          };
          attachments?: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }> | null;
        }
      | { __typename?: "EventItem" }
      | {
          __typename?: "InstructionItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          dateModified: any;
          notifyDueDate: any;
          creatorId: string;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
          attachments?: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }> | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            productSchema: {
              __typename?: "ProductSchema";
              id: string;
              name: string;
              isDefault: boolean;
              productId: string;
              isInternal: boolean;
              status: ProductSchemaStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaSections: {
                __typename?: "SchemaSectionList";
                items: Array<{
                  __typename?: "SchemaSection";
                  id: string;
                  name: string;
                  displayText: string;
                  displayOrder: number;
                  columnPlacement: ColumnPlacementType;
                  productSchemaId: string;
                  isInternal: boolean;
                  status: SchemaSectionStatus;
                  dateCreated: any;
                  creatorId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaFields: {
                    __typename?: "SchemaFieldList";
                    items: Array<{
                      __typename?: "SchemaField";
                      id: string;
                      name: string;
                      schemaSectionId: string;
                      fieldTypeId: string;
                      displayText: string;
                      displayOrder: number;
                      isRequired: boolean;
                      isInternal: boolean;
                      complexTypeItemId?: string | null;
                      extraData?: string | null;
                      status: SchemaFieldStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      fieldType: {
                        __typename?: "FieldType";
                        id: string;
                        description: string;
                        shortCode: FieldTypeEnum;
                        status: FieldTypeStatus;
                      };
                    }>;
                  };
                }>;
              };
            };
            contract: {
              __typename?: "Contract";
              id: string;
              contractTypeId: string;
              valueCurrency: string;
              timeZone: string;
            };
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
          };
          data: {
            __typename?: "ItemData";
            sections: Array<{
              __typename?: "ItemDataSection";
              name: string;
              entries: Array<{
                __typename?: "ItemDataEntry";
                name: string;
                value: string;
              }>;
            }>;
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          statusOption: {
            __typename?: "ItemStatusOption";
            id: string;
            displayOrder: number;
            description: string;
            equatesToRemoved: boolean;
            style: ItemStatusOptionStyles;
            statusCollectionId: string;
            isInternal: boolean;
            status: ItemStatusOptionStatus;
            creatorId: string;
            dateCreated: any;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
          owner: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }
      | { __typename?: "RiskItem" }
      | {
          __typename: "VariationItem";
          id: string;
          number: string;
          regardingId: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        };
    auditTrails?: Array<{
      __typename?: "AuthorizationWorkflowAuditTrail";
      id: string;
      auditId: string;
      regardingProductItemId: string;
      regardingItemHistoryId?: string | null;
      regardingProductType: ProductType;
      productInstanceId: string;
      actionId: string;
      status: AuthorizationActionStatus;
      denialReason?: string | null;
      dateCreated: any;
      creatorId: string;
      productInstance?: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        soloModeSupported: boolean;
        contractId: string;
        productId: string;
        productSchemaId: string;
        statusCollectionId: string;
        status: ProductInstanceStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contract: {
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          number: string;
          valueCurrency: string;
          value: number;
          startDate?: any | null;
          endDate?: any | null;
          contractTypeId: string;
          projectId: string;
          country: string;
          province?: string | null;
          timeZone: string;
          coordinatesLatitude?: number | null;
          coordinatesLongitude?: number | null;
          status: ContractStatus;
          dateCreated: any;
          creatorId: string;
          sections: Array<{
            __typename?: "ContractSection";
            id: string;
            number?: number | null;
            description: string;
            completionDate: any;
            status: ContractSectionStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          keyDates: Array<{
            __typename?: "ContractKeyDate";
            id: string;
            number?: number | null;
            conditionToBeMet: string;
            keyDate: any;
            status: ContractKeyDateStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            status: ProjectStatus;
          };
          contractType: {
            __typename?: "ContractType";
            id: string;
            description: string;
            subType?: string | null;
            version?: string | null;
            status: ContractTypeStatus;
          };
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
        statusCollection: {
          __typename?: "ItemStatusCollection";
          id: string;
          name: string;
          isDefault: boolean;
          status: ItemStatusCollectionStatus;
        };
      } | null;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }> | null;
  } | null;
};

export type AuthorizationWorkflowsQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type AuthorizationWorkflowsQuery = {
  __typename?: "Query";
  authorizationWorkflows?: {
    __typename?: "AuthorizationWorkflowList";
    items?: Array<{
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    }> | null;
  } | null;
};

export type AvailableLookupsByContractQueryVariables = Exact<{
  contractId: Scalars["ID"];
}>;

export type AvailableLookupsByContractQuery = {
  __typename?: "Query";
  availableLookupsByContract: {
    __typename?: "CompanyLookupCollectionList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "CompanyLookupCollection";
      id: string;
      companyId: string;
      name: string;
      optionsLockedForUsers: boolean;
      optionsCharLengthLimit?: number | null;
      usage: CompanyLookupCollectionUsage;
      status: CompanyLookupCollectionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      options: {
        __typename?: "LookupOptionList";
        items: Array<{
          __typename?: "LookupOption";
          id: string;
          value: string;
          displayOrder: number;
          collectionId: string;
          isInternal: boolean;
          status: LookupOptionStatus;
          creatorId: string;
          dateCreated: any;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    }>;
  };
};

export type CompEventAbandonInstructionPromptQueryVariables = Exact<{
  input: CompEventAbandonInstructionPromptInput;
}>;

export type CompEventAbandonInstructionPromptQuery = {
  __typename?: "Query";
  compEventAbandonInstructionPrompt: {
    __typename?: "CompEventAbandonInstructionPrompt";
    remarks?: string | null;
  };
};

export type CompEventActionQueryVariables = Exact<{
  input: CompEventActionInput;
}>;

export type CompEventActionQuery = {
  __typename?: "Query";
  compEventAction: {
    __typename?: "CompEventActionList";
    items: Array<{
      __typename?: "CompEventAction";
      type: CompEventActionType;
      actionMapping?: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      } | null;
    }>;
    ongoingAuthorizationAudit?: {
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      actionId: string;
      workflowId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateInitiated: any;
      dateCreated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
    } | null;
  };
};

export type CompEventDenyingOfMoreTimePromptQueryVariables = Exact<{
  input: CompEventDenyingOfMoreTimePromptInput;
}>;

export type CompEventDenyingOfMoreTimePromptQuery = {
  __typename?: "Query";
  compEventDenyingOfMoreTimePrompt: {
    __typename?: "CompEventDenyingOfMoreTimePrompt";
    remarks?: string | null;
  };
};

export type CompEventGrantingOfMoreTimePromptQueryVariables = Exact<{
  input: CompEventGrantingOfMoreTimePromptInput;
}>;

export type CompEventGrantingOfMoreTimePromptQuery = {
  __typename?: "Query";
  compEventGrantingOfMoreTimePrompt: {
    __typename?: "CompEventGrantingOfMoreTimePrompt";
    grantedDays: number;
    remarks?: string | null;
    actionDueDate: any;
  };
};

export type CompEventHistoryItemsQueryVariables = Exact<{
  compEventId: Scalars["ID"];
}>;

export type CompEventHistoryItemsQuery = {
  __typename?: "Query";
  compEventHistoryItems: {
    __typename?: "CompEventHistoryItemList";
    items: Array<{
      __typename?: "CompEventHistoryItem";
      id: string;
      compEventId: string;
      actionType: CompEventActionType;
      actionNumber: string;
      isIncomplete?: boolean | null;
      dateSent: any;
      dateCreated: any;
      dueDate?: any | null;
      sentByUserId: string;
      sentByPartyId?: string | null;
      offline: boolean;
      daysLate: number;
      authorizationWorkflowAudit?: {
        __typename?: "AuthorizationWorkflowAudit";
        id: string;
        initiatedById: string;
        regardingProductItemId: string;
        regardingProductType: ProductType;
        regardingItemHistoryId?: string | null;
        workflowId: string;
        actionId: string;
        status: AuthorizationWorkflowAuditStatus;
        dateCreated: any;
        dateInitiated: any;
        initiatedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        action: {
          __typename?: "AuthorizationWorkflowActionMapping";
          id: string;
          productOutputActionId: string;
          workflowId: string;
          partyId?: string | null;
          productInstanceId: string;
          status: AuthorizationWorkflowActionMappingStatus;
          dateCreated: any;
          creatorId: string;
          modifiedById: string;
          dateModified: any;
          productOutputAction: {
            __typename?: "ProductOutputAction";
            id: string;
            name: string;
            productId: string;
            isInternal: boolean;
            status: ProductOutputActionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
          };
          workflow: {
            __typename?: "AuthorizationWorkflow";
            id: string;
            name: string;
            status: AuthorizationWorkflowStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            modifiedById: string;
            projectId: string;
            levels: Array<{
              __typename?: "AuthorizationWorkflowLevel";
              sequence: number;
              mode: AuthorizationWorkflowLevelMode;
              userIds: Array<string>;
              users: {
                __typename?: "UserList";
                items: Array<{
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  jobTitle: string;
                  country: string;
                  mobileNumber?: any | null;
                  alternateNumber?: any | null;
                  dateOfBirth?: any | null;
                  profilePicture?: string | null;
                  email: any;
                  companyId: string;
                  registered?: boolean | null;
                  status: UserStatus;
                  lastActive?: any | null;
                  dateInvited: any;
                  registeredDate?: any | null;
                  company: {
                    __typename?: "Company";
                    id: string;
                    tradingName: string;
                    registeredName: string;
                  };
                  invitedBy: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                }>;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            modifiedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              number?: string | null;
              sector: ProjectSector;
              description?: string | null;
              status: ProjectStatus;
              dateCreated: any;
              creatorId: string;
              billingCompanyId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              contracts: {
                __typename?: "ContractList";
                items: Array<{
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ContractStatus;
                }>;
              };
              billingCompany: {
                __typename?: "Company";
                id: string;
                type?: CompanyType | null;
                registeredName: string;
                tradingName: string;
                registrationNumber?: string | null;
                logo?: string | null;
                vatRegistrationNumber?: string | null;
                status: CompanyStatus;
                creatorId: string;
                dateCreated: any;
                physicalAddress?: {
                  __typename?: "Address";
                  line1?: string | null;
                  line2?: string | null;
                  city?: string | null;
                  provinceState?: string | null;
                  country: string;
                  code?: string | null;
                } | null;
                postalAddress?: {
                  __typename?: "Address";
                  line1?: string | null;
                  line2?: string | null;
                  city?: string | null;
                  provinceState?: string | null;
                  country: string;
                  code?: string | null;
                } | null;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              };
            };
          };
          party?: {
            __typename?: "ContractBindingType";
            id: string;
            description: string;
            contractTypeId: string;
            status: ContractBindingTypeStatus;
          } | null;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            contractId: string;
            product:
              | { __typename?: "Claims"; id: string; name: string }
              | { __typename?: "CompEvents"; id: string; name: string }
              | { __typename?: "DailyDiary"; id: string; name: string }
              | { __typename?: "EarlyWarnings"; id: string; name: string }
              | { __typename?: "Events"; id: string; name: string }
              | { __typename?: "Instructions"; id: string; name: string }
              | { __typename?: "RisksRegister"; id: string; name: string }
              | { __typename?: "Variations"; id: string; name: string };
            contract: {
              __typename?: "Contract";
              id: string;
              friendlyName: string;
              timeZone: string;
              valueCurrency: string;
              project: {
                __typename?: "Project";
                id: string;
                friendlyName: string;
              };
            };
          };
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
        regardingProductItem:
          | {
              __typename: "ClaimItem";
              id: string;
              number: string;
              regardingId: string;
              claimRegardingType: ClaimRegardingType;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                contract: {
                  __typename?: "Contract";
                  id: string;
                  timeZone: string;
                };
              };
            }
          | {
              __typename: "CompEventItem";
              id: string;
              number: string;
              productInstanceId: string;
              regardingId: string;
              regardingType: CompEventRegardingType;
              status: CompEventStatus;
              price?: number | null;
              time?: number | null;
              earlyWarningId?: string | null;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              deemingDate?: any | null;
              lastAction: {
                __typename?: "CompEventItemLastAction";
                actionType: CompEventActionType;
                daysLate?: number | null;
                date: any;
              };
              nextStep?: {
                __typename?: "CompEventItemNextStep";
                actionType: CompEventActionType;
                dueDate: any;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                status: ProductInstanceStatus;
                product:
                  | { __typename?: "Claims"; id: string; name: string }
                  | { __typename?: "CompEvents"; id: string; name: string }
                  | { __typename?: "DailyDiary"; id: string; name: string }
                  | { __typename?: "EarlyWarnings"; id: string; name: string }
                  | { __typename?: "Events"; id: string; name: string }
                  | { __typename?: "Instructions"; id: string; name: string }
                  | { __typename?: "RisksRegister"; id: string; name: string }
                  | { __typename?: "Variations"; id: string; name: string };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ContractStatus;
                  timeZone: string;
                  valueCurrency: string;
                  project: {
                    __typename?: "Project";
                    id: string;
                    name: string;
                    friendlyName: string;
                    status: ProjectStatus;
                  };
                };
              };
              earlyWarning?: {
                __typename?: "EarlyWarningItem";
                id: string;
                title: string;
                dateSent: any;
                number: string;
                productInstance: {
                  __typename?: "ProductInstance";
                  id: string;
                  product:
                    | { __typename?: "Claims"; id: string }
                    | { __typename?: "CompEvents"; id: string }
                    | { __typename?: "DailyDiary"; id: string }
                    | { __typename?: "EarlyWarnings"; id: string }
                    | { __typename?: "Events"; id: string }
                    | { __typename?: "Instructions"; id: string }
                    | { __typename?: "RisksRegister"; id: string }
                    | { __typename?: "Variations"; id: string };
                };
              } | null;
              regardingEvent?: {
                __typename?: "EventItem";
                id: string;
                title: string;
                productInstanceId: string;
                number: string;
                data: {
                  __typename?: "ItemData";
                  sections: Array<{
                    __typename?: "ItemDataSection";
                    name: string;
                    entries: Array<{
                      __typename?: "ItemDataEntry";
                      name: string;
                      value: string;
                    }>;
                  }>;
                };
                productInstance: {
                  __typename?: "ProductInstance";
                  id: string;
                  product:
                    | { __typename?: "Claims"; id: string }
                    | { __typename?: "CompEvents"; id: string }
                    | { __typename?: "DailyDiary"; id: string }
                    | { __typename?: "EarlyWarnings"; id: string }
                    | { __typename?: "Events"; id: string }
                    | { __typename?: "Instructions"; id: string }
                    | { __typename?: "RisksRegister"; id: string }
                    | { __typename?: "Variations"; id: string };
                };
              } | null;
              regardingInstruction?: {
                __typename?: "InstructionItem";
                id: string;
                title: string;
                number: string;
                productInstanceId: string;
                data: {
                  __typename?: "ItemData";
                  sections: Array<{
                    __typename?: "ItemDataSection";
                    name: string;
                    entries: Array<{
                      __typename?: "ItemDataEntry";
                      name: string;
                      value: string;
                    }>;
                  }>;
                };
                productInstance: {
                  __typename?: "ProductInstance";
                  id: string;
                  product:
                    | { __typename?: "Claims"; id: string }
                    | { __typename?: "CompEvents"; id: string }
                    | { __typename?: "DailyDiary"; id: string }
                    | { __typename?: "EarlyWarnings"; id: string }
                    | { __typename?: "Events"; id: string }
                    | { __typename?: "Instructions"; id: string }
                    | { __typename?: "RisksRegister"; id: string }
                    | { __typename?: "Variations"; id: string };
                };
              } | null;
              notifiedByParty: {
                __typename?: "ContractBindingType";
                id: string;
                description: string;
                contractTypeId: string;
                status: ContractBindingTypeStatus;
              };
            }
          | {
              __typename?: "DailyDiaryItem";
              id: string;
              date: any;
              number: string;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              sentById?: string | null;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                status: ProductInstanceStatus;
                contract: {
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  timeZone: string;
                  number: string;
                  status: ContractStatus;
                  dateCreated: any;
                  project: {
                    __typename?: "Project";
                    id: string;
                    name: string;
                    friendlyName: string;
                    status: ProjectStatus;
                  };
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    };
              };
            }
          | {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              severity: string;
              number: string;
              productInstanceId: string;
              ownerId: string;
              statusOptionId: string;
              dateCreated: any;
              creatorId: string;
              dateModified: any;
              offline: boolean;
              givenById?: string | null;
              dateSent: any;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              owner: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                productSchema: {
                  __typename?: "ProductSchema";
                  id: string;
                  name: string;
                  isDefault: boolean;
                  productId: string;
                  isInternal: boolean;
                  status: ProductSchemaStatus;
                  creatorId: string;
                  dateCreated: any;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaSections: {
                    __typename?: "SchemaSectionList";
                    items: Array<{
                      __typename?: "SchemaSection";
                      id: string;
                      name: string;
                      displayText: string;
                      displayOrder: number;
                      columnPlacement: ColumnPlacementType;
                      productSchemaId: string;
                      isInternal: boolean;
                      status: SchemaSectionStatus;
                      dateCreated: any;
                      creatorId: string;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      schemaFields: {
                        __typename?: "SchemaFieldList";
                        items: Array<{
                          __typename?: "SchemaField";
                          id: string;
                          name: string;
                          schemaSectionId: string;
                          fieldTypeId: string;
                          displayText: string;
                          displayOrder: number;
                          isRequired: boolean;
                          isInternal: boolean;
                          complexTypeItemId?: string | null;
                          extraData?: string | null;
                          status: SchemaFieldStatus;
                          creatorId: string;
                          dateCreated: any;
                          creator: {
                            __typename?: "User";
                            id: string;
                            firstname: string;
                            surname: string;
                            email: any;
                          };
                          fieldType: {
                            __typename?: "FieldType";
                            id: string;
                            description: string;
                            shortCode: FieldTypeEnum;
                            status: FieldTypeStatus;
                          };
                        }>;
                      };
                    }>;
                  };
                };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  valueCurrency: string;
                  timeZone: string;
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      numberingFormat: string;
                    };
              };
              attachments?: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }> | null;
            }
          | { __typename?: "EventItem" }
          | {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              severity: string;
              number: string;
              productInstanceId: string;
              ownerId: string;
              statusOptionId: string;
              dateCreated: any;
              dateModified: any;
              notifyDueDate: any;
              creatorId: string;
              offline: boolean;
              givenById?: string | null;
              dateSent: any;
              attachments?: Array<{
                __typename?: "Attachment";
                id: string;
                fileName: string;
                fileUrl: string;
                mimeType: string;
                description?: string | null;
                locked: boolean;
                fileSize: number;
                status: AttachmentStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                  company: {
                    __typename?: "Company";
                    id: string;
                    registeredName: string;
                    tradingName: string;
                  };
                };
              }> | null;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                productSchema: {
                  __typename?: "ProductSchema";
                  id: string;
                  name: string;
                  isDefault: boolean;
                  productId: string;
                  isInternal: boolean;
                  status: ProductSchemaStatus;
                  creatorId: string;
                  dateCreated: any;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  schemaSections: {
                    __typename?: "SchemaSectionList";
                    items: Array<{
                      __typename?: "SchemaSection";
                      id: string;
                      name: string;
                      displayText: string;
                      displayOrder: number;
                      columnPlacement: ColumnPlacementType;
                      productSchemaId: string;
                      isInternal: boolean;
                      status: SchemaSectionStatus;
                      dateCreated: any;
                      creatorId: string;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      schemaFields: {
                        __typename?: "SchemaFieldList";
                        items: Array<{
                          __typename?: "SchemaField";
                          id: string;
                          name: string;
                          schemaSectionId: string;
                          fieldTypeId: string;
                          displayText: string;
                          displayOrder: number;
                          isRequired: boolean;
                          isInternal: boolean;
                          complexTypeItemId?: string | null;
                          extraData?: string | null;
                          status: SchemaFieldStatus;
                          creatorId: string;
                          dateCreated: any;
                          creator: {
                            __typename?: "User";
                            id: string;
                            firstname: string;
                            surname: string;
                            email: any;
                          };
                          fieldType: {
                            __typename?: "FieldType";
                            id: string;
                            description: string;
                            shortCode: FieldTypeEnum;
                            status: FieldTypeStatus;
                          };
                        }>;
                      };
                    }>;
                  };
                };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  contractTypeId: string;
                  valueCurrency: string;
                  timeZone: string;
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      numberingFormat: string;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      numberingFormat: string;
                    };
              };
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              statusOption: {
                __typename?: "ItemStatusOption";
                id: string;
                displayOrder: number;
                description: string;
                equatesToRemoved: boolean;
                style: ItemStatusOptionStyles;
                statusCollectionId: string;
                isInternal: boolean;
                status: ItemStatusOptionStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              };
              owner: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }
          | { __typename?: "RiskItem" }
          | {
              __typename: "VariationItem";
              id: string;
              number: string;
              regardingId: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                contract: {
                  __typename?: "Contract";
                  id: string;
                  timeZone: string;
                };
              };
            };
        auditTrails?: Array<{
          __typename?: "AuthorizationWorkflowAuditTrail";
          id: string;
          auditId: string;
          regardingProductItemId: string;
          regardingItemHistoryId?: string | null;
          regardingProductType: ProductType;
          productInstanceId: string;
          actionId: string;
          status: AuthorizationActionStatus;
          denialReason?: string | null;
          dateCreated: any;
          creatorId: string;
          productInstance?: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            contractId: string;
            productId: string;
            productSchemaId: string;
            statusCollectionId: string;
            status: ProductInstanceStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contract: {
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              number: string;
              valueCurrency: string;
              value: number;
              startDate?: any | null;
              endDate?: any | null;
              contractTypeId: string;
              projectId: string;
              country: string;
              province?: string | null;
              timeZone: string;
              coordinatesLatitude?: number | null;
              coordinatesLongitude?: number | null;
              status: ContractStatus;
              dateCreated: any;
              creatorId: string;
              sections: Array<{
                __typename?: "ContractSection";
                id: string;
                number?: number | null;
                description: string;
                completionDate: any;
                status: ContractSectionStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
              keyDates: Array<{
                __typename?: "ContractKeyDate";
                id: string;
                number?: number | null;
                conditionToBeMet: string;
                keyDate: any;
                status: ContractKeyDateStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                status: ProjectStatus;
              };
              contractType: {
                __typename?: "ContractType";
                id: string;
                description: string;
                subType?: string | null;
                version?: string | null;
                status: ContractTypeStatus;
              };
            };
            product:
              | {
                  __typename?: "Claims";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Events";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Instructions";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  name: string;
                  numberingFormat: string;
                  soloModeSupported: boolean;
                  allowMultipleInstances: boolean;
                  status: ProductStatus;
                };
            statusCollection: {
              __typename?: "ItemStatusCollection";
              id: string;
              name: string;
              isDefault: boolean;
              status: ItemStatusCollectionStatus;
            };
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }> | null;
      } | null;
      noticeAnswers?: {
        __typename?: "CompEventHistoryNoticeAnswers";
        claimTypeId: string;
        earlyWarningId?: string | null;
        claimType: {
          __typename?: "ClaimTypeInfo";
          id: string;
          clause: string;
          description: string;
        };
        earlyWarning?: {
          __typename?: "EarlyWarningItem";
          id: string;
          title: string;
          severity: string;
          number: string;
          productInstanceId: string;
          ownerId: string;
          statusOptionId: string;
          dateCreated: any;
          creatorId: string;
          dateModified: any;
          offline: boolean;
          givenById?: string | null;
          dateSent: any;
        } | null;
      } | null;
      confirmationAnswers?: {
        __typename?: "CompEventHistoryConfirmationAnswers";
        assumptions?: string | null;
        ewCouldveBeenGiven: boolean;
      } | null;
      rejectionAnswers?: {
        __typename?: "CompEventHistoryRejectionAnswers";
        reason: string;
      } | null;
      noticeRequestQuotationAnswers?: {
        __typename?: "CompEventHistoryNoticeRequestQuotationAnswers";
        claimTypeId: string;
        assumptions?: string | null;
        claimType: {
          __typename?: "ClaimTypeInfo";
          id: string;
          clause: string;
          description: string;
        };
      } | null;
      quotationRevisionRequestAnswers?: {
        __typename?: "CompEventHistoryQuotationRevisionRequestAnswers";
        reason: string;
      } | null;
      quotationAcceptanceAnswers?: {
        __typename?: "CompEventHistoryQuotationAcceptanceAnswers";
        acceptedQuotationId: string;
        acceptedQuotation: {
          __typename?: "CompEventQuotation";
          id: string;
          compEventId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          accepted?: boolean | null;
          revision: number;
          dateCreated: any;
          creatorId: string;
          keyDatesChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          sectionalChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              company: { __typename?: "Company"; registeredName: string };
            };
          }>;
        };
      } | null;
      noticeOfSilenceCompEventNoticeAnswers?: {
        __typename?: "CompEventHistoryNoticeOfSilenceCompEventNoticeAnswers";
        remarks?: string | null;
        deemedAcceptedDate: any;
      } | null;
      noticeOfSilenceQuotationAnswers?: {
        __typename?: "CompEventHistoryNoticeOfSilenceQuotationAnswers";
        acceptedQuotationId: string;
        remarks?: string | null;
        deemedAcceptedDate: any;
        acceptedQuotation: {
          __typename?: "CompEventQuotation";
          id: string;
          compEventId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          accepted?: boolean | null;
          revision: number;
          dateCreated: any;
          creatorId: string;
          keyDatesChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          sectionalChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              company: { __typename?: "Company"; registeredName: string };
            };
          }>;
        };
      } | null;
      noticeOfSilenceOwnAssessmentAnswers?: {
        __typename?: "CompEventHistoryNoticeOfSilenceOwnAssessmentAnswers";
        acceptedQuotationId: string;
        remarks?: string | null;
        deemedAcceptedDate: any;
        acceptedQuotation: {
          __typename?: "CompEventQuotation";
          id: string;
          compEventId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          accepted?: boolean | null;
          revision: number;
          dateCreated: any;
          creatorId: string;
          keyDatesChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          sectionalChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              company: { __typename?: "Company"; registeredName: string };
            };
          }>;
        };
      } | null;
      noticeProposedInstructionAnswers?: {
        __typename?: "CompEventHistoryNoticeProposedInstructionAnswers";
        claimTypeId: string;
        assumptions?: string | null;
        claimType: {
          __typename?: "ClaimTypeInfo";
          id: string;
          clause: string;
          description: string;
        };
      } | null;
      abandonInstructionAnswers?: {
        __typename?: "CompEventHistoryAbandonInstructionAnswers";
        remarks: string;
      } | null;
      quotationAnswers?: {
        __typename?: "CompEventHistoryQuotationAnswers";
        preferredQuotationId: string;
        preferredQuotation: {
          __typename?: "CompEventQuotation";
          id: string;
          compEventId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          accepted?: boolean | null;
          revision: number;
          dateCreated: any;
          creatorId: string;
          keyDatesChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          sectionalChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              company: { __typename?: "Company"; registeredName: string };
            };
          }>;
        };
      } | null;
      ownAssessmentNoticeAnswers?: {
        __typename?: "CompEventHistoryOwnAssessmentNoticeAnswers";
        reason: string;
      } | null;
      ownAssessmentAnswers?: {
        __typename?: "CompEventHistoryOwnAssessmentAnswers";
        draftAssessmentId?: string | null;
        assessmentId?: string | null;
        draftAssessment?: {
          __typename?: "DraftAssessment";
          id: string;
          compEventId: string;
          reference: string;
          details: string;
          price: number;
          time: number;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          keyDatesChanges: Array<{
            __typename?: "ContractTimeImpact";
            id: string;
            days: number;
          } | null>;
          sectionalChanges: Array<{
            __typename?: "ContractTimeImpact";
            id: string;
            days: number;
          } | null>;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
              company: {
                __typename?: "Company";
                id: string;
                registeredName: string;
                tradingName: string;
              };
            };
          }>;
        } | null;
        assessment?: {
          __typename?: "CompEventAssessment";
          id: string;
          compEventId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          revision: number;
          dateCreated: any;
          creatorId: string;
          keyDatesChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          sectionalChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              company: { __typename?: "Company"; registeredName: string };
            };
          }>;
        } | null;
      } | null;
      requestForMoreTimeAnswers?: {
        __typename?: "CompEventHistoryRequestForMoreTimeAnswers";
        requestedDays: number;
        remarks?: string | null;
      } | null;
      grantingOfMoreTimeAnswers?: {
        __typename?: "CompEventHistoryGrantingOfMoreTimeAnswers";
        grantedDays: number;
        remarks?: string | null;
      } | null;
      denyingOfMoreTimeAnswers?: {
        __typename?: "CompEventHistoryDenyingOfMoreTimeAnswers";
        remarks?: string | null;
      } | null;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
      recipients: Array<{
        __typename?: "CompEventRecipient";
        userId: string;
        name: string;
        preference: {
          __typename?: "UserPreferencesValue";
          inApp: boolean;
          email: boolean;
        };
      }>;
      sentByUser: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      sentByParty?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
    }>;
  };
};

export type CompEventItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type CompEventItemsQuery = {
  __typename?: "Query";
  compEventItems: {
    __typename?: "CompEventItemList";
    items: Array<{
      __typename: "CompEventItem";
      id: string;
      number: string;
      productInstanceId: string;
      regardingId: string;
      regardingType: CompEventRegardingType;
      status: CompEventStatus;
      price?: number | null;
      time?: number | null;
      earlyWarningId?: string | null;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      deemingDate?: any | null;
      lastAction: {
        __typename?: "CompEventItemLastAction";
        actionType: CompEventActionType;
        daysLate?: number | null;
        date: any;
      };
      nextStep?: {
        __typename?: "CompEventItemNextStep";
        actionType: CompEventActionType;
        dueDate: any;
      } | null;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        status: ProductInstanceStatus;
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
        contract: {
          __typename?: "Contract";
          id: string;
          name: string;
          friendlyName: string;
          status: ContractStatus;
          timeZone: string;
          valueCurrency: string;
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            status: ProjectStatus;
          };
        };
      };
      earlyWarning?: {
        __typename?: "EarlyWarningItem";
        id: string;
        title: string;
        dateSent: any;
        number: string;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          product:
            | { __typename?: "Claims"; id: string }
            | { __typename?: "CompEvents"; id: string }
            | { __typename?: "DailyDiary"; id: string }
            | { __typename?: "EarlyWarnings"; id: string }
            | { __typename?: "Events"; id: string }
            | { __typename?: "Instructions"; id: string }
            | { __typename?: "RisksRegister"; id: string }
            | { __typename?: "Variations"; id: string };
        };
      } | null;
      regardingEvent?: {
        __typename?: "EventItem";
        id: string;
        title: string;
        productInstanceId: string;
        number: string;
        data: {
          __typename?: "ItemData";
          sections: Array<{
            __typename?: "ItemDataSection";
            name: string;
            entries: Array<{
              __typename?: "ItemDataEntry";
              name: string;
              value: string;
            }>;
          }>;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          product:
            | { __typename?: "Claims"; id: string }
            | { __typename?: "CompEvents"; id: string }
            | { __typename?: "DailyDiary"; id: string }
            | { __typename?: "EarlyWarnings"; id: string }
            | { __typename?: "Events"; id: string }
            | { __typename?: "Instructions"; id: string }
            | { __typename?: "RisksRegister"; id: string }
            | { __typename?: "Variations"; id: string };
        };
      } | null;
      regardingInstruction?: {
        __typename?: "InstructionItem";
        id: string;
        title: string;
        number: string;
        productInstanceId: string;
        data: {
          __typename?: "ItemData";
          sections: Array<{
            __typename?: "ItemDataSection";
            name: string;
            entries: Array<{
              __typename?: "ItemDataEntry";
              name: string;
              value: string;
            }>;
          }>;
        };
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          product:
            | { __typename?: "Claims"; id: string }
            | { __typename?: "CompEvents"; id: string }
            | { __typename?: "DailyDiary"; id: string }
            | { __typename?: "EarlyWarnings"; id: string }
            | { __typename?: "Events"; id: string }
            | { __typename?: "Instructions"; id: string }
            | { __typename?: "RisksRegister"; id: string }
            | { __typename?: "Variations"; id: string };
        };
      } | null;
      notifiedByParty: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      };
    }>;
  };
};

export type CompEventNoticeOfSilenceCompEventNoticePromptQueryVariables =
  Exact<{
    input: CompEventNoticeOfSilenceCompEventNoticePromptInput;
  }>;

export type CompEventNoticeOfSilenceCompEventNoticePromptQuery = {
  __typename?: "Query";
  compEventNoticeOfSilenceCompEventNoticePrompt: {
    __typename?: "CompEventNoticeOfSilenceCompEventNoticePrompt";
    remarks?: string | null;
  };
};

export type CompEventNoticeOfSilenceOwnAssessmentPromptQueryVariables = Exact<{
  input: CompEventNoticeOfSilenceOwnAssessmentPromptInput;
}>;

export type CompEventNoticeOfSilenceOwnAssessmentPromptQuery = {
  __typename?: "Query";
  compEventNoticeOfSilenceOwnAssessmentPrompt: {
    __typename?: "CompEventNoticeOfSilenceOwnAssessmentPrompt";
    remarks?: string | null;
    quotations: Array<{
      __typename?: "CompEventQuotation";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      currency: string;
      time: number;
      accepted?: boolean | null;
      revision: number;
      dateCreated: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      sectionalChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          company: { __typename?: "Company"; registeredName: string };
        };
      }>;
    }>;
  };
};

export type CompEventNoticeOfSilenceQuotationPromptQueryVariables = Exact<{
  input: CompEventNoticeOfSilenceQuotationPromptInput;
}>;

export type CompEventNoticeOfSilenceQuotationPromptQuery = {
  __typename?: "Query";
  compEventNoticeOfSilenceQuotationPrompt: {
    __typename?: "CompEventNoticeOfSilenceQuotationPrompt";
    remarks?: string | null;
    quotations: Array<{
      __typename?: "CompEventQuotation";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      currency: string;
      time: number;
      accepted?: boolean | null;
      revision: number;
      dateCreated: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      sectionalChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          company: { __typename?: "Company"; registeredName: string };
        };
      }>;
    }>;
  };
};

export type CompEventNoticePromptQueryVariables = Exact<{
  input: CompEventNoticePromptInput;
}>;

export type CompEventNoticePromptQuery = {
  __typename?: "Query";
  compEventNoticePrompt: {
    __typename?: "CompEventNoticePrompt";
    compEventTypes: Array<{
      __typename?: "ListItem";
      id: string;
      name: string;
    }>;
    earlyWarnings: Array<{ __typename?: "ListItem"; id: string; name: string }>;
  };
};

export type CompEventNoticeRequestQuotationPromptQueryVariables = Exact<{
  input: CompEventNoticeRequestQuotationPromptInput;
}>;

export type CompEventNoticeRequestQuotationPromptQuery = {
  __typename?: "Query";
  compEventNoticeRequestQuotationPrompt: {
    __typename?: "CompEventNoticeRequestQuotationPrompt";
    assumptions?: string | null;
    compEventTypes: Array<{
      __typename?: "ListItem";
      id: string;
      name: string;
    }>;
  };
};

export type CompEventOwnAssessmentNoticePromptQueryVariables = Exact<{
  input: CompEventOwnAssessmentNoticePromptInput;
}>;

export type CompEventOwnAssessmentNoticePromptQuery = {
  __typename?: "Query";
  compEventOwnAssessmentNoticePrompt: {
    __typename?: "CompEventOwnAssessmentNoticePrompt";
    reason: string;
  };
};

export type CompEventOwnAssessmentPromptQueryVariables = Exact<{
  input: CompEventOwnAssessmentPromptInput;
}>;

export type CompEventOwnAssessmentPromptQuery = {
  __typename?: "Query";
  compEventOwnAssessmentPrompt: {
    __typename?: "CompEventOwnAssessmentPrompt";
    draftAssessments: Array<{
      __typename?: "DraftAssessment";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    }>;
  };
};

export type CompEventQuotationAcceptancePromptQueryVariables = Exact<{
  input: CompEventQuotationAcceptancePromptInput;
}>;

export type CompEventQuotationAcceptancePromptQuery = {
  __typename?: "Query";
  compEventQuotationAcceptancePrompt: {
    __typename?: "CompEventQuotationAcceptancePrompt";
    quotations: Array<{
      __typename?: "CompEventQuotation";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      currency: string;
      time: number;
      accepted?: boolean | null;
      revision: number;
      dateCreated: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      sectionalChanges: Array<{
        __typename?: "CompEventQuotationTimeChange";
        number?: number | null;
        description: string;
        days: number;
      }>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          company: { __typename?: "Company"; registeredName: string };
        };
      }>;
    }>;
  };
};

export type CompEventQuotationPromptQueryVariables = Exact<{
  input: CompEventQuotationPromptInput;
}>;

export type CompEventQuotationPromptQuery = {
  __typename?: "Query";
  compEventQuotationPrompt: {
    __typename?: "CompEventQuotationPrompt";
    draftQuotations: Array<{
      __typename?: "DraftQuotation";
      id: string;
      compEventId: string;
      reference: string;
      details: string;
      price: number;
      time: number;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      keyDatesChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      sectionalChanges: Array<{
        __typename?: "ContractTimeImpact";
        id: string;
        days: number;
      } | null>;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }>;
    }>;
  };
};

export type CompEventQuotationAssessmentsQueryVariables = Exact<{
  compEventId: Scalars["ID"];
}>;

export type CompEventQuotationAssessmentsQuery = {
  __typename?: "Query";
  compEventQuotationAssessments: {
    __typename?: "CompEventQuotationAssessmentList";
    items: Array<
      | {
          __typename?: "CompEventAssessment";
          id: string;
          compEventId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          revision: number;
          dateCreated: any;
          creatorId: string;
          keyDatesChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          sectionalChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              company: { __typename?: "Company"; registeredName: string };
            };
          }>;
        }
      | {
          __typename?: "CompEventQuotation";
          id: string;
          compEventId: string;
          reference: string;
          details: string;
          price: number;
          currency: string;
          time: number;
          accepted?: boolean | null;
          revision: number;
          dateCreated: any;
          creatorId: string;
          keyDatesChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          sectionalChanges: Array<{
            __typename?: "CompEventQuotationTimeChange";
            number?: number | null;
            description: string;
            days: number;
          }>;
          attachments: Array<{
            __typename?: "Attachment";
            id: string;
            fileName: string;
            fileUrl: string;
            mimeType: string;
            description?: string | null;
            locked: boolean;
            fileSize: number;
            status: AttachmentStatus;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              company: { __typename?: "Company"; registeredName: string };
            };
          }>;
        }
    >;
  };
};

export type CompEventRequestForMoreTimePromptQueryVariables = Exact<{
  input: CompEventRequestForMoreTimePromptInput;
}>;

export type CompEventRequestForMoreTimePromptQuery = {
  __typename?: "Query";
  compEventRequestForMoreTimePrompt: {
    __typename?: "CompEventRequestForMoreTimePrompt";
    requestedDays: number;
    remarks?: string | null;
  };
};

export type CompaniesQueryVariables = Exact<{
  lite: Scalars["Boolean"];
}>;

export type CompaniesQuery = {
  __typename?: "Query";
  companies: {
    __typename?: "CompanyList";
    items: Array<{
      __typename?: "Company";
      id: string;
      type?: CompanyType | null;
      registeredName: string;
      tradingName: string;
      registrationNumber?: string | null;
      logo?: string | null;
      vatRegistrationNumber?: string | null;
      status: CompanyStatus;
      creatorId: string;
      dateCreated: any;
      contracts?: {
        __typename?: "ContractList";
        items: Array<{ __typename?: "Contract"; id: string }>;
      };
      physicalAddress?: {
        __typename?: "Address";
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        provinceState?: string | null;
        country: string;
        code?: string | null;
      } | null;
      postalAddress?: {
        __typename?: "Address";
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        provinceState?: string | null;
        country: string;
        code?: string | null;
      } | null;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type GetCompanyLiteByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
}>;

export type GetCompanyLiteByIdQuery = {
  __typename?: "Query";
  company?: {
    __typename?: "Company";
    id: string;
    registeredName: string;
    tradingName: string;
  } | null;
};

export type CompanyLookupCollectionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CompanyLookupCollectionQuery = {
  __typename?: "Query";
  companyLookupCollection: {
    __typename?: "CompanyLookupCollection";
    id: string;
    companyId: string;
    name: string;
    optionsLockedForUsers: boolean;
    optionsCharLengthLimit?: number | null;
    usage: CompanyLookupCollectionUsage;
    status: CompanyLookupCollectionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    options: {
      __typename?: "LookupOptionList";
      items: Array<{
        __typename?: "LookupOption";
        id: string;
        value: string;
        displayOrder: number;
        collectionId: string;
        isInternal: boolean;
        status: LookupOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
  };
};

export type CompanyLookupCollectionsQueryVariables = Exact<{
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type CompanyLookupCollectionsQuery = {
  __typename?: "Query";
  companyLookupCollections: {
    __typename?: "CompanyLookupCollectionList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "CompanyLookupCollection";
      id: string;
      companyId: string;
      name: string;
      optionsLockedForUsers: boolean;
      optionsCharLengthLimit?: number | null;
      usage: CompanyLookupCollectionUsage;
      status: CompanyLookupCollectionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      options: {
        __typename?: "LookupOptionList";
        items: Array<{
          __typename?: "LookupOption";
          id: string;
          value: string;
          displayOrder: number;
          collectionId: string;
          isInternal: boolean;
          status: LookupOptionStatus;
          creatorId: string;
          dateCreated: any;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
      };
    }>;
  };
};

export type GetCompanyUsersQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
}>;

export type GetCompanyUsersQuery = {
  __typename?: "Query";
  company?: {
    __typename?: "Company";
    id: string;
    users: {
      __typename?: "UserList";
      items: Array<{
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        companyId: string;
        registered?: boolean | null;
        status: UserStatus;
        lastActive?: any | null;
        dateInvited: any;
      }>;
    };
  } | null;
};

export type ContractBindingTypesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ContractBindingTypesQuery = {
  __typename?: "Query";
  contract: {
    __typename?: "Contract";
    id: string;
    contractType: {
      __typename?: "ContractType";
      id: string;
      description: string;
      subType?: string | null;
      version?: string | null;
      status: ContractTypeStatus;
      contractBindingTypes: {
        __typename?: "ContractBindingTypeList";
        items: Array<{
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        }>;
      };
    };
  };
};

export type ContractLiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ContractLiteQuery = {
  __typename?: "Query";
  contract: {
    __typename?: "Contract";
    id: string;
    name: string;
    friendlyName: string;
    contractTypeId: string;
    timeZone: string;
    valueCurrency: string;
    contractType: {
      __typename?: "ContractType";
      description: string;
      subType?: string | null;
      version?: string | null;
      id: string;
    };
  };
};

export type ContractTypeQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ContractTypeQuery = {
  __typename?: "Query";
  contractType: {
    __typename?: "ContractType";
    id: string;
    description: string;
    subType?: string | null;
    version?: string | null;
    status: ContractTypeStatus;
    contractBindingTypes: {
      __typename?: "ContractBindingTypeList";
      items: Array<{
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      }>;
    };
  };
};

export type ClaimTypesQueryVariables = Exact<{
  contractTypeId: Scalars["ID"];
}>;

export type ClaimTypesQuery = {
  __typename?: "Query";
  claimTypes: {
    __typename?: "ClaimTypeList";
    items: Array<{
      __typename?: "ClaimType";
      id: string;
      contractTypeId: string;
      clause: string;
      description: string;
      isInternal: boolean;
      status: ClaimTypeStatus;
      dateCreated: any;
      creatorId: string;
      contractType: {
        __typename?: "ContractType";
        id: string;
        description: string;
        subType?: string | null;
        version?: string | null;
        status: ContractTypeStatus;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type ContractTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ContractTypesQuery = {
  __typename?: "Query";
  contractTypes: {
    __typename?: "ContractTypeList";
    items: Array<{
      __typename?: "ContractType";
      id: string;
      description: string;
      subType?: string | null;
      version?: string | null;
      status: ContractTypeStatus;
    }>;
  };
};

export type ContractTypesWithBindingTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ContractTypesWithBindingTypesQuery = {
  __typename?: "Query";
  contractTypes: {
    __typename?: "ContractTypeList";
    items: Array<{
      __typename?: "ContractType";
      id: string;
      description: string;
      subType?: string | null;
      version?: string | null;
      status: ContractTypeStatus;
      contractBindingTypes: {
        __typename?: "ContractBindingTypeList";
        items: Array<{
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        }>;
      };
    }>;
  };
};

export type DailyDiaryItemByDateQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
  date: Scalars["AWSDate"];
}>;

export type DailyDiaryItemByDateQuery = {
  __typename?: "Query";
  dailyDiaryItemByDate?:
    | {
        __typename: "DailyDiaryItem";
        id: string;
        productInstanceId: string;
        date: any;
        number: string;
        status: DailyDiaryItemStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        sentById?: string | null;
        dateSent?: any | null;
        revisions: Array<{
          __typename?: "DailyDiaryItemRevision";
          id: string;
          number: string;
          sentById?: string | null;
          dateSent?: any | null;
          status: DailyDiaryItemStatus;
          authorizationWorkflowAudit?: {
            __typename?: "AuthorizationWorkflowAudit";
            id: string;
            initiatedById: string;
            regardingProductItemId: string;
            regardingProductType: ProductType;
            regardingItemHistoryId?: string | null;
            workflowId: string;
            actionId: string;
            status: AuthorizationWorkflowAuditStatus;
            dateCreated: any;
            dateInitiated: any;
            initiatedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            workflow: {
              __typename?: "AuthorizationWorkflow";
              id: string;
              name: string;
              status: AuthorizationWorkflowStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              modifiedById: string;
              projectId: string;
              levels: Array<{
                __typename?: "AuthorizationWorkflowLevel";
                sequence: number;
                mode: AuthorizationWorkflowLevelMode;
                userIds: Array<string>;
                users: {
                  __typename?: "UserList";
                  items: Array<{
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    jobTitle: string;
                    country: string;
                    mobileNumber?: any | null;
                    alternateNumber?: any | null;
                    dateOfBirth?: any | null;
                    profilePicture?: string | null;
                    email: any;
                    companyId: string;
                    registered?: boolean | null;
                    status: UserStatus;
                    lastActive?: any | null;
                    dateInvited: any;
                    registeredDate?: any | null;
                    company: {
                      __typename?: "Company";
                      id: string;
                      tradingName: string;
                      registeredName: string;
                    };
                    invitedBy: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                  }>;
                };
              }>;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              modifiedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                number?: string | null;
                sector: ProjectSector;
                description?: string | null;
                status: ProjectStatus;
                dateCreated: any;
                creatorId: string;
                billingCompanyId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                contracts: {
                  __typename?: "ContractList";
                  items: Array<{
                    __typename?: "Contract";
                    id: string;
                    name: string;
                    friendlyName: string;
                    status: ContractStatus;
                  }>;
                };
                billingCompany: {
                  __typename?: "Company";
                  id: string;
                  type?: CompanyType | null;
                  registeredName: string;
                  tradingName: string;
                  registrationNumber?: string | null;
                  logo?: string | null;
                  vatRegistrationNumber?: string | null;
                  status: CompanyStatus;
                  creatorId: string;
                  dateCreated: any;
                  physicalAddress?: {
                    __typename?: "Address";
                    line1?: string | null;
                    line2?: string | null;
                    city?: string | null;
                    provinceState?: string | null;
                    country: string;
                    code?: string | null;
                  } | null;
                  postalAddress?: {
                    __typename?: "Address";
                    line1?: string | null;
                    line2?: string | null;
                    city?: string | null;
                    provinceState?: string | null;
                    country: string;
                    code?: string | null;
                  } | null;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                };
              };
            };
            action: {
              __typename?: "AuthorizationWorkflowActionMapping";
              id: string;
              productOutputActionId: string;
              workflowId: string;
              partyId?: string | null;
              productInstanceId: string;
              status: AuthorizationWorkflowActionMappingStatus;
              dateCreated: any;
              creatorId: string;
              modifiedById: string;
              dateModified: any;
              productOutputAction: {
                __typename?: "ProductOutputAction";
                id: string;
                name: string;
                productId: string;
                isInternal: boolean;
                status: ProductOutputActionStatus;
                dateCreated: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    };
              };
              workflow: {
                __typename?: "AuthorizationWorkflow";
                id: string;
                name: string;
                status: AuthorizationWorkflowStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                modifiedById: string;
                projectId: string;
                levels: Array<{
                  __typename?: "AuthorizationWorkflowLevel";
                  sequence: number;
                  mode: AuthorizationWorkflowLevelMode;
                  userIds: Array<string>;
                  users: {
                    __typename?: "UserList";
                    items: Array<{
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      jobTitle: string;
                      country: string;
                      mobileNumber?: any | null;
                      alternateNumber?: any | null;
                      dateOfBirth?: any | null;
                      profilePicture?: string | null;
                      email: any;
                      companyId: string;
                      registered?: boolean | null;
                      status: UserStatus;
                      lastActive?: any | null;
                      dateInvited: any;
                      registeredDate?: any | null;
                      company: {
                        __typename?: "Company";
                        id: string;
                        tradingName: string;
                        registeredName: string;
                      };
                      invitedBy: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                    }>;
                  };
                }>;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                modifiedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  number?: string | null;
                  sector: ProjectSector;
                  description?: string | null;
                  status: ProjectStatus;
                  dateCreated: any;
                  creatorId: string;
                  billingCompanyId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  contracts: {
                    __typename?: "ContractList";
                    items: Array<{
                      __typename?: "Contract";
                      id: string;
                      name: string;
                      friendlyName: string;
                      status: ContractStatus;
                    }>;
                  };
                  billingCompany: {
                    __typename?: "Company";
                    id: string;
                    type?: CompanyType | null;
                    registeredName: string;
                    tradingName: string;
                    registrationNumber?: string | null;
                    logo?: string | null;
                    vatRegistrationNumber?: string | null;
                    status: CompanyStatus;
                    creatorId: string;
                    dateCreated: any;
                    physicalAddress?: {
                      __typename?: "Address";
                      line1?: string | null;
                      line2?: string | null;
                      city?: string | null;
                      provinceState?: string | null;
                      country: string;
                      code?: string | null;
                    } | null;
                    postalAddress?: {
                      __typename?: "Address";
                      line1?: string | null;
                      line2?: string | null;
                      city?: string | null;
                      provinceState?: string | null;
                      country: string;
                      code?: string | null;
                    } | null;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                  };
                };
              };
              party?: {
                __typename?: "ContractBindingType";
                id: string;
                description: string;
                contractTypeId: string;
                status: ContractBindingTypeStatus;
              } | null;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                contractId: string;
                product:
                  | { __typename?: "Claims"; id: string; name: string }
                  | { __typename?: "CompEvents"; id: string; name: string }
                  | { __typename?: "DailyDiary"; id: string; name: string }
                  | { __typename?: "EarlyWarnings"; id: string; name: string }
                  | { __typename?: "Events"; id: string; name: string }
                  | { __typename?: "Instructions"; id: string; name: string }
                  | { __typename?: "RisksRegister"; id: string; name: string }
                  | { __typename?: "Variations"; id: string; name: string };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  friendlyName: string;
                  timeZone: string;
                  valueCurrency: string;
                  project: {
                    __typename?: "Project";
                    id: string;
                    friendlyName: string;
                  };
                };
              };
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              modifiedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
            regardingProductItem:
              | {
                  __typename: "ClaimItem";
                  id: string;
                  number: string;
                  regardingId: string;
                  claimRegardingType: ClaimRegardingType;
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      timeZone: string;
                    };
                  };
                }
              | {
                  __typename: "CompEventItem";
                  id: string;
                  number: string;
                  productInstanceId: string;
                  regardingId: string;
                  regardingType: CompEventRegardingType;
                  status: CompEventStatus;
                  price?: number | null;
                  time?: number | null;
                  earlyWarningId?: string | null;
                  dateCreated: any;
                  dateModified: any;
                  creatorId: string;
                  deemingDate?: any | null;
                  lastAction: {
                    __typename?: "CompEventItemLastAction";
                    actionType: CompEventActionType;
                    daysLate?: number | null;
                    date: any;
                  };
                  nextStep?: {
                    __typename?: "CompEventItemNextStep";
                    actionType: CompEventActionType;
                    dueDate: any;
                  } | null;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    status: ProductInstanceStatus;
                    product:
                      | { __typename?: "Claims"; id: string; name: string }
                      | { __typename?: "CompEvents"; id: string; name: string }
                      | { __typename?: "DailyDiary"; id: string; name: string }
                      | {
                          __typename?: "EarlyWarnings";
                          id: string;
                          name: string;
                        }
                      | { __typename?: "Events"; id: string; name: string }
                      | {
                          __typename?: "Instructions";
                          id: string;
                          name: string;
                        }
                      | {
                          __typename?: "RisksRegister";
                          id: string;
                          name: string;
                        }
                      | { __typename?: "Variations"; id: string; name: string };
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      name: string;
                      friendlyName: string;
                      status: ContractStatus;
                      timeZone: string;
                      valueCurrency: string;
                      project: {
                        __typename?: "Project";
                        id: string;
                        name: string;
                        friendlyName: string;
                        status: ProjectStatus;
                      };
                    };
                  };
                  earlyWarning?: {
                    __typename?: "EarlyWarningItem";
                    id: string;
                    title: string;
                    dateSent: any;
                    number: string;
                    productInstance: {
                      __typename?: "ProductInstance";
                      id: string;
                      product:
                        | { __typename?: "Claims"; id: string }
                        | { __typename?: "CompEvents"; id: string }
                        | { __typename?: "DailyDiary"; id: string }
                        | { __typename?: "EarlyWarnings"; id: string }
                        | { __typename?: "Events"; id: string }
                        | { __typename?: "Instructions"; id: string }
                        | { __typename?: "RisksRegister"; id: string }
                        | { __typename?: "Variations"; id: string };
                    };
                  } | null;
                  regardingEvent?: {
                    __typename?: "EventItem";
                    id: string;
                    title: string;
                    productInstanceId: string;
                    number: string;
                    data: {
                      __typename?: "ItemData";
                      sections: Array<{
                        __typename?: "ItemDataSection";
                        name: string;
                        entries: Array<{
                          __typename?: "ItemDataEntry";
                          name: string;
                          value: string;
                        }>;
                      }>;
                    };
                    productInstance: {
                      __typename?: "ProductInstance";
                      id: string;
                      product:
                        | { __typename?: "Claims"; id: string }
                        | { __typename?: "CompEvents"; id: string }
                        | { __typename?: "DailyDiary"; id: string }
                        | { __typename?: "EarlyWarnings"; id: string }
                        | { __typename?: "Events"; id: string }
                        | { __typename?: "Instructions"; id: string }
                        | { __typename?: "RisksRegister"; id: string }
                        | { __typename?: "Variations"; id: string };
                    };
                  } | null;
                  regardingInstruction?: {
                    __typename?: "InstructionItem";
                    id: string;
                    title: string;
                    number: string;
                    productInstanceId: string;
                    data: {
                      __typename?: "ItemData";
                      sections: Array<{
                        __typename?: "ItemDataSection";
                        name: string;
                        entries: Array<{
                          __typename?: "ItemDataEntry";
                          name: string;
                          value: string;
                        }>;
                      }>;
                    };
                    productInstance: {
                      __typename?: "ProductInstance";
                      id: string;
                      product:
                        | { __typename?: "Claims"; id: string }
                        | { __typename?: "CompEvents"; id: string }
                        | { __typename?: "DailyDiary"; id: string }
                        | { __typename?: "EarlyWarnings"; id: string }
                        | { __typename?: "Events"; id: string }
                        | { __typename?: "Instructions"; id: string }
                        | { __typename?: "RisksRegister"; id: string }
                        | { __typename?: "Variations"; id: string };
                    };
                  } | null;
                  notifiedByParty: {
                    __typename?: "ContractBindingType";
                    id: string;
                    description: string;
                    contractTypeId: string;
                    status: ContractBindingTypeStatus;
                  };
                }
              | {
                  __typename?: "DailyDiaryItem";
                  id: string;
                  date: any;
                  number: string;
                  dateCreated: any;
                  dateModified: any;
                  creatorId: string;
                  sentById?: string | null;
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    status: ProductInstanceStatus;
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      name: string;
                      friendlyName: string;
                      timeZone: string;
                      number: string;
                      status: ContractStatus;
                      dateCreated: any;
                      project: {
                        __typename?: "Project";
                        id: string;
                        name: string;
                        friendlyName: string;
                        status: ProjectStatus;
                      };
                    };
                    product:
                      | {
                          __typename?: "Claims";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "CompEvents";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "DailyDiary";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "EarlyWarnings";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "Events";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "Instructions";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "RisksRegister";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "Variations";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        };
                  };
                }
              | {
                  __typename?: "EarlyWarningItem";
                  id: string;
                  title: string;
                  severity: string;
                  number: string;
                  productInstanceId: string;
                  ownerId: string;
                  statusOptionId: string;
                  dateCreated: any;
                  creatorId: string;
                  dateModified: any;
                  offline: boolean;
                  givenById?: string | null;
                  dateSent: any;
                  data: {
                    __typename?: "ItemData";
                    sections: Array<{
                      __typename?: "ItemDataSection";
                      name: string;
                      entries: Array<{
                        __typename?: "ItemDataEntry";
                        name: string;
                        value: string;
                      }>;
                    }>;
                  };
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  owner: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    productSchema: {
                      __typename?: "ProductSchema";
                      id: string;
                      name: string;
                      isDefault: boolean;
                      productId: string;
                      isInternal: boolean;
                      status: ProductSchemaStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      schemaSections: {
                        __typename?: "SchemaSectionList";
                        items: Array<{
                          __typename?: "SchemaSection";
                          id: string;
                          name: string;
                          displayText: string;
                          displayOrder: number;
                          columnPlacement: ColumnPlacementType;
                          productSchemaId: string;
                          isInternal: boolean;
                          status: SchemaSectionStatus;
                          dateCreated: any;
                          creatorId: string;
                          creator: {
                            __typename?: "User";
                            id: string;
                            firstname: string;
                            surname: string;
                            email: any;
                          };
                          schemaFields: {
                            __typename?: "SchemaFieldList";
                            items: Array<{
                              __typename?: "SchemaField";
                              id: string;
                              name: string;
                              schemaSectionId: string;
                              fieldTypeId: string;
                              displayText: string;
                              displayOrder: number;
                              isRequired: boolean;
                              isInternal: boolean;
                              complexTypeItemId?: string | null;
                              extraData?: string | null;
                              status: SchemaFieldStatus;
                              creatorId: string;
                              dateCreated: any;
                              creator: {
                                __typename?: "User";
                                id: string;
                                firstname: string;
                                surname: string;
                                email: any;
                              };
                              fieldType: {
                                __typename?: "FieldType";
                                id: string;
                                description: string;
                                shortCode: FieldTypeEnum;
                                status: FieldTypeStatus;
                              };
                            }>;
                          };
                        }>;
                      };
                    };
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      valueCurrency: string;
                      timeZone: string;
                    };
                    product:
                      | {
                          __typename?: "Claims";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "CompEvents";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "DailyDiary";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "EarlyWarnings";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Events";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Instructions";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "RisksRegister";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Variations";
                          id: string;
                          numberingFormat: string;
                        };
                  };
                  attachments?: Array<{
                    __typename?: "Attachment";
                    id: string;
                    fileName: string;
                    fileUrl: string;
                    mimeType: string;
                    description?: string | null;
                    locked: boolean;
                    fileSize: number;
                    status: AttachmentStatus;
                    dateCreated: any;
                    dateModified: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                      company: {
                        __typename?: "Company";
                        id: string;
                        registeredName: string;
                        tradingName: string;
                      };
                    };
                  }> | null;
                }
              | { __typename?: "EventItem" }
              | {
                  __typename?: "InstructionItem";
                  id: string;
                  title: string;
                  severity: string;
                  number: string;
                  productInstanceId: string;
                  ownerId: string;
                  statusOptionId: string;
                  dateCreated: any;
                  dateModified: any;
                  notifyDueDate: any;
                  creatorId: string;
                  offline: boolean;
                  givenById?: string | null;
                  dateSent: any;
                  attachments?: Array<{
                    __typename?: "Attachment";
                    id: string;
                    fileName: string;
                    fileUrl: string;
                    mimeType: string;
                    description?: string | null;
                    locked: boolean;
                    fileSize: number;
                    status: AttachmentStatus;
                    dateCreated: any;
                    dateModified: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                      company: {
                        __typename?: "Company";
                        id: string;
                        registeredName: string;
                        tradingName: string;
                      };
                    };
                  }> | null;
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    productSchema: {
                      __typename?: "ProductSchema";
                      id: string;
                      name: string;
                      isDefault: boolean;
                      productId: string;
                      isInternal: boolean;
                      status: ProductSchemaStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      schemaSections: {
                        __typename?: "SchemaSectionList";
                        items: Array<{
                          __typename?: "SchemaSection";
                          id: string;
                          name: string;
                          displayText: string;
                          displayOrder: number;
                          columnPlacement: ColumnPlacementType;
                          productSchemaId: string;
                          isInternal: boolean;
                          status: SchemaSectionStatus;
                          dateCreated: any;
                          creatorId: string;
                          creator: {
                            __typename?: "User";
                            id: string;
                            firstname: string;
                            surname: string;
                            email: any;
                          };
                          schemaFields: {
                            __typename?: "SchemaFieldList";
                            items: Array<{
                              __typename?: "SchemaField";
                              id: string;
                              name: string;
                              schemaSectionId: string;
                              fieldTypeId: string;
                              displayText: string;
                              displayOrder: number;
                              isRequired: boolean;
                              isInternal: boolean;
                              complexTypeItemId?: string | null;
                              extraData?: string | null;
                              status: SchemaFieldStatus;
                              creatorId: string;
                              dateCreated: any;
                              creator: {
                                __typename?: "User";
                                id: string;
                                firstname: string;
                                surname: string;
                                email: any;
                              };
                              fieldType: {
                                __typename?: "FieldType";
                                id: string;
                                description: string;
                                shortCode: FieldTypeEnum;
                                status: FieldTypeStatus;
                              };
                            }>;
                          };
                        }>;
                      };
                    };
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      contractTypeId: string;
                      valueCurrency: string;
                      timeZone: string;
                    };
                    product:
                      | {
                          __typename?: "Claims";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "CompEvents";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "DailyDiary";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "EarlyWarnings";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Events";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Instructions";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "RisksRegister";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Variations";
                          id: string;
                          numberingFormat: string;
                        };
                  };
                  data: {
                    __typename?: "ItemData";
                    sections: Array<{
                      __typename?: "ItemDataSection";
                      name: string;
                      entries: Array<{
                        __typename?: "ItemDataEntry";
                        name: string;
                        value: string;
                      }>;
                    }>;
                  };
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  statusOption: {
                    __typename?: "ItemStatusOption";
                    id: string;
                    displayOrder: number;
                    description: string;
                    equatesToRemoved: boolean;
                    style: ItemStatusOptionStyles;
                    statusCollectionId: string;
                    isInternal: boolean;
                    status: ItemStatusOptionStatus;
                    creatorId: string;
                    dateCreated: any;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                  };
                  owner: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                }
              | { __typename?: "RiskItem" }
              | {
                  __typename: "VariationItem";
                  id: string;
                  number: string;
                  regardingId: string;
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      timeZone: string;
                    };
                  };
                };
            auditTrails?: Array<{
              __typename?: "AuthorizationWorkflowAuditTrail";
              id: string;
              auditId: string;
              regardingProductItemId: string;
              regardingItemHistoryId?: string | null;
              regardingProductType: ProductType;
              productInstanceId: string;
              actionId: string;
              status: AuthorizationActionStatus;
              denialReason?: string | null;
              dateCreated: any;
              creatorId: string;
              productInstance?: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                contractId: string;
                productId: string;
                productSchemaId: string;
                statusCollectionId: string;
                status: ProductInstanceStatus;
                dateCreated: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  number: string;
                  valueCurrency: string;
                  value: number;
                  startDate?: any | null;
                  endDate?: any | null;
                  contractTypeId: string;
                  projectId: string;
                  country: string;
                  province?: string | null;
                  timeZone: string;
                  coordinatesLatitude?: number | null;
                  coordinatesLongitude?: number | null;
                  status: ContractStatus;
                  dateCreated: any;
                  creatorId: string;
                  sections: Array<{
                    __typename?: "ContractSection";
                    id: string;
                    number?: number | null;
                    description: string;
                    completionDate: any;
                    status: ContractSectionStatus;
                    dateCreated: any;
                    dateModified: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                  }>;
                  keyDates: Array<{
                    __typename?: "ContractKeyDate";
                    id: string;
                    number?: number | null;
                    conditionToBeMet: string;
                    keyDate: any;
                    status: ContractKeyDateStatus;
                    dateCreated: any;
                    dateModified: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                  }>;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  project: {
                    __typename?: "Project";
                    id: string;
                    name: string;
                    friendlyName: string;
                    status: ProjectStatus;
                  };
                  contractType: {
                    __typename?: "ContractType";
                    id: string;
                    description: string;
                    subType?: string | null;
                    version?: string | null;
                    status: ContractTypeStatus;
                  };
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    };
                statusCollection: {
                  __typename?: "ItemStatusCollection";
                  id: string;
                  name: string;
                  isDefault: boolean;
                  status: ItemStatusCollectionStatus;
                };
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }> | null;
          } | null;
          sentBy?: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          } | null;
        }>;
        latestRevision: {
          __typename?: "DailyDiaryItemRevision";
          id: string;
          number: string;
          sentById?: string | null;
          dateSent?: any | null;
          status: DailyDiaryItemStatus;
          authorizationWorkflowAudit?: {
            __typename?: "AuthorizationWorkflowAudit";
            id: string;
            initiatedById: string;
            regardingProductItemId: string;
            regardingProductType: ProductType;
            regardingItemHistoryId?: string | null;
            workflowId: string;
            actionId: string;
            status: AuthorizationWorkflowAuditStatus;
            dateCreated: any;
            dateInitiated: any;
            initiatedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            workflow: {
              __typename?: "AuthorizationWorkflow";
              id: string;
              name: string;
              status: AuthorizationWorkflowStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              modifiedById: string;
              projectId: string;
              levels: Array<{
                __typename?: "AuthorizationWorkflowLevel";
                sequence: number;
                mode: AuthorizationWorkflowLevelMode;
                userIds: Array<string>;
                users: {
                  __typename?: "UserList";
                  items: Array<{
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    jobTitle: string;
                    country: string;
                    mobileNumber?: any | null;
                    alternateNumber?: any | null;
                    dateOfBirth?: any | null;
                    profilePicture?: string | null;
                    email: any;
                    companyId: string;
                    registered?: boolean | null;
                    status: UserStatus;
                    lastActive?: any | null;
                    dateInvited: any;
                    registeredDate?: any | null;
                    company: {
                      __typename?: "Company";
                      id: string;
                      tradingName: string;
                      registeredName: string;
                    };
                    invitedBy: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                  }>;
                };
              }>;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              modifiedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              project: {
                __typename?: "Project";
                id: string;
                name: string;
                friendlyName: string;
                number?: string | null;
                sector: ProjectSector;
                description?: string | null;
                status: ProjectStatus;
                dateCreated: any;
                creatorId: string;
                billingCompanyId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                contracts: {
                  __typename?: "ContractList";
                  items: Array<{
                    __typename?: "Contract";
                    id: string;
                    name: string;
                    friendlyName: string;
                    status: ContractStatus;
                  }>;
                };
                billingCompany: {
                  __typename?: "Company";
                  id: string;
                  type?: CompanyType | null;
                  registeredName: string;
                  tradingName: string;
                  registrationNumber?: string | null;
                  logo?: string | null;
                  vatRegistrationNumber?: string | null;
                  status: CompanyStatus;
                  creatorId: string;
                  dateCreated: any;
                  physicalAddress?: {
                    __typename?: "Address";
                    line1?: string | null;
                    line2?: string | null;
                    city?: string | null;
                    provinceState?: string | null;
                    country: string;
                    code?: string | null;
                  } | null;
                  postalAddress?: {
                    __typename?: "Address";
                    line1?: string | null;
                    line2?: string | null;
                    city?: string | null;
                    provinceState?: string | null;
                    country: string;
                    code?: string | null;
                  } | null;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                };
              };
            };
            action: {
              __typename?: "AuthorizationWorkflowActionMapping";
              id: string;
              productOutputActionId: string;
              workflowId: string;
              partyId?: string | null;
              productInstanceId: string;
              status: AuthorizationWorkflowActionMappingStatus;
              dateCreated: any;
              creatorId: string;
              modifiedById: string;
              dateModified: any;
              productOutputAction: {
                __typename?: "ProductOutputAction";
                id: string;
                name: string;
                productId: string;
                isInternal: boolean;
                status: ProductOutputActionStatus;
                dateCreated: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    };
              };
              workflow: {
                __typename?: "AuthorizationWorkflow";
                id: string;
                name: string;
                status: AuthorizationWorkflowStatus;
                dateCreated: any;
                dateModified: any;
                creatorId: string;
                modifiedById: string;
                projectId: string;
                levels: Array<{
                  __typename?: "AuthorizationWorkflowLevel";
                  sequence: number;
                  mode: AuthorizationWorkflowLevelMode;
                  userIds: Array<string>;
                  users: {
                    __typename?: "UserList";
                    items: Array<{
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      jobTitle: string;
                      country: string;
                      mobileNumber?: any | null;
                      alternateNumber?: any | null;
                      dateOfBirth?: any | null;
                      profilePicture?: string | null;
                      email: any;
                      companyId: string;
                      registered?: boolean | null;
                      status: UserStatus;
                      lastActive?: any | null;
                      dateInvited: any;
                      registeredDate?: any | null;
                      company: {
                        __typename?: "Company";
                        id: string;
                        tradingName: string;
                        registeredName: string;
                      };
                      invitedBy: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                    }>;
                  };
                }>;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                modifiedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  number?: string | null;
                  sector: ProjectSector;
                  description?: string | null;
                  status: ProjectStatus;
                  dateCreated: any;
                  creatorId: string;
                  billingCompanyId: string;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  contracts: {
                    __typename?: "ContractList";
                    items: Array<{
                      __typename?: "Contract";
                      id: string;
                      name: string;
                      friendlyName: string;
                      status: ContractStatus;
                    }>;
                  };
                  billingCompany: {
                    __typename?: "Company";
                    id: string;
                    type?: CompanyType | null;
                    registeredName: string;
                    tradingName: string;
                    registrationNumber?: string | null;
                    logo?: string | null;
                    vatRegistrationNumber?: string | null;
                    status: CompanyStatus;
                    creatorId: string;
                    dateCreated: any;
                    physicalAddress?: {
                      __typename?: "Address";
                      line1?: string | null;
                      line2?: string | null;
                      city?: string | null;
                      provinceState?: string | null;
                      country: string;
                      code?: string | null;
                    } | null;
                    postalAddress?: {
                      __typename?: "Address";
                      line1?: string | null;
                      line2?: string | null;
                      city?: string | null;
                      provinceState?: string | null;
                      country: string;
                      code?: string | null;
                    } | null;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                  };
                };
              };
              party?: {
                __typename?: "ContractBindingType";
                id: string;
                description: string;
                contractTypeId: string;
                status: ContractBindingTypeStatus;
              } | null;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                contractId: string;
                product:
                  | { __typename?: "Claims"; id: string; name: string }
                  | { __typename?: "CompEvents"; id: string; name: string }
                  | { __typename?: "DailyDiary"; id: string; name: string }
                  | { __typename?: "EarlyWarnings"; id: string; name: string }
                  | { __typename?: "Events"; id: string; name: string }
                  | { __typename?: "Instructions"; id: string; name: string }
                  | { __typename?: "RisksRegister"; id: string; name: string }
                  | { __typename?: "Variations"; id: string; name: string };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  friendlyName: string;
                  timeZone: string;
                  valueCurrency: string;
                  project: {
                    __typename?: "Project";
                    id: string;
                    friendlyName: string;
                  };
                };
              };
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              modifiedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
            regardingProductItem:
              | {
                  __typename: "ClaimItem";
                  id: string;
                  number: string;
                  regardingId: string;
                  claimRegardingType: ClaimRegardingType;
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      timeZone: string;
                    };
                  };
                }
              | {
                  __typename: "CompEventItem";
                  id: string;
                  number: string;
                  productInstanceId: string;
                  regardingId: string;
                  regardingType: CompEventRegardingType;
                  status: CompEventStatus;
                  price?: number | null;
                  time?: number | null;
                  earlyWarningId?: string | null;
                  dateCreated: any;
                  dateModified: any;
                  creatorId: string;
                  deemingDate?: any | null;
                  lastAction: {
                    __typename?: "CompEventItemLastAction";
                    actionType: CompEventActionType;
                    daysLate?: number | null;
                    date: any;
                  };
                  nextStep?: {
                    __typename?: "CompEventItemNextStep";
                    actionType: CompEventActionType;
                    dueDate: any;
                  } | null;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    status: ProductInstanceStatus;
                    product:
                      | { __typename?: "Claims"; id: string; name: string }
                      | { __typename?: "CompEvents"; id: string; name: string }
                      | { __typename?: "DailyDiary"; id: string; name: string }
                      | {
                          __typename?: "EarlyWarnings";
                          id: string;
                          name: string;
                        }
                      | { __typename?: "Events"; id: string; name: string }
                      | {
                          __typename?: "Instructions";
                          id: string;
                          name: string;
                        }
                      | {
                          __typename?: "RisksRegister";
                          id: string;
                          name: string;
                        }
                      | { __typename?: "Variations"; id: string; name: string };
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      name: string;
                      friendlyName: string;
                      status: ContractStatus;
                      timeZone: string;
                      valueCurrency: string;
                      project: {
                        __typename?: "Project";
                        id: string;
                        name: string;
                        friendlyName: string;
                        status: ProjectStatus;
                      };
                    };
                  };
                  earlyWarning?: {
                    __typename?: "EarlyWarningItem";
                    id: string;
                    title: string;
                    dateSent: any;
                    number: string;
                    productInstance: {
                      __typename?: "ProductInstance";
                      id: string;
                      product:
                        | { __typename?: "Claims"; id: string }
                        | { __typename?: "CompEvents"; id: string }
                        | { __typename?: "DailyDiary"; id: string }
                        | { __typename?: "EarlyWarnings"; id: string }
                        | { __typename?: "Events"; id: string }
                        | { __typename?: "Instructions"; id: string }
                        | { __typename?: "RisksRegister"; id: string }
                        | { __typename?: "Variations"; id: string };
                    };
                  } | null;
                  regardingEvent?: {
                    __typename?: "EventItem";
                    id: string;
                    title: string;
                    productInstanceId: string;
                    number: string;
                    data: {
                      __typename?: "ItemData";
                      sections: Array<{
                        __typename?: "ItemDataSection";
                        name: string;
                        entries: Array<{
                          __typename?: "ItemDataEntry";
                          name: string;
                          value: string;
                        }>;
                      }>;
                    };
                    productInstance: {
                      __typename?: "ProductInstance";
                      id: string;
                      product:
                        | { __typename?: "Claims"; id: string }
                        | { __typename?: "CompEvents"; id: string }
                        | { __typename?: "DailyDiary"; id: string }
                        | { __typename?: "EarlyWarnings"; id: string }
                        | { __typename?: "Events"; id: string }
                        | { __typename?: "Instructions"; id: string }
                        | { __typename?: "RisksRegister"; id: string }
                        | { __typename?: "Variations"; id: string };
                    };
                  } | null;
                  regardingInstruction?: {
                    __typename?: "InstructionItem";
                    id: string;
                    title: string;
                    number: string;
                    productInstanceId: string;
                    data: {
                      __typename?: "ItemData";
                      sections: Array<{
                        __typename?: "ItemDataSection";
                        name: string;
                        entries: Array<{
                          __typename?: "ItemDataEntry";
                          name: string;
                          value: string;
                        }>;
                      }>;
                    };
                    productInstance: {
                      __typename?: "ProductInstance";
                      id: string;
                      product:
                        | { __typename?: "Claims"; id: string }
                        | { __typename?: "CompEvents"; id: string }
                        | { __typename?: "DailyDiary"; id: string }
                        | { __typename?: "EarlyWarnings"; id: string }
                        | { __typename?: "Events"; id: string }
                        | { __typename?: "Instructions"; id: string }
                        | { __typename?: "RisksRegister"; id: string }
                        | { __typename?: "Variations"; id: string };
                    };
                  } | null;
                  notifiedByParty: {
                    __typename?: "ContractBindingType";
                    id: string;
                    description: string;
                    contractTypeId: string;
                    status: ContractBindingTypeStatus;
                  };
                }
              | {
                  __typename?: "DailyDiaryItem";
                  id: string;
                  date: any;
                  number: string;
                  dateCreated: any;
                  dateModified: any;
                  creatorId: string;
                  sentById?: string | null;
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    status: ProductInstanceStatus;
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      name: string;
                      friendlyName: string;
                      timeZone: string;
                      number: string;
                      status: ContractStatus;
                      dateCreated: any;
                      project: {
                        __typename?: "Project";
                        id: string;
                        name: string;
                        friendlyName: string;
                        status: ProjectStatus;
                      };
                    };
                    product:
                      | {
                          __typename?: "Claims";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "CompEvents";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "DailyDiary";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "EarlyWarnings";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "Events";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "Instructions";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "RisksRegister";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        }
                      | {
                          __typename?: "Variations";
                          id: string;
                          name: string;
                          numberingFormat: string;
                          soloModeSupported: boolean;
                          allowMultipleInstances: boolean;
                          status: ProductStatus;
                        };
                  };
                }
              | {
                  __typename?: "EarlyWarningItem";
                  id: string;
                  title: string;
                  severity: string;
                  number: string;
                  productInstanceId: string;
                  ownerId: string;
                  statusOptionId: string;
                  dateCreated: any;
                  creatorId: string;
                  dateModified: any;
                  offline: boolean;
                  givenById?: string | null;
                  dateSent: any;
                  data: {
                    __typename?: "ItemData";
                    sections: Array<{
                      __typename?: "ItemDataSection";
                      name: string;
                      entries: Array<{
                        __typename?: "ItemDataEntry";
                        name: string;
                        value: string;
                      }>;
                    }>;
                  };
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  owner: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    productSchema: {
                      __typename?: "ProductSchema";
                      id: string;
                      name: string;
                      isDefault: boolean;
                      productId: string;
                      isInternal: boolean;
                      status: ProductSchemaStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      schemaSections: {
                        __typename?: "SchemaSectionList";
                        items: Array<{
                          __typename?: "SchemaSection";
                          id: string;
                          name: string;
                          displayText: string;
                          displayOrder: number;
                          columnPlacement: ColumnPlacementType;
                          productSchemaId: string;
                          isInternal: boolean;
                          status: SchemaSectionStatus;
                          dateCreated: any;
                          creatorId: string;
                          creator: {
                            __typename?: "User";
                            id: string;
                            firstname: string;
                            surname: string;
                            email: any;
                          };
                          schemaFields: {
                            __typename?: "SchemaFieldList";
                            items: Array<{
                              __typename?: "SchemaField";
                              id: string;
                              name: string;
                              schemaSectionId: string;
                              fieldTypeId: string;
                              displayText: string;
                              displayOrder: number;
                              isRequired: boolean;
                              isInternal: boolean;
                              complexTypeItemId?: string | null;
                              extraData?: string | null;
                              status: SchemaFieldStatus;
                              creatorId: string;
                              dateCreated: any;
                              creator: {
                                __typename?: "User";
                                id: string;
                                firstname: string;
                                surname: string;
                                email: any;
                              };
                              fieldType: {
                                __typename?: "FieldType";
                                id: string;
                                description: string;
                                shortCode: FieldTypeEnum;
                                status: FieldTypeStatus;
                              };
                            }>;
                          };
                        }>;
                      };
                    };
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      valueCurrency: string;
                      timeZone: string;
                    };
                    product:
                      | {
                          __typename?: "Claims";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "CompEvents";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "DailyDiary";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "EarlyWarnings";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Events";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Instructions";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "RisksRegister";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Variations";
                          id: string;
                          numberingFormat: string;
                        };
                  };
                  attachments?: Array<{
                    __typename?: "Attachment";
                    id: string;
                    fileName: string;
                    fileUrl: string;
                    mimeType: string;
                    description?: string | null;
                    locked: boolean;
                    fileSize: number;
                    status: AttachmentStatus;
                    dateCreated: any;
                    dateModified: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                      company: {
                        __typename?: "Company";
                        id: string;
                        registeredName: string;
                        tradingName: string;
                      };
                    };
                  }> | null;
                }
              | { __typename?: "EventItem" }
              | {
                  __typename?: "InstructionItem";
                  id: string;
                  title: string;
                  severity: string;
                  number: string;
                  productInstanceId: string;
                  ownerId: string;
                  statusOptionId: string;
                  dateCreated: any;
                  dateModified: any;
                  notifyDueDate: any;
                  creatorId: string;
                  offline: boolean;
                  givenById?: string | null;
                  dateSent: any;
                  attachments?: Array<{
                    __typename?: "Attachment";
                    id: string;
                    fileName: string;
                    fileUrl: string;
                    mimeType: string;
                    description?: string | null;
                    locked: boolean;
                    fileSize: number;
                    status: AttachmentStatus;
                    dateCreated: any;
                    dateModified: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                      company: {
                        __typename?: "Company";
                        id: string;
                        registeredName: string;
                        tradingName: string;
                      };
                    };
                  }> | null;
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    productSchema: {
                      __typename?: "ProductSchema";
                      id: string;
                      name: string;
                      isDefault: boolean;
                      productId: string;
                      isInternal: boolean;
                      status: ProductSchemaStatus;
                      creatorId: string;
                      dateCreated: any;
                      creator: {
                        __typename?: "User";
                        id: string;
                        firstname: string;
                        surname: string;
                        email: any;
                      };
                      schemaSections: {
                        __typename?: "SchemaSectionList";
                        items: Array<{
                          __typename?: "SchemaSection";
                          id: string;
                          name: string;
                          displayText: string;
                          displayOrder: number;
                          columnPlacement: ColumnPlacementType;
                          productSchemaId: string;
                          isInternal: boolean;
                          status: SchemaSectionStatus;
                          dateCreated: any;
                          creatorId: string;
                          creator: {
                            __typename?: "User";
                            id: string;
                            firstname: string;
                            surname: string;
                            email: any;
                          };
                          schemaFields: {
                            __typename?: "SchemaFieldList";
                            items: Array<{
                              __typename?: "SchemaField";
                              id: string;
                              name: string;
                              schemaSectionId: string;
                              fieldTypeId: string;
                              displayText: string;
                              displayOrder: number;
                              isRequired: boolean;
                              isInternal: boolean;
                              complexTypeItemId?: string | null;
                              extraData?: string | null;
                              status: SchemaFieldStatus;
                              creatorId: string;
                              dateCreated: any;
                              creator: {
                                __typename?: "User";
                                id: string;
                                firstname: string;
                                surname: string;
                                email: any;
                              };
                              fieldType: {
                                __typename?: "FieldType";
                                id: string;
                                description: string;
                                shortCode: FieldTypeEnum;
                                status: FieldTypeStatus;
                              };
                            }>;
                          };
                        }>;
                      };
                    };
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      contractTypeId: string;
                      valueCurrency: string;
                      timeZone: string;
                    };
                    product:
                      | {
                          __typename?: "Claims";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "CompEvents";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "DailyDiary";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "EarlyWarnings";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Events";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Instructions";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "RisksRegister";
                          id: string;
                          numberingFormat: string;
                        }
                      | {
                          __typename?: "Variations";
                          id: string;
                          numberingFormat: string;
                        };
                  };
                  data: {
                    __typename?: "ItemData";
                    sections: Array<{
                      __typename?: "ItemDataSection";
                      name: string;
                      entries: Array<{
                        __typename?: "ItemDataEntry";
                        name: string;
                        value: string;
                      }>;
                    }>;
                  };
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  statusOption: {
                    __typename?: "ItemStatusOption";
                    id: string;
                    displayOrder: number;
                    description: string;
                    equatesToRemoved: boolean;
                    style: ItemStatusOptionStyles;
                    statusCollectionId: string;
                    isInternal: boolean;
                    status: ItemStatusOptionStatus;
                    creatorId: string;
                    dateCreated: any;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                  };
                  owner: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                }
              | { __typename?: "RiskItem" }
              | {
                  __typename: "VariationItem";
                  id: string;
                  number: string;
                  regardingId: string;
                  productInstance: {
                    __typename?: "ProductInstance";
                    id: string;
                    description: string;
                    numberingFormat: string;
                    contract: {
                      __typename?: "Contract";
                      id: string;
                      timeZone: string;
                    };
                  };
                };
            auditTrails?: Array<{
              __typename?: "AuthorizationWorkflowAuditTrail";
              id: string;
              auditId: string;
              regardingProductItemId: string;
              regardingItemHistoryId?: string | null;
              regardingProductType: ProductType;
              productInstanceId: string;
              actionId: string;
              status: AuthorizationActionStatus;
              denialReason?: string | null;
              dateCreated: any;
              creatorId: string;
              productInstance?: {
                __typename?: "ProductInstance";
                id: string;
                description: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                contractId: string;
                productId: string;
                productSchemaId: string;
                statusCollectionId: string;
                status: ProductInstanceStatus;
                dateCreated: any;
                creatorId: string;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                contract: {
                  __typename?: "Contract";
                  id: string;
                  name: string;
                  friendlyName: string;
                  number: string;
                  valueCurrency: string;
                  value: number;
                  startDate?: any | null;
                  endDate?: any | null;
                  contractTypeId: string;
                  projectId: string;
                  country: string;
                  province?: string | null;
                  timeZone: string;
                  coordinatesLatitude?: number | null;
                  coordinatesLongitude?: number | null;
                  status: ContractStatus;
                  dateCreated: any;
                  creatorId: string;
                  sections: Array<{
                    __typename?: "ContractSection";
                    id: string;
                    number?: number | null;
                    description: string;
                    completionDate: any;
                    status: ContractSectionStatus;
                    dateCreated: any;
                    dateModified: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                  }>;
                  keyDates: Array<{
                    __typename?: "ContractKeyDate";
                    id: string;
                    number?: number | null;
                    conditionToBeMet: string;
                    keyDate: any;
                    status: ContractKeyDateStatus;
                    dateCreated: any;
                    dateModified: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                  }>;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  project: {
                    __typename?: "Project";
                    id: string;
                    name: string;
                    friendlyName: string;
                    status: ProjectStatus;
                  };
                  contractType: {
                    __typename?: "ContractType";
                    id: string;
                    description: string;
                    subType?: string | null;
                    version?: string | null;
                    status: ContractTypeStatus;
                  };
                };
                product:
                  | {
                      __typename?: "Claims";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "CompEvents";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "DailyDiary";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "EarlyWarnings";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Events";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Instructions";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "RisksRegister";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    }
                  | {
                      __typename?: "Variations";
                      id: string;
                      name: string;
                      numberingFormat: string;
                      soloModeSupported: boolean;
                      allowMultipleInstances: boolean;
                      status: ProductStatus;
                    };
                statusCollection: {
                  __typename?: "ItemStatusCollection";
                  id: string;
                  name: string;
                  isDefault: boolean;
                  status: ItemStatusCollectionStatus;
                };
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }> | null;
          } | null;
          sentBy?: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          } | null;
        };
        submissions: Array<{
          __typename?: "DailyDiaryItemSubmission";
          userId: string;
          date?: any | null;
          user: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        sentBy?: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        };
      }
    | {
        __typename: "EmptyDailyDiaryItem";
        productInstanceId: string;
        date: any;
      }
    | null;
};

export type DailyDiaryPresetQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DailyDiaryPresetQuery = {
  __typename?: "Query";
  dailyDiaryPreset: {
    __typename?: "DailyDiaryPreset";
    id: string;
    name: string;
    status: DailyDiaryPresetStatus;
    dateCreated: any;
    creatorId: string;
    sections: Array<{
      __typename?: "DailyDiaryPresetSection";
      name: string;
      fields: Array<{
        __typename?: "DailyDiaryPresetSectionField";
        name: string;
        displayName?: string | null;
        fieldType: FieldTypeEnum;
        isRequired: boolean;
        lookupId?: string | null;
        isHidden: boolean;
        unit?: string | null;
      }>;
    }>;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type DailyDiaryPresetSchemaQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DailyDiaryPresetSchemaQuery = {
  __typename?: "Query";
  dailyDiaryPresetSchema: {
    __typename?: "DailyDiaryPresetSchema";
    lookups: Array<{ __typename?: "ListItem"; id: string; name: string }>;
    sections: Array<{
      __typename?: "DailyDiaryPresetSchemaSection";
      name: string;
      fields: Array<{
        __typename?: "DailyDiaryPresetSchemaSectionField";
        name: string;
        displayName?: string | null;
        fieldType: FieldTypeEnum;
        isRequired: boolean;
        canBeHidden: boolean;
        units: Array<string>;
      }>;
    }>;
  };
};

export type DailyDiaryPresetsQueryVariables = Exact<{ [key: string]: never }>;

export type DailyDiaryPresetsQuery = {
  __typename?: "Query";
  dailyDiaryPresets: {
    __typename?: "DailyDiaryPresetList";
    items: Array<{
      __typename?: "DailyDiaryPreset";
      id: string;
      name: string;
      status: DailyDiaryPresetStatus;
      dateCreated: any;
      creatorId: string;
      sections: Array<{
        __typename?: "DailyDiaryPresetSection";
        name: string;
        fields: Array<{
          __typename?: "DailyDiaryPresetSectionField";
          name: string;
          displayName?: string | null;
          fieldType: FieldTypeEnum;
          isRequired: boolean;
          lookupId?: string | null;
          isHidden: boolean;
          unit?: string | null;
        }>;
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type DisabledClaimTypesOverrideQueryVariables = Exact<{
  contractId: Scalars["ID"];
}>;

export type DisabledClaimTypesOverrideQuery = {
  __typename?: "Query";
  disabledClaimTypesOverride: {
    __typename?: "DisabledClaimTypeOverrideList";
    items: Array<{
      __typename?: "DisabledClaimTypeOverride";
      id: string;
      contractId: string;
      claimTypeId: string;
      dateCreated: any;
      creatorId: string;
      claimType: {
        __typename?: "ClaimType";
        id: string;
        contractTypeId: string;
        clause: string;
        description: string;
        isInternal: boolean;
        status: ClaimTypeStatus;
        dateCreated: any;
        creatorId: string;
        contractType: {
          __typename?: "ContractType";
          id: string;
          description: string;
          subType?: string | null;
          version?: string | null;
          status: ContractTypeStatus;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        timeZone: string;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type DraftEventItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DraftEventItemQuery = {
  __typename?: "Query";
  draftEventItem: {
    __typename?: "EventItem";
    id: string;
    title: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    creatorId: string;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        company: { __typename?: "Company"; registeredName: string };
      };
    }> | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      contract: {
        __typename?: "Contract";
        valueCurrency: string;
        timeZone: string;
      };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type DraftEventItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type DraftEventItemsQuery = {
  __typename?: "Query";
  draftEventItems: {
    __typename?: "EventItemList";
    items: Array<{
      __typename?: "EventItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      ownerId: string;
      statusOptionId: string;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        contract: {
          __typename?: "Contract";
          valueCurrency: string;
          timeZone: string;
        };
      };
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      statusOption: {
        __typename?: "ItemStatusOption";
        id: string;
        displayOrder: number;
        description: string;
        equatesToRemoved: boolean;
        style: ItemStatusOptionStyles;
        statusCollectionId: string;
        isInternal: boolean;
        status: ItemStatusOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      owner: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type DraftInstructionItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DraftInstructionItemQuery = {
  __typename?: "Query";
  draftInstructionItem?: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    notifyDueDate: any;
    creatorId: string;
    offline: boolean;
    givenById?: string | null;
    dateSent: any;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          registeredName: string;
          id: string;
          tradingName: string;
        };
      };
    }> | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      contract: {
        __typename?: "Contract";
        id: string;
        contractTypeId: string;
        valueCurrency: string;
        timeZone: string;
      };
      product:
        | { __typename?: "Claims"; id: string; numberingFormat: string }
        | { __typename?: "CompEvents"; id: string; numberingFormat: string }
        | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
        | { __typename?: "EarlyWarnings"; id: string; numberingFormat: string }
        | { __typename?: "Events"; id: string; numberingFormat: string }
        | { __typename?: "Instructions"; id: string; numberingFormat: string }
        | { __typename?: "RisksRegister"; id: string; numberingFormat: string }
        | { __typename?: "Variations"; id: string; numberingFormat: string };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  } | null;
};

export type DraftInstructionItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type DraftInstructionItemsQuery = {
  __typename?: "Query";
  draftInstructionItems: {
    __typename?: "InstructionItemList";
    items: Array<{
      __typename?: "InstructionItem";
      id: string;
      title: string;
      severity: string;
      number: string;
      productInstanceId: string;
      ownerId: string;
      statusOptionId: string;
      dateCreated: any;
      dateModified: any;
      notifyDueDate: any;
      creatorId: string;
      offline: boolean;
      givenById?: string | null;
      dateSent: any;
      attachments?: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }> | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        productSchema: {
          __typename?: "ProductSchema";
          id: string;
          name: string;
          isDefault: boolean;
          productId: string;
          isInternal: boolean;
          status: ProductSchemaStatus;
          creatorId: string;
          dateCreated: any;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          schemaSections: {
            __typename?: "SchemaSectionList";
            items: Array<{
              __typename?: "SchemaSection";
              id: string;
              name: string;
              displayText: string;
              displayOrder: number;
              columnPlacement: ColumnPlacementType;
              productSchemaId: string;
              isInternal: boolean;
              status: SchemaSectionStatus;
              dateCreated: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaFields: {
                __typename?: "SchemaFieldList";
                items: Array<{
                  __typename?: "SchemaField";
                  id: string;
                  name: string;
                  schemaSectionId: string;
                  fieldTypeId: string;
                  displayText: string;
                  displayOrder: number;
                  isRequired: boolean;
                  isInternal: boolean;
                  complexTypeItemId?: string | null;
                  extraData?: string | null;
                  status: SchemaFieldStatus;
                  creatorId: string;
                  dateCreated: any;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  fieldType: {
                    __typename?: "FieldType";
                    id: string;
                    description: string;
                    shortCode: FieldTypeEnum;
                    status: FieldTypeStatus;
                  };
                }>;
              };
            }>;
          };
        };
        contract: {
          __typename?: "Contract";
          id: string;
          contractTypeId: string;
          valueCurrency: string;
          timeZone: string;
        };
        product:
          | { __typename?: "Claims"; id: string; numberingFormat: string }
          | { __typename?: "CompEvents"; id: string; numberingFormat: string }
          | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              numberingFormat: string;
            }
          | { __typename?: "Events"; id: string; numberingFormat: string }
          | { __typename?: "Instructions"; id: string; numberingFormat: string }
          | {
              __typename?: "RisksRegister";
              id: string;
              numberingFormat: string;
            }
          | { __typename?: "Variations"; id: string; numberingFormat: string };
      };
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      statusOption: {
        __typename?: "ItemStatusOption";
        id: string;
        displayOrder: number;
        description: string;
        equatesToRemoved: boolean;
        style: ItemStatusOptionStyles;
        statusCollectionId: string;
        isInternal: boolean;
        status: ItemStatusOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      owner: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type EffectPresetQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EffectPresetQuery = {
  __typename?: "Query";
  effectPreset: {
    __typename?: "EffectPreset";
    id: string;
    name: string;
    contractTypeId: string;
    isDefault: boolean;
    isInternal: boolean;
    status: EffectPresetStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    options: {
      __typename?: "EffectOptionList";
      items: Array<{
        __typename?: "EffectOption";
        id: string;
        label: string;
        isInternal: boolean;
        estimationType: EffectEstimationType;
        unit: string;
        effectPresetId: string;
        status: EffectOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
      }>;
    };
  };
};

export type ItemEffectPresetQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type ItemEffectPresetQuery = {
  __typename?: "Query";
  itemEffectPreset: {
    __typename?: "EffectPreset";
    id: string;
    name: string;
    contractTypeId: string;
    isDefault: boolean;
    isInternal: boolean;
    status: EffectPresetStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
    };
    options: {
      __typename?: "EffectOptionList";
      items: Array<{
        __typename?: "EffectOption";
        id: string;
        label: string;
        isInternal: boolean;
        estimationType: EffectEstimationType;
        unit: string;
        effectPresetId: string;
        status: EffectOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
        };
      }>;
    };
  };
};

export type EventItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type EventItemsQuery = {
  __typename?: "Query";
  eventItems: {
    __typename?: "EventItemList";
    items: Array<{
      __typename?: "EventItem";
      id: string;
      title: string;
      number: string;
      productInstanceId: string;
      ownerId: string;
      statusOptionId: string;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      compEvent?: {
        __typename?: "CompEventItem";
        id: string;
        number: string;
      } | null;
      claim?: { __typename?: "ClaimItem"; id: string; number: string } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        contract: {
          __typename?: "Contract";
          valueCurrency: string;
          timeZone: string;
        };
      };
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      statusOption: {
        __typename?: "ItemStatusOption";
        id: string;
        displayOrder: number;
        description: string;
        equatesToRemoved: boolean;
        style: ItemStatusOptionStyles;
        statusCollectionId: string;
        isInternal: boolean;
        status: ItemStatusOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      owner: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type GlobalConfigurationQueryVariables = Exact<{ [key: string]: never }>;

export type GlobalConfigurationQuery = {
  __typename?: "Query";
  globalConfiguration: {
    __typename?: "GlobalConfiguration";
    maxUploadFileSize: number;
  };
};

export type InstructionItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InstructionItemQuery = {
  __typename?: "Query";
  instructionItem?: {
    __typename?: "InstructionItem";
    id: string;
    title: string;
    severity: string;
    number: string;
    productInstanceId: string;
    ownerId: string;
    statusOptionId: string;
    dateCreated: any;
    dateModified: any;
    notifyDueDate: any;
    creatorId: string;
    offline: boolean;
    givenById?: string | null;
    dateSent: any;
    attachments?: Array<{
      __typename?: "Attachment";
      id: string;
      fileName: string;
      fileUrl: string;
      mimeType: string;
      description?: string | null;
      locked: boolean;
      fileSize: number;
      status: AttachmentStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
        company: {
          __typename?: "Company";
          id: string;
          registeredName: string;
          tradingName: string;
        };
      };
    }> | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      numberingFormat: string;
      productSchema: {
        __typename?: "ProductSchema";
        id: string;
        name: string;
        isDefault: boolean;
        productId: string;
        isInternal: boolean;
        status: ProductSchemaStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        schemaSections: {
          __typename?: "SchemaSectionList";
          items: Array<{
            __typename?: "SchemaSection";
            id: string;
            name: string;
            displayText: string;
            displayOrder: number;
            columnPlacement: ColumnPlacementType;
            productSchemaId: string;
            isInternal: boolean;
            status: SchemaSectionStatus;
            dateCreated: any;
            creatorId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            schemaFields: {
              __typename?: "SchemaFieldList";
              items: Array<{
                __typename?: "SchemaField";
                id: string;
                name: string;
                schemaSectionId: string;
                fieldTypeId: string;
                displayText: string;
                displayOrder: number;
                isRequired: boolean;
                isInternal: boolean;
                complexTypeItemId?: string | null;
                extraData?: string | null;
                status: SchemaFieldStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                fieldType: {
                  __typename?: "FieldType";
                  id: string;
                  description: string;
                  shortCode: FieldTypeEnum;
                  status: FieldTypeStatus;
                };
              }>;
            };
          }>;
        };
      };
      contract: {
        __typename?: "Contract";
        id: string;
        contractTypeId: string;
        valueCurrency: string;
        timeZone: string;
      };
      product:
        | { __typename?: "Claims"; id: string; numberingFormat: string }
        | { __typename?: "CompEvents"; id: string; numberingFormat: string }
        | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
        | { __typename?: "EarlyWarnings"; id: string; numberingFormat: string }
        | { __typename?: "Events"; id: string; numberingFormat: string }
        | { __typename?: "Instructions"; id: string; numberingFormat: string }
        | { __typename?: "RisksRegister"; id: string; numberingFormat: string }
        | { __typename?: "Variations"; id: string; numberingFormat: string };
    };
    data: {
      __typename?: "ItemData";
      sections: Array<{
        __typename?: "ItemDataSection";
        name: string;
        entries: Array<{
          __typename?: "ItemDataEntry";
          name: string;
          value: string;
        }>;
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    statusOption: {
      __typename?: "ItemStatusOption";
      id: string;
      displayOrder: number;
      description: string;
      equatesToRemoved: boolean;
      style: ItemStatusOptionStyles;
      statusCollectionId: string;
      isInternal: boolean;
      status: ItemStatusOptionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    owner: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  } | null;
};

export type InstructionItemsQueryVariables = Exact<{
  productInstanceId: Scalars["ID"];
}>;

export type InstructionItemsQuery = {
  __typename?: "Query";
  instructionItems: {
    __typename?: "InstructionItemList";
    items: Array<{
      __typename?: "InstructionItem";
      id: string;
      title: string;
      severity: string;
      number: string;
      productInstanceId: string;
      ownerId: string;
      statusOptionId: string;
      dateCreated: any;
      dateModified: any;
      notifyDueDate: any;
      creatorId: string;
      offline: boolean;
      givenById?: string | null;
      dateSent: any;
      compEvent?: {
        __typename?: "CompEventItem";
        id: string;
        number: string;
      } | null;
      claim?: { __typename?: "ClaimItem"; id: string; number: string } | null;
      variation?: {
        __typename?: "VariationItem";
        id: string;
        number: string;
      } | null;
      attachments?: Array<{
        __typename?: "Attachment";
        id: string;
        fileName: string;
        fileUrl: string;
        mimeType: string;
        description?: string | null;
        locked: boolean;
        fileSize: number;
        status: AttachmentStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
      }> | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        numberingFormat: string;
        productSchema: {
          __typename?: "ProductSchema";
          id: string;
          name: string;
          isDefault: boolean;
          productId: string;
          isInternal: boolean;
          status: ProductSchemaStatus;
          creatorId: string;
          dateCreated: any;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          schemaSections: {
            __typename?: "SchemaSectionList";
            items: Array<{
              __typename?: "SchemaSection";
              id: string;
              name: string;
              displayText: string;
              displayOrder: number;
              columnPlacement: ColumnPlacementType;
              productSchemaId: string;
              isInternal: boolean;
              status: SchemaSectionStatus;
              dateCreated: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              schemaFields: {
                __typename?: "SchemaFieldList";
                items: Array<{
                  __typename?: "SchemaField";
                  id: string;
                  name: string;
                  schemaSectionId: string;
                  fieldTypeId: string;
                  displayText: string;
                  displayOrder: number;
                  isRequired: boolean;
                  isInternal: boolean;
                  complexTypeItemId?: string | null;
                  extraData?: string | null;
                  status: SchemaFieldStatus;
                  creatorId: string;
                  dateCreated: any;
                  creator: {
                    __typename?: "User";
                    id: string;
                    firstname: string;
                    surname: string;
                    email: any;
                  };
                  fieldType: {
                    __typename?: "FieldType";
                    id: string;
                    description: string;
                    shortCode: FieldTypeEnum;
                    status: FieldTypeStatus;
                  };
                }>;
              };
            }>;
          };
        };
        contract: {
          __typename?: "Contract";
          id: string;
          contractTypeId: string;
          valueCurrency: string;
          timeZone: string;
        };
        product:
          | { __typename?: "Claims"; id: string; numberingFormat: string }
          | { __typename?: "CompEvents"; id: string; numberingFormat: string }
          | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              numberingFormat: string;
            }
          | { __typename?: "Events"; id: string; numberingFormat: string }
          | { __typename?: "Instructions"; id: string; numberingFormat: string }
          | {
              __typename?: "RisksRegister";
              id: string;
              numberingFormat: string;
            }
          | { __typename?: "Variations"; id: string; numberingFormat: string };
      };
      data: {
        __typename?: "ItemData";
        sections: Array<{
          __typename?: "ItemDataSection";
          name: string;
          entries: Array<{
            __typename?: "ItemDataEntry";
            name: string;
            value: string;
          }>;
        }>;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      statusOption: {
        __typename?: "ItemStatusOption";
        id: string;
        displayOrder: number;
        description: string;
        equatesToRemoved: boolean;
        style: ItemStatusOptionStyles;
        statusCollectionId: string;
        isInternal: boolean;
        status: ItemStatusOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      owner: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type LookupCollectionFieldsFragment = {
  __typename?: "LookupCollection";
  id: string;
  name: string;
  optionsLockedForUsers: boolean;
  optionsCharLengthLimit?: number | null;
  isInternal: boolean;
  status: LookupCollectionStatus;
  creatorId: string;
  dateCreated: any;
  creator: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
};

export type LookupCollectionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LookupCollectionQuery = {
  __typename?: "Query";
  lookupCollection: {
    __typename?: "LookupCollection";
    id: string;
    name: string;
    optionsLockedForUsers: boolean;
    optionsCharLengthLimit?: number | null;
    isInternal: boolean;
    status: LookupCollectionStatus;
    creatorId: string;
    dateCreated: any;
    options: {
      __typename?: "LookupOptionList";
      items: Array<{
        __typename?: "LookupOption";
        id: string;
        value: string;
        displayOrder: number;
        collectionId: string;
        isInternal: boolean;
        status: LookupOptionStatus;
        creatorId: string;
        dateCreated: any;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type LookupCollectionsQueryVariables = Exact<{ [key: string]: never }>;

export type LookupCollectionsQuery = {
  __typename?: "Query";
  lookupCollections: {
    __typename?: "LookupCollectionList";
    items: Array<{
      __typename?: "LookupCollection";
      id: string;
      name: string;
      optionsLockedForUsers: boolean;
      optionsCharLengthLimit?: number | null;
      isInternal: boolean;
      status: LookupCollectionStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type LookupOptionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LookupOptionQuery = {
  __typename?: "Query";
  lookupOption: {
    __typename?: "LookupOption";
    id: string;
    value: string;
    displayOrder: number;
    collectionId: string;
    isInternal: boolean;
    status: LookupOptionStatus;
    creatorId: string;
    dateCreated: any;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
};

export type NewClaimTypesOverrideQueryVariables = Exact<{
  contractId: Scalars["ID"];
}>;

export type NewClaimTypesOverrideQuery = {
  __typename?: "Query";
  newClaimTypesOverride: {
    __typename?: "NewClaimTypeOverrideList";
    items: Array<{
      __typename?: "NewClaimTypeOverride";
      id: string;
      contractId: string;
      clause: string;
      description: string;
      status: NewClaimTypeOverrideStatus;
      dateCreated: any;
      creatorId: string;
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        timeZone: string;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type ProductInstanceLiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProductInstanceLiteQuery = {
  __typename?: "Query";
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    numberingFormat: string;
    soloModeSupported: boolean;
    contractId: string;
    productId: string;
    productSchemaId: string;
    statusCollectionId: string;
    status: ProductInstanceStatus;
    dateCreated: any;
    creatorId: string;
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    product:
      | {
          __typename?: "Claims";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "CompEvents";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "DailyDiary";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "EarlyWarnings";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Events";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Instructions";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "RisksRegister";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        }
      | {
          __typename?: "Variations";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
        };
    contract: {
      __typename?: "Contract";
      id: string;
      name: string;
      friendlyName: string;
      number: string;
      valueCurrency: string;
      value: number;
      startDate?: any | null;
      endDate?: any | null;
      contractTypeId: string;
      projectId: string;
      country: string;
      province?: string | null;
      timeZone: string;
      coordinatesLatitude?: number | null;
      coordinatesLongitude?: number | null;
      status: ContractStatus;
      dateCreated: any;
      creatorId: string;
      sections: Array<{
        __typename?: "ContractSection";
        id: string;
        number?: number | null;
        description: string;
        completionDate: any;
        status: ContractSectionStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
      keyDates: Array<{
        __typename?: "ContractKeyDate";
        id: string;
        number?: number | null;
        conditionToBeMet: string;
        keyDate: any;
        status: ContractKeyDateStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        status: ProjectStatus;
      };
      contractType: {
        __typename?: "ContractType";
        id: string;
        description: string;
        subType?: string | null;
        version?: string | null;
        status: ContractTypeStatus;
      };
    };
    extraConfig?:
      | { __typename: "CompEventExtraConfig" }
      | {
          __typename: "DailyDiaryExtraConfig";
          presetId: string;
          manPowerLookupId?: string | null;
          equipmentLookupId?: string | null;
          areaLookupId?: string | null;
          hseTypeLookupId?: string | null;
          companyLookupId?: string | null;
          teamLookupId?: string | null;
          shiftLookupId?: string | null;
          startDate: any;
        }
      | null;
  };
};

export type ProductInstanceSchemaQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProductInstanceSchemaQuery = {
  __typename?: "Query";
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    soloModeSupported: boolean;
    productSchema: {
      __typename?: "ProductSchema";
      id: string;
      name: string;
      isDefault: boolean;
      productId: string;
      isInternal: boolean;
      status: ProductSchemaStatus;
      creatorId: string;
      dateCreated: any;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      schemaSections: {
        __typename?: "SchemaSectionList";
        items: Array<{
          __typename?: "SchemaSection";
          id: string;
          name: string;
          displayText: string;
          displayOrder: number;
          columnPlacement: ColumnPlacementType;
          productSchemaId: string;
          isInternal: boolean;
          status: SchemaSectionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          schemaFields: {
            __typename?: "SchemaFieldList";
            items: Array<{
              __typename?: "SchemaField";
              id: string;
              name: string;
              schemaSectionId: string;
              fieldTypeId: string;
              displayText: string;
              displayOrder: number;
              isRequired: boolean;
              isInternal: boolean;
              complexTypeItemId?: string | null;
              extraData?: string | null;
              status: SchemaFieldStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
              fieldType: {
                __typename?: "FieldType";
                id: string;
                description: string;
                shortCode: FieldTypeEnum;
                status: FieldTypeStatus;
              };
            }>;
          };
        }>;
      };
    };
  };
};

export type ProductInstanceUserRolesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProductInstanceUserRolesQuery = {
  __typename?: "Query";
  productInstance: {
    __typename?: "ProductInstance";
    id: string;
    description: string;
    contractId: string;
    productId: string;
    productSchemaId: string;
    statusCollectionId: string;
    status: ProductInstanceStatus;
    creatorId: string;
    roles: {
      __typename?: "ProductUserRoleList";
      items: Array<{
        __typename?: "ProductUserRole";
        userId: string;
        productInstanceId: string;
        productRoleId: string;
        status: ProductUserRoleStatus;
        dateCreated: any;
        creatorId: string;
        user: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          status: UserStatus;
          jobTitle: string;
          registered?: boolean | null;
          company: {
            __typename?: "Company";
            id: string;
            registeredName: string;
            tradingName: string;
          };
        };
        productRole: {
          __typename?: "ProductRole";
          id: string;
          name: string;
          isInternal: boolean;
          status: ProductRoleStatus;
        };
      }>;
    };
  };
};

export type ProductLiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProductLiteQuery = {
  __typename?: "Query";
  product:
    | { __typename?: "Claims"; id: string; name: string }
    | { __typename?: "CompEvents"; id: string; name: string }
    | { __typename?: "DailyDiary"; id: string; name: string }
    | { __typename?: "EarlyWarnings"; id: string; name: string }
    | { __typename?: "Events"; id: string; name: string }
    | { __typename?: "Instructions"; id: string; name: string }
    | { __typename?: "RisksRegister"; id: string; name: string }
    | { __typename?: "Variations"; id: string; name: string };
};

export type ProductRoleActionsQueryVariables = Exact<{ [key: string]: never }>;

export type ProductRoleActionsQuery = {
  __typename?: "Query";
  productRoleActions: {
    __typename?: "ProductRoleActionList";
    items: Array<{
      __typename?: "ProductRoleAction";
      id: string;
      name: string;
      isInternal: boolean;
      status: ProductRoleActionStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
      };
    }>;
  };
};

export type ProductsQueryVariables = Exact<{ [key: string]: never }>;

export type ProductsQuery = {
  __typename?: "Query";
  products: {
    __typename?: "ProductList";
    items: Array<
      | {
          __typename: "Claims";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
        }
      | {
          __typename: "CompEvents";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
        }
      | {
          __typename: "DailyDiary";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
        }
      | {
          __typename: "EarlyWarnings";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
        }
      | {
          __typename: "Events";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
        }
      | {
          __typename: "Instructions";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
        }
      | {
          __typename: "RisksRegister";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
        }
      | {
          __typename: "Variations";
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          id: string;
          name: string;
          status: ProductStatus;
        }
    >;
  };
};

export type ProductsWithOutputActionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProductsWithOutputActionsQuery = {
  __typename?: "Query";
  products: {
    __typename?: "ProductList";
    items: Array<
      | {
          __typename?: "Claims";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
          outputActions: {
            __typename?: "ProductOutputActionList";
            items: Array<{
              __typename?: "ProductOutputAction";
              id: string;
              name: string;
              productId: string;
              isInternal: boolean;
              status: ProductOutputActionStatus;
              dateCreated: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
              };
            }>;
          };
        }
      | {
          __typename?: "CompEvents";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
          outputActions: {
            __typename?: "ProductOutputActionList";
            items: Array<{
              __typename?: "ProductOutputAction";
              id: string;
              name: string;
              productId: string;
              isInternal: boolean;
              status: ProductOutputActionStatus;
              dateCreated: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
              };
            }>;
          };
        }
      | {
          __typename?: "DailyDiary";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
          outputActions: {
            __typename?: "ProductOutputActionList";
            items: Array<{
              __typename?: "ProductOutputAction";
              id: string;
              name: string;
              productId: string;
              isInternal: boolean;
              status: ProductOutputActionStatus;
              dateCreated: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
              };
            }>;
          };
        }
      | {
          __typename?: "EarlyWarnings";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
          outputActions: {
            __typename?: "ProductOutputActionList";
            items: Array<{
              __typename?: "ProductOutputAction";
              id: string;
              name: string;
              productId: string;
              isInternal: boolean;
              status: ProductOutputActionStatus;
              dateCreated: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
              };
            }>;
          };
        }
      | {
          __typename?: "Events";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
          outputActions: {
            __typename?: "ProductOutputActionList";
            items: Array<{
              __typename?: "ProductOutputAction";
              id: string;
              name: string;
              productId: string;
              isInternal: boolean;
              status: ProductOutputActionStatus;
              dateCreated: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
              };
            }>;
          };
        }
      | {
          __typename?: "Instructions";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
          outputActions: {
            __typename?: "ProductOutputActionList";
            items: Array<{
              __typename?: "ProductOutputAction";
              id: string;
              name: string;
              productId: string;
              isInternal: boolean;
              status: ProductOutputActionStatus;
              dateCreated: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
              };
            }>;
          };
        }
      | {
          __typename?: "RisksRegister";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
          outputActions: {
            __typename?: "ProductOutputActionList";
            items: Array<{
              __typename?: "ProductOutputAction";
              id: string;
              name: string;
              productId: string;
              isInternal: boolean;
              status: ProductOutputActionStatus;
              dateCreated: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
              };
            }>;
          };
        }
      | {
          __typename?: "Variations";
          id: string;
          name: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          allowMultipleInstances: boolean;
          status: ProductStatus;
          outputActions: {
            __typename?: "ProductOutputActionList";
            items: Array<{
              __typename?: "ProductOutputAction";
              id: string;
              name: string;
              productId: string;
              isInternal: boolean;
              status: ProductOutputActionStatus;
              dateCreated: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
              };
            }>;
          };
        }
    >;
  };
};

export type ProjectAuthorizationAuditsQueryVariables = Exact<{
  projectId: Scalars["ID"];
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type ProjectAuthorizationAuditsQuery = {
  __typename?: "Query";
  projectAuthorizationAudits: {
    __typename?: "AuthorizationWorkflowAuditList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      workflowId: string;
      actionId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      dateInitiated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      regardingProductItem:
        | {
            __typename: "ClaimItem";
            id: string;
            number: string;
            regardingId: string;
            claimRegardingType: ClaimRegardingType;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename: "CompEventItem";
            id: string;
            number: string;
            productInstanceId: string;
            regardingId: string;
            regardingType: CompEventRegardingType;
            status: CompEventStatus;
            price?: number | null;
            time?: number | null;
            earlyWarningId?: string | null;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            deemingDate?: any | null;
            lastAction: {
              __typename?: "CompEventItemLastAction";
              actionType: CompEventActionType;
              daysLate?: number | null;
              date: any;
            };
            nextStep?: {
              __typename?: "CompEventItemNextStep";
              actionType: CompEventActionType;
              dueDate: any;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              status: ProductInstanceStatus;
              product:
                | { __typename?: "Claims"; id: string; name: string }
                | { __typename?: "CompEvents"; id: string; name: string }
                | { __typename?: "DailyDiary"; id: string; name: string }
                | { __typename?: "EarlyWarnings"; id: string; name: string }
                | { __typename?: "Events"; id: string; name: string }
                | { __typename?: "Instructions"; id: string; name: string }
                | { __typename?: "RisksRegister"; id: string; name: string }
                | { __typename?: "Variations"; id: string; name: string };
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
                timeZone: string;
                valueCurrency: string;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
            };
            earlyWarning?: {
              __typename?: "EarlyWarningItem";
              id: string;
              title: string;
              dateSent: any;
              number: string;
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingEvent?: {
              __typename?: "EventItem";
              id: string;
              title: string;
              productInstanceId: string;
              number: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            regardingInstruction?: {
              __typename?: "InstructionItem";
              id: string;
              title: string;
              number: string;
              productInstanceId: string;
              data: {
                __typename?: "ItemData";
                sections: Array<{
                  __typename?: "ItemDataSection";
                  name: string;
                  entries: Array<{
                    __typename?: "ItemDataEntry";
                    name: string;
                    value: string;
                  }>;
                }>;
              };
              productInstance: {
                __typename?: "ProductInstance";
                id: string;
                product:
                  | { __typename?: "Claims"; id: string }
                  | { __typename?: "CompEvents"; id: string }
                  | { __typename?: "DailyDiary"; id: string }
                  | { __typename?: "EarlyWarnings"; id: string }
                  | { __typename?: "Events"; id: string }
                  | { __typename?: "Instructions"; id: string }
                  | { __typename?: "RisksRegister"; id: string }
                  | { __typename?: "Variations"; id: string };
              };
            } | null;
            notifiedByParty: {
              __typename?: "ContractBindingType";
              id: string;
              description: string;
              contractTypeId: string;
              status: ContractBindingTypeStatus;
            };
          }
        | {
            __typename?: "DailyDiaryItem";
            id: string;
            date: any;
            number: string;
            dateCreated: any;
            dateModified: any;
            creatorId: string;
            sentById?: string | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              status: ProductInstanceStatus;
              contract: {
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                timeZone: string;
                number: string;
                status: ContractStatus;
                dateCreated: any;
                project: {
                  __typename?: "Project";
                  id: string;
                  name: string;
                  friendlyName: string;
                  status: ProjectStatus;
                };
              };
              product:
                | {
                    __typename?: "Claims";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Events";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Instructions";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    name: string;
                    numberingFormat: string;
                    soloModeSupported: boolean;
                    allowMultipleInstances: boolean;
                    status: ProductStatus;
                  };
            };
          }
        | {
            __typename?: "EarlyWarningItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            creatorId: string;
            dateModified: any;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
          }
        | { __typename?: "EventItem" }
        | {
            __typename?: "InstructionItem";
            id: string;
            title: string;
            severity: string;
            number: string;
            productInstanceId: string;
            ownerId: string;
            statusOptionId: string;
            dateCreated: any;
            dateModified: any;
            notifyDueDate: any;
            creatorId: string;
            offline: boolean;
            givenById?: string | null;
            dateSent: any;
            attachments?: Array<{
              __typename?: "Attachment";
              id: string;
              fileName: string;
              fileUrl: string;
              mimeType: string;
              description?: string | null;
              locked: boolean;
              fileSize: number;
              status: AttachmentStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
                company: {
                  __typename?: "Company";
                  id: string;
                  registeredName: string;
                  tradingName: string;
                };
              };
            }> | null;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              productSchema: {
                __typename?: "ProductSchema";
                id: string;
                name: string;
                isDefault: boolean;
                productId: string;
                isInternal: boolean;
                status: ProductSchemaStatus;
                creatorId: string;
                dateCreated: any;
                creator: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
                schemaSections: {
                  __typename?: "SchemaSectionList";
                  items: Array<{
                    __typename?: "SchemaSection";
                    id: string;
                    name: string;
                    displayText: string;
                    displayOrder: number;
                    columnPlacement: ColumnPlacementType;
                    productSchemaId: string;
                    isInternal: boolean;
                    status: SchemaSectionStatus;
                    dateCreated: any;
                    creatorId: string;
                    creator: {
                      __typename?: "User";
                      id: string;
                      firstname: string;
                      surname: string;
                      email: any;
                    };
                    schemaFields: {
                      __typename?: "SchemaFieldList";
                      items: Array<{
                        __typename?: "SchemaField";
                        id: string;
                        name: string;
                        schemaSectionId: string;
                        fieldTypeId: string;
                        displayText: string;
                        displayOrder: number;
                        isRequired: boolean;
                        isInternal: boolean;
                        complexTypeItemId?: string | null;
                        extraData?: string | null;
                        status: SchemaFieldStatus;
                        creatorId: string;
                        dateCreated: any;
                        creator: {
                          __typename?: "User";
                          id: string;
                          firstname: string;
                          surname: string;
                          email: any;
                        };
                        fieldType: {
                          __typename?: "FieldType";
                          id: string;
                          description: string;
                          shortCode: FieldTypeEnum;
                          status: FieldTypeStatus;
                        };
                      }>;
                    };
                  }>;
                };
              };
              contract: {
                __typename?: "Contract";
                id: string;
                contractTypeId: string;
                valueCurrency: string;
                timeZone: string;
              };
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
            };
            data: {
              __typename?: "ItemData";
              sections: Array<{
                __typename?: "ItemDataSection";
                name: string;
                entries: Array<{
                  __typename?: "ItemDataEntry";
                  name: string;
                  value: string;
                }>;
              }>;
            };
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            statusOption: {
              __typename?: "ItemStatusOption";
              id: string;
              displayOrder: number;
              description: string;
              equatesToRemoved: boolean;
              style: ItemStatusOptionStyles;
              statusCollectionId: string;
              isInternal: boolean;
              status: ItemStatusOptionStatus;
              creatorId: string;
              dateCreated: any;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
            owner: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }
        | { __typename?: "RiskItem" }
        | {
            __typename: "VariationItem";
            id: string;
            number: string;
            regardingId: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          };
      auditTrails?: Array<{
        __typename?: "AuthorizationWorkflowAuditTrail";
        id: string;
        auditId: string;
        regardingProductItemId: string;
        regardingItemHistoryId?: string | null;
        regardingProductType: ProductType;
        productInstanceId: string;
        actionId: string;
        status: AuthorizationActionStatus;
        denialReason?: string | null;
        dateCreated: any;
        creatorId: string;
        productInstance?: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          soloModeSupported: boolean;
          contractId: string;
          productId: string;
          productSchemaId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contract: {
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            number: string;
            valueCurrency: string;
            value: number;
            startDate?: any | null;
            endDate?: any | null;
            contractTypeId: string;
            projectId: string;
            country: string;
            province?: string | null;
            timeZone: string;
            coordinatesLatitude?: number | null;
            coordinatesLongitude?: number | null;
            status: ContractStatus;
            dateCreated: any;
            creatorId: string;
            sections: Array<{
              __typename?: "ContractSection";
              id: string;
              number?: number | null;
              description: string;
              completionDate: any;
              status: ContractSectionStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            keyDates: Array<{
              __typename?: "ContractKeyDate";
              id: string;
              number?: number | null;
              conditionToBeMet: string;
              keyDate: any;
              status: ContractKeyDateStatus;
              dateCreated: any;
              dateModified: any;
              creatorId: string;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            project: {
              __typename?: "Project";
              id: string;
              name: string;
              friendlyName: string;
              status: ProjectStatus;
            };
            contractType: {
              __typename?: "ContractType";
              id: string;
              description: string;
              subType?: string | null;
              version?: string | null;
              status: ContractTypeStatus;
            };
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
          statusCollection: {
            __typename?: "ItemStatusCollection";
            id: string;
            name: string;
            isDefault: boolean;
            status: ItemStatusCollectionStatus;
          };
        } | null;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      }> | null;
    }>;
  };
};

export type GetProjectByIdLiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetProjectByIdLiteQuery = {
  __typename?: "Query";
  project: {
    __typename?: "Project";
    id: string;
    name: string;
    friendlyName: string;
  };
};

export type ItemStatusCollectionLiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ItemStatusCollectionLiteQuery = {
  __typename?: "Query";
  itemStatusCollection: {
    __typename?: "ItemStatusCollection";
    id: string;
    name: string;
  };
};

export type SystemVersionQueryVariables = Exact<{ [key: string]: never }>;

export type SystemVersionQuery = {
  __typename?: "Query";
  systemVersion: {
    __typename?: "SystemVersion";
    assemblyVersion: string;
    environment: string;
  };
};

export type DocumentTemplatesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type DocumentTemplatesQuery = {
  __typename?: "Query";
  documentTemplates: {
    __typename?: "DocumentTemplateList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "DocumentTemplate";
      id: string;
      name: string;
      description: string;
      isInternal: boolean;
      status: DocumentTemplateStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
      };
    }>;
  };
};

export type DocumentTemplatesNameOnlyQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type DocumentTemplatesNameOnlyQuery = {
  __typename?: "Query";
  documentTemplates: {
    __typename?: "DocumentTemplateList";
    nextToken?: string | null;
    items: Array<{ __typename?: "DocumentTemplate"; id: string; name: string }>;
  };
};

export type TimePeriodsQueryVariables = Exact<{
  contractTypeId: Scalars["ID"];
}>;

export type TimePeriodsQuery = {
  __typename?: "Query";
  timePeriods: {
    __typename?: "TimePeriodList";
    items: Array<{
      __typename?: "TimePeriod";
      id: string;
      contractTypeId: string;
      reference: string;
      description: string;
      duration: number;
      status: TimePeriodStatus;
      isInternal: boolean;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type TimePeriodsOverrideQueryVariables = Exact<{
  contractId: Scalars["ID"];
}>;

export type TimePeriodsOverrideQuery = {
  __typename?: "Query";
  timePeriodsOverride: {
    __typename?: "TimePeriodOverrideList";
    items: Array<{
      __typename?: "TimePeriodOverride";
      id: string;
      contractId: string;
      timePeriodId: string;
      duration: number;
      dateCreated: any;
      creatorId: string;
      timePeriod: {
        __typename?: "TimePeriod";
        id: string;
        contractTypeId: string;
        reference: string;
        description: string;
        duration: number;
        status: TimePeriodStatus;
        isInternal: boolean;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      contract: {
        __typename?: "Contract";
        id: string;
        name: string;
        friendlyName: string;
        timeZone: string;
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    }>;
  };
};

export type LocalAuthProductInstanceFieldsFragment = {
  __typename?: "ProductInstance";
  id: string;
  description: string;
  numberingFormat: string;
  product:
    | { __typename?: "Claims"; id: string; numberingFormat: string }
    | { __typename?: "CompEvents"; id: string; numberingFormat: string }
    | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
    | { __typename?: "EarlyWarnings"; id: string; numberingFormat: string }
    | { __typename?: "Events"; id: string; numberingFormat: string }
    | { __typename?: "Instructions"; id: string; numberingFormat: string }
    | { __typename?: "RisksRegister"; id: string; numberingFormat: string }
    | { __typename?: "Variations"; id: string; numberingFormat: string };
  contract: { __typename?: "Contract"; id: string; timeZone: string };
};

export type HomePageAuthorizationWorkflowAuditFieldsFragment = {
  __typename?: "AuthorizationWorkflowAudit";
  id: string;
  initiatedById: string;
  regardingProductItemId: string;
  regardingProductType: ProductType;
  regardingItemHistoryId?: string | null;
  actionId: string;
  status: AuthorizationWorkflowAuditStatus;
  dateCreated: any;
  dateInitiated: any;
  initiatedBy: {
    __typename?: "User";
    id: string;
    firstname: string;
    surname: string;
    email: any;
  };
  action: {
    __typename?: "AuthorizationWorkflowActionMapping";
    id: string;
    productOutputActionId: string;
    workflowId: string;
    partyId?: string | null;
    productInstanceId: string;
    status: AuthorizationWorkflowActionMappingStatus;
    dateCreated: any;
    creatorId: string;
    modifiedById: string;
    dateModified: any;
    productOutputAction: {
      __typename?: "ProductOutputAction";
      id: string;
      name: string;
      productId: string;
      isInternal: boolean;
      status: ProductOutputActionStatus;
      dateCreated: any;
      creatorId: string;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      product:
        | {
            __typename?: "Claims";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "CompEvents";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "DailyDiary";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "EarlyWarnings";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Events";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Instructions";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "RisksRegister";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          }
        | {
            __typename?: "Variations";
            id: string;
            name: string;
            numberingFormat: string;
            soloModeSupported: boolean;
            allowMultipleInstances: boolean;
            status: ProductStatus;
          };
    };
    workflow: {
      __typename?: "AuthorizationWorkflow";
      id: string;
      name: string;
      status: AuthorizationWorkflowStatus;
      dateCreated: any;
      dateModified: any;
      creatorId: string;
      modifiedById: string;
      projectId: string;
      levels: Array<{
        __typename?: "AuthorizationWorkflowLevel";
        sequence: number;
        mode: AuthorizationWorkflowLevelMode;
        userIds: Array<string>;
        users: {
          __typename?: "UserList";
          items: Array<{
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            jobTitle: string;
            country: string;
            mobileNumber?: any | null;
            alternateNumber?: any | null;
            dateOfBirth?: any | null;
            profilePicture?: string | null;
            email: any;
            companyId: string;
            registered?: boolean | null;
            status: UserStatus;
            lastActive?: any | null;
            dateInvited: any;
            registeredDate?: any | null;
            company: {
              __typename?: "Company";
              id: string;
              tradingName: string;
              registeredName: string;
            };
            invitedBy: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          }>;
        };
      }>;
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      project: {
        __typename?: "Project";
        id: string;
        name: string;
        friendlyName: string;
        number?: string | null;
        sector: ProjectSector;
        description?: string | null;
        status: ProjectStatus;
        dateCreated: any;
        creatorId: string;
        billingCompanyId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        contracts: {
          __typename?: "ContractList";
          items: Array<{
            __typename?: "Contract";
            id: string;
            name: string;
            friendlyName: string;
            status: ContractStatus;
          }>;
        };
        billingCompany: {
          __typename?: "Company";
          id: string;
          type?: CompanyType | null;
          registeredName: string;
          tradingName: string;
          registrationNumber?: string | null;
          logo?: string | null;
          vatRegistrationNumber?: string | null;
          status: CompanyStatus;
          creatorId: string;
          dateCreated: any;
          physicalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          postalAddress?: {
            __typename?: "Address";
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            provinceState?: string | null;
            country: string;
            code?: string | null;
          } | null;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
        };
      };
    };
    party?: {
      __typename?: "ContractBindingType";
      id: string;
      description: string;
      contractTypeId: string;
      status: ContractBindingTypeStatus;
    } | null;
    productInstance: {
      __typename?: "ProductInstance";
      id: string;
      description: string;
      contractId: string;
      product:
        | { __typename?: "Claims"; id: string; name: string }
        | { __typename?: "CompEvents"; id: string; name: string }
        | { __typename?: "DailyDiary"; id: string; name: string }
        | { __typename?: "EarlyWarnings"; id: string; name: string }
        | { __typename?: "Events"; id: string; name: string }
        | { __typename?: "Instructions"; id: string; name: string }
        | { __typename?: "RisksRegister"; id: string; name: string }
        | { __typename?: "Variations"; id: string; name: string };
      contract: {
        __typename?: "Contract";
        id: string;
        friendlyName: string;
        timeZone: string;
        valueCurrency: string;
        project: { __typename?: "Project"; id: string; friendlyName: string };
      };
    };
    creator: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    modifiedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
  };
  regardingProductItem:
    | {
        __typename: "ClaimItem";
        id: string;
        number: string;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          product:
            | { __typename?: "Claims"; id: string; numberingFormat: string }
            | { __typename?: "CompEvents"; id: string; numberingFormat: string }
            | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                numberingFormat: string;
              }
            | { __typename?: "Events"; id: string; numberingFormat: string }
            | {
                __typename?: "Instructions";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "Variations";
                id: string;
                numberingFormat: string;
              };
          contract: { __typename?: "Contract"; id: string; timeZone: string };
        };
      }
    | {
        __typename: "CompEventItem";
        id: string;
        number: string;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          product:
            | { __typename?: "Claims"; id: string; numberingFormat: string }
            | { __typename?: "CompEvents"; id: string; numberingFormat: string }
            | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                numberingFormat: string;
              }
            | { __typename?: "Events"; id: string; numberingFormat: string }
            | {
                __typename?: "Instructions";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "Variations";
                id: string;
                numberingFormat: string;
              };
          contract: { __typename?: "Contract"; id: string; timeZone: string };
        };
      }
    | {
        __typename?: "DailyDiaryItem";
        id: string;
        date: any;
        number: string;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          product:
            | { __typename?: "Claims"; id: string; numberingFormat: string }
            | { __typename?: "CompEvents"; id: string; numberingFormat: string }
            | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                numberingFormat: string;
              }
            | { __typename?: "Events"; id: string; numberingFormat: string }
            | {
                __typename?: "Instructions";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "Variations";
                id: string;
                numberingFormat: string;
              };
          contract: { __typename?: "Contract"; id: string; timeZone: string };
        };
      }
    | {
        __typename: "EarlyWarningItem";
        id: string;
        number: string;
        title: string;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          product:
            | { __typename?: "Claims"; id: string; numberingFormat: string }
            | { __typename?: "CompEvents"; id: string; numberingFormat: string }
            | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                numberingFormat: string;
              }
            | { __typename?: "Events"; id: string; numberingFormat: string }
            | {
                __typename?: "Instructions";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "Variations";
                id: string;
                numberingFormat: string;
              };
          contract: { __typename?: "Contract"; id: string; timeZone: string };
        };
      }
    | { __typename?: "EventItem" }
    | {
        __typename: "InstructionItem";
        id: string;
        number: string;
        title: string;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          product:
            | { __typename?: "Claims"; id: string; numberingFormat: string }
            | { __typename?: "CompEvents"; id: string; numberingFormat: string }
            | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                numberingFormat: string;
              }
            | { __typename?: "Events"; id: string; numberingFormat: string }
            | {
                __typename?: "Instructions";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "Variations";
                id: string;
                numberingFormat: string;
              };
          contract: { __typename?: "Contract"; id: string; timeZone: string };
        };
      }
    | { __typename?: "RiskItem" }
    | {
        __typename: "VariationItem";
        id: string;
        number: string;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          numberingFormat: string;
          product:
            | { __typename?: "Claims"; id: string; numberingFormat: string }
            | { __typename?: "CompEvents"; id: string; numberingFormat: string }
            | { __typename?: "DailyDiary"; id: string; numberingFormat: string }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                numberingFormat: string;
              }
            | { __typename?: "Events"; id: string; numberingFormat: string }
            | {
                __typename?: "Instructions";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                numberingFormat: string;
              }
            | {
                __typename?: "Variations";
                id: string;
                numberingFormat: string;
              };
          contract: { __typename?: "Contract"; id: string; timeZone: string };
        };
      };
};

export type UserPendingAuthorizationActionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserPendingAuthorizationActionsQuery = {
  __typename?: "Query";
  userPendingAuthorizationActions: Array<{
    __typename?: "AuthorizationWorkflowAudit";
    id: string;
    initiatedById: string;
    regardingProductItemId: string;
    regardingProductType: ProductType;
    regardingItemHistoryId?: string | null;
    actionId: string;
    status: AuthorizationWorkflowAuditStatus;
    dateCreated: any;
    dateInitiated: any;
    initiatedBy: {
      __typename?: "User";
      id: string;
      firstname: string;
      surname: string;
      email: any;
    };
    action: {
      __typename?: "AuthorizationWorkflowActionMapping";
      id: string;
      productOutputActionId: string;
      workflowId: string;
      partyId?: string | null;
      productInstanceId: string;
      status: AuthorizationWorkflowActionMappingStatus;
      dateCreated: any;
      creatorId: string;
      modifiedById: string;
      dateModified: any;
      productOutputAction: {
        __typename?: "ProductOutputAction";
        id: string;
        name: string;
        productId: string;
        isInternal: boolean;
        status: ProductOutputActionStatus;
        dateCreated: any;
        creatorId: string;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        product:
          | {
              __typename?: "Claims";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "CompEvents";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "DailyDiary";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "EarlyWarnings";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Events";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Instructions";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "RisksRegister";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            }
          | {
              __typename?: "Variations";
              id: string;
              name: string;
              numberingFormat: string;
              soloModeSupported: boolean;
              allowMultipleInstances: boolean;
              status: ProductStatus;
            };
      };
      workflow: {
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      };
      party?: {
        __typename?: "ContractBindingType";
        id: string;
        description: string;
        contractTypeId: string;
        status: ContractBindingTypeStatus;
      } | null;
      productInstance: {
        __typename?: "ProductInstance";
        id: string;
        description: string;
        contractId: string;
        product:
          | { __typename?: "Claims"; id: string; name: string }
          | { __typename?: "CompEvents"; id: string; name: string }
          | { __typename?: "DailyDiary"; id: string; name: string }
          | { __typename?: "EarlyWarnings"; id: string; name: string }
          | { __typename?: "Events"; id: string; name: string }
          | { __typename?: "Instructions"; id: string; name: string }
          | { __typename?: "RisksRegister"; id: string; name: string }
          | { __typename?: "Variations"; id: string; name: string };
        contract: {
          __typename?: "Contract";
          id: string;
          friendlyName: string;
          timeZone: string;
          valueCurrency: string;
          project: { __typename?: "Project"; id: string; friendlyName: string };
        };
      };
      creator: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      modifiedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
    };
    regardingProductItem:
      | {
          __typename: "ClaimItem";
          id: string;
          number: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        }
      | {
          __typename: "CompEventItem";
          id: string;
          number: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        }
      | {
          __typename?: "DailyDiaryItem";
          id: string;
          date: any;
          number: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        }
      | {
          __typename: "EarlyWarningItem";
          id: string;
          number: string;
          title: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        }
      | { __typename?: "EventItem" }
      | {
          __typename: "InstructionItem";
          id: string;
          number: string;
          title: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        }
      | { __typename?: "RiskItem" }
      | {
          __typename: "VariationItem";
          id: string;
          number: string;
          productInstance: {
            __typename?: "ProductInstance";
            id: string;
            description: string;
            numberingFormat: string;
            product:
              | { __typename?: "Claims"; id: string; numberingFormat: string }
              | {
                  __typename?: "CompEvents";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "DailyDiary";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "EarlyWarnings";
                  id: string;
                  numberingFormat: string;
                }
              | { __typename?: "Events"; id: string; numberingFormat: string }
              | {
                  __typename?: "Instructions";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "RisksRegister";
                  id: string;
                  numberingFormat: string;
                }
              | {
                  __typename?: "Variations";
                  id: string;
                  numberingFormat: string;
                };
            contract: { __typename?: "Contract"; id: string; timeZone: string };
          };
        };
  }>;
};

export type UserPendingAuthorizationAuditsQueryVariables = Exact<{
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type UserPendingAuthorizationAuditsQuery = {
  __typename?: "Query";
  userPendingAuthorizationAudits: {
    __typename?: "AuthorizationWorkflowAuditList";
    nextToken?: string | null;
    items: Array<{
      __typename?: "AuthorizationWorkflowAudit";
      id: string;
      initiatedById: string;
      regardingProductItemId: string;
      regardingProductType: ProductType;
      regardingItemHistoryId?: string | null;
      actionId: string;
      status: AuthorizationWorkflowAuditStatus;
      dateCreated: any;
      dateInitiated: any;
      initiatedBy: {
        __typename?: "User";
        id: string;
        firstname: string;
        surname: string;
        email: any;
      };
      action: {
        __typename?: "AuthorizationWorkflowActionMapping";
        id: string;
        productOutputActionId: string;
        workflowId: string;
        partyId?: string | null;
        productInstanceId: string;
        status: AuthorizationWorkflowActionMappingStatus;
        dateCreated: any;
        creatorId: string;
        modifiedById: string;
        dateModified: any;
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        workflow: {
          __typename?: "AuthorizationWorkflow";
          id: string;
          name: string;
          status: AuthorizationWorkflowStatus;
          dateCreated: any;
          dateModified: any;
          creatorId: string;
          modifiedById: string;
          projectId: string;
          levels: Array<{
            __typename?: "AuthorizationWorkflowLevel";
            sequence: number;
            mode: AuthorizationWorkflowLevelMode;
            userIds: Array<string>;
            users: {
              __typename?: "UserList";
              items: Array<{
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                jobTitle: string;
                country: string;
                mobileNumber?: any | null;
                alternateNumber?: any | null;
                dateOfBirth?: any | null;
                profilePicture?: string | null;
                email: any;
                companyId: string;
                registered?: boolean | null;
                status: UserStatus;
                lastActive?: any | null;
                dateInvited: any;
                registeredDate?: any | null;
                company: {
                  __typename?: "Company";
                  id: string;
                  tradingName: string;
                  registeredName: string;
                };
                invitedBy: {
                  __typename?: "User";
                  id: string;
                  firstname: string;
                  surname: string;
                  email: any;
                };
              }>;
            };
          }>;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          modifiedBy: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          project: {
            __typename?: "Project";
            id: string;
            name: string;
            friendlyName: string;
            number?: string | null;
            sector: ProjectSector;
            description?: string | null;
            status: ProjectStatus;
            dateCreated: any;
            creatorId: string;
            billingCompanyId: string;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
            contracts: {
              __typename?: "ContractList";
              items: Array<{
                __typename?: "Contract";
                id: string;
                name: string;
                friendlyName: string;
                status: ContractStatus;
              }>;
            };
            billingCompany: {
              __typename?: "Company";
              id: string;
              type?: CompanyType | null;
              registeredName: string;
              tradingName: string;
              registrationNumber?: string | null;
              logo?: string | null;
              vatRegistrationNumber?: string | null;
              status: CompanyStatus;
              creatorId: string;
              dateCreated: any;
              physicalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              postalAddress?: {
                __typename?: "Address";
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                provinceState?: string | null;
                country: string;
                code?: string | null;
              } | null;
              creator: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            };
          };
        };
        party?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          description: string;
          contractId: string;
          product:
            | { __typename?: "Claims"; id: string; name: string }
            | { __typename?: "CompEvents"; id: string; name: string }
            | { __typename?: "DailyDiary"; id: string; name: string }
            | { __typename?: "EarlyWarnings"; id: string; name: string }
            | { __typename?: "Events"; id: string; name: string }
            | { __typename?: "Instructions"; id: string; name: string }
            | { __typename?: "RisksRegister"; id: string; name: string }
            | { __typename?: "Variations"; id: string; name: string };
          contract: {
            __typename?: "Contract";
            id: string;
            friendlyName: string;
            timeZone: string;
            valueCurrency: string;
            project: {
              __typename?: "Project";
              id: string;
              friendlyName: string;
            };
          };
        };
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
      };
      regardingProductItem:
        | {
            __typename: "ClaimItem";
            id: string;
            number: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename: "CompEventItem";
            id: string;
            number: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename?: "DailyDiaryItem";
            id: string;
            date: any;
            number: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | {
            __typename: "EarlyWarningItem";
            id: string;
            number: string;
            title: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | { __typename?: "EventItem" }
        | {
            __typename: "InstructionItem";
            id: string;
            number: string;
            title: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          }
        | { __typename?: "RiskItem" }
        | {
            __typename: "VariationItem";
            id: string;
            number: string;
            productInstance: {
              __typename?: "ProductInstance";
              id: string;
              description: string;
              numberingFormat: string;
              product:
                | { __typename?: "Claims"; id: string; numberingFormat: string }
                | {
                    __typename?: "CompEvents";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "DailyDiary";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "EarlyWarnings";
                    id: string;
                    numberingFormat: string;
                  }
                | { __typename?: "Events"; id: string; numberingFormat: string }
                | {
                    __typename?: "Instructions";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "RisksRegister";
                    id: string;
                    numberingFormat: string;
                  }
                | {
                    __typename?: "Variations";
                    id: string;
                    numberingFormat: string;
                  };
              contract: {
                __typename?: "Contract";
                id: string;
                timeZone: string;
              };
            };
          };
    }>;
  };
};

export type UserProductInstancesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UserProductInstancesQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    roles: {
      __typename?: "ProductUserRoleList";
      items: Array<{
        __typename?: "ProductUserRole";
        productInstanceId: string;
        productRoleId: string;
        status: ProductUserRoleStatus;
        productInstance: {
          __typename?: "ProductInstance";
          id: string;
          soloModeSupported: boolean;
          contractId: string;
          numberingFormat: string;
          productId: string;
          statusCollectionId: string;
          status: ProductInstanceStatus;
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        productRole: {
          __typename?: "ProductRole";
          id: string;
          name: string;
          productId: string;
          actionIds: Array<string>;
          status: ProductRoleStatus;
        };
      }>;
    };
  } | null;
};

export type WorkflowActionsPromptQueryVariables = Exact<{
  input: WorkflowActionsPromptInput;
}>;

export type WorkflowActionsPromptQuery = {
  __typename?: "Query";
  workflowActionsPrompt: {
    __typename?: "WorkflowActionPromptList";
    workflows: {
      __typename?: "AuthorizationWorkflowList";
      items?: Array<{
        __typename?: "AuthorizationWorkflow";
        id: string;
        name: string;
        status: AuthorizationWorkflowStatus;
        dateCreated: any;
        dateModified: any;
        creatorId: string;
        modifiedById: string;
        projectId: string;
        levels: Array<{
          __typename?: "AuthorizationWorkflowLevel";
          sequence: number;
          mode: AuthorizationWorkflowLevelMode;
          userIds: Array<string>;
          users: {
            __typename?: "UserList";
            items: Array<{
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              jobTitle: string;
              country: string;
              mobileNumber?: any | null;
              alternateNumber?: any | null;
              dateOfBirth?: any | null;
              profilePicture?: string | null;
              email: any;
              companyId: string;
              registered?: boolean | null;
              status: UserStatus;
              lastActive?: any | null;
              dateInvited: any;
              registeredDate?: any | null;
              company: {
                __typename?: "Company";
                id: string;
                tradingName: string;
                registeredName: string;
              };
              invitedBy: {
                __typename?: "User";
                id: string;
                firstname: string;
                surname: string;
                email: any;
              };
            }>;
          };
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        modifiedBy: {
          __typename?: "User";
          id: string;
          firstname: string;
          surname: string;
          email: any;
        };
        project: {
          __typename?: "Project";
          id: string;
          name: string;
          friendlyName: string;
          number?: string | null;
          sector: ProjectSector;
          description?: string | null;
          status: ProjectStatus;
          dateCreated: any;
          creatorId: string;
          billingCompanyId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          contracts: {
            __typename?: "ContractList";
            items: Array<{
              __typename?: "Contract";
              id: string;
              name: string;
              friendlyName: string;
              status: ContractStatus;
            }>;
          };
          billingCompany: {
            __typename?: "Company";
            id: string;
            type?: CompanyType | null;
            registeredName: string;
            tradingName: string;
            registrationNumber?: string | null;
            logo?: string | null;
            vatRegistrationNumber?: string | null;
            status: CompanyStatus;
            creatorId: string;
            dateCreated: any;
            physicalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            postalAddress?: {
              __typename?: "Address";
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              provinceState?: string | null;
              country: string;
              code?: string | null;
            } | null;
            creator: {
              __typename?: "User";
              id: string;
              firstname: string;
              surname: string;
              email: any;
            };
          };
        };
      }> | null;
    };
    outputActions: {
      __typename?: "ProductOutputActionOriginatingPartyList";
      items: Array<{
        __typename?: "ProductOutputActionOriginatingParty";
        productOutputAction: {
          __typename?: "ProductOutputAction";
          id: string;
          name: string;
          productId: string;
          isInternal: boolean;
          status: ProductOutputActionStatus;
          dateCreated: any;
          creatorId: string;
          creator: {
            __typename?: "User";
            id: string;
            firstname: string;
            surname: string;
            email: any;
          };
          product:
            | {
                __typename?: "Claims";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "CompEvents";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "DailyDiary";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "EarlyWarnings";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Events";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Instructions";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "RisksRegister";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              }
            | {
                __typename?: "Variations";
                id: string;
                name: string;
                numberingFormat: string;
                soloModeSupported: boolean;
                allowMultipleInstances: boolean;
                status: ProductStatus;
              };
        };
        originatingParty?: {
          __typename?: "ContractBindingType";
          id: string;
          description: string;
          contractTypeId: string;
          status: ContractBindingTypeStatus;
        } | null;
      }>;
    };
  };
};
