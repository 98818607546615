import { Button, Typography, css, styled, useTheme } from "@mui/material";
import { BackIcon } from "components/Icons/BackIcon";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useTranslation } from "react-i18next";

export type HeaderBackButtonProps = {
  disabled?: boolean;
  onBack?: () => void;
};

const StyledButton = styled(Button)<{ disabled?: boolean }>(({ disabled }) =>
  disabled
    ? css`
        opacity: 0.6;
      `
    : ""
);

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = ({
  disabled = false,
  onBack,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigateBack = useNavigateBack();

  return (
    <StyledButton
      variant="outlined"
      onClick={onBack ?? navigateBack}
      data-testid="back-btn"
      size="large"
      disabled={disabled}
    >
      <BackIcon />
      <Typography
        variant="p2"
        fontWeight={700}
        color={theme.palette.grey[800]}
        ml={1}
      >
        {t("common.buttons.back")}
      </Typography>
    </StyledButton>
  );
};
