import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { statusOptionLightFields } from "./changeEarlyWarningStatus";

export const changeEventItemOwnerMutation = gql`
  ${statusOptionLightFields}
  ${creatorFields}

  mutation changeEventItemOwner($id: ID!, $ownerId: ID!) {
    changeEventItemOwner(id: $id, ownerId: $ownerId) {
      id
      title
      statusOption {
        ...StatusOptionLightFields
      }
      dateModified
      ownerId
      owner {
        ...CreatorFields
        jobTitle
        company {
          id
          registeredName
          tradingName
        }
      }
    }
  }
`;
