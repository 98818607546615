import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { DeleteConfirmationModal } from "components/DeleteConfirmationModal";
import {
  AddDailyDiaryDelayRecordInput,
  DailyDiaryPresetSection,
  DailyDiaryDelayRecord,
  EditDailyDiaryDelayRecordInput,
  FieldTypeEnum,
  AttachmentInput,
  DailyDiaryExtraConfig,
} from "generated/graphql";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";
import { BaseHeaderSection } from "../BaseSectionHeader";
import { useDelaySection } from "./useDelaySection";
import { DelayRecordModal } from "./DelayRecordModal/DelayRecordModal";
import { DelayOptionalColumn, DelayTable } from "./DelayTable/DelayTable";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { ImportDelayRecordsModal } from "./ImportDelayRecordsModal";
import { useDiaryMetadata } from "../../hooks/useDiaryMetadata";
import { DelayLookups } from "./DelayRecordModal/DelayRecordForm";

export type DelaySectionProps = {
  section: DailyDiaryPresetSection;
};

export const DelaySection: React.FC<DelaySectionProps> = ({ section }) => {
  const { t } = useTranslation();

  const updatedAttachmentsRef = useRef<AttachmentInput[]>();

  const { dailyDiaryId: dailyDiaryIdSearchParam } = useDiaryMetadata();

  const [recordToDeleteEdit, setRecordToDeleteEdit] =
    useState<DailyDiaryDelayRecord>();
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const {
    modalVisibility: importModalVisibility,
    toggleModalVisibility: toggleImportModalVisibility,
  } = useBasicModal();

  const {
    dailyDiary,
    emptyDailyDiary,
    contractLookupCollections,
    activeContractLookupCollections,
    loading: contextLoading,
    crtRevisionId,
    productInstance,
    refetchDDByDate,
  } = useContext(DailyDiaryContext);
  const { modalVisibility, toggleModalVisibility } = useBasicModal();
  const {
    delayRecords,
    loading: delayRecordsLoading,
    addDelayRecord,
    editDelayRecord,
    removeDelayRecord,
    refetchDDDelayRecords,
  } = useDelaySection(dailyDiary, crtRevisionId);

  const areaLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).areaLookupId
    );
  }, [productInstance]);

  const shiftLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).shiftLookupId
    );
  }, [productInstance]);

  const teamLookupIdOverride = useMemo(() => {
    return (
      productInstance?.extraConfig &&
      (productInstance.extraConfig as DailyDiaryExtraConfig).teamLookupId
    );
  }, [productInstance]);

  const optionalColumns: DelayOptionalColumn[] = useMemo(() => {
    const columnsToReturn: DelayOptionalColumn[] = [];

    section.fields.forEach((field) => {
      switch (field.name) {
        case "Area":
          !field.isHidden && columnsToReturn.push(DelayOptionalColumn.Area);
          break;
        case "Discipline":
          !field.isHidden &&
            columnsToReturn.push(DelayOptionalColumn.Discipline);
          break;
        case "Team":
          !field.isHidden && columnsToReturn.push(DelayOptionalColumn.Team);
          break;
        case "Shift":
          !field.isHidden && columnsToReturn.push(DelayOptionalColumn.Shift);
          break;
      }
    });

    return columnsToReturn;
  }, [section]);

  const localLookups = useMemo(() => {
    const neededLookups = section.fields
      .filter((field) => field.fieldType === FieldTypeEnum.Lkp)
      .map((field) => {
        if (field.name === "Area" && areaLookupIdOverride) {
          return { fieldName: field.name, lookupId: areaLookupIdOverride };
        }
        if (field.name === "Shift" && shiftLookupIdOverride) {
          return { fieldName: field.name, lookupId: shiftLookupIdOverride };
        }
        if (field.name === "Team" && teamLookupIdOverride) {
          return { fieldName: field.name, lookupId: teamLookupIdOverride };
        }
        return { fieldName: field.name, lookupId: field.lookupId! };
      });

    const delayLookups = {} as DelayLookups;

    neededLookups.forEach((neededLookup) => {
      switch (neededLookup.fieldName) {
        case "Shift":
          delayLookups.Shift = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
        case "Area":
          delayLookups.Area = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
        case "Discipline":
          delayLookups.Discipline = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
        case "Team":
          delayLookups.Team = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
        case "DelayType":
          delayLookups.DelayType = activeContractLookupCollections.find(
            (lkp) => lkp.id === neededLookup.lookupId
          )!;
          break;
      }
    });

    return delayLookups;
  }, [
    section,
    activeContractLookupCollections,
    areaLookupIdOverride,
    teamLookupIdOverride,
    shiftLookupIdOverride,
  ]);

  const toggleConfirmationModalVisibility = () => {
    setConfirmationModalVisibility((crtState) => !crtState);
  };

  const handleAddDelayRecord = () => {
    toggleModalVisibility();
  };

  const triggerEditRecordFlow = (recordId: GridRowId) => {
    const record = delayRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    toggleModalVisibility();
  };

  const handleDelayModalClose = () => {
    toggleModalVisibility();
    setRecordToDeleteEdit(undefined);
  };

  const triggerRemoveFlow = (recordId: GridRowId) => {
    const record = delayRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    setConfirmationModalVisibility(true);
  };

  const handleAttachmentsUpdated = async (
    attachmentInputs: AttachmentInput[]
  ) => {
    updatedAttachmentsRef.current = attachmentInputs;
    if (recordToDeleteEdit) {
      await editDelayRecord({
        variables: {
          input: {
            id: recordToDeleteEdit.id,
            attachments: attachmentInputs,
            areaOptionId: recordToDeleteEdit.areaOptionId,
            delayTypeOptionId: recordToDeleteEdit.delayTypeOptionId,
            description: recordToDeleteEdit.description,
            disciplineOptionId: recordToDeleteEdit.disciplineOptionId,
            duration: recordToDeleteEdit.duration,
            teamOptionId: recordToDeleteEdit.teamOptionId,
            shiftOptionId: recordToDeleteEdit.shiftOptionId,
          },
        },
      });
      refetchDDDelayRecords();
    }
  };

  const handleModalPrimaryClick = async (
    delayRecord: AddDailyDiaryDelayRecordInput | EditDailyDiaryDelayRecordInput,
    closeModal: boolean
  ) => {
    if (recordToDeleteEdit) {
      await editDelayRecord({
        variables: {
          input: {
            ...(delayRecord as EditDailyDiaryDelayRecordInput),
            id: recordToDeleteEdit.id,
            attachments: attachmentsToAttachmentInputs(
              // editing attachments on existing record are updated immediately. Here just commiting the same data
              recordToDeleteEdit.attachments ?? []
            ),
          },
        },
      });
    } else {
      await addDelayRecord({
        variables: {
          input: {
            ...(delayRecord as AddDailyDiaryDelayRecordInput),
            ...(dailyDiary?.id
              ? { dailyDiaryId: dailyDiary.id }
              : {
                  date: emptyDailyDiary?.date,
                  productInstanceId: emptyDailyDiary?.productInstanceId,
                }),
            attachments: updatedAttachmentsRef.current ?? [],
          },
        },
      });
    }

    updatedAttachmentsRef.current = undefined;

    if (closeModal) {
      handleDelayModalClose();
    }
  };

  const handleSuccessfulImport = () => {
    toggleImportModalVisibility();
    if (dailyDiaryIdSearchParam) {
      refetchDDDelayRecords();
    } else {
      refetchDDByDate?.();
    }
  };

  const handleRemoveRecord = () => {
    toggleConfirmationModalVisibility();
    removeDelayRecord({ variables: { id: recordToDeleteEdit?.id! } });
    setRecordToDeleteEdit(undefined);
  };

  useEffect(() => {
    if (recordToDeleteEdit && !delayRecords.includes(recordToDeleteEdit)) {
      const updatedRecord = delayRecords.find(
        (rec) => rec.id === recordToDeleteEdit.id
      );
      if (updatedRecord) {
        setRecordToDeleteEdit(updatedRecord);
      }
    }
  }, [delayRecords, recordToDeleteEdit]);

  return (
    <>
      <DeleteConfirmationModal
        title={t("Projects.DailyDiaries.RemoveConfirmationModal.title")}
        subtitle={t("Projects.DailyDiaries.RemoveConfirmationModal.subtitle")}
        open={confirmationModalVisibility}
        onPrimaryClick={handleRemoveRecord}
        onSecondaryClick={toggleConfirmationModalVisibility}
      />
      {importModalVisibility && (
        <ImportDelayRecordsModal
          open={importModalVisibility}
          optionalColumns={optionalColumns}
          delayLookupCollections={contractLookupCollections}
          loading={contextLoading}
          onClose={toggleImportModalVisibility}
          onImportSuccess={handleSuccessfulImport}
        />
      )}
      <DelayRecordModal
        open={modalVisibility}
        onPrimaryClick={handleModalPrimaryClick}
        onSecondaryClick={handleDelayModalClose}
        onClose={handleDelayModalClose}
        onAttachmentsUpdated={handleAttachmentsUpdated}
        primaryBtnLoading={delayRecordsLoading}
        optionalColumns={optionalColumns}
        section={section}
        delayLookups={localLookups}
        delayRecord={recordToDeleteEdit}
      />
      <Box width="100%">
        <BaseHeaderSection
          title={t("Projects.DailyDiaries.sections.delays")}
          addBtnCaption={t("Projects.DailyDiaries.delay")}
          onAdd={handleAddDelayRecord}
          onImport={toggleImportModalVisibility}
          mb={3}
        />
        <DelayTable
          records={delayRecords}
          optionalColumns={optionalColumns}
          delayLookupCollections={contractLookupCollections}
          loading={delayRecordsLoading || contextLoading}
          onEdit={triggerEditRecordFlow}
          onRemove={triggerRemoveFlow}
        />
      </Box>
    </>
  );
};
